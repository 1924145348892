<template>
  <component
    :is="columnItem.renderFormItem"
    v-if="columnItem.renderFormItem"
    v-bind="fieldPropsSnapshot"
    v-on="listeners"
    :value="value"
  />
  <RequiredFormItem v-else-if="columnItem.valueType === 'rich-text'" v-bind="formProps">
    <div class="rich-text-container" v-if="fieldPropsSnapshot.disabled" v-html="value"></div>
    <Weditor v-else :value="value" v-bind="fieldPropsSnapshot" v-on="listeners" />
  </RequiredFormItem>
  <RequiredFormItem v-else-if="columnItem.valueType === 'date'" v-bind="formProps">
    <component
      :is="columnItem.dateComponent || 'el-date-picker'"
      style="width: 100%"
      v-bind="fieldPropsSnapshot"
      v-on="listeners"
      :value="value"
    />
  </RequiredFormItem>

  <RequiredFormItemSelect
    v-else-if="columnItem.valueType === 'select'"
    :value="value"
    v-bind="{
      textDisplay: fieldPropsSnapshot.disabled,
      ...formProps,
      ...valueEnumSelectProps,
      ...fieldPropsSnapshot
    }"
    v-on="listeners"
  />

  <RequiredFormItem v-else-if="columnItem.valueType === 'upload'" v-bind="formProps">
    <CommonUpload :value="value" v-bind="fieldPropsSnapshot" v-on="listeners" />
  </RequiredFormItem>

  <RequiredFormItem v-else-if="columnItem.valueType === 'radio'" v-bind="formProps">
    <el-radio-group :value="value" v-bind="fieldPropsSnapshot" v-on="listeners">
      <el-radio
        v-for="(valueIetm, _index) of valueEnumSelectProps.dataList"
        :key="_index"
        :label="valueIetm[valueEnumSelectProps.valueKey]"
      >
        {{ valueIetm[valueEnumSelectProps.labelKey] }}
      </el-radio>
    </el-radio-group>
  </RequiredFormItem>

  <RequiredFormItemInput
    v-else
    v-bind="{
      ...formProps,
      ...fieldPropsSnapshot
    }"
    v-on="listeners"
    :value="value"
  />
</template>

<script>
import RequiredFormItem from '../CustomFormItem/requiredFormItem.vue'
import RequiredFormItemSelect from '../CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemInput from '../CustomFormItem/requiredFormItemInput.vue'
import Weditor from '@/components/Weditor/index.vue'
import deepEqual from 'fast-deep-equal'
import CommonUpload from '@/components/CustomFormItem/CommonUpload/index.vue'

export default {
  components: {
    Weditor,
    RequiredFormItem,
    RequiredFormItemSelect,
    RequiredFormItemInput,
    CommonUpload
  },
  props: {
    value: '',
    columnItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // fieldProps快照，避免重复渲染
      fieldPropsSnapshot: null
    }
  },
  computed: {
    // 获取form-item的props
    // 优先使用formProps配置，其次使用对应组件的props(后续将废弃)，最后使用fieldProps的required
    formProps({ columnItem }) {
      const tempProps = Object.assign({
        label: columnItem.label || columnItem.title,
        prop: columnItem.name
      })
      let fieldProps = this.getPropsValue(columnItem.fieldProps)
      if (columnItem.renderFormItem) {
        // 自定义组件
      } else if (columnItem.valueType === 'date') {
        fieldProps = this.getPropsValue(columnItem.fieldProps, columnItem.dateProps)
      } else if (columnItem.valueType === 'select') {
        fieldProps = this.getPropsValue(columnItem.fieldProps, columnItem.selectProps)
      } else {
        fieldProps = this.getPropsValue(columnItem.fieldProps, columnItem.inputProps)
      }
      tempProps.required = !!fieldProps?.required && !fieldProps.disabled
      tempProps.style = fieldProps.style
      return Object.assign({}, tempProps, this.getPropsValue(columnItem.formProps))
    },

    fieldProps({ columnItem }) {
      let tempProps = this.getPropsValue(this.$attrs, columnItem.fieldProps)
      if (columnItem.renderFormItem) {
        // 自定义组件
        tempProps.config = {
          ...columnItem,
          ...tempProps.config
        }
      } else if (columnItem.valueType === 'date') {
        tempProps = this.getPropsValue(tempProps, columnItem.dateProps)
        if (!tempProps.valueFormat) {
          tempProps.valueFormat = /time/.test(tempProps.type) ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'
        }
        if (!tempProps.defaultTime) {
          tempProps.defaultTime = /range$/.test(tempProps.type)
            ? [new Date().toLocaleTimeString(), new Date().toLocaleTimeString()]
            : new Date().toLocaleTimeString()
        }
        if (!tempProps.placeholder) {
          tempProps.placeholder = `请选择${tempProps.label ? tempProps.label : '时间'}`
        }
        if (!tempProps.startPlaceholder) {
          tempProps.startPlaceholder = `请选择开始时间`
        }
        if (!tempProps.endPlaceholder) {
          tempProps.endPlaceholder = `请选择结束时间`
        }
      } else if (columnItem.valueType === 'select') {
        tempProps = this.getPropsValue(tempProps, columnItem.selectProps)
      } else {
        tempProps = this.getPropsValue(tempProps, columnItem.inputProps)
      }
      return tempProps
    },
    valueEnumSelectProps({ columnItem }) {
      const { valueEnum } = columnItem
      if (valueEnum?.length) {
        return {
          dataList: columnItem.valueEnum,
          labelKey: columnItem.valueEnum && columnItem.valueEnum.length ? 'label' : '',
          valueKey: columnItem.valueEnum && columnItem.valueEnum.length ? 'value' : ''
        }
      }
      return {}
    },
    listeners({ $listeners, columnItem }) {
      const listeners = Object.assign($listeners, columnItem.listeners)
      if (columnItem.valueType === 'rich-text' && !listeners.onGetEditorContent) {
        listeners.onGetEditorContent = (e) => {
          this.$nextTick(() => {
            this.listeners.input(e)
          })
        }
      }
      for (const eventFn in listeners) {
        if (typeof listeners[eventFn] === 'function') {
          listeners[eventFn] = listeners[eventFn].bind(this)
        }
      }
      return listeners
    }
  },
  watch: {
    fieldProps: {
      immediate: true,
      handler(val) {
        if (!deepEqual(val, this.fieldPropsSnapshot)) {
          this.fieldPropsSnapshot = val
        }
      }
    },
    value(value, newValue) {
      if (!deepEqual(value, newValue)) {
        this.$emit('inputChange')
      }
    }
  },
  methods: {
    getPropsValue(...mergefnOrObjectRest) {
      return Object.assign(
        {},
        ...mergefnOrObjectRest.map((propsFnOrObj) => {
          if (typeof propsFnOrObj === 'function') {
            return propsFnOrObj(this.columnItem, this.$attrs.params)
          }
          return propsFnOrObj
        })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.rich-text-container {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 10px;
}
</style>
