<template>
  <div class="welcome-container">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Welcome',
  computed: {
    ...mapGetters(['name'])
  }
}
</script>

<style lang="scss" scoped>
.welcome {
  &-container {
    height: calc(100vh - 50px - 34px - 40px);
    background: url(~@/assets/img/welcome.png) no-repeat;
    background-size: 100% auto;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
</style>
