var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-tabs',{staticStyle:{"margin-top":"16px"},attrs:{"type":"border-card"},model:{value:(_vm.form.channelType),callback:function ($$v) {_vm.$set(_vm.form, "channelType", $$v)},expression:"form.channelType"}},[_c('el-tab-pane',{attrs:{"label":"传统渠道","name":"1"}},[(_vm.form.channelType === '1')?_c('div',[_c('ClassicChannel')],1):_vm._e()]),_c('el-tab-pane',{attrs:{"label":"新渠道","name":"2"}},[(_vm.form.channelType === '2')?_c('div',[_c('el-card',{attrs:{"shadow":"none"}},[_c('el-form',{attrs:{"inline":true,"model":_vm.form}},[_c('RequiredFormItemSelect',{attrs:{"required":false,"label":"所属渠道性质一级分类","labelKey":"content","valueKey":"id","getDataList":() => _vm.getStoreType('store_type'),"selectProps":{
                clearable: true,
                filterable: true,
                'popper-class': 'selectWrap'
              }},on:{"change":_vm.onChange},model:{value:(_vm.form.stairId),callback:function ($$v) {_vm.$set(_vm.form, "stairId", $$v)},expression:"form.stairId"}}),_c('RequiredFormItemSelect',{attrs:{"disabled":_vm.disabled,"required":false,"label":"所属渠道性质二级分类","labelKey":"content","valueKey":"id","dataList":_vm.dataList,"selectProps":{
                clearable: true,
                filterable: true
              }},model:{value:(_vm.form.foxBaseId),callback:function ($$v) {_vm.$set(_vm.form, "foxBaseId", $$v)},expression:"form.foxBaseId"}}),_c('el-form-item',{attrs:{"label":"三级分类编号"}},[_c('el-input',{attrs:{"placeholder":"请填写三级分类编号","clearable":""},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")])],1)],1)],1),_c('TablePro',{ref:"newTableProRef",attrs:{"request":_vm.getData,"columns":_vm.newChannelColumns,"auto-width-cell":"","auto-height":false,"maxAutoWidthTitle":300}},[_c('el-table-column',{attrs:{"slot":"tableBefore","type":"selection","reserve-selection":"","fixed":true},slot:"tableBefore"})],1)],1):_vm._e()]),_c('el-tab-pane',{attrs:{"label":"经典卖场管理","name":"3"}},[(_vm.form.channelType === '3')?_c('div',[_c('TraditionalChannel')],1):_vm._e()])],1),_c('el-dialog',{attrs:{"title":_vm.type ? '修改' : '新增',"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.cancel}},[_c('el-form',{ref:"form",attrs:{"model":_vm.f,"label-width":"200px"}},[_c('RequiredFormItemSelect',{attrs:{"disabled":true,"label":"所属渠道性质一级分类","labelKey":"content","valueKey":"id","getDataList":() => _vm.getStoreType('store_type'),"selectProps":{
          clearable: true,
          filterable: true
        }},model:{value:(_vm.f.stairId),callback:function ($$v) {_vm.$set(_vm.f, "stairId", $$v)},expression:"f.stairId"}}),_c('RequiredFormItemSelect',{attrs:{"prop":"parentId","disabled":_vm.type ? true : false,"label":"所属渠道性质二级分类","labelKey":"content","valueKey":"id","getDataList":() => _vm.getStoreType('secondary_store_type'),"selectProps":{
          clearable: true,
          filterable: true
        }},on:{"changeSelectedItem":_vm.onSecondaryChange},model:{value:(_vm.f.parentId),callback:function ($$v) {_vm.$set(_vm.f, "parentId", $$v)},expression:"f.parentId"}}),(_vm.type)?_c('el-form-item',{attrs:{"label":"渠道性质三级分类编号"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.f.code),callback:function ($$v) {_vm.$set(_vm.f, "code", $$v)},expression:"f.code"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"渠道性质三级分类名称","prop":"content","rules":[
          {
            required: true,
            message: '请输入渠道性质三级分类名称',
            trigger: 'blur'
          }
        ]}},[_c('el-input',{attrs:{"clearable":""},model:{value:(_vm.f.content),callback:function ($$v) {_vm.$set(_vm.f, "content", $$v)},expression:"f.content"}})],1),_c('el-form-item',{attrs:{"label":"备注(对应联合营销平台编码)"}},[_c('el-input',{attrs:{"clearable":""},model:{value:(_vm.f.remark),callback:function ($$v) {_vm.$set(_vm.f, "remark", $$v)},expression:"f.remark"}})],1),_c('el-form-item',{attrs:{"label":"更新人"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.f.createName),callback:function ($$v) {_vm.$set(_vm.f, "createName", $$v)},expression:"f.createName"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }