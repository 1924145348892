<template>
  <div class="app-container">
    <TablePro
      ref="tableProRef"
      :columns="columns"
      :request="getTableList"
      :actions="['export']"
      auto-width-cell>
      <template #tableBefore>
        <el-table-column type="selection" reserve-selection />
      </template>
      <template #actionArea="{ selectedList }">
        <el-button
          @click="onRemove(selectedList)"
          type="danger"
          :disabled="!selectedList.length">
          批量删除
        </el-button>
        <el-button @click="onExportCount(1)"> 违规次数导出 </el-button>
        <el-button @click="onExportCount(2)"> 违约金导出 </el-button>
        <el-button @click="onExportCount(3)"> 产品总清单报表导出 </el-button>
        <el-button @click="onExportCount(4)"> 违规产品导出 </el-button>
        <el-button @click="onExportCount(5)">
          跨品牌违规销售报表导出
        </el-button>
        <el-button @click="onExportCount(6)">
          违约照片导出
        </el-button>
      </template>
    </TablePro>
    <PopupForm
      ref="popupFormRef"
      popup-type="el-drawer"
      :columns="popupFormColumns"
      :actions="['cancel']"
      label-position="left"
      destroy-slot-on-close
      width="30%" />
    <PopupForm
      ref="popupFormRefphoto"
      popup-type="el-drawer"
      :columns="popupFormColumnsPht"
      :actions="['cancel']"
      label-position="left"
      destroy-slot-on-close
      width="40%" />
    <CommonPopup ref="goodsListRef" width="70%" destroy-slot-on-close>
      <template #default="{ data }">
        <GoodsList :dataList="data" :shopNumber="shopNumber" />
      </template>
    </CommonPopup>
    <CommonPopup ref="violationRef" width="70%" destroy-slot-on-close>
      <template #default="{ data }">
        <ViolationList
          :dataList="data"
          :shopNumber="shopNumber"
          :dealerCode="dealerCode"
          :dealerName="dealerName" />
      </template>
    </CommonPopup>
  </div>
</template>

<script setup>
import {
  apiGetInspectionList,
  apiBatchDeleteInspection,
  downloadCountJob,
  downloadFineJob,
  downloadProductTotalList,
  downloadViolationProduct,
  downloadCrossBrandSales,
  apibatchRejected,
  apiGetBusinessList,
  downloadViolationPhoto
} from '../api'
import TablePro, { render } from '@/components/TablePro/index.vue'
import PopupForm, { renderFormItem } from '@/components/FormPro/PopupForm.vue'
import CommonSelect from '@/components/CustomFormItem/Select.vue'
import CommonPopup from '@/components/FormPro/CommonPopup.vue'
import GoodsList from './components/GoodsList'
import ViolationList from './components/ViolationList'
import { ref, reactive } from 'vue'
import { Message, MessageBox } from 'element-ui'

const tableProRef = ref(null)
const popupFormRef = ref(null)
const popupFormRefphoto = ref(null)
//弹框
const goodsListRef = ref(null)
const violationRef = ref(null)

const shopNumber = ref('')
const dealerCode = ref('')
const dealerName = ref('')
const columnData = [
  { title: '商场外立面', name: 'mallFacade' },
  { title: '商场平面图', name: 'mallLayout' },
  { title: '门店门头', name: 'storeFront' },
  { title: '整体店态', name: 'overallStore' },
  { title: '浅谈区', name: 'discussionArea' },
  { title: '吧台', name: 'barCounter' },
  { title: '餐吧', name: 'diningBar' },
  { title: '礼品堆', name: 'giftPile' },
  { title: '枕头', name: 'pillow' },
  { title: '助眠', name: 'sleepAid' },
  { title: '床品', name: 'bedding' },
  { title: '商场广告', name: 'mallAdvertisement' },
  { title: '堆头', name: 'pileHead' },
  { title: 'U形旗', name: 'UFlag' },
  { title: '吊旗', name: 'hangingFlag' },
  { title: '竖幅', name: 'verticalBanner' },
  { title: '立柱', name: 'pillar' },
  { title: '床旗', name: 'bedFlag' },
  { title: '手持KT板', name: 'handheldKTBoard' },
  { title: '背景板', name: 'backgroundBoard' },
  { title: '主推产品板', name: 'featuredProductBoard' },
  { title: '其他', name: 'other' },
  { title: '导购照片', name: 'shoppingGuide' }
]
const searchParams = reactive({
  params: {}
})
const onExportCount = type => {
  const { currPage, pageSize, ...rest } = searchParams.params
  switch (type) {
    case 1:
      downloadCountJob({ enable: 1, ...rest })
      break
    case 2:
      downloadFineJob({ enable: 1, ...rest })
      break
    case 3:
      downloadProductTotalList({ enable: 1, ...rest })
      break
    case 4:
      downloadViolationProduct({ enable: 1, ...rest })
      break
    case 5:
      downloadCrossBrandSales({ enable: 1, ...rest })
      break
    case 6:
      downloadViolationPhoto({ enable: 1, ...rest })
      break
    default:
      // 处理默认情况
      break
  }
}

const onRemove = async items => {
  if (items.length === 0) return
  for (var item of items) {
    if (item.status !== 1) {
      Message.warning('只能勾选已提交状态的数据')
      return
    }
  }
  const confirm = await MessageBox.confirm('确定要删除选中项吗？', {
    type: 'warning'
  }).catch(() => {})
  if (!confirm) return
  console.log(items)
  const ids = items.map(v => v.id)
  await apiBatchDeleteInspection(ids)
  Message.success('删除成功')
  tableProRef.value.onRefreshTableList()
  tableProRef.value.onResetSelected()
}

const columns = [
  {
    title: '序号',
    tableColumnProps: { align: 'center' },
    valueType: 'index'
  },
  {
    title: '巡检记录ID',
    tableColumnProps: { align: 'center' },
    name: 'id',
    search: true,
    order: 0
  },
  {
    title: '状态',
    name: 'status',
    tableColumnProps: { align: 'center' },
    valueType: 'select',
    search: true,
    valueEnum: [
      { label: '草稿', value: 0 },
      { label: '已提交', value: 1 }
    ],
    order: 4
  },
  {
    title: '是否下发',
    name: 'flowStatus',
    tableColumnProps: { align: 'center' },
    valueType: 'select',
    search: true,
    // hideInTable: true,
    hideInExport: true,
    valueEnum: [
      { label: '否', value: 0 },
      { label: '是', value: 1 }
    ],
    order: 3
  },
  {
    title: '门店编号',
    name: 'shopNumber',
    search: true,
    tableColumnProps: { align: 'center' },
    order: 6
  },
  {
    title: '门店类型',
    name: 'shopInspectionType',
    search: true,
    tableColumnProps: { align: 'center' },
    valueType: 'select',
    valueEnum: [
      { label: '门店', value: 0 },
      { label: '私设网点', value: 1 }
    ],
    order: 7
  },
  {
    title: '省份',
    name: 'province',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '城市',
    name: 'city',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '授权城市',
    name: 'authorizedCity',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '经销商编号',
    name: 'dealerCode',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '经销商名称',
    name: 'dealerName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '巡检人姓名',
    name: 'inspectorName',
    search: true,
    tableColumnProps: { align: 'center' },
    order: 5
  },
  {
    title: '巡检人工号',
    name: 'inspectorAccount',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '巡检人部门',
    name: 'inspectorDeptName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '巡检日期',
    name: 'inspectTime',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '提交时间',
    name: 'xxx',
    valueType: 'date',
    search: {
      transform(e) {
        return e
          ? {
              inspectBeginTime: e[0],
              inspectEndTime: e[1]
            }
          : {}
      }
    },
    fieldProps: {
      type: 'datetimerange'
    },
    hideInTable: true,
    hideInExport: true,
    order: 2
  },
  {
    title: '品牌',
    name: 'bigBrandId',
    search: true,
    hideInTable: true,
    hideInExport: true,
    order: 1,
    renderFormItem: renderFormItem((h, context) => {
      const getListCategory = ({ page, name }) => {
        return apiGetBusinessList({
          currPage: page,
          name: name,
          brandLevel:0,
          ifDelete:0,
          type:0
        })
      }
      return (
        <CommonSelect
          placeholder='请选择品类'
          getDataList={getListCategory}
          label-key='name'
          value-key='id'
          clearable
          filterable
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '基础信息',
    name: 'phontoStatus',
    tableColumnProps: { align: 'center', minWidth: '120' },
    render: render((h, context) => {
      return (
        <el-button
          link
          v-loading-click={() => {
            const obj = JSON.parse(context.data.basicInformationJson)
            const dataListPup = {
              bigBrandName: context.data?.bigBrandName,
              bigRegionName: obj?.dealer?.bigRegionName,
              smallRegionName: obj?.dealer?.smallRegionName,
              provinceName: obj?.dealer?.provinceName,
              cityName: obj?.dealer?.cityName,
              areaName: obj?.dealer?.areaName,
              authorizedCityName: context.data?.authorizedCity,
              bossName: obj?.dealer?.bossName,
              bossContactInfo: obj?.dealer?.bossContactInfo,
              dealerName: context.data?.dealerName,
              licenseName: context.data?.licenseName,
              licenseNum: context.data?.licenseNum,
              authorizedBrandNum: context.data?.shopBrandNumber,
              authorizedBrandName: context.data?.authorizedBrandName,
              //门店编号
              shopNumber: context.data?.shopNumber,
              positioning:[obj?.shop?.longitude??obj.longitude,obj?.shop?.latitude??obj.latitude].filter(Boolean).join(','),
              seriesNameList: obj?.shop?.seriesNameList,
              shopTypeName: obj?.shop?.shopTypeName,
              storeNatureName: obj?.shop?.storeNatureName,
              marketName: obj?.shop?.marketName,
              marketType: obj?.shop?.marketType,
              shopName:context.data?.shopName,
              shopAddress:obj?.shop?.shopAddress,
              shopArea:obj?.shop?.shopArea,
              decorationTime:obj?.shop?.decorationTime,
              shopStatus:obj?.shop?.shopStatus ?'有效':'失效',
              businessStatus:obj?.shop?.businessStatus??obj.businessStatus,
              shoppingGuideCount:obj?.shop?.shoppingGuideCount??obj.shoppingGuideCount,
              shoppingGuideFullTime:(obj?.shop?.shoppingGuideFullTime??obj.shoppingGuideFullTime) ? '是' : '否',
              inspectionCount:obj?.shop?.inspectionCount??obj.inspectionCount,
              inspectionOk:(obj?.shop?.inspectionOk??obj.inspectionOk) ? '是' : '否',
              inspectionNotOkReason:obj?.shop?.inspectionNotOkReason??obj.inspectionNotOkReason,
              remark:obj?.shop?.remark??obj.remark,
            }
            return popupFormRef.value.onShow(dataListPup, () => {}, {
              title: '基础信息'
            })
          }}>
          查看详情
        </el-button>
      )
    })
  },
  {
    title: '巡检门店产品',
    tableColumnProps: { align: 'center', minWidth: '120' },
    render: render((h, context) => {
      return (
        <el-button
          link
          v-loading-click={() => {
            shopNumber.value = context.data.shopNumber
            const obj = context.data.inspectShopProductJson
              ? JSON.parse(context.data.inspectShopProductJson)?.products
              : []
            return goodsListRef.value.onShow(obj, () => {}, {
              title: '门店产品'
            })
          }}>
          查看详情
        </el-button>
      )
    })
  },
  {
    title: '违规详情',
    tableColumnProps: { align: 'center', minWidth: '120' },
    render: render((h, context) => {
      return (
        <el-button
          link
          v-loading-click={async () => {
            shopNumber.value = context.data.shopNumber
            dealerCode.value = context.data.dealerCode
            dealerName.value = context.data.dealerName
            const obj = context.data.violationDetailJson
              ? JSON.parse(context.data.violationDetailJson)?.violations
              : []
            return violationRef.value.onShow(obj, () => {}, {
              title: '违规详情'
            })
          }}>
          查看详情
        </el-button>
      )
    })
  },
  {
    title: '照片',
    tableColumnProps: { align: 'center', minWidth: '120' },
    render: render((h, context) => {
      return (
        <el-button
          link
          v-loading-click={() => {
            const imgObj = context.data.photoJson
              ? JSON.parse(context?.data?.photoJson)
              : {}
            console.log(imgObj, 'imgObj')
            const mergedObject = {}
            if (Object.keys(imgObj).length) {
              imgObj?.shops?.forEach(shop => {
                const mappedName = columnData.find(
                  item => item.title === shop.label
                )?.name
                if (mappedName) {
                  mergedObject[mappedName] = shop.urls
                }
              })

              imgObj?.advertisements?.forEach(advertisement => {
                const mappedName = columnData.find(
                  item => item.title === advertisement.label
                )?.name
                if (mappedName) {
                  mergedObject[mappedName] = advertisement.urls
                }
              })
              mergedObject['shoppingGuide'] = imgObj?.shoppingGuide?.urls
              console.log(mergedObject, 'NEWmergedObject')
            }
            return popupFormRefphoto.value.onShow(mergedObject, () => {}, {
              title: '查看详情'
            })
          }}>
          查看详情
        </el-button>
      )
    })
  },
  {
    title: '操作',
    width: 80,
    render: render((h, context) => {
      return (
        <div>
          {context.data.status === 1 ? (
            <el-button
              link
              v-loading-click={() => {
                return MessageBox.confirm(
                  '此操作将驳回该数据，是否继续？',
                  '提示',
                  {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }
                ).then(async () => {
                  await apibatchRejected([context.data.id])
                  Message.success('成功')
                  tableProRef.value.onRefreshTableList()
                })
              }}>
              驳回
            </el-button>
          ) : (
            ''
          )}
        </div>
      )
    })
  }
]
const getTableList = async params => {
  searchParams.params = params
  const res = await apiGetInspectionList({
    ...params,
    enable: 1
  })
  return {
    data: res.data,
    totalCount: res.totalCount
  }
}
const popupFormColumns = [
  {
    title: '事业部：',
    name: 'bigBrandName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '营销部：',
    name: 'bigRegionName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '营销中心：',
    name: 'smallRegionName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  // {
  //   title: '区域负责人：',
  //   name: 'xx',
  //   fieldProps: { placeholder: '无', disabled: true }
  // },
  {
    title: '省份：',
    name: 'provinceName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '城市：',
    name: 'cityName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '区/县：',
    name: 'areaName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '授权城市：',
    name: 'authorizedCityName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '实控人：',
    name: 'bossName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '实控人电话：',
    name: 'bossContactInfo',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '经销商名称：',
    name: 'dealerName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '售达方编号：',
    name: 'licenseNum',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '售达方名称：',
    name: 'licenseName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '送达方编号：',
    name: 'authorizedBrandNum',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '送达方名称：',
    name: 'authorizedBrandName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '门店编号：',
    name: 'shopNumber',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '巡检地址定位：',
    name: 'positioning',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '门店代理系列：',
    name: 'seriesNameList',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '渠道性质：',
    name: 'shopTypeName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '店态性质：',
    name: 'storeNatureName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '卖场名称：',
    name: 'marketName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '卖场类型：',
    name: 'marketType',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '门店名称：',
    name: 'shopName',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '门店地址：',
    name: 'shopAddress',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '门店面积：',
    name: 'shopArea',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '上次装修时间：',
    name: 'decorationTime',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '系统门店状态：',
    name: 'shopStatus',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '营业状态：',
    name: 'businessStatus',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '导购人数：',
    name: 'shoppingGuideCount',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '导购是否专职：',
    name: 'shoppingGuideFullTime',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '本次巡检次数：',
    name: 'inspectionCount',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '门店是否正常巡检：',
    name: 'inspectionOk',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '门店不能巡检原因：',
    name: 'inspectionNotOkReason',
    fieldProps: { placeholder: '无', disabled: true }
  },
  {
    title: '备注：',
    name: 'remark',
    fieldProps: { placeholder: '无', disabled: true }
  }
]

const popupFormColumnsPht = columnData.map(column => ({
  title: column.title,
  name: column.name,
  renderFormItem: renderFormItem((h, context) => {
    return (
      <div>
        {context.$attrs.params[column.name]?.map((item, index) => (
          <el-image
            key={index}
            src={item}
            preview-src-list={context.$attrs.params[column.name]}
            fit="cover"
            style="width: 100px; height: 100px;margin: 10px;"
          />
        ))}
      </div>
    )
  })
}))
</script>
