<template>
  <div style="margin-top:20px">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>{{ type == 'edit' ? '编辑我的招商计划' : '我的招商计划' }}</span>
      </div>
      <div v-if="type == 'edit'">
        <el-button type="primary" class="download"
          >excel导入
          <input
            ref="files"
            type="file"
            class="file"
            name=""
            id=""
            @change="uploadExcel"
        /></el-button>
        <el-button @click="downloadExcel">下载模板</el-button>
        <el-button type="danger" @click="del" :disabled="rowId.length===0">删除</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </div>
      <div>
        <el-table
          ref="filterTable"
          :data="tableData"
          border
          style="width: 100%;margin-top:20px"
          :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
          @row-click="rowClick"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="province" label="省份" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="品牌" align="center">
          </el-table-column>
          <el-table-column
            prop="storeType"
            label="店面类型（独立店、KA店、社区店）"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="january" label="1月" align="center">
          </el-table-column>
          <el-table-column prop="february" label="2月" align="center">
          </el-table-column>
          <el-table-column prop="march" label="3月" align="center">
          </el-table-column>
          <el-table-column prop="april" label="4月" align="center">
          </el-table-column>
          <el-table-column prop="may" label="5月" align="center">
          </el-table-column>
          <el-table-column prop="june" label="6月" align="center">
          </el-table-column>
          <el-table-column prop="july" label="7月" align="center">
          </el-table-column>
          <el-table-column prop="august" label="8月" align="center">
          </el-table-column>
          <el-table-column prop="september" label="9月" align="center">
          </el-table-column>
          <el-table-column prop="october" label="10月" align="center">
          </el-table-column>
          <el-table-column prop="november" label="11月" align="center">
          </el-table-column>
          <el-table-column prop="december" label="12月" align="center">
          </el-table-column>
          <el-table-column prop="monthTotal" label="合计" align="center">
          </el-table-column>
        </el-table>
        <div class="total">
          <span>合计：{{ totalSum }}</span>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          :current-page="currentPage"
          :page-sizes="[10, 30, 50]"
          :page-size="pageSize || 10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="currentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getMinePlanDetail,
  downloadExc,
  uploadMinePlan,
  delOrReset
} from '@/api/businessPlan'
import { getStorage } from '@/utils/storage'
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      type: this.$route.query.type,
      activityId: this.$route.query.id,
      totalSum: 0,
      userInfo: {},
      rowId: []
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    this.getDataList()
  },
  methods: {
    async getDataList() {
      const res = await getMinePlanDetail({
        currPage: this.currentPage,
        pageSize: this.pageSize,
        activityId: this.activityId,
        type: 1,
        kye: 0
      })
      if (res.code === 0) {
        this.tableData = res.data
        this.total = res.totalCount
        console.log(res.data[0]);
        this.totalSum = res.data[0]?res.data[0].targetCount: 0
      }
    },
    // 下载模板
    async downloadExcel() {
      await downloadExc()
    },
    // 上传模板
    async uploadExcel(e) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      formData.append('activityId', this.activityId)
      formData.append('jobNumber', this.userInfo.code)
      formData.append('userId', this.userInfo.accountId)
      formData.append('username', this.userInfo.realName)
      this.$refs.files.value = ''
      const res = await uploadMinePlan(formData)
      if (res.code === 0) {
        this.$message.success('导入成功')
        this.currentPage = 1
        this.getDataList()
      }
    },
    // 删除
    del() {
      this.$confirm('是否删除该计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const provinceKye = this.tableData
          .filter(item => {
            return !!this.rowId.includes(item.id)
          })
          .map(i => i.province)
          .join(',')
        const res = await delOrReset({
          activityId: this.activityId,
          provinceKye: provinceKye
        })
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getDataList()
        }
      })
    },
    // 重置
    reset() {
       this.$confirm('是否重置我的计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await delOrReset({
          activityId: this.activityId
        })
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getDataList()
        }
      })
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.total {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  border: 1px solid #e6ebf5;
  border-top-width: 0;
}
.download {
  position: relative;
  .file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
