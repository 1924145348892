<template>
  <div>
    <div class="goBack" @click="back">
      <i class="el-icon-arrow-left" />
      <span>返回上级</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    // 返回
    back() {
      if (this.url) {
        this.$router.push({ path: this.url })
      } else {
        this.$router.back()
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.goBack {
  width: 120px;
  height: 30px;
  background: #f8f9fd;
  font-size: 12px;
  color: #596278;
  line-height: 30px;
  text-align: center;
  position: fixed;
  right: 20px;
  top: 90px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-family: PingFangSC-Regular, PingFang SC;
  z-index: 9999;
}
</style>
