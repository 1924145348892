import http from "../../utils/request";
// 活动列表
export const getActiveData = params => {
  return http({
    url: '/consumermanage/activitydistributor/listpage',
    params,
    method: 'GET'
  })
}
// 地区负责人
export const getEffective = (params) => {
  return http({
    url: '/consumermanage/regionuser/listpage',
    params,
    method: 'GET'
  })
}
// 地区
export const getAddress = params => {
  return http({
    url: '/user/region/address',
    params,
    method: 'GET'
  })
}
// 保存活动
export const saveData = (url, data) => {
  return http({
    url,
    data,
    method: 'POST'
  })
}

// 获取活动详情
export const activeDetail = params => {
  return http({
    url: '/consumermanage/activitydistributor/info',
    params,
    method: 'GET'
  })
}

// 删除活动
export const delActive = (data) => {
  return http({
    url: '/consumermanage/activitydistributor/delete',
    data,
    method: 'POST'
  })
}

// 修改活动状态
export const activeStatus = data => {
  return http({
    url: '/consumermanage/activitydistributor/update/status',
    data,
    method: 'POST'
  })
}
// 区域负责人
export const areaGather = params => {
  return http({
    url: '/consumermanage/activitydistributor/information/gathering',
    params,
    method: 'GET'
  })
}

export const createUser = params => {
  return http({
    url: '/consumermanage/distributor/list/createUserId',
    params,
    method: 'GET'
  })
}

// 删除导入客户信息
export const delClient = data => {
  return http({
    url: '/consumermanage/distributor/delete',
    data,
    method: 'POST'
  })
}
// 导入客户信息
export const importClient = data => {
  return http({
    url: '/consumermanage/distributor/imports',
    data,
    method: 'POST'
  })
}
