import * as reg from './reg'
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}
export const checkNumber = (rule,value,callback) => {
  if(!value){
    callback(new Error('必填项不能为空'))
  }else if(!reg.number.test(value)){
    callback(new Error('请输入正整数'))
  }else{
    callback()
  }
}
export const checkFloatNumber = (rule,value,callback) => {
  if(!value){
    callback(new Error('必填项不能为空'))
  }else if(!reg.floatNumber.test(value)){
    callback(new Error('请输入整数或者保留2位小数的数字'))
  }else{
    callback()
  }
}

