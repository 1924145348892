<!--
 * @Author: wangmq
 * @Date: 2023-04-22 11:51:14
 * @LastEditors: wangmq
 * @LastEditTime: 2023-06-16 18:16:37
-->
<template>
  <div>
    <TablePro
      ref="tableRef"
      :columns="tableColumns"
      :request="getDataList"
      :actions="['export']"
      @selection-change="onSelect">
      <template #actionArea>
        <div>
          <PopupForm
            ref="popupFormRef"
            popup-type="dialog"
            width="450px"
            title="新增"
            :columns="formColumns"
            :on-submit="onSubmit">
            <!-- 触发表单按钮，也可以通过调用组件实例onShow方法触发
              onShow(Object),Object初始化表单数据，返回一个promise，当弹框关闭时resolve
           -->
            <template #trigger="{ onShow }">
              <el-button
                type="primary"
                @click="
                  onShow({
                    moduleBrand: 'C',
                    updateName: userInfo.realName
                  })
                ">
                添加
              </el-button>
            </template>
          </PopupForm>
          <el-button
            type="primary"
            :disabled="selectList.length === 0"
            @click="handleChangeStatus(0)">
            批量生效
          </el-button>
          <el-button
            type="primary"
            :disabled="selectList.length === 0"
            @click="handleChangeStatus(1)">
            批量失效
          </el-button>
        </div>
      </template>
    </TablePro>
  </div>
</template>

<script>
import TablePro, { render, renderFormItem } from '@/components/TablePro'
import CommonSelect from '@/components/CustomFormItem/requestModuleSelect.vue'
import PopupForm from '@/components/FormPro/PopupForm.vue'
import SelectPro from '@/components/CustomFormItem/Select.vue'
import {
  requestGetShopList,
  update,
  updateStatus,
  add
} from '@/api/processBasicConfiguration'
import { getCityInfoData } from '@/api/flowSetting/cityInfoManage'
import deepCopy from '@/utils/deepCopy'
import { getStorage } from '@/utils/storage'
export default {
  components: {
    TablePro,
    CommonSelect,
    PopupForm,
    SelectPro
  },
  data() {
    return {
      userInfo: {},
      selectList: [],
      tableColumns: [
        {
          title: '卖场名称',
          name: 'marketName',
          search: true
        },
        {
          title: '卖场类型',
          name: 'marketType'
        },
        {
          title: '渠道性质',
          name: 'shopType',
          search: {
            transform(e) {
              return e
                ? {
                    shopTypeCode: e
                  }
                : {}
            }
          },
          renderFormItem: renderFormItem((_, context) => {
            return (
              <CommonSelect
                attrs={context.$attrs}
                on={context.$listeners}
                label="渠道性质"
                showLabel={false}
                type="store_type"
              />
            )
          })
        },
        {
          title: '渠道性质二级分类',
          name: 'shopTypeValue',
          search: {
            transform(e) {
              return e
                ? {
                    shopTypeValueCode: e
                  }
                : {}
            }
          },
          renderFormItem: renderFormItem((_, context) => {
            return (
              <CommonSelect
                attrs={context.$attrs}
                on={context.$listeners}
                label="渠道性质二级分类"
                showLabel={false}
                type="secondary_store_type"
                getDataListParams={{ parentType: 'store_type' }}
                parent-code={context.$attrs.params.shopType}
              />
            )
          })
        },
        {
          title: '授权城市',
          name: 'explainJson'
        },
        {
          title: '生/失效',
          name: 'ifDelete',
          valueEnum: [
            { label: '生效', value: 0 },
            { label: '失效', value: 1 }
          ]
        },
        { title: '更新人', name: 'updateName' },
        {
          title: '操作',
          width: 100,
          render: render((h, { data, index }) => {
            return (
              <div>
                <el-button
                  style={{ marginRight: '10px' }}
                  type="text"
                  onClick={() => {
                    data.ifDelete = data.ifDelete ? 0 : 1
                    this.handleUpdate(data)
                  }}>
                  {data.ifDelete ? '生效' : '失效'}
                </el-button>
                <PopupForm
                  ref="popupFormRef"
                  popup-type="dialog"
                  width="450px"
                  title="修改"
                  columns={this.formColumns}
                  attrs={{
                    onSubmit: this.onSubmit
                  }}
                  scopedSlots={{
                    trigger: ({ onShow }) => {
                      return (
                        <el-button
                          type="text"
                          onClick={() =>
                            onShow({
                              ...data,
                              updateName: this.userInfo.realName
                            })
                          }>
                          修改
                        </el-button>
                      )
                    }
                  }}></PopupForm>
              </div>
            )
          })
        }
      ],
      formColumns: [
        {
          title: '渠道性质',
          name: 'shopTypeCode',
          renderFormItem: renderFormItem(
            (_, context) => {
              return (
                <CommonSelect
                  attrs={context.$attrs}
                  on={context.$listeners}
                  label="渠道性质"
                  showLabel={false}
                  type="store_type"
                />
              )
            },
            {
              required: true
            }
          )
        },
        {
          title: '渠道性质二级分类',
          name: 'shopTypeValue',
          // transform(e) {
          //   console.log(e)
          //   e
          //     ? {
          //         parentId: e.id,
          //         parentCode: e.code
          //       }
          //     : {}
          // },
          renderFormItem: renderFormItem(
            (_, context) => {
              return (
                <CommonSelect
                  attrs={context.$attrs}
                  on={context.$listeners}
                  label="渠道性质二级分类"
                  showLabel={false}
                  type="secondary_store_type"
                  value-key="content"
                  getDataListParams={{ parentType: 'store_type' }}
                  parent-code={context.$attrs.params.shopTypeCode}
                  disabled={!context.$attrs.params.shopTypeCode}
                  onChangeSelectedItem={e => {
                    context.$attrs.params.parentId = e ? e.id : ''
                    context.$attrs.params.parentCode = e ? e.code : ''
                  }}
                />
              )
            },
            {
              required: true
            }
          )
        },
        {
          title: '卖场名称',
          name: 'marketName',
          fieldProps: {
            required: true
          },
          transform(e) {
            return e
              ? {
                  content: e
                }
              : {}
          }
          // renderFormItem: renderFormItem(
          //   (_, context) => {
          //     return (
          //       <CommonSelect
          //         attrs={context.$attrs}
          //         on={context.$listeners}
          //         label="卖场名称"
          //         showLabel={false}
          //         type="dealer_shop.market_name"
          //         value-key="content"
          //         disabled={!context.$attrs.params.shopTypeCode}
          //         onChangeSelectedItem={e => {
          //           context.$attrs.params.typeDetail =
          //             context.$attrs.params.marketType = e ? e.typeDetail : ''
          //           context.$attrs.params.content = e ? e.content : ''
          //         }}
          //       />
          //     )
          //   },
          //   {
          //     required: true
          //   }
          // )
        },
        {
          title: '卖场类型',
          name: 'marketType',
          transform(e) {
            return e
              ? {
                  typeDetail: e
                }
              : {}
          },
          renderFormItem: renderFormItem((_, context) => {
            return (
              <CommonSelect
                attrs={context.$attrs}
                on={context.$listeners}
                label="卖场类型"
                showLabel={false}
                type="dealer_shop.market_type"
                value-key="content"
              />
            )
          })
        },
        {
          title: '授权城市',
          name: 'explainJson',
          renderFormItem: renderFormItem((_, context) => {
            return (
              <SelectPro
                attrs={context.$attrs}
                on={context.$listeners}
                label="授权城市"
                showLabel={false}
                label-key="authorizedCityName"
                valueKey="authorizedCityName"
                filterable={true}
                remote={true}
                clearable={true}
                getDataList={this.getAuthorizedCity}
              />
            )
          })
        },
        {
          title: '更新人',
          name: 'updateName',
          fieldProps: {
            readonly: true
          }
        }
      ]
    }
  },
  mounted() {
    this.userInfo = getStorage('userInfo')
  },
  methods: {
    async getDataList(params) {
      console.log(params)
      const res = await requestGetShopList(params)
      return res
    },
    async handleUpdate(data) {
      const valueData = deepCopy(data)
      valueData.updateName = this.userInfo.realName
      await update(valueData)
      this.$message.success('操作成功')
      this.$refs.tableRef.onRefreshTableList()
    },
    onSelect(rows) {
      this.selectList = rows
    },
    async handleChangeStatus(status) {
      await updateStatus({
        idsList: this.selectList.map(item => item.id),
        ifDelete: status
      })
      this.$message.success('操作成功')
      this.$refs.tableRef.onRefreshTableList()
    },
    async getAuthorizedCity(params) {
      const res = await getCityInfoData({
        currPage: params.page,
        addr: params.searchText
      })
      return res
    },
    async onSubmit(data) {
      data.createName = this.userInfo.realName
      data.module = 'distributor'
      data.type = 'dealer_shop.market_name'
      if (data.id) {
        await update(data)
        this.$refs.tableRef.onRefreshTableList()
      } else {
        await add(data)
        this.$refs.tableRef.onRefreshTableList(1)
      }
      this.$message.success('操作成功')
      return true
    }
  }
}
</script>

<style scoped lang="scss"></style>
