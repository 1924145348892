var render = function render(){var _vm=this,_c=_vm._self._c;return _c('required-form-item-select',_vm._g(_vm._b({on:{"changeSelectedItem":_vm.onChangeSelectedItem}},'required-form-item-select',{
    labelKey:'name',
    valueKey: 'id',
    disabledKey: 'ifDelete',
    multiple: true,
    clearable: true,
    filterDataList: _vm.onFilterDataList,
    getDataList: _vm.getBrandList,
    ..._vm.$attrs,
    ..._vm.selectProps
  },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }