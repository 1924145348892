import http from '@/utils/request'

/**
 * 保存经典经销权转让表单
 * @see http://10.12.0.64/docs/operate/operate-1eobak1cc068s
 */
export async function saveClassicDealershipTransfer(params) {
  const res = await http.post('/dealermanage/franchisechange/pushDraft', params)
  return res.data
}

/**
 * 获取经典经销权转让表单
 * @param {string} formId
 */
export async function getClassicDealershipTransfer(formId) {
  const res = await http.get('/dealermanage/declarationform/details', {
    params: {
      fromId: formId
    }
  })
  return res.data
}

/**
 * 获取售达方门店数
 * @see http://10.12.0.64/docs/operate/operate-1eoang6g30bbc
 * @param {object} params
 * @param {string} params.businessLicenseId
 * @param {string} [params.authorizedCityCode]
 * @param {string} [params.brandId]
 * @param {0|1} [params.shopStatus=0] (0:有效;1:失效)
 */
export async function getBusinessLicenseShopCount(params) {
  const res = await http('/dealermanage/shop/shopcount', {
    params: {
      shopStatus: 0,
      ...params
    }
  })
  return res.data?.count
}
