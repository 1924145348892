<template>
  <custom-upload-list
    ref="upload"
    :auto-upload="$attrs['auto-upload'] === true"
    multiple
    :action="$attrs['action'] || 'none'"
    :http-request="onRequest"
    :on-remove="onRemove"
    :on-change="onChange"
    :on-puase="onPuase"
    :before-upload="onBeforeUpload"
    v-bind="$attrs"
    class="upload-file"
    :on-exceed="onExceed"
    v-on="$listeners"
  >
    <template v-if="$attrs.disabled !== true">
      <el-button slot="trigger">选择文件</el-button>
      <el-button
        v-if="$attrs['auto-upload'] !== true"
        style="margin-left: 10px"
        type="primary"
        @click="onSubmitList"
      >
        开始上传
      </el-button>
      <div slot="tip" class="el-upload__tip">
        <slot name="tip" />
      </div>
    </template>
  </custom-upload-list>
</template>

<script>
import { uploadFile } from '@/utils/obsUpload'
import { aliUrlToFileObject } from '@/utils/upload'
import customUploadList from './upload/src/index'
let pauseCallback = null

const formatUploadedFileList = fileList => {
  return fileList
    .filter(item => item.url || item.status === 'success')
    .map(item => {
      return {
        url: item.response ? item.response.url : item.url,
        name: item.response ? item.response.name : item.name
      }
    })
}
const requestQueueList = {}
export default {
  components: {
    customUploadList
  },
  model: {},
  props: {
    limitSize: {
      type: [String, Number], // 单位: MB
      default: ''
    }
  },
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 第一次渲染将默认文件列表返回
      if (this.$refs.upload.fileList.length) {
        this.onFilterSuccess(this.$refs.upload.fileList)
      }
    })
  },
  methods: {
    onBeforeUpload(...rest) {
      // 限制文件大小
      if (this.limitSize) {
        const file = rest[0]
        if (file.size / 1024 / 1024 > this.limitSize) {
          this.$message.warning({
            message: `上传文件${file.name}大小超过${this.limitSize}MB`,
            duration: 5000
          })
          return false
        }
      }
      if (this.$attrs['before-upload']) {
        return this.$attrs['before-upload'](...rest)
      }
      return true
    },
    // 暂停
    onPuase(file = {}) {
      const currClient = requestQueueList[file.uid]
      if (currClient) {
        // 暂停上传
        currClient.cancel()
        if (pauseCallback) {
          // 返回Promise表示暂停需要等待接口请求完成
          return pauseCallback
        }
      }
    },
    onRequest({ file, onSuccess, onError, onProgress, ...rest }) {
      if (this.$attrs['http-request']) {
        return this.$attrs['http-request']
      }
      // 获取upload组件当前文件实例
      const wrapFile = this.$refs.upload.getFile(file)

      // 暂停完成回调函数
      let pauseCallbackExecutor = null
      pauseCallback = new Promise(resolve => {
        pauseCallbackExecutor = resolve
      })

      // 初始进度条
      onProgress({
        percent: requestQueueList[file.uid]
          ? requestQueueList[file.uid].percent
          : 0
      })
      ;(requestQueueList[file.uid]
        ? requestQueueList[file.uid].resume
        : uploadFile)(
        file,
        {
          progress: p => {
            // 保存进度
            requestQueueList[file.uid].percent = p * 100
            onProgress({ percent: p * 100 })
          }
        },
        (cancel, resume) => {
          // 将实例方法传入栈内
          requestQueueList[file.uid] = {
            percent: requestQueueList[file.uid]
              ? requestQueueList[file.uid].percent
              : 0,
            cancel,
            resume
          }
        }
      )
        .then(result => {
          const requestUrl = result.res.requestUrls[0]
          onSuccess({
            ...aliUrlToFileObject(requestUrl, file.name),
            originalName: file.name,
            // 去掉uploadId
            url: requestUrl.split('?')[0]
          })
        })
        .catch(() => {
          const fileRow = wrapFile || {}
          if (fileRow.status === 'uploading') {
            onError(file)
          } else if (fileRow.status === 'pausing') {
            // 暂停完成回调
            pauseCallbackExecutor()
          }
        })
        .finally(() => {
          // 完成上传,清空上传栈
          if (requestQueueList[file.uid]?.percent >= 100) {
            delete requestQueueList[file.uid]
          }
        })
    },
    onSubmitList() {
      this.$refs.upload.submit()
    },
    // 筛选上传成功 and 默认文件列表
    onFilterSuccess(fileList) {
      const uploadedFlatList = fileList
        .filter(item => item.url || item.status === 'success')
        .map(item => {
          const name = item.response ? item.response.originalName : item.name
          return {
            url: item.response ? item.response.url : item.url,
            name: name,
            response: item.response
              ? item.response
              : aliUrlToFileObject(item.url, name),
            is_uploaded: !item.response // true表示由file-list指定的默认文件列表
          }
        })
      this.$emit('onUploaded', uploadedFlatList)
    },
    onExceed(...rest) {
      if (this.$attrs['on-exceed']) {
        return this.$attrs['on-exceed'](...rest)
      }
      this.$message.warning(`当前限制选择${this.$attrs.limit}个文件`)
    },
    onRemove(...rest) {
      if (this.$attrs['on-remove']) {
        this.$attrs['on-remove'](...rest)
      }

      // 返回已经上传的文件列表
      this.onFilterSuccess(rest[1])
    },
    onChange(...rest) {
      if (this.$attrs['on-change']) {
        this.$attrs['on-change'](...rest)
      }
      // 返回已经上传的文件列表
      this.onFilterSuccess(rest[1])
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-file[disabled='disabled'] {
  ::v-deep {
    .el-upload {
      display: none;
    }
    .el-upload-list__item-status-label {
      display: none !important;
    }
    .progress {
      display: none !important;
    }
  }
}
</style>
