import http from "../../utils/request";

// 获取业务部门
export const businessdivisionList = () => {
  return http({
    // baseURL: "",
    url: "/dealermanage/businessdivision/list"
  });
};
// 提交
export const submit = data => {
  return http({
    // baseURL: "",
    url: "/dealermanage/operationflow/channelJoinApi/submit",
    data,
    method: "POST"
  });
};
// 保存到草稿
export const saveDraft = data => {
  return http({
    // baseURL: "",
    url: "/dealermanage/operationflow/channelJoinApi/saveDraft",
    data,
    method: "POST"
  });
};

// 详情
export const detail = params => {
  return http({
    // baseURL: "",
    url: "/dealermanage/operationflow/channelJoinApi/getDetail",
    params
  });
};
