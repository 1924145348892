import {
  getProvinceOrCityOrAreaList
} from "@/api/common/";
import {
  Message
} from 'element-ui'
export const common = {
  methods: {
    //   保留两位小数
    oninput(num) {
      var str = num
      var len1 = str.substr(0, 1)
      var len2 = str.substr(1, 1)
      // 如果第一位是0，第二位不是点，就用数字把点替换掉
      // eslint-disable-next-line eqeqeq
      if (str.length > 1 && len1 == 0 && len2 != '.') {
        str = str.substr(1, 1)
      }
      // 第一位不能是.
      // eslint-disable-next-line eqeqeq
      if (len1 == '.') {
        str = ''
      }
      // 限制只能输入一个小数点
      if (str.indexOf('.') !== -1) {
        var str_ = str.substr(str.indexOf('.') + 1)
        if (str_.indexOf('.') !== -1) {
          str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
        }
      }
      // 正则替换
      str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
      // console.log(str.);
      var reg = /\.\d\d\d$/
      if (reg.test(str)) {
        str = str.substr(0, str.length - 1)
      } // 小数点后只能输两位
      return str
    },
    getAuthCityList({
      getDataListParams: province
    }) {
      if (!province) return [];
      return getProvinceOrCityOrAreaList({
        type: "city",
        province
      }).then(({
        data
      }) => data);
    },
    getAuthAreaList({
      getDataListParams: dataString
    }) {
      const [province, city] = dataString.split(",");
      if (!province || !city) return [];
      return getProvinceOrCityOrAreaList({
        type: "area",
        province,
        city
      }).then(({
        data
      }) => data);
    },
    regIsCard(rule, value, callback) {
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      const f = reg.test(value);
      if (!f) {
        callback(new Error("身份证有误"))
      }else {
        callback()
      }
    },
    regCheckPhone(rule, value, callback) {
      // if (!(/^1[3456789]d{9}$/.test(value))) {
        // Message.error("手机号码有误，请检查是否填写正确");
        // return false;
      // }
      const reg = new RegExp(/^1[3456789]\d{9}$/)
      if(reg.test(value)) {
        callback()
      }else {
        callback(new Error("手机号码有误"))
      }
    },
    // 非零
    regNonzero(rule,value,callback) {
      if(!value || value === '0' || value === 0) {
        callback(new Error('必须大于0'))
      }else {
        callback()
      }
    }
  },

}
