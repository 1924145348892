<template>
  <div>
    <el-tabs v-model="typeManage" @tab-click="getRoleDataList">
      <el-tab-pane label="订单专员" name="0">
        <div>
          <el-form :inline="true" :model="form" class="demo-form-inline">
            <!-- <el-form-item label="工号：">
              <el-input
                v-model="form.attacheNumber"
                placeholder="请输入工号"
                @keydown.enter.native="query"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="订单专员：">
              <el-input
                v-model="form.attache"
                placeholder="请输入姓名/工号"
                @keydown.enter.native="query"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="query">查询</el-button>
            </el-form-item>
          </el-form>
          <div>
            <!-- <div>
              <el-radio-group
                v-model="typeManage"
                style="margin-bottom:10px"
                @change="getRoleDataList"
              >
                <el-radio label="1" border>订单主管</el-radio>
                <el-radio label="0" border>订单专员</el-radio>
              </el-radio-group>
            </div> -->
            <el-button @click="add"
              >新增订单{{ typeManage === '1' ? '主管' : '专员' }}</el-button
            >
            <el-button
              type="warning"
              :disabled="rowId.length !== 1"
              @click="edit"
              >修改订单{{ typeManage === '1' ? '主管' : '专员' }}</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            ref="table_attr"
            :data="tableData"
            border
            style="width: 100%;margin-top:20px"
            @row-click="rowClick"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column
              prop="bossNumber"
              label="订单主管工号"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="realName"
              label="订单主管"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column prop="name" label="角色" align="center">
            </el-table-column>
            <el-table-column
              prop="attacheNumber"
              label="订单专员工号"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="attacheName"
              label="订单专员"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column prop="attacheRoleName" label="角色" align="center">
            </el-table-column>
            <el-table-column prop="dealerStatus" label="状态" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.dealerStatus === 0 ? '生效' : '失效' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="updateTime"
              label="更新时间"
              align="center"
              width="230"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="订单主管" name="1">
        <div ref="form">
          <el-form :inline="true" :model="form" class="demo-form-inline">
            <!-- <el-form-item label="工号：" >
              <el-input
                v-model="form.bossNumber"
                placeholder="请输入工号"
                @keydown.enter.native="query"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="订单主管：">
              <el-input
                v-model="form.inCharge"
                placeholder="请输入姓名/工号"
                @keydown.enter.native="query"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="query">查询</el-button>
            </el-form-item>
          </el-form>
          <div>
            <!-- <div>
              <el-radio-group
                v-model="typeManage"
                style="margin-bottom:10px"
                @change="getRoleDataList"
              >
                <el-radio label="1" border>订单主管</el-radio>
                <el-radio label="0" border>订单专员</el-radio>
              </el-radio-group>
            </div> -->
            <el-button @click="add"
              >新增订单{{ typeManage === '1' ? '主管' : '专员' }}</el-button
            >
            <el-button
              type="warning"
              :disabled="rowId.length !== 1"
              @click="edit"
              >修改订单{{ typeManage === '1' ? '主管' : '专员' }}</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            ref="table"
            :data="tableData"
            border
            style="width: 100%;margin-top:20px"
            @row-click="rowClick"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column
              prop="bossNumber"
              label="订单主管工号"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="realName" label="订单主管" align="center">
            </el-table-column>
            <el-table-column prop="name" label="角色" align="center">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.status === 0 ? '生效' : '失效' }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="pagination">
      <el-pagination
        background
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="pageSize || 10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="currentChange"
      />
    </div>
    <el-dialog :visible.sync="dialogVisible" width="375px">
      <el-form
        :model="formData"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        @validate="handleValidate"
      >
        <el-form-item
          label="人员："
          prop="bossNumber"
          :rules="{
            required: true,
            message: '请选择人员',
            trigger: 'change'
          }"
        >
          <!-- <el-input v-model="formData.realName" /> -->
          <RequiredFormItemSelect
            :title="formData.realName"
            v-model="formData.bossNumber"
            :dataList="userList"
            :getDataList="getAsyncUserDataList"
            :getNextList="getAsyncUserDataList"
            labelKey="real_name"
            valueKey="code"
            :totalCount="userTotalCount"
            :selectProps="{
              filterable: true,
              remote: true,
              clearable: true,
              'popper-class': 'custom-business-select',
              disabled: !!formData.id
            }"
            @changeSelectedItem="
              e => {
                formData.realName = e ? e.real_name : ''
              }
            "
            @onOptionsListNotFound="onOptionsListNotFound"
          >
            <template v-slot="{ scope }">
              <span>{{ scope.row.real_name }}（{{ scope.row.code }}）</span>
              <!-- <small>{{ scope.row.realName }}</small> -->
            </template>
          </RequiredFormItemSelect>
        </el-form-item>
        <el-form-item
          label="角色："
          prop="dealerRoleId"
          :rules="{
            required: true,
            message: '请选择角色',
            trigger: 'change'
          }"
        >
          <RequiredFormItemSelect
            :title="formData.name"
            v-model="formData.dealerRoleId"
            :dataList="roleList"
            :getDataList="getAsyncRoleDataList"
            :getNextList="getAsyncRoleDataList"
            labelKey="name"
            valueKey="id"
            :totalCount="totalCount"
            :selectProps="{
              filterable: true,
              remote: true,
              clearable: true,
              'popper-class': 'custom-business-select'
            }"
            @changeSelectedItem="
              e => {
                formData.name = e ? e.name : ''
              }
            "
            @onOptionsListNotFound="onRoleOptionsListNotFound"
          />
        </el-form-item>
        <el-form-item label="生效/失效：" prop="status">
          <el-tooltip
            :content="formData.status === 0 ? '生效' : '失效'"
            placement="top"
          >
            <el-switch
              v-model="formData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="0"
              :inactive-value="1"
            >
            </el-switch>
            </el-switch>
          </el-tooltip>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveOrUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  attacheDataList,
  roleDataList,
  userDataList,
  saveMessage,
  attacheDetail
} from '@/api/commonConfig/orderSpecialist'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import { tableHeight } from '@/mixin/tableHeight'
export default {
  mixins: [tableHeight],
  components: {
    RequiredFormItemSelect
  },
  data() {
    return {
      form: {},
      tableData: [],
      rowId: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      formData: {
        status:0
      },
      typeManage: '0',
      roleList: [], // 角色列表
      totalCount: 0, // 角色列表总数
      userList: [], // 用户列表
      userTotalCount: 0
    }
  },
  created() {
    this.getDataList()
    this.getRoleDataList()
    this.getUserDataList()
  },
  methods: {
    async getDataList() {
      const res = await attacheDataList({
        currPage: this.currentPage,
        pageSize: this.pageSize,
        ...this.form,
        type: this.typeManage
      })
      const { data, totalCount, code } = res
      if (code === 0) {
        this.tableData = data
        this.total = totalCount
      }
      // console.log(res);
    },
    // async getPartList() {
    //   const res = await partList({
    //     currPage: this.currentPage,
    //     pageSize: this.pageSize,
    //     type: 1
    //   })
    //   this.partTableData = res.data
    // },
    // 获取角色列表
    async getRoleDataList() {
      this.roleList = []
      this.currentPage = 1
      this.pageSize = 10
      this.form = {}
      this.rowId = []
      this.getDataList()
      const res = await roleDataList({
        roleTypeCode: this.typeManage === '1' ? 15 : 16,
        currPage: 1,
        pageSize: 10
      })
      if (res.code === 0) {
        this.roleList = res.data
        this.totalCount = res.totalCount
      }
    },
    // 获取角色列表分页
    async getAsyncRoleDataList({ searchText, page }) {
      const res = await roleDataList({
        name: searchText,
        roleTypeCode: this.typeManage === '1' ? 15 : 16,
        currPage: page || 1,
        pageSize: 10
      })
      if (res.code === 0) {
        // this.businessList = [...this.businessList, ...res.data]
        if (res.currPage == 1) {
          this.roleList = [...res.data]
        } else if (res.currPage > 1) {
          this.roleList = [...this.roleList, ...res.data]
        }
        return res.data
      }
    },
    // 编辑角色
    async onRoleOptionsListNotFound({ value, getDataListParams }, callback) {
      // console.log(this.typeManage === '1'
      //       ? this.formData.name
      //       : this.formData.attacheRoleName)
      const res = await roleDataList({
        name: this.formData.name,
        roleTypeCode: this.typeManage === '1' ? 15 : 16,
        currPage: 1,
        pageSize: 10
      })
      // console.log(res);
      callback(res.data)
    },
    // 编辑用户
    async onOptionsListNotFound({ value, getDataListParams }, callback) {
      const res = await userDataList({
        code: value,
        sourceNum: 20,
        loginType: 10,
        currPage: 1,
        pageSize: 10
      })
      callback(res.data)
    },
    // 获取用户列表
    async getUserDataList() {
      const res = await userDataList({
        sourceNum: 20,
        loginType: 10,
        currPage: 1,
        pageSize: 10
      })
      if (res.code === 0) {
        this.userList = res.data
        this.userTotalCount = res.totalCount
      }
    },
    async getAsyncUserDataList({ searchText, page }) {
      const isNum = new RegExp('^[0-9]*$').test(searchText)
      const res = await userDataList({
        sourceNum: 20,
        loginType: 10,
        currPage: page || 1,
        pageSize: 10,
        realName: !isNum ? searchText : null,
        code: isNum ? searchText : null
      })
      if (res.code === 0) {
        // this.businessList = [...this.businessList, ...res.data]
        if (res.currPage == 1) {
          this.userList = [...res.data]
        } else if (res.currPage > 1) {
          this.userList = [...this.userList, ...res.data]
        }
        return res.data
      }
    },
    query() {
      this.currentPage = 1
      this.getDataList()
    },
    // 新增订单专员
    add() {
      this.formData = {
        status:0
      }
      // this.formData.status = 0
      this.dialogVisible = true
    },
    async edit() {
      const res = await attacheDetail({
        id: this.rowId[0],
        type: this.typeManage
      })
      // console.log(res);
      this.dialogVisible = true
      if (res.code === 0) {
        // console.log(res.data)
        this.formData = res.data
      }
    },
    // 保存
    saveOrUpdate() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          // console.log(this.formData);
          this.formData.type = this.typeManage
          const data = {}
          for (let key in this.formData) {
            if (key === 'name') {
              const keyWord =
                this.typeManage === '1' ? 'name' : 'attacheRoleName'
              data[keyWord] = this.formData[key]
              continue
            }
            data[key] = this.formData[key]
          }
          const url = this.formData.id
            ? '/dealermanage/boss/updateStaff'
            : '/dealermanage/boss/addStaff'
          // console.log(data);
          const res = await saveMessage(url, data)
          // console.log(res)
          if (res.code === 0) {
            this.dialogVisible = false
            this.$message.success('保存成功')
            this.getDataList()
          }
        }
      })
    },
    handleValidate(_, flag, message) {
      if (!flag) {
        this.$message.warning(message)
        // this.flagVisable = false
      }
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return this.typeManage === '1' ? item.roleId : item.attacheRoleId
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      const key = this.typeManage === '1' ? 'table' : 'table_attr'
      this.$refs[key].toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
