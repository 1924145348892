import request from "@/utils/request";
// 草稿箱
export function getList(data) {
  return request({
    // url: "/dealermanage/declarationform/listpage", // 旧
    url: '/dealermanage/declarationform/getDraftPage',
    method: "get",
    params:data
  })
}
// 流程信息列表
export function getFlowList(){
  return request({
    url: "/dealermanage/datadictionary/list",
    method: "get",
    params: {
      type: 'flow'
    }
  })
}

// 我的申请列表
export function getApplyList(data){
  return request({
    // url: "dealermanage/declarationform/listpage", // 旧
    url: '/dealermanage/declarationform/getApplyPage',
    method: "get",
    params: data
  })
}

// 我的申请子列表
export function getShopList(data){
  return request({
    url: '/dealermanage/declarationform/list',
    method: "get",
    params: data    
  })
}
export function getShopListPage(data){
  return request({
    url: '/dealermanage/declarationform/listpage',
    method: "get",
    params: data    
  })
}

// 发起提交
export function addSumbit(data){
  return request({
    url: '/dealermanage/declarationform/andReload',
    method: "get",
    params: data    
  })  
}

// 草稿箱删除
export function deleteList(data){
  return request({
    url: 'dealermanage/declarationform/delete',
    method: "post",
    data: data    
  })
}

