<template>
  <fragment>
    <div class="CommonPopup">
      <component
        :is="popupType === 'dialog' ? 'el-dialog' : 'el-drawer'"
        :visible="visible"
        append-to-body
        v-bind="{
          class: popupClassName,
          title: displayTitle,
          ...popupProps,

          ...(popupType === 'dialog' ? dialogProps : drawerProps),
          ...$attrs,
          ...(popupType === 'dialog'
            ? { width: checkMobile ? '100%' : width }
            : { size: checkMobile ? '100%' : width })
        }"
        @close="onHide"
        v-on="{
          ...popupListeners,
          ...(popupType === 'dialog' ? dialogListeners : drawerListeners),
          ...$listeners
        }"
      >
        <slot :data="data" slot="title" name="title" />
        <div
          v-if="!destroySlotOnClose || (destroySlotOnClose && visible)"
          :class="{ 'app-container': popupType === 'drawer' }"
        >
          <slot :data="data" :on-show="onShow" :on-hide="onHide" :on-close="onClose" />
        </div>
      </component>
    </div>
    <slot name="trigger" :on-show="onShow" />
  </fragment>
</template>

<script>
import CommonForm from './CommonForm.vue'
import { isMobile } from './fn'

export default {
  components: {
    CommonForm
  },
  props: {
    title: { type: String, default: '' },
    width: { type: [String, Number], default: null },
    popupType: {
      type: String,
      // Dialog Drawer
      default: 'dialog'
    },
    dialogProps: {
      type: Object,
      default: () => ({})
    },
    dialogListeners: {
      type: Object,
      default: () => ({})
    },
    drawerProps: {
      type: Object,
      default: () => ({})
    },
    drawerListeners: {
      type: Object,
      default: () => ({})
    },
    popupClassName: {
      type: String,
      default: 'popup-form-window'
    },
    // 不想区分dialog与drawer参数，直接传popup
    popupProps: {
      type: Object,
      default: () => ({})
    },
    popupListeners: {
      type: Object,
      default: () => ({})
    },
    // 关闭时销毁插槽
    destroySlotOnClose: {
      type: Boolean,
      default: false
    },
    // 关闭时清空data数据
    clearDataOnClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      displayTitle: '',
      data: null
    }
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        this.displayTitle = this.title
      }
    },
    visible() {
      this.$emit(this.visible ? 'show' : 'hide')
    }
  },
  computed: {
    checkMobile() {
      return isMobile()
    }
  },
  methods: {
    onClose() {
      this.visible = false
      this.data = null
    },
    onHide() {
      if (this.clearDataOnClose) {
        this.data = null
      }
      this.visible = false
    },
    onShow(data = null, callback = () => {}, options = {}) {
      this.data = data
      if (options.title) {
        this.displayTitle = options.title
      }
      this.visible = true
      return new Promise((resolve) => {
        const watchEvent = this.$watch('visible', (flag) => {
          if (!flag) {
            resolve()
            if (typeof callback === 'function') {
              callback()
            }
            watchEvent()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.CommonPopup {
  position: fixed;
}
.popup-form-window {
  .el-drawer__body {
    padding: 0;
  }
}
</style>
