<script setup>
import { ref, computed } from 'vue'
import 'element-ui/lib/theme-chalk/display.css'
import StoreRenovationBaseForm from './components/StoreRenovationBaseForm.vue'
import { getStorage } from '@/utils/storage'
import HeaderTitle from '@/views/flow/components/headerTitle'
import FlowForm from './components/flowForm'
import {
  saveStoreLoadingMaterialOrder,
  submitStoreLoadingMaterialOrder,
  submitStoreLoadingMaterialOrderApproveData,
  getStoreLoadingMaterialOrderDetail
} from './api/classicalStoreChangeFlow'
import { useRoute, useRouter } from '@/utils/router'

const route = useRoute()
const decimalsRule = {
  trigger: 'blur',
  message: '只能填写数字并且最多两位小数',
  validator: (rule, value, callback) => {
    // console.log('validator', rule)
    const numberRegex = /^\d+(\.\d{1,2})?$/
    if (value != '' && !numberRegex.test(value)) {
      callback(rule.message)
    } else {
      callback()
    }
  }
}
const integerRule = {
  message: '必须是整数，不能为0',
  validator: (rule, value, callback) => {
    if (value != '' && !/^[1-9]\d*$/.test(value)) {
      callback(rule.message)
    } else {
      callback()
    }
  }
}
const approvalDataRules = ref({
  placementOrderFinalAmount: [decimalsRule]
})
const baseKeys = {
  title: '标题',
  orderNumber: '单号',
  zsOrderNumber: '招商单号',
  linkOrderNumber: '门店设计流程单号',
  storeManageProcessName: '门店管理流程',
  storeManageProcessCode: '门店管理流程单号',
  optJobNumber: '提单人工号',
  optUserName: '提单人',
  optDeptName: '提单人部门',
  businessLicenseName: '售达方',
  bossName: '经销商（实际控股人）',
  bossContactInfo: '经销商联系方式',
  bossNumber: '经销商账号',
  authorizedCityName: '授权城市',
  marketLevelName: '城市等级',
  province: '省份',
  city: '地级市',
  area: '区/县',
  marketCenterName: '营销中心',
  designBrandNames: '门店设计品牌',
  oldBrandNames: '已代理品牌'
}

const shopKeys = {
  parentShopNumber: '物理门店编号',
  shopNumber: '门店编号',
  createShopType: '开店类型',
  storeOpeningMode: '开店模式',
  contactPerson: '门店负责人',
  contactPersonPhone: '门店负责人联系方式',
  renovationLiablePerson: '装修对接人',
  renovationPersonPhone: '装修对接人电话',
  decorationArea: '装修面积',
  adjustedStoreArea: '调整后门店面积',
  marketName: '卖场名称',
  marketType: '卖场类型',
  shopAddress: '门店地址',
  shopPositionLevel: '门店位置等级',
  storeNature: '店态性质',
  managementModel: '经营模式'
}
const fdFormTemplateCode = 'distributor-JXS032'

const flowFormRef = ref(null)
const flowData = ref({
  linkOrderNumber: '',
  beforeRenovationShop: {},
  approveData: {
    orderSpecialist: '',
    designPlanUrls: []
  }
})
const approveData = ref({})

const flowId = computed(() => {
  const { id, businessId } = route.query
  return id || businessId
})
const isApprove = computed(() => !!route.query.processId)
async function getDetail(type, cachedFormData) {
  if (type != 'detail') {
    flowData.value = {
      ...flowData.value,
      ...(cachedFormData || {})
    }
    return
  }
  const { data } = await getStoreLoadingMaterialOrderDetail({
    formId: flowId.value
  })
  flowData.value = {
    beforeRenovationShop: data.shopData,
    ...data
  }
  approveData.value = {
    ...data.approveData
  }
}
function getOptUserInfo() {
  const userInfo = getStorage('userInfo')
  return {
    optUserId: userInfo.accountId, //提单人ID【必须】',
    optUserName: userInfo.realName, //提单人姓名【必须】,
    optJobNumber: userInfo.code || userInfo.account, //提单人工号【必须】
    optDeptName: userInfo.deptName,
    optDeptId: userInfo.deptId
  }
}
function onApproval(bpmExecuteBean, bpmDealerJoinApproveModel) {
  const { optUserId, optUserName, optJobNumber } = getOptUserInfo()

  const formData = {
    id: flowId.value,
    optUserId, //提单人ID【必须】',
    optUserName, //提单人姓名【必须】,
    optJobNumber, //提单人工号【必须】
    applyOrderModel: {
      approveAccount: optJobNumber, //审批人工号   [必填]',
      businessId: flowId.value,
      processInstanceId: route.query.processId,
      systemCode: 'distributor', //来源系统  [必传],
      fdFormTemplateCode, //模板/流程编码,
      pageVersion: 'V1', //页面版本 当前流程使用的页面版本  [必传]',
      bpmExecuteBean
    },
    approveData: bpmDealerJoinApproveModel
  }

  if (Number(flowData.value?.status ?? 0) === 20) {
    flowData.value = {
      ...flowData.value,
      applyOrderModel: formData.applyOrderModel,
      approveData: bpmDealerJoinApproveModel
    }
    return flowFormRef.value?.onCommit('submit')
  }

  return submitStoreLoadingMaterialOrderApproveData(formData)
}

function onSubmit(type) {
  const { beforeRenovationShop, ...form } = flowData.value
  return type !== 'submit'
    ? saveStoreLoadingMaterialOrder(form)
    : submitStoreLoadingMaterialOrder(form)
}
</script>

<template>
  <FlowForm
    ref="flowFormRef"
    :fd-form-template-code="fdFormTemplateCode"
    :approval-data-rules="approvalDataRules"
    :model="flowData"
    :is-edit="[0, 20].includes(Number(flowData?.declarationForm?.status))"
    :showSave="false"
    :approval-data="approveData"
    :on-submit="onSubmit"
    :on-approval="onApproval"
    :get-detail="getDetail"
    submitButtonText="重新发起流程"
    title="慕思经典-店装物料下单流程">
    <StoreRenovationBaseForm
      disabled
      :model-value.sync="flowData"
      :base-keys="baseKeys"
      :shop-keys="shopKeys" />

    <HeaderTitle v-if="isApprove" style="margin: 20px 0" title="店装物料信息" />
  </FlowForm>
</template>
