import Vue from 'vue'
import { Loading } from 'element-ui'

// 临时关闭vue警告
export const ignoreVueWarn = async(fn) => {
  Vue.config.silent = true
  await fn()
  Vue.config.silent = false
}

const bindListener = (el, binding, vnode) => {
  if (el._loadingClickListener) {
    el.removeEventListener('click', el._loadingClickListener)
  }
  el._loadingClickListener = async(event) => {
    event.stopPropagation()
    if (el._loadingClickFunctionInstance) {
      return el._loadingClickFunctionInstance
    }
    const tempLoading = Loading.service({
      target: el,
      text: '',
      spinner: 'el-icon-loading',
      customClass: 'loadingClick-loading'
    })
    try {
      el._loadingClickFunctionInstance = binding.value
      await el._loadingClickFunctionInstance()
    } finally {
      el._loadingClickFunctionInstance = null
      tempLoading.close()
    }
  }

  el.addEventListener('click', el._loadingClickListener)
}

export default { install() {
  Vue.directive('loading-click', {
    bind: bindListener,
    update: bindListener,
    unbind(el) {
      if (el._loadingClickListener) {
        el.removeEventListener('click', el._loadingClickListener)
      }
    }
  })
} }

