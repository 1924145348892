// 华为对象存储服务
import { getStorage, setStorage } from '@/utils/storage' // get token from cookie
import { getObsInfo } from '@/api/common/index'
const OBS_CONFIG_STORAGE = 'obsInfoJSON'

const hostReg = /^http(s)?:\/\/(.*?)\//

const fillString = (str = '', length = 2, fillValue = '0', isBefore = true) => {
  if (String(str).length < length) {
    const count = length - String(str).length
    const fillValues = Array(count).fill(fillValue)
    if (isBefore) {
      return fillValues.join('') + String(str)
    } else {
      return String(str) + fillValues.join('')
    }
  }
  return `${str}`
}

const getFormatDate = () => {
  const date = new Date()
  return `${date.getFullYear()}${fillString(date.getMonth() + 1)}${fillString(date.getDate())}`
}

export const getObsConfig = async() => {
  const obsInfo = getStorage(OBS_CONFIG_STORAGE)
  if (obsInfo && obsInfo.credential && new Date() < new Date(obsInfo.credential.expiresAt)) {
    return obsInfo
  } else {
    return getObsInfo().then((res) => {
      setStorage(OBS_CONFIG_STORAGE, res.data)
      return res.data
    })
  }
}

// 通过url解析文件信息
export const aliUrlToFileObject = (url, originalName = '') => {
  const baseUrl = url.match(hostReg)[0]
  let fileDir = url.replace(hostReg, '')
  fileDir = fileDir.split('?')[0]
  const fileSlice = fileDir.split('.')
  const fileDirSlice = fileDir.split('/')
  const ext = fileSlice.length > 1 ? fileSlice[fileSlice.length - 1] : ''
  return {
    fileName: originalName || fileDirSlice[fileDirSlice.length - 1],
    requestDomainName: baseUrl,
    fileType: ext,
    requestPath: fileDir
  }
}

function initFileObj(originalName) {
  // 给文件名添加时间戳
  const nameArr = originalName.split('.')
  let ext = ''
  if (nameArr.length > 1) {
    ext = nameArr.pop()
  }
  nameArr.push(`${Date.now()}`)
  let name = nameArr.join('.')
  try {
    name = window.btoa(encodeURIComponent(name))
  } catch {
    name = Math.random().toString().substr(3, 10) + Date.now()
  }
  return {
    name: ext ? `${name}.${ext}` : `${name}`,
    ext
  }
}

/**
 * 上传文件到华为云
 * @param {*} options 配置项
 */
export const uploadFile = async(file, uploadOptions = {}, callback) => {
  const obsConfig = await getObsConfig()
  const client = new ObsClient({
    access_key_id: obsConfig.credential.access,
    secret_access_key: obsConfig.credential.secret,
    security_token: obsConfig.credential.securitytoken,
    server: obsConfig.endPoint
  })

  const filePath = `cloud-derucci-system/${getFormatDate()}/${
    initFileObj(file.name).name
  }`

  if (Object.prototype.toString.call(callback) === '[object Function]') {
    callback(
      // 暂停,暂不支持
      () => {},
      // 续传,暂不支持
      () => {}
    )
  }

  return new Promise((resolve, reject) => {
    client.uploadFile({
      Bucket: obsConfig.bucketName,
      Key: filePath,
      SourceFile: file,
      PartSize: 9 * 1024 * 1024,
      ProgressCallback: (transferredAmount, totalAmount, totalSeconds) => {
        const complete = transferredAmount / totalAmount
        if (uploadOptions.progress) {
          uploadOptions.progress(complete)
        }
      }
    }, (error, res) => {
      if (error) {
        return reject(error)
      } else {
        resolve({
          res: {
            requestUrls: [`${obsConfig.bucketDomain}/${filePath}`]
          }
        })
      }
    })
  })
}
