<template>
  <vuedraggable
    v-model="innerFileList"
    draggable=".CommonUpload-File-Item"
    tag="div"
    class="CommonUpload-File-Picture-List-Container"
    @start="drag = true"
    @end="drag = false"
  >
    <transition-group name="list" tag="div" class="CommonUpload-File-List">
      <div
        v-for="(fileItem, fileIndex) of innerFileList"
        :key="fileItem.uid"
        class="CommonUpload-File-Item"
        :style="{ width: itemWidth, height: itemHeight }"
        :class="[fileItem.status]"
      >
        <slot name="preview-cover" :file="fileItem"> </slot>
        <span v-if="showFormat && !$slots['preview-cover']" class="CommonUpload-File-Preview">
          {{ getFileExtension(fileItem.name || fileItem.url) }}
        </span>
        <div v-if="fileItem.status === 'uploading'" class="CommonUpload-File-Upload-Progress">
          <span>文件上传中</span>
          <el-progress :percentage="fileItem.percent" :show-text="false" :stroke-width="2" />
        </div>
        <el-tooltip
          v-else
          effect="light"
          :content="typeof fileItem.response === 'string' ? fileItem.response : ''"
          :disabled="fileItem.status !== 'error' || !fileItem.response"
        >
          <div class="CommonUpload-File-Wrap">
            <div v-if="isVideo(fileItem)" class="CommonUpload-File video">
              <i class="el-icon-video-camera-solid"></i>
            </div>
            <img v-else class="CommonUpload-File" :src="fileItem.thumbUrl || fileItem.url" />
            <div class="CommonUpload-File-Actions">
              <i
                v-if="isVideo(fileItem)"
                class="el-icon-video-play"
                @click="onPlay(fileItem, fileIndex)"
              ></i>
              <i
                v-else
                class="el-icon-view"
                @mousedown.stop
                @click="onPreview(fileItem, fileIndex)"
              />
              <template v-if="!disabled">
                <i class="el-icon-delete" @mousedown.stop @click="$emit('delete', fileItem, fileIndex)" />
                <i class="el-icon-rank" />
              </template>
            </div>
          </div>
        </el-tooltip>
      </div>
      <div v-if="showUploadButton" key="upload-button">
        <slot />
      </div>
    </transition-group>
  </vuedraggable>
</template>

<script>
import vuedraggable from 'vuedraggable'
export default {
  components: { vuedraggable },
  model: {
    prop: 'fileList',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    itemWidth: {
      type: String,
      default: '104px'
    },
    itemHeight: {
      type: String,
      default: '104px'
    },
    fileList: {
      type: Array,
      default: () => []
    },
    showUploadButton: {
      type: Boolean,
      default: true
    },
    fit: {
      type: String,
      default: 'contain'
    },
    showFormat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drag: false
    }
  },
  computed: {
    innerFileList: {
      get({ fileList }) {
        return fileList
      },
      set(fileList) {
        this.$emit('input', fileList)
      }
    }
  },
  methods: {
    getFileExtension(webUrl) {
      if (!webUrl || webUrl == '') return ''
      return webUrl.match(/^(.*)(\.)(.{1,8})$/)[3].toLowerCase()
    },
    isVideo(file) {
      if (!file.url || file.url == 0 || file.url == '') return false
      var videoType = new Array(
        'avi',
        'wmv',
        'mpg',
        'mpeg',
        'mov',
        'rm',
        'ram',
        'swf',
        'flv',
        'mp4',
        'mp3',
        'wma',
        'avi',
        'rm',
        'rmvb',
        'flv',
        'mpg',
        'mkv'
      )
      const matchUrl = file?.url.match(/^(.*)(\.)(.{1,8})$/)
      if (!matchUrl || !matchUrl[3]) return false
      return videoType.includes(this.getFileExtension(file.url))
    },
    onPreview(file, index) {
      this.$emit('preview', file, index)
    },
    onPlay(file, index) {
      this.$emit('play', file, index)
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/colors';

.CommonUpload-File-Picture-List-Container {
  .CommonUpload-File-List {
    display: flex;
    flex-wrap: wrap;
    & > div:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
    & > .CommonUpload-File-Item {
      margin-bottom: 8px;
      border: 1px dashed #d9d9d9;
      border-radius: 2px;
      transition: border-color 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      transition: background-color 0.3s, opacity 0.3s, transform 0.3s;
      margin-right: 8px;
      position: relative;

      &.uploading {
        background-color: #fafafa;
      }
      &.error {
        border: 1px solid $danger-dark-color;
      }
      &.done {
        border: 1px solid #d9d9d9;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        .CommonUpload-File-Actions {
          .el-button--mini {
            opacity: 1;
          }
        }
      }
      .CommonUpload-File-Wrap {
        flex: 1;
        width: 100%;
        overflow: hidden;
        .CommonUpload-File-Actions {
          opacity: 0;
          transition: opacity 0.3s;
        }
        &:hover {
          .CommonUpload-File-Actions {
            opacity: 1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            [class^='el-icon'] {
              color: rgba(0, 0, 0, 0.8);
              font-size: 15px;
              cursor: pointer;
              background-color: rgba(255, 255, 255, 0.6);
              /* filter: blur(10px); */
              min-width: 48%;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                color: rgba(0, 0, 0, 1);
                background-color: rgba(255, 255, 255, 1);
              }
            }
            .el-icon-delete {
              color: rgba(255, 0, 0, 0.9);
              &:hover {
                color: rgba(255, 0, 0, 1);
              }
            }
            .el-icon-delete {
              margin-left: 1px;
            }
            .el-icon-thumb {
              margin-top: 1px;
            }
          }
        }
      }
      .CommonUpload-File {
        flex: auto;
        width: 100%;
        height: 100%;
        object-fit: v-bind(fit);
        &.video {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2em;
        }
      }
      &.error {
        color: $danger-dark-color;
        .CommonUpload-File-Actions {
          .el-button--mini {
            opacity: 1;
          }
        }
      }
    }
  }
}
.list-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.list-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.list-leave-active {
  position: absolute;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.CommonUpload-File-Preview {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  font-size: 12px;
  padding: 6px;
  overflow: hidden;
  color: #fff;
}
</style>
