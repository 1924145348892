import http from '@/utils/request'

// 售达方合作方

/**
 * 保存合作方流程草稿
 */
export async function requestDealerPartnerFlowDraft(params) {
  const res = await http({
    url: '/dealermanage/operationflow/dealerNewChannelReportApi/saveDraft',
    method: 'post',
    data: params
  })
  return res.data
}

/**
 * 保存合作方流程
 * @param {string} 表单 id
 */
export async function requestDealerPartnerFlowSave(id) {
  const formData = new FormData()
  formData.append('formId', id)
  const res = await http({
    url: '/dealermanage/operationflow/dealerNewChannelReportApi/submit',
    method: 'post',
    data: formData
  })
  return res
}

/**
 * 获取合作方报备流程详情
 * @param {string} id 表单 id
 */
export async function requestDealerPartnerFlow(id) {
  const formData = new FormData()
  formData.append('formId', id)
  const res = await http({
    url: '/dealermanage/operationflow/dealerNewChannelReportApi/getDetail',
    method: 'post',
    data: formData
  })
  return res.data
}

/**
 * 获取合作方详情
 * @param {string} code 执照代码
 * @param {string} senderNumber 送达方编号
 *  */
export async function requestDealerPartner(code,senderNumber) {
  const res = await http({
    url: '/dealermanage/operationflow/dealerNewChannelReportApi/getPartnerInfoByPartnerCreditCode',
    method: 'get',
    params: {
      partnerCreditCode: code,
      senderNumber
    }
  })
  return res.data
}
