<template>
  <div>
    <el-drawer
      title="修改详情"
      :visible.sync="storeVisibleDialog"
      direction="rtl"
      size="50%;overflow-y:auto">
      <div style="padding: 0 20px">
        <div class="demo-drawer__content">
          <el-form
            label-position="right"
            label-width="300px"
            :model="form"
            disabled
            style="padding-bottom: 60px"
            ref="form">
            <el-form-item prop="" label="下单权限">
              <el-switch
                disable
                :value="form.orderAuthority"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0">
              </el-switch>
              <div style="display: flex">
                <span>修改至</span>
                <span>{{
                  uploadForm.orderAuthority === '1' ? '生效' : '失效'
                }}</span>
              </div>
            </el-form-item>
            <el-form-item prop="" label="备注">
              <el-input
                type="textarea"
                rows="5"
                placeholder="备注"
                v-model="form.updateRemark" />
            </el-form-item>
            <!-- <el-form-item prop="" label="相关流程">
              <el-input placeholder="相关流程" v-model="form.aboutFlow" />
            </el-form-item> -->
          </el-form>
        </div>
        <div
          style="
            text-align: center;
            position: fixed;
            bottom: 0px;
            padding: 10px 0;
            width: 50%;
            background: #fff;
          ">
          <!-- <el-button type="primary" @click="submit">保存</el-button>
          <el-button @click="storeVisibleDialog = false">取消</el-button> -->
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    selectItem: {
      type: Object,
      default: () => ({})
    },
    updateDetailData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        orderAuthority: 0,
        updateRemark: ''
      },
      uploadForm: {},
      storeVisibleDialog: false
    }
  },
  watch: {
    selectItem(val) {
      for (const key in this.form) {
        this.form[key] = val[key] || ''
      }
    },
    updateDetailData(val) {
      if (val) {
        for (const key in val) {
          this.uploadForm[key] = val[key]
        }
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
:deep(.el-form) {
  .el-input__inner {
    width: 250px;
  }
}

:deep(.el-form-item__content) {
  display: flex;
  align-items: center;
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
  .el-textarea__inner {
    width: 250px;
  }
  .el-input {
    width: auto;
  }
  span {
    white-space: nowrap;
    padding: 0 10px;
  }
  span + span {
    color: red;
  }
}
</style>
