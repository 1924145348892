<template>
  <required-form-item
    ref="formItemRef"
    class="required-form-item-upload-file"
    v-bind="attrs.other"
    :disabled="isDisabled"
    v-on="$listeners">
    <div class="describe">
      <slot name="describe" />
    </div>
    <upload-file
      ref="uploadFileRef"
      show-preview-button
      :disabled="isDisabled"
      :value="value"
      :auto-upload="$attrs['auto-upload'] !== false"
      v-bind="attrs.uploadFile"
      :on-preview="handlePreview"
      :on-preview-button="handlePreviewButton"
      v-on="listeners"
      @changeValue="onChangeValue">
      <slot slot="tip" name="tip" />
    </upload-file>
    <el-button
      v-if="downloadAll && fileList.length && IsPC()"
      @click="handleDownloadAll">
      下载全部
    </el-button>
  </required-form-item>
</template>
<script>
import moment from 'moment'
import requiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
import uploadFile from '@/components/CustomFormItem/uploadFile'
import { FormItem } from 'element-ui'
import { mapGetters } from 'vuex'
import { aliUrlToFileObject } from '@/utils/upload'
import JSZip from 'jszip'
import { Base64 } from 'js-base64'
import { getStorage } from '@/utils/storage'

const toCamelString = text => {
  const keyArr = text.split('-')
  let camelKey = keyArr[0]

  for (let i = 1, len = keyArr.length; i < len; i++) {
    camelKey += keyArr[i].replace(/^./, keyArr[i][0].toLocaleUpperCase())
  }
  return camelKey
}
const extractUrl = file => {
  // 将url放置一级目录,使之与编辑处于同级
  if (file) {
    if (!file.url || file.response) {
      file.url = file.response.url
    }
  }
  return file
}
const jpgExtReg = /png|jpe?g|gif|svg/

export default {
  components: {
    uploadFile,
    requiredFormItem
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  inject: {
    bpmFlow: {
      default: undefined
    },
    oaFlow: {
      default: undefined
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: undefined
    },
    disabledPrior: {
      type: Boolean,
      default: undefined
    },
    onPreview: {
      type: Function,
      default: function () {}
    },
    downloadAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fileList: this.value
    }
  },

  computed: {
    ...mapGetters('dealershipTransfer', ['isOA']),
    attrs({ $attrs }) {
      const formItemKeys = Object.keys(FormItem.props)
        .concat(Object.keys(requiredFormItem.props))
        .map(toCamelString)
      const uploadFile = {
        ...($attrs['uploadFileProps'] || {}),
        ...($attrs['upload-file-props'] || {})
      }
      const other = {}
      const blackList = ['uploadFileListeners', 'uploadFileProps']
      for (const key in $attrs) {
        const camelKey = toCamelString(key)
        if (formItemKeys.includes(camelKey)) {
          other[key] = $attrs[key]
        } else if (!blackList.includes(camelKey)) {
          uploadFile[key] = $attrs[key]
        }
      }

      return {
        other,
        uploadFile
      }
    },
    listeners({ $listeners, $attrs }) {
      return Object.assign(
        $listeners,
        $attrs['uploadFileListeners'],
        $attrs['upload-file-listeners']
      )
    },
    isDisabled({ disabledPrior, disabled, bpmFlow, oaFlow, isOA }) {
      if (disabledPrior !== undefined) {
        return disabledPrior
      }
      if (!bpmFlow && isOA) {
        return true
      }
      return oaFlow?.disabled || bpmFlow?.disabled || disabled
    }
  },
  watch: {
    value() {
      this.fileList = this.value
    }
  },
  methods: {
    reloadFileList(fileList) {
      this.$refs.uploadFileRef?.reloadDefaultFileList(fileList)
    },
    IsPC() {
      const userAgentInfo = navigator.userAgent
      const Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
      ]
      let flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },

    onChangeValue(fileList) {
      this.fileList = fileList
      this.$emit('changeValue', fileList)
      this.$nextTick(() => {
        this.$refs.formItemRef.$formItemRef.validate('change')
      })
    },
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()

        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response)
          }
        }

        xhr.send()
      })
    },
    handleDownloadAll() {
      const zip = new JSZip()

      const zipFileName = moment().format('YYYY-MM-DD hh:mm:ss')

      this.fileList.forEach(async file => {
        zip.file(
          file.fileName,
          this.getBlob(`${file.requestDomainName}${file.requestPath}`),
          { binary: true }
        )
      })

      zip.generateAsync({ type: 'blob' }).then(content => {
        this.saveAs(content, `${zipFileName}.zip`)
      })
    },
    async saveAs(blob, fileName) {
      var elementA = document.createElement('a')
      // 文件的名称为时间戳加文件名后缀
      elementA.download = fileName
      elementA.style.display = 'none'
      // 生成一个指向blob的URL地址，并赋值给a标签的href属性
      elementA.href = window.URL.createObjectURL(blob)
      document.body.appendChild(elementA)
      elementA.click()
      document.body.removeChild(elementA)
    },
    async handlePreview(file) {
      if (!this.IsPC()) {
        window.open(file.url)
      } else {
        const blob = await this.getBlob(file.url)
        this.saveAs(blob, file.name)
      }
    },
    async handlePreviewButton(file) {
      file = extractUrl(file)
      if (this.onPreviewButton) {
        return this.onPreviewButton(file)
      }
      const { fileType } = aliUrlToFileObject(file.url, file.name)
      if (jpgExtReg.test(fileType)) {
        const imageList = this.fileList
          .filter(item => jpgExtReg.test(item.fileType))
          .map(item => `${item.requestDomainName}${item.requestPath}`)

        return this.$viewerApi({
          images: imageList,
          options: {
            initialViewIndex: imageList.findIndex(item => item === file.url)
          }
        })
      } else {
        console.log(file.url)
        // 预览pdf直接打开
        if (/\.pdf$/.test(file.url)) {
          window.open(file.url)
          return
        }
        const b64Encoded = Base64.encode(file.url)
        const watermark =
          `${getStorage('userInfo')?.realName}  ${
            getStorage('userInfo')?.account
          }` || 'DeRUCCI'
        window.open(
          'https://preview.iderucci.com/onlinePreview?url=' +
            encodeURIComponent(b64Encoded) +
            '&watermarkTxt=' +
            encodeURIComponent(watermark)
        )
        // window.open(file.url)
      }

      // this.handlePreview(file)
    }
  }
}
</script>

<style lang="scss" scoped></style>
