<template>
  <upload-file
    ref="uploadFileRef"
    :file-list="defaultFileList"
    v-bind="$attrs"
    v-on="$listeners"
    @onUploaded="onUploaded">
    <slot slot="tip" name="tip" />
  </upload-file>
</template>

<script>
import UploadFile from '@/components/UploadFile/index'
import { isEqual } from 'element-ui/src/utils/util'

export default {
  components: {
    UploadFile
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultFileList: [],
      isUploaded: false // 是否已经上传过
    }
  },
  computed: {
    $uploadFileRef() {
      return this.$refs.uploadFileRef
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(originalFileList, val) {
        if (this.defaultFileList.length || this.isUploaded) return
        if (originalFileList && originalFileList.length) {
          this.defaultFileList = this.setFileListFormat(originalFileList)
        }
      }
    }
  },
  methods: {
    setFileListFormat(originalFileList) {
      return originalFileList.map(fileItem => ({
        name: fileItem.fileName,
        url: `${fileItem.requestDomainName}${fileItem.requestPath}`
      }))
    },
    reloadDefaultFileList(fileList) {
      this.defaultFileList = this.setFileListFormat(fileList)
    },
    onUploaded(uploadedFlatList) {
      if (uploadedFlatList.length === this.value.length) {
        if (
          isEqual(
            uploadedFlatList.map(item => item.response),
            this.value
          )
        ) {
          return
        }
      }

      this.isUploaded = true
      this.$emit(
        'changeValue',
        uploadedFlatList.map(item => Object.assign({}, item.response))
      )
    }
  }
}
</script>

<style></style>
