const getName = (name, type = '') => {
  if (type) {
    return `${type}OverseasData${name.replace(/^./, name[0].toLocaleUpperCase())}`
  } else {
    return `overseasData${name.replace(/^./, name[0].toLocaleUpperCase())}`
  }
}

export default (type = '') => [
  {
    path: 'apply',
    name: getName('apply', type),
    component: () => import('@/views/overseasData/apply'),
    meta: { title: '流程申请' }
  },
  {
    path: 'myApply',
    name: getName('myApply', type),
    component: () => import('@/views/overseasData/myApply'),
    meta: { title: '我的申请' }
  },
  {
    path: 'enterQuery',
    name: getName('enterQuery', type),
    component: () => import('@/views/overseasData/enterQuery'),
    meta: { title: '录入查询页' }
  },
  {
    path: 'businessFlow',
    name: getName('businessFlow', type),
    component: () => import('@/views/overseasData/businessFlow'),
    meta: { title: '海外售达方数据录入', code: 'JXS010' },
    hidden: true
  },
  {
    path: 'deliverypartyFlow',
    name: getName('deliverypartyFlow', type),
    component: () => import('@/views/overseasData/deliverypartyFlow'),
    meta: { title: '海外送达方录入', code: 'JXS010' },
    hidden: true
  },
  {
    path: 'shopFlow',
    name: getName('shopFlow', type),
    component: () => import('@/views/overseasData/shopFlow'),
    meta: { title: '海外门店录入', code: 'JXS010' },
    hidden: true
  }
]
