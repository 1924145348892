<template>
  <div>
    <el-drawer
      title="修改详情"
      :visible.sync="storeVisibleDialog"
      @close="handleClose"
      direction="rtl"
      size="50%;overflow-y:auto">
      <div style="padding: 0 20px">
        <div class="demo-drawer__content">
          <el-form
            label-position="right"
            label-width="300px"
            :model="form"
            :disabled="isOnlyShow"
            style="padding-bottom: 60px"
            ref="form">
            <div>
              <el-popover
                placement="top-start"
                title="变更记录"
                :width="200"
                trigger="hover"
                :content="datahistorydetail.result">
                <template #reference>
                  <el-button>
                    <i class="el-icon-question"></i> 变更记录
                  </el-button>
                </template>
              </el-popover>
            </div>
            <el-form-item prop="" label="经销商编号">
              <el-input
                placeholder="新增后自动带出"
                :value="form.dealerCode"
                disabled />
            </el-form-item>
            <el-form-item label="经销商类型">
              <required-module-form-item-select
                :showLabel="false"
                prop="channelType"
                :required="false"
                v-model="form.channelType"
                type="dealer_type"
                network />
              <div class="update-box" v-if="isShowForm(uploadForm.channelType)">
                <span>修改至</span>
                <required-module-form-item-select
                  :showLabel="false"
                  prop="channelType"
                  :required="false"
                  placeholder=""
                  v-model="uploadForm.channelType"
                  type="dealer_type"
                  network />
              </div>
            </el-form-item>
            <el-form-item label="空白/转让加盟">
              <required-form-item-select
                v-model="form.newOrTransfer"
                prop="newOrTransfer"
                :showLabel="false"
                :required="false"
                :data-list="['空白加盟', '转让加盟']" />
              <div
                class="update-box"
                v-if="isShowForm(uploadForm.newOrTransfer)">
                <span>修改至</span>
                <required-form-item-select
                  v-model="uploadForm.newOrTransfer"
                  prop="newOrTransfer"
                  :showLabel="false"
                  :required="false"
                  :data-list="['空白加盟', '转让加盟']" />
              </div>
            </el-form-item>
            <el-form-item label="经销商性质">
              <required-module-form-item-select
                :showLabel="false"
                v-model="form.joinNature"
                :required="false"
                placeholder=""
                type="join_nature" />
              <div class="update-box" v-if="isShowForm(uploadForm.joinNature)">
                <span>修改至</span>
                <required-module-form-item-select
                  :showLabel="false"
                  v-model="uploadForm.joinNature"
                  :required="false"
                  placeholder=""
                  type="join_nature" />
              </div>
            </el-form-item>
            <el-form-item label="AB 商">
              <RequiredFormItemSelect
                :showLabel="false"
                prop="dealerLevel"
                v-model="form.dealerLevel"
                :required="false"
                :dataList="[
                  {
                    title: 'A商',
                    name: 'A'
                  },
                  {
                    title: 'B商',
                    name: 'B'
                  }
                ]"
                valueKey="name"
                labelKey="title" />

              <div class="update-box" v-if="isShowForm(uploadForm.dealerLevel)">
                <span>修改至</span>
                <RequiredFormItemSelect
                  :showLabel="false"
                  prop="dealerLevel"
                  v-model="uploadForm.dealerLevel"
                  placeholder=""
                  :required="false"
                  disabled
                  :dataList="[
                    {
                      title: 'A商',
                      name: 'A'
                    },
                    {
                      title: 'B商',
                      name: 'B'
                    }
                  ]"
                  valueKey="name"
                  labelKey="title" />
              </div>
            </el-form-item>
            <el-form-item prop="historyBoss" label="历史实控人">
              <el-input placeholder="历史实控人" v-model="form.historyBoss" />
              <div class="update-box" v-if="isShowForm(uploadForm.historyBoss)">
                <span>修改至</span>
                <span>{{ uploadForm.historyBoss }}</span>
              </div>
            </el-form-item>
            <el-form-item prop="createTime" label="首批摆场样品下单终审时间">
              <el-date-picker
                v-model="form.firstOpeningTime"
                type="datetime"
                placeholder="首批摆场样品下单终审时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
              <div
                class="update-box"
                v-if="isShowForm(uploadForm.firstOpeningTime)">
                <span>修改至</span>
                <el-date-picker
                  v-model="uploadForm.firstOpeningTime"
                  type="datetime"
                  placeholder="首批摆场样品下单终审时间"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item prop="" label="经典-授权时间">
              <el-date-picker
                v-model="form.jdBrandTime"
                type="datetime"
                placeholder="经典-授权时间"
                value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
              <div class="update-box" v-if="isShowForm(uploadForm.jdBrandTime)">
                <span>修改至</span>
                <el-date-picker
                  v-model="uploadForm.jdBrandTime"
                  type="datetime"
                  placeholder="经典-授权时间"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item
              prop="updateRemark"
              label="备注"
              :rules="{
                required: true,
                message: '请输入备注',
                trigger: 'blur'
              }">
              <el-input
                placeholder="备注"
                type="textarea"
                rows="5"
                v-model="form.updateRemark" />
            </el-form-item>
            <div
              style="
                text-align: center;
                position: fixed;
                bottom: 0px;
                padding: 10px 0;
                width: 50%;
                background: #fff;
              ">
              <el-button type="primary" @click="submit">保存</el-button>
              <el-button @click="storeVisibleDialog = false">取消</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getBossDataList, getBossDetail } from '@/api/businessOos'
import { crmdistributorUpdate } from '../api'
import { getCityInfoData } from '@/api/flowSetting/cityInfoManage'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import { getStorage } from '@/utils/storage'
import moment from 'moment'

export default {
  components: {
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect,
    RequiredFormItem
  },
  props: {
    selectItem: {
      type: Object,
      default: () => ({})
    },
    updateDetailData: {
      type: Object,
      default: () => ({})
    },
    isOnlyShow: {
      type: Boolean,
      default: false
    },
    datahistorydetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      storeVisibleDialog: false,
      required: true,
      loading: false,
      options: [
        {
          label: '停用',
          value: 1
        },
        {
          label: '启用',
          value: 0
        }
      ],
      form: {
        updateRemark: '',
        id: '',
        dealerCode: '',
        channelType: '',
        newOrTransfer: '',
        joinNature: '',
        dealerLevel: '',
        historyBoss: '',
        firstOpeningTime: '',
        jdBrandTime: '',
        updateRemark: '',
        bossId: '', //必传
        declarationFormId: '', //必传
        authorizedCityCode: '' //必传
      },
      total: null,
      bossSelectedItem: {
        bossNumber: '',
        idCard: '',
        dealerCode: '',
        dealerName: ''
      },
      authorizedCityTotal: null,
      authorizedCity: null,
      uploadForm: {}
    }
  },
  watch: {
    selectItem(val) {
      for (const key in this.form) {
        this.form[key] = val[key] || ''
      }
      const joinNature = val?.joinNature ?? ''
      this.form.joinNature = String(joinNature)
    },
    updateDetailData(val) {
      if (val) {
        for (const key in val) {
          this.uploadForm[key] = val[key]
        }
      }
    }
  },
  computed: {
    isShowForm() {
      return data => {
        return data || data === ''
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeVisibleDialog')
      this.uploadForm = {}
    },
    async onNotFoundCity({ value: authorizedCityCode }, callback) {
      callback(
        await getCityInfoData({
          authorizedCityCode
        }).then(res => res.data)
      )
    },
    getCityInfoData({ page, searchText }) {
      return getCityInfoData({
        currPage: page,
        addr: searchText
      }).then(res => {
        this.authorizedCityTotal = res.totalCount
        return res.data
      })
    },
    async onNotFoundBoss({ value: bossId }, callback) {
      let res = await getBossDetail({ id: bossId })
      callback(
        await getBossDataList({
          bossNumber: res.data[0].bossNumber
        }).then(response => {
          return response.data
        })
      )
    },
    getBossDataList({ page, searchText }) {
      return getBossDataList({
        currPage: page,
        searchKeyword: searchText
      }).then(res => {
        this.total = res.totalCount
        return res.data
      })
    },
    submit() {
      const userInfo = getStorage('userInfo') || {}
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.selectItem && this.selectItem.id) {
            this.loading = true
            crmdistributorUpdate({
              ...this.form,
              updateTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              updateUserName: userInfo?.realName,
              updateUserId: userInfo?.accountId
            })
              .then(res => {
                if (res.code === 0) {
                  this.$message.success(res.msg)
                  this.storeVisibleDialog = false
                  this.$refs['form'].resetFields()
                  this.$emit('clearSelection')
                  this.$emit('getDataList')
                } else {
                  this.$message.error(res.msg)
                }
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    cancel() {
      this.$emit('clearSelection')
      this.$emit('update:visible', false)
    },
    init() {
      this.$refs['form'].resetFields()
      this.required = true
      this.loading = false
      this.total = null
      this.bossSelectedItem = {
        bossNumber: '',
        idCard: '',
        dealerCode: '',
        dealerName: ''
      }
      this.authorizedCityTotal = null
      this.authorizedCity = null
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-form-item__content) {
  display: flex;
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }

  .el-textarea__inner {
    width: 250px;
  }
  .el-input {
    width: auto;
  }
  & .update-box {
    display: flex;
    & > span {
      white-space: nowrap;
      padding: 0 10px;
    }
    span + span {
      color: red;
    }
  }
}
</style>
