<template>
  <el-dropdown trigger="click" @command="handleSetSize">
    <div>
      <i class="el-icon-magic-stick" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="item of sizeOptions"
        :key="item.value"
        :disabled="size === item.value"
        :command="item.value"
      >
        {{ item.label }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  data() {
    return {
      sizeOptions: [
        { label: '默认', value: 'default' },
        { label: '中等', value: 'medium' },
        { label: '小型', value: 'small' },
        { label: '超小', value: 'mini' }
      ]
    }
  },
  computed: {
    size() {
      return this.$store.getters.elementSize
    }
  },
  methods: {
    $_isMobile() {
      const rect = document.body.getBoundingClientRect()
      return rect.width - 1 < 992
    },
    handleSetSize(size) {
      if(this.$_isMobile()){
        this.$message.error('小屏幕状态不可切换')
        return
      }
      this.$ELEMENT.size = size
      this.$store.dispatch('app/setElementSize', size)
      this.refreshView()
      this.$message({
        message: '切换成功！',
        type: 'success'
      })
    },
    refreshView() {
      // In order to make the cached page re-rendered
      this.$store.dispatch('tagsView/delAllCachedViews', this.$route)

      const { path } = this.$route

      this.$store.dispatch('app/setRedirect', path)
      const query = location.href.split('?')[1] || ''

      this.$nextTick(() => {
        this.$router.replace({
          path: '/redirect?' + query
        })
      })
    }
  }
}
</script>
