var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.FlowForm,{ref:"flowFormRef",attrs:{"fd-form-template-code":_setup.fdFormTemplateCode,"approval-data-rules":_setup.approvalDataRules,"model":_setup.flowData,"is-edit":[0, 20].includes(Number(_setup.flowData?.declarationForm?.status)),"approval-data":_setup.approveData,"on-submit":_setup.onSubmit,"on-approval":_setup.onApproval,"get-detail":_setup.getDetail,"submitButtonText":"发起流程","title":"慕思经典-门店装修流程"},scopedSlots:_vm._u([(_setup.isApprove)?{key:"approval-divider",fn:function({ fieldItem }){return [(fieldItem.fdFieldId == 'storeStyle')?_c(_setup.HeaderTitle,{staticStyle:{"margin":"20px 0"},attrs:{"title":"终端门店数据"}}):_vm._e(),(fieldItem.fdFieldId == 'matcherProfessionalism')?_c(_setup.HeaderTitle,{staticStyle:{"margin":"20px 0"},attrs:{"title":"经销商评价"}}):_vm._e()]}}:null],null,true)},[_c(_setup.StoreRenovationBaseForm,{attrs:{"model-value":_setup.flowData,"base-keys":_setup.baseKeys,"shop-keys":_setup.shopKeys,"link-flow-params":{ processCode: 'JXS030' }},on:{"update:modelValue":function($event){_setup.flowData=$event},"update:model-value":function($event){_setup.flowData=$event},"linkFlowChange":_setup.linkFlowChange}}),(!_setup.isApprove)?_c('div',[_c(_setup.HeaderTitle,{staticStyle:{"margin":"20px 0"},attrs:{"title":"终端门店装修信息"}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"品牌区域编码","value":_setup.approveData?.brandRegionCode,"placeholder":"/","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"营销中心","value":_setup.approveData?.marketCenterName,"placeholder":"/","disabled":""}}),_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"value":_setup.approveData?.pannelDesigner,"label":"平面图设计师","labelKey":"realName","valueKey":"account","getDataList":async () => {
          const res = await _setup.requestGetDesignerList({
            ifEnable: 1,
            pageSize: 200
          })
          return res?.data ?? []
        },"disabled":""}}),_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"value":_setup.approveData?.sofaConstruction,"label":"施工图设计师","labelKey":"personName","valueKey":"personNum","selectProps":{
        filterable: true,
        remote:true
      },"getDataList":_setup.requestJindieDataList,"disabled":""},on:{"onOptionsListNotFound":_setup.onJindieDataNotFound}}),_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"prop":`approveData.engineeringSupervision`,"label":"工程监理","labelKey":"personName","valueKey":"personNum","selectProps":{
        filterable: true,
        remote:true
      },"getDataList":_setup.requestJindieDataList},on:{"onOptionsListNotFound":_setup.onJindieDataNotFound,"changeSelectedItem":e => {
          _setup.flowData.approveData.engineeringSupervision = e ? e.personNum : ''
          _setup.flowData.approveData.engineeringSupervisionName = e
            ? e.personName
            : ''
        }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.personName)+" "+_vm._s(scope.row.personNum))])]}}],null,false,1646107964),model:{value:(_setup.flowData.approveData.engineeringSupervision),callback:function ($$v) {_vm.$set(_setup.flowData.approveData, "engineeringSupervision", $$v)},expression:"flowData.approveData.engineeringSupervision"}})],1):_vm._e(),(_setup.isApprove)?_c(_setup.HeaderTitle,{staticStyle:{"margin":"20px 0"},attrs:{"title":"终端门店装修信息"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }