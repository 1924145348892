<template>
  <div>
    <TablePro :columns="columns" ref="table" :request="getDataList">
      <template #actionArea>
        <div>
          <el-form
            ref="form"
            :inline="true"
            :model="selectForm"
            class="demo-form-inline"
          >
            <el-form-item
              :label="`${item.name}：`"
              v-for="(item, index) in formInline"
              :key="index"
            >
              <template v-if="item.type === 'input'">
                <el-input
                  v-model="selectForm[item.key]"
                  :placeholder="`请输入${item.name}`"
                ></el-input>
              </template>
              <template v-if="item.type === 'date'">
                <el-date-picker
                  v-model="selectForm[item.key]"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </template>
              <template v-if="item.type === 'select'">
                <el-select
                  :multiple="item.multiple"
                  filterable
                  clearable
                  v-model="selectForm[item.key]"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(i,index) in item.options"
                    :key="index"
                    :label="i.label"
                    :value="i.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-form-item>
            <!-- <el-form-item label="授权城市：">
              <el-select
                v-model="selectForm.authorizedCityCode"
                filterable
                remote
                reserve-keyword
                placeholder="请输入授权城市"
                :remote-method="handleSearch"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="query">查询</el-button>
            </el-form-item>
          </el-form>
          <el-card shadow="never">
            <span class="tip"> ！经销商对应授权品牌超90天未开任何门店</span>
          </el-card>
          <div style="margin-top: 20px">
            <el-button type="primary" :loading="btnLoading" @click="handleExcel"
              >导出excel</el-button
            >
          </div>
        </div>
      </template>
    </TablePro>
  </div>
</template>

<script>
import TablePro, { render } from '@/components/TablePro/index.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import {
  requestGetDataList,
  requestExcel,
  requestBrandList,
  requestCityList,
  requestEditStatus
} from '@/api/authorizationRemind'
export default {
  components: {
    TablePro,
    RequiredFormItemSelect
  },
  data() {
    return {
      columns: [
        {
          title: '实控人编号',
          name: 'bossNumber'
        },
        {
          title: '实控人名称',
          name: 'bossName'
        },
        {
          title: '售达方编号',
          name: 'licenseNumber'
        },
        {
          title: '售达方名称',
          name: 'businessLicenseName'
        },
        {
          title: '省份',
          name: 'province'
        },
        {
          title: '授权城市',
          name: 'city'
        },
        {
          title: '授权品牌',
          name: 'brandName'
        },
        {
          title: '授权状态',
          name: 'ifEnabled',
          render: render((h, { data }) => (
            <div>{data.ifEnabled === '1' ? '失效' : '生效'}</div>
          ))
        },
        {
          title: '授权取消日期',
          name: 'overTime'
        },
        {
          title: '授权日期',
          name: 'authDate'
        },
        // {
        //   title: '操作',
        //   render: render((h, { data }) => (
        //     <div>
        //       <el-button
        //         type={data.ifEnabled === '1' ? 'success' : 'danger'}
        //         onClick={() =>
        //           this.handleEditStatus(
        //             {
        //               id: data.id,
        //               opType: data.ifEnabled === '1' ? 0 : 1
        //             },
        //             data
        //           )
        //         }
        //       >
        //         {data.ifEnabled === '1' ? '生效授权' : '取消授权'}
        //       </el-button>
        //     </div>
        //   ))
        // }
      ],
      selectForm: {},
      formInline: [
        {
          name: '实控人编号',
          key: 'bossNumber',
          type: 'input'
        },
        {
          name: '实控人名称',
          key: 'bossName',
          type: 'input'
        },
        {
          name: '售达方编号',
          key: 'licenseNumber',
          type: 'input'
        },
        {
          name: '售达方名称',
          key: 'businessLicenseName',
          type: 'input'
        },
        {
          name: '省份',
          key: 'province',
          type: 'input'
        },
        {
          name: '授权城市',
          key: 'authorizedCityCode',
          type: 'input'
        },
        {
          name: '授权品牌',
          key: 'brandCode',
          type: 'select',
          options:this.brandList,
          multiple: true
        },
        {
          name: '授权取消日期',
          key: 'overTimeStart',
          type: 'date'
        },
        {
          name: '授权状态',
          key: 'ifEnable',
          type: 'select',
          options:[{
            label:'生效',
            value: 0
          },
          {
            label:'失效',
            value: 1
          }]
        }
      ],
      btnLoading: false,
      brandList: [],
      cityList: []
    }
  },
  mounted() {
    this.getBrandList()
  },
  methods: {
    query() {
      this.$refs.table.onRefreshTableList(1)
    },
    // 获取数据列表
    async getDataList(params) {
      const res = await requestGetDataList({
        ...params,
        ...this.selectForm,
        brandCode: this.selectForm.brandCode?.join(',') ?? ''
      })
      return {
        data: res.data,
        totalCount: res.totalCount
      }
    },
    // 导出
    async handleExcel() {
      this.btnLoading = true
      try {
        await requestExcel()
      } catch (error) {
        console.log(error)
      } finally {
        this.btnLoading = false
      }
    },
    // 获取授权品牌列表
    async getBrandList() {
      const res = await requestBrandList()
      this.brandList = res.data.map(item=>({...item,label:item.name,value:item.code}))
      this.$set(this.formInline[6],'options',this.brandList)
    },
    async handleSearch(query) {
      if (query !== '') {
        const res = await requestCityList(query)
        console.log(res)
        // this.cityList =
      } else {
        this.cityList = []
      }
    },
    handleEditStatus(data, params) {
      this.$confirm(
        `确认${data.opType === 0 ? '生效' : '失效'}售达方 ${
          params.businessLicenseName
        } 下＂${params.brandName}＂授权?`,
        '提示'
      ).then(async() => {
        await requestEditStatus(data)
        this.$refs.table.onRefreshTableList(1)
        this.$message.success('修改成功')
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.tip {
  font-size: 14px;
  color: #409eff;
}
</style>