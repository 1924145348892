export default [
  {
    path: 'storeReport',
    name: 'storeReport',
    component: () => import('@/views/watchData/storeReport'),
    meta: {
      title: '门店问题数据明细',
    }
  },
  {
    path: 'delearReport',
    name: 'delearReport',
    component: () => import('@/views/watchData/delearReport'),
    meta: {
      title: '经销商问题数据明细',
    }
  },
  {
    path: 'questionReport',
    name: 'questionReport',
    component: () => import('@/views/watchData/questionReport'),
    meta: {
      title: '问题统计报表',
      keepAlive:true
    }
  },
  {
    path: 'taskReport',
    name: 'taskReport',
    component: () => import('@/views/watchData/taskReport'),
    meta: {
      title: '任务统计报表',
      keepAlive:true
    }
  },
  {
    path: 'sapReport',
    name: 'sapkReport',
    component: () => import('@/views/watchData/sapReport'),
    meta: {
      title: 'SAP数据监控',
      // keepAlive:true
    }
  }
]
