<script setup lang="jsx">
import { ref, computed } from 'vue'
import TablePro, { render, renderFormItem } from '@/components/TablePro'
import PopupForm from '@/components/FormPro/PopupForm'
import {
  getHoteList,
  addHoteItem,
  updateHoteItem,
  updateHoteStatus,
  delHoteItem,
  getCityMapList
} from './api/'
import { getStorage } from '@/utils/storage'
import { Message, MessageBox } from 'element-ui'
import QQMap from '@/components/Map/qqmap'

const popupFormRef = ref(null)
const tableProRef = ref(null)
const ciotyOptions = ref()

const getOptions = async () => {
  const { data } = await getCityMapList()

  ciotyOptions.value = data.map(item => {
    return {
      ...item,
      label: item.content,
      value: item.content,
      children: item.cityList.map(option => {
        return {
          label: option.content,
          value: option.content,
          children: option.areaList.map(areaItem => ({
            label: areaItem.content,
            value: areaItem.content
          }))
        }
      })
    }
  })
}

getOptions()

const statusEnum = [
  { label: '有效', value: 0 },
  { label: '无效', value: 1 }
]

const tablePrpConfig = ref({
  request: async params => getHoteList({ ...params }),
  autoWidthCell: true,
  columns: [
    { title: '序号', valueType: 'index', width: 50 },
    { title: '酒店ID', name: 'id' },
    { title: '酒店名称', name: 'hotelName', search: true },
    { title: '联系方式', name: 'hotelPhone' },
    { title: '省份', name: 'province' },
    { title: '城市', name: 'city' },
    { title: '区/县', name: 'area' },
    { title: '详细地址', name: 'address' },
    {
      title: '经纬度',
      width: 160,
      name: 'longitude_latitude',
      render: render((h, ctx) => {
        const str = `${ctx.data.longitude} - ${ctx.data.latitude}`
        return <span>{str}</span>
      })
    },
    { title: '围栏半径(m)', name: 'radius' },
    {
      title: '状态',
      name: 'status',
      hideInTable: true,
      valueType: 'select',
      search: true,
      valueEnum: statusEnum
    },
    {
      title: '状态',
      name: 'status',
      width: 100,
      render: render((h, context) => {
        return (
          <el-switch
            value={context.data.status}
            active-value={0}
            inactive-value={1}
            onChange={async () => {
              {
                try {
                  await updateHoteStatus({ id: context.data.id })
                  Message.success('修改成功')
                  tableProRef.value?.onRefreshTableList()
                } catch (err) {
                  //
                }
              }
            }}
          />
        )
      })
    },
    { title: '更新时间', name: 'updateTime' },
    { title: '更新人', name: 'updateBy' },
    {
      title: '操作',
      width: 200,
      tableColumnProps: { fixed: 'right' },
      render: render((h, ctx) => {
        const { data } = ctx
        const {
          province,
          city,
          area,
          address,
          hotelName,
          hotelPhone,
          latitude,
          longitude,
          radius,
          status,
          id
        } = data

        const onDelect = async () => {
          try {
            await MessageBox.confirm('是否删除？')
            await delHoteItem([data.id])
            tableProRef.value?.onRefreshTableList()
          } catch (error) {
            if (error != 'cancel') Message.error('失败')
          }
        }

        return (
          <div>
            <el-button
              type="primary"
              onClick={() =>
                popupFormRef.value.onShow(
                  {
                    address,
                    hotelName,
                    hotelPhone,
                    latitude,
                    longitude,
                    radius,
                    status,
                    id,
                    province_city_area: [province, city, area]
                  },
                  undefined,
                  {
                    title: '编辑'
                  }
                )
              }>
              编辑
            </el-button>
            <el-button type="danger" v-loading-click={() => onDelect()}>
              删除
            </el-button>
          </div>
        )
      })
    }
  ]
})

const selectLocationColumns = ref([
  {
    name: '_latitude_longitude',
    search: {
      transform(e) {
        return e ? e : {}
      }
    },
    renderFormItem: renderFormItem((h, context) => {
      return (
        context.$attrs.value && (
          <QQMap
            ref="mapRef"
            center={
              context.$attrs.value?.longitude ? context.$attrs.value : null
            }
            locationName={context.$attrs.params?.locationName}
            onOnChange={e => {
              context.$listeners.input(e)
            }}
          />
        )
      )
    })
  }
])

const formConfig = ref({
  onSubmit: async params => {
    console.log('params', params)
    const { province_city_area, ...data } = params
    const [province, city, area] = province_city_area
    const { realName } = getStorage('userInfo')
    const hoteData = {
      ...data,
      province,
      city,
      area
    }
    if (params.id) {
      await updateHoteItem({ ...hoteData, updateBy: realName })
    } else {
      await addHoteItem({ ...hoteData, createBy: realName })
    }
    Message.success('操作成功')
    tableProRef.value?.onRefreshTableList()
    return true
  },
  popupType: 'el-dialog',
  width: '80%',
  columns: [
    {
      title: '酒店名称',
      name: 'hotelName',
      fieldProps: { required: true, maxlength: 30 }
    },
    {
      title: '联系方式',
      name: 'hotelPhone',
      fieldProps: { required: true, maxlength: 11 }
    },
    {
      title: '省/市/区',
      name: 'province_city_area',
      renderFormItem: renderFormItem(
        (h, context) => {
          const onChange = value => {
            context.$listeners.input(value)
          }

          const onMapSubmit = params => {
            const { latitude, longitude, address } = params
            popupFormRef.value?.$refs.formRef.onUpdateValue(
              'latitude',
              latitude
            )
            popupFormRef.value?.$refs.formRef.onUpdateValue(
              'longitude',
              longitude
            )
            popupFormRef.value?.$refs.formRef.onUpdateValue('address', address)
            popupFormRef.value?.$refs.formRef.$refs.commonFormRef?.validate()
            return true
          }

          return (
            <div>
              <el-cascader
                style="width:40%;margin-right:20px"
                attrs={context.$attrs}
                options={ciotyOptions.value}
                filterable
                onChange={onChange}
              />
              <PopupForm
                title="选择地址"
                columns={selectLocationColumns.value}
                attrs={{ 'on-submit': onMapSubmit }}
                scopedSlots={{
                  trigger: ({ onShow }) => (
                    <el-button
                      v-loading-click={() =>
                        onShow({
                          _latitude_longitude: context.$attrs.params?.longitude
                            ? {
                                longitude: context.$attrs.params?.longitude,
                                latitude: context.$attrs.params?.latitude,
                                address: context.$attrs.params?.address
                              }
                            : {},
                          locationName:
                            context.$attrs.params?.province_city_area.join('')
                        })
                      }>
                      选择详细地址与经纬度
                    </el-button>
                  )
                }}
              />
            </div>
          )
        },
        { required: true }
      )
    },
    {
      title: '详细地址',
      name: 'address',
      fieldProps: { required: true, maxlength: 50 }
    },
    {
      title: '经度',
      name: 'longitude',
      fieldProps: { required: true, maxlength: 9, readonly: true }
    },
    {
      title: '纬度',
      name: 'latitude',
      fieldProps: { required: true, maxlength: 9, readonly: true }
    },
    {
      title: '围栏半径(m)',
      name: 'radius',
      fieldProps: { required: true, maxlength: 9, type: 'number' }
    },
    {
      title: '状态',
      name: 'status',
      valueType: 'select',
      search: true,
      defaultValue: 0,
      valueEnum: statusEnum
    }
  ]
})
</script>

<template>
  <TablePro ref="tableProRef" v-bind="tablePrpConfig">
    <template #actionArea>
      <PopupForm ref="popupFormRef" v-bind="formConfig">
        <template #trigger="{ onShow }">
          <el-button
            type="primary"
            @click="onShow({}, undefined, { title: '新增' })">
            新增
          </el-button>
        </template>
      </PopupForm>
    </template>
  </TablePro>
</template>
