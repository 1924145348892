export default [
  {
    path: '/'
  },
  {
    path: 'cityInfoManage',
    name: 'cityInfoManage',
    component: () => import('@/views/flowSetting/cityInfoManage'),
    meta: {
      title: '城市信息管理'
    }
  }
]
