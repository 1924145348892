import variables from '@/styles/element-variables.scss'
import variablesDef from '@/styles/variables.scss'
import defaultSettings from '@/settings'
import { setStorage,getStorage } from '@/utils/storage'

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings

const fixed=JSON.parse(getStorage('fixedHeader')||'false')
const tagsView=JSON.parse(getStorage('tagsView')||'true')

const state = {
  theme: getStorage('theme')|| 'default-theme',// variables.theme,
  showSettings: showSettings,
  fixedHeader: fixed,
  sidebarLogo: sidebarLogo,
  tagsView: tagsView,
  column:getStorage('column')||'5'
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
      setStorage(key,value)
    }
  },
  CHANGE_THEME(state, data) {
    state.theme=data
    setStorage('theme',data)
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  changeTheme({ commit }, data){
    commit('CHANGE_THEME', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
