/**
 * 海外渠道合作流程
 * doc: http://10.12.0.64/docs/operate/operate-1emdeab3a7aeo
 */

import http from '@/utils/request'

// 保存草稿
export const saveDraft = data =>
  http({
    url: '/dealermanage/operationflow/overseasSinomiChannelJoinApi/saveDraft',
    method: 'post',
    data
  })

// 提交 OA
export const submit = data =>
  http({
    url: '/dealermanage/operationflow/overseasSinomiChannelJoinApi/submit',
    method: 'post',
    data
  })

// 获取详情
export const detail = params =>
  http({
    url: '/dealermanage/operationflow/overseasSinomiChannelJoinApi/getDetail',
    method: 'get',
    params
  })
