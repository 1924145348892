<template>
  <el-form
    ref="refForm"
    :rules="rules"
    style="padding: 0 30px"
    :label-width="labelWidth"
    :inline="false"
    :model="state.form">
    <el-form-item label="上级" prop="umParentId">
      <el-cascader
        v-model="state.umParentId"
        :disabled="!editStatus"
        placeholder="请选择上级菜单"
        :options="state.menuListTree"
        :show-all-levels="false"
        :props="{ checkStrictly: true }"
        @change="handleChange"></el-cascader>
    </el-form-item>
    <el-form-item label="标题" prop="umName">
      <el-input v-model="state.form.umName" :disabled="!editStatus" />
    </el-form-item>
    <el-form-item label="路径" prop="umRoute">
      <el-input v-model="state.form.umRoute" :disabled="!editStatus" />
    </el-form-item>
    <el-form-item label="图标" v-show="state.form.umType == 0">
      <el-select
        v-model="state.form.umIcon"
        clearable
        filterable
        placeholder="请选择图标"
        :disabled="!editStatus">
        <el-option
          v-for="item in state.optionsIcon"
          :key="item.value"
          :label="item.label"
          :value="item.value">
          <i :class="item.value" style="float: left; font-size: 25px"></i>
          <span style="float: right; color: #8492a6; font-size: 14px">{{
            item.value
          }}</span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="排序" prop="umIndex">
      <el-input v-model="state.form.umIndex" :disabled="!editStatus" />
    </el-form-item>
    <el-form-item label="类型" prop="umType">
      <el-radio-group v-model="state.form.umType" :disabled="!editStatus">
        <!-- <el-radio :label="3">菜单</el-radio> -->
        <el-radio :label="0">页面</el-radio>
        <el-radio :label="1">按钮</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="editStatus">
      <el-button @click="emit('onCancel')">取消</el-button>
      <el-button type="primary" @click="handleSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, watch, ref } from 'vue'
import { Message } from 'element-ui'
import { menuAdd, menuUpdate } from '@/api/system/menu'
import { toTree } from '@/utils/index'
import icons from '@/utils/icon.json'

const rules = {
  umParentId: [{ required: true, message: '请选择上级菜单', trigger: 'blur' }],
  umName: [{ required: true, message: '请输入菜单标题', trigger: 'blur' }],

  umIndex: [{ required: true, message: '请输入菜单排序序号', trigger: 'blur' }],
  umType: { required: true, message: '请选择活动资源', trigger: 'blur' }
}

const props = defineProps({
  labelWidth: String,
  status: {
    type: Number,
    default: 0 //0添加 1修改
  },
  selectItem: {
    type: Object,
    default: {}
  },
  menuList: Array,
  editStatus: Boolean
})
const refForm = ref(null)

const state = reactive({
  form: {},
  menuListTree: [],
  umParentId: [],
  optionsIcon: icons.map(item => ({ value: item, label: item }))
})

const emit = defineEmits(['onSaveMenu', 'onCancel'])
const systemCode = process.env.VUE_APP_SYSTEM_CODE

watch(
  () => props.selectItem,
  val => {
    state.form = val
    state.umParentId = val.umParentId
  }
)

watch(
  () => props.menuList,
  val => {
    state.menuListTree = [
      {
        value: '0',
        label: '根目录',
        children: toTree(val, {
          parentId: 'umParentId',
          id: 'id',
          label: 'umName'
        })
      }
    ]
  }
)

const handleChange = val => {
  state.umParentId = val
  state.form.umParentId = val
}

const handleSubmit = () => {
  refForm.value.validate(async valid => {
    if (valid) {
      const {
        systemId,
        umParentId,
        umName,
        umRoute,
        umIcon,
        umType,
        umIndex,
        id
      } = state.form

      const pid =
        Array.isArray(umParentId) && umParentId.length
          ? umParentId.pop()
          : umParentId || '0'

      let params = {
        systemId: systemId || systemCode,
        umParentId: pid,
        umName,
        umRoute,
        umIcon,
        umType,
        umIndex
      }

      if (props.status) {
        params.id = id
        await menuUpdate(params)
      } else {
        await menuAdd(params)
        state.form = {}
      }
      Message.success('success')
      emit('onSaveMenu')
    } else {
      return false
    }
  })
}
</script>

<style></style>
