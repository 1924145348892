<template>
  <div class="auth">授权登录中....</div>
</template>

<script>
//用于统一登录授权登录
import store from '@/store'
export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.query.code) {
      next('/login')
      return
    }
    try {
      //webRedirectUri:process.env.VUE_APP_REDIRECT_URL
      await store.dispatch('user/ssoLoginCrossPlatform', {
        code: to.query.code,
        systemCode: '30',
        webRedirectUri: process.env.VUE_APP_REDIRECT_URL + '/redirect.html'
      })
      next('/welcome')
    } catch (err) {
      console.log(err)
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  height: 300px;
  text-align: center;
  line-height: 300px;
}
</style>
