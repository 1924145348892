/**
 * http://10.12.0.64/docs/operate/operate-1e353gn29cjvs
 * 慕思招商平台3期 - 海外数据录入
 */

import http from '@/utils/request'
// 录入查询页
export const overseasenterrecordListpage = params => {
  return http({
    url: '/dealermanage/overseasenterrecord/listpage',
    params,
    method: 'GET'
  })
}

// 保存海外售达方
export const saveSoldToParty = data => {
  return http({
    url: '/dealermanage/operationflow/overseasMarketApi/saveSoldToParty',
    method: 'POST',
    data
  })
}
// 保存海外送达方
export const saveShipToParty = data => {
  return http({
    url: '/dealermanage/operationflow/overseasMarketApi/saveShipToParty',
    method: 'POST',
    data
  })
}
// 保存海外门店
export const saveShop = data => {
  return http({
    url: '/dealermanage/operationflow/overseasMarketApi/saveShop',
    method: 'POST',
    data
  })
}
