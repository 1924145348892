import http from "@/utils/request";
export const getBossOrganizationPage = params => http({
    url: '/dealermanage/boss/bossOrganizationPage',
    method:'get',
    params
  })
//实控人关联售达方列表查询 
export const getAttachLicenseList = bossId => http({
    url: `/dealermanage/boss/attachLicenseList/${bossId}`,
    method:'get'
})
//更改实控人对应售达方
export const bossBindLicense = data => http({
    url: '/dealermanage/boss/bossBindLicense',
    method:'post',
    data
})