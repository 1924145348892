<script setup>
import { ref, computed } from 'vue'
import StoreRenovationBaseForm from './components/StoreRenovationBaseForm.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import { getStorage } from '@/utils/storage'
import HeaderTitle from '@/views/flow/components/headerTitle'
import FlowForm from './components/flowForm'
import {
  submitRectifyApproveData,
  submitRectifyFlow,
  getRectifyFlowItem
} from './api/classicalStoreChangeFlow'
import { useRoute } from '@/utils/router'

const innerFlowData = {
  linkOrderNumber: '',
  beforeRenovationShop: {}
}
const fdFormTemplateCode = 'distributor-JXS033'

const route = useRoute()
const flowFormRef = ref(null)
const flowData = ref(innerFlowData)
const approvalData = ref({})
const flowId = computed(() => {
  const { id, businessId } = route.query
  return id || businessId
})
const originFlowData = ref({})

async function getDetail(type, cachedFormData) {
  if (type != 'detail') {
    flowData.value = {
      ...flowData.value,
      ...(cachedFormData || {})
    }
    return
  }
  const { data } = await getRectifyFlowItem({ formId: flowId.value })
  originFlowData.value = data
  flowData.value = {
    ...innerFlowData,
    ...data,
    linkOrderNumber: data.storeDesignProcessCode
  }
  approvalData.value = data.approveData ?? {}
}

function onApproval(bpmExecuteBean, bpmApproveModel) {
  const userInfo = getStorage('userInfo')
  const optJobNumber = userInfo.code || userInfo.account
  const approveFormData = {
    id: flowId.value,
    optUserId: userInfo.accountId, //提单人ID【必须】',
    optUserName: userInfo.realName, //提单人姓名【必须】,
    optJobNumber, //提单人工号【必须】
    applyOrderModel: {
      approveAccount: optJobNumber, //审批人工号   [必填]',
      businessId: flowId.value,
      processInstanceId: bpmExecuteBean?.processId,
      systemCode: 'distributor', //来源系统  [必传],
      fdFormTemplateCode, //模板/流程编码,
      pageVersion: 'V1', //页面版本 当前流程使用的页面版本  [必传]',
      bpmExecuteBean
    },
    approveData: bpmApproveModel
  }

  if (Number(originFlowData.value?.status ?? 0) === 20) {
    originFlowData.value = {
      ...originFlowData.value,
      approveData: bpmApproveModel,
      applyOrderModel: approveFormData.applyOrderModel
    }
    return flowFormRef.value?.onCommit('submit')
  }
  console.log('submitRectifyApproveData', approveFormData)
  return submitRectifyApproveData(approveFormData)
}

function onSubmit(type) {
  if (type !== 'submit') return
  submitRectifyFlow(originFlowData.value)
}
</script>

<template>
  <FlowForm
    ref="flowFormRef"
    :fd-form-template-code="fdFormTemplateCode"
    :model="flowData"
    :is-edit="[0, 20].includes(Number(flowData?.status))"
    :showSave="false"
    :approval-data="approvalData"
    :on-submit="onSubmit"
    :on-approval="onApproval"
    :get-detail="getDetail"
    submitButtonText="重新发起流程"
    title="慕思经典-门店整改流程">
    <StoreRenovationBaseForm
      disabled
      :model-value.sync="flowData"
      :link-flow-params="{ processCode: 'JXS030' }" />
    <HeaderTitle v-if="flowId" style="margin: 20px 0" title="店装物料信息" />
  </FlowForm>
</template>
