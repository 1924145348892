//中文姓名或英文姓名
export const nameReg = /^(?:[\u4e00-\u9fa5·]{2,16}|[a-zA-Z]{1}[a-zA-Z\s]{0,20}[a-zA-Z]{1})$/;

//身份证号, 支持1/2代(15位/18位数字)
export const userIdReg = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;

//中国手机号(宽松), 只要是13,14,15,16,17,18,19开头即可
export const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

//邮箱
export const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//统一社会信用代码(宽松匹配)(15位/18位/20位数字/字母)
export const creditCodeReg = /^(([0-9A-Za-z]{15})|([0-9A-Za-z]{18})|([0-9A-Za-z]{20}))$/;

//银行卡号（10到30位, 覆盖对公/私账户, 参考[微信支付] https://pay.weixin.qq.com/wiki/doc/api/xiaowei.php?chapter=22_1
//不做验证
export const bankAccountReg = /^\d+$/;

//数字/货币金额（支持负数、千分位分隔符）
export const moneyReg = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;

//QQ号码格式
export const qqReg = /^[1-9][0-9]{4,10}$/;

//验证身份证
export const validUserId = (rule, value, callback) => {
  userIdReg.lastIndex = 0;
  if (!userIdReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证中英文名称
export const validName = (rule, value, callback) => {
  nameReg.lastIndex = 0;
  if (!nameReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证手机号码
export const validPhone = (rule, value, callback) => {
  phoneReg.lastIndex = 0;
  if (!phoneReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证邮件地址
export const validEmail = (rule, value, callback) => {
  emailReg.lastIndex = 0;
  if (!emailReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证社会统一信用代码
export const validCreditCode = (rule, value, callback) => {
  creditCodeReg.lastIndex = 0;
  if (!creditCodeReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证银行账号
export const validbankAccount = (rule, value, callback) => {
  bankAccountReg.lastIndex = 0;
  if (!bankAccountReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证金额
export const validMoney = (rule, value, callback) => {
  moneyReg.lastIndex = 0;
  if (!moneyReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证qq
export const validQQ = (rule, value, callback) => {
  qqReg.lastIndex = 0;
  if (!qqReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//验证数组为空
export const validArrayLength = (rule, value, callback) => {
  if (value.length == 0) {
    return callback(new Error("数组为空"));
  }
  callback();
};
// qq or email
export const validQQOrEmail = (rule, value, callback) => {
  qqReg.lastIndex = 0;
  emailReg.lastIndex = 0;
  if (!qqReg.test(value) && !emailReg.test(value)) {
    return callback(new Error("格式有误"));
  }
  callback();
};
//rules 必填项验证
export const requiredRule = {
  required: true,
  message: "必填项",
  trigger: ["blur", "change"]
};

//rules 验证文件
export const filesRules = {
  validator: validArrayLength,
  message: "请上传文件",
  trigger: ["blur", "change"]
};

export const validPhoneRule = [
  requiredRule,
  { validator: validPhone, message: "格式有误", trigger: ["blur", "change"] }
];

export const validUserIdRule = [
  requiredRule,
  { validator: validUserId, message: "格式有误", trigger: ["blur", "change"] }
];

export const validNameRule = [
  requiredRule,
  { validator: validName, message: "格式有误", trigger: ["blur", "change"] }
];
