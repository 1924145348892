import http, { download } from '@/utils/request'
/**
 * http://10.12.0.64/docs/advert/advert-1dmq6tp5rn041
 * 补贴额度
 */
// 列表
export const getDataList = (params) => {
  return http({
    url: '/dealermanage/advSubsidy/listpage',
    params
  })
}

// 导出模板
export const exportTemplate = (params) => {
  return download('dealermanage/advSubsidy/exportTemplate', params)
}

// 导入
export const importData = (data) => {
  return http({
    url: '/dealermanage/advSubsidy/import',
    method: 'POST',
    data
  })
}
// 导出数据
export const exportData = (params) => {
  return http({
    url: '/dealermanage/advSubsidy/export',
    params
  })
}
// 查询流程列表
export const getDeclarationList = (params) => {
  return http({
    url: '/dealermanage/advSubsidy/getDeclarationList',
    params
  })
}
// 调整额度
export const adjustAmount = (data) => {
  return http({
    url: '/dealermanage/advSubsidy/adjustAmount',
    data,
    method: 'POST'
  })
}
// 删除
export const deleteData = (data) => {
  return http({
    url: 'dealermanage/advSubsidy/loseEfficacy',
    data,
    method: 'POST'
  })
}

// 补贴调整列表
export const recordDeclarationPage = (params) => {
  return http({
    url: '/dealermanage/advSubsidy/recordDeclarationPage',
    params
  })
}
