var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_setup.customBaseForm),function(formLabel,formKey){return [_vm._t("custom-base-item",function(){return [(formKey === 'linkOrderNumber')?[_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"prop":formKey,"getDataListParams":_setup.props.linkFlowParams,"total-count":_setup.listTotalCount,"get-data-list":_setup.getLinkFlowList,"get-next-list":_setup.getLinkFlowList,"select-props":{
            filterable: true,
            remote: true,
            clearable: true
          },"disabled":_setup.props.disabled,"label":formLabel.replace('单号', ''),"label-key":"title","value-key":"orderNumber"},on:{"onOptionsListNotFound":_setup.onNotFoundLinkFlowList,"onChangeItem":_setup.onLinkFlowChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{on:{"click":function($event){return _setup.goLinkFlowDetail(1)}}},[_c('label',[_vm._v(_vm._s(formLabel.replace('单号', '')))]),_c('i',{staticClass:"el-icon-link"})])]},proxy:true}],null,true),model:{value:(_setup.form[formKey]),callback:function ($$v) {_vm.$set(_setup.form, formKey, $$v)},expression:"form[formKey]"}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"value":_setup.form[formKey],"delay-update":"","placeholder":"/","disabled":"","label":formLabel,"rows":1,"required":false,"inputProps":{ readonly: true }}})]:_c(_setup.RequiredFormItemInput,{staticClass:"formItem",style:(['oldBrandNames'].includes(formKey) ? 'min-width:40%' : null),attrs:{"delay-update":"","value":_setup.baseFormItemValue(formKey),"type":"text","labelTip":['oldBrandNames', 'designBrandNames', 'title'].includes(formKey)
            ? _setup.form[formKey]
            : null,"prop":formKey,"label":formLabel,"rows":1,"required":false,"inputProps":{ readonly: true },"placeholder":"/","disabled":""},scopedSlots:_vm._u([(formKey === 'storeManageProcessName')?{key:"label",fn:function(){return [_c('span',{on:{"click":function($event){return _setup.goLinkFlowDetail(0)}}},[_c('label',[_vm._v(_vm._s(formLabel))]),_c('i',{staticClass:"el-icon-link"})])]},proxy:true}:null],null,true)})]},{"formKey":formKey,"formLabel":formLabel})]}),_c(_setup.HeaderTitle,{staticStyle:{"margin":"20px 0"},attrs:{"title":"终端门店基础信息"}}),_vm._l((_setup.customShopKeys),function(formLabel,formKey){return [_vm._t("custom-shop-item",function(){return [(formKey === 'managementModel')?_c(_setup.RequiredModuleFormItemSelect,{staticClass:"formItem",attrs:{"value":_setup.shopFormItemValue(formKey),"label":formLabel,"required":false,"disabled":"","type":"management_model"}}):(formKey === 'storeNature')?_c(_setup.RequiredModuleFormItemSelect,{staticClass:"formItem",attrs:{"value":_setup.shopFormItemValue(formKey),"label":formLabel,"disabled":"","type":"shop_nature_code"}}):_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"value":_setup.shopFormItemValue(formKey),"delay-update":"","placeholder":"/","disabled":"","label":formLabel,"rows":1,"required":false,"inputProps":{ readonly: true }}})]},{"formKey":formKey})]}),_vm._l((_setup.fileKeys),function(fileLabel,fileKey){return [(_setup.flowFiles(fileKey) && _setup.flowFiles(fileKey).length)?_c(_setup.RequiredFormItemUploadFile,{key:fileKey,ref:el => (_setup.uploadFileRefs[fileKey] = el),refInFor:true,staticClass:"formItem",attrs:{"value":_setup.flowFiles(fileKey),"label":fileLabel,"required":false,"disabled":"","limit-size":"400"}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }