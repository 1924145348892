import http from "@/utils/request";
// 保存草稿
export const saveDraft = data => http({url:'/dealermanage/bpm/storeRectification/saveDraft',method:"POST",data})
//获取详情
export const getDetail = params => http({url:'/dealermanage/bpm/storeRectification/details',params})
//提交
export const submit = data => http({url:'/dealermanage/bpm/storeRectification/submit',method:"POST",data})
//审批
export const submitApproveData = data => http({url:'/dealermanage/bpm/storeRectification/submitApproveData',method:"POST",data})
//获取关联巡检整改流程详情
export const getStoreInspectionRectificationDetail = params => http({url:'/dealermanage/bpm/storeInspectionRectification/details',params})