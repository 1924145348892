import http, { download } from '@/utils/request'
import { getStorage } from '@/utils/storage'
export const requestGetDataList = data => {
  return http({
    url: '/dealermanage/deliveryparty/outDeliveryPartyPage',
    params: data
  })
}

// 列表导出
export const requestExcel = () => {
  download('dealermanage/deliveryparty/exportNoShopPartyExcel')
}

// 获取授权品牌列表
export const requestBrandList = () => {
  return http({
    url: '/dealermanage/brandseries/list',
    params: {
      brandLevel: 1,
      ifEnabled: 0
    }
  })
}

// 授权城市
export const requestCityList = params => {
  return http({
    url: '/dealermanage/authorizedcity/getProvinceOrCityOrAreaList',
    params: {
      type: 'city',
      province: params
    }
  })
}

// 修改授权状态
export const requestEditStatus = data => {
  return http({
    url: '/dealermanage/deliveryparty/updatePartyEnableStatus',
    method: 'post',
    data: {
      ...data,
      opUserName: getStorage('userInfo').realName
    }
  })
}
