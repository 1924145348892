<template>
  <div>
    <div ref="form">
      <el-form
        :inline="true"
        label-width="120px"
        :model="formInline"
        class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`">
          <template v-if="item.select">
            <request-module-select
              v-model="formInline[item.key]"
              :filterable="true"
              :placeholder="item.label"
              :type="item.model" />
          </template>
          <template v-else>
            <el-input
              v-model="formInline[item.key]"
              :placeholder="`请输入${item.label}`"
              @keyup.enter.native="query"></el-input>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button style="margin-right: 10px" type="primary" @click="query"
            >查询</el-button
          >
          <el-button @click="hanldeDowloadTemplate">下载模板</el-button>
          <el-button @click="() => $refs.fileRef.click()">
            <input
              id="file"
              ref="fileRef"
              @change="handleImportChange"
              hidden
              type="file"
              name="file"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />导入
          </el-button>
          <el-button @click="exportFile">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div :style="{ height: `calc(100vh - ${withoutTableHeight}px - 20px) ` }">
      <el-table
        ref="table"
        border
        :data="dataList"
        style="width: 100%; margin-top: 20px"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        v-loading="loading"
        height="100%">
        <el-table-column prop="shopNumber" label="门店编号" width="140" />
        <el-table-column
          prop="shopName"
          label="门店名称"
          :showOverflowTooltip="true" />
        <el-table-column prop="statusExplain" label="门店状态" width="200">
          <template slot-scope="scope">
            <div class="shopTypeSelect">
              <request-module-select
                :value="scope.row.statusExplain"
                type="shop_state_code"
                :disabled="true"
                placeholder=" " />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="jdCancelBrandTime"
          label="经典-取消授权时间"
          width="130"
          :show-overflow-tooltip="true" />
        <el-table-column label="操作" width="110" fixed="right">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleShowStoreDrawer(scope.row)"
              >修改</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              :underline="false"
              @click="handleShowRecordDrawer(scope.row)"
              >变更记录</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange" />
    </div>
    <storeDrawer
      ref="storeDrawer"
      :storeVisibleDialog.sync="storeVisibleDialog"
      :selectItem="selectItem"
      :updateDetailData="updateDetailData"
      :isOnlyShow="isOnlyShow"
      @onUpdateStore="getDataList"
      @closeVisibleDialog="isOnlyShow = false"
      :cancel="() => (storeVisibleDialog = false)" />
    <shopRecordDrawer
      :selectItem="selectRecordItem"
      @recordDetail="handleRecordDetail"
      ref="recordDrawer" />
  </div>
</template>

<script>
import storeDrawer from './components/storeDrawer'
import shopRecordDrawer from './components/shopRecordDrawer'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import {
  getShopDataList,
  dealermanageShopDownload,
  dealermanageShopImport,
  listpageExporttask
} from '@/api/businessOos'
import { getStorage } from '@/utils/storage'
import { tableHeight } from '@/mixin/tableHeight'
import { uploadFile } from '@/utils/obsUpload'
import throttle from 'lodash/throttle'

const formInlineData = [
  {
    label: '门店编号',
    key: 'shopNumber',
    select: false
  },
  {
    label: '门店名称',
    key: 'shopName',
    select: false
  }
]
let _this = null
export default {
  components: {
    RequestModuleSelect,
    storeDrawer,
    shopRecordDrawer
  },
  mixins: [tableHeight],
  inject: ['addTask'],
  data() {
    return {
      selectList: {},
      formInline: {}, // 搜索条件
      formInlineData,
      dataList: [],
      dataId: '',
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      shopTypeOption: [],
      storeNatureOption: [],
      id: '',
      status: 0,
      loading: false,
      storeVisibleDialog: false, // 门店数据变更
      selectItem: {},
      selectRecordItem: {},
      businessLicenseId: '',
      timer: 0,
      timer_others: 0,
      userInfo: {},
      storeTypeList: [], // 门店二级分类列表
      fullscreenLoading: false,
      authorizedCityName: '',
      updateDetailData: {},
      isOnlyShow: false
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    this.getDataList()
    _this = this
  },
  methods: {
    exportFile: throttle(() => {
      _this.addTask(
        '门店操作日志导出',
        () =>
          listpageExporttask({
            userId: _this.userInfo.accountId,
            userAccount: _this.userInfo.account,
            moduleName: '门店操作日志导出',
            tableName: 'dealer_shop'
          }),
        '1'
      )
    }, 5000),
    async handleImportChange(e) {
      // console.log(e.target.files)
      const { res } = await uploadFile(e.target.files[0])
      //导入excel表格
      const form = new FormData()
      form.append('file', e.target.files[0])
      form.append('createBy', this.userInfo.accountId)
      form.append('createName', this.userInfo.realName)
      form.append('fileUrl', res.requestUrls[0])
      dealermanageShopImport(form).then(res => {
        res?.msg && this.$message.success(res.data || res.msg)
        this.getDataList()
      })
      e.target.value = ''
    },
    hanldeDowloadTemplate: throttle(() => dealermanageShopDownload({}), 500),
    handleRecordDetail(item) {
      this.$refs.storeDrawer.storeVisibleDialog = true
      this.isOnlyShow = true
      this.selectItem = JSON.parse(item.historicalData)
      this.selectItem.updateRemark = item.remark
      this.updateDetailData = JSON.parse(item.afterInfo)
    },
    handleShowRecordDrawer(item) {
      this.isOnlyShow = false
      this.selectRecordItem = item
      this.$refs.recordDrawer.showRecordDrawer = true
    },
    handleShowStoreDrawer(item) {
      this.$refs.storeDrawer.storeVisibleDialog = true
      this.selectItem = item
    },
    // 获取门店列表
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })

      this.loading = true
      const res = await getShopDataList({
        ...this.formInline,
        accountId: this.userInfo.accountId,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      // console.log(res);
      if (res.code === 0) {
        res.data.forEach(item => {
          if (item.decorationTime) {
            const arr = item.decorationTime.split('-')
            if (item.shopType == '1' || item.shopType == '4') {
              arr[0] = String(Number(arr[0]) + 4)
            } else {
              arr[0] = String(Number(arr[0]) + 6)
            }
            item.endDecorationTime = arr.join('-')
          }
        })
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    query() {
      // console.log(this.formInline);
      this.page = 1
      this.getDataList()
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },
    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.selectList = data
      this.id = data.id
      this.authorizedCityName = data.authorizedCityName
      switch (type) {
        case 'boss':
          //查看送达方
          this.getBossCorrelationInfo({
            shopId: data.id,
            userId: this.userInfo.accountId,
            dataType: 'authorized_brand'
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';

:deep(.el-form) {
  .el-input__inner {
    width: 250px;
  }
}

.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 12px;
  color: #409eff;
  &:hover {
    opacity: 0.8;
  }
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        border: none;
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 0px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}
</style>
