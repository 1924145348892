import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus')
      ? !!+Cookies.get('sidebarStatus')
      : true,
    withoutAnimation: false
  },
  device:
    document.body.getBoundingClientRect().width - 1 < 992
      ? 'mobile'
      : 'desktop',
  path: '/welcome',
  elementSize: Cookies.get('elementSize') || 'default',
  formTheme: Cookies.get('formTheme') || 'default',
  isWxworkMoblie:
    /Android|iPhone/gi.test(navigator.userAgent) &&
    navigator.userAgent.includes('wxwork') //判断是否在企业微信移动端环境
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_REDIRECT: (state, path) => {
    state.path = path
  },
  SET_ELEMENTSIZE(state, size) {
    state.elementSize = size
    Cookies.set('elementSize', size)
  },
  SET_FORMTHEME(state, theme) {
    state.formTheme = theme
    Cookies.set('formTheme', theme)
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setRedirect({ commit }, path) {
    commit('SET_REDIRECT', path)
  },
  setElementSize({ commit }, size) {
    commit('SET_ELEMENTSIZE', size)
  },
  setFormTheme({ commit }, theme) {
    commit('SET_FORMTHEME', theme)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
