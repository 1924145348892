<template>
  <div>
    <el-form
      :model="formData"
      ref="ruleForm"
      label-width="180px"
      class="flow-form"
    >
      <el-form-item
        label="流程类型："
        prop="processCode"
        :rules="{
          required: true,
          message: '请选择流程类型',
          trigger: 'change'
        }"
      >
        <el-select v-model="formData.processCode" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提单人：" prop="name">
        <span>{{ userInfo.realName }}</span>
      </el-form-item>
      <el-form-item
        label="系统优化建议（描述）："
        prop="describes"
        :rules="{
          required: true,
          message: '系统优化建议（描述）不能为空',
          trigger: 'blur'
        }"
      >
        <el-input
          type="textarea"
          v-model="formData.describes"
          resize="none"
          rows="8"
        />
      </el-form-item>
      <FileUplodItem
        class="formItem"
        v-model="formData.fileRecordList"
        prop="fileRecordList"
        label="附件："
        @onUploaded="file => handleUpload(file)"
      >
        <div slot="tip">单文件不能超过400M</div>
      </FileUplodItem>
      <el-form-item>
        <el-button type="primary" @click="save">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import FileUplodItem from '@/components/CustomFormItem/requiredFormItemUploadFile'
import { getStorage } from '@/utils/storage'
import { getFlowTypeList } from '@/api/commonConfig/dataManage'
import { saveFeedBack } from '@/api/commonConfig/feedBack'
export default {
  components: {
    FileUplodItem
  },
  data() {
    return {
      formData: {
        issueType: 1
      },
      options: [],
      userInfo: {}
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    this.getFlowType()
  },
  methods: {
    handleUpload(file) {
      console.log(file)
    },
    // 获取流程类型
    async getFlowType() {
      const res = await getFlowTypeList({
        module: 'distributor',
        type: 'flow'
      })
      if (res.code === 0) {
        this.options = res.data.map(item => {
          return {
            value: item.code,
            label: item.content
          }
        })
      }
    },
    save() {
      this.formData = {
        ...this.formData,
        issueType: 1,
        username: this.userInfo.realName,
        account: this.userInfo.code,
        userId: this.userInfo.accountId
      }
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          this.$load.show()
          const res = await saveFeedBack(this.formData)
          this.$load.close()
          if (res.code === 0) {
            this.$confirm('提交成功', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'success'
            })
          }

        } else {
          this.$message.warning('必填项不能为空')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.formItem {
  width: 20%;
}

.required-form-item-input {
  ::v-deep {
    .el-input.is-disabled {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input {
      .el-input__inner[type='number'] {
        padding-right: 0;
      }
    }
  }
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
