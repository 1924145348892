<template>
  <div class="subsidiesSetting">
    <TablePro
      ref="tableProRef"
      :actions="[]"
      :columns="columns"
      :request="requestList"
    />
  </div>
</template>

<script>
import TablePro from '@/components/TablePro'
import * as advSubsidyApi from './apis/advSubsidy'

export default {
  components: {
    TablePro
  },
  data() {
    const columns = [
      { title: '省份', name: 'authorizedProvinceName', search: true },
      { title: '售达方编号', name: 'licenseNumber', search: true },
      { title: '售达方描述', name: 'businessLicenseName' },
      { title: '实控人', name: 'bossName', search: true },
      { title: '授权城市', name: 'authorizedCityName', search: true },
      { title: '授权城市编号', name: 'authorizedCityCode', search: true },
      { title: '品牌', name: 'brandName', search: true, hideInTable: true },
      { title: '城市级别', name: 'marketLevelName' },
      { title: '调整后额度', name: 'remainingAmount' },
      { title: '调整额度', name: 'adjustAmount' },
      { title: '调整方式', name: 'adjustType', renderText: (text) => ['初始化', '人工调整', '流程调整'][text] },
      { title: '调整单号', name: 'orderNumber' },
      { title: '调整人', name: 'createUserName' },
      { title: '调整时间', name: 'createTime' }
    ]
    return {
      columns
    }
  },
  methods: {
    requestList(params) {
      return advSubsidyApi.recordDeclarationPage(params)
    }
  }
}
</script>

