<template>
  <FlowForm
    ref="flowFormRef"
    :fd-form-template-code="fdFormTemplateCode"
    :model="form"
    :is-edit="[0, 20].includes(Number(flowData?.declarationForm?.status))"
    :showSave="false"
    :on-submit="onSubmit"
    :on-approval="onApproval"
    :get-detail="getFlowDetail"
    :approval-data="approvalData"
    submitButtonText="重新发起流程"
    title="专卖店设计及装修流程">
    <template v-for="(formValue, formKey) in dealerKeys">
      <RequiredFormItemInput
        v-if="
          formKey == 'partyBrandNameList'
            ? isClassical
            : ![
                'childrenSupportingAreaFlag',
                'childrenSupportingArea'
              ].includes(formKey)
        "
        delay-update
        class="formItem"
        :style="
          ['partyBrandNameList', 'brandNameList'].includes(formKey)
            ? 'min-width:40%'
            : null
        "
        :prop="formKey"
        :value="form[formKey]"
        :label="formValue"
        :type="
          ['partyBrandNameList', 'brandNameList'].includes(formKey)
            ? 'textarea'
            : 'text'
        "
        placeholder="/"
        :rows="1"
        :required="false"
        :inputProps="{ readonly: true }" />
    </template>

    <HeaderTitle style="margin: 20px 0" title="终端门店信息" />

    <el-divider></el-divider>
    <el-table
      fit
      border
      :data="[form.beforeRenovationShop || {}]"
      style="width: 100%">
      <el-table-column min-width="120" label="序号" type="index" />
      <el-table-column
        v-if="isClassical"
        min-width="120"
        prop="parentShopNumber"
        label="物理门店编号" />
      <el-table-column min-width="120" prop="shopNumber" label="门店编号" />
      <el-table-column min-width="200" prop="shopName" label="门店名称" />
      <el-table-column
        min-width="120"
        prop="storeNature"
        :label="isClassical ? '店态性质' : '门店性质'">
        <template v-slot="{ row }">
          <RequiredModuleFormItemSelect
            disabled
            class="formItem"
            v-model="row.storeNature"
            :showLabel="false"
            type="shop_nature_code" />
        </template>
      </el-table-column>
      <!--经典品牌流程显示-->
      <template v-if="isClassical">
        <template
          v-for="(classicalValue, classicalKey) in {
            createShopType: '开店类型',
            displayShopType: '是否展示店',
            renovationLiablePerson: '装修对接人',
            renovationPersonPhone: '装修对接人电话',
            decorationArea: '装修面积',
            dianjiangDesignerName: '设计师',
            ifDianjiangDesigner: '是否点将设计',
            dianjiangDesignerPrice: '参考费用'
          }">
          <el-table-column
            min-width="120"
            :prop="classicalKey"
            :label="classicalValue" />
        </template>
        <el-table-column
          min-width="180"
          prop="independentDoorHeadFlag"
          label="独立外立面门头">
          <template v-slot="{ row }">
            <RequiredModuleFormItemSelect
              v-model="row.independentDoorHeadFlag"
              disabled
              class="formItem"
              :show-label="false"
              label="独立外立面门头"
              type="independent_door_head_flag_boolean" />
          </template>
        </el-table-column>
        <el-table-column min-width="180" prop="shopType" label="渠道性质">
          <template v-slot="{ row }">
            <RequiredModuleFormItemSelect
              v-model="row.shopType"
              class="formItem"
              type="store_type"
              :show-label="false"
              disabled />
          </template>
        </el-table-column>
        <el-table-column
          min-width="240"
          prop="shopTypeValue"
          label="渠道性质二级分类">
          <template v-slot="{ row }">
            <RequiredModuleFormItemSelect
              v-if="['1', '4'].includes(row.shopType)"
              v-model="row.shopTypeValue"
              class="formItem"
              :show-label="false"
              type="secondary_store_type"
              value-key="content"
              :parent-code="row.shopType"
              :getDataListParams="{ parentType: 'store_type' }"
              disabled />
            <RequiredFormItemInput
              v-else
              v-model.trim="row.shopTypeValue"
              class="formItem"
              :show-label="false"
              disabled />
          </template>
        </el-table-column>
        <el-table-column min-width="180" prop="marketName" label="卖场名称">
          <template v-slot="{ row }">
            <RequiredModuleFormItemSelect
              v-model="row.marketName"
              class="formItem"
              label="卖场名称"
              type="dealer_shop.market_name"
              :show-label="false"
              disabled />
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="marketType" label="卖场类型" />
      </template>

      <el-table-column
        min-width="180"
        prop="shopPositionLevel"
        label="门店位置等级">
        <template v-slot="{ row }">
          <RequiredModuleFormItemSelect
            class="formItem"
            disabled
            v-model="row.shopPositionLevel"
            label="门店位置等级"
            type="shop_level"
            :showLabel="false" />
        </template>
      </el-table-column>
      <el-table-column label="经营模式" width="180">
        <template v-slot="{ row }">
          <RequiredModuleFormItemSelect
            class="formItem"
            disabled
            v-model="row.managementModel"
            :showLabel="false"
            label="经营模式"
            type="management_model" />
        </template>
      </el-table-column>
      <template
        v-for="(shopLabel, shopProp) in {
          province: '省份',
          city: '地级市',
          area: '区/县',
          street: '道路/街道',
          shopFloor: '门店楼层',
          shopFloorNumber: '门店铺号',
          shopAddress: '门店地址',
          brandName: '门店授权系列',
          shopArea: isClassical ? '调整后门店面积' : '门店实用面积',
          openingTime: '开业时间',
          decorationTime: '最新装修时间'
        }">
        <el-table-column min-width="120" :prop="shopProp" :label="shopLabel">
        </el-table-column>
      </template>
    </el-table>

    <div style="height: 30px"></div>

    <RequiredFormItemInput
      disabled
      delay-update
      class="formItem"
      prop="childrenSupportingAreaFlag"
      label="是否儿童配套专区"
      placeholder="/"
      :value="form.childrenSupportingAreaFlag ? `是` : '否'"
      :required="false"
      :inputProps="{ readonly: true }" />
    <RequiredFormItemInput
      disabled
      delay-update
      class="formItem"
      prop="childrenSupportingArea"
      label="儿童配套专区面积"
      placeholder="/"
      :value="form.childrenSupportingArea"
      :required="false"
      :inputProps="{ readonly: true }" />

    <div style="height: 30px"></div>

    <HeaderTitle
      v-if="Object.values(flowFiles).some(file => file.length > 0)"
      style="margin: 20px 0"
      title="关联附件" />

    <template v-for="(fileLabel, fileKey) in fileKeys">
      <RequiredFormItemUploadFile
        v-if="flowFiles[fileKey] && flowFiles[fileKey].length"
        v-model="flowFiles[fileKey]"
        class="formItem"
        :label="fileLabel"
        :required="false"
        :key="fileKey"
        disabled
        limit-size="400">
        <div v-if="fileKey == 'taxpayerProveFileList'" slot="describe">
          1：一般纳税人登记证 或 <br />
          2：税务登记事项通知书或 <br />
          3：网站“全国企业一般纳税人资格查询截图或 <br />
          4：其他途径截图/照片
        </div>
      </RequiredFormItemUploadFile>
    </template>
    <div style="height: 30px"></div>
  </FlowForm>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { mapGetters, mapState } from 'vuex'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect.vue'

import HeaderTitle from '../flow/components/headerTitle'
import FlowForm from './components/flowForm'

import {
  getApprovalData,
  submitApprovalData,
  submitFlow
} from './api/shopDeviseFlowApi.js'
import { getStorage } from '@/utils/storage'

export default {
  name: 'ShopDeviseFlow',
  components: {
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequestModuleSelect,
    HeaderTitle,
    FlowForm
  },
  data() {
    return {
      form: {
        decorationStatus: '1',
        invalid: '',
        isNewDevise: '0',
        flowStatus: '0',
        displayChange: '0',
        supervisorName: '',
        urgentLevelText: ''
      },
      tableData: [],
      dealerKeys: {
        title: '标题',
        orderNumber: '单号',
        zsOrderNumber: '招商单号',
        urgentLevelText: '紧急程度',
        linkOrderTitle: '关联流程',
        linkOrderNumber: '关联流程单号',
        optJobNumber: '提单人工号',
        optUserName: '提单人',
        optDeptName: '提单人部门',
        businessLicenseName: '售达方',
        realName: '经销商（实际控股人）',
        contactInfo: '经销商联系方式',
        bossNumber: '经销商账号',
        authorizedCityName: '授权城市',
        marketLevelName: '城市等级',
        contactPerson: '门店负责人',
        contactPersonPhone: '门店负责人联系方式',
        province: '省份',
        city: '地级市',
        area: '区/县',
        brandNameList: '门店设计品牌',
        partyBrandNameList: '已代理品牌',
        childrenSupportingAreaFlag: '是否儿童配套专区',
        childrenSupportingArea: '儿童配套专区面积'
      },
      //记录附件
      fileKeys: {
        businessLicenseFileList: '营业执照照片/电子档',
        /* 1：一般纳税人登记证 或2：税务登记事项通知书或 3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片 */
        taxpayerProveFileList: '上传附件',
        bankFileList: '开户证明或者网银截图(能显示开户银行全称)',
        drawingAndReportAndTalkFileList: '图纸，考察报告，考察谈话录音',
        promiseAndStoreDrawingFileList: '承诺说明书，商场店面图纸',
        // 加盟
        shopOutsideFileList: '店铺含外围照片不低于5张或视频', //店铺含外围照片不低于5张或视频
        shopMarketInsideLocationFileList: '商场平面图标注慕思店位置', //商场平面图标注慕思店位置
        shopCADFileList: '人流动向店面CAD图纸', //人流动向店面CAD图纸
        shopLeaseContractFileList: '店铺租赁合同', //店铺租赁合同
        integrityCommitmentFileList: '廉洁承诺书', //廉洁承诺书
        bankStatementFileList: '银行流水', //银行流水
        teamPhotoFileList: '团队照片', // 团队照片
        independentShopDoorHeaderFileList: '独立店标注门头位置', //独立店标注门头位置
        openShopPromiseFileList: '开店承诺书', //开店承诺书
        vmdDesignFileList: 'VMD设计需求表', //VMD设计需求表
        dealerBestsignCertificatePhotoFileList: '经销商上上签认证截图', //经销商上上签认证截图
        cityDealerAgreementFileList: '一城多商的协议', //一城多商的协议
        newBusinessContractFileList: '新商合同', //新商合同
        //门店系列管理
        fileRecordList: '老经销商附件', //店铺含外围照片不低于5张或视频（旧名称）
        shopPositionList: '商场平面图标注慕思位置及人流动向、独立店地图定位', //商场平面图标注慕思位置及人流动向、独立店地图定位
        shopCadList: '店铺CAD图纸', // 店铺CAD图纸
        shopLeaseContractList: '店铺租赁合同', //店铺租赁合同
        paymentVoucherList: '新商预打款凭证', // 新商预打款凭证
        //重装附件
        fileList: '店铺含外围照片不低于5张或视频', //默认旧数据，店铺含外围照片不低于5张或视频（必填）
        planeList: '商场平面图标注慕思位置及人流动向、独立店地图定位', //商场平面图标注慕思位置及人流动向、独立店地图定位
        cadList: '店铺CAD图纸', //店铺CAD图纸（必填）
        leaseContractList: '店铺租赁合同', //店铺租赁合同
        commitmentList: '开店承诺书', //开店承诺书
        vmdList: 'VMD设计需求表', //VMD设计需求表
        // 新店
        fileList1: '店铺含外围照片不低于5张或视频', //店铺含外围照片不低于5张或视频（必填）
        fileList2: '商场平面图标注慕思位置及人流动向、独立店地图定位', //商场平面图标注慕思位置及人流动向、独立店地图定位
        fileList3: '店铺CAD图纸', //店铺CAD图纸（必填）
        fileList4: '店铺租赁合同', //店铺租赁合同
        fileList5: '新商预打款凭证', //新商预打款凭证
        fileList6: '开店承诺书', //开店承诺书
        fileList7: 'VMD设计需求表', //VMD设计需求表
        fileList8: '二级分销商身份证照片、分销协议', //二级分销商身份证照片、分销协议
        fileList9: '门店一镜到底视频' //门店一镜到底视频
      },
      shopKeys: {
        parentShopNumber: '物理门店编号',
        shopNumber: '门店编号',
        shopName: '门店名称',
        storeNature: this.isClassical ? '店态性质' : '门店性质',
        createShopType: '开店类型',
        displayShopType: '是否展示店',
        renovationLiablePerson: '装修对接人',
        renovationPersonPhone: '装修对接人电话',
        decorationArea: '装修面积',
        dianjiangDesigner: '设计师工号',
        dianjiangDesignerName: '设计师名称',
        ifDianjiangDesigner: '是否点将设计',
        dianjiangDesignerPrice: '参考费用',
        independentDoorHeadFlag: '独立外立面门头',
        shopType: '渠道性质',
        shopTypeValue: '渠道性质二级分类',
        marketName: '卖场名称',
        marketType: '卖场类型',
        shopPositionLevel: '门店位置等级',
        managementModel: '经营模式',
        province: '省份',
        city: '地级市',
        area: '区/县',
        street: '道路/街道',
        shopFloor: '门店楼层',
        shopFloorNumber: '门店铺号',
        shopAddress: '门店地址',
        brandName: '门店授权系列',
        shopArea: this.isClassical ? '调整后门店面积' : '门店实用面积',
        openingTime: '开业时间',
        decorationTime: '最新装修时间'
      },
      //审批字段
      approvalData: {},
      flowData: {}, //存储流程数据
      flowFiles: [],
      fdFormTemplateCode: 'distributor-JXS008'
    }
  },
  computed: {
    ...mapGetters('dealershipTransfer', ['isOA']),
    isClassical() {
      // ifClassicalFlow:'是否经典流程', // 1是0否
      const { ifClassicalFlow = 0 } = this.flowData
      return ifClassicalFlow == 1
    },
    flowId() {
      const { id, businessId } = this.$route.query
      return id || businessId
    }
  },
  methods: {
    async getFlowDetail() {
      if (!this.flowId) return
      const { code, data } = await getApprovalData({ formId: this.flowId })
      if (code != 0) return
      this.flowData = data
      const {
        declarationForm = {},
        title,
        licenseNumber, // 售达方编号
        brandNameList = [], //品牌数组
        partyBrandNameList = [], //已代理品牌
        approveData = {},
        beforeRenovationShop
      } = data

      this.approvalData = approveData
      console.log('approvalData', this.approvalData)
      console.log('beforeRenovationShop', beforeRenovationShop)

      this.flowFiles = this.mergeOrginIntoKeys(this.fileKeys, data)

      const brandNameListJoin = brandNameList.join('、')

      this.form = {
        ...this.mergeOrginIntoKeys(this.dealerKeys, {
          ...declarationForm,
          ...beforeRenovationShop,
          ...data
        }),
        beforeRenovationShop: {
          ...beforeRenovationShop,
          brandName: brandNameListJoin,
          shopAddress: this.setShopAddress({
            ...beforeRenovationShop,
            brandNameListJoin
          })
        },
        linkOrderTitle: title,
        urgentLevelText: declarationForm.urgentLevel == 0 ? '正常' : '紧急',
        brandNameList: brandNameListJoin,
        partyBrandNameList: partyBrandNameList.join('、')
      }
      console.log(this.form)
    },
    mergeOrginIntoKeys(keys, data) {
      let obj = {}
      Object.keys(keys).forEach(key => {
        if (data[key]) obj = { ...obj, [key]: data[key] }
      })
      return obj
    },
    setShopAddress({
      city = '',
      area = '',
      street = '',
      shopTypeValue = '',
      shopFloor = '',
      shopFloorNumber = '',
      brandNameListJoin = ''
    }) {
      return `${city}${area}${street}${shopTypeValue}${shopFloor}${shopFloorNumber}（${brandNameListJoin}）专卖店`
    },
    setFormData(obj) {
      const formData = new FormData()
      for (const key in obj) {
        formData.append(key, obj[key])
      }
      return formData
    },
    async onApproval(bpmExecuteBean, bpmDealerJoinApproveModel) {
      const userInfo = getStorage('userInfo')

      const formData = {
        declarationForm: {
          id: this.flowId,
          optUserId: userInfo.id, //提单人ID【必须】',
          optUserName: userInfo.realName, //提单人姓名【必须】,
          optJobNumber: userInfo.account //提单人工号【必须】
        },
        approveData: bpmDealerJoinApproveModel,
        applyOrderModel: {
          bpmExecuteBean,
          approveAccount: userInfo.code || userInfo.account, //审批人工号   [必填]',
          systemCode: 'distributor', //来源系统  [必传],
          fdFormTemplateCode: this.fdFormTemplateCode //模板/流程编码,
          // pageVersion: 'V1' //页面版本 当前流程使用的页面版本  [后台触发流程生成]'
        }
      }

      if (Number(this.flowData.declarationForm.status) === 20) {
        this.flowData = {
          ...this.flowData,
          declarationForm: {
            ...this.flowData.declarationForm,
            ...formData.declarationForm
          },
          approveData: {
            ...this.flowData.approveData,
            ...bpmDealerJoinApproveModel
          },
          applyOrderModel: {
            ...this.flowData.applyOrderModel,
            ...formData.applyOrderModel
          }
        }

        return this.$refs.flowFormRef.onCommit('submit')
      }

      return submitApprovalData(formData)
    },
    async onSubmit() {
      return submitFlow(this.flowData)
    }
  }
}
</script>

<style scoped lang="scss">
.oa::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #fff;
}

.file-col {
  margin-bottom: 16px;
}

.flow-form {
  ::v-deep {
    .el-table__row {
      .el-form-item {
        width: 100%;
        margin: 0;
        &.is-error {
          margin-bottom: 22px;
        }
      }
    }
    @media (max-width: 702px) {
      .formItem {
        width: calc(100% - 10px);
      }
    }

    @media screen and (min-width: 702px) and (max-width: 1260px) {
      .formItem {
        width: calc(33% - 10px);
      }
    }
    @media (min-width: 1260px) {
      .formItem {
        width: calc(25% - 10px);
      }
    }
    @media (min-width: 1500px) {
      .formItem {
        width: calc(20% - 10px);
      }
    }
  }
}

.flow-title {
  margin-bottom: 20px;
}
.row-wrap {
  margin-top: 20px;
}
.row-wrap::v-deep.el-row--flex {
  flex-wrap: wrap;
}
.footer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background-color: #fff;
  z-index: 99;
}
.formItem::v-deep.el-form-item {
  margin-bottom: 0;
}
.footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 10px;
  &.oa {
    display: none;
  }
}
</style>
