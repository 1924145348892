<template>
  <div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>{{type=="edit"?'目标概览':'我的年度概览'}}</span>
      </div>
      <div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="省份" name="0"></el-tab-pane>
          <el-tab-pane label="品牌" name="1"></el-tab-pane>
        </el-tabs>
        <div>
          <el-table ref="filterTable" :data="tableData" border style="width: 100%;margin-top:20px" :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }">
            <el-table-column prop="createJobNumber" label="负责人工号" align="center">
            </el-table-column>
            <el-table-column prop="province" label="省份" align="center" v-if="activeName == 0">
            </el-table-column>
            <el-table-column prop="brand" label="品牌" align="center" v-else>
            </el-table-column>
            <el-table-column prop="january" label="1月" align="center">
            </el-table-column>
            <el-table-column prop="february" label="2月" align="center">
            </el-table-column>
            <el-table-column prop="march" label="3月" align="center">
            </el-table-column>
            <el-table-column prop="april" label="4月" align="center">
            </el-table-column>
            <el-table-column prop="may" label="5月" align="center">
            </el-table-column>
            <el-table-column prop="june" label="6月" align="center">
            </el-table-column>
            <el-table-column prop="july" label="7月" align="center">
            </el-table-column>
            <el-table-column prop="august" label="8月" align="center">
            </el-table-column>
            <el-table-column prop="september" label="9月" align="center">
            </el-table-column>
            <el-table-column prop="october" label="10月" align="center">
            </el-table-column>
            <el-table-column prop="november" label="11月" align="center">
            </el-table-column>
            <el-table-column prop="december" label="12月" align="center">
            </el-table-column>
            <el-table-column prop="monthTotal" label="合计" align="center">
            </el-table-column>
          </el-table>
          <div class="total">
            <span>合计：{{sumTotal}}</span>
          </div>
        </div>
      </div>
       <div class="pagination">
          <el-pagination
            background
            :current-page="currentPage"
            :page-sizes="[10, 30, 50]"
            :page-size="pageSize || 10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="currentChange"
          />
        </div>
    </el-card>
  </div>
</template>

<script>
import {getMinePlanDetail} from '@/api/businessPlan'
  export default {
    data() {
      return {
        tableData: [],
        // rowId: [],
        currentPage: 1,
        total: 0,
        pageSize: 10,
        activeName: '0',
        type:this.$route.query.type,
        id:this.$route.query.id,
        sumTotal:0
      }
    },
    created() {
      this.getPlanDetail()
    },
    methods: {
      async getPlanDetail() {
        const res = await getMinePlanDetail({
          activityId:this.id,
          type:'0',
          kye:this.activeName,
          currPage:this.currentPage,
          pageSize:this.pageSize
        })
        if(res.code === 0) {
          this.tableData = res.data
          this.total = res.totalCount
          this.sumTotal = res.data[0]?res.data[0].targetCount: 0
        }
      },
      handleClick() {
        this.currentPage = 1
        this.getPlanDetail()
      },
      handleSelectionChange(val) {
        this.rowId = val.map(item => {
          return item.id
        })
      },
      // 点击行实现点击复选框
      rowClick(row, column, event) {
        this.$refs.filterTable.toggleRowSelection(row)
      },
      // 页面切换
      currentChange(page) {
        this.currentPage = page
        this.getPlanDetail()
      },
      // 页数切换
      handleSizeChange(page) {
        this.currentPage = 1
        this.pageSize = page
        this.getPlanDetail()
      }
    },
  }

</script>

<style lang='scss' scoped>
  .total {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    align-items: center;
    padding-right: 60px;
    width: 100%;
    border: 1px solid #e6ebf5;
    border-top-width: 0;
  }

  .pagination {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

  }

</style>
