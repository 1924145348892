/* eslint-disable */

/** utils start ------------------------------- */

//生成uuId
function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const doc = document

/**
 * 判断目标参数是否为指定类型的对象
 * @param   {Object|null|undefined} source  目标参数
 * @param   {string} match 匹配类型（首字母大写）
 * @return  {boolean}   类型判断结果
 */
export function isType(source, match) {
  return '[object ' + match + ']' === {}.toString.call(source)
}

export function isFunction(source) {
  return isType(source, 'Function')
}

/**
 * 将json结构转为字符串
 * @param json 数据对象
 */
export function jsonStringify(json) {
  var query = ''
  for (var i in json) {
    if (json.hasOwnProperty(i)) {
      query += i + '=' + json[i] + '&'
    }
  }
  return query.substr(0, query.length - 1)
}

export function getBrowserInfo() {
  const scr = window.screen,
    nav = navigator,
    ua = nav.userAgent

  return {
    size: scr.width + 'x' + scr.height,
    // 网络类型
    network:
      nav.connection && nav.connection.effectiveType
        ? nav.connection.effectiveType
        : '-',
    // 语言
    language: nav.language || '',
    //地域时间差
    timeZone: new Date().getTimezoneOffset() / 60 || '',
    //浏览器信息
    userAgent: encodeURIComponent(ua)
  }
}

/**
 * 会话id，刷新页面会更新
 */
export function sessionId() {
  return getUUID()
}

/**
 * 设备id，读取cookie，不存在则种入cookie
 */
export function deviceId() {
  const cookieName = 'dr_device_id'
  var did = getCookie(cookieName)
  if (!did) {
    did = getUUID()
    setCookie(cookieName, did, 365)
  }
  return did
}

/**
 * 白名单校验
 */
export function checkWhiteList(whiteList) {
  if (whiteList.length === 0) {
    return true
  }
  var href = window.location.href
  var flag = false
  for (var i = 0; i < whiteList.length; i++) {
    if (href.indexOf(whiteList[i]) > -1) {
      flag = true
      break
    }
  }
  return flag
}

/**
 * 截取字符串
 * @param str 目标字符串
 * @param start 起始字符串
 * @param end 截取到的字符
 */
export function stringSplice(str, start, end, pass) {
  if (str === '') {
    return ''
  }
  pass = pass === '' ? '=' : pass
  start += pass
  var ps = str.indexOf(start)
  ps = ps > 0 ? ps + start.length : 0
  var pe = str.indexOf(end, ps)
  pe = pe > 0 ? pe : str.length
  return str.substring(ps, pe)
}

/**
 * 设置cookie
 * @param name 名称
 * @param value 值
 * @param days 保存时间
 * @param domain 域
 */
export function setCookie(name, value, days, domain) {
  // 一天的毫秒数
  const DAY = 86400000
  if (value === null) {
    return
  }
  if (domain === undefined || domain === null) {
    // 去除host中的端口部分
    domain = stringSplice(window.location.host, 'host', ':', '')
  }
  if (days === undefined || days === null || days === '') {
    doc.cookie = name + '=' + value + ';domain=' + domain + ';path=/'
  } else {
    var now = new Date()
    var time = now.getTime() + DAY * days
    now.setTime(time)
    doc.cookie =
      name +
      '=' +
      value +
      ';domain=' +
      domain +
      ';expires=' +
      now.toUTCString() +
      ';path/'
  }
}
/**
 * 读取cookie
 * @param name 名称
 */
export function getCookie(name) {
  if (name === undefined || name === null) {
    return
  }
  var reg = RegExp(name)
  if (reg.test(doc.cookie)) {
    return stringSplice(doc.cookie, name, ';', '')
  }
}

/**
 * 函数代理
 * @param {Object} context 环境
 * @param {string} methodName 方法名
 * @param {Function} fn 代理函数
 */
export function proxy(context, methodName, fn) {
  var originalMethod = context[methodName]
  context[methodName] = function () {
    originalMethod.apply(context, arguments)
    if (isFunction(fn)) {
      fn(Array.prototype.slice.call(arguments))
    }
  }
}

/**
 * 获取当前url
 * @returns
 */
export function getPath() {
  try {
    return (
      window.location.pathname + window.location.search + window.location.hash
    )
  } catch (ex) {
    return ''
  }
}

/**
 * 判断是否需要发送日志，可以被 options 覆盖
 * @param newPath {string} 新路径
 * @param oldPath {string} 旧路径
 * @return {boolean}
 */
export function shouldTrackUrlChange(newPath, oldPath) {
  return !!(newPath && oldPath)
}

/**
 * 确保何种 hash 是我们所认为 pageview 事件
 * 只是别 `#/path1/path2` or `#!/path1/path2`
 * 这里主要考虑到单纯以 #path1/path2 `noslash` 用法比较少，同时元素锚点，和百度统计的热力图一定会带去恶意流量，故暂不兼容
 * 后续百度统计的热力图升级完可以考虑兼容 `noslash` 的 hash 路由
 */
export function ensureSlash(path) {
  if (path.charAt(0) === '/' || path.charAt(0) === '!') {
    return true
  }

  return false
}

/** utils end --------------------------------- */

/** url start --------------------------------- */
/**
 * @file: Url utilities.
 */

/* eslint-disable */

/**
 * 判断是否支持 pushstate
 */
export var supportsPushState = (function () {
  var ua = window.navigator.userAgent

  if (
    (ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) &&
    ua.indexOf('Mobile Safari') !== -1 &&
    ua.indexOf('Chrome') === -1 &&
    ua.indexOf('Windows Phone') === -1
  ) {
    return false
  }

  return window.history && 'pushState' in window.history
})()

/**
 * 获取 hash
 */
export var getHash = function () {
  // We can't use window.location.hash here because it's not
  // consistent across browsers - Firefox will pre-decode it!
  var href = window.location.href
  var index = href.indexOf('#')

  // empty path
  if (index < 0) {
    return ''
  }

  href = href.slice(index + 1)
  // decode the hash but not the search or hash
  // as search(query) is already decoded
  // https://github.com/vuejs/vue-router/issues/2708
  var searchIndex = href.indexOf('?')

  if (searchIndex < 0) {
    var hashIndex = href.indexOf('#')
    if (hashIndex > -1) {
      href = decodeURI(href.slice(0, hashIndex)) + href.slice(hashIndex)
    } else {
      href = decodeURI(href)
    }
  } else {
    href = decodeURI(href.slice(0, searchIndex)) + href.slice(searchIndex)
  }

  return href
}

/** url end ----------------------------------- */

/** event start ------------------------------- */

const mtEvent = {
  /**
   * 为目标元素添加事件监听器
   * @param {Node|Window}  element     目标元素.
   * @param {string}              type        事件类型.
   * @param {Function}            listener    需要添加的监听器.
   */

  on: function (element, type, listener) {
    if (element.attachEvent) {
      element.attachEvent('on' + type, function (evt) {
        listener.call(element, evt)
      })
    } else if (element.addEventListener) {
      element.addEventListener(type, listener, false)
    }
  },

  /**
   * 阻止事件的默认行为
   * @param {Event} event 事件对象
   */
  preventDefault: function (event) {
    if (event.preventDefault) {
      event.preventDefault()
    } else {
      event.returnValue = false
    }
  }
}

/** event end ------------------------------ */

class Track {
  constructor(options) {
    this.userId = options.userId || deviceId() //用户Id
    this.whiteList = options.whiteList || [] //白名单
    this.clientKey = options.clientKey //客户端唯一标识
    this.baseUrl =
      'https://atom.derucci.com:9443/internalservice/app/flowdataapi/add'
    this.path = getPath()
    this.bindEvents()
  }

  /**
   * 事件绑定
   */
  bindEvents() {
    if (supportsPushState) {
      // 监听 history
      proxy(history, 'pushState', () => {
        console.log('pushState')
        this.handleUrlChange(true)
      })

      // proxy(history, "replaceState", () => {
      //   console.log("replaceState");
      //   this.handleUrlChange(false);
      // });

      mtEvent.on(window, 'popstate', () => {
        console.log('popstate')
        this.handleUrlChange(true)
      })
    } else {
      mtEvent.on(window, 'hashchange', () => {
        console.log('hashchange')
        ensureSlash() && this.handleUrlChange(true)
      })
    }
  }

  /**
   * history state 发生变化时触发
   * @param {boolean} 是否更新
   */
  handleUrlChange(historyDidUpdate) {
    // 保证处理逻辑在页面逻辑之后执行
    setTimeout(() => {
      var oldPath = this.path
      var newPath = getPath()

      if (shouldTrackUrlChange(newPath, oldPath) && oldPath !== newPath) {
        this.path = newPath

        if (historyDidUpdate) {
          this.sendEventData('visit')
        }
      }
    }, 0)
  }

  //获取发送数据
  getData() {
    var browserInfo = jsonStringify(getBrowserInfo())
    var t = 't=' + new Date().getTime()
    var arr = window.location.href.split('//')
    var source = arr.length > 1 ? arr[1] : arr[0]
    // 完整链接
    var path = 'path=' + encodeURIComponent(window.location.href)
    //去除参数
    // encodeURIComponent(stringSplice(source, "path", "?", ""));
    const reg =
      /[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g
    var title = 'title=' + document.title.replace(reg, '')
    var sid = 'sessionId=' + sessionId()
    var did = 'deviceId=' + deviceId()
    var clientKey = 'clientKey=' + this.clientKey
    return (
      clientKey +
      '&' +
      browserInfo +
      '&' +
      t +
      '&' +
      path +
      '&' +
      title +
      '&' +
      sid +
      '&' +
      did
    )
  }

  /**
   * 上报数据
   * @param evt 事件
   * @param ext 扩展数据
   */
  sendEventData(evt, ext) {
    if (evt === '') {
      return
    }
    let extstr = ''
    if (ext) {
      for (let i in ext) {
        if (ext.hasOwnProperty(i)) {
          extstr += '"' + i + '":"' + ext[i] + '",'
        }
      }
      if (extstr.length > 0) {
        extstr = 'ext={' + extstr.substr(0, extstr.length - 1) + '}'
      }
    }
    const url = `${this.baseUrl}?evt=${evt}&userId=${
      this.userId
    }&${this.getData()}${extstr.length > 0 ? '&' + extstr : ''}`

    this.sendRequest(url)
  }

  sendRequest(url) {
    if (this.whiteList.length && checkWhiteList(this.whiteList)) {
      console.error('域名不在白名单内')
      return
    }
    var img = new Image()
    img.src = url
  }
}

export default Track
