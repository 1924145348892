<template>
  <div>
    <el-form inline>
      <el-form-item label="招商目标/计划名称：">
        <el-input
          v-model="f.title"
          placeholder="请输入招商目标/计划名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="年份：">
        <el-date-picker
          v-model="f.year"
          type="year"
          placeholder="请选择年份"
          value-format="yyyy"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <main>
      <div>
        <el-button type="primary" @click="add">添加计划</el-button>
        <el-button type="warning" @click="edit" :disabled="rowId.length == 0"
          >编辑计划</el-button
        >
        <el-button type="danger" @click="del" :disabled="rowId.length == 0"
          >删除计划</el-button
        >
      </div>
      <el-table
        ref="filterTable"
        :data="tableData"
        border
        style="width: 100%;margin-top:20px"
        @row-click="rowClick"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column prop="title" label="招商目标/计划名称" align="center">
        </el-table-column>
        <el-table-column prop="year" label="年份" align="center">
        </el-table-column>
        <el-table-column prop="status" label="活动状态" align="center">
          <template slot-scope="scope">
            <div>
              <el-tag effect="plain" v-if="scope.row.status === 0">新建</el-tag>
              <el-tag effect="plain" v-else-if="scope.row.status === 10"
                >删除</el-tag
              >
              <el-tag effect="plain" v-else-if="scope.row.status === 20"
                >下发</el-tag
              >
              <el-tag effect="plain" v-else-if="scope.row.status === 30"
                >作废</el-tag
              >
              <el-tag effect="plain" v-else>完成</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="操作" width="380" align="center">
          <template slot-scope="scope">
            <div class="sortList" @click.stop="">
              <span class="sort" @click="aDetail(scope.row.id, 'detail')"
                >查看详情</span
              >
              <span class="sort" @click="detail(scope.row.id, 'detail')"
                >信息收集</span
              >
              <span
                class="sort"
                v-if="scope.row.status === 0"
                @click="detail(scope.row.id, 'edit')"
                >编辑活动</span
              >
              <span
                v-if="scope.row.status === 0"
                class="sort"
                @click="changeStatus(scope.row.id, 20, '下发')"
                >下发</span
              >
              <span
                v-if="scope.row.status === 20"
                class="sort"
                @click="changeStatus(scope.row.id, 30, '作废')"
                >作废</span
              >
              <span
                v-if="scope.row.status === 20"
                class="sort"
                @click="changeStatus(scope.row.id, 40, '完成')"
                >完成</span
              >
              <span
                v-if="scope.row.status === 20"
                class="sort"
                @click="changeStatus(scope.row.id, 50, '撤回')"
                >撤回</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          :current-page="currentPage"
          :page-sizes="[10, 30, 50]"
          :page-size="pageSize || 10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="currentChange"
        />
      </div>
    </main>
    <el-drawer
      :title="title"
      :visible.sync="drawer"
      direction="rtl"
      :size="`${widthStyle};overflow-y:auto`"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-position="labelPosition"
      >
        <el-form-item label="目标/ 计划名称：" prop="title" label-width="160px">
          <el-col :span="12">
            <el-input
              v-model="form.title"
              :readonly="type === 'detail'"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="目标/ 计划年份：" prop="year" label-width="160px">
          <el-col :span="12">
            <el-date-picker v-model="form.year" type="year" value-format="yyyy">
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          label="目标/计划责任人："
          prop="regionUserIdList"
          label-width="160px"
        >
          <el-button @click="dialogVisible = true" v-show="type != 'detail'"
            >点击选择</el-button
          >
          <span style="padding:0 5px"
            >共{{
              form.regionUserIdList && form.regionUserIdList.length
            }}个负责人</span
          >
          <div class="personList">
            <el-tag
              v-for="(item, index) in filterItem(form.regionUserIdList)"
              :key="index"
              type="success"
              style="margin-right:5px"
              >{{ item }}</el-tag
            >
          </div>
        </el-form-item>
        <el-form-item label-width="25px">
          <el-button
            v-if="type !== 'detail'"
            type="primary"
            @click="saveOrUpdate"
            >保存</el-button
          >
          <el-button @click="drawer = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-dialog
      title="选择计划责任人"
      :visible.sync="dialogVisible"
      width="375px"
      :append-to-body="true"
    >
      <div>
        <!-- <el-input
          placeholder="请输入省份名称"
          v-model="province"
          @keydown.native="searchCity"
        ></el-input>
        <div>

        </div> -->
        <TreePersonItem
          v-model="form.regionUserIdList"
          :data="data"
          @handleChange="handleChange"
        />
      </div>
      <span slot="footer" class="dialog-footer" v-if="type !== 'detail'">
        <el-button type="primary" @click="sureCityPersonList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPlanList,
  addOrUpdateActive,
  getActiveInfo,
  activeStatusChange,
  delActive,
  updateMinePlan
} from '@/api/businessPlan'
import TreePersonItem from './components/treePersonItem'
import { getRegionuserTreeData } from '@/api/businessPlan'
import { getStorage } from '@/utils/storage'
let params = {}
let timer = null
export default {
  components: {
    TreePersonItem
  },
  data() {
    return {
      f: {
        title: '',
        year: ''
      },
      tableData: [],
      rowId: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      drawer: false,
      dialogVisible: false,
      widthStyle: '40%',
      title: '添加计划',
      form: {},
      rules: {
        title: [
          {
            required: true,
            message: '请输入目标/ 计划名称',
            trigger: 'blur'
          }
        ],
        year: [
          {
            required: true,
            message: '请选择目标/ 计划年份',
            trigger: 'change'
          }
        ],
        regionUserIdList: [
          {
            required: true,
            message: '请选择目标/计划责任人',
            trigger: 'change'
          }
        ]
      },
      province: '',
      checkList: [],
      cityPersonLabelList: [], // 源数据
      labelList: [],
      labelPosition: 'right',
      userInfo: {},
      type: null,
      // personList: [],
      data: []
    }
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    params = {}
    if (from.name === 'BusinessPlanDetail') {
      params = from.params
    }
    next()
  },
  created() {
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 1200) {
      this.widthStyle = '100%'
      this.labelPosition = 'top'
    }
    this.currentPage = params.page || 1
    this.getDataList()
    this.getTreeData()
    this.userInfo = getStorage('userInfo')
  },
  methods: {
    async getDataList() {
      const res = await getPlanList({
        ...this.f,
        currPage: this.currentPage,
        pageSize: this.pageSize
      })
      // console.log(res)
      const { data, totalCount, code } = res
      if (code === 0) {
        this.tableData = data
        this.total = totalCount
      }
    },
    async getTreeData() {
      const res = await getRegionuserTreeData({
        pageSize: 100
      })
      // console.log(res);
      const treeData = res.data.map(item => {
        item.label = item.provinceName
        item.userList.forEach(i => {
          i.label = i.userName
        })
        return item
      })
      this.data = treeData
    },
    // 获取计划人姓名
    handleChange(list) {
      this.personList = list
    },
    query() {
      this.currentPage = 1
      this.getDataList()
    },
    reset() {
      this.f = {
        name: '',
        year: ''
      }
      this.currentPage = 1
      this.getDataList()
    },
    add() {
      this.drawer = true
      this.title = '添加计划'
      this.type = 'add'
      this.form = {}
      // this.personList = []
    },
    // 删除
    del() {
      if (this.rowId.length == 0) {
        this.$message.warning('请选择需要删除的活动')
        return
      }
      // 检查是否都是新建活动
      const status = this.tableData
        .filter(item => this.rowId.includes(item.id))
        .every(i => i.status === 0)
      if (status) {
        this.$confirm(`是否删除该活动?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await delActive(this.rowId)
          if (res.code === 0) {
            this.getDataList()
            this.$message.success('删除成功')
          }
        })
      } else {
        this.$message.warning('只能删除新建活动')
      }
    },
    async edit() {
      if (this.rowId.length >= 2 || this.rowId.length == 0) {
        this.$message.warning('请选择一条活动编辑')
        return
      }
      this.type = 'edit'
      this.drawer = true
      this.title = '编辑计划'
      const res = await getActiveInfo({ id: this.rowId[0] })
      if (res.code === 0) {
        this.form = res.data
      }
    },
    filterItem(ids) {
      if (!ids) return
      const personNameList = []
      this.data.forEach(item => {
        if (item.userList.filter(list => ids.includes(list.id)).length > 0) {
          const filterList = item.userList.filter(list => ids.includes(list.id))
          personNameList.push(...filterList.map(v => v.userName))
        }
      })
      return personNameList
    },
    async aDetail(id, type) {
      this.drawer = true
      this.title = '查看详情'
      this.type = type
      const res = await getActiveInfo({ id })
      console.log(res)
      if (res.code === 0) {
        this.form = res.data
        // this.personList = this.filterItem(res.data.regionUserIdList)
      }
    },
    detail(id, type) {
      this.$router.push({
        path: '/businessPlan/businessPlanDetail',
        query: {
          type,
          id,
          page: this.currentPage
        }
      })
    },
    // 切换状态
    changeStatus(id, status, message) {
      this.$confirm(`该活动是否${message}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await activeStatusChange({ id, status })
        if (status === 50) {
          await updateMinePlan({ activityId: id, planStatus: 0 })
        }
        if (res.code === 0) {
          this.getDataList()
          this.$message({
            type: 'success',
            message: `活动已${message}`
          })
        }
      })
    },
    // 搜索省份
    searchCity() {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        console.log(this.province)
        if (this.province) {
          this.labelList = this.cityPersonLabelList.filter(item =>
            item.label.includes(this.province)
          )
          // console.log(this.cityPersonLabelList.filter(item=>item.label.includes(this.province)));
        } else {
          this.labelList = this.cityPersonLabelList
        }
      }, 300)
    },
    // 确定目标负责人
    sureCityPersonList() {
      this.dialogVisible = false
    },
    // 保存
    saveOrUpdate() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const res = await addOrUpdateActive({
            ...this.form,
            createUserName: this.userInfo.realName,
            createUserId: this.userInfo.accountId
          })
          // console.log(res)
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            this.drawer = false
            this.currentPage = 1
            this.getDataList()
          }
        } else {
          this.$message.warning('必填项不能为空')
          return false
        }
      })
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.provinceList {
  display: flex;
  flex-direction: column;
  height: 350px;
  overflow-y: auto;

  .el-checkbox {
    margin: 5px 0;
  }
}

.sortList {
  display: flex;
  justify-content: space-around;

  .sort {
    color: $primary-color;
    cursor: pointer;
  }
}
.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.personList {
  margin-top: 5px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  width: 100%;
  min-height: 40px;
  padding: 0 8px;
}
</style>
