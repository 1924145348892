<template>
  <div class="container">
    <FlowForm
      ref="flowFormRef"
      title="慕思经典-经销商主动放弃经销权申请流程"
      :tips="[
        '针对经销商主动提交《经销商主动放弃经销权申请书》并经总部批准进行的市场转让，自主放弃申请一旦签批完成，不可撤回'
      ]"
      fd-form-template-code="distributor-JXS027"
      :model="form"
      :is-edit="[0, 20].includes(Number(form.status))"
      :get-detail="getDetail"
      :on-submit="onSubmit"
      :approval-data="form.approveData"
      :on-approval="onApproval">
      <el-image
        style="width: 100%"
        v-if="isMobile && form.fileRecordList.length"
        :src="`${form.fileRecordList[0].requestDomainName}${form.fileRecordList[0].requestPath}`"></el-image>
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        ">
        <div style="flex: 1">
          <div>
            <RequiredFormItemInput
              delayUpdate
              label="标题"
              class="formItem"
              :value="form.title"
              disabled
              :required="false" />
            <RequiredFormItemInput
              delayUpdate
              label="单号"
              class="formItem"
              :value="form.orderNumber"
              placeholder="自动生成"
              disabled
              :required="false" />
            <RequiredFormItemInput
              delayUpdate
              label="招商单号"
              class="formItem"
              :value="form.zsOrderNumber"
              placeholder="自动生成"
              disabled
              :required="false" />
          </div>
          <HeaderTitle style="margin-top: 20px" title="经销商个人基础信息" />
          <div>
            <div>
              <RequiredFormItemInput
                class="formItem"
                label="提单人工号"
                :value="form.optJobNumber"
                placeholder="自动生成"
                disabled />
              <RequiredFormItemInput
                class="formItem"
                label="提单人"
                :value="form.optUserName"
                placeholder="自动生成"
                disabled />
              <RequiredFormItemInput
                class="formItem"
                label="提单部门"
                :value="form.optDeptName"
                placeholder="自动生成"
                disabled />
              <RequiredFormItemInput
                class="formItem"
                label="提单日期"
                :value="form.optTime"
                placeholder="自动生成"
                disabled />
            </div>

            <RequiredFormItemSelect
              class="formItem"
              ref="businessRef"
              prop="crmDistributorCode"
              v-model="form.crmDistributorCode"
              label="经销商（实际控股人）"
              labelKey="dealerName"
              valueKey="dealerCode"
              :selectProps="{
                filterable: true,
                remote: true,
                'popper-class': 'custom-business-select'
              }"
              :total-count="dealerListCount"
              :getDataList="getDealerList"
              :getNextList="getDealerList"
              @onOptionsListNotFound="onDealerNotFound"
              @change="onDealerChange"
              @changeSelectedItem="
                e => {
                  selectedDealerItem = e
                  form.crmDistributorId = e ? e.id : ''
                  form.crmDistributorName = e ? e.dealerName : ''
                  form.crmDistributorCode = e ? e.dealerCode : ''
                  form.bossId = e ? e.bossId : ''
                  form.authorizedCityName = e ? e.authorizedCityName : ''
                  form.authorizedCityCode = e ? e.authorizedCityCode : ''
                  if (e) {
                    getAuthorizedCityDetail(e.authorizedCityCode)
                    getDealerBossDetail(e.bossId)
                    requestGetDealerData(e.dealerCode)
                    requestSeries(e.dealerCode)
                    reqestMarketLevelName()
                  }
                }
              ">
              <template v-slot="{ scope }">
                <span>{{ scope.row.dealerCode }}</span>
                <small>{{ scope.row.dealerName }}</small>
              </template>
            </RequiredFormItemSelect>
            <RequiredFormItemInput
              class="formItem"
              label="经销商（实际控股人）联系方式"
              :value="form.bossContactInfo"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemInput
              class="formItem"
              label="授权城市"
              :value="form.authorizedCityName"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemInput
              class="formItem"
              label="省份"
              :value="form.province"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemInput
              class="formItem"
              label="城市"
              :value="form.city"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemInput
              class="formItem"
              label="区/县"
              :value="form.area"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemInput
              class="formItem"
              label="已代理系列"
              :value="form.seriesNames"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemSelect
              class="formItem"
              ref="businessRef"
              prop="licenseNumber"
              v-model="form.licenseNumber"
              label="售达方名称"
              labelKey="businessLicenseName"
              valueKey="licenseNumber"
              :disabled="!form.crmDistributorCode"
              :getDataListParams="{ dealerCode: form.crmDistributorCode }"
              :selectProps="{
                filterable: true,
                remote: true,
                'popper-class': 'custom-business-select'
              }"
              :total-count="businessLicensesCount"
              :getDataList="getBusinessLicenses"
              :getNextList="getBusinessLicenses"
              @onOptionsListNotFound="onBusinessLicensesNotFound"
              @changeSelectedItem="onBusinessLicenseSelect">
              <template v-slot="{ scope }">
                <span>{{ scope.row.licenseNumber }}</span>
                <small>{{ scope.row.businessLicenseName }}</small>
              </template>
            </RequiredFormItemSelect>
            <el-form-item class="formItem" label="近三年提货业绩（元）">
              <div v-for="(item, index) in form.remarkMap" :key="index">
                <div>{{ item.year_desc }}年</div>
                <el-input
                  :value="item.ship_inv_rb_add_amt_cur"
                  readonly></el-input>
              </div>
            </el-form-item>
            <el-form-item class="formItem" label="近三年提货业绩增长率">
              <div
                v-for="(item, index) in form.remarkMapGrowthRate"
                :key="index">
                <div>{{ item.year_desc }}年</div>
                <el-input
                  :value="`${(item.growth_rate * 100).toFixed(2)}%`"
                  readonly></el-input>
              </div>
            </el-form-item>
            <el-form-item class="formItem" label="同级别市场排名">
              <div>
                <div v-for="(item, index) in form.levelSort" :key="index">
                  <div>{{ item.year_desc }}年</div>
                  <el-input :value="item.row_index" readonly></el-input>
                </div>
              </div>
            </el-form-item>
            <RequiredFormItemInput
              class="formItem"
              label="营销中心编码"
              :value="form.approveData.smallRegionCode"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemInput
              class="formItem"
              label="营销中心名称"
              :value="form.approveData.smallRegionName"
              placeholder="自动生成"
              disabled />
            <RequiredFormItemInput
              class="formItem"
              label="市场级别名称"
              :value="form.approveData.marketLevelName"
              placeholder="自动生成"
              disabled />
            <div>
              <RequiredFormItemInput
                delay-update
                v-model="form.abandoningReason"
                style="width: 100%"
                class="formItem"
                label="申请自主放弃经销权原因" />
            </div>
            <div>
              <RequiredFormItemUploadFile
                style="width: 100%"
                v-model="form.fileRecordList"
                class="formItem"
                :limit="1"
                limit-size="400"
                prop="fileRecordList"
                label="上传附件"
                auto-upload>
                <template v-slot:describe>
                  <div>
                    <p style="color: red">经销商必须上传盖章签字版文件</p>
                    <div v-if="!isOA">
                      <FilePreview
                        :isPermission="form.businessLicenseName ? true : false"
                        @showTip="
                          () => {
                            Message.error('请选择售达方')
                          }
                        ">
                        <FileDom :form="form"></FileDom>
                      </FilePreview>
                    </div>
                  </div>
                </template>
                <template v-slot:tip>
                  <div>单个文件不能超过400M</div>
                </template>
              </RequiredFormItemUploadFile>
            </div>

            <div>
              <RequiredFormItemInput
                delay-update
                v-model="form.remark"
                style="width: 100%"
                class="formItem"
                :required="false"
                :inputProps="{
                  type: 'textarea',
                  resize: 'none',
                  rows: 6,
                  maxlength: '500',
                  showWordLimit: true
                }"
                label="备注" />
            </div>
          </div>
        </div>
        <div
          v-if="!isMobile"
          class="docxTemplate"
          style="border: 1px solid #000; flex-shrink: 0">
          <FileDom :form="form" style=""></FileDom>
        </div>
      </div>
    </FlowForm>
  </div>
</template>

<script setup>
import FilePreview from '@/views/bpmFlow/components/FilePreview.vue'
import FileDom from '@/views/bpmFlow/components/FileDom.vue'
import { ref, reactive, computed, onMounted } from 'vue'
import { Message } from 'element-ui'
import dateFormat from 'dateformat'
import FlowForm from '@/views/bpmFlow/components/flowForm.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import HeaderTitle from '@/views/bpmFlow/components/headerTitle.vue'
import store from '@/store'
import { useRoute, useRouter } from '@/utils/router'
import {
  getBusinessCityList,
  getBossDetail,
  getDealerBrand
} from '@/api/businessOos'
import {
  getByAuthorizedCityCode,
  requestGetSizeBarListByDept,
  requestGetBusinesslicense
} from '@/api/common'
import { requestGetPickUp } from '@/views/flow/api/common'
import * as dealerGiveUpDealershipApi from '@/views/bpmFlow/api/dealerGiveUpDealership'
import { authorizedCityAndLevel } from '@/api/flowSetting/cityInfoManage'
import { getStorage, getSession } from '@/utils/storage'

const $route = useRoute()
const routeQuery = $route.query
const flowFormRef = ref()
const user = store.state.user.userInfo
const isOA = computed(() => store.state.dealershipTransfer.isOA)
const isMobile = computed(
  () => store.state.app.device === 'mobile' || store.state.app.isWxworkMoblie
)
const form = ref({
  id: '', //表单id
  title: '慕思经典-经销商主动放弃经销权申请流程', //标题
  orderNumber: '', //单号
  zsOrderNumber: '', //招商单号
  optJobNumber: '', //提单人工号
  optUserId: '', //提单人id
  optUserName: '', //提单人
  optDeptId: '', //提单部门id
  optDeptName: '', //提单部门
  optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'), //提单日期
  crmDistributorId: '', //经销商id
  crmDistributorCode: '', //经销商编号
  crmDistributorName: '', //经销商名称
  bossId: '', //实控人id
  bossNumber: '', //实控人编号
  bossName: '', //实控人名称
  bossContactInfo: '', //经销商（实际控股人）联系方式
  bossIdCard: '', //实控人身份证号码
  businessLicenseId: '', //售达方ID
  licenseNumber: '', //售达方编号
  businessLicenseName: '', //售达方名称
  authorizedCityCode: '', //授权城市编号
  authorizedCityName: '', //授权城市名称
  province: '', //省
  city: '', //市
  area: '', //区
  bigBrandId: '1390119720428154881', ////大品牌ID
  bigBrandCode: 'C', //大品牌编码
  bigBrandName: '慕思经典', //大品牌名称
  seriesNames: '', //当前代理的系列名称 多个用英文逗号隔开
  remarkMap: [], //近三年提货业绩
  remarkMapGrowthRate: [], //近三年提货业绩 增长率
  levelSort: [], //本年度同级别市场排名
  abandoningReason: '', //申请自主放弃经销权原因
  fileRecordList: [], //附件
  remark: '', //备注
  status: 0,
  applyOrderModel: {
    approveAccount: '',
    businessId: '',
    processInstanceId: '',
    bpmCreateBean: {},
    bpmExecuteBean: {},
    fdFormTemplateCode: '',
    pageVersion: '',
    systemCode: ''
  },
  approveData: {
    smallRegionCode: '', //小区(营销中心)编码"
    smallRegionName: '', //小区(营销中心)名称"
    marketLevelName: '' //市场级别名称"
  }
})
const businessLicensesCount = ref(0)
const selectedBusinessLicenseItem = ref()
const dealerListCount = ref(0)
const selectedDealerItem = ref()
const getDealerList = async ({ page, searchText }) => {
  const systemCode = getSession('systemCode') || process.env.VUE_APP_SYSTEM_CODE
  const res = await getBusinessCityList({
    currPage: page,
    dealerName: searchText,
    ifEnabled: 0,
    bossId: systemCode == '120' ? getStorage('bossInfo')?.id : ''
  })
  dealerListCount.value = res.totalCount
  const item = res.data.find(
    item => item.dealerCode === form.value.crmDistributorCode
  )
  if (item) {
    form.value.crmDistributorId = item.id
    form.value.crmDistributorName = item.dealerName
    form.value.crmDistributorCode = item.dealerCode
    form.value.bossId = item.bossId
    form.value.authorizedCityName = item.authorizedCityName
    form.value.authorizedCityCode = item.authorizedCityCode
  }
  return res.data
}
const onDealerNotFound = async ({ value: dealerCode }, callback) => {
  const res = await getBusinessCityList({
    dealerCode
  })
  callback(res.data)
}
const onDealerChange = () => {
  form.value.bossId = ''
  form.value.bossName = ''
  form.value.bossNumber = ''
  form.value.bossContactInfo = ''
  form.value.bossIdCard = ''
  form.value.province = ''
  form.value.city = ''
  form.value.area = ''
  form.value.town = ''
  form.value.authorizedCityName = ''
  form.value.authorizedCityCode = ''
  form.value.seriesNames = ''
  form.value.businessLicenseId = ''
  form.value.businessLicenseName = ''
  form.value.licenseNumber = ''
  form.value.remarkMap = []
  form.value.remarkMapGrowthRate = []
  form.value.levelSort = []
  form.value.approveData = {
    smallRegionCode: '',
    smallRegionName: '',
    marketLevelName: ''
  }
}
const getBusinessLicenses = async ({ page, searchText, getDataListParams }) => {
  const res = await requestGetBusinesslicense({
    currPage: page,
    businessLicenseName: searchText,
    ...getDataListParams
  })
  businessLicensesCount.value = res.totalCount
  return res.data
}
const onBusinessLicensesNotFound = async (
  { value: licenseNumber },
  callback
) => {
  const res = await requestGetBusinesslicense({
    licenseNumber
  })
  callback(res.data)
}
const onBusinessLicenseSelect = item => {
  // businessLicenseId:"", //售达方ID
  //     licenseNumber:"", //售达方编号
  //     businessLicenseName:"", //售达方名称
  if (item) {
    selectedBusinessLicenseItem.value = item
    form.value.businessLicenseId = item.id
    form.value.businessLicenseName = item.businessLicenseName
    form.value.licenseNumber = item.licenseNumber
    return
  }
  selectedBusinessLicenseItem.value = {}
  form.businessLicenseId = ''
  form.businessLicenseName = ''
  form.licenseNumber = ''
}
const getAuthorizedCityDetail = async authorizedCityCode => {
  const res = await getByAuthorizedCityCode({ authorizedCityCode })
  const {
    province = '',
    provinceCode = '',
    city = '',
    cityCode = '',
    area = '',
    areaCode = ''
  } = res.data
  form.value.province = province
  form.value.city = city
  form.value.area = area
  await requestSmallRegion({
    provinceCode,
    cityCode,
    areaCode
  })
}
const getDealerBossDetail = async bossId => {
  const res = await getBossDetail({ id: bossId })
  if (res.data && res.data.length) {
    const {
      bossNumber = '',
      realName = '',
      contactInfo = '',
      idCard = ''
    } = res.data[0]
    form.value.bossName = realName
    form.value.bossNumber = bossNumber
    form.value.bossContactInfo = contactInfo
    form.value.bossIdCard = idCard
  }
}
const requestGetDealerData = async dealerCode => {
  const dealerData = await requestGetPickUp({
    dealer_code: dealerCode,
    year_desc: new Date().getFullYear()
  })
  const remarkMap = []
  const remarkMapGrowthRate = []
  const levelSort = []
  if (dealerData?.data && dealerData?.data?.length) {
    const data =
      dealerData.data.sort((a, b) => {
        return Number(b.year_desc) - Number(a.year_desc)
      }) || []
    data.forEach(item => {
      remarkMap.push({
        year_desc: item.year_desc,
        ship_inv_rb_add_amt_cur: item.ship_inv_rb_add_amt_cur
      })
      remarkMapGrowthRate.push({
        year_desc: item.year_desc,
        growth_rate: item.growth_rate
      })
      levelSort.push({ year_desc: item.year_desc, row_index: item.row_index })
    })
    form.value.remarkMap = remarkMap
    form.value.remarkMapGrowthRate = remarkMapGrowthRate
    form.value.levelSort = levelSort
  }
}
const requestSeries = async dealerCode => {
  const res = await getDealerBrand({
    dealerCode,
    bigBrandCode: form.value.bigBrandCode
  })
  form.value.seriesNames = res.data.map(item => item.name).join(',')
}
const reqestMarketLevelName = async () => {
  const res = await authorizedCityAndLevel({
    overseasFlag: 0,
    authorizedCityCode: form.value.authorizedCityCode,
    brandId: form.value.bigBrandId
  })
  form.value.approveData.marketLevelName = res.data.length
    ? res.data[0].marketLevelName
    : ''
}
const requestSmallRegion = async params => {
  const res = await requestGetSizeBarListByDept(params)
  form.value.approveData.smallRegionCode = res.data?.smallCode
  form.value.approveData.smallRegionName = res.data?.smallName
}
const onApproval = async (bpmExecuteBean, applyOrderModel) => {
  // console.log('审批',bpmExecuteBean, applyOrderModel)
  if (Number(form.value.status) === 20) {
    form.value.applyOrderModel = {
      ...form.value.applyOrderModel,
      ...applyOrderModel
    }
    form.value.applyOrderModel.bpmExecuteBean = bpmExecuteBean
    return flowFormRef.value.onCommit('submit')
  }

  return dealerGiveUpDealershipApi.submitApproveData({
    id: routeQuery.id || routeQuery.businessId,
    optUserId: user.accountId,
    optUserName: user.realName,
    optJobNumber: user.code || user.account,
    approveData: form.value.approveData,
    applyOrderModel: {
      ...form.value.applyOrderModel,
      ...applyOrderModel,
      approveAccount: user.code || user.account,
      bpmExecuteBean
    }
  })
}
const getDetail = async (type, cachedFormData) => {
  let initFormData = cachedFormData
  // 编辑状态
  if (type === 'detail') {
    const { data } = await dealerGiveUpDealershipApi.getDetail({
      formId: routeQuery.id || routeQuery.businessId
    })
    initFormData = data
  }

  if (initFormData) {
    for (const key in form.value) {
      if (initFormData[key] !== undefined) {
        if (['remarkMap', 'remarkMapGrowthRate', 'levelSort'].includes(key)) {
          const keyValue = initFormData[key]
          form.value[key] = Array.isArray(keyValue)
            ? keyValue
            : JSON.parse(keyValue)
        } else {
          form.value[key] = initFormData[key]
        }
      }
    }
  }

  if (user && !isOA.value) {
    const {
      optUserId,
      optJobNumber,
      optUserName,
      optDeptId,
      optDeptName,
      ...rest
    } = form.value || {}
    form.value = {
      ...rest,
      optUserId: optUserId || user.accountId,
      optJobNumber: optJobNumber || user.code,
      optUserName: optUserName || user.realName,
      optDeptId: optDeptId || user.deptId,
      optDeptName: optDeptName || user.deptName
    }
  }
}
const onSubmit = async type => {
  const remarkMap = JSON.stringify(form.value.remarkMap)
  const remarkMapGrowthRate = JSON.stringify(form.value.remarkMapGrowthRate)
  const levelSort = JSON.stringify(form.value.levelSort)
  const time = dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss')
  form.value.optTime = time
  form.value.applyOrderModel.approveAccount = user.code || user.account
  form.value.applyOrderModel.fdFormTemplateCode = 'distributor-JXS027'
  form.value.applyOrderModel.pageVersion = 'V1'
  form.value.applyOrderModel.systemCode = 'distributor'
  form.value.title = `慕思经典-经销商主动放弃经销权申请流程(${
    form.value.authorizedCityName || ''
  },${form.value.bossName || ''},慕思经典})`
  if (type !== 'submit') {
    return dealerGiveUpDealershipApi.saveDraft({
      ...form.value,
      remarkMap,
      remarkMapGrowthRate,
      levelSort
    })
  }
  return dealerGiveUpDealershipApi.submit({
    ...form.value,
    remarkMap,
    remarkMapGrowthRate,
    levelSort
  })
}
</script>

<style lang="scss">
.container .docxTemplate .title {
  font-size: 16px;
}
.docxTemplate {
  width: 375px;
  height: 667px;
  margin: 0 auto;
  padding: 0 10px;
  font-size: 12px;
  line-height: 2;
  word-break: break-all;
  text-align: justify;
}
</style>
