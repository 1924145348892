<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <!-- <theme-picker style="vertical-align: top;margin-right:20px;line-height:60px;"
                    @change="themeChange" /> -->
      <slot name="right-menu" />
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <!-- <img
            src="https://consumer.derucci.net/static/img/logo.c8d2606c.png"
            class="user-avatar"
          > -->
          <span>{{ userInfo.nickName || userInfo.realName }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item> 首页 </el-dropdown-item>
          </router-link>
          <!-- <el-dropdown-item divided @click.native="dialogFormVisible = true">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item> -->
          <el-dropdown-item divided @click.native="onLogout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <template>
        <el-tooltip content="版本更新" effect="dark" placement="bottom">
          <div class="right-menu-item hover-effect">
            <HitoryNotify />
          </div>
        </el-tooltip>
      </template> -->
      <!-- <template>
        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
      </template> -->
      <template>
        <el-tooltip content="系统设置" effect="dark" placement="bottom">
          <div class="right-menu-item hover-effect">
            <i class="el-icon-setting" @click="themeVisibleDialog = true" />
          </div>
        </el-tooltip>
      </template>
    </div>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      width="375px"
      center="">
      <el-form
        :model="userStatus"
        :rules="rules"
        ref="ruleForm"
        label-width="90px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="userStatus.oldPassword"
            show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="userStatus.newPassword"
            show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="sureNewPassword">
          <el-input
            type="password"
            v-model="userStatus.sureNewPassword"
            show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </div>
    </el-dialog>
    <Theme
      :themeVisibleDialog="themeVisibleDialog"
      :cancel="() => (themeVisibleDialog = false)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import SizeSelect from '@/components/SizeSelect'
import HitoryNotify from '@/components/HistoryNotify'
// import FormThemeSelect from "@/components/FormThemeSelect";
// import ThemePicker from '@/components/ThemePicker'
import ThemeSelect from '@/components/ThemeSelect'
import Theme from './Theme.vue'
import { getStorage, removeStorage } from '@/utils/storage'
import { updatePassword } from '@/api/user'
import md5 from 'md5'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    SizeSelect,
    ThemeSelect,
    // FormThemeSelect
    // ThemePicker
    Theme,
    HitoryNotify
  },
  data() {
    return {
      userInfo: getStorage('userInfo') || {},
      dialogFormVisible: false,
      userStatus: {
        oldPassword: '',
        newPassword: '',
        sureNewPassword: ''
      },
      themeVisibleDialog: false,
      rules: {
        oldPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入旧密码'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入新密码'))
              } else if (/\s/g.test(value)) {
                callback(new Error('密码不能包含空格'))
              } else if (
                !/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{7,20}$/g.test(
                  value
                )
              ) {
                callback(new Error('密码必须包含字母、数字、特殊字符'))
              } else if (value.length < 7) {
                callback(new Error('密码必须大于6位'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        sureNewPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.userStatus.newPassword) {
                callback(new Error('两次输入密码不一致!'))
              } else if (value.length < 6) {
                callback(new Error('密码不能少于6位'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar'])
  },
  methods: {
    ...mapActions('user', ['logout']),
    themeChange(val) {
      this.$store.dispatch('app/toggleSideBar')
      this.$store.dispatch('settings/changeTheme', {
        key: 'theme',
        value: val
      })
      this.$store.dispatch('app/toggleSideBar')
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async onLogout() {
      this.logout()
      location.href = process.env.VUE_APP_SSO_LOGOUT
      // this.$router.push(`/login?exit=1&redirect=${this.$route.fullPath}`)
    },
    changePassword() {
      // console.log(this.userInfo)
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const formData = {
            accountId: this.userInfo.accountId,
            oldPassword: md5(this.userStatus.oldPassword),
            newPassword: this.userStatus.newPassword
          }
          const res = await updatePassword(formData)
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: res.data
            })
            this.userStatus = {
              oldPassword: '',
              newPassword: '',
              sureNewPassword: ''
            }
            this.dialogFormVisible = false
          }
        } else {
          this.$message.warning('数据异常,请检查！')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-dropdown {
  text-align: center;
  .el-dropdown-menu__item--divided:before {
    height: 0;
  }
}
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 10px;
      vertical-align: top;

      .avatar-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          margin-right: 5px;
        }

        .el-icon-caret-bottom {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
