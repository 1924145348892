<template>
  <el-card v-if="searchColumns.length" class="searchForm" v-bind="cardProps">
    <el-form
      ref="searchFormRef"
      :model="params"
      v-bind="formProps"
      @submit.native.prevent="onSearch"
      :class="{ 'action-form-mobile': checkMobile }"
    >
      <template v-if="searchLayout === 'inline'">
        <FormColumnRender
          v-for="(columnItem, columnIndex) of searchColumns"
          :key="columnItem.name"
          v-model="params[columnItem.name]"
          :params="params"
          :column-item="columnItem"
        />
        <el-form-item class="action-form-item">
          <el-button type="primary" native-type="submit">查询</el-button>
          <el-button @click="onReset">重置</el-button>
          <slot name="actionArea" />
        </el-form-item>
      </template>
      <el-row v-if="searchLayout === 'normal'" type="flex" :gutter="10">
        <el-col
          v-for="(columnItem, columnIndex) of searchColumns"
          :key="columnItem.name"
          v-bind="{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }"
          :class="hiddenSearchCol(columnIndex)"
        >
          <FormColumnRender
            v-model="params[columnItem.name]"
            :params="params"
            :column-item="columnItem"
          />
        </el-col>
        <el-col
          :xs="offsetComputed(1)"
          :sm="offsetComputed(2)"
          :md="offsetComputed(2)"
          :lg="offsetComputed(3)"
          :xl="offsetComputed(4)"
        >
          <el-form-item class="action-form-item" label="操作">
            <el-button type="primary" native-type="submit">查询</el-button>
            <template v-if="screenColumns.length">
              <PopupForm
                ref="screenPopupFormRef"
                :columns="screenColumns"
                popup-type="drawer"
                title="筛选"
                :on-submit="onScreen"
                :drawer-props="{ class: 'screen-drawer-container' }"
                width="700px;"
              >
                <template #trigger="{ onShow }">
                  <el-button style="margin: 0 10px" type="warning" @click="onShow(screenParams)"
                    >筛选</el-button
                  >
                </template>
                <template #footer="{ onSubmit, onClear }">
                  <el-form-item class="footer">
                    <!-- onSearch自带一次转换，这里配置关闭转换值isTransformParams=false -->
                    <el-button
                      v-loading-click="() => onSubmit({ isTransformParams: false })"
                      type="primary"
                    >
                      筛选
                    </el-button>
                    <el-button @click="onClear">清空</el-button>
                  </el-form-item>
                </template>
              </PopupForm>
            </template>
            <el-button @click="onReset">重置</el-button>
            <slot name="actionArea" />
            <el-button
              :class="{
                'hidden-xs-only': searchColumns.length <= 1,
                'hidden-sm-only': searchColumns.length <= 2,
                'hidden-md-only': searchColumns.length <= 2,
                'hidden-lg-only': searchColumns.length <= 3,
                'hidden-xl-only': searchColumns.length <= 4
              }"
              type="text"
              @click="unfoldSearchFormIetm = !unfoldSearchFormIetm"
              >{{ !unfoldSearchFormIetm ? '展开' : '收起'
              }}<i :class="`el-icon-arrow-${!unfoldSearchFormIetm ? 'down' : 'up'}`" />
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="Object.keys(dontEmptyScreenParams).length" class="screen-container">
      <div class="title">已选筛选条件</div>
      <div class="screen-content">
        <div v-for="(value, key) of dontEmptyScreenParams" :key="key" class="screen-item">
          <span class="label">{{ screenColumns.find((item) => item.name === key).title }}：</span>
          <el-tag size="medium" closable style="margin-right: 5px" @close="onDeleteScreenItem(key)">
            {{ getScreenDisplayValue(key, value) }}
          </el-tag>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { transformParams, deepCopy, isEmpty, onInitParams, isMobile } from './fn'
import PopupForm from '@/components/FormPro/PopupForm.vue'
import FormColumnRender from '@/components/FormPro/FormColumnRender.vue'
import { isEqual } from 'element-ui/src/utils/util'

export default {
  components: {
    PopupForm,
    FormColumnRender
  },
  model: {
    prop: 'searchParams',
    event: 'input'
  },
  props: {
    searchParams: {
      type: Object,
      default: () => ({})
    },
    searchLayout: {
      type: String,
      default: 'normal'
    },
    formProps: {
      type: Object,
      default: () => ({
        'label-position': 'top'
      })
    },
    columns: {
      type: Array,
      default: () => []
    },
    cardProps: {
      type: Object,
      default: () => ({})
    },
    defaultUnfold: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      params: {},
      screenParams: {},
      unfoldSearchFormIetm: this.defaultUnfold
    }
  },
  computed: {
    checkMobile() {
      return isMobile()
    },
    dontEmptyScreenParams({ screenParams }) {
      const result = {}
      for (const key in screenParams) {
        if (!this.screenColumns.some((item) => item.name === key)) {
          continue
        }
        if (!isEmpty(screenParams[key])) {
          result[key] = screenParams[key]
        }
      }
      return result
    },
    searchColumns({ columns }) {
      return columns.filter((item) => item.search && !item.hideInSearch)
    },
    screenColumns({ columns }) {
      return (
        columns
          .filter((item) => item.screen)
          // 把search.transform移除，最终提交才执行
          .map(({ search, ...item }) => item)
          .sort((a, b) => {
            if (a.screenOrder === undefined) {
              a.screenOrder = a.order
            } else if (b.screenOrder === undefined) {
              b.screenOrder = b.order
            }

            if (a.screenOrder === undefined) return 1
            else if (b.screenOrder === undefined) return -1
            return b.screenOrder - a.screenOrder
          })
      )
    },
    hiddenSearchCol({ unfoldSearchFormIetm, searchColumns }) {
      return (index) =>
        !unfoldSearchFormIetm
          ? {
              'hidden-xs-only': !(searchColumns.length <= 1 || index < 1),
              'hidden-sm-only': !(searchColumns.length <= 2 || index < 1),
              'hidden-md-only': !(searchColumns.length <= 2 || index < 1),
              'hidden-lg-only': !(searchColumns.length <= 3 || index < 2),
              'hidden-xl-only': !(searchColumns.length <= 4 || index < 3)
            }
          : null
    }
  },
  watch: {
    columns: {
      // immediate: true,
      handler() {
        this.onInit()
      }
    },
    params: {
      deep: true,
      handler(params) {
        this.$emit('input', params)
        // 相同key值合并
        for (const key in this.screenParams) {
          if (isEqual(this.screenParams[key], params[key])) return
          this.screenParams[key] = params[key]
        }
      }
    },
    screenParams: {
      deep: true,
      handler(screenParams, oldScreenParams) {
        const noEmptyScreenParams = {}
        const noEmptyOldScreenParams = {}
        for (const key in screenParams) {
          if (!isEmpty(screenParams[key])) {
            noEmptyScreenParams[key] = screenParams[key]
          }
        }
        for (const key in oldScreenParams) {
          if (!isEmpty(oldScreenParams[key])) {
            noEmptyOldScreenParams[key] = oldScreenParams[key]
          }
        }
        const _isEqual = isEqual(noEmptyScreenParams, noEmptyOldScreenParams)
        if (_isEqual) return

        // 相同key值合并
        for (const key in screenParams) {
          if (this.params.hasOwnProperty(key)) {
            this.params[key] = screenParams[key]
          }
        }
        this.$refs?.screenPopupFormRef?.onInit(screenParams)
        this.$nextTick(() => {
          this.onSearch()
        })
      }
    }
  },
  async created() {
    await this.onInit()
    this.$nextTick(() => {
      this.$emit(
        'onInitDefaultParams',
        transformParams(Object.assign({}, this.params, this.screenParams), this.columns)
      )
    })
  },
  methods: {
    onDeleteScreenItem(key, index) {
      this.screenParams[key] = ''
      if (this.params[key]) {
        this.params[key] = ''
      }
      this.onSearch()
    },
    getScreenDisplayValue(key, value) {
      const currentColumn = this.screenColumns.find((item) => item.name === key)
      if (currentColumn.valueEnum?.length) {
        // 如果是枚举值，返回label
        const findValueItems = currentColumn.valueEnum.filter((item) => value.includes(item.value))
        if (findValueItems.length) {
          return findValueItems.map((item) => item.label).join('/')
        }
      }
      // return Array.isArray(value) ? value.filter(Boolean) : [value]
      return Array.isArray(value) ? value.join('/') : value
    },
    async onInit(params = {}) {
      this.defaultScreenParams = await onInitParams(
        {},
        this.columns.filter((item) => item.screen)
      )
      this.defaultSearchParams = await onInitParams(
        params,
        this.columns.filter((item) => item.search && !item.hiddenSearch)
      )
      this.params = deepCopy(this.defaultSearchParams)
      this.screenParams = deepCopy(this.defaultScreenParams)
    },
    // 始终让查询按钮保持在最右端
    offsetComputed(count) {
      const totalCount = this.searchColumns.length
      const span = 24 / count
      let offset = 0
      if (totalCount <= count || this.unfoldSearchFormIetm) {
        offset = (count - 1 - (totalCount % count)) * span
      }
      return {
        span,
        offset
      }
    },
    onScreen(params) {
      this.screenParams = { ...params }
      return true
    },
    onSearch() {
      this.$emit(
        'onSearch',
        transformParams(Object.assign({}, this.params, this.screenParams), this.columns)
      )
    },
    async onReset() {
      this.params = deepCopy(this.defaultSearchParams)
      this.screenParams = deepCopy(this.defaultScreenParams)
      await this.$nextTick()
      this.$emit(
        'onReset',
        transformParams(Object.assign({}, this.params, this.screenParams), this.columns)
      )
    }
  }
}
</script>

<style lang="scss">
.searchForm {
  margin-bottom: 15px;
  .action-form-mobile {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 80vh;
  }
  .el-row--flex {
    flex-wrap: wrap;
    flex: 1;
  }
  .el-form--inline {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      display: flex;
      flex-grow: 1;
      .el-form-item__label {
        span {
          white-space: nowrap;
        }
      }
      .el-form-item__content {
        flex: 1;
        .el-select {
          width: 100%;
        }
      }
    }
    .action-form-item {
      text-align: right;
      margin-bottom: 0;
      .el-form-item__label {
        color: #fff;
      }
    }
  }
  .action-form-item {
    text-align: right;
    .el-form-item__label {
      color: #fff;
    }
  }
  .screen-container {
    .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .screen-content {
      font-size: 12px;
      display: flex;
      flex-wrap: wrap;
      color: #606266;
      .screen-item {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
.screen-drawer-container {
  .app-container {
    position: relative;
    min-height: calc(100vh - 45px - 32px);
    padding-bottom: 50px;
  }
  .form-pro {
    margin-left: -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .edit-item {
      flex-grow: 1;
      min-width: calc(50% - 10px);
      .el-form-item__content {
        .el-input__inner {
          padding-right: 30px;
        }
        .el-select,
        .el-cascader {
          display: block;
        }
      }
    }
  }
  .footer {
    position: fixed;
    width: 700px;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: block;
    text-align: right;
    margin-bottom: 0;
  }
}
</style>
