<template>
  <div>
    <el-form inline>
      <el-form-item label="招商目标/计划名称：">
        <el-input v-model="f.title"></el-input>
      </el-form-item>
      <el-form-item label="年份：">
        <el-date-picker v-model="f.startTime" type="year" value-format="yyyy">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="提交状态：">
        <el-select v-model="f.planStatus" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <main>
      <el-table
        ref="filterTable"
        :data="tableData"
        border
        style="width: 100%;margin-top:20px"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      >
        <el-table-column prop="title" label="招商目标/计划名称" align="center">
        </el-table-column>
        <el-table-column prop="startTime" label="年份" align="center">
        </el-table-column>
        <el-table-column prop="ifIssued" label="目标状态" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="!scope.row.ifIssued || scope.row.ifIssued == 1"
                >下发</span
              >
              <span v-else>待下发</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="活动状态" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.status == 0">新建</span>
              <span v-else-if="scope.row.status == 10">删除</span>
              <span v-else-if="scope.row.status == 20">下发</span>
              <span v-else-if="scope.row.status == 30">作废</span>
              <span v-else>完成</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="planStatus" label="提交状态" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="!scope.row.planStatus || scope.row.planStatus == 0"
                >新建</span
              >
              <span v-else-if="scope.row.planStatus == 1">下发</span>
              <span v-else-if="scope.row.planStatus == 2">作废</span>
              <span v-else>完成</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="操作" width="380" align="center">
          <template slot-scope="scope">
            <div class="sortList" @click.stop="">
              <span
                class="sort"
                v-if="!scope.row.planStatus || scope.row.planStatus == 0"
                @click="toEdit('edit', scope.row.id)"
                >编辑目标/计划</span
              >
              <span class="sort" @click="toEdit('detail', scope.row.id)"
                >查看</span
              >
              <span
                v-if="scope.row.planStatus == 1"
                class="sort"
                @click="updateStatus(scope.row.id)"
                >撤回</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
    <div class="pagination">
      <el-pagination
        background
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="pageSize || 10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script>
import { getMinePlanDataList, updateMinePlan } from '@/api/businessPlan'
import { getStorage } from '@/utils/storage'
let params = {}
export default {
  data() {
    return {
      f: {
        title: '',
        startTime: '',
        planStatus: ''
      },
      options: [
        {
          label: '新建',
          value: 0
        },
        {
          label: '下发',
          value: 1
        },
        {
          label: '完成',
          value: 2
        },
        {
          label: '作废',
          value: 3
        }
      ],
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      userInfo: {}
    }
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    params = {}
    if (from.name === 'BusinessPlanDetail') {
      params = from.params
    }
    next()
  },
  created() {
    this.currentPage = params.page || 1
    this.userInfo = getStorage('userInfo')
    this.getDataList()
  },
  methods: {
    async getDataList() {
      const res = await getMinePlanDataList({
        ...this.f,
        currPage: this.currentPage,
        pageSize: this.pageSize,
        userId: this.userInfo.accountId
      })
      console.log(res)
      if (res.code === 0) {
        this.tableData = res.data
        this.total = res.totalCount
      }
    },
    query() {
      this.currentPage = 1
      this.getDataList()
    },
    reset() {
      this.f = {
        title: '',
        startTime: '',
        planStatus: ''
      }
      this.currentPage = 1
      this.getDataList()
    },
    add() {
      this.drawer = true
      this.title = '添加计划'
    },
    toEdit(type, id) {
      this.$router.push({
        path: '/businessPlan/editMineBusinessPlan',
        query: {
          type,
          id,
          page: this.currentPage
        }
      })
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    },
    // 撤回
    updateStatus(id) {
      this.$confirm('是否撤回该计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await updateMinePlan({
          activityId: id,
          planStatus: 0
        })
        if (res.code === 0) {
          this.getDataList()
          this.$message.success('撤回成功')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.provinceList {
  display: flex;
  flex-direction: column;
  height: 350px;
  overflow-y: auto;

  .el-checkbox {
    margin: 5px 0;
  }
}

.sortList {
  display: flex;
  justify-content: space-around;

  .sort {
    color: $primary-color;
    cursor: pointer;
  }
}

.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
