import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import SimpleLayout from '@/layout/simpleLayout'
import attractPlatform from './modules/attractPlatform'
import flow from './modules/flow'
import businessOos from './modules/businessOos'
import store from '@/store/index'
import flowSetting from './modules/flowSetting'
import businessPlan from './modules/businessPlan'
import watchData from './modules/watchData'
import commonConfig from './modules/commonConfig'
import overseasData from './modules/overseasData'
import processBasicConfiguration from './modules/processBasicConfiguration'
import systemRouter from './modules/system'
import bpmManage from './modules/bpmManage'
import sfa from './modules/sfa'
export const constantRoutes = [
  {
    path: '/redirect',
    hidden: true,
    component: () => import('@/views/redirect/index')
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/authLogin',
    component: () => import('@/views/login/authLogin'),
    hidden: true
  },
  {
    path: '/paila',
    component: () => import('@/views/login/paila'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/welcome',
    children: [
      {
        path: 'welcome',
        name: 'welcome',
        hidden: true,
        component: () => import('@/views/welcome/index'),
        meta: { title: '欢迎', icon: 'dashboard', affix: true }
      }
    ]
  },
  {
    name: 'system',
    path: '/system',
    component: Layout,
    redirect: '/welcome',
    meta: { title: '工作台', icon: 'el-icon-eleme' },
    children: systemRouter
  },
  {
    path: '/flow',
    component: Layout,
    redirect: '/flow/index',
    name: 'flow',
    meta: { title: '招商流程', icon: 'dashboard' },
    children: flow()
  },
  {
    path: '/businessOos',
    component: Layout,
    redirect: '/attractPlatform/index',
    name: 'businessOos',
    meta: { title: '经销商数据', icon: 'dashboard' },
    children: businessOos
  },
  {
    path: '/flowSetting',
    component: Layout,
    redirect: '/flowSetting/index',
    name: 'flowSetting',
    meta: { title: '流程配置', icon: 'dashboard' },
    children: flowSetting
  },
  {
    path: '/watchData',
    component: Layout,
    redirect: '/watchData/watchReport',
    name: 'watchData',
    meta: { title: '数据监控' },
    children: watchData
  },
  {
    path: '/attractPlatform',
    component: Layout,
    redirect: '/attractPlatform/index',
    name: 'attractPlatform',
    meta: { title: '招商管理', icon: 'dashboard' },
    children: attractPlatform
  },
  {
    path: '/businessPlan',
    component: Layout,
    redirect: '/businessPlan/index',
    name: 'BusinessPlan',
    meta: { title: '招商计划/目标', icon: 'dashboard' },
    children: businessPlan
  },
  {
    path: '/commonConfig',
    component: Layout,
    redirect: '/commonConfig/index',
    name: 'CommonConfig',
    meta: { title: '通用配置', icon: 'dashboard' },
    children: commonConfig
  },
  {
    path: '/adSubsidy',
    component: Layout,
    redirect: '/ADSubsidy/subsidiesLimit',
    name: 'ADSubsidy',
    meta: { title: '经销商广告补贴管理', icon: 'dashboard' },
    children: [
      {
        path: 'subsidiesLimit',
        name: 'SubsidiesLimit',
        component: () => import('@/views/adSubsidy/subsidiesLimit'),
        meta: { title: '经销商广告补贴额度' }
      },
      {
        path: 'subsidiesSetting',
        name: 'SubsidiesSetting',
        component: () => import('@/views/adSubsidy/subsidiesSetting'),
        meta: { title: '经销商广告补贴调整' }
      }
    ]
  },
  {
    path: '/authorizationRemind',
    component: Layout,
    redirect: '/authorizationRemind/remindCancel',
    name: 'AuthorizationRemind',
    meta: { title: '授权提醒', icon: 'dashboard' },
    children: [
      {
        path: 'remindCancel',
        name: 'RemindCancel',
        component: () => import('@/views/authorizationRemind/remindCancel'),
        meta: { title: '授权取消提醒' }
      }
    ]
  },
  {
    path: '/overseasData',
    component: Layout,
    redirect: '/overseasData/apply',
    name: 'overseasData',
    meta: { title: '海外数据录入', icon: 'dashboard' },
    children: overseasData()
  },
  {
    path: '/weChatWork',
    component: Layout,
    redirect: '/weChatWork/departmentManage',
    name: 'weChatWork',
    meta: { title: '企微部门架构管理', icon: 'dashboard' },
    children: [
      {
        path: 'departmentManage',
        name: 'departmentManage',
        component: () => import('@/views/weChatWorkDepartmentManage/index'),
        meta: { title: '企微部门架构管理' }
      }
    ]
  },
  {
    path: '/processBasicConfiguration',
    component: Layout,
    redirect: '/processBasicConfiguration/marketPlaceManage',
    name: 'ProcessBasicConfiguration',
    meta: { title: '流程基本配置', icon: 'dashboard' },
    children: processBasicConfiguration
  },
  {
    path: '/gmDataManege',
    component: Layout,
    redirect: '/gmDataManege/authorizationBrand',
    name: 'GmDataManege',
    meta: { title: '主数据管理', icon: 'dashboard' },
    children: [
      {
        path: 'authorizationBrands',
        name: 'AuthorizationBrands',
        component: () => import('@/views/gmDataManege/authorizationBrands'),
        meta: {
          title: '授权系列管理'
        }
      },
      {
        path: 'dealerData',
        name: 'DealerData',
        component: () => import('@/views/gmDataManege/dealerData'),
        meta: {
          title: '经销商数据管理'
        }
      },
      {
        path: 'receiverData',
        name: 'ReceiverData',
        component: () => import('@/views/gmDataManege/receiverData'),
        meta: {
          title: '送达方数据管理'
        }
      },
      {
        path: 'shopData',
        name: 'ShopData',
        component: () => import('@/views/gmDataManege/shopData'),
        meta: {
          title: '门店数据管理'
        }
      },

      {
        path: 'dealerBusiness/training',
        name: '/gmDataManege/dealerBusiness/training',
        component: () =>
          import('@/views/gmDataManege/dealerBusiness/training/index.vue'),
        meta: {
          title: '培训管理'
        }
      },
      {
        path: 'dealerBusiness/inspection',
        name: '/gmDataManege/dealerBusiness/inspection',
        component: () =>
          import('@/views/gmDataManege/dealerBusiness/inspection/index.vue'),
        meta: { title: '终端门店巡检管理' },
        children: [
          {
            path: 'statement/statement',
            name: '/statement/statement',
            component: () => import('@/views/gmDataManege/dealerBusiness/inspection/statement/statement.vue'),
            meta: {
              title: '整改通知单列表'
            }
          },
          {
            path: 'store-inspection/manage',
            name: '/store-inspection/manage',
            component: () => import('@/views/gmDataManege/dealerBusiness/inspection/store-inspection/manage.vue'),
            meta: {
              title: '门店巡检管理'
            }
          },
          {
            path: 'violation-type/manage',
            name: '/violation-type/manage',
            component: () => import('@/views/gmDataManege/dealerBusiness/inspection/violation-type/manage.vue'),
            meta: {
              title: '违规类型管理'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/oa/flow',
    component: SimpleLayout,
    name: 'oa',
    redirect: '/oa/flow/index',
    children: flow('oa'),
    hidden: true
  },
  {
    path: '/bpmManage',
    component: Layout,
    redirect: '/bpmManage/temlateList',
    name: 'bpmManage',
    meta: { title: '流程管理' },
    children: bpmManage
  },
  {
    path: '/sfa',
    component: Layout,
    redirect: '/sfa/hotelList',
    name: 'Sfa',
    meta: { title: '报价系统' },
    children: sfa
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.afterEach((to, form) => {
  const isOA = to.matched.some(item => item.name === 'oa')
  // 设置OA标识
  store.commit('dealershipTransfer/setIsOa', isOA)
})

export default router

/*/ 以下代码用于统计路由数量，跟系统业务无关
const temp = []
function getPaths(routes, basePath) {
  routes.forEach(route => {
    if (route.children) {
      basePath && !/^\/\//.test(basePath)
        ? getPaths(route.children, basePath + '/' + route.path)
        : getPaths(route.children, route.path)
    } else {
      if (basePath) {
        temp.push(basePath + '/' + route.path)
      } else {
        temp.push(route.path)
      }
    }
  })
}
getPaths(constantRoutes)
const test = temp.filter(item => !/^\/oa\//.test(item))
console.log(
  Array.from(
    new Set(
      test
        .map(item => item.replace('//', '/'))
        .map(item => 'https://merchants.derucci.net/#' + item.split('/:')[0])
    )
  ).join('\n')
)
*/
