<!--
 * @Author: wangmq
 * @Date: 2023-11-17 10:48:25
 * @LastEditors: MacBook-Pro-5.local
 * @LastEditTime: 2024-05-02 20:09:36
-->
<template>
  <div>
    <el-form class="flow-form" :inline="true" ref="ruleForm" label-width="100%" label-position="left">
      <el-form-item class="formItem" style="width:auto" label="标题">
        <div>
          {{value?value:title}}
        </div>
      </el-form-item>
      <!-- <el-input v-model="number" placeholder="单号" readonly /> -->
      <BaseItem  class="formItem" v-model="number" label="单号" :readonly="true" placeholder="归档后自动生成"/>
      <BaseItem  class="formItem" v-model="zsOrderNumber" label="招商单号" :readonly="true" placeholder="归档后自动生成"/>
      <BaseItem  class="formItem" :value="type === 'direct'?'直营新店':''" label="流程类型" :readonly="true" v-if="type === 'direct'"/>
    </el-form>
  </div>
</template>

<script>
  /**
   * 1.title:标题名
   * 2.number:单号
   * */
  import BaseItem from '@/components/BaseSelect/baseFormSelect'
  import {
    mapGetters
  } from 'vuex'
  export default {
    data() {
      return {
        type: ''
      }
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      number: {
        type: String || Number,
        default: ''
      },
      value: {
        typeof: String,
        default: ''
      },
      zsOrderNumber: {
        type: String || Number,
        default: ''
      }
    },
    components: {
      BaseItem
    },
    computed: {
      ...mapGetters("dealershipTransfer", ["isOA"]),
    },
    mounted() {
      const {type} = this.$route.query
      this.type = type
    },
  }

</script>

<style lang='scss' scoped>
  .formItem {
    width: 20%;
  }

  .iconfont {
    font-size: 20px;
    cursor: pointer;
  }

  ::v-deep .el-form-item__content {
    width: 100%;
  }

  @media(max-width: 992px) {
    .formItem {
      width: 100%;
    }
  }

  @media screen and (min-width:992px) and (max-width:1024px) {
    .formItem {
      width: 30%;
    }
  }

  @media screen and (min-width:1024px) and (max-width:1200px) {
    .formItem {
      width: 30%;
    }
  }

</style>
