<template>
  <div>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="流程类型：">
        <el-select v-model="form.processCode" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间：">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="filterTable"
      :data="tableData"
      border
      style="width: 100%;margin-top:20px"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
    >
      <el-table-column prop="processCode" label="流程类型" align="center">
        <template slot-scope="scope">
          <div>
            {{
              typeOptions.length > 0 &&
                typeOptions.find(item => item.value === scope.row.processCode)
                  .label
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="提单人" align="center">
      </el-table-column>
      <el-table-column prop="describes" label="问题描述" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="提交时间" align="center">
      </el-table-column>
      <el-table-column prop="fileRecordList" label="附件" align="center">
        <template slot-scope="scope">
          <div>
            <div class="link" v-for="(item, index) in scope.row.fileRecordList" :key="index">
              <el-link

                :title="item.fileName"
                :href="item.requestDomainName + item.requestPath"
                target="_blank"
                type="success"
                >{{ item.fileName }}</el-link
              >
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="pageSize || 10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script>
import { getFeedBack } from '@/api/commonConfig/feedBack'
import { getFlowTypeList } from '@/api/commonConfig/dataManage'
export default {
  data() {
    return {
      date: [],
      form: {},
      typeOptions: [],
      tableData: [],
      rowId: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  created() {
    this.getFlowType()
    this.getDataList()
  },
  watch: {
    date(value) {
      if (value && value.length > 0) {
        this.form.startTime = value[0]
        this.form.endTime = value[1]
      } else {
        this.form.startTime = ''
        this.form.endTime = ''
      }
    }
  },
  methods: {
    async getDataList() {
      const res = await getFeedBack({
        ...this.form,
        pageSize: this.pageSize,
        currPage: this.currentPage
      })
      console.log(res)
      if (res.code === 0) {
        this.tableData = res.data
        this.total = res.totalCount
      }
    },
    // 获取流程类型
    async getFlowType() {
      const res = await getFlowTypeList({
        module: 'distributor',
        type: 'flow'
      })
      if (res.code === 0) {
        this.typeOptions = res.data.map(item => {
          return {
            value: item.code,
            label: item.content
          }
        })
      }
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getDataList()
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link {
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
