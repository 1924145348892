import http from '@/utils/request'

/**
 * @typedef {{
 *  dealerDeclarationForm:{
 *    id?:string // 表单 id，新增则不填
 *    title:string // 表单标题
 *    orderNumber?:string // 表单号
 *    optUserId:string // 提单人 id
 *    optUserName:string // 提单人名称
 *    optJobNumber:string // 提单人工号
 *    optDeptId:string // 提单人部门 id
 *    optDeptName:string // 提单人部门
 *    optTime:string // 提单日期
 *    status?:0|10 // 表单状态（0：草稿，10：发布）
 *  }
 *  dealerBoss:{
 *    id:string // 经销商 id
 *    realName:string // 经销商|实际控股人
 *    contactInfo:string // 经销商联系方式
 *  }
 *  dealerBusinessLicense:{
 *    id:string // 售达方 id
 *    declarationFormId?:string // 表单 id
 *    bossId:string // 经销商 id
 *  }
 *  dealerClearBrandInfo:{
 *    provinceCode:string // 省份 code
 *    provinceName:string // 省份名称
 *    authorizedCityCode:string // 授权城市 code
 *    authorizedCityName:string // 授权城市名称
 *    areaCode:string // 地区 code
 *    areaName:string // 地区名称
 *    cityLevel:string // 授权城市等级
 *    brandId:string // 品牌类型 id
 *    brandType:string // 品牌类型名称
 *    performance:string // 上一年提货业绩（万）
 *    agentBrand:string // 已代理系列列表
 *    marketCenter:string // 营销中心名称
 *    marketCenterCode:string // 营销中心编码
 *    clearBrandSeries:{
 *      id:string // 取消系列 id
 *      name:string // 取消系列名称
 *      code:string // 取消系列 code
 *      brandLevel:0|1|2 // 取消系列类型（0：大品牌，1：系列，2：业绩系列）
 *    }[] // 取消系列列表
 *    clearBrandReason:string // 取消授权原因
 *    remark?:string // 备注
 *    bankFiles:{
 *      fileName: string // 文件名
 *      fileType: string // 文件类型
 *      requestDomainName: string // 请求域名
 *      requestPath: string // 请求路径
 *    }[] // 上传附件列表
 *  }
 * }} DealershipCancelApplication
 */

/**
 * @description 新增经销权取消申请
 * @param {DealershipCancelApplication} params
 */
export const addDealershipCancelApplication = async params => {
  return http.post(
    '/dealermanage/operationflow/dealerClearBrandApi/submit',
    params
  )
}

/**
 * @description 保存经销权取消申请草稿
 * @param {DealershipCancelApplication} params
 */
export const saveDealershipCancelApplicationDraft = async params => {
  return http.post(
    '/dealermanage/operationflow/dealerClearBrandApi/saveDraft',
    params
  )
}

/**
 * @description 获取经销权取消申请详情
 * @param {any} id 表单 id
 * @returns {Promise<DealershipCancelApplication>}
 */
export const getDealershipCancelApplication = async id => {
  const res = await http.get(
    '/dealermanage/operationflow/dealerClearBrandApi/getDetail',
    {
      params: {
        formId: id
      }
    }
  )
  return res.data
}

/**
 * 获取城市等级
 * @param {string} cityCode 城市 code
 * @param {string} brandId 品牌 id
 */
export async function getCityLevel(cityCode, brandId) {
  const res = await http.get('/dealermanage/authorizedcitylevel/listpage', {
    params: {
      authorizedCityCode: cityCode,
      brandId
    }
  })
  return res.data[0]
}
