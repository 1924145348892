<template>
  <el-form ref="formRef" :model="form" @validate="onValidate">
    <template v-for="(fieldItem, fieldIndex) of fieldListComputed">
      <slot name="approval-divider" :form="form" :fieldItem="fieldItem" />
      <slot name="custom-item" :form="form" :field="fieldItem">
        <RequiredFormItemInput
          v-if="fieldItem.fieldType === 'input'"
          :key="fieldIndex"
          v-model="form[fieldItem.fdFieldId]"
          :required="fieldItem.require"
          :prop="fieldItem.fdFieldId"
          :disabled-prior="!fieldItem.edit"
          class="formItem"
          :label="fieldItem.fdName"
          :rules="approvalDataRules[fieldItem.fdFieldId]" />
        <RequiredFormItemDatePicker
          v-else-if="fieldItem.fieldType === 'date'"
          :key="fieldIndex"
          v-model="form[fieldItem.fdFieldId]"
          class="formItem"
          :required="fieldItem.require"
          :prop="fieldItem.fdFieldId"
          :disabled-prior="!fieldItem.edit"
          :label="fieldItem.fdName" />
        <RequiredFormItemDatePicker
          v-else-if="fieldItem.fieldType === 'daterange'"
          v-model="form[fieldItem.fdFieldId]"
          :key="fieldIndex"
          class="formItem lg"
          :label="fieldItem.fdName"
          :required="fieldItem.require"
          :prop="fieldItem.fdFieldId"
          :disabled-prior="!fieldItem.edit"
          :datePickerProps="{ type: fieldItem.fieldType }"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']" />
        <template v-else-if="fieldItem.fieldType === 'select'">
          <RequiredFormItemSelect
            :key="fieldIndex"
            v-model="form[fieldItem.fdFieldId]"
            :required="fieldItem.require"
            :prop="fieldItem.fdFieldId"
            :disabled-prior="!fieldItem.edit"
            class="formItem"
            :label="fieldItem.fdName"
            v-bind="fieldItem.fieldProps"
            :select-props="{ clearable: true }"
            :value-translate="selectValueTranslate" />
          <RequiredFormItemInput
            v-if="isAddRemark(form[fieldItem.fdFieldId])"
            :key="fieldIndex + 'remark'"
            v-model="form[`${fieldItem.fdFieldId}Remark`]"
            :required="fieldItem.require"
            :prop="`${fieldItem.fdFieldId}Remark`"
            :disabled-prior="!fieldItem.edit"
            :input-props="{ clearable: true }"
            class="formItem"
            label="人工填写"
            type="textarea"
            maxlength="300"
            rows="1" />
        </template>

        <template v-else-if="fieldItem.fieldType === 'dycSelect'">
          <RequiredFormItemSelect
            :key="fieldIndex"
            v-model="form[fieldItem.fdFieldId]"
            :required="fieldItem.require"
            :prop="fieldItem.fdFieldId"
            :disabled-prior="!fieldItem.edit"
            :label="fieldItem.fdName"
            :select-props="{
              clearable: true,
              ...(fieldItem.fieldProps?.selectProps || {})
            }"
            class="formItem"
            v-bind="fieldItem.fieldProps"
            v-on="fieldItem.listeners" />
          <RequiredFormItemInput
            v-if="
              isAddRemark(form[fieldItem.fdFieldId]) && fieldItem.remarkName
            "
            :key="fieldIndex + 'remark'"
            v-model="form[fieldItem.remarkName]"
            :required="fieldItem.require"
            :prop="fieldItem.remarkName"
            :disabled-prior="!fieldItem.edit"
            :input-props="{ clearable: true, autosize: true }"
            class="formItem"
            label="人工填写"
            type="textarea"
            maxlength="300"
            rows="1" />
        </template>

        <RequiredFormItemUploadFile
          v-else-if="fieldItem.fieldType === 'file'"
          :key="fieldIndex"
          v-model="form[fieldItem.fdFieldId]"
          :required="fieldItem.require"
          :prop="fieldItem.fdFieldId"
          :disabled-prior="!fieldItem.edit"
          class="formItem"
          :label="fieldItem.fdName"
          v-bind="fieldItem.fieldProps" />

        <template v-else-if="props.fdFormTemplateCode === 'distributor-JXS021'">
          <RequiredFormItemSelect
            v-if="
              fieldItem.fieldType === 'custom' &&
              fieldItem.fdFieldId === 'newOrTransferName'
            "
            :key="fieldIndex"
            v-model="form[fieldItem.fdFieldId]"
            :required="fieldItem.require"
            :prop="fieldItem.fdFieldId"
            :disabled-prior="!fieldItem.edit"
            class="formItem"
            :label="fieldItem.fdName"
            value-key="label"
            label-key="label"
            :data-list="[
              { label: '空白加盟', value: 1 },
              { label: '转让加盟', value: 2 }
            ]"
            clearable
            @onChangeItem="
              selectItem => {
                form.newOrTransferCode = selectItem?.value
              }
            " />
          <RequiredFormItemSelect
            v-if="
              fieldItem.fieldType === 'custom' &&
              fieldItem.fdFieldId === 'orderSpecialistAccount'
            "
            :key="fieldIndex"
            v-model="form[fieldItem.fdFieldId]"
            :required="fieldItem.require"
            :prop="fieldItem.fdFieldId"
            :disabled-prior="!fieldItem.edit"
            class="formItem"
            :label="fieldItem.fdName"
            value-key="account"
            :get-data-list="getUserList"
            :label-key="({ username, account }) => `${username}（${account}）`"
            filterable
            remote
            clearable
            @onOptionsListNotFound="onNotFoundUserList"
            @onChangeItem="
              selectItem => {
                form.orderSpecialistName = selectItem?.username
              }
            " />
        </template>
        <RequiredFormItemSelect
          v-else-if="
            props.fdFormTemplateCode === 'distributor-JXS008' &&
            fieldItem.fieldType === 'custom' &&
            fieldItem.fdFieldId === 'spaceDesignerAccount' &&
            form?.ifDianjiangDesigner === '是'
          "
          :key="fieldIndex"
          v-model="form[fieldItem.fdFieldId]"
          :required="fieldItem.require"
          :prop="fieldItem.fdFieldId"
          :disabled-prior="!fieldItem.edit"
          class="formItem"
          :label="fieldItem.fdName"
          value-key="account"
          :get-data-list="getDesignerList"
          :label-key="({ realName, account }) => `${realName}  ${account}`"
          filterable
          remote
          clearable
          @onOptionsListNotFound="onNotFoundDesignerList"
          @onChangeItem="
            selectItem => {
              form = { ...form, spaceDesignerPhon: selectItem?.phone }
            }
          " />
      </slot>
    </template>
  </el-form>
</template>

<script setup>
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import { getStorage } from '@/utils/storage'
import { Message } from 'element-ui'
import request from '@/utils/request'
import { nextTick, onMounted, ref, watch, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router/composables'
import { queryColumnAuthority } from '../api'
import { requestGetUserList, requestGetDesignerList } from '@/api/common'
import { isEqual } from 'element-ui/src/utils/util'
import { mergeStep } from '@/utils'

const emit = defineEmits(['getApprovalColumns'])

const userInfo = getStorage('userInfo')
const route = useRoute()
const props = defineProps({
  approvalData: {
    type: Object,
    default: () => ({})
  },
  fdFormTemplateCode: {
    type: String,
    default: ''
  },
  approvalFlowParams: {
    type: Object,
    default: () => ({})
  },
  approvalDataRules: { type: Object, default: () => ({}) }
})

const formRef = ref(null)
const fieldList = ref([])
const form = ref({})

async function getUserList({ page, searchText: searchVal }) {
  return requestGetUserList({
    page,
    limit: 10,
    searchVal
  }).then(res => {
    const { list, ...result } = res.page
    return { data: list, ...result }
  })
}

async function onNotFoundUserList({ value: searchVal }, callback) {
  callback(
    await requestGetUserList({
      searchVal
    }).then(res => res.page.list)
  )
}

async function getDesignerList({ page, searchText }) {
  return requestGetDesignerList({
    currPage: page,
    realName: searchText,
    limit: 10
  }).then(res => res.data)
}

async function onNotFoundDesignerList({ value: account }, callback) {
  callback(
    await requestGetDesignerList({
      account
    }).then(res => res.data)
  )
}

// 处理selectList里的value为数字的情况
function selectValueTranslate(value) {
  if (typeof value === 'number') {
    return String(value)
  }
  return value
}

const isAddRemark = value =>
  ['00', '其他（人工填写）', '其他(人工填写)'].includes(value)

const fieldListComputed = computed(() => {
  console.log('fieldList', fieldList.value)
  if (route.meta.code !== 'JXS020') {
    return fieldList.value
  }
  let fieldListTemp = []
  // if (shopPositionLevelByUser03Empty.value) {
  //   form.value.shopPositionLevelByUser03 = form.value.shopPositionLevelByUser02
  // }
  if (Number(form.value.sfyqsmcjzbt) === 1) {
    form.value.ddbjlx = ''
    form.value.strongEntryType = ''
    fieldListTemp = fieldList.value.filter(item => {
      return !['strongEntryType', 'ddbjlx'].includes(item.fdFieldId)
    })
  } else if (
    form.value.strongEntryType &&
    Number(form.value.strongEntryType) !== 3
  ) {
    form.value.ddbjlx = ''
    fieldListTemp = fieldList.value.filter(item => {
      return !['ddbjlx'].includes(item.fdFieldId)
    })
  } else {
    fieldListTemp = [...fieldList.value]
  }
  return fieldListTemp
})
const getQueryColumnAuthority = mergeStep(async () => {
  fieldList.value = await queryColumnAuthority({
    formType: route.query?.formType || 0, //formType: 0 或 null, 原审批页面，1，查询页面
    formInstanceId: route.query.id || route.query.businessId,
    loginName: userInfo.code,
    processId: route.query.processId,
    fdFormTemplateCode: props.fdFormTemplateCode,
    fdNodeType: 2
  }).then(({ data = [] }) =>
    data
      .sort((a, b) => a.sort - b.sort)
      .filter(item => {
        if (
          ['strongEntryType', 'sfyqsmcjzbt'].includes(item.fdFieldId) &&
          props.approvalFlowParams.strongEntryTypePrams.marketType !==
            '强势卖场'
        ) {
          item.show = false
        }
        if (
          [
            'shopPositionLevelByUser01',
            'shopPositionLevel',
            'shopPositionLevelByUser02',
            'shopPositionLevelByUser03'
          ].includes(item.fdFieldId) &&
          !props.approvalFlowParams.shopPositionLevelParams
            .showShopPositionLevel
        ) {
          item.show = false
        }
        if (
          item.show &&
          item.fdFieldId === 'shareNewGJSubsidy' &&
          Number(props.approvalFlowParams.shareNewGJSubsidyParams?.areaCount) >=
            200
        ) {
          const shareNewGJSubsidyBrands = [
            ['1390119723225755650', '1390119724693762050'],
            ['1390119722785353730', '1390119723439665154']
          ]
          function checkSubarrayContains(mainString, subarrays) {
            // 将主字符串转换为数组
            const mainArray = mainString
            // 遍历每个子数组
            for (const subarray of subarrays) {
              // 检查主数组是否包含子数组的所有项
              const containsAll = subarray.every(item =>
                mainArray.includes(item)
              )
              if (containsAll) {
                return true // 找到满足的子数组
              }
            }
            return false // 没有满足的子数组
          }
          item.show = checkSubarrayContains(
            props.approvalFlowParams.shareNewGJSubsidyParams.brandsIds,
            shareNewGJSubsidyBrands
          )
        }
        return item.show
      })
      .map(item => {
        item.fieldType = 'input'
        if (item.remark) {
          // type: input/date/select/dycSelect
          // {\"type\":\"select\",\"selectList\":[{\"label\":\"男\",\"value\":\"1\"},{\"label\":\"女\",\"value\":\"0\"}]}
          // "{\"type\":\"dycSelect\",\"dycJson\":\"{url:\\\"接口链接\\\",params:\\\"接口参数\\\",pageSize:\\\"\\\",currentPage:\\\"\\\",lable:\\\"\\\",value:\\\"\\\"}\"}"
          // console.log(item.remark)
          const tempObj = JSON.parse(item.remark)
          item.fieldType = tempObj.type ? tempObj.type : 'input'
          if (item.fieldType === 'select') {
            if (Array.isArray(tempObj.selectList)) {
              // 处理selectList里的value为数字的情况
              tempObj.selectList.forEach(item => {
                if (typeof item.value === 'number') {
                  item.value = String(item.value)
                }
              })
            }
            item.fieldProps = {
              labelKey: 'label',
              valueKey: 'value',
              dataList: tempObj.selectList
            }
          }
          if (item.fieldType === 'dycSelect') {
            const {
              label,
              value,
              multiple,
              searchName,
              fieldProps,
              selectProps,
              remarkName,
              ...dycJson
            } = JSON.parse(tempObj.dycJson)
            item.remarkName = remarkName
            item.fieldProps = {
              labelKey: label,
              valueKey: value,
              multiple: multiple ?? false,
              getDataList: ({ page, getDataListParams, searchText }) => {
                dycJson.params = {
                  ...getDataListParams,
                  ...dycJson.params,
                  [searchName || value]: searchText,
                  currPage: page
                }
                return request({
                  ...dycJson
                })
              },
              // 请求下拉列表额外传参
              getDataListParams: computed(() => {
                const params = {
                  ...(item.fdFieldId === 'brandsByUser01'
                    ? props.approvalFlowParams.brandsByUser01Params
                    : {}),
                  ...([
                    'shopPositionLevelByUser01',
                    'shopPositionLevel',
                    'shopPositionLevelByUser02',
                    'shopPositionLevelByUser03'
                  ].includes(item.fdFieldId)
                    ? props.approvalFlowParams.shopPositionLevelParams
                    : {})
                }
                return params
              }),
              selectProps,
              ...fieldProps
            }

            // 数据回显，管理字段配置searchName
            item.listeners = {
              onOptionsListNotFound: async ({ value }, callback) => {
                if (searchName) {
                  dycJson.params[searchName] = Array.isArray(value)
                    ? value.join(',')
                    : value
                }
                callback(await request(dycJson).then(res => res.data))
              }
            }
          }
          if (item.fieldType === 'file') {
            item.fieldProps = tempObj?.dycJson
              ? JSON.parse(tempObj.dycJson)
              : {}
          }
          if (props.fdFormTemplateCode === 'distributor-JXS021') {
            if (item.fdFieldId === 'orderSpecialistName') {
              // 自定义字段，与orderSpecialistAccount共用一个下拉框，所以其中一个不允许编辑
              item.edit = false
            }
          }
          if (props.fdFormTemplateCode === 'distributor-JXS020') {
            if (
              item.fdFieldId === 'gmdxsbtlx' &&
              props.approvalFlowParams.strongEntryTypePrams.marketType ===
                '购物中心店'
            ) {
              form.value.gmdxsbtlx = '1'
              item.edit = false
            }
          }
        }
        return item
      })
  )
  emit('getApprovalColumns', fieldList.value)
})
// const shopPositionLevelByUser03Empty = ref(false)
watch(
  () => props.approvalData,
  async data => {
    // if (!data || Object.keys(data).length === 0) return
    if (
      fieldList.value.length === 0 &&
      (route.query.id || route.query.businessId) &&
      route.query.processId
    ) {
      await getQueryColumnAuthority()
    }
    form.value = { ...form.value, ...data }
  },
  {
    immediate: true,
    deep: true
  }
)

let validateFlag = false
function onValidate(_, flag, message) {
  if (!flag && message && validateFlag) {
    setTimeout(() => {
      Message.warning(message)
      validateFlag = false
    })
  }
}

defineExpose({
  async onSubmit(validate) {
    if (validate) {
      console.log('validate submit')
      validateFlag = true
      await formRef.value.validate()
    }
    console.log(form.value)
    return form.value
  }
})
</script>

<style scoped lang="scss"></style>
