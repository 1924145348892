
import http from "../../utils/request";
import { download } from '../../utils/request'
// 列表
export const gettableList = (data) => {
  return http({
    method: 'get',
    url: 'consumermanage/regionuser/listpage',
    // url: 'user/account/effective',
    // baseURL: 'derucci/',
    params: data
  })
}
// 管辖地区列表
export const getRegionUser = (data) => {
  return http({
    method: 'get',
    url: 'user/region/address',
    params: data
  })
}
// 地区责任人列表
export const getUserName = (data) => {
  return http({
    method: 'get',
    url: 'user/account/effective',
    params: data
  })
}
// 新增
export const postUser = (data) => {
  return http({
    method: 'post',
    url: 'consumermanage/regionuser/add',
    data: data
  })
}
// 删除
export const postDel = (data) => {
  return http({
    method: 'post',
    url: 'consumermanage/regionuser/delete',
    data: data
  })
}
// 修改地区责任人
export const postChangeProvice = (data) => {
  return http({
    method: 'post',
    url: 'consumermanage/regionuser/update',
    data: data
  })
}
// 负责大品牌
export const brandSeries = (params) => {
  return http({
    url: 'dealermanage/brandseries/listpage',
    method: 'GET',
    params: params
  })
}
// 招商资料管理---------------------------------
// 列表
export const manageList = (data) => {
  return http({
    method: 'get',
    url: 'consumermanage/distributor/lists',
    params: data
  })
}
// 招商主管列表
export const regionList = (data) => {
  return http({
    method: 'get',
    url: 'consumermanage/regionuser/listpage',
    params: data
  })
}
// 进行中活动列表
export const activeList = () => {
  return http({
    method: 'get',
    url: 'consumermanage/activity/activityName'
  })
}
// 导出
export const excelList = () => {
  return download('consumermanage/distributor/export', {})
  // return http({
  //   method: 'get',
  //   url: 'consumermanage/distributor/export',
  //   responseType: 'blob'
  // })
}
// 新增
export const postForm = (data) => {
  return http({
    method: 'post',
    url: 'consumermanage/distributor/add',
    data: data
  })
}
// 修改
export const postChangeForm = (data) => {
  return http({
    method: 'post',
    url: 'consumermanage/distributor/update',
    data: data
  })
}
// 下载模板
export const downloadTemplate = () => {
  return download('consumermanage/distributor/download', {})
}
// 导入
export const uploadExcel = (data) => {
  return http({
    url: 'consumermanage/distributor/imports',
    method: 'POST',
    data: data
  })
}
// 删除
export const del = (data) => {
  return http({
    url: 'consumermanage/distributor/delete',
    method: 'POST',
    data: data
  })
}

