<template>
  <div style="margin-top:20px">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>各区域开店计划汇总明细</span>
      </div>
      <div>
        <el-button @click="uploadExcel">导出excel</el-button>
      </div>
      <div>
        <el-table
          ref="filterTable"
          :data="tableData"
          border
          style="width: 100%;margin-top:20px"
          :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
          @row-click="rowClick"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="province" label="省份" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="品牌" align="center">
          </el-table-column>
          <el-table-column
            prop="storeType"
            label="店面类型（独立店、KA店店、社区店）"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="jan" label="1月" align="center">
          </el-table-column>
          <el-table-column prop="feb" label="2月" align="center">
          </el-table-column>
          <el-table-column prop="mar" label="3月" align="center">
          </el-table-column>
          <el-table-column prop="apr" label="4月" align="center">
          </el-table-column>
          <el-table-column prop="may" label="5月" align="center">
          </el-table-column>
          <el-table-column prop="june" label="6月" align="center">
          </el-table-column>
          <el-table-column prop="july" label="7月" align="center">
          </el-table-column>
          <el-table-column prop="aug" label="8月" align="center">
          </el-table-column>
          <el-table-column prop="sep" label="9月" align="center">
          </el-table-column>
          <el-table-column prop="octo" label="10月" align="center">
          </el-table-column>
          <el-table-column prop="nov" label="11月" align="center">
          </el-table-column>
          <el-table-column prop="dece" label="12月" align="center">
          </el-table-column>
        </el-table>
        <div class="total">
          <span>合计：{{ totalSum }}</span>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          :current-page="currentPage"
          :page-sizes="[10, 30, 50]"
          :page-size="pageSize || 10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="currentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { getTargetListData, excelPlan } from '@/api/businessPlan'
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      totalSum: 0,
      rowId: []
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      const res = await getTargetListData({
        currPage: this.currentPage,
        pageSize: this.pageSize,
        activityId: this.$route.query.id,
        type: 1
      })
      if (res.code === 0) {
        this.tableData = res.data
        this.total = res.totalCount
        if (res.data.length == 0) {
          this.totalSum = 0
          return
        }
        res.data.forEach(item => {
          this.totalSum += item.targetSum
        })
      }
    },
    // 导出excel
    async uploadExcel() {
      await excelPlan({ activityId: this.$route.query.id })
      this.$message.success('导出成功')
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.total {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: flex-end;
  padding: 0 20px;
  border: 1px solid #e6ebf5;
  border-top-width: 0;
}
</style>
