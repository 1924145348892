import axios from 'axios'
import { Message } from 'element-ui'
import { getSession, getStorage, removeStorage } from '@/utils/storage'
import router from '@/router'
import md5 from 'js-md5'
import qs from 'qs'
import store from '@/store'
import { requestWrap, wrapMasterRuleConfig } from '@/utils/requestWrap'
import { ssoLoginJump } from '@/utils'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL:'https://atom.musiyoujia.com',
  timeout: 1000 * 10 * 10 * 6 // request timeout 10分钟
})

export const baseHeaders = () => {
  return {
    api_client_code:
      getSession('systemCode') || process.env.VUE_APP_SYSTEM_CODE,
    api_version: '1.0.0',
    api_timestamp: (+new Date()).toString()
  }
}

// request interceptor
service.interceptors.request.use(
  async config => {
    const token = getStorage('token') || ''
    const baseParams = baseHeaders()
    if (!config.isLogin) {
      baseParams.api_sign = _getSign({
        api_token: token,
        ...baseParams
      })
      baseParams.api_token = token
    }

    config.headers = {
      ...baseParams,
      ...config.headers
    }
    if (config.upload) config.timeout = 1000 * 60 * 60 * 2
    config = await requestWrap(config)
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response interceptor
let requstTime = 0
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code) {
      _catchErrorData(res)
      return Promise.reject(response)
    }
    return res
  },
  error => {
    const data = error.response && error.response.data && error.response.data
    if (data) {
      _catchErrorData(data)
      return Promise.reject(data)
    } else {
      Message({
        message: error || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service

export function download(url, options = {}) {
  const baseURL = process.env.VUE_APP_BASE_API
  const token = getStorage('token') || ''

  const baseParams = baseHeaders()
  options.api_sign = _getSign({ api_token: token, ...baseParams })
  options.api_token = token
  requestWrap({ url, params: { ...options, ...baseParams } }).then(config => {
    // console.log(config)
    const arg = qs.stringify(config.params)
    if (url.indexOf('?') === -1) url += '?'
    // console.log(`${baseURL}/${url}${arg}`)
    // window.location.href = `${baseURL}/${url}${arg}`
    window.open(`${baseURL}/${url}${arg}`)
  })
}

async function _catchErrorData(data) {
  if (requstTime !== 0) return
  requstTime++
  const codeArr = [101, 102, 105]
  if (codeArr.includes(data.code)) {
    Message.error('登录信息失效了')
    store.dispatch('user/logout')
    ssoLoginJump()
    return
    // router.push(`/login?redirect=${location.hash.substr(1)}`)
  }

  if (!codeArr.includes(data.code)) {
    Message({
      message: data.msg || 'Error',
      type: 'error',
      duration: 3 * 1000
    })
  }
  //如果是保存草稿则跳转到草稿列表
  if (data?.code === 1 && data.msg?.includes('数据已保存到草稿')) {
    setTimeout(() => router.push('/flow/draft'), 1000)
    return
  }
  //提示消失后重置可以弹出下一次提示
  setTimeout(() => (requstTime = 0), 3000)
}

function _getSign(obj) {
  const str = qs.stringify(obj)
  return md5(str).toUpperCase()
}
