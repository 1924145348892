var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('div',{staticClass:"CommonPopup"},[_c(_vm.popupType === 'dialog' ? 'el-dialog' : 'el-drawer',_vm._g(_vm._b({tag:"component",attrs:{"visible":_vm.visible,"append-to-body":""},on:{"close":_vm.onHide}},'component',{
        class: _vm.popupClassName,
        title: _vm.displayTitle,
        ..._vm.popupProps,

        ...(_vm.popupType === 'dialog' ? _vm.dialogProps : _vm.drawerProps),
        ..._vm.$attrs,
        ...(_vm.popupType === 'dialog'
          ? { width: _vm.checkMobile ? '100%' : _vm.width }
          : { size: _vm.checkMobile ? '100%' : _vm.width })
      },false),{
        ..._vm.popupListeners,
        ...(_vm.popupType === 'dialog' ? _vm.dialogListeners : _vm.drawerListeners),
        ..._vm.$listeners
      }),[_vm._t("title",null,{"slot":"title","data":_vm.data}),(!_vm.destroySlotOnClose || (_vm.destroySlotOnClose && _vm.visible))?_c('div',{class:{ 'app-container': _vm.popupType === 'drawer' }},[_vm._t("default",null,{"data":_vm.data,"onShow":_vm.onShow,"onHide":_vm.onHide,"onClose":_vm.onClose})],2):_vm._e()],2)],1),_vm._t("trigger",null,{"onShow":_vm.onShow})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }