<template>
  <div class="commonConfig/coefficientManagement">
    <el-form inline>
      <el-form-item label="装修补贴类型">
        <el-input v-model="coefficientsSearchFields.name" clearable />
      </el-form-item>
      <el-form-item>
        <el-button
          @click="
            () => {
              Object.keys(coefficientsSearchFields).forEach(
                k => (coefficientsSearchParams[k] = coefficientsSearchFields[k])
              )
              coefficientsSearchParams.currPage = 1
            }
          "
          >查询</el-button
        >
        <el-button type="primary" @click="onSaveDialogAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="coefficients"
      border
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }">
      <el-table-column label="装修补贴类型" prop="name" />
      <el-table-column label="状态">
        <template #default="{ row }">
          <el-switch
            :value="row.enable === 0"
            @change="onCoefficientStatusUpdate(row)" />
        </template>
      </el-table-column>
      <el-table-column label="更新人" prop="updateBy" />
      <el-table-column label="更新时间" prop="updateTime" />
      <el-table-column label="操作" width="80">
        <template #default="{ row }">
          <!-- <el-button type="text" @click="onSaveDialogUpdate(row)"
            >选择系数</el-button
          > -->
          <el-button type="text" @click="onSaveDialogUpdate(row)"
            >补贴上限</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 16px"
      background
      layout="total, sizes, prev, pager, next, jumper"
      :total="coefficientsTotal"
      :page-sizes="[10, 30, 50]"
      :page-size="coefficientsSearchParams.pageSize"
      @size-change="
        size => {
          coefficientsSearchParams.currPage = 1
          coefficientsSearchParams.pageSize = size
        }
      "
      :current-page="coefficientsSearchParams.currPage"
      @current-change="page => (coefficientsSearchParams.currPage = page)" />
    <SaveDialog
      :open="saveDialogOpened"
      @update:open="v => (saveDialogOpened = v)"
      :coefficient-id="saveDialogId"
      @saved="refreshCoefficients" />
  </div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
import {
  listDecorationSubsidies,
  updateDecorationSubsidyStatus,
  getDecorationSubsidy
} from '@/api/commonConfig/decorationSubsidy'
import SaveDialog from './components/SaveDialog.vue'

const coefficientsSearchParams = reactive({
  currPage: 1,
  pageSize: 10,
  name: ''
})
const {
  coefficientsTotal,
  coefficients,
  coefficientsSearchFields,
  refreshCoefficients
} = useCoefficients(coefficientsSearchParams)
async function onCoefficientStatusUpdate(row) {
  const status = !(row.enable === 0)
  await updateDecorationSubsidyStatus(row.id, status)
  row.enable = status ? 0 : 1
  const res = await getDecorationSubsidy(row.id)
  Object.keys(row).forEach(key => (row[key] = res[key]))
}

const { onSaveDialogAdd, onSaveDialogUpdate, saveDialogId, saveDialogOpened } =
  useSaveDialog()

function useCoefficients(searchParams) {
  watch(searchParams, refreshCoefficients, {
    immediate: true
  })
  const coefficientsSearchFields = reactive({
    name: ''
  })
  const coefficientsTotal = ref(0)
  const coefficients = ref([])
  async function refreshCoefficients() {
    const res = await listDecorationSubsidies({
      associatedBusinessId: 1,
      ...searchParams
    })
    coefficientsTotal.value = res.totalCount
    coefficients.value = res.data
  }

  return {
    coefficientsSearchFields,
    coefficientsTotal,
    coefficients,
    refreshCoefficients
  }
}

function useSaveDialog() {
  const saveDialogOpened = ref(false)
  const saveDialogId = ref('')
  function onSaveDialogAdd() {
    saveDialogId.value = ''
    saveDialogOpened.value = true
  }
  function onSaveDialogUpdate(row) {
    saveDialogId.value = row.id
    saveDialogOpened.value = true
  }
  return {
    saveDialogOpened,
    saveDialogId,
    onSaveDialogAdd,
    onSaveDialogUpdate
  }
}
</script>

<style lang="scss">
.commonConfig\/coefficientManagement {
  .el-card__header {
    padding: 8px 20px;
  }
}
</style>
