// @ts-nocheck
export default function saveJSON(data, filename) {
	if (!data) {
		console.error('保存的数据为空');
		return;
	}
	if (!filename) filename = 'json.json';
	if (typeof data === 'object') {
		data = JSON.stringify(data, undefined, 4);
	}

	var blob = new Blob([data], { type: 'text/json' }),
		e = document.createEvent('MouseEvents'),
		a = document.createElement('a');
	a.download = filename;
	a.href = window.URL.createObjectURL(blob);
	console.log(blob, '链接', a.href);

	a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');

	e.initMouseEvent(
		'click',
		true, // 是否可以冒泡
		false, // 是否可以阻止事件默认行为
		window, // 指向window对象
		0, // 事件的鼠标点击数量
		0, // 事件的屏幕的x坐标
		0,
		0, // 事件的客户端x坐标
		0,
		false, // 事件发生时 control 键是否被按下
		false, // 事件发生时 alt 键是否被按下
		false, // 事件发生时 shift 键是否被按下
		false, // 事件发生时 meta 键是否被按下
		0, // 鼠标按键值
		null
	);
	// 向一个指定的事件目标派发一个事件
	a.dispatchEvent(e);
}
