<template>
  <el-card ref="tableAreaRef" class="common-table" v-bind="cardProps">
    <template #header>
      <div ref="tableActionAreaRef" class="tableActionArea">
        <slot name="actionArea" :total="total" :loading="loading" />
      </div>
    </template>
    <el-table
      v-if="tableLayoutDirection === 'vertical'"
      ref="tableRef"
      v-loading="loading"
      v-bind="attrs"
      v-on="$listeners"
    >
      <slot name="tableBefore" />
      <CustomElTableColumn
        v-for="(column, index) of decorateColumns"
        :key="index"
        :column="column"
      />
      <slot name="table-after" />
    </el-table>
    <template v-if="tableLayoutDirection === 'horizontal'">
      <div v-for="(dataItem, dataIndex) of attrs.data" :key="dataIndex" class="table-horizontal">
        <div
          v-for="(columnItem, columnIndex) of decorateColumns"
          :key="columnIndex"
          class="row"
          :class="{ minWidth: columnItem.minWidthContent }"
          :style="columnItem.rowStyle"
        >
          <div class="label" :style="columnItem.labelStyle">
            <component
              :is="columnItem.title"
              v-if="Object.prototype.toString.call(columnItem.title) === '[object Object]'"
              :text="columnItem.title"
              :data.sync="attrs.data[dataIndex]"
              :index="dataIndex"
              v-on="$listeners"
            />

            <span v-else>{{ columnItem.title }} </span>
          </div>

          <div
            class="cell"
            :style="{ minWidth: columnItem.tableColumnProps?.width, ...columnItem.cellStyle }"
          >
            <span
              v-if="columnItem.valueType === 'index'"
              class="cell-text"
              v-text="dataIndex + 1"
            />
            <span v-else-if="!columnItem.render" class="cell-text">{{
              renderText(columnItem, dataItem, dataIndex)
            }}</span>
            <component
              :is="columnItem.render"
              v-else
              :text="columnItem.name && dataItem && dataItem[columnItem.name]"
              :config="columnItem"
              :data.sync="attrs.data[dataIndex]"
              :index="dataIndex"
              v-on="$listeners"
            />
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="request && !hidePagination"
      class="pagination-container"
      :style="{ height: `${paginationHeight}px` }"
    >
      <el-pagination
        :background="!checkMobile"
        :small="checkMobile"
        :layout="paginationLayout"
        :total="frontEndPaging ? dataList.length || defaultDataList.length : total"
        :page-sizes="pageSizes"
        :page-size="pages.pageSize"
        :current-page="pages.currPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      />
    </div>
    <!-- <BasicPage /> -->
  </el-card>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { mergeStep } from '@/utils/index'
import ResizeObserver from 'resize-observer-polyfill'
import { renderText, getThumbUrl, getWidthCellWidth, getTextWidth, isMobile } from './fn'
import CustomElTableColumn from './CustomElTableColumn.vue'
import { deepCopy } from './fn'
export default {
  components: {
    CustomElTableColumn
    // BasicPage
  },
  props: {
    showOverflowTooltip: {
      type: Boolean,
      default: false
    },
    tableLayoutDirection: {
      type: String,
      // horizontal and vertical
      default: 'vertical'
    },
    defaultDataList: {
      type: Array,
      default: () => []
    },
    frontEndPaging: {
      type: Boolean,
      default: false
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: null
    },
    pageSizes: {
      type: Array,
      default: () => [10, 30, 50, 100, 500]
    },
    request: {
      type: Function,
      default: null // (params, source='table'|'export')=>Promise<{data:Array, totalCount: Number, ...}>
    },
    responseConfig: {
      type: Object,
      default: () => ({
        list: 'data',
        total: 'totalCount',
        // 导出键值对
        excelTitle: 'excelTitle'
      })
    },
    cardProps: {
      type: Object,
      default: () => ({})
    },
    autoWidthCell: {
      type: Boolean,
      default: false
    },
    maxAutoWidthCell: {
      type: Number,
      default: 350
    },
    maxAutoWidthTitle: {
      type: Number,
      default: 120
    }
  },
  data() {
    const pageSize = this.pageSizes[0]
    return {
      pages: {
        pageSize,
        currPage: 1
      },
      dataList: [],
      loading: false,
      total: 0,
      tableAreaHeight: null,
      paginationHeight: 54
    }
  },
  computed: {
    paginationLayout() {
      return isMobile() ? 'total,prev, pager, next' : 'total, sizes, prev, pager, next, jumper'
    },
    checkMobile() {
      return isMobile()
    },
    attrs({ pages, frontEndPaging, dataList, defaultDataList, tableAreaHeight, $attrs }) {
      let data = dataList.length ? dataList : defaultDataList
      if (frontEndPaging) {
        const index = (pages.currPage - 1) * pages.pageSize
        data = data.slice(index, index + pages.pageSize)
      }
      return {
        data,
        border: true,
        headerCellStyle: { background: '#F5F7FA', color: '#393F4D' },
        maxHeight: tableAreaHeight,
        ...$attrs
      }
    },
    tableColumns({ columns }) {
      if (!columns) return []
      const filterHideInTable = (list) => {
        return list
          .map((item) => {
            if (!item.hideInTable && item.tableColumnList?.length) {
              item.tableColumnList = filterHideInTable(item.tableColumnList)
            }
            return item
          })
          .filter((item) => !item.hideInTable)
      }
      return filterHideInTable(columns)
    },
    decorateColumns({
      autoWidthCell,
      dataList,
      tableColumns,
      tableBaseFontSize,
      maxAutoWidthCell,
      maxAutoWidthTitle
    }) {
      // 自动计算表格宽度
      const setDefaultCellWidth = (_columns) =>
        _columns.map((columnItem) => {
          if (columnItem.tableColumnList) {
            columnItem.tableColumnList = setDefaultCellWidth(columnItem.tableColumnList)
            return columnItem
          }
          if ((autoWidthCell || columnItem.autoWidthCell) && !columnItem.width) {
            const contentWidth =
              dataList
                .map((dataItem) => getWidthCellWidth(columnItem, dataItem, tableBaseFontSize) + 22)
                .filter(Boolean)
                .sort((a, b) => b - a)[0] || 0
            // 标题宽度，如果标题宽度大于内容宽度，则使用标题宽度 + 间距，最大不能超过maxAutoWidthTitle
            const titleWidth = Math.min(
              getTextWidth(columnItem.title, tableBaseFontSize, 'bold') + 22,
              maxAutoWidthTitle
            )
            columnItem.tableColumnProps = {
              minWidth: Math.min(Math.max(titleWidth, contentWidth), maxAutoWidthCell),
              ...columnItem.tableColumnProps
            }
          }
          return columnItem
        })
      return setDefaultCellWidth(deepCopy(tableColumns))
    }
  },
  mounted() {
    if (this.autoHeight) {
      const tableParentRef = this.$refs.tableAreaRef.$el
      this.setTableAreaHeight()
      this.observer = new ResizeObserver(
        debounce(300, (entries, observer) => {
          this.setTableAreaHeight()
        })
      )
      this.observer.observe(tableParentRef)
    }
    if (this.$refs.tableRef) {
      this.tableBaseFontSize = getComputedStyle(this.$refs.tableRef.$el, null)['fontSize']
    }
  },
  destroyed() {
    if (this.autoHeight) {
      this.observer.disconnect()
    }
  },
  methods: {
    getThumbUrl,
    renderText,
    setTableAreaHeight() {
      const tableParentRef = this.$refs.tableAreaRef?.$el

      const tableActionAreaRef = this.$refs.tableActionAreaRef
      let ACTION_CONTINER_HRIGHT = 0
      if (tableActionAreaRef) {
        ACTION_CONTINER_HRIGHT = this.$refs.tableActionAreaRef.offsetHeight
      }

      const CARD_BODY_PADDING = 20 * 2
      const tableAreaHeight =
        tableParentRef?.offsetHeight -
        ACTION_CONTINER_HRIGHT -
        this.paginationHeight -
        CARD_BODY_PADDING
      if (this.tableAreaHeight !== tableAreaHeight) {
        this.tableAreaHeight = tableAreaHeight
      }
    },
    getParams(customParams) {
      // const params = transformParams(this.params, this.columns)
      return { ...this.params, ...this.pages, ...customParams }
    },
    async onButtonAction(buttonItem, { row, $index }) {
      if (buttonItem.valueType === 'page') {
        // buttonItem.actionUrl
      } else if (buttonItem.valueType === 'action') {
        await this.$confirm(`确定${buttonItem.title}`)
      }
    },
    /**
     * customParams 自定搜索传参
     */
    loadTableList: mergeStep(async function(
      customParams,
      { actionType } = { actionType: 'unknown' }
    ) {
      if (this.request) {
        return this.request(this.getParams(customParams), 'table', { actionType })
      } else {
        throw new Error('request is undefined')
      }
    }),
    async setTableList({ actionType } = { actionType: 'search' }) {
      if (this.frontEndPaging && this.pages.currPage > 1) {
        return this.dataList
      }
      if (!this.request) {
        return this.defaultDataList
      }
      this.loading = true
      try {
        const data = await this.loadTableList({}, { actionType })
        this.$emit('setRequestData', data)
        this.dataList = data[this.responseConfig.list]
        this.total = data[this.responseConfig.total]
        this.$nextTick(() => {
          // 避免设置fixed后，高度不匹配
          this.$refs.tableRef?.doLayout()
        })
        // 切换页面数据
        this.$emit('setTableList', this.dataList, { actionType })
      } catch (error) {
        console.log(error)
      }
      this.loading = false
      return this.dataList
    },
    onCurrentChange(currPage, options = { actionType: 'changePage' }) {
      this.pages.currPage = currPage
      return this.setTableList(options)
    },
    onSizeChange(pageSize) {
      this.pages.pageSize = pageSize
      this.pages.currPage = 1
      return this.setTableList({ actionType: 'changePage' })
    },
    onSearchTableList(params, currPage, { actionType } = {}) {
      this.params = { ...params }
      if (typeof currPage === 'number') {
        this.pages.currPage = currPage
      }
      return this.setTableList({ actionType })
    },
    onClearTableList() {
      this.dataList = []
    }
  }
}
</script>

<style lang="scss">
.common-table {
  .el-card__header {
    border: 0;
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.common-table {
  .tableActionArea {
    :deep(.action-area) {
      padding: 18px 20px;
      border-bottom: 1px solid #ebeef5;
    }
    &:empty {
      display: none;
    }
  }
  .action-area:empty {
    display: none;
  }
  /* height: 50vh; */
  .table-horizontal {
    display: flex;
    flex-wrap: wrap;
    & + .table-horizontal {
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid #efefef;
    }
    .row {
      border: 1px solid #dfe4ed;
      margin-right: -1px;
      margin-bottom: -1px;
      font-size: 12px;
      display: flex;
      width: 100%;

      .label {
        width: 120px;
        max-width: 120px;
        padding: 10px;
        border-right: 1px solid #dfe4ed;
        background-color: #e2f2fb;
        font-weight: bold;
      }
      .cell {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-width: 20px;
        .cell-text {
          padding: 0 10px;
        }
        &:deep(.text-display) {
          padding: 0 10px;
        }
        &:deep(.el-form-item) {
          margin-bottom: 0;
          margin-right: 0;
          border: 0;
          &.is-error {
            margin-bottom: 18px;
          }
          .el-input__inner {
            border: 0;
          }
        }
      }

      &.min-width {
        flex: 1 1 auto;
        width: inherit;
        .label {
          width: inherit;
          max-width: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          white-space: nowrap;
        }
      }
    }
  }
  .pagination-container {
    padding-top: 20px;
    text-align: center;
  }
  .el-icon-picture-outline {
    font-size: 30px;
  }
  .el-image__error {
    padding: 6px 0px;
    border: 1px dashed #ddd;
    flex-direction: column;
    .el-icon-picture-outline {
      font-size: 16px;
    }
    .el-image__erro-text {
      font-size: 12px;
      /* white-space: nowrap; */
    }
  }
}
</style>
