<template>
  <el-badge :value="exportSupported.includes('selected') ? dataList.length || '' : ''" type="info">
    <PopupForm
      ref="popupFormRef"
      title="选择导出类型"
      width="500px"
      :on-submit="onExportExcel"
      :columns="exportPopupFormColumns"
    >
      <template #trigger="{ onShow }">
        <el-button
          v-if="!fetchExportData && dataList.length"
          v-bind="$attrs"
          :disabled="disabled"
          @click="onExportExcel({ type: 'selected' })"
          >导出数据</el-button
        >
        <el-button
          :disabled="disabled"
          v-bind="$attrs"
          @click="
            onShow({
              type: dataList.length && exportSupported.includes('selected') ? 'selected' : 'all'
            })
          "
          >导出数据
        </el-button>
      </template>
      <template #footer="{ onSubmit, onCancel }">
        <el-form-item style="display: block; text-align: right; margin-bottom: 0">
          <el-button v-loading-click="onSubmit.bind(null, 'all')" type="primary">
            直接导出
          </el-button>
          <el-popover
            @show="initInnerExportColumns(exportColumns)"
            v-model="visibleCheckedPopover"
            placement="left"
            width="400"
            trigger="click"
          >
            <el-card class="box-card" shadow="never">
              <div
                slot="header"
                style="display: flex; justify-content: space-between; align-items: center"
              >
                <el-checkbox
                  :value="checkedExportItemName.length === innerExportColumns.length"
                  :indeterminate="
                    !!checkedExportItemName.length &&
                    checkedExportItemName.length !== innerExportColumns.length
                  "
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
                <el-button
                  v-loading-click="onSubmit.bind(null, 'select')"
                  type="primary"
                  :disabled="!checkedExportItemName.length"
                >
                  导出
                </el-button>
              </div>
              <el-checkbox-group :value="checkedExportItemName" @input="onChangeChecked">
                <draggable
                  v-model="sortExportColumns"
                  class="question-list"
                  handle=".handle"
                  animation="200"
                >
                  <div
                    v-for="exportColumnsItem in sortExportColumns"
                    :key="exportColumnsItem.name"
                    class="export-item"
                  >
                    <el-checkbox :label="exportColumnsItem.name"
                      >{{ exportColumnsItem.title }}
                    </el-checkbox>
                    <i class="el-icon-sort handle" />
                  </div>
                </draggable>
              </el-checkbox-group>
            </el-card>
            <el-button slot="reference" style="margin: 0 10px">编辑导出列导出</el-button>
          </el-popover>
          <el-button @click="onCancel"> 取消 </el-button>
        </el-form-item>
      </template>
    </PopupForm>
  </el-badge>
</template>

<script>
import PopupForm from '../FormPro/PopupForm.vue'
import { exportExcel } from './exportExcel'
import { renderFormItem, formatJson, deepCopy } from './fn'
import draggable from 'vuedraggable'
import { tableProDb } from './tableProDb'
import { getStorage } from '@/utils/storage'
import deepEqual from 'fast-deep-equal'

export default {
  components: {
    PopupForm,
    draggable
  },
  inject: ['addTask'],
  props: {
    // 定义后开启导出项缓存
    exportsDbKey: {
      type: String,
      default: ''
    },
    exportSupported: { type: Array, default: () => ['selected', 'all'] },
    disabled: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: () => []
    },
    // 导出方法为空，且dataList有数据时直接导出
    fetchExportData: {
      type: Function,
      default: null
    },
    exportTaskType: {
      type: String,
      // 'clientTask', 'serverTask'
      default: 'clientTask'
    },
    exportFileName:{
      type: String,
      default: ''
    },
    exportRequestParams: {
      type: Object,
      default: () => ({
        currPage: 1,
        // 分片大小
        sliceSize: 5000,
        // 最大请求数量
        pageSize: 5000 * 5
      })
    },
    exportColumns: {
      type: [Array, Object],
      default: null
    }
  },
  data() {
    const exportPopupFormColumns = [
      {
        name: 'type',
        renderFormItem: renderFormItem(
          (h, context) => {
            const options = [
              ...(this.exportSupported.includes('selected')
                ? [
                    {
                      value: 'selected',
                      disabled: !this.dataList.length,
                      title: '导出已选中的记录',
                      description: `仅导出在列表中已选中的${this.dataList.length}条记录`
                    }
                  ]
                : []),
              ...(this.exportSupported.includes('all')
                ? [
                    {
                      value: 'all',
                      title: '导出当前查询结果',
                      description: `导出当前查询和筛选结果的所有记录`
                    }
                  ]
                : [])
            ]
            return options.map((item, index) => {
              return (
                <div style={{ marginTop: index ? '24px' : 0 }} key={item.value}>
                  <el-radio
                    attrs={context.$attrs}
                    on={context.$listeners}
                    disabled={item.disabled}
                    label={item.value}
                  >
                    {item.title}
                  </el-radio>
                  <div style='color: #aaa;margin-left: 24px;margin-top: 4px;'>
                    {item.description}
                  </div>
                </div>
              )
            })
          },
          {
            required: true
          }
        )
      }
    ]

    return {
      visibleCheckedPopover: false,
      exportPopupFormColumns,
      innerExportColumns: []
    }
  },
  computed: {
    sortExportColumns: {
      get({ innerExportColumns }) {
        return innerExportColumns.sort((a, b) => {
          return a.exportSort - b.exportSort
        })
      },
      set(list) {
        this.innerExportColumns = this.innerExportColumns.map((item) => {
          const exportSort = list.findIndex((listItem) => listItem.name === item.name)
          return {
            ...item,
            exportSort
          }
        })
      }
    },
    checkedExportItemName({ innerExportColumns }) {
      return innerExportColumns.filter((item) => item.checked).map((item) => item.name)
    }
  },
  watch: {
    async innerExportColumns(innerExportColumns, oldInnerExportColumns) {
      const userInfo = getStorage('userInfo')
      if (deepEqual(innerExportColumns, oldInnerExportColumns)) return
      if (!innerExportColumns.length) return
      if (this.exportsDbKey) {
        if (!this.tableConfig) {
          const id = await tableProDb.exports.add({
            exports_key: `${this.exportsDbKey}_${userInfo.id}`,
            export_columns: deepCopy(innerExportColumns)
          })
          this.tableConfig = {
            id,
            exports_key: `${this.exportsDbKey}_${userInfo.id}`,
            export_columns: deepCopy(innerExportColumns)
          }
        } else {
          tableProDb.exports.update(this.tableConfig.id, {
            export_columns: deepCopy(innerExportColumns)
          })
        }
      }
    }
  },
  methods: {
    onChangeChecked(value) {
      this.innerExportColumns = this.innerExportColumns.map((item) => {
        return {
          ...item,
          checked: value.includes(item.name)
        }
      })
    },
    exportExcelSlice(options) {
      const maxSliceCount = 50000
      const exportData = options.data
      if (exportData.length > maxSliceCount) {
        const exportDataSliceCount = Math.ceil(exportData.length / maxSliceCount)
        const exportDataSlice = []
        for (let i = 0; i < exportDataSliceCount; i++) {
          exportDataSlice.push(exportData.slice(i * maxSliceCount, (i + 1) * maxSliceCount))
        }
        return Promise.all(
          exportDataSlice.map(async (dataList, dataIndex) => {
            return exportExcel({
              titleList: options.titleList,
              data: dataList,
              filename: `${options.filename}-${dataIndex}`
            })
          })
        )
      }
      return exportExcel(options)
    },
    /**
     * @param type: 'all' || 'selected'
     */
    async onExportExcel({ type }, screenType = 'all') {
      // 让表单隐藏，按钮继续加载
      this.$refs.popupFormRef.onHide()
      // 隐藏导出列编辑弹窗
      this.visibleCheckedPopover = false
      // 加入任务队列
      const title = this.exportFileName||this.$route.meta?.title || '导出数据'

      let exportColumns = this.convertExportColumnsToArray(this.exportColumns).filter(
        (item) => item.defaultExport !== false
      )
      if (screenType === 'select') {
        exportColumns = this.convertExportColumnsToArray(this.exportColumns)
          .filter((item) => {
            return this.checkedExportItemName.includes(item.name)
          })
          .map((item) => {
            return {
              ...item,
              exportOrder: this.sortExportColumns.findIndex(({ name }) => {
                return item.name === name
              })
            }
          })
          .sort((a, b) => {
            return a.exportOrder - b.exportOrder
          })
      }
      const taskTypeEnum = {
        local: '0',
        server: '1'
      }

      if (this.exportTaskType === 'serverTask') {
        return this.addTask(
          '',
          () => {
            return this.fetchExportData(
              'serverTask',
              { ...this.exportRequestParams, exportColumns },
              undefined
            )
          },
          taskTypeEnum.server
        )
      }

      return this.addTask(
        title,
        async (callback) => {
          let exportData = this.dataList
          if (this.fetchExportData) {
            exportData = await this.fetchExportData(type, this.exportRequestParams, callback)
          }

          return this.exportExcelSlice({
            titleList: exportColumns.map((item) => item.title),
            data: await formatJson(exportColumns, exportData),
            filename: title
          })
        },
        taskTypeEnum.local
      )
    },
    handleCheckAllChange(value) {
      this.innerExportColumns = this.innerExportColumns.map((item) => ({
        ...item,
        checked: value
      }))
    },
    convertExportColumnsToArray(columns) {
      const exportColumns =
        Object.prototype.toString.call(columns) === '[object Object]'
          ? Object.keys(columns).map((key) => ({
              name: key,
              title: columns[key]
            }))
          : columns

      return exportColumns
    },
    async initInnerExportColumns(columns) {
      const userInfo = getStorage('userInfo')
      const exportColumns = this.convertExportColumnsToArray(columns)
      let innerExportColumns = exportColumns.map((item, index) => ({
        title: item.title,
        name: item.name,
        checked: item.defaultExport !== false,
        exportSort: index
      }))
      if (this.exportsDbKey) {
        this.tableConfig = await tableProDb.exports.get({
          exports_key: `${this.exportsDbKey}_${userInfo.id}`
        })
      }
      if (this.tableConfig?.export_columns?.length) {
        if (this.tableConfig?.export_columns.length === innerExportColumns.length) {
          // 配置项和缓存长度相同/文本一致
          const isHomology = innerExportColumns.every((item) => {
            return this.tableConfig?.export_columns.some(({ title, name }) => {
              return item.title === title && item.name === name
            })
          })
          if (isHomology) {
            innerExportColumns = this.tableConfig?.export_columns
          }
        }
      }
      this.innerExportColumns = innerExportColumns
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  :deep(.el-card__body) {
    overflow: scroll;
    max-height: 60vh;
    padding: 0 20px;
  }
  .export-item {
    min-width: 25%;
    font-size: 14px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
