<template>
  <div class="container">
    <!-- <GoBack /> -->
    <HeaderTitle :title="CNTitle"/>
    <el-table :data="data.data" border style="width: 100%" :header-cell-style="{background:'#e2f2fb',color:'#393F4D'}">
      <el-table-column
        v-for="(item, index) in headerList"
        :key="index"
        :prop="item"
        :label="item"
        width="190px"
      >
      </el-table-column>
    </el-table>
   
  </div>
</template>

<script>
import HeaderTitle from '../../flow/components/headerTitle.vue'
export default {
  name: 'tableItem',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  components:{
    HeaderTitle
  },
  data() {
    return {
      headerList: []
    }
  },
  computed: {
    CNTitle() {
      var title = ''
      switch (this.data.tableName) {
        case 'dealer_shop':
          title = '门店表'
          break;
        case 'dealer_authorized_brand':
          title = '送达方'
          break;
        case 'dealer_boss':
          title = '实控人'
          break;
        case 'dealer_business_license':
          title = '售达方'
          break;
        case 'dealer_crm_distributor':
          title = '经销商'
          break;
        case 'dealer_delivery_party':
          title = '售达方授权系列表'
          break;
        case 'dealer_shop_brand':
          title = '门店系列关联表'
          break;
        default:
          title = this.data.tableName
          break
      }
      return title
    }
  },
  mounted() {
    this.headerList = this.getHeaderList()
  },
  methods: {
    getHeaderList() {
      let headerList = []
      const data = this.data.data
      if (data.length > 0) {
        headerList = Object.keys(data[0])
      }
      return headerList
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  margin-bottom: 35px;
}
</style>
