<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import moment from 'moment'
import FlowForm from './components/flowForm.vue'
import store from '@/store'
import { useRoute } from 'vue-router/composables'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import {
  datadictionaryList,
  getAuthorizedCities,
  requestGetSizeBarListByDept,
  requestGetSizeBarListByDeptMerge
} from '@/api/common'
import { getBusinessCityList, getBossDetail } from '@/api/businessOos'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import http from '@/utils/request'
import { queryColumnAuthority } from '@/views/bpmFlow/api'
import { Message } from 'element-ui'
import module from '@/components/CustomFormItem/data/module.data'

const shopStatuses = module.shop_status.reduce((acc, { content, code }) => {
  // 使用 code 作为键，content 作为值
  acc[code] = content
  return acc
}, {})

const route = useRoute()
const user = computed(() => store.state.user.userInfo)
const isApproval = computed(() => !!route.query.processId)
const isEditable = computed(() => [0, 20].includes(Number(form.status)))

const formRef = ref()
const form = reactive({
  id: '',
  zsOrderNumber: '',
  processCode: 'JXS025',
  title: '经销商4S星级认证流程',
  orderNumber: '',
  optUserId: '',
  optJobNumber: '',
  optUserName: '',
  optDeptId: '',
  optDeptName: '',
  optTime: '',
  dealerType: 1,
  status: 0,
  declarationType: 'BPM',
  remark: '',
  bossId: '',
  bossNumber: '',
  bossName: '',
  crmDistributorId: '',
  crmDistributorCode: '',
  crmDistributorName: '',
  authorizedCity: {
    id: '',
    authorizedCityCode: '',
    authorizedCityName: '',
    overseasFlag: '',
    countryCode: '',
    province: '',
    provinceCode: '',
    provinceQywxId: '',
    city: '',
    cityCode: '',
    area: '',
    town: '',
    townCode: '',
    areaCode: '',
    marketLevelName: '',
    marketLevelCode: '',
    marketLevelTypeName: '',
    marketLevelType: '',
    ifEnabled: '',
    statusExplain: '',
    updateTime: '',
    createTime: '',
    levelId: ''
  },
  /**营销部 */
  bigRegionCode: '',
  bigRegionName: '',
  /**营销中心 */
  smallRegionCode: '',
  smallRegionName: '',
  /**现有星级 */
  nowStarRating: '',
  /**平均分 */
  averageScore: '',
  /**申请星级 */
  applyForStarRating: '',
  applyOrderModel: {
    approveAccount: '',
    systemCode: 'distributor',
    fdFormTemplateCode: 'distributor-JXS025',
    pageVersion: 'V1',
    bpmCreateBean: undefined,
    bpmExecuteBean: undefined
  },
  approveData: {
    /**评定星级 */
    evaluateStarRating: '',
    /**申请星级 */
    applyForStarRating: '',
    /**评分表汇总 */
    adrzpfbCountFileUrl: [],
    shopDataList: []
  }
})
async function init(type) {
  form.optUserId = user.value.accountId
  form.optUserName = user.value.realName
  form.optJobNumber = user.value.code
  form.optDeptId = user.value.deptId
  form.optDeptName = user.value.deptName
  if (type === 'detail') {
    const { data } = await http.get(
      '/dealermanage/crmDistributorXingJi/details',
      {
        params: {
          formId: route.query.id || route.query.businessId
        }
      }
    )
    Object.keys(form).forEach(key => {
      if (key === 'approveData') {
        form[key] = {
          ...form[key],
          ...data[key]
        }
        return
      }
      if (data[key] !== undefined) {
        form[key] = data[key]
      }
    })
    form.approveData.applyForStarRating = form.applyForStarRating
  }
  if (isApproval.value) {
    fetchApprovalCols()
  }
}

const starLevels = useStarLevels()
const evaluatedStarLevels = computed(() => {
  if (form.applyForStarRating === '') return starLevels.value
  const level = Number(form.applyForStarRating)
  return starLevels.value.filter(v => Number(v.code) <= level)
})

async function onDealerOptionChange(option) {
  if (!isEditable.value) return
  if (option) {
    form.crmDistributorId = option.id
    form.crmDistributorName = option.dealerName
    form.crmDistributorCode = option.dealerCode
    form.bossId = option.bossId
    const { data: cities } = await getAuthorizedCities({
      currPage: 1,
      pageSize: 1,
      authorizedCityCode: option.authorizedCityCode,
      brandId: '1390119720428154881'
    })
    if (cities.length === 0) return
    const [authorized] = cities
    form.authorizedCity = authorized
    const { data: marketing } = await requestGetSizeBarListByDeptMerge({
      provinceCode: authorized.provinceCode,
      cityCode: authorized.cityCode,
      areaCode: authorized.areaCode,
      townCode: authorized.townCode
    })
    form.bigRegionName = marketing.administrativeName
    form.bigRegionCode = marketing.administrativeCode
    form.smallRegionCode = marketing.smallCode
    form.smallRegionName = marketing.smallName
    getDealerBossDetail()
    fetchDealerStar()
    fetchShops()
  }
}
async function onSubmit(type) {
  const time = moment().format('YYYY-MM-DD HH:mm:ss')
  form.optTime = time
  if (type === 'save') {
    await http.post('/dealermanage/crmDistributorXingJi/saveDraft', {
      ...form,
      status: 0
    })
    return form.id
  }
  if (type === 'submit') {
    if (form.approveData.shopDataList.length === 0) {
      const message = '门店数据不能为空'
      Message.warning(message)
      throw new Error(message)
    }
    return http.post('/dealermanage/crmDistributorXingJi/submit', {
      ...form,
      status: 10
    })
  }
}
const approvalCols = ref()
async function onApproval(bpmExecuteBean, bpmDealerJoinApproveModel) {
  const operationType = bpmExecuteBean.operationType.split('_')[1]
  if (operationType === 'pass') {
    await formRef.value.$refs.ruleForm.validate()
  }
  form.applyOrderModel.approveAccount = user.value.code || user.value.account
  form.applyOrderModel.bpmExecuteBean = bpmExecuteBean
  if (Number(form.status) === 20) {
    return formRef.value.onCommit('submit')
  }
  const {
    id,
    optUserId,
    optUserName,
    optJobNumber,
    approveData,
    applyOrderModel
  } = form
  return http.post('/dealermanage/crmDistributorXingJi/submitApproveData', {
    id,
    optUserId,
    optUserName,
    optJobNumber,
    approveData,
    applyOrderModel
  })
}

const dealerListCount = ref(0)
const getDealerList = async ({ page, searchText }) => {
  const res = await getBusinessCityList({
    currPage: page,
    dealerName: searchText,
    ifEnabled: 0
  })
  dealerListCount.value = res.totalCount
  return res.data
}
const onDealerNotFound = async ({ value: dealerCode }, callback) => {
  const res = await getBusinessCityList({
    dealerCode
  })
  callback(res.data)
}
const getDealerBossDetail = async () => {
  const res = await getBossDetail({ id: form.bossId })
  if (res.data && res.data.length) {
    const item = res.data[0]
    form.bossName = item.realName
    form.bossNumber = item.bossNumber
  }
}

function useStarLevels() {
  const starLevels = ref([])
  onMounted(async () => {
    const { data } = await datadictionaryList({
      currPage: 1,
      pageSize: 100,
      type: 'starRating'
    })
    starLevels.value = data
  })
  return starLevels
}

async function fetchShops() {
  const res = await http({
    url: '/dealermanage/shopsearch/brand/shoplistpage',
    params: {
      currPage: 1,
      pageSize: 100,
      notShopType: 'SJ007',
      shopStatus: 0,
      bigBrandId: '1390119720428154881',
      bossId: form.bossId,
      authorizedCityCode: form.authorizedCity.authorizedCityCode
    }
  })
  form.approveData.shopDataList = res.data.map(v => ({
    shopNumber: v.shopNumber,
    marketType: v.marketType,
    marketName: v.marketName,
    statusExplain: v.statusExplain,
    decorationTime: v.decorationTime || '',
    decorationStopTime: v.decorationStopTime || '',
    sdrzyxzlFileUrl: [],
    sdrzpfbFileUrl: []
  }))
}
async function fetchDealerStar() {
  const { data } = await getBusinessCityList({
    currPage: 1,
    pageSize: 1,
    bossId: form.bossId,
    authorizedCityCode: form.authorizedCity.authorizedCityCode
  })
  const dealer = data[0]
  if (!dealer) {
    form.nowStarRating = ''
  } else {
    form.nowStarRating = dealer.starRating
  }
}
async function fetchApprovalCols() {
  const { data = [] } = await queryColumnAuthority({
    formType: route.query?.formType || 0, //formType: 0 或 null, 原审批页面，1，查询页面
    formInstanceId: route.query.id || route.query.businessId,
    loginName: user.value.code,
    processId: route.query.processId,
    fdFormTemplateCode: 'distributor-JXS025',
    fdNodeType: 2
  })
  const result = {}
  data.forEach(v => {
    result[v.fdFieldId] = {
      ...v,
      remark: v.remark ? JSON.parse(v.remark) : undefined
    }
  })
  approvalCols.value = result
}
</script>

<template>
  <FlowForm
    ref="formRef"
    title="经销商4S星级认证流程"
    fd-form-template-code="distributor-JXS025"
    :get-detail="init"
    :on-submit="onSubmit"
    :on-approval="onApproval"
    :model="form"
    :is-edit="isEditable">
    <div>
      <RequiredFormItemInput
        class="formItem"
        label="标题"
        v-model="form.title" />
      <RequiredFormItemInput
        class="formItem"
        label="单号"
        :value="form.orderNumber"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="招商单号"
        :value="form.zsOrderNumber"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单人工号"
        :value="form.optJobNumber"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单人"
        :value="form.optUserName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单部门"
        :value="form.optDeptName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单日期"
        :value="form.optTime"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemSelect
        class="formItem"
        ref="businessRef"
        prop="crmDistributorCode"
        v-model="form.crmDistributorCode"
        label="经销商（实际控股人）"
        labelKey="dealerName"
        valueKey="dealerCode"
        :selectProps="{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        }"
        :total-count="dealerListCount"
        :getDataList="getDealerList"
        :getNextList="getDealerList"
        @onOptionsListNotFound="onDealerNotFound"
        @changeSelectedItem="onDealerOptionChange">
        <template v-slot="{ scope }">
          <span>{{ scope.row.dealerCode }}</span>
          <small>{{ scope.row.dealerName }}</small>
        </template>
      </RequiredFormItemSelect>
      <RequiredFormItemInput
        class="formItem"
        label="授权城市"
        :value="form.authorizedCity.authorizedCityName"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemInput
        class="formItem"
        label="城市级别"
        :value="form.authorizedCity.marketLevelName"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemInput
        class="formItem"
        label="省份"
        :value="form.authorizedCity.province"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemInput
        class="formItem"
        label="城市"
        :value="form.authorizedCity.city"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemInput
        class="formItem"
        label="区/县"
        :value="form.authorizedCity.area"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemInput
        class="formItem"
        label="营销部"
        :value="form.bigRegionName"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemInput
        class="formItem"
        label="营销中心"
        :value="form.smallRegionName"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemInput
        class="formItem"
        label="现有星级"
        :value="form.nowStarRating"
        disabled
        :required="false"
        placeholder="自动带出" />
      <RequiredFormItemSelect
        class="formItem"
        prop="applyForStarRating"
        label="申请星级"
        labelKey="content"
        valueKey="code"
        v-model="form.applyForStarRating"
        :dataList="starLevels" />
    </div>

    <template #approval-form>
      <div v-if="isApproval">
        <RequiredFormItemSelect
          class="formItem"
          v-if="approvalCols?.evaluateStarRating?.show"
          :disabled-prior="!approvalCols?.evaluateStarRating?.edit"
          :required="approvalCols?.evaluateStarRating?.require"
          prop="approveData.evaluateStarRating"
          label="评定星级"
          labelKey="content"
          valueKey="code"
          v-model="form.approveData.evaluateStarRating"
          :dataList="evaluatedStarLevels" />
        <UploadFile
          class="formItem"
          v-if="approvalCols?.adrzpfbCountFileUrl?.show"
          label="实地认证评分表汇总（星级扫描）"
          v-model="form.approveData.adrzpfbCountFileUrl"
          :disabled-prior="!approvalCols?.evaluateStarRating?.edit"
          :required="approvalCols?.adrzpfbCountFileUrl?.require"
          prop="approveData.adrzpfbCountFileUrl">
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
      </div>
      <el-table
        style="margin-bottom: 16px"
        border
        :data="form.approveData.shopDataList">
        <el-table-column label="序号" type="index" width="50" />
        <el-table-column label="门店编码" prop="shopNumber" width="100" />
        <el-table-column
          label="门店状态"
          prop="statusExplain"
          width="100"
          :formatter="row => shopStatuses[row.statusExplain]" />
        <el-table-column label="卖场类型" prop="marketType" width="100" />
        <el-table-column label="卖场名称" prop="marketName" width="100" />
        <el-table-column
          label="装修时间"
          prop="decorationTime"
          width="100"
          :formatter="
            v => (v.decorationTime ? v.decorationTime.slice(0, 10) : '')
          " />
        <el-table-column
          label="装修到期时间"
          width="100"
          prop="decorationStopTime"
          :formatter="
            v => (v.decorationStopTime ? v.decorationStopTime.slice(0, 10) : '')
          " />
        <template v-if="isApproval">
          <el-table-column
            v-if="approvalCols?.practicallyTime?.show"
            label="实地认证时间">
            <template #default="{ row }">
              <el-form-item
                prop
                :rules="[
                  {
                    required: approvalCols?.practicallyTime?.require,
                    message: '请选择实地认证时间',
                    trigger: 'change'
                  }
                ]">
                <el-date-picker
                  v-model="row.practicallyTime"
                  style="width: 100%"
                  :disabled="!approvalCols?.practicallyTime?.edit"
                  value-format="yyyy-MM-dd 00:00:00"
                  type="date" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            v-if="approvalCols?.sdrzyxzlFileUrl?.show"
            label="实地认证验星资料（各个模块水印相机拍照）">
            <template #default="{ row, $index }">
              <UploadFile
                v-model="row.sdrzyxzlFileUrl"
                :required="approvalCols?.sdrzyxzlFileUrl?.require"
                :disabled-prior="!approvalCols?.sdrzyxzlFileUrl?.edit"
                style="width: 100%"
                :prop="`approveData.shopDataList[${$index}].sdrzyxzlFileUrl`"
                :showLabel="false" />
            </template>
          </el-table-column>
          <el-table-column
            v-if="approvalCols?.sdrzpfbFileUrl?.show"
            label="实地认证评分表（星级扫描）">
            <template #default="{ row, $index }">
              <UploadFile
                v-model="row.sdrzpfbFileUrl"
                :required="approvalCols?.sdrzpfbFileUrl?.require"
                :disabled-prior="!approvalCols?.sdrzpfbFileUrl?.edit"
                style="width: 100%"
                :prop="`approveData.shopDataList[${$index}].sdrzpfbFileUrl`"
                :showLabel="false" />
            </template>
          </el-table-column>
        </template>
      </el-table>
    </template>
  </FlowForm>
</template>
