<template>
  <div class="watch">
    <el-form :inline="true">
      <el-form-item>
        <el-input v-model="search.dealerName"
                  placeholder="请输入经销商名称"
                  clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker value-format="yyyy-MM-dd"
                        v-model="date"
                        :pickerOptions="pickerOptions"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"  @change="handleDateChange"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="handleFind">查询</el-button>
        <el-button type="primary"
                   @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table ref="filterTable"
              v-loading="loading"
              element-loading-text="Loading"
              border
              :data="dataList"
              style="width: 100%"
              :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}">
      <el-table-column :show-overflow-tooltip="true"
                       v-for="(item,index) in  itemKey"
                       :key="index"
                       :prop="item"
                       :label="listItem[item]"
                       align="center"
                       width="120">
        <template slot-scope="scope">
          <el-tag type="danger" @click.native="handleReport(scope.row,item)"
                  v-if="scope.row.checkColumns.split(';').includes(item)">{{scope.row[item]||'-'}}</el-tag>
          <span v-else @click="handleReport(scope.row,item)">{{scope.row[item]||'-'}}</span>
        </template>
      </el-table-column>
    </el-table>
    <br>
    <div class="paginationBar">
      <el-pagination background
                     layout="total,prev, pager, next,sizes,jumper"
                     :total="total"
                     :page-sizes="[10, 30, 50]"
                     :page-size="pageSize"
                     :current-page="currentPage"
                     @current-change="currentChange"
                     @size-change="handleSizeChange" />
    </div>
        <reportDrawer :visibleDialog="visibleDialog" :shopNumber="shopNumber" :column="column" table="dealer_crm_distributor" @closeDrawer="visibleDialog=false" />
  </div>
</template>

<script>
import {
  getDistributorTaskResults,
  exportDistributorTaskResults
} from '@/api/watchData/'
import { parseTime } from '@/utils/index'
import reportDrawer from './components/reportDrawer.vue'

var listItem = {
  ruleName: '巡检规则名称',
  ruleDescription: '规则描述',
  resultCreateTime: '检查日期',
  dealerName: '经销商名称',
  updateTime: '更新时间',
  updateUserName: '更新人',
  authorizedCityCode: '授权城市编号',
  authorizedCityName: '授权城市名称',
  channelType: '经销商类型',
  dealerCode: '经销商编号',
  marketLevelCode: '市场级别编号',
  marketLevelName: '城市级别',
  createUserName: '经销商创建人',
  secondaryChannelType: '经销商类型二级分类',
  source: '来源',
  sourceClassification: '来源分类',
  statusExplain: '经销商状态',
  thirdChannelType: '经销商类型三级分类',

  shopBrandName: '送达方名称',
  createTime: '授权时间',
  brandCode: '授权系列',
  businessLicenseName: '售达方名称',
  bossName: '实控人名称'
}

const nowDate = [
  parseTime(Date.now(), '{y}-{m}-{d}') + ' 00:00:00',
  parseTime(Date.now(), '{y}-{m}-{d}') + ' 23:59:59'
]

const brandCode={
  '03':	'慕思经典-歌蒂娅',
  '04':'慕思经典-凯奇',
  '02':'慕思经典-0769',
  '01':'慕思经典-3D',
  '09':'慕思经典-苏斯',
  '41':'慕思经典-凯锐',
  '42':'慕思经典-蓝奢',
  '43':'慕思经典-北欧',
  '45':'慕思经典-智能电动',
  '44':'慕思经典-意简',
  '07':'慕思儿童',
  '18':'助眠系列',
  '16':'芯睡眠',
  '08':'慕思时尚-V6',
  '31':'V6家居',
  '28':'CALIAITALIA',
  '29':'CALIASOFART',
  '35':'渠道沙发',
  '30':'电商沙发',
  '32':'慕思沙发皮艺系列',
  '36':'慕思沙发布艺系列',
  '33':'酒店工程家居',
  '39':'慕思沙发现代系列',
  '40':'慕思功能沙发',
  '22':'慕思家纺',
  '13':'慕思助眠',
  '15':'DeRUCCI HOME',
  '12':'慕思高端-PAULY',
  '21':'慕思高端-慕思国际',
  '37':'慕思高端-slumberzone',
  '38':'慕思高端-MDL',
  '19':'崔佧',
  '26':'思丽德赛-国际',
  '27':'思丽德赛-简约',
  '23':'慕思优家',
  '24':'慕思海外',
  '34':'海外家居',
  '25':'慕思电商',
  '46':'慕思工程',
  '06':'慕思经典-兰博基尼',
  '47':'慕思经典-新渠道'
}

export default {
  components:{
    reportDrawer
  },
  data() {
    return {
      dataList: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      listItem: listItem,
      itemKey: Object.keys(listItem),
      search: {
        startTime: nowDate[0],
        endTime: nowDate[1]
      },
      date: null,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      checkColumns: [],
      shopNumber:'',
      column:'',
      visibleDialog:false
    }
  },
  created() {
    const { taskId, startTime, endTime } = this.$route.query
    if (taskId) {
      this.search = { taskId, startTime, endTime }
      this.date = [startTime, endTime]
    } else {
      this.date = nowDate
    }

    this.getDataList(this.search)
  },
  methods: {
     handleReport(item,column){
       console.log(item)
      this.visibleDialog=true
      this.shopNumber=item.bossId
      this.column=column
    },
    handleDateChange(date) {
       this.date=[date[0] + ' 00:00:00',date[1] + ' 23:59:59']
    },
    handleExport() {
      const { startTime, endTime, dealerName } = this.search
      const { taskId } = this.$route.query
      const url = taskId
        ? `${exportDistributorTaskResults}?startTime=${startTime}&endTime=${endTime}&taskId=${taskId}`
        : `${exportDistributorTaskResults}?startTime=${startTime}&endTime=${endTime}&dealerName=${dealerName}`
      window.open(url, '_blank')
    },
    handleFind() {
      if (this.date) {
        this.search.startTime = this.date[0]
        this.search.endTime = this.date[1]
      }
      this.currentPage=1
      this.getDataList(this.search)
    },
    async getDataList(search = {}) {
      this.loading = true
      const { status, list } = await getDistributorTaskResults({
        ...search,
        page: this.currentPage,
        limit: this.pageSize,
        ...this.form
      })
      this.loading = false
      if (status) {
        for(const item of list.list){
         const codeList= item.brandCode.split(',').map(item=>brandCode[item])
         item.brandCode=codeList.join(',')
        }
        this.dataList = list.list
        this.total = list.totalCount
      }
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList(this.search)
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList(this.search)
    }
  }
}
</script>

<style>
.paginationBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  overflow-x: auto;
}
</style>