<template>
  <div v-loading="loading" class="qqmap">
    <el-autocomplete
      v-if="search"
      v-model="searchName"
      class="inline-input"
      value-key="title"
      label="address"
      :fetch-suggestions="querySearch"
      placeholder="请输入内容"
      :trigger-on-focus="false"
      popper-class="qqamp-autocomplete"
      @select="handleSelect">
      <template slot-scope="{ item }">
        <p class="name">{{ item.title }}</p>
        <small class="address">{{ item.address }}</small>
      </template>
    </el-autocomplete>
    <slot />
    <div ref="qqmapRef" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
const APPKEY = `6JKBZ-PV3WF-UOQJD-JUTPM-FS6WV-PCFU7`

export default {
  props: {
    search: {
      type: Boolean,
      default: true
    },
    center: {
      type: Object,
      default: null
    },
    locationName: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      searchName: ''
    }
  },
  watch: {
    locationName(val, old) {
      if (!val) return
      this.searchLocation(val)
    }
  },
  mounted() {
    this.loadScript()
  },
  methods: {
    handleSelect(e) {
      this.map.setCenter(e.location)
      this.setLocation(e.location, e.address)
    },
    async querySearch(queryString, cb) {
      const suggest = new TMap.service.Suggestion({
        pageSize: 10
      })
      // const { data } = await search.searchRectangle({
      const { data } = await suggest.getSuggestions({
        keyword: queryString,
        location: this.map.getCenter()
      })
      cb(data)
    },
    loadScript() {
      if (window.TMap) return this.initMap()
      this.loading = true
      // 创建script标签，并设置src属性添加到body中
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://map.qq.com/api/gljs?v=1.exp&libraries=service&key=${APPKEY}`
      document.body.appendChild(script)
      script.onload = () => {
        this.loading = false
        this.initMap()
      }
    },
    async initMap() {
      // 创建地图实例
      this.map = new TMap.Map(this.$refs.qqmapRef, {
        zoom: 13,
        ...(this.center
          ? {
              center: new TMap.LatLng(
                this.center.latitude,
                this.center.longitude
              )
            }
          : {})
      })
      if (this.center) {
        const centerLocation = new TMap.LatLng(
          this.center.latitude,
          this.center.longitude
        )
        const address = await this.locationToLocationName(centerLocation)
        this.setLocation(centerLocation, address)
      } else if (this.locationName) {
        try {
          await this.searchLocation(this.locationName)
        } catch {
          this.useIpLocation()
        }
      } else {
        this.useIpLocation()
      }
      this.map.on('click', this.handleClickMap)
    },
    async handleClickMap(e) {
      if (this.disabled) {
        return
      }

      const address = await this.locationToLocationName(e.latLng)
      this.setLocation(e.latLng, address)
    },
    async locationToLocationName(location) {
      const geocoder = new TMap.service.Geocoder()
      const { result } = await geocoder.getAddress({ location })
      return result?.formatted_addresses?.recommend || result.address
    },
    async searchLocation(locationName) {
      const geocoder = new TMap.service.Geocoder()
      const { result } = await geocoder.getLocation({
        address: locationName
      })
      this.map.setCenter(result.location)
      this.setLocation(result.location, locationName)
    },
    async useIpLocation() {
      const ipLocation = new TMap.service.IPLocation() // 新建一个IP定位类
      const { result } = await ipLocation.locate()
      this.map.setCenter(result.location)
      const { province, city } = result.ad_info
      this.setLocation(result.location, `${province}${city}`)
    },
    setLocation(location, title) {
      this.$emit('onChange', {
        latitude: Number(location.lat).toFixed(6),
        longitude: Number(location.lng).toFixed(6),
        address: title
      })
      this.setInfoWindow(location, title)
      this.setMarker(location)
    },
    setInfoWindow(location, title) {
      if (this.InfoWindow) {
        this.InfoWindow.setPosition(location)
        this.InfoWindow.setContent(title)
        this.InfoWindow.open()
      } else {
        this.InfoWindow = new TMap.InfoWindow({
          map: this.map,
          position: location, // 设置信息框位置
          content: title, // 设置信息框内容
          offset: { x: 0, y: -32 }
        })
        this.InfoWindow.on('closeclick', () => {
          if (this.markerLayer) {
            this.markerLayer.setVisible(false)
          }
          this.$emit('onChange', null)
        })
      }
    },
    setMarker(location) {
      if (this.markerLayer) {
        this.markerLayer.setVisible(true)
        this.markerLayer.updateGeometries([
          {
            ...this.markerLayer.getGeometryById('marker-layer'),
            position: location
          }
        ])
      } else {
        this.markerLayer = new TMap.MultiMarker({
          geometries: [
            {
              id: 'marker-layer',
              position: location,
              styleId: 'marker'
            }
          ],
          map: this.map,
          styles: {
            marker: new TMap.MarkerStyle({
              width: 24,
              height: 35,
              anchor: { x: 12, y: 35 }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.qqmap {
  position: relative;
  background-color: #efefef;

  .inline-input {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 300px;
    z-index: 1001;
  }
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
.address {
  font-size: 12px;
  color: #b4b4b4;
  display: block;
  line-height: 1.2;
}

.highlighted .address {
  color: #ddd;
}
</style>

<style lang="scss">
.qqamp-autocomplete {
  li {
    line-height: normal;
    padding: 10px;
  }
}
</style>
