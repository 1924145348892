var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.columnItem.renderFormItem)?_c(_vm.columnItem.renderFormItem,_vm._g(_vm._b({tag:"component",attrs:{"value":_vm.value}},'component',_vm.fieldPropsSnapshot,false),_vm.listeners)):(_vm.columnItem.valueType === 'rich-text')?_c('RequiredFormItem',_vm._b({},'RequiredFormItem',_vm.formProps,false),[(_vm.fieldPropsSnapshot.disabled)?_c('div',{staticClass:"rich-text-container",domProps:{"innerHTML":_vm._s(_vm.value)}}):_c('Weditor',_vm._g(_vm._b({attrs:{"value":_vm.value}},'Weditor',_vm.fieldPropsSnapshot,false),_vm.listeners))],1):(_vm.columnItem.valueType === 'date')?_c('RequiredFormItem',_vm._b({},'RequiredFormItem',_vm.formProps,false),[_c(_vm.columnItem.dateComponent || 'el-date-picker',_vm._g(_vm._b({tag:"component",staticStyle:{"width":"100%"},attrs:{"value":_vm.value}},'component',_vm.fieldPropsSnapshot,false),_vm.listeners))],1):(_vm.columnItem.valueType === 'select')?_c('RequiredFormItemSelect',_vm._g(_vm._b({attrs:{"value":_vm.value}},'RequiredFormItemSelect',{
    textDisplay: _vm.fieldPropsSnapshot.disabled,
    ..._vm.formProps,
    ..._vm.valueEnumSelectProps,
    ..._vm.fieldPropsSnapshot
  },false),_vm.listeners)):(_vm.columnItem.valueType === 'upload')?_c('RequiredFormItem',_vm._b({},'RequiredFormItem',_vm.formProps,false),[_c('CommonUpload',_vm._g(_vm._b({attrs:{"value":_vm.value}},'CommonUpload',_vm.fieldPropsSnapshot,false),_vm.listeners))],1):(_vm.columnItem.valueType === 'radio')?_c('RequiredFormItem',_vm._b({},'RequiredFormItem',_vm.formProps,false),[_c('el-radio-group',_vm._g(_vm._b({attrs:{"value":_vm.value}},'el-radio-group',_vm.fieldPropsSnapshot,false),_vm.listeners),_vm._l((_vm.valueEnumSelectProps.dataList),function(valueIetm,_index){return _c('el-radio',{key:_index,attrs:{"label":valueIetm[_vm.valueEnumSelectProps.valueKey]}},[_vm._v(" "+_vm._s(valueIetm[_vm.valueEnumSelectProps.labelKey])+" ")])}),1)],1):_c('RequiredFormItemInput',_vm._g(_vm._b({attrs:{"value":_vm.value}},'RequiredFormItemInput',{
    ..._vm.formProps,
    ..._vm.fieldPropsSnapshot
  },false),_vm.listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }