<template>
  <el-form-item :class="['formItem',(isOA||readonly)?'required-form-item-input':'']" :prop="prop" :label="label" :rules="[
      {
        required: required,
        message: label? `${label}不能为空` : '',
        trigger: ['blur', 'change']
      }
    ]">
    <el-input :title="inputData" v-model="inputData" @keyup.native="$emit('input',inputData)" :readonly="readonly" @blur="(e)=>{$emit('blur',e)}" v-bind="$attrs" v-on="$listeners"/>
  </el-form-item>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'
  export default {
    props: {
      value: {
        type: [String, Number]
      },
      label: {
        type: String,
        default: ''
      },
      prop: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters("dealershipTransfer", ["isOA"]),
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          this.inputData = newVal
        }
      }
    },
    data() {
      return {
        inputData: ''
      }
    },
  }

</script>

<style lang='scss' scoped>
  @import "../../styles/colors.scss";

  .formItem {
    width: 20%;
  }

  @media (max-width: 702px) {
    .formItem {
      width: 100%;
    }
  }

  @media screen and (min-width: 702px) and (max-width: 1260px) {
    .formItem {
      width: calc(33% - 10px);
    }
  }

  @media (min-width: 1260px) {
    .formItem {
      width: calc(25% - 10px);
    }
  }

  .required-form-item-input {
    ::v-deep {
      .el-input.is-disabled ,.el-input{
        .el-input__inner {
          background-color: transparent;
          border-color: transparent;
          border-bottom-color: #dfe4ed;
          color: #333;
          cursor: default;
          padding-left: 10px;
        }
      }

      .el-input {
        .el-input__inner[type="number"] {
          padding-right: 0;
        }
      }
    }
  }

</style>
