<template>
  <FlowForm
    ref="flowFormRef"
    :model="form"
    :get-detail="getDetail"
    :on-submit="onSubmit"
    :show-save="false"
    title="海外送达方数据录入"
  >
    <HeaderTitle style="margin: 20px 0 10px" title="实控人信息" />
    <RequiredFormItemSelect
      ref="businessRef"
      v-model="form.businessLicenseId"
      class="formItem"
      prop="businessLicenseId"
      label="售达方"
      label-key="licenseNumber"
      value-key="id"
      filterable
      remote
      popper-class="custom-business-select"
      :get-data-list="getBusinessLicense"
      :get-next-list="getBusinessLicense"
      @onOptionsListNotFound="onNotFoundBusinessLicense"
      @changeSelectedItem="
        e => {
          businessSelectedItem = e
          form.businessLicenseName = e.businessLicenseName
          form.licenseNumber = e.licenseNumber
        }
      "
    >
      <template v-slot="{ scope }">
        <span>{{ scope.row.licenseNumber }}</span>
        <small>{{ scope.row.businessLicenseName }}</small>
      </template>
    </RequiredFormItemSelect>
    <RequiredFormItemInput
      v-model="form.businessLicenseName"
      class="formItem"
      label="售达方名称"
      placeholder="自动带出"
      disabled
    />
    <RequiredFormItemSelect
      ref="shopRef"
      v-model="form.authorizedCityCode"
      class="formItem"
      prop="authorizedCityCode"
      label="授权城市"
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      filterable
      remote
      :get-data-list="getCitylist"
      :get-next-list="getCitylist"
      @onOptionsListNotFound="onNotFoundCityList"
      @changeSelectedItem="
        e => {
          form.authorizedCityName = e ? e.authorizedCityName : ''
        }
      "
    />

    <RequiredModuleFormItemSelect
      v-model="form.sendOtherCountryFlag"
      prop="sendOtherCountryFlag"
      type="flow_common_select_ifEnable"
      class="formItem"
      label="是否需要发往他国"
    />
    <RequiredModuleFormItemSelect
      v-model="form.sendOtherCountryName"
      prop="sendOtherCountryName"
      module="public"
      type="country"
      value-key="content"
      class="formItem"
      label="发往国家"
    />
    <RequiredFormItemSelect
      ref="applyBrandListRef"
      v-model="form.seriesCode"
      class="formItem"
      label-key="name"
      value-key="code"
      label="授权系列"
      placeholder="下拉选择/目前仅支持A2系列"
      :get-data-list="getBrandseriesList"
      @change="changeApplyBrandListSelectedItem"
    />

    <RequiredFormItemInput
      v-model="form.senderNumber"
      class="formItem"
      label="送达方编号"
      placeholder="归档后自动生成"
      disabled
    />
    <RequiredFormItemInput
      v-model="form.senderName"
      class="formItem"
      label="送达方名称"
      placeholder="归档后自动生成"
      disabled
    />
    <RequiredFormItemInput
      v-model="form.senderOtherName"
      prop="senderOtherName"
      class="formItem"
      label="送达方别名"
    />
    <RequiredModuleFormItemSelect
      v-model="form.overseasFlag"
      type="overseas_flag"
      class="formItem"
      label="送达方类型"
    />
  </FlowForm>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import * as BusinessApi from '@/api/attractPlatform/business'
import { saveShipToParty } from './overseasDataApi'
import { brandseriesList } from '@/api/common'
import * as dealerJoinApi from '@/api/attractPlatform/dealerJoinApi'
import { authorizedcityListpage } from '@/api/businessOos'

import FlowForm from './components/FlowForm'
import HeaderTitle from '@/views/flow/components/headerTitle'

import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'

export default {
  components: {
    FlowForm,
    HeaderTitle,
    RequiredModuleFormItemSelect,
    RequiredFormItemInput,
    RequiredFormItemSelect
  },
  data() {
    return {
      form: {
        businessLicenseId: '', //	是	strng	售达方id
        businessLicenseName: '', //	是	strng	售达方名称
        licenseNumber: '', //	是	strng	售达方编号
        sendOtherCountryFlag: '', //	是	strng	是否需要发往他国 1是 0否
        sendOtherCountryName: '', //	否	strng	发往国家
        seriesId: '', //	是	strng	大品牌id
        seriesName: '', //	是	strng	大品牌名字
        seriesCode: '', //	是	strng	大品牌编号
        senderNumber: '', //	是	strng	送达方编号
        senderName: '', //	是	strng	送达方名称
        senderOtherName: '', //	是	strng	送达方别名
        overseasFlag: '', //	是	strng	是否海外送达方标记 0否 1是
        authorizedCityCode: '', //	是	strng	授权城市编码
        authorizedCityName: '', //	是	strng	授权城市名称
        createUsername: '', //	是	strng	创建人名字
        createUserId: '' //	是	strng	创建人id
      },

      businessSelectedItem: null // 选中的售达方
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState({ isOA: state => state.dealershipTransfer.isOA })
  },
  methods: {
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await dealerJoinApi.detail({
          formId: this.$route.query.id
        })
        initFormData = data
      }
      if (initFormData) {
        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
      }
      if (this.userInfo && !this.isOA) {
        const { createUserId, createUserName, ...rest } = this.form || {}
        this.form = {
          ...rest,
          createUserId: createUserId || this.userInfo.accountId,
          createUserName: createUserName || this.userInfo.realName
        }
      }
    },
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    getCitylist({ page: currPage, searchText: authorizedCityName }) {
      return authorizedcityListpage({
        ifEnabled: 0,
        currPage,
        addr: authorizedCityName,
        pageSize: 10,
        overseasFlag: 1
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundCityList({ value: authorizedCityCode }, callback) {
      callback(
        await authorizedcityListpage({
          ifEnabled: 0,
          authorizedCityCode
        }).then(res => res.data)
      )
    },
    getBrandseriesList(params) {
      return brandseriesList({
        brandLevel: 1,
        // code: 'A2'
        code: '24'
      }).then(res => res.data)
    },
    changeApplyBrandListSelectedItem() {
      this.$nextTick(() => {
        const selectItems =
          this.$refs.applyBrandListRef.$selectRef.selectedItem || []
        this.form.seriesId = selectItems.id
        this.form.seriesCode = selectItems.code
        this.form.seriesName = selectItems.name
      })
    },
    onSubmit() {
      return saveShipToParty(this.form)
    }
  }
}
</script>

<style></style>
