<template>
  <div class="anchor-navigate">
    <el-dropdown>
      <span class="el-dropdown-link">
        页面导航<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(item,index) in options" :key="index">
          <el-link @click="onScrollTo(item)" :underline="false">{{
            item.title
          }}</el-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <p class="el-border-bottom">页面导航</p>

    <ul>
      <li v-for="item in options">
        <el-link :href="`#${item.id}`" :underline="false">{{
          item.title
        }}</el-link>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      defualt: () => []
    }
  },
  methods: {
    onScrollTo(item) {
      const anchor = document.querySelector(`#${item.id}`)
      if (!anchor) return
      anchor.scrollIntoView(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.anchor-navigate {
  position: fixed;
  top: 30%;
  right: 0;
  padding: 15px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px 0 0 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
  /* transform: translateX(60%); */
  &:hover {
    transform: translateX(0);
    background-color: rgba(255, 255, 255, 1);
  }
}
</style>
