const MEASURE_SIZE = 200
export const isImageFileType = (type) => type.indexOf('image/') === 0
export function previewImage(file) {
  return new Promise(resolve => {
    if (!file.type || !isImageFileType(file.type)) {
      resolve('')
      return
    }

    const canvas = document.createElement('canvas')
    canvas.width = MEASURE_SIZE
    canvas.height = MEASURE_SIZE
    canvas.style.cssText = `position: fixed; left: 0; top: 0; width: ${MEASURE_SIZE}px; height: ${MEASURE_SIZE}px; z-index: 9999; display: none;`
    document.body.appendChild(canvas)
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      const { width, height } = img

      let drawWidth = MEASURE_SIZE
      let drawHeight = MEASURE_SIZE
      let offsetX = 0
      let offsetY = 0

      if (width > height) {
        drawHeight = height * (MEASURE_SIZE / width)
        offsetY = -(drawHeight - drawWidth) / 2
      } else {
        drawWidth = width * (MEASURE_SIZE / height)
        offsetX = -(drawWidth - drawHeight) / 2
      }

      ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight)
      const dataURL = canvas.toDataURL()
      document.body.removeChild(canvas)

      resolve(dataURL)
    }
    img.crossOrigin = 'anonymous'
    if (file.type.startsWith('image/svg+xml')) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (reader.result) img.src = reader.result
      })
      reader.readAsDataURL(file)
    } else {
      img.src = window.URL.createObjectURL(file)
    }
  })
}

export function getThumbUrl(url, l_width) {
  // 华为obs
  const isObsUrl = /obs.iderucci.com/.test(url)

  if (isObsUrl) {
    return `${url}?x-image-process=image/resize,l_${parseInt(l_width)},limit_0`
  }
  return url
}
