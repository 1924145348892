import Vue from 'vue'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
// import 'element-ui/lib/theme-chalk/index.css'
// import locale from "element-ui/lib/locale/lang/en"; // lang i18n
import 'element-ui/lib/theme-chalk/display.css'
import 'viewerjs/dist/viewer.css'
import '@/styles/index.scss' // global css
import '@/styles/element-variables.scss'
import 'default-passive-events'
import '@/icons' // icon
import '@/permission' // permission control
import ElementUI from 'element-ui'
import VueViewer, { Viewer } from 'v-viewer'
import $load from '@/utils/loading'
import App from './App'
import store from './store'
import router from './router'
import loadingClick from '@/derective/common/loadingClick'
// import dialogDrag from '@/derective/common/dialogDrag'

import permission from '@/derective/permission'
import { hasPermission } from '@/utils'

import Track from '@/utils/point.module.min'
import { getStorage } from '@/utils/storage'

const track = new Track({
  clientKey: '10012', //平台key，管理员提供
  userId: getStorage('userInfo')?.accountId || '' //用户Id，如果有可以提供 ，否则会以游客方式统计
})
track.sendEventData('visit')

// vue-fragment 支持多个根节点
import { Plugin } from 'vue-fragment'
Vue.use(Plugin)

const containerData = {
  width: window.innerWidth,
  height: window.innerHeight
}
console.log('打印当前页面高度', { containerData })
window.addEventListener(
  'message',
  function ({ data }) {
    // oa检测到屏幕长发生变化或者滚动
    if (data.scrollTop && data.windowHeight) {
      console.log({ data })
      containerData.height = data.scrollTop * 2 + data.windowHeight
    }
  },
  false
)
Viewer.prototype.initContainer = function () {
  this.containerData = containerData
  // Object.assign(this.containerData, containerData)
}

Vue.use(ElementUI, { size: 'small' })
Vue.use(permission)
Vue.use(loadingClick)
// Vue.use(dialogDrag)

Vue.use(VueViewer)
Vue.prototype.$load = $load
Vue.prototype.$haspermission = hasPermission
Vue.config.productionTip = false

store.dispatch('commonConfig/asyncInitCommonData')

export default new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
