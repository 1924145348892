<template>
  <div class="enterQuery">
    <TablePro :columns="columns" :request="requestTableList" />
  </div>
</template>

<script>
import TablePro from '@/components/TablePro'
import { overseasenterrecordListpage } from './overseasDataApi'
export default {
  components: { TablePro },
  data() {
    return {
      columns: [
        {
          title: '实控人编码（账号）',
          label: '实控人编码',
          name: 'bossNumber',
          search: true
        },
        { title: '实控人姓名', name: 'bossName', search: true },
        { title: '售达方', name: 'businessLicenseName' },
        { title: '送达方', name: 'senderName' },
        { title: '门店', name: 'shopName' },
        { title: '录入人', name: 'createUserName' },
        { title: '创建时间', name: 'createTime' }
      ]
    }
  },
  methods: {
    requestTableList(params) {
      return overseasenterrecordListpage(params)
    }
  }
}
</script>

<style></style>
