<template>
  <fragment>
    <slot :render="taskQueueRender" />
  </fragment>
</template>

<script setup>
import {
  ref,
  unref,
  defineComponent,
  computed,
  provide,
  nextTick,
  onMounted,
  onUnmounted
} from 'vue'
import { mergeStep } from '@/utils'
import {
  reportTaskList,
  reportTaskDownloadNumber,
  reportTaskCancel,
  reportTaskRead
} from '@/api/common'
import { Notification, MessageBox, Message } from 'element-ui'
import { pingURL } from './fn'

import TablePro, { render } from './index.vue'
import { tableProDb } from './tableProDb'
import { getStorage } from '@/utils/storage'

const taskTypeEnum = {
  local: '0',
  server: '1'
}

const cardProps = {
  shadow: 'hover',
  class: '!border-0',
  bodyStyle: 'padding: 0'
}
const TaskStatusEnum = {
  未开始: 0,
  进行中: 1,
  已完成: 2,
  已失败: 3
}
const ServerTaskStatusEnum = {
  待开始: 0,
  执行中: 10,
  失败: 20,
  已完成: 30,
  已取消: 40
}

provide('addTask', addTask)

const visiblePopover = ref(false)
const taskList = ref([])
const columns = [
  { title: '任务名称', name: 'title' },
  {
    title: '任务状态',
    name: 'status',
    valueEnum: [
      { label: '未开始', value: TaskStatusEnum.未开始 },
      { label: '进行中', value: TaskStatusEnum.进行中 },
      { label: '已完成', value: TaskStatusEnum.已完成 },
      { label: '已失败', value: TaskStatusEnum.已失败 }
    ]
  },
  {
    title: '任务进度',
    name: 'progress',
    renderText(_, data) {
      console.log(data)
      return data.status === TaskStatusEnum.进行中 ? `${data.progress}%` : '-'
    }
  },
  {
    title: '操作',
    width: 150,
    render: render((h, context) => {
      console.log(context)
      return (
        <div>
          {[TaskStatusEnum.已完成, TaskStatusEnum.已失败].includes(
            context.data.status
          ) && (
            <el-button
              size="small"
              type="text"
              onClick={() => {
                context.data.status = TaskStatusEnum.未开始
                taskList.value = [...taskList.value]
                checkTaskQueue()
              }}>
              再次执行
            </el-button>
          )}
          {context.data.status !== TaskStatusEnum.进行中 && (
            <el-button
              size="small"
              type="text"
              onClick={() => {
                removeTask(context.data)
              }}>
              删除
            </el-button>
          )}
        </div>
      )
    })
  }
]

const isLocalProgress = computed(() => {
  return unref(taskList).some(item => item.status === TaskStatusEnum.进行中)
})

const activeName = ref(taskTypeEnum.server)

const serverTableProps = {
  autoWidthCell: true,
  frontEndPaging: true,
  columns: [
    {
      title: '文件名',
      name: 'fileName'
    },
    {
      title: '任务状态',
      name: 'taskStatus',
      search: true,
      valueType: 'select',
      valueEnum: [
        { label: '待开始', value: 0, status: 'info' },
        { label: '执行中', value: 10, status: 'warning' },
        { label: '失败', value: 20, status: 'danger' },
        { label: '已完成', value: 30, status: 'success' },
        { label: '已取消', value: 40, status: 'info' }
      ]
    },
    {
      title: '下载次数',
      name: 'downloadNumber'
    },
    /**
    {
      title: '创建人工号',
      name: 'createAccount'
    },
     */
    {
      title: '创建时间',
      name: 'createTime'
    },
    {
      title: '操作',
      width: 80,
      render: render((h, context) => {
        return (
          <fragment>
            {context.data.taskStatus === ServerTaskStatusEnum.已完成 && (
              <el-button
                v-loading-click={() => downloadTaskExcel(context.data)}
                type="text">
                下载文件
              </el-button>
            )}
            {context.data.taskStatus === ServerTaskStatusEnum.待开始 && (
              <el-button
                v-loading-click={() => onServerTaskCancel(context.data)}
                type="text">
                取消任务
              </el-button>
            )}
          </fragment>
        )
      })
    }
  ],
  request(params, _, { actionType }) {
    const userInfo = getStorage('userInfo')
    params.createAccount = userInfo.account
    params.pageSize = 9999
    return reportTaskList(params, actionType !== 'refresh').then(res => {
      serverTaskList.value = res.data
      return res
    })
  },
  showTableSetting: false,
  autoHeight: false
}
async function onServerTaskCancel(taskItem) {
  await MessageBox.confirm('是否取消该任务？')
  await reportTaskCancel({
    id: taskItem.id
  })
  Message.success('操作成功')
  updateServerTask()
}

const serverTaskRef = ref(null)
const taskQueueRender = defineComponent({
  setup() {
    return () => {
      return (
        <el-popover
          value={visiblePopover.value}
          onInput={e => (visiblePopover.value = e)}
          width="900"
          trigger="click"
          placement="top-start"
          scopedSlots={{
            default: () => (
              <el-tabs
                value={activeName.value}
                onInput={e => (activeName.value = e)}
                type="border-card">
                <el-tab-pane label="服务器导出任务" name={taskTypeEnum.server}>
                  <div style="color: #666; margin-bottom: 15px">
                    <div>
                      服务器导出完成后，需要手动点击下载（推荐本地下载）
                    </div>
                    <div>如果不需要当前导出任务，请手动取消</div>
                  </div>
                  <TablePro
                    ref={e => (serverTaskRef.value = e)}
                    attrs={{ ...serverTableProps }}
                  />
                </el-tab-pane>
                <el-tab-pane label="本地导出任务" name={taskTypeEnum.local}>
                  <div style="color: #666; margin-bottom: 15px">
                    刷新页面时清空当前列表
                  </div>
                  <TablePro
                    columns={columns}
                    showTableSetting={false}
                    default-data-list={taskList.value}
                    card-props={cardProps}
                    auto-height={false}
                  />
                </el-tab-pane>
              </el-tabs>
            ),
            reference: () => (
              <el-button
                circle
                class="queue-circle"
                icon={
                  progressTaskCount.value
                    ? 'el-icon-loading'
                    : 'el-icon-download'
                }></el-button>
            )
          }}></el-popover>
      )
    }
  }
})

function addTask(title, method, taskType = taskTypeEnum.local) {
  activeName.value = taskType
  if (taskType === taskTypeEnum.server) {
    return new Promise(async (resolve, reject) => {
      try {
        await method()
        Notification({
          title: '提示',
          message: '已加入服务器任务队列，请稍后查看'
        })
        checkServerTaskQueue()
        resolve()
        nextTick(() => {
          visiblePopover.value = true
        })
      } catch (error) {
        reject(error)
      }
    })
  } else {
    return new Promise((resolve, reject) => {
      Notification({
        title: '提示',
        message: '已加入导出队列，请不要关闭页面'
      })
      taskList.value.push({
        title,
        progress: 0,
        status: 0,
        method,
        resolve: resolve,
        reject: reject
      })
      checkTaskQueue()
      nextTick(() => {
        visiblePopover.value = true
      })
    })
  }
}
function removeTask(task) {
  taskList.value = taskList.value.filter(item => item !== task)
}
async function downloadTaskExcel(taskItem) {
  downFile(taskItem.outerNetPath)
  await reportTaskDownloadNumber({ id: taskItem.id })
  updateServerTask()
}

function downFile(url) {
  window.open(url, '_blank')
}

const serverTaskList = ref([])
async function updateServerTask() {
  await serverTaskRef.value?.onRefreshTableList(1)
}
const progressServerTaskCount = computed(() => {
  return serverTaskList.value.filter(item =>
    [ServerTaskStatusEnum.待开始, ServerTaskStatusEnum.执行中].includes(
      item.taskStatus
    )
  ).length
})
const progressLocalTaskCount = computed(() => {
  return taskList.value.filter(item =>
    [TaskStatusEnum.未开始, TaskStatusEnum.进行中].includes(item.status)
  ).length
})

const progressTaskCount = computed(() => {
  return progressLocalTaskCount.value + progressServerTaskCount.value
})

// 检查服务器任务队列
const checkServerTaskQueue = mergeStep(async function () {
  const userInfo = getStorage('userInfo')
  await updateServerTask()
  serverTaskList.value.forEach(async item => {
    if (
      item.taskStatus === ServerTaskStatusEnum.已完成 &&
      !item.downloadNumber &&
      !item.isRead
    ) {
      // 前端已经操作过，跳过提示
      const taskQuery = await tableProDb.export_excel_flag.get({
        task_key: `${item.id}_${userInfo.id}`
      })
      if (taskQuery) return

      // 下载完成提示用户下载
      MessageBox.confirm(
        `任务《${item.fileName}》已完成，立即下载文件？`,
        '提示',
        {
          confirmButtonText: '下载',
          cancelButtonText: '取消',
          type: 'success'
        }
      )
        .then(() => {
          return downloadTaskExcel(item)
        })
        .catch(() => {
          // 标记为已读
          reportTaskRead({ id: item.id })
        })
        .finally(() => {
          // 记录操作
          tableProDb.export_excel_flag.add({
            task_key: `${item.id}_${userInfo.id}`
          })
        })
    }
  })
  setTimeout(() => {
    if (progressServerTaskCount.value) {
      checkServerTaskQueue()
    }
  }, 60 * 1000)
})
// 检查任务队列
const checkTaskQueue = mergeStep(async function () {
  // 检查是否有进行中
  if (isLocalProgress.value) return

  const nextTaskItem = taskList.value.find(
    item => item.status === TaskStatusEnum.未开始
  )
  if (!nextTaskItem) return
  try {
    await runTask(nextTaskItem)
  } catch (error) {
    console.log(error)
  } finally {
    setTimeout(() => {
      // 跳过当前进程，不然会被mergeStep拦截
      checkTaskQueue()
    })
  }
})
async function runTask(task) {
  if (task.status === TaskStatusEnum.进行中) return
  task.status = TaskStatusEnum.进行中
  try {
    const res = await task.method(progress => {
      task.progress = progress
    })
    task.resolve(res)
    task.status = TaskStatusEnum.已完成
    Notification.success({
      title: '提示',
      message: `任务"${task.title}"执行成功`
    })
  } catch (error) {
    task.reject(error)
    task.status = TaskStatusEnum.已失败
    Notification.error({
      title: '提示',
      message: `任务"${task.title}"失败'`
    })
    throw error
  }
}

onMounted(() => {
  checkServerTaskQueue()
  window.addEventListener('beforeunload', beforeWindowUnload)
})
onUnmounted(() => {
  window.removeEventListener('beforeunload', beforeWindowUnload)
})

function beforeWindowUnload(e) {
  if (unref(isLocalProgress)) {
    e.preventDefault()
    e.returnValue = ''
  }
}
</script>
<style lang="scss">
.TaskQueue {
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 999;
}

.queue-circle {
  border-radius: 50% !important;
  padding: 12px !important;
  border: none;
  margin-right: 10px;
}
.el-badge__content.is-fixed {
  right: 30px;
  top: 10px;
}
</style>
