import { render, staticRenderFns } from "./areaDepartmentShip.vue?vue&type=template&id=29cd97d4&scoped=true"
import script from "./areaDepartmentShip.vue?vue&type=script&setup=true&lang=js"
export * from "./areaDepartmentShip.vue?vue&type=script&setup=true&lang=js"
import style0 from "./areaDepartmentShip.vue?vue&type=style&index=0&id=29cd97d4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29cd97d4",
  null
  
)

export default component.exports