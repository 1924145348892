import http from "../../utils/request";

// 根据idCart获取经销商信息
export const getBossByIdCard = params => {
  return http({
    url: "/dealermanage/boss/getBossByIdCard",
    params
  });
};
// 提交
export const submit = data => {
  return http({
    url: "/dealermanage/operationflow/dealerJoinApi/submit",
    data,
    method: "POST"
  });
};
// 保存到草稿
export const saveDraft = data => {
  return http({
    url: "/dealermanage/operationflow/dealerJoinApi/saveDraft",
    data,
    method: "POST"
  });
};

// 详情
export const detail = params => {
  return http({
    url: "/dealermanage/operationflow/dealerJoinApi/getDetail",
    params
  });
};
