<template>
  <CommonPopup
    ref="commonPopupRef"
    :popup-class-name="popupClassName"
    v-bind="attrs.commonPopupProps"
    v-on="popupListeners"
  >
    <template #default="{ data }">
      <slot name="form-content-before" />
      <CommonForm
        ref="formRef"
        v-bind="{ isPreview: formDisabled, ...attrs.commonFormProps }"
        :class="{ isPreview: formDisabled }"
        :default-params="data"
        v-on="$listeners"
      >
        <template #form-before="{ params }">
          <slot name="form-before" :params="params" />
        </template>
        <template #form-after="{ params }">
          <slot name="form-after" :params="params" />
        </template>
        <template #footer="{ params }">
          <slot
            name="footer"
            :params="params"
            :on-submit="onSubmitForm"
            :on-reset="onReset"
            :on-clear="onClear"
            :on-cancel="onHide"
          >
            <el-form-item style="display: block; text-align: right; margin-bottom: 0">
              <el-button v-if="actions.includes('reset')" @click="onReset">
                {{ resetText }}
              </el-button>
              <el-button v-if="actions.includes('cancel')" @click="onHide">
                {{ closeText }}
              </el-button>
              <el-button
                v-if="actions.includes('submit')"
                v-loading-click="onSubmitForm"
                type="primary"
              >
                {{ confirmText }}
              </el-button>
            </el-form-item>
          </slot>
        </template>
      </CommonForm>
    </template>
    <template #trigger>
      <slot name="trigger" :on-show="onShow" :on-preview="onPreview" />
    </template>
  </CommonPopup>
</template>

<script>
import CommonForm from './CommonForm.vue'
import CommonPopup from './CommonPopup.vue'
export * from './fn'

const toCamelString = (text) => {
  const keyArr = text.split('-')
  let camelKey = keyArr[0]

  for (let i = 1, len = keyArr.length; i < len; i++) {
    camelKey += keyArr[i].replace(/^./, keyArr[i][0].toLocaleUpperCase())
  }
  return camelKey
}
export const attrsAssign = (attrs) => {
  const commonPopupPropsKeys = Object.keys(CommonPopup.props).map(toCamelString)

  const commonPopupProps = {}
  const commonFormProps = {}
  for (const key in attrs) {
    const camelKey = toCamelString(key)
    // 在form-item和required-form-item组件定义的props
    if (commonPopupPropsKeys.includes(camelKey)) {
      commonPopupProps[key] = attrs[key]
    } else {
      commonFormProps[key] = attrs[key]
    }
  }

  return { commonPopupProps, commonFormProps }
}

export default {
  components: {
    CommonForm,
    CommonPopup
  },
  props: {
    popupClassName: {
      type: String,
      default: 'popup-form'
    },
    actions: {
      // submit,cancel,reset
      type: Array,
      default: () => ['submit', 'cancel']
    },
    confirmText: {
      type: String,
      default: '提交'
    },
    resetText: {
      type: String,
      default: '重置'
    },
    closeText: {
      type: String,
      default: '取消'
    }
  },
  data() {
    return {
      formDisabled: false
    }
  },
  computed: {
    attrs({ $attrs }) {
      return attrsAssign($attrs)
    },
    popupListeners({ $listeners }) {
      const { popupListeners, dialogListeners, drawerListeners } = $listeners
      return {
        popupListeners: popupListeners || {},
        dialogListeners: dialogListeners || {},
        drawerListeners: drawerListeners || {}
      }
    }
  },
  methods: {
    onHide() {
      this.$nextTick(() => {
        this.$refs.commonPopupRef.onHide()
      })
    },
    onShow(params = {}, callback = () => {}, options = {}) {
      return new Promise((resolve) => {
        this.$refs.commonPopupRef.onShow(
          params,
          () => {
            resolve(this.submitFlag)
            if (typeof callback === 'function') {
              callback(this.submitFlag)
              this.submitFlag = false
            }
          },
          options
        )
      })
    },
    onPreview(params = {}, callback = () => {}, options = {}) {
      this.formDisabled = true
      return new Promise((resolve) => {
        this.$refs.commonPopupRef.onShow(
          params,
          () => {
            resolve(this.submitFlag)
            this.formDisabled = false
            if (typeof callback === 'function') {
              callback(this.submitFlag)
              this.submitFlag = false
            }
          },
          options
        )
      })
    },
    async onSubmitForm(...rest) {
      this.submitFlag = await this.$refs.formRef.onSubmit(...rest)
      if (this.submitFlag) {
        this.onHide()
      }
    },

    onReset(params) {
      return this.$refs.formRef.onReset(params)
    },
    onClear() {
      return this.$refs.formRef.onClear()
    },
    onInit(...rest) {
      this.$refs.formRef.onInit(...rest)
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-form {
  display: inline-block;
}
.isPreview {
  :deep(.el-radio__input.is-disabled.is-checked .el-radio__inner) {
    border-color: #409eff;
    background: #409eff;
    &::after {
      background-color: #f5f7fa;
    }
  }
  :deep(.el-input.is-disabled .el-input__inner) {
    color: inherit;
    cursor: inherit;
  }
  :deep(.el-range-editor.is-disabled) {
    cursor: inherit;
    input {
      color: #000;
      cursor: inherit;
    }
  }
}
</style>
