import http from '@/utils/request'
// 保存草稿
export const saveDraft = data =>
  http({
    url: '/dealermanage/bpm/overseasDealer/saveDraft',
    method: 'POST',
    data
  })
//获取详情
export const getDetail = params =>
  http({ url: '/dealermanage/bpm/overseasDealer/details', params })
//提交
export const submit = data =>
  http({
    url: '/dealermanage/bpm/overseasDealer/submit',
    method: 'POST',
    data
  })
//审批
export const submitApproveData = data =>
  http({
    url: '/dealermanage/bpm/overseasDealer/submitApproveData',
    method: 'POST',
    data
  })
