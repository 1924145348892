<template>
  <div>
    <TableItem
      v-for="(item, index) in tableData"
      :key="item.tableName || index"
      :data="item"
    />
    <div class="flex">
      <el-button type="warning" @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableItem from './components/tableItem.vue'
export default {
  data() {
    return {
      tableData: []
    }
  },
  components: {
    TableItem
  },
  methods: {
    goBack() {
      this.$route.params.page = this.$route.query.page
      this.$router.back()
    }
  },
  mounted() {
    this.dataJson.forEach(item => {
      if (!this.tableData.find(i => i.tableName === item.tableName)) {
        this.tableData.push({
          tableName: item.tableName,
          data: [JSON.parse(item.historicalData)]
        })
      } else {
        this.tableData = this.tableData.map(data => {
          if (data.tableName === item.tableName) {
            data.data.push(JSON.parse(item.historicalData))
          }
          return data
        })
      }
    })
  },
  computed: {
    ...mapState('commonConfig', ['dataJson'])
  }
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  justify-content: center;
}
</style>
