const state = {
  applyData: ["慕思经典", "独立店-V6", "独立店-家纺", "独立店-助眠非家居", "独立店-优家", "独立店-慕思家居", "独立店-DeRUCCI HOME", "独立店-慕思高端"],
  educationData: ["其他", "小学", "初中", "高中", "职高", "中技", "中专", "大专", "本科", "硕士", "博士", "MBA", "EMBA", "博士后"],
  brandData: ["歌蒂娅", "凯奇", "0769", "3D", "儿童", "慕思北欧", "慕思凯锐", "慕思意简", "慕思蓝奢", "慕思智能电动", "V6", "V6家居", "苏斯", "Pauly", "慕思国际", "slumberzone", "MDL", "DeRUCCI HOME", "慕思助眠", "慕思芯睡眠", "助眠系列", "优家整装", "Calia Italia", "Calia Sofart", "电商沙发", "慕思真皮沙发", "酒店工程家居", "海外家居", "渠道沙发", "慕思布衣沙发"],
  shopData: ['KA商场店', '百货店', '创新渠道', '当地商场', '独立店', '社区店', '展示店', '助眠非标展位'],
  shopTypeData: ['八方来家居', '博皇家居', '博皇家具(KA)', '博耐建材家居', '大明宫家居(KA)', '当地商场', '得胜家居(KA)', '地产物业', '第六空间(KA)', '第三空间', '第一城家具', '电器连锁', '定制等', '东华家居', '独立店', '非KA商场', '富安居家居(KA)', '富尔玛家居(KA)', '富森美家居(KA)', '国安居家居', '国邦家居(KA)', '国际家居', '国瑞建材家居', '国泰家具', '海田家私', '好百年家居(KA)', '恒志家居', '弘阳家居(KA)', '红树湾家居', '红星美凯龙(KA)', '花花世界家居', '花样年华家居', '华美居', '华耐家居(KA)', '华润小径', '环渤海家居(KA)', '辉煌家居', '吉盛伟邦(KA)', '家具采购中心', '家盛美居', '家装公司', '简爱家居(KA)', '金海马家居', '金盛家居(KA)', '精木国际家居', '居然家居', '居然之家(KA)', '俊雄家居', '凯美家居', '乐康家居', '罗浮宫家居', '马会家居', '美汇名家', '美居慕思', '美泰家居', '名都家具', '名家居', '南城家居', '欧凯龙家具(KA)', '欧上美居', '欧亚达家居(KA)', '欧亚美家居(KA)', '商超', '深业家之福', '世邦家居', '世贸家居', '松宝家居', '岁宝家居', '同福商贸', '同心城装饰', '万户来家居', '万泰汇', '维家思广场', '维亚国际', '沃尔玛', '喜盈门家居(KA)', '香江家居(KA)', '新大新家居', '星河国际家居', '益家园家居(KA)', '银座家居(KA)', '盈锋家居', '源雅针织品', '月星家居(KA)', '越王广场', '粤东国际'],
  dealerType: [{
      label: "原经销商",
      value: 1
    },
    {
      label: "新经销商",
      value: 0
    }
  ],
  type:[
    {label:"是",value:1},
    {label:"否",value:0}
  ],
  agencyShopRunWay:['投资经营','全职经营','其他'],
  agencyShopManageWay:['自己管理','亲属关联','招聘人员管理','其他方式'],
  maritalStatus:[
    {
      label:"未婚",
      value:0,
    },
    {
      label:"已婚",
      value:1,
    }
    // {
    //   label:"离异",
    //   value:2
    // }
  ],
  sex:[
    // {
    //   label:"保密",
    //   value:0,
    // },
    {
      label:"男",
      value:1,
    },
    {
      label:"女",
      value:2,
    }
  ],
  businessLicenseType: ['个体工商户','有限责任公司'],
  payTaxesType:['一般纳税人','小规模纳税人','定额纳税人'],
  billType:['专票','普票'],
  fundTypes:[
    {
      label:"未到账",
      value:0
    },
    {
      label:"到账",
      value:1
    }
  ],
  brandCostType:[
    {
      label:"品牌推广费",
      value:0
    },
    {
      label:"品牌保证金",
      value:1
    },
    // {
    //   label:"儿童(红星)非标展位定金",
    //   value:2
    // },
    {
      label:"意向金",
      value:2
    }
  ],
  removeShopReason:["商场原因","经销商经营原因","换位重装","系统数据修正","换商换位/换系列","店面换系列"],

}

export default {
  namespaced: true,
  state
}
