<template>
  <div class="tablePro" :class="{ 'auto-height': autoHeight }">
    <template v-if="$slots.searchArea">
      <slot
        name="searchArea"
        :columns="searchAndScreenColumns"
        @onSearch="onSearchTableList($event, 1, { actionType: 'search' })"
        @onReset="onSearchTableList($event, 1, { actionType: 'reset' })"
      />
    </template>
    <SearchForm
      v-else
      ref="searchFormRef"
      v-model="searchParams"
      :columns="searchAndScreenColumns"
      :form-props="searchFormProps"
      :search-layout="searchLayout"
      :card-props="cardProps"
      :default-unfold="defaultUnfoldSearchForm"
      @onSearch="onSearchTableList($event, 1, { actionType: 'search' })"
      @onReset="onSearchTableList($event, 1, { actionType: 'reset' })"
      @onInitDefaultParams="onInitDefaultParams"
    />
    <CommonTable
      ref="tableRef"
      class="table-area"
      v-bind="getBindProps"
      v-on="$listeners"
      @selection-change="onSelect"
      @setRequestData="onSetRequestData"
    >
      <template #actionArea="{ total, loading }">
        <!-- 操作区域 -->
        <div class="action-area">
          <slot name="actionArea" :selected-list="selectedList" />
          <ExportButton
            v-if="actions && actions.includes('export')"
            :disabled="loading"
            :data-list="selectedList"
            :exportsDbKey="getTableKey"
            :fetch-export-data="onFetchExportData"
            :export-request-params="innerExportRequestParams(total)"
            :export-columns="exportColumns"
            :export-supported="exportSupported"
            :export-task-type="exportTaskType"
            :export-file-name="exportFileName"
          />
          <el-button v-if="selectedList.length" type="info" @click="onResetSelected">
            重置选中项
          </el-button>
          <slot name="actionAreaAfter" :selected-list="selectedList" />
        </div>
      </template>
      <template v-if="$slots.tableBefore" slot="tableBefore">
        <slot name="tableBefore" />
      </template>
      <template
        v-if="
          !$slots.tableBefore &&
          (actions || []).some((item) => ['export'].includes(item)) &&
          exportSupported.includes('selected')
        "
        slot="tableBefore"
      >
        <el-table-column type="selection" reserve-selection />
      </template>
    </CommonTable>
  </div>
</template>

<script>
import deepEqual from 'fast-deep-equal'
import SearchForm from './SearchForm.vue'
import CommonTable from './Table.vue'
import ExportButton from './ExportButton.vue'
import { formatJson, transformParams } from './fn'
import md5 from 'js-md5'

export * from './fn'
export default {
  name: 'TablePro',
  components: {
    SearchForm,
    CommonTable,
    ExportButton
  },
  props: {
    autoHeight: {
      type: Boolean,
      default: true
    },
    actions: {
      type: [Array, null],
      // 'export'
      validator(value) {
        const matchArr = ['export']
        if (value && value.length) {
          return value.every((item) => matchArr.includes(item))
        }
        return true
      },
      default: () => []
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    columns: {
      type: Array,
      default: null
    },
    searchFormProps: {
      type: Object,
      default: () => ({
        inline: true
      })
    },
    searchLayout: {
      type: String,
      default: 'normal' // 'normal','inline'
    },
    cardProps: {
      type: Object,
      default: () => ({
        shadow: 'never'
      })
    },
    // 额外请求参数，更新时触发刷新,autoRequest最好将其设置为false，否则可能有并发问题
    requestParams: {
      type: Object,
      default: () => ({})
    },
    exportRequestParams: {
      type: Object,
      default: () => ({
        currPage: 1,
        // 分片大小
        sliceSize: 5000,
        // 最大请求数量
        pageSize: 5000 * 5
      })
    },
    // 默认展开搜索表单
    defaultUnfoldSearchForm: {
      type: Boolean,
      default: false
    },
    autoRequest: {
      type: Boolean,
      default: true
    },
    exportSupported: { type: Array, default: () => ['selected', 'all'] },
    exportTaskType: {
      type: String,
      // 'clientTask', 'serverTask'
      default: 'clientTask'
    },
    exportFileName:{
      type: String,
      default: ''
    },
    // 表格数据的key，用来缓存表格数据/包括导出编辑，如果不传，那么使用路由地址作为key。如果路由就不缓存
    tableKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editVisible: false,
      selectedList: [],
      searchParams: {},
      // Table 的数据
      requestData: null
    }
  },
  computed: {
    getTableKey({ tableKey, getBindProps: { columns } }) {
      return tableKey || `${this.$route.fullPath}_${md5(columns)}`
    },
    // 查询表单、编辑表单的权重，权重越小排序靠前
    // 添加默认值
    searchAndScreenColumns({ columns }) {
      if (!columns) return null
      const searchAndScreenColumns = [...columns]
        .filter((item) => (item.search && !item.hideInSearch) || item.screen)
        .sort((a, b) => {
          if (a.order === undefined) return 1
          if (b.order === undefined) return -1
          return b.order - a.order
        })
      return searchAndScreenColumns.map((item) => {
        if (item.valueType === 'select') {
          item.fieldProps = {
            clearable: true,
            ...item.fieldProps
          }
        }
        if (item.dateProps || item.fieldProps) {
          if (/range$/.test(item.fieldProps?.type)) {
            if (!item.fieldProps.valueFormat) {
              item.fieldProps.valueFormat = 'yyyy-MM-dd HH:mm:ss'
            }
            if (!item.fieldProps.defaultTime) {
              item.fieldProps.defaultTime = ['00:00:00', '23:59:59']
            }
          }
          if (/range$/.test(item.dateProps?.type)) {
            if (!item.dateProps.valueFormat) {
              item.dateProps.valueFormat = 'yyyy-MM-dd HH:mm:ss'
            }
            if (!item.dateProps.defaultTime) {
              item.dateProps.defaultTime = ['00:00:00', '23:59:59']
            }
          }
        }
        return item
      })
    },
    exportColumns({ columns }) {
      if (this.requestData?.[this.$attrs.responseConfig?.excelTitle || 'excelTitle']) {
        return this.requestData[this.$attrs.responseConfig?.excelTitle || 'excelTitle']
      }
      if (!columns) return null
      function getColumns(columnList, parentTitle) {
        let columns = []
        columnList.map((item) => {
          const title = parentTitle ? `${parentTitle}/${item.title}` : `${item.title}`
          if (!item.hideInExport) {
            if (item.name || item.renderText) {
              columns.push({
                ...item,
                title
              })
            }
            if (item.tableColumnList) {
              const childrenColumns = getColumns(item.tableColumnList, title)
              columns = [...columns, ...childrenColumns]
            }
          }
        })
        return columns
      }
      const exportColumns = getColumns(columns)
      return exportColumns
    },
    getBindProps({ columns, cardProps, rowKey, autoHeight, $attrs }) {
      return {
        columns,
        cardProps: cardProps,
        rowKey: rowKey,
        autoHeight: autoHeight,
        ...$attrs
      }
    },
    // 表单搜索参数，经过转换
    searchRequestParams({ searchParams, searchAndScreenColumns }) {
      return transformParams(searchParams, searchAndScreenColumns)
    }
  },
  created() {
    if (this.autoRequest) {
      if (this.$slots.searchArea) {
        this.onRefreshTableList()
      }
    }
  },
  watch: {
    requestParams: {
      handler(params, oldParams) {
        if (!deepEqual(params, oldParams)) {
          this.onSearchTableList({ actionType: 'requestParams' })
        }
      },
      deep: true
    }
  },
  methods: {
    onSetRequestData(data) {
      this.requestData = data
    },
    innerExportRequestParams(total) {
      return {
        ...this.exportRequestParams,
        pageSize:
          total > this.exportRequestParams.pageSize ? this.exportRequestParams.pageSize : total
      }
    },
    async onFetchExportData(type, exportRequestParams, callback) {
      // 导出选择数据
      if (type === 'selected') return this.selectedList

      const { sliceSize, ...pureExportRequestParams } = exportRequestParams
      // 服务器队列导出
      if (type === 'serverTask') {
        return await this.$attrs.request(
          { ...pureExportRequestParams, ...this.searchRequestParams },
          'serverTaskExport'
        )
      }

      // 调用接口导出当前搜索条件下的所有数据
      let exportData = []
      // 分片逻辑
      if (exportRequestParams.pageSize > sliceSize) {
        // 分片数量
        const sliceCount = Math.ceil(exportRequestParams.pageSize / sliceSize)
        // 分片余数
        const remainderCount = exportRequestParams.pageSize % sliceSize
        for (let i = 0; i < sliceCount; i++) {
          const pageSize = sliceCount === i + 1 ? remainderCount : sliceSize
          callback(Math.floor((i / sliceCount) * 100))
          const exportDataPiece = await this.$attrs
            .request(
              {
                ...pureExportRequestParams,
                ...this.requestParams,
                ...this.searchRequestParams,
                currPage: i + exportRequestParams.currPage,
                pageSize: sliceSize
              },
              'export'
            )
            .then((res) => {
              // 如果分片余数=0，那么最后一次请求的分片大小就是分片大小
              return res[this.$attrs.responseConfig?.list || 'data'].slice(0, pageSize || sliceSize)
            })
          exportData.push(...exportDataPiece)
        }
      } else {
        // 分片大余总数，直接请求
        let i = 0
        const timer = setInterval(() => {
          // 获取不到进度，给点随机进度
          if (i > 96) {
            return clearInterval(timer)
          }
          callback((i += 1))
        }, 100)
        clearInterval(timer)
        const res = await this.$attrs.request(
          { ...pureExportRequestParams, ...this.searchRequestParams },
          'export'
        )
        exportData = res[this.$attrs.responseConfig?.list || 'data']
      }
      return exportData
    },
    onSearchTableList(params, currPage = 1, { actionType } = {}) {
      console.log(params)
      this.$refs.tableRef.onSearchTableList(
        { ...this.searchRequestParams, ...this.requestParams, ...params },
        currPage,
        { actionType }
      )
    },
    onInitDefaultParams(params, currPage = 1) {
      if (this.autoRequest) {
        this.onSearchTableList(params, currPage, { actionType: 'init' })
      }
    },
    onInitSearchParams(params) {
      this.$refs.searchFormRef.onInit(params)
    },
    onRefreshTableList(currPage) {
      if (currPage) {
        return this.$refs.tableRef.onCurrentChange(currPage, {
          actionType: 'refresh'
        })
      } else {
        return this.$refs.tableRef.setTableList({
          actionType: 'refresh'
        })
      }
    },
    onSelect(selectedList) {
      this.selectedList = selectedList
      this.$emit('selectionChange', selectedList)
    },
    async onExportExcel() {
      let exportData = this.selectedList
      if (!exportData.length) {
        const data = await this.$refs.tableRef.loadTableList(this.exportRequestParams)
        exportData = data[this.$refs.tableRef.responseConfig.list]
      }
      // if (this.onExport) {
      //   return this.onExport(exportData)
      // }
      const { export_json_to_excel } = require('@/vendor/Export2Excel')
      export_json_to_excel({
        header: this.exportColumns.map((item) => item.title),
        data: await formatJson(this.exportColumns, exportData),
        filename: this.$route.meta?.title || '导出数据'
      })
      return true
    },
    onResetSelected() {
      this.$refs.tableRef.$refs.tableRef.clearSelection()
    },
    onResetTableList() {
      this.$refs.tableRef.onClearTableList()
      this.$refs.tableRef.$refs.tableRef.clearSelection()
    },
    // 单选执行清除并选中
    onSelectedRow(...rest) {
      this.$refs.tableRef.$refs.tableRef.toggleRowSelection(...rest)
    }
  }
}
</script>

<style lang="scss">
.tablePro {
  &.auto-height {
    height: calc(100vh - 124px);
    min-height: 540px;
    display: flex;
    flex-direction: column;
  }
  /* .el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
  } */
  .action-area {
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
    &:empty {
      display: none;
    }
    > * {
      margin-left: 15px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
}
</style>
