var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"ruleForm",staticClass:"flow-form",attrs:{"model":_vm.formData,"label-width":"180px"}},[_c('el-form-item',{attrs:{"label":"流程类型：","prop":"processCode","rules":{
        required: true,
        message: '请选择流程类型',
        trigger: 'change'
      }}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.processCode),callback:function ($$v) {_vm.$set(_vm.formData, "processCode", $$v)},expression:"formData.processCode"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"提单人：","prop":"name"}},[_c('span',[_vm._v(_vm._s(_vm.userInfo.realName))])]),_c('el-form-item',{attrs:{"label":"系统优化建议（描述）：","prop":"describes","rules":{
        required: true,
        message: '系统优化建议（描述）不能为空',
        trigger: 'blur'
      }}},[_c('el-input',{attrs:{"type":"textarea","resize":"none","rows":"8"},model:{value:(_vm.formData.describes),callback:function ($$v) {_vm.$set(_vm.formData, "describes", $$v)},expression:"formData.describes"}})],1),_c('FileUplodItem',{staticClass:"formItem",attrs:{"prop":"fileRecordList","label":"附件："},on:{"onUploaded":file => _vm.handleUpload(file)},model:{value:(_vm.formData.fileRecordList),callback:function ($$v) {_vm.$set(_vm.formData, "fileRecordList", $$v)},expression:"formData.fileRecordList"}},[_c('div',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("单文件不能超过400M")])]),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }