const getName = (name, type = '') => {
  if (type) {
    return `${type}${name.replace(/^./, name[0].toLocaleUpperCase())}`
  } else {
    return name
  }
}

export default (type = '') => [
  {
    path: 'flowApply',
    name: getName('flowApply', type),
    component: () => import('@/views/flow/flowApply'),
    meta: { title: '流程申请' }
  },
  {
    path: 'myApply',
    name: getName('myApply', type),
    component: () => import('@/views/flow/myApply'),
    meta: { title: '我的申请' }
  },
  {
    path: 'draft',
    name: getName('draft', type),
    component: () => import('@/views/flow/draft'),
    meta: { title: '草稿箱' }
  },
  {
    path: 'areaFlow',
    name: getName('areaFlow', type),
    component: () => import('@/views/flow/area_flow'),
    meta: { title: '区域加盟申请表', code: 'JXS001' },
    hidden: true
  },
  {
    path: 'dealerStoreRenovation',
    name: getName('dealerStoreRenovation', type),
    component: () => import('@/views/flow/dealerStoreRenovation'),
    meta: { title: '经销商店面重装申请表', code: 'JXS006' },
    hidden: true
  },
  {
    path: 'newShopFlow',
    name: getName('newShopFlow', type),
    component: () => import('@/views/flow/newShopFlow'),
    meta: { title: '增加新店申请表', code: 'JXS004' },
    hidden: true
  },
  {
    path: 'newSeriesFlow',
    name: getName('newSeriesFlow', type),
    component: () => import('@/views/flow/newSeriesFlow'),
    meta: { title: '老经销商增加新系列申请表', code: 'JXS003' },
    hidden: true
  },
  {
    path: 'shopSeriesManagement',
    name: getName('shopSeriesManagement', type),
    component: () => import('@/views/flow/shop_series_management'),
    meta: { title: '门店系列管理申请表', code: 'JXS005' },
    hidden: true
  },
  {
    path: 'dealershipTransfer',
    name: getName('dealershipTransfer', type),
    component: () => import('@/views/flow/dealershipTransfer'),
    meta: { title: '经销权转让申请表', code: 'JXS010' },
    hidden: true
  },
  {
    path: 'customerMainChange',
    name: getName('CustomerMainChange', type),
    component: () => import('@/views/flow/customerMainChange'),
    meta: { title: '客户主数据修改申请表', code: 'JXS009' },
    hidden: true
  },
  {
    path: 'shopDeviseFlow',
    name: getName('ShopDeviseFlow', type),
    component: () => import('@/views/flow/shopDeviseFlow'),
    meta: { title: '专卖店设计及装修流程', code: 'JXS008' },
    hidden: true
  },
  {
    path: 'channelFlow',
    name: getName('ChannelFlow', type),
    component: () => import('@/views/flow/channelFlow'),
    meta: { title: '渠道合作申请表', code: 'JXS002' },
    hidden: true
  },
  {
    path: 'dealerRemoveStore',
    name: getName('dealerRemoveStore', type),
    component: () => import('@/views/flow/dealerRemoveStore'),
    meta: { title: '撤店申请流程', code: 'JXS007' },
    hidden: true
  },
  {
    path: 'dealershipCancel',
    name: getName('dealershipCancel', type),
    component: () => import('@/views/flow/dealershipCancel'),
    meta: { title: '经销权取消申请流程', code: 'JXS013' },
    hidden: true
  },
  {
    path: 'subsidyVerification',
    name: getName('subsidyVerification', type),
    component: () => import('@/views/flow/subsidyVerification'),
    meta: { title: '经销商考核补贴流程', code: 'JXS014' },
    hidden: true
  },
  {
    path: 'dealerInterview',
    name: getName('dealerInterview', type),
    component: () => import('@/views/flow/dealerInterview'),
    meta: { title: '经销商意向加盟申请表', code: 'JXS019' },
    hidden: true
  },
  {
    path: 'newChannelDealerPartnerReport',
    name: getName('newChannelDealerPartnerReport', type),
    component: () => import('@/views/flow/newChannelDealerPartnerReport.vue'),
    meta: {
      title: '新渠道经销商合作方信息报备流程',
      code: 'JXS018',
      hidden: true
    }
  },
  {
    path: 'storeManagementFlow',
    name: getName('storeManagementFlow', type),
    component: () => import('@/views/flow/storeManagementFlow'),
    meta: {
      title: '慕思经典经销商门店管理申请表',
      code: 'JXS020',
      hidden: true
    }
  },
  {
    path: 'classicalAreaFlow',
    name: getName('classicalAreaFlow', type),
    component: () => import('@/views/flow/classicalAreaFlow'),
    meta: { title: '慕思经典经销商加盟流程申请表', code: 'JXS021' },
    hidden: true
  },
  {
    path: 'overseasChannelFlow',
    name: getName('overseasChannelFlow', type),
    component: () => import('@/views/flow/overseasChannelFlow'),
    meta: { title: '海外渠道合作申请表', code: 'JXS023' },
    hidden: true
  },
  {
    path: 'classicDealershipTransfer',
    name: getName('classicDealershipTransfer', type),
    component: () => import('@/views/flow/classicDealershipTransfer.vue'),
    meta: { title: '慕思经典经销权转让申请表', code: 'JXS022' },
    hidden: true
  },
  {
    path: 'entrance',
    name: getName('entrance', type),
    component: () => import('@/views/bpmFlow/entrance.vue'),
    meta: { title: '流程入口' },
    hidden: false
  },
  {
    path: 'testFlow',
    name: getName('testFlow', type),
    component: () => import('@/views/bpmFlow/testFlow.vue'),
    meta: { title: '测试流程' },
    hidden: false
  },
  {
    path: 'bpmFlowClassicalAreaFlow',
    name: getName('bpmFlowClassicalAreaFlow', type),
    component: () => import('@/views/bpmFlow/classicalAreaFlow.vue'),
    meta: {
      title: '慕思经典经销商加盟流程申请表',
      code: 'JXS021',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowStoreManagementFlow',
    name: getName('bpmFlowStoreManagementFlow', type),
    component: () => import('@/views/bpmFlow/storeManagementFlow.vue'),
    meta: {
      title: '慕思经典经销商门店管理申请表',
      code: 'JXS020',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmflowShopDeviseFlow',
    name: getName('bpmflowShopDeviseFlow', type),
    component: () => import('@/views/bpmFlow/shopDeviseFlow.vue'),
    meta: {
      title: '专卖店设计及装修流程',
      code: 'JXS008',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowDealer4SCertification',
    name: getName('bpmFlowDealer4SCertification', type),
    component: () => import('@/views/bpmFlow/dealer4SCertification.vue'),
    meta: {
      title: '经销商4S星级认证流程',
      code: 'JXS025',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'overseasSinomiChannelFlow',
    name: getName('overseasSinomiChannelFlow', type),
    component: () => import('@/views/flow/overseasSinomiChannelFlow'),
    meta: { title: '希诺米海外渠道合作申请表', code: 'JXS024' },
    hidden: true
  },
  {
    path: 'bpmFlowNewChannelDealerNetworkReport',
    name: getName('bpmFlowNewChannelDealerNetworkReport', type),
    component: () => import('@/views/bpmFlow/newChannelDealerNetworkReport'),
    meta: {
      title: '新渠道经销商合作网点报备流程',
      code: 'JXS026',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowDealerGiveUpDealership',
    name: getName('bpmFlowDealerGiveUpDealership', type),
    component: () => import('@/views/bpmFlow/dealerGiveUpDealership'),
    meta: {
      title: '慕思经典-经销商主动放弃经销权申请流程',
      code: 'JXS027',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowStoreRectificationAppeal',
    name: getName('bpmFlowStoreRectificationAppeal', type),
    component: () => import('@/views/bpmFlow/storeRectificationAppeal'),
    meta: {
      title: '终端门店整改申诉流程',
      code: 'JXS028',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'terminalStoresFlow',
    name: getName('terminalStoresFlow', type),
    component: () => import('@/views/bpmFlow/terminalStoresFlow.vue'),
    meta: {
      title: '终端门店巡检整改流程',
      code: 'JXS029',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowClassicalStoreDesignFlow',
    name: getName('bpmFlowClassicalStoreDesignFlow', type),
    component: () => import('@/views/bpmFlow/classicalStoreDesignFlow.vue'),
    meta: {
      title: '慕思经典-门店设计流程',
      code: 'JXS030',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowClassicalStoreRectifyFlow',
    name: getName('bpmFlowClassicalStoreRectifyFlow', type),
    component: () => import('@/views/bpmFlow/classicalStoreRectifyFlow.vue'),
    meta: {
      title: '慕思经典-门店整改流程',
      code: 'JXS033',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowClassicalStoreStatusChangeFlow',
    name: getName('bpmFlowClassicalStoreStatusChangeFlow', type),
    component: () =>
      import('@/views/bpmFlow/classicalStoreStatusChangeFlow.vue'),
    meta: {
      title: '慕思经典-门店状态调整流程',
      code: 'JXS034',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowClassicalStoreMaterialOrderFlow',
    name: getName('bpmFlowClassicalStoreMaterialOrderFlow', type),
    component: () =>
      import('@/views/bpmFlow/classicalStoreMaterialOrderFlow.vue'),
    meta: {
      title: '慕思经典-店装物料下单流程',
      code: 'JXS032',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowClassicalStoreDecorationFlow',
    name: getName('bpmFlowClassicalStoreDecorationFlow', type),
    component: () => import('@/views/bpmFlow/classicalStoreDecorationFlow.vue'),
    meta: {
      title: '慕思经典-门店装修流程',
      code: 'JXS031',
      declarationType: 'BPM'
    },
    hidden: true
  },
  {
    path: 'bpmFlowOverseasDealersJoin',
    name: getName('bpmFlowOverseasDealersJoin', type),
    component: () => import('@/views/bpmFlow/overseasDealersJoin.vue'),
    meta: {
      title: '海外经销商加盟流程',
      code: 'JXS035',
      declarationType: 'BPM'
    },
    hidden: true
  }
]
