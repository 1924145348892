/*
 * @Author: wangmq
 * @Date: 2023-11-17 14:35:57
 * @LastEditors: wangmq
 * @LastEditTime: 2023-11-28 16:23:39
 */
import http from '@/utils/request'

// 保存草稿
export function saveDraft(data) {
  return http({
    url: '/dealermanage/shopManageFlow/saveDraft',
    method: 'post',
    data
  })
}

// 提交流程
export function submitFlow(data) {
  return http({
    url: '/dealermanage/shopManageFlow/submit',
    method: 'post',
    data
  })
}

// 节点审批提交
export function submitNode(data) {
  return http({
    url: '/dealermanage/shopManageFlow/submitApproveData',
    method: 'post',
    data
  })
}

// 获取流程详情
export function requestGetFlowDetail(id) {
  return http({
    url: '/dealermanage/shopManageFlow/details',
    params: {
      formId: id
    }
  })
}