<template>
  <div class="watch">
    <el-form :inline="true">
      <el-form-item>
        <el-input
          prefix-icon="el-icon-search"
          v-model="search.ruleName"
          placeholder="请输入规则名称"
          clearable
        >
          <el-select
            slot="prepend"
            v-model="search.checkType"
            placeholder="请选择业务对象"
            style="width: 160px"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="门店" value="0"> </el-option>
            <el-option label="经销商" value="1"> </el-option>
          </el-select>
          <el-button slot="append" type="primary" @click="handleFind"
            >查询</el-button
          >
        </el-input>
      </el-form-item>
      <el-form-item> </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click=";(taskItem = {}), (taskVisibleDialog = 1)"
          >新增</el-button
        >
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      ref="filterTable"
      v-loading="loading"
      element-loading-text="Loading"
      border
      :data="dataList"
      style="width: 100%"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        v-for="(item, index) in itemKey"
        :key="index"
        :prop="item"
        :label="listItem[item]"
        align="center"
      />
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 10px"
            @click=";(taskItem = scope.row), (taskVisibleDialog = 2)"
            >修改</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="hanldeDelete(scope.row.id)"
          >
            <el-button slot="reference" type="danger" size="mini"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <br />
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes,jumper"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      />
    </div>

    <taskDrawer
      :taskVisibleDialog="taskVisibleDialog"
      :taskItem="taskItem"
      :cancel="() => (taskVisibleDialog = 0)"
      @onUpdate="getDataList(search)"
    />
  </div>
</template>

<script>
import {
  getDealerSqlTaskInfoList,
  exportDealerSqlTaskResult,
  dealerSqlTaskInfoDelete
} from '@/api/watchData/'
import taskDrawer from './components/taskDrawer'
import { Base64 } from 'js-base64'

const listItem = {
  businessObject: '业务对象',
  dataStandardName: '数据标准名称',
  businessRule: '业务规则',
  ruleName: '规则名称',
  ruleDescription: '规则描述',
  ruleDimension: '规则维度',
  checkSql: '校验SQL',
  updateName: '更新人'
}

export default {
  components: {
    taskDrawer
  },
  data () {
    return {
      dataList: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      listItem: listItem,
      itemKey: Object.keys(listItem),
      search: {},
      taskVisibleDialog: 0,
      taskItem: {}
    }
  },
  created () {
    this.getDataList(this.search)
  },
  methods: {
    hanldeDelete (id) {
      dealerSqlTaskInfoDelete({ id }).then(res => {
        this.$message.success('删除成功')
        this.getDataList(this.search)
      })
    },
    handleExport () {
      const { ruleName, checkType } = this.search
      window.open(
        `${exportDealerSqlTaskResult}?ruleName=${ruleName}&checkType=${checkType}`,
        '_blank'
      )
    },
    handleFind () {
      this.currentPage = 1
      this.getDataList(this.search)
    },
    async getDataList (search = {}) {
      this.loading = true
      const { status, page } = await getDealerSqlTaskInfoList({
        ...search,
        page: this.currentPage,
        limit: this.pageSize,
        ...this.form
      })
      this.loading = false
      if (status) {
        // page.list.map(item => ({
        //   ...item,
        //   checkSql: Base64.decode(item.checkSql)
        // }))
        for (const item of page.list) {
          try {
            item.checkSql = Base64.decode(item.checkSql)
          } catch (err) {
            console.log(err)
          }
        }
        this.dataList = page.list
        this.total = page.totalCount
      }
    },
    // 页面切换
    currentChange (page) {
      this.currentPage = page
      this.getDataList(this.search)
    },
    // 页数切换
    handleSizeChange (page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList(this.search)
    }
  }
}
</script>

<style>
.paginationBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  overflow-x: auto;
}
</style>