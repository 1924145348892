import http from '@/utils/request'
export const getDataHistoryDetailByTable = params => {
  return http({
    url:'/dealermanage/datahistorydetail/getDataHistoryDetailByTable',
    params
  })
}
export const getColumnByTable = params => {
  return http({
    url:'/dealermanage/datahistorydetail/getColumnByTable',
    params
  })
}