<template>
  <div class="menu">
    <el-card
      shadow="always"
      class="card-menu"
      :body-style="{ padding: '20px' }">
      <template #header>
        <div class="card-header">
          <span>菜单</span>
          <div>
            <el-button
              class="button"
              icon="el-icon-download"
              type="text"
              @click="() => useExportJson(state.uMenuList, 'menu.json')"
              >导出</el-button
            >
            <el-button
              class="button"
              icon="el-icon-plus"
              type="text"
              @click="handleAddItem"
              v-if="showSyncBtn"
              >添加</el-button
            >
            <el-button
              class="button"
              icon="el-icon-sort"
              type="text"
              @click="handleSyncMenu"
              v-if="showSyncBtn"
              >同步菜单</el-button
            >
          </div>
        </div>
      </template>

      <el-scrollbar>
        <div class="menu-nav">
          <el-menu
            :unique-opened="true"
            @select="handleMenuSelect"
            @open="hanleMenuOpen">
            <NavMenu :menuList="state.menuList" />
          </el-menu>
        </div>
      </el-scrollbar>
    </el-card>

    <el-card
      shadow="always"
      class="card-config"
      :body-style="{ padding: '20px' }">
      <template #header>
        <div class="card-header">
          <span>配置</span>
          <div>
            <el-button
              icon="el-icon-edit"
              type="text"
              @click="state.editStatus = true"
              >编辑</el-button
            >
            <el-popconfirm title="确定要删除吗？" @confirm="handleDeleteItem">
              <template #reference>
                <el-button
                  icon="el-icon-delete"
                  type="text"
                  @click="handleDeleteItems"
                  >删除</el-button
                >
              </template>
            </el-popconfirm>
          </div>
        </div>
      </template>
      <ConfigMenu
        style="width: 80%"
        labelWidth="30%"
        :status="1"
        :selectItem="state.selectItem"
        :menuList="state.uMenuList"
        @onSaveMenu="getMenuList(), (state.editStatus = false)"
        :editStatus="state.editStatus"
        @onCancel="state.editStatus = false" />
    </el-card>

    <el-drawer
      title="添加菜单"
      :visible.sync="state.drawer"
      direction="rtl"
      size="size:30%;min-width:400px;"
      :before-close="drawerClose">
      <ConfigMenu
        labelWidth="80px"
        :status="0"
        :selectItem="state.drawerItem"
        :menuList="tempMenuList"
        :editStatus="true"
        @onCancel="state.drawer = false"
        @onSaveMenu="getMenuList(), (state.drawer = false)" />
    </el-drawer>
  </div>
</template>

<script setup>
import { reactive, computed, ref, nextTick } from 'vue'
import NavMenu from './components/NavMenu.vue'
import ConfigMenu from './components/ConfigMenu.vue'
import { menuList, menuDelete, menuBatchSync } from '@/api/system/menu'
import { Message, MessageBox } from 'element-ui'
import useExportJson from '@/utils/useExportJson'

const state = reactive({
  menuListTree: [],
  uMenuList: [],
  drawer: false,
  selectItem: {},
  drawerItem: {},
  editStatus: false,
  showAddBtn: true
})

const tempMenuList = ref([])

// state.showAddBtn=import.meta.env.MODE=='development'

const showSyncBtn = computed(() => {
  return process.env.VUE_APP_BASE_API == 'https://cloud.derucci.com:7443'
})

const hanleMenuOpen = id => {
  state.selectItem = state.uMenuList.find(item => item.id == id)
}

const drawerClose = () => {
  state.drawer = false
  state.drawerItem = {}
}

const getMenuList = () => {
  menuList({ system_id: process.env.VUE_APP_SYSTEM_CODE }).then(({ data }) => {
    state.menuList = toTree(data || [])
    state.uMenuList = data
  })
}
getMenuList()

//选中的菜单数据
const handleMenuSelect = id => {
  state.selectItem = state.uMenuList.find(item => item.id == id)
}

const handleAddItem = () => {
  state.drawer = true
  nextTick(() => (tempMenuList.value = Object.assign([], state.uMenuList)))
}

function handleDeleteItems() {
  Message.error('请先选中要删除的菜单')
}
//删除菜单
async function handleDeleteItem() {
  console.log(state.selectItem)
  if (state.selectItem.id) {
    await menuDelete([state.selectItem.id])
    state.selectItem = {}
    getMenuList()
    Message.success('删除成功')
  }
}

const handleSyncMenu = () => {
  MessageBox('确定要将全部菜单同步到生产环境吗?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      menuBatchSync([process.env.VUE_APP_SYSTEM_CODE]).then(res => {
        res.code === 0 && Message.success('同步成功！')
      })
    })
    .catch(() => {})
}

const toTree = (data = [], pid = '0') => {
  const tree = id =>
    data
      .filter(item => item.umParentId === id)
      .map(item => ({ ...item, children: tree(item.id) }))
  return tree(pid)
}
</script>

<style lang="scss" scoped>
.el-menu {
  border: none;
}

.menu {
  min-height: 85vh;
  display: flex;

  &-nav {
    height: 70vh;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-menu {
  width: 400px;
  margin-right: 30px;
  min-width: 300px;
}

.card-config {
  flex: 1;
  min-width: 300px;
}

@media screen and (max-width: 760px) {
  .card-menu {
    width: 200px;
  }

  .card-config {
    min-width: 600px;
  }
}
</style>
