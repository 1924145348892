var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.FlowForm,{ref:"flowFormRef",attrs:{"fd-form-template-code":"distributor-JXS020","is-edit":[0, 20].includes(Number(_setup.formData.status)),"model":_setup.formData,"on-submit":_setup.handleSave,"approval-data":_setup.approvalData,"getDetail":() => true,"title":"慕思经典经销商门店管理申请表","tips":[
    '1.流程适用范围：慕思经典经销商；',
    '2.流程适用场景：经销商全新开店（包括空白加盟、换址新开、新开店面），整店重装和扩店装修；',
    '3.一个流程仅允许提交一个门店信息，多个门店需要提交多个流程。'
  ],"onApproval":_setup.onApproval,"approvalFlowParams":{
    brandsByUser01Params: {
      licenseId: _setup.formData.businessLicenseId,
      authorizedCityCode: _setup.formData.authorizedCityCode
    },
    shopPositionLevelParams: {
      parentCode: _setup.formData.shop.shopType === 'SJ009' ? 'SJ009' : '0',
      showShopPositionLevel:
        _setup.formData.shop.shopType === 'SJ009' ||
        ['重点卖场', '一般卖场', '强势卖场', '非集中卖场'].includes(
          _setup.formData.shop.marketType
        )
    },
    strongEntryTypePrams: {
      marketType: _setup.formData.shop.marketType
    },
    shareNewGJSubsidyParams: {
      brandsIds: _setup.formData.brands,
      areaCount: ['局部重装', '原店扩大'].includes(_setup.formData.storeOpeningMode)
        ? Number(_setup.formData.shop.shopAddArea)
        : Number(_setup.formData.shop.shopArea)
    }
  }}},[_c('div',[_c(_setup.BaseMessage,{attrs:{"title":_setup.flowTitle,"number":_setup.formData.orderNumber,"zsOrderNumber":_setup.formData.zsOrderNumber}}),_c('el-form',{ref:"ruleForm",staticClass:"flow-form",attrs:{"model":_setup.formData,"inline":true,"label-position":"top"}},[_c('br'),_c(_setup.RequiredFormItemSelect,{staticClass:"formItem requiredFormItem",attrs:{"prop":"storeOpeningType","label":"开店类型","labelKey":"label","valueKey":"value","dataList":Object.keys(_setup.shopModelMap).map(item => ({
            value: item,
            label: item
          })),"selectListeners":{
          change: data => {
            _setup.formData.storeOpeningMode = ''
            _setup.formData.marketShopCount = ''
            _setup.formData.shop = {
              decorationArea: '', // 门店装修面积
              ifParentShopNumber: '',
              parentShopNumber: '',
              // oldShopNumber: '',
              marketShopCount: '',
              id: '',
              storeNature: '',
              shopNumber: '',
              independentDoorHeadFlag: '',
              province: _setup.authorizedCityMsg?.province || '',
              city: _setup.authorizedCityMsg?.city || '',
              area: '',
              street: '',
              shopType: '',
              shopTypeValue: '',
              shopFloor: '',
              shopFloorNumber: '',
              shopPositionLevel: '',
              marketName: '',
              marketType: '',
              regionAreaCode: '',
              longitude: '',
              latitude: '',
              managementModel: '',
              contactPerson: '',
              contactPersonIdcard: '',
              contactPersonPhone: '',
              decorationTime: '',
              renovationLiablePerson: '',
              renovationPersonPhone: '',
              shopAlias: '',
              shopRent: '',
              shopArea: '',
              dianjiangDesigner: '',
              dianjiangDesignerName: '',
              dianjiangDesignerPhone: '',
              ifDianjiangDesigner: '',
              shopBrandContentList: [],
              shopAddArea: '',
              oldShopArea: '',
              ifRenovation: 1,
              dianjiangDesignerPrice: '',
              dianjiangDesignerFactor: '',
              marketLevelName: _setup.authorizedCityMsg.marketLevelName,
              marketLevelCode: _setup.authorizedCityMsg.marketLevelCode,
              doorHeaderBeforeArea: '', //调整前门头面积
              doorHeaderRenovationArea: '', //门头装修面积
              doorHeaderArea: '' //门头实用面积(调整后门头面积)
            }
          }
        }},model:{value:(_setup.formData.storeOpeningType),callback:function ($$v) {_vm.$set(_setup.formData, "storeOpeningType", $$v)},expression:"formData.storeOpeningType"}}),_c(_setup.RequiredFormItemSelect,{staticClass:"formItem requiredFormItem",attrs:{"prop":"storeOpeningMode","label":"开店模式","labelKey":"label","valueKey":"value","dataList":_setup.shopModelMap[_setup.formData.storeOpeningType] || []},on:{"onChangeItem":e => {
            _setup.formData.marketShopCount = ''
            _setup.formData.shop = {
              decorationArea: '', // 门店装修面积
              ifParentShopNumber: '',
              parentShopNumber: '',
              // oldShopNumber: '',
              marketShopCount: '',
              id: '',
              storeNature: '',
              shopNumber: '',
              independentDoorHeadFlag: '',
              province: _setup.authorizedCityMsg?.province || '',
              city: _setup.authorizedCityMsg?.city || '',
              area: '',
              street: '',
              shopType: '',
              shopTypeValue: '',
              shopFloor: '',
              shopFloorNumber: '',
              shopPositionLevel: '',
              marketName: '',
              marketType: '',
              regionAreaCode: '',
              longitude: '',
              latitude: '',
              managementModel: '',
              contactPerson: '',
              contactPersonIdcard: '',
              contactPersonPhone: '',
              decorationTime: '',
              renovationLiablePerson: '',
              renovationPersonPhone: '',
              shopAlias: '',
              shopRent: '',
              shopArea: '',
              dianjiangDesigner: '',
              dianjiangDesignerName: '',
              dianjiangDesignerPhone: '',
              ifDianjiangDesigner: '',
              shopBrandContentList: [],
              shopAddArea: '',
              oldShopArea: '',
              ifRenovation: 1,
              dianjiangDesignerPrice: '',
              dianjiangDesignerFactor: '',
              marketLevelName: _setup.authorizedCityMsg.marketLevelName,
              marketLevelCode: _setup.authorizedCityMsg.marketLevelCode,
              doorHeaderBeforeArea: '', //调整前门头面积
              doorHeaderRenovationArea: '', //门头装修面积
              doorHeaderArea: '' //门头实用面积(调整后门头面积)
            }
          }},model:{value:(_setup.formData.storeOpeningMode),callback:function ($$v) {_vm.$set(_setup.formData, "storeOpeningMode", $$v)},expression:"formData.storeOpeningMode"}}),_c('br'),_vm._l((_setup.formColumns.flowBasicColumns),function(item){return _c(_setup.RequiredFormItemInput,{key:item.name,staticClass:"formItem",attrs:{"required":item.required,"value":_setup.formData[item.name] ?? '',"label":item.label,"readonly":item.readonly,"placeholder":item.placeholder}})}),_c(_setup.BusinessItemSelect,{attrs:{"prop":"businessLicenseId","selectListeners":{
          change: data => {
            _setup.formData.authorizedCityCode = ''
            _setup.formData.brands = []
            _setup.authorizedCityMsg = {}
          }
        }},on:{"onChange":_setup.handleChangeBusiness},model:{value:(_setup.formData.businessLicenseId),callback:function ($$v) {_vm.$set(_setup.formData, "businessLicenseId", $$v)},expression:"formData.businessLicenseId"}}),_vm._l((_setup.formColumns.businessLicenseColumns),function(item){return _c(_setup.RequiredFormItemInput,{key:item.name,staticClass:"formItem",attrs:{"required":item.required,"value":_setup.businessMsg[item.name] ?? '',"label":item.label,"readonly":item.readonly,"placeholder":item.placeholder}})}),_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"disabled":!_setup.formData.businessLicenseId,"prop":"authorizedCityCode","label":"授权城市确认","labelKey":item => `${item.city}${item.area || ''}${item.town || ''}`,"value-key":"authorizedCityCode","get-data-list":_setup.getAuthorizedCityByBusinessLicense,"get-data-list-params":_setup.formData.businessLicenseId,"selectListeners":{
          change: () => {
            _setup.formData.brands = []
          }
        }},on:{"changeSelectedItem":value => {
            _setup.authorizedCityMsg = value ? value : {}
            _setup.formData.shop.city = value ? value.city : ''
            _setup.formData.shop.province = value ? value.province : ''
          }},model:{value:(_setup.formData.authorizedCityCode),callback:function ($$v) {_vm.$set(_setup.formData, "authorizedCityCode", $$v)},expression:"formData.authorizedCityCode"}}),_vm._l((_setup.formColumns.authorizedCityColumns),function(item){return _c(_setup.RequiredFormItemInput,{key:item.name,staticClass:"formItem",attrs:{"required":item.required,"value":_setup.authorizedCityMsg[item.name] ?? '',"label":item.label,"readonly":item.readonly,"placeholder":item.placeholder}})}),_c('el-form-item',{staticClass:"formItem",attrs:{"title":_setup.saveBrandList,"label":"已代理品牌"}},[_c('div',{staticStyle:{"min-height":"33px"}},[_vm._v(_vm._s(_setup.saveBrandList))])]),_c(_setup.BrandSelect,{ref:"brandSelect",staticClass:"requiredFormItem",attrs:{"label":"新店品牌确认","showAll":"","authorizedCityCode":_setup.formData.authorizedCityCode,"businessLicenseId":_setup.formData.businessLicenseId,"status":_setup.flowStatus,"prop":"brands","onlyClassic":true},on:{"onChangeSelectedItem":data => {
            _setup.newShopBrandGet(data)
          },"onChangeItem":_setup.handleChangeShopBrand},model:{value:(_setup.formData.brands),callback:function ($$v) {_vm.$set(_setup.formData, "brands", $$v)},expression:"formData.brands"}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"required":false,"value":_setup.formData.bigRegionName,"label":"营销部","readonly":true,"placeholder":"根据新店品牌自动带出"}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"required":false,"value":_setup.formData.marketCenterName,"label":"营销中心","readonly":true,"placeholder":"根据新店品牌自动带出"}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"required":false,"value":_setup.formData.shopBrandNumber,"label":"送达方编号","readonly":true}}),(
          ['', 0, 20, undefined].includes(_setup.formData.status) &&
          _setup.formData.storeOpeningMode === '换址新开'
        )?_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"prop":"linkOrderNumber","label":"关联撤店单号（若换址新开请填写关联撤店单号）","getDataList":_setup.getFlowList,"getNextList":_setup.getFlowList,"totalCount":_setup.flowTotalCount,"selectProps":{
          filterable: true,
          remote: true,
          clearable: true,
          'popper-class': 'custom-business-select'
        },"labelKey":"orderNumber","valueKey":"orderNumber","required":_setup.formData.storeOpeningMode === '换址新开'},on:{"onOptionsListNotFound":_setup.onOptionsListNotFound,"changeSelectedItem":e => {
            if (!e) return
            _setup.formData.linkOrderFormId = e.id
            _setup.formData.linkOrderOaRequestId = e.oaRequestId
          }},model:{value:(_setup.formData.linkOrderNumber),callback:function ($$v) {_vm.$set(_setup.formData, "linkOrderNumber", $$v)},expression:"formData.linkOrderNumber"}}):(_setup.formData.storeOpeningMode === '换址新开')?_c('el-form-item',{staticClass:"formItem",attrs:{"label":"关联撤店单号"}},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _setup.oaDetail(_setup.formData.linkOrderNumber)}}},[_vm._v(_vm._s(_setup.formData.linkOrderNumber))])]):_vm._e(),_c('br'),_c(_setup.HeaderTitle,{attrs:{"title":"申请开店信息"}}),_c(_setup.StoreFlowBaseTable,{attrs:{"defaultData":_setup.formData.shop,"authorizedSelectedItem":_setup.authorizedCityMsg,"defaultDataBrandList":_setup.brandData,"businessLicense":_setup.businessMsg,"storeOpeningType":_setup.formData.storeOpeningType,"storeOpeningMode":_setup.formData.storeOpeningMode,"flowStatus":_setup.formData.status,"bigRegionName":_setup.formData.bigRegionName},on:{"getMarketShopCount":e => {
            _setup.formData.shop.marketShopCount = _setup.formData.marketShopCount = e
          }}}),_c('br'),_vm._l((_setup.uploadAttachmentColumns),function(item,idx){return (!(item?.hidden ?? false))?_c(_setup.RequiredFormItemUploadFile,{key:item.name,staticClass:"formItem",attrs:{"label":"上传附件","prop":`${item.name}`,"rules":item.rules || [],"required":item?.required ?? true,"onPreview":_setup.handlePreview},on:{"onUploaded":file => _setup.handleUpload(file, item.name)},model:{value:(_setup.formData[item.name]),callback:function ($$v) {_vm.$set(_setup.formData, item.name, $$v)},expression:"formData[item.name]"}},[_c('div',{attrs:{"slot":"describe"},slot:"describe"},[_c('div',[_vm._v("附件：")]),_c('div',[_vm._v(_vm._s(item.label))]),(item.fileUrl)?_c('div',[_c('el-button',{on:{"click":function($event){return _setup.downloadFile(item.fileUrl)}}},[_vm._v("模板下载")])],1):_vm._e(),(item?.exampleUrlList?.length > 0)?_c('div',_vm._l((item?.exampleUrlList),function(url,i){return _c('div',{key:url,staticClass:"example"},[_c('el-image',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":url,"fit":"contain"}}),_c('div',{staticClass:"link",attrs:{"title":url}},[_vm._v(" 示例"+_vm._s(idx + 1)+"-"+_vm._s(i + 1)+" ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _setup.handlePreview({ url })}}},[_vm._v(" 示例预览")])],1)}),0):_vm._e()]),_c('div',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("单文件不能超过400M")])]):_vm._e()}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",staticStyle:{"width":"100%"},attrs:{"required":false,"label":"备注","type":"textarea","rows":6},model:{value:(_setup.formData.remark),callback:function ($$v) {_vm.$set(_setup.formData, "remark", $$v)},expression:"formData.remark"}})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }