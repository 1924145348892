/*
 * @Author: wangmq
 * @Date: 2023-04-13 16:43:57
 * @LastEditors: wangmq
 * @LastEditTime: 2023-04-14 09:25:21
 */
import { getCurrentInstance } from 'vue'

export function useRoute() {
  const { proxy } = getCurrentInstance()
  const route = proxy.$route
  return route
}
export function useRouter() {
  const { proxy } = getCurrentInstance()
  const router = proxy.$router
  return router
}
