/*
 * @Author: wangmq
 * @Date: 2022-11-08 15:40:05
 * @LastEditors: wangmq
 * @LastEditTime: 2022-11-10 09:25:10
 */
import http from "@/utils/request";

/**
 * @description: 保存到草稿
 * @param {object} data
 */
export const requestDraftFlow = async (data) => {
  const res = await http({
    url: '/dealermanage/operationflow/dealerJoinIntentionApi/saveDraft',
    method: 'post',
    data
  })
  return res.data
}

/**
 * @description: 提交流程
 * @param {object} data
 */
export const requestSubmitFlow = async (data) => {
  const res = await http({
    url: '/dealermanage/operationflow/dealerJoinIntentionApi/submit',
    params: data
  })
  return res.data
}

/**
 * @description: 
 * @return {*}
 */
export const requestFlowDetail = async id => {
  const res = await http({
    url: '/dealermanage/operationflow/dealerJoinIntentionApi/getDetail',
    params: {
      formId: id
    },
  })
  return res.data
}