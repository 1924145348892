import AliOSS from "ali-oss";
import { getOssInfo } from "@/api/common/index";
import dateFormat from "dateformat";

const hostReg = /^http(s)?:\/\/(.*?)\//;

export const getOssConfig = async () => {
  let ossInfoJSON = sessionStorage.getItem("ossInfoJSON");
  let ossInfo = "";
  if (ossInfoJSON) {
    try {
      ossInfo =
        typeof ossInfoJSON === "string" ? JSON.parse(ossInfoJSON) : ossInfoJSON;
      if (new Date() >= new Date(ossInfo.Credentials.Expiration)) {
        sessionStorage.removeItem("ossInfoJSON");
        throw "alioss token expired, try to refresh";
      }
    } catch {
      sessionStorage.removeItem("ossInfoJSON");
      return getOssConfig();
    }
  } else {
    ossInfoJSON = await getOssInfo().then(res => res.data);
    sessionStorage.setItem("ossInfoJSON", ossInfoJSON);
    ossInfo =
      typeof ossInfoJSON === "string" ? JSON.parse(ossInfoJSON) : ossInfoJSON;
  }

  let ossConfig = {
    //阿里云账号AccessKey拥有所有API的访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式访问API。
    accessKeyId: ossInfo.Credentials.AccessKeyId,
    accessKeySecret: ossInfo.Credentials.AccessKeySecret,
    stsToken: ossInfo.Credentials.SecurityToken,
    region: "oss-cn-hangzhou",
    bucket:process.env.VUE_APP_OSS_URL || 'derucci-app'
  };
  return ossConfig;
};

// 通过url解析文件信息
export const aliUrlToFileObject = (url, originalName = "") => {
  const baseUrl = url.match(hostReg)[0];
  let fileDir = url.replace(hostReg, "");
  fileDir = fileDir.split("?")[0];
  const fileSlice = fileDir.split(".");
  const fileDirSlice = fileDir.split("/");
  const ext = fileSlice.length > 1 ? fileSlice[fileSlice.length - 1] : "";
  return {
    fileName: originalName || fileDirSlice[fileDirSlice.length - 1],
    requestDomainName: baseUrl,
    fileType: ext,
    requestPath: fileDir
  };
};

function initFileObj(originalName) {
  // 给文件名添加时间戳
  const nameArr = originalName.split(".");
  let ext = "";
  if (nameArr.length > 1) {
    ext = nameArr.pop();
  }
  nameArr[nameArr.length] += `timestamp=${Date.now()}`;
  let name = nameArr.join(".");
  try {
    name = window.btoa(encodeURIComponent(name));
  } catch {
    name =
      Math.random()
        .toString()
        .substr(3, 10) + Date.now();
  }
  return {
    name: ext ? `${name}.${ext}` : `${name}`,
    ext
  };
}

/**
 * 上传文件到阿里云
 * @param {*} options 配置项
 */
export const uploadFile = async (file, uploadOptions = {}, callback) => {
  const client = new AliOSS(await getOssConfig());

  let tempCheckpoint = uploadOptions.checkpoint;
  if (Object.prototype.toString.call(callback) === "[object Function]") {
    callback(
      // 暂停
      client.cancel.bind(client),
      // 续传
      (_, uploadOptions = {}, callback) => {
        return uploadFile(
          file,
          {
            ...uploadOptions,
            checkpoint: tempCheckpoint
          },
          callback
        );
      }
    );
  }
  // 分片上传
  return client.multipartUpload(
    `/bigfile/merchants-platform/${dateFormat(Date.now(), "yyyy-mm-dd")}/${
      initFileObj(file.name).name
    }`,
    file,
    {
      ...uploadOptions,
      progress: (p, checkpoint, ...rest) => {
        tempCheckpoint = checkpoint;
        if (uploadOptions.progress) {
          uploadOptions.progress(p, checkpoint, ...rest);
        }
      }
    }
  );

  // 直接上传
  // return client.put(file.name, file, uploadOptions);
};
