// @ts-nocheck
import request from '@/utils/request'

export const systemListPage = params => {
  return request({
    url: '/user/system/listpage',
    method: 'get',
    params
  })
}

export const menuList = params => {
  return request({
    url: '/user/menu/list',
    method: 'get',
    params
  })
}

//新增菜单
export const menuAdd = data => {
  return request({
    url: '/user/menu/add',
    method: 'post',
    data
  })
}

//修改菜单
export const menuUpdate = data => {
  return request({
    url: '/user/menu/update',
    method: 'post',
    data
  })
}

//删除菜单
export const menuDelete = data => {
  return request({
    url: '/user/menu/delete',
    method: 'post',
    data
  })
}

/** 同步应用菜单 */
export const menuBatchSync = data => {
  return request({ method: 'post', url: '/user/menu/batchSync', data })
}
