import http from '@/utils/request'

//列表
export const getList = params =>
  http({ url: '/user/account/accountlistpage', params })

//批量权限
export const editPower = data =>
  http({ url: '/dealermanage/userdatalimit/batchadd', method: 'POST', data })

//批量权限
export const getPower = params =>
  http({ url: '/dealermanage/userdatalimit/list', params })

//获取经销商数据；跟进品牌、省、城市
export const getDealer = data =>
  http({
    url: '/dealermanage/crmdistributor/getDistributor',
    data,
    method: 'POST'
  })

//更新经销商授权数据
export const updateDealer = data =>
  http({ url: '/dealermanage/userdatalimit/batchadd/v2', data, method: 'POST' })
