import http from '@/utils/request'
import { download } from '@/utils/request'

// 获取主数据管理列表
export const getDataManageDataList = params => {
  return http({
    url: '/dealermanage/declarationform/getFormList',
    params
  })
}

// 获取流程类型

export const getFlowTypeList = params => {
  return http({
    url: '/dealermanage/datadictionary/list',
    params
  })
}
// 修改流程类型

export const changStatusUpdate = data => {
  return http({
    url: '/dealermanage/declarationform/update',
    data,
    method: 'post'
  })
}

// 查看关联流程
export const getFlowData = params => {
  return http({
    url: '/dealermanage/declarationform/getRelevanceForm',
    params
  })
}

// 导出文档
export const excelUpload = params => {
  let url = 'dealermanage/declarationform/export'
  // switch (params.processCode) {
  //   case 'JXS005':
  //     url = 'dealermanage/shop/apply/export'
  //     break
  //   case 'JXS006':
  //     url = 'dealermanage/shopDecorate/exportRedecorateForm'
  //     break
  //   case 'JXS007':
  //     url = 'dealermanage/shopRevoke/exportRevokeForm'
  //     break
  //   case 'JXS008':
  //     url = 'dealermanage/shopRevoke/exportRevokeForm'
  //     break
  // }
  download(url, params)
}
