export const tableHeight = {
  data() {
    return {
      withoutTableHeight: 0
    }
  },
  mounted() {
    const formH = this.$refs.form.offsetHeight
    const topH = document.querySelector('#topBar').offsetHeight
    const isMobile = /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry/i.test(
      navigator.userAgent
    )
    this.withoutTableHeight = isMobile ? 0 : formH + topH + 100
  }
}
