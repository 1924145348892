<template>
  <div
    style="height: 100vh; width: 100vw"
    v-loading.fullscreen="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="流程载入中"></div>
</template>

<script setup>
// templateId=${templateId}&processId=${processId}&fdId=${formId}
// ${templateId}为表单模板id入参字段，${processId}为流程实例id，${formId} 为业务系统文档单据id
import { jointUrl } from '@/utils'
import { useRoute, useRouter } from '@/utils/router'
import { ref } from 'vue'
import { bpmFormView } from './api'

const route = useRoute()
const router = useRouter()
const { query } = route

import { Message } from 'element-ui'

const loading = ref(false)
const resData = ref('')

loading.value = true

const params = {
  fdFormInstanceId: query.fdId
}
query.templateId && (params.flowTemplateId = query.templateId)
query.fdFormTemplateCode &&
  (params.fdFormTemplateCode = query.fdFormTemplateCode)

bpmFormView(params)
  .then(res => {
    resData.value = JSON.stringify(res)
    if (res.data.pageUrl) {
      const hrefQuery = {
        from: 'bpm',
        businessId: query.fdId,
        processId: query.processId,
        formType: query.formType //0为正常流程表单，1为只读并显示全部过程表单
      }
      if (/^\//.test(res.data.pageUrl)) {
        router.push({ path: res.data.pageUrl, query: hrefQuery })

        return
      }
      if (/^http/.test(res.data.pageUrl)) {
        location.href = jointUrl(res.data.pageUrl, hrefQuery)
        return
      }

      Message.error(`获取到的流程url格式错误，url为：${res.data.pageUrl}`)
    }
  })
  .finally(() => (loading.value = false))
</script>

<style lang="scss" scoped></style>
