<template>
  <common-select
    ref="selectRef"
    :clearable="clearable"
    :filterable="filterable"
    :remote="!dependentParentContent"
    :label-key="labelKey"
    :value-key="valueKey"
    :total-count="totalCount"
    :data-list="dataList"
    :get-data-list="requestDataList"
    :get-next-list="isLocal ? null : requestDataList"
    :get-data-trigger="dataTrigger"
    :value-translate="valueTranslate"
    :get-data-list-params="{ ...dataListParams, ...getDataListParams }"
    :filter-data-list="filterDataListFn"
    v-bind="$attrs"
    @onOptionsListNotFound="onOptionsListNotFound"
    v-on="$listeners" />
</template>

<script>
import Select from './Select'
import { datadictionaryList } from '@/api/common'
import LocalModuleData from './data/module.data.js'
import md5 from 'js-md5'
import store from '@/store'

let response = {}
store.subscribeAction(({ type }) => {
  if (type === 'user/logout') {
    response = {}
  }
})
export default {
  components: { [Select.name]: Select },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  props: {
    /**
     * 远程获取
     * ### module = distributor
     * market:商场类型
     * logistics_company:物流公司
     * shop_type: 门店类型
     * delivery_type: 运输方式
     * transport_type: 装运条件
     * business_type: 业务部门
     * shop_application_type: 申请类型
     * dealer_source: 经销商来源
     * dealer_sub_source: 二级经销商来源
     * cooperation_code: 渠道类型
     * dealer_type: 经销商类型
     * dealer_sub_type: 经销商类型二级分类
     * dealer_thirdary_type: 经销商类型三级分类
     * secondary_channel_type: 二级渠道类型
     * exhibition_shop_type: 展示店类型
     * store_type: 门店类型
     * secondary_store_type: 二级门店类型
     * three_store_type: 三级门店类型
     * shop_nature_code: 门店性质
     * shop_level: 门店等级
     * exchange_provision: 交易条款
     * settlement_type: 付款方式
     * overseas_department: 销售部门
     * product_level: 产品档次
     * distributor_state_code: 客户状态
     * cooperation_end_reason: 终止合作原因
     * payment_type_code: 付款类型
     * reconciliation_account: 统驭科目
     *
     * ### module = public
     * country: 国家
     * province: 省份
     * city: 城市
     * area: 镇区
     * town: 街道
     *
     * 本地值
     * education:学历
     * marital_status:婚姻状况
     * sex:性别
     * dealer_type:经销商类型, 新老经销商
     * urgent_level:紧急程度
     * business_license_type:营业执照类型
     * pay_taxes_type:纳税类型
     * bill_type:专票
     * brand_management:慕思品牌经营情况
     * create_shop_type:开店类型
     * mode_of_operation:代理店的经营方式
     * management_style:代理店的管理方式
     * store_size:门店规模
     * partnership:合作关系
     * management_model:经营模式
     * finance_confirm_status:财务确认款项
     * renovation: 是否装修
     * shop_status: 门店状态
     * shop_if_decorate 门店是否装修
     * decorate_style 装修风格
     */
    type: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      default: 'distributor'
    },
    // 将数值类型转换字符串
    valueTranslate: {
      type: Function,
      default: value => {
        if (typeof value === 'number') {
          return String(value)
        }
        return value
      }
    },
    labelKey: {
      type: String,
      default: 'content'
    },
    valueKey: {
      type: String,
      default: 'code'
    },
    // 父code
    parentCode: {
      type: String,
      default: ''
    },
    // 依赖父级code
    dependentParentCode: {
      type: Boolean,
      default: false
    },
    // 父content
    parentContent: {
      type: String,
      default: ''
    },
    // 依赖父级content
    dependentParentContent: {
      type: Boolean,
      default: false
    },
    network: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterDataList: {
      type: Function,
      default: null
    },
    filterable: {
      type: Boolean,
      default: true
    },
    getDataListParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      totalCount: null
    }
  },

  computed: {
    dataTrigger({ $attrs: { getDataTrigger } }) {
      if (getDataTrigger) {
        return getDataTrigger
      }
      return this.isLocal ? [] : ['create', 'change']
    },
    isLocal({ type }) {
      return !this.network && !!LocalModuleData[type]
    },
    dataList({ isLocal, type }) {
      if (isLocal) {
        return LocalModuleData[type]
      } else {
        return []
      }
    },
    dataListParams({ parentCode, parentContent }) {
      return {
        parentCode,
        parentContent
      }
    },
    $formItemRef() {
      return this.$refs.formItemRef
    },
    $selectRef() {
      return this.$refs.selectRef
    }
  },
  watch: {
    dataList: {
      immediate: true,
      handler() {
        if (this.isLocal) {
          this.totalCount = 1
        }
      }
    }
  },
  methods: {
    filterDataListFn(list) {
      let disabled = this.$attrs.disabled
      if (this.$refs.selectRef) {
        disabled = this.$refs.selectRef.isDisabled
      }
      if (this.filterDataList) {
        return this.filterDataList(list)
      }
      return list
        .map(item => {
          item[this.valueKey] = this.valueTranslate(item[this.valueKey])
          return item
        })
        .filter(item => disabled || item.ifDelete !== 1)
    },
    onOptionsListNotFound({ value, getDataListParams }, callback) {
      if (this.dependentParentCode) {
        if (!this.parentCode) {
          return []
        }
      }
      if (this.dependentParentContent) {
        if (!this.parentContent) {
          return []
        }
      }
      const params = {
        type: this.type,
        module: this.module,
        [this.valueKey]: value,
        ...getDataListParams,
        ...this.getDataListParams
      }
      const paramsStr = JSON.stringify(params)
      const paramsKey = md5(paramsStr)
      if (response[paramsKey]) {
        return callback(response[paramsKey].data)
      }

      return datadictionaryList(params).then(res => {
        response[paramsKey] = res
        callback(res.data)
      })
    },
    async requestDataList({ page, searchText, getDataListParams }) {
      if (this.dependentParentCode) {
        if (!this.parentCode) {
          return []
        }
      }
      if (this.dependentParentContent) {
        if (!this.parentContent) {
          return []
        }
      }
      if (this.isLocal) {
        const filterDataList = this.dataList.filter(item => {
          const reg = new RegExp(searchText)
          return reg.test(item.content)
        })
        this.totalCount = filterDataList.length
        return filterDataList
      }
      const params = {
        type: this.type,
        module: this.module,
        currPage: page,
        ...getDataListParams,
        content: searchText,
        ...this.getDataListParams
      }
      const paramsStr = JSON.stringify(params)
      const paramsKey = md5(paramsStr)
      if (response[paramsKey]) {
        const responseData = await response[paramsKey]
        this.totalCount = responseData.totalCount
        return responseData.data
      }

      response[paramsKey] = datadictionaryList(params).then(res => {
        response[paramsKey] = res
        this.totalCount = res.totalCount
        return res
      })
      return response[paramsKey].then(res => res.data)
    }
  }
}
</script>
