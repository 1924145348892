<template>
  <div>
    <el-form :inline="true" :model="form" :rules="rules" class="demo-form-inline" ref="form">
      <el-form-item label="数据类型（表名）" prop="table">
        <el-select v-model="form.table" placeholder="请选择数据类型（表名）">
          <el-option v-for="(item,index) in tableOptions" :key="index" :label="item.name" :value="item.table"/>
        </el-select>
      </el-form-item>
      <el-form-item label="编号" prop="data">
        <el-input v-model="form.data" placeholder="请输入编号"></el-input>
      </el-form-item>
      <!-- <el-form-item label="字段" prop="column">
        <el-select v-model="form.column" placeholder="请选择字段">
          <el-option v-for="(item,index) in columnOptions" :key="index" :label="item.columnComment" :value="item.columnName"/>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>
  <div>
    <el-table
      ref="table"
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%;margin-top:20px"
      height="630px"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }">
      <el-table-column
        prop="orderNumber"
        label="流程单号"
        width="180">
      </el-table-column>
      <el-table-column
        prop="title"
        label="流程标题"
        width="180">
      </el-table-column>
      <el-table-column
        prop="historicalData"
        label="更新后的数据">
        <template slot-scope="scope">
         <el-button type="primary" size="small" @click="openDrawer(scope.row.historicalData)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateFields"
        label="更新字段">
      </el-table-column>
      <el-table-column
        prop="createUserName"
        label="更新人">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="更新时间">
      </el-table-column>
    </el-table>
  </div>
    <div class="pagination">
       <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-sizes="[10,30,50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-drawer ref="drawer" title="更新后数据" :visible.sync="visible" direction="rtl" size="60%">
      <div style="padding:0 20px;line-height:2">
        <div v-for="(item,key) in historicalData" :key="key">
          <b>{{key}}</b>：{{item}}
        </div>
      </div>
    </el-drawer>
  </div>
  
</template>
<script>
import {requiredRule} from '@/utils/useFormValidator'
import { getDataHistoryDetailByTable, getColumnByTable } from '@/api/commonConfig/dataAudit'
export default {
  data(){
    return {
      loading:false,
      form:{
        table:'',
        data:'',
        column:''
      },
      rules:{
        table:[requiredRule],
        // column:[requiredRule]
      },
      tableData:[],
      currPage:1,
      pageSize:10,
      total:0,
      columnOptions:[],
      visible:false,
      historicalData:{},
      tableOptions:[
        {
          table:'dealer_authorized_brand',
          name:'送达方'
        },
        {
          table:'dealer_boss',
          name:'人员'
        },
        {
          table:'dealer_business_license',
          name:'售达方'
        },
        {
          table:'dealer_crm_distributor',
          name:'经销商'
        },
        {
          table:'dealer_delivery_party',
          name:'经销商授权系列'
        },
        {
          table:'dealer_shop',
          name:'门店'
        },
        {
          table:'dealer_shop_brand',
          name:'门店系列'
        }
      ]
    }
  },
  methods:{
    openDrawer(historicalData){
      this.historicalData = JSON.parse(historicalData)
      this.visible = true
    },
    async getColumn(table){
      this.form.column = ''
      this.columnOptions = []
      let {msg,code,data} = await getColumnByTable({table})
      if(code === 0){
        this.columnOptions = data
      }else{
        this.$message.error(msg)
      }

    },
    query(){
      this.$refs.form.validate(valid => {
        if(valid){
          this.currPage = 1
          this.getData()
        }
      })
     
    },
    async getData(){
      this.loading = true
      let params = {
        ...this.form,
        currPage:this.currPage,
        pageSize:this.pageSize
      }
      let {msg,code,data,totalCount} = await getDataHistoryDetailByTable(params)
      this.loading = false
      if(code === 0){
        this.tableData = data
        this.total = totalCount
      }else{
        this.$message.error(msg)
      }
    },
    handleSizeChange(pageSize){
      this.pageSize = pageSize
      this.currPage = 1
      this.getData()
    },
    handleCurrentChange(page){
      this.currPage = page
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/colors';
.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-drawer__body{
  overflow-y:auto;
}
</style>
