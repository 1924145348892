export default [
  {
    path: 'hotelList',
    name: 'HotelList',
    component: () => import('@/views/sfa/hotelList.vue'),
    meta: { title: '酒店列表' }
  },
  {
    path: 'roomList',
    name: 'RoomList',
    component: () => import('@/views/sfa/roomList.vue'),
    meta: { title: '房间列表' }
  },
  {
    path: 'productList',
    name: 'ProductList',
    component: () => import('@/views/sfa/productList.vue'),
    meta: { title: '产品列表' }
  },
  {
    path: 'clerkList',
    name: 'ClerkList',
    component: () => import('@/views/sfa/clerkList.vue'),
    meta: { title: '员工列表' }
  }
]
