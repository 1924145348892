import http, { download } from "@/utils/request";

//保存
export const getCompanyList = params => http({ url: "/dealermanage/salesorganization/listpage", params })

//提交
export const getSeriesList = params => http({ url: "/dealermanage/brandseries/listpage", params })

//获取门店信息
export const save = data => http({ url: "/dealermanage/examineSubsidy/draft", data, method: 'POST' })

//获取详情
export const submit = data => http({ url: "/dealermanage/examineSubsidy/submit", data, method: 'POST' })

//获取关联流程
export const getDetail = params => http({ url: "/dealermanage/examineSubsidy/detail", params })

//上传表格json数组
export const submitExeclJson = data => http({ url: "/dealermanage/examineSubsidy/importDetail", data, method: 'POST' })

//上传表格数组
export const updateExecl = data => http({ url: "/dealermanage/examineSubsidy/importDetailExcel", data, method: 'POST' })

// 导出表格模板
export const downloadExecl = params => download("dealermanage/examineSubsidy/exportTemplate", params)

// 导出表格数据
export const exportExecl = params => download("dealermanage/examineSubsidy/exportDetail", params)

// 获取表格数据列表
export const getExeclList = params => http({ url: "/dealermanage/examineSubsidy/subsidyDetailPage", params })