<template>
  <div>
    <div ref="form">
      <el-form
        ref="form"
        :inline="true"
        :model="formInline"
        class="demo-form-inline">
        <el-form-item
          v-for="(item, index) in formItem"
          :key="index"
          label-width="90px"
          :label="`${item.name}：`">
          <template v-if="item.type === 'input'">
            <el-input
              v-model="formInline[item.key]"
              style="width: 230px"
              :placeholder="`请输入${item.name}`" />
          </template>
          <template v-if="item.type === 'date'">
            <el-date-picker
              v-model="formInline[item.key]"
              style="width: 230px"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd" />
          </template>
          <template v-if="item.type === 'select'">
            <el-select
              v-model="formInline[item.key]"
              placeholder="请选择"
              style="width: 230px">
              <el-option
                v-for="item in item.data"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <!-- <el-button @click="dialogVisible = true">修改流程状态</el-button> -->
        <el-button
          v-permission="['dataManage_excel']"
          type="primary"
          @click="uploadExcel"
          >导出excel</el-button
        >
      </div>
    </div>

    <div :style="{ height: `calc(100vh - ${withoutTableHeight}px)` }">
      <el-table
        ref="table"
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%; margin-top: 20px"
        height="100%"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        @row-click="rowClick"
        @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection"> </el-table-column> -->
        <el-table-column prop="title" label="流程名称" align="center" />
        <el-table-column prop="orderNumber" label="流程单号" align="center" />
        <el-table-column prop="zsOrderNumber" label="招商单号" align="center" />
        <el-table-column prop="id" label="表单 form_id" align="center" />
        <el-table-column prop="optTime" label="提单时间" align="center" />
        <el-table-column prop="optUserName" label="提单人" align="center" />
        <el-table-column prop="overTime" label="归档时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.status === 40 ? scope.row.overTime : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="关联流程" align="center">
          <template slot-scope="scope">
            <div>
              <span class="sort" @click.stop="checkFlow(scope.row.orderNumber)"
                >查看关联流程</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <div
                class="sort"
                @click="detail(scope.row)"
                v-if="scope.row.status !== 0">
                查看详情
              </div>
              <div class="sort" @click.stop="editFlowStatus(scope.row)">
                修改流程状态
              </div>
              <div
                v-if="[20, 9].includes(scope.row.status)"
                v-permission="['dataManage_reSend']"
                class="sort"
                @click="reSend(scope.row)">
                重新发起
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="流程状态" align="center">
          <template slot-scope="scope">
            <div>
              {{
                statusOptions.length &&
                statusOptions.find(item => item.value == scope.row.status).label
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="bossNumber"
          label="生成流程数据表"
          align="center">
          <template slot-scope="scope">
            <div>
              <span
                class="sort"
                @click="goToJson(scope.row.dealerDataHistoryDetailList)"
                >生成流程数据表</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="pageSize || 10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="currentChange" />
    </div>
    <el-dialog title="修改流程状态" :visible.sync="dialogVisible" width="375px">
      <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="流程状态：">
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeStatus">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title="查看关联流程"
      :visible.sync="drawer"
      direction="rtl"
      :size="widthStyle">
      <el-table
        ref="flowTable"
        :data="flowData"
        border
        style="width: 100%; margin-top: 20px"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }">
        <el-table-column prop="title" label="关联流程名称" align="center" />
        <el-table-column
          prop="orderNumber"
          label="关联流程单号"
          align="center" />
        <el-table-column prop="optTime" label="提单时间" align="center" />
        <el-table-column prop="overTime" label="归档时间" align="center" />
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import {
  getDataManageDataList,
  getFlowTypeList,
  changStatusUpdate,
  getFlowData,
  excelUpload
} from '@/api/commonConfig/dataManage'
import { getStorage } from '@/utils/storage'
import { tableHeight } from '@/mixin/tableHeight'
import { mapMutations } from 'vuex'
import flow from '@/router/modules/flow'
let params = {}
export default {
  mixins: [tableHeight],
  data() {
    return {
      formInline: {},
      formItem: [
        { name: '流程类型', type: 'select', data: [], key: 'processCode' },
        { name: '提单时间', type: 'date', key: 'startTime' },
        { name: '归档时间', type: 'date', key: 'endTime' },
        { name: '招商单号', type: 'input', key: 'zsOrderNumber' },
        { name: '流程单号', type: 'input', key: 'orderNumber' },
        {
          name: '流程来源',
          type: 'select',
          data: [
            { value: 'OA', label: 'OA' },
            { value: 'BPM', label: 'BPM' }
          ],
          key: 'declarationType'
        },
        {
          name: '流程状态',
          type: 'select',
          key: 'status',
          data: [
            { value: '', label: '全部' },
            { value: 5, label: '提交中' },
            { value: 9, label: '发布失败/OA失败' },
            { value: 10, label: '已发布' },
            { value: 20, label: '退回/重审' },
            { value: 30, label: '已失效/作废' },
            { value: 40, label: '已归档' }
          ]
        },
        { name: '实控人编号', type: 'input', key: 'dealerCode' },
        // { name: '售达方编号', type: 'input', key: 'licenseNumber' },
        // { name: '经销商编号', type: 'input', key: 'bossNumber' },
        // { name: '送达方编号', type: 'input', key: 'shopBrandNumber' },
        // { name: '门店编号', type: 'input', key: 'shopNumber' },
        { name: '提单人', type: 'input', key: 'optUserName' },
        // { name: '实控人名称', type: 'input', key: 'realName' },
        // { name: '授权城市', type: 'input', key: 'city' },
        { name: '关联单号', type: 'input', key: 'linkOrderNumber' },
        { name: '实控人名称', type: 'input', key: 'searchBoss' },
        { name: '授权系列', type: 'input', key: 'searchBrandSeries' },
        { name: '授权城市', type: 'input', key: 'searchAuthorizedCity' }
      ],
      tableData: [],
      rowId: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      status: null,
      drawer: false,
      widthStyle: '50%',
      flowData: [],
      id: null,
      userInfo: {},
      loading: false,
      row: {} //
    }
  },
  computed: {
    statusOptions() {
      const item = this.tableData.find(item => item.id == this.id)
      return [
        { value: '0', label: '草稿' },
        { value: '5', label: '提交中' },
        { value: '9', label: '发布失败/OA失败' },
        { value: '10', label: '已发布' },
        {
          value: '20',
          label: '退回/重审',
          disabled: item && item.declarationType == 'BPM'
        },
        { value: '30', label: '已失效/作废' },
        { value: '40', label: '已归档' }
      ]
    }
  },
  created() {
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 1200) {
      this.widthStyle = '100%'
    }
    this.currentPage = params.page || 1
    this.userInfo = getStorage('userInfo')
    this.getFlowType()
    this.getDataList()
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    params = {}
    if (from.name === 'DataJsonTable') {
      params = from.params
    }
    next()
  },
  methods: {
    ...mapMutations('commonConfig', ['setDataJson']),
    async getDataList(form = {}) {
      this.loading = true
      if (this.formInline.startTime && this.formInline.startTime.length > 0) {
        form.startOptTime = this.formInline.startTime[0]
        form.endOptTime = this.formInline.startTime[1]
      }
      if (this.formInline.endTime && this.formInline.endTime.length > 0) {
        form.startUpdateTime = this.formInline.endTime[0]
        form.endUpdateTime = this.formInline.endTime[1]
      }
      const data = {
        ...this.formInline,
        ...form,
        currPage: this.currentPage,
        pageSize: this.pageSize
      }
      const formData = {}
      for (const key in data) {
        if (key !== 'startTime' && key !== 'endTime') {
          formData[key] = data[key]
        }
      }
      const res = await getDataManageDataList(formData)
      if (res.code === 0) {
        this.tableData = res.data
        this.total = res.totalCount
      }
      this.loading = false
    },
    // 获取流程类型
    async getFlowType() {
      const res = await getFlowTypeList({
        module: 'distributor',
        type: 'flow'
      })
      if (res.code === 0) {
        this.formItem[0].data = res.data
          .map(item => {
            return {
              value: item.code,
              label: item.content
            }
          })
          .filter(i => i.label != '其他')
      }
    },
    // 重新发起
    reSend(data) {
      // console.log(data);
      const routerList = flow()
      const path = routerList.find(item => {
        if (
          item.meta.declarationType &&
          item.meta.declarationType !== data.declarationType
        ) {
          return false
        }
        return item.meta.code === data.processCode
      }).path
      this.$router.push({
        path: `/flow/${path}`,
        query: { id: data.id, admin: 1 }
      })
    },
    // 编辑流程状态
    editFlowStatus({ id, status }) {
      this.dialogVisible = true
      this.status = String(status)
      this.id = id
    },
    // 修改状态
    async changeStatus() {
      const res = await changStatusUpdate({
        id: this.id,
        updateUsername: this.userInfo.realName,
        status: this.status
      })
      if (res.code === 0) {
        this.getDataList()
        this.dialogVisible = false
        this.$message.success('流程修改成功')
      }
    },
    // 详情
    detail(item) {
      if (/^JX/.test(item.orderNumber)) {
        const url = `${
          process.env.VUE_APP_OA_URL
        }/mobile/plugin/loginForSession.jsp?loginid=MerchantSysAdmin&requestid=${
          item.oaRequestId || ''
        }&workflowid=${item.orderNumber || ''}`
        window.open(url)
      } else {
        const url = `${location.origin}${location.pathname}#/oa/flow/entrance?fdId=${item.id}&fdFormTemplateCode=distributor-${item.processCode}&processId=${item.orderNumber}&from=bpm&formType=1`
        window.open(url)
      }
    },
    // 查询
    query() {
      const form = { ...this.formInline }
      this.currentPage = 1
      this.getDataList(form)
    },
    reset() {
      this.formInline = {}
      this.currentPage = 1
      this.getDataList(this.formInline)
    },
    // 导出excel
    async uploadExcel() {
      if (!this.formInline.processCode) {
        this.$message.warning('请选择流程类型')
        return
      }

      const tempObj = { ...this.formInline }
      delete tempObj.startTime
      delete tempObj.endTime

      const data = {
        startOptTime:
          this.formInline.startTime && this.formInline.startTime.length > 0
            ? this.formInline.startTime[0]
            : '',
        endOptTime:
          this.formInline.startTime && this.formInline.startTime.length > 0
            ? this.formInline.startTime[1]
            : '',
        processCode: this.formInline.processCode,
        startUpdateTime:
          this.formInline.endTime && this.formInline.endTime.length > 0
            ? this.formInline.endTime[0]
            : '',
        endUpdateTime:
          this.formInline.endTime && this.formInline.endTime.length > 0
            ? this.formInline.endTime[1]
            : ''
      }
      await excelUpload({ ...tempObj, ...data })
    },
    // 获取关联流程信息
    async checkFlow(id) {
      this.drawer = true
      const res = await getFlowData({
        linkOrderNumber: id
      })
      if (res.code === 0) {
        this.flowData = res.data
      }
    },
    // 跳转到Json表格
    goToJson(json) {
      this.setDataJson(json)
      this.$router.push({
        path: '/commonConfig/dataJsonTable',
        query: { page: this.currentPage }
      })
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.table.toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  font-size: 12px;
}

@import '@/styles/colors';

.sort {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
