<!--
 * @Author: wangmq
 * @Date: 2023-07-25 15:12:09
 * @LastEditors: wangmq
 * @LastEditTime: 2023-07-26 16:08:56
-->
<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="生/失效授权系列" name="1">
        <TablePro ref="tableRef" :columns="columns" :request="getDataList">
          <template #actionArea>
            <div>
              <el-button type="primary" @click="downloadModule"
                >下载模板</el-button
              >
              <PopupForm
                title="导入"
                :columns="formColumns"
                :onSubmit="handleSubmit"
                @hide="
                  () => {
                    $refs.uploadRef?.clearFiles()
                  }
                ">
                <template #trigger="{ onShow }">
                  <el-button style="margin-left: 12px" @click="onShow({})"
                    >导入</el-button
                  >
                </template>
              </PopupForm>
            </div>
          </template>
        </TablePro>
      </el-tab-pane>
      <!-- <el-tab-pane label="新增授权系列" name="2">配置管理</el-tab-pane>
      <el-tab-pane label="修改授权系列" name="3">角色管理</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import TablePro, {
  render,
  renderFormItem
} from '@/components/TablePro/index.vue'
import PopupForm from '@/components/FormPro/PopupForm.vue'
import CommonUpload from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import { getHandleList, requestGetModule, requestSubmit } from './api'
import UploadFile from '@/components/CustomFormItem/uploadFile.vue'
import { uploadFile } from '@/utils/obsUpload'
import { getStorage } from '@/utils/storage'
export default {
  components: {
    TablePro,
    PopupForm,
    CommonUpload,
    UploadFile
  },
  data() {
    return {
      activeName: '1',
      fileList: [],
      columns: [
        {
          title: '日期',
          name: 'createTime'
        },
        {
          title: '事由',
          name: 'updateReason'
        },
        {
          title: '备注',
          name: 'remark'
        },
        {
          title: '处理人',
          name: 'updateUserName'
        },
        {
          title: '数据清单',
          name: 'id',
          render: render((_, context) => {
            return (
              <div>
                {/* <el-button type="primary">查看</el-button> */}
                <el-button
                  type="primary"
                  onClick={() => {
                    window.open(context.data.fileUrl, '失效授权系列')
                  }}>
                  下载
                </el-button>
              </div>
            )
          })
        }
      ],
      formColumns: [
        {
          title: '事由',
          name: 'updateReason',
          fieldProps: {
            required: true
          }
        },
        {
          title: '选择附件',
          name: '_multipartFile',
          transform: val => {
            return val ? { ...val } : {}
          },
          renderFormItem: renderFormItem(
            (_, context) => {
              const attrs = {
                ...context.$attrs,
                onChange: async file => {
                  if (!file) {
                    context.$listeners.input(undefined)
                    return
                  }
                  const { res } = await uploadFile(file.raw)
                  context.$listeners.input({
                    multipartFile: file.raw,
                    fileUrl: res.requestUrls[0]
                  })
                }
              }
              return (
                <el-upload
                  ref="uploadRef"
                  action="/"
                  auto-upload={false}
                  on={context.$listeners}
                  limit={1}
                  attrs={attrs}>
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              )
            },
            {
              required: true
            }
          )
        }
      ]
    }
  },
  methods: {
    handleClick() {
      console.log('tab click')
    },
    async getDataList(params) {
      const res = await getHandleList({ ...params, logicType: 'overEnable' })
      return res
    },
    async handleSubmit(data) {
      const userInfo = getStorage('userInfo')
      const postData = {
        ...data,
        updateBy: userInfo.accountId,
        updateUserName: userInfo.realName
      }
      const formData = new FormData()
      for (const key in postData) {
        formData.append(key, postData[key])
      }
      await requestSubmit(formData)
      this.$message.success('提交成功')
      this.$refs.tableRef.onRefreshTableList(1)
      this.$refs.uploadRef?.clearFiles()
      return true
    },
    downloadModule() {
      requestGetModule()
    }
  }
}
</script>

<style scoped lang="scss"></style>
