<template>
  <div class="role">
    <div class="role-action">
      <el-form label-width="80px" :inline="true">
        <el-form-item label="展示名称">
          <el-input v-model="form.name" placeholder="展示名称" />
        </el-form-item>
        <el-form-item label="展示字段">
          <el-input v-model="form.description" placeholder="展示字段" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onRefresh">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="
              () => {
                form = {}
                onRefresh()
              }
            ">
            重置
          </el-button>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="
              handleEdit(
                {
                  fdFormTemplateCode: route.query.fdFormTemplateCode || '',
                  fdFormTemplateName: route.query.fdFormTemplateName || ''
                },
                true
              )
            "
            >新增</el-button
          >
        </el-form-item>
        <el-form-item>
          <input
            id="file"
            ref="fileRef"
            @change="handleChange"
            hidden
            type="file"
            name="file"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
          <el-button @click="handelImport">导入</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="commonBpmTemplateColumnDownload"
            >下载模板</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-table
      border
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      :data="state.userList"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      v-loading="state.loading">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="systemCode" label="系统平台" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.systemCode == 'distributor'" type="info"
            >渠道运营管理系统</el-tag
          >
          <el-tag v-else type="danger">未知</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="parentId" label="父级ID" align="center">
      </el-table-column>
      <el-table-column
        prop="type"
        label="字段类型"
        align="center"></el-table-column>
      <el-table-column
        prop="sort"
        label="字段排序"
        align="center"></el-table-column>
      <el-table-column prop="enable" label="状态" align="center">
        <template #default="scope">
          <!-- {{ ['有效', '无效', '禁用'][scope.row.enable] }} -->
          <el-switch
            v-model="scope.row.enable"
            @change="handleEnable(scope.row)"
            :active-value="0"
            :inactive-value="1"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="展示名称"
        align="center"></el-table-column>
      <el-table-column prop="description" label="展示字段" align="center">
      </el-table-column>
      <el-table-column prop="isAuthority" label="是否权限" align="center">
        <template #default="scope">
          {{ ['无', '有'][scope.row.isAuthority] }}
        </template>
      </el-table-column>
      <el-table-column prop="createBy" label="创建人" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop="updateBy" label="更新人" align="center">
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center">
      </el-table-column>

      <el-table-column label="操作" width="200px" align="center">
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row, false)"
            >编辑</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.type === 'array'"
            @click="
              handleEdit(
                {
                  type: 'array',
                  id: scope.row.id,
                  fdFormTemplateName: scope.row.fdFormTemplateName,
                  fdFormTemplateCode: scope.row.fdFormTemplateCode
                },
                true
              )
            "
            >添加子级</el-button
          >
          <el-button type="text" @click="handleShowLogList(scope.row.id)"
            >变更日志</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        background
        :current-page="page.currPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>

    <el-dialog
      :title="state.isAdd ? '新增' : '修改'"
      :visible.sync="state.drawer"
      direction="rtl"
      @close="isChild = false"
      size="30%;min-width:500px;">
      <div style="padding: 10px 20px">
        <el-form
          label-width="100px"
          :inline="false"
          :model="state.detail"
          :rules="rules"
          ref="ruleForm">
          <RequiredFormItemSelect
            prop="fdFormTemplateCode"
            label="表单名称"
            v-model="state.detail.fdFormTemplateCode"
            labelKey="fdFormTemplateName"
            valueKey="fdFormTemplateCode"
            :disabled="disabled"
            clearable
            style="width: 100%"
            :getDataList="
              ({ page }) =>
                bpmtemplateListpage({
                  currPage: page,
                  systemCode: 'distributor'
                })
            "
            @changeSelectedItem="
              e => {
                if (e) {
                  state.detail.fdFormTemplateName = e?.fdFormTemplateName
                }
              }
            "
            @onOptionsListNotFound="onNotFoundfdFormTemplate" />
          <!-- <el-form-item label="表单名称" prop="fdFormTemplateName">
            <el-input
              v-model="state.detail.fdFormTemplateName"
              disabled></el-input>
          </el-form-item> -->
          <el-form-item label="表单编码" prop="fdFormTemplateCode">
            <el-input
              v-model="state.detail.fdFormTemplateCode"
              disabled></el-input>
          </el-form-item>
          <el-form-item label="字段名称" prop="name">
            <el-input v-model="state.detail.name"></el-input>
          </el-form-item>
          <el-form-item label="字段类型" prop="type">
            <!-- <el-input v-model="state.detail.type"></el-input> -->
            <el-select v-model="state.detail.type" placeholder="请选择字段类型">
              <el-option key="string" label="string" value="string"></el-option>
              <el-option key="array" label="array" value="array"></el-option>
              <el-option key="number" label="number" value="number"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字段排序" prop="name">
            <el-input v-model="state.detail.sort" type="number"></el-input>
          </el-form-item>
          <el-form-item label="字段展示" prop="description">
            <el-input v-model="state.detail.description"></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否权限">
            <el-switch
              v-model="state.detail.isAuthority"
              :active-value="0"
              :inactive-value="1"></el-switch>
          </el-form-item> -->
          <el-form-item label="表单类型" prop="remark">
            <el-select v-model="formatRemark.type" placeholder="请选择表单类型">
              <el-option
                :key="key"
                :label="item.label"
                :value="item.value"
                v-for="(item, key) in typeList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="选项值"
            prop="dycJson"
            v-if="['dycSelect'].includes(formatRemark.type)">
            <el-input
              type="textarea"
              :rows="4"
              v-model="formatRemark.dycJson"
              placeholder="请输入动态下拉所需json"></el-input>
            <span>
              tips:
              <br />
              {{ dycTips }}
            </span>
          </el-form-item>
          <el-form-item
            v-if="formatRemark.type == 'select'"
            label="选项值"
            prop="description">
            <el-button type="primary" @click="addSelectItem"
              >添加选项</el-button
            >
            <el-form :model="dynamicValidateForm">
              <div
                v-for="(item, index) in dynamicValidateForm.selectList"
                style="display: flex">
                <el-form-item
                  :prop="'selectList.' + index + '.keyValue'"
                  :rules="{
                    required: true,
                    message: '不能标签名称为空',
                    trigger: 'blur'
                  }">
                  <div style="display: flex">
                    <el-input
                      v-model="item.keyValue"
                      placeholder="男|1"
                      :key="index" />
                    <el-button type="danger" @click="removeSelectItem(index)"
                      >删除</el-button
                    >
                  </div>
                </el-form-item>
              </div>
            </el-form>
          </el-form-item>
          <el-form-item label="状态">
            <el-switch
              v-model="state.detail.enable"
              :active-value="0"
              :inactive-value="1"></el-switch>
          </el-form-item>

          <el-form-item v-if="!state.isDetail">
            <el-button type="primary" @click="handleRoleSubmit">保存</el-button>
            <el-button @click="state.drawer = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <logList
      :visible.sync="showLogList"
      :listId="listId"
      title="变更日志"
      size="90%;min-width:750px;" />
  </div>
</template>

<script setup>
import { Message } from 'element-ui'
import { reactive, ref, computed } from 'vue'
import {
  bpmtemplateListpage,
  bpmtemplatecolumnListpage,
  bpmtemplatecolumnUpdate,
  bpmtemplatecolumnAdd,
  commonBpmTemplateColumnDownload,
  commonBpmTemplateColumnImport,
  bpmtemplatecolumnEnableOrDisenable
} from '@/api/bpmManage'
import { getStorage } from '@/utils/storage'
import { useRoute } from '@/utils/router'
import logList from './components/logList'
import { RequiredFormItemSelect } from '@/components/CustomFormItem'
const route = useRoute()

const systemCode = 'distributor'
const userInfo = getStorage('userInfo') || {}

const fileRef = ref(null)
const ruleForm = ref(null)
const form = ref({})

const dycTips = `{url:"接口链接",params:"接口参数",lable:"下拉数据文本",value:"下拉数据值",multiple:"是否多选",searchName:"搜索key必须开启搜索",selectProps:{filterable:"开启过滤",remote:"开始搜索"}}`

const state = reactive({
  userList: [],
  drawer: false, //修改用户
  isDetail: false, //是否查看详情
  detail: {},
  loading: false,
  isAdd: true
})

const isChild = ref(false)

const showLogList = ref(false)
const listId = ref('')

const disabled = computed(() => {
  if (!state.isAdd || isChild.value) {
    return true
  }
  return route.query.fdFormTemplateCode ? true : false
})
const onNotFoundfdFormTemplate = async ({ value: fdFormTemplateCode }, callback) => {
  callback(
    await bpmtemplateListpage({
      currPage: 1,
      pageSize: 10,
      systemCode: 'distributor',
      fdFormTemplateCode
    }).then(res => res.data)
  )
}
const rules = ref({
  fdFormTemplateCode: [
    { required: true, message: '请输入模板名称', trigger: 'blur' }
  ],
  fdFormTemplateName: [
    { required: true, message: '请输入模板编码', trigger: 'blur' }
  ],
  name: [{ required: true, message: '请选择模板类型', trigger: 'blur' }],
  type: [{ required: true, message: '请输入模板描述', trigger: 'change' }],
  description: [{ required: true, message: '请输入展示字段', trigger: 'blur' }],
  remark: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请选择表单类型'))
        } else {
          callback()
        }
      },
      trigger: 'change'
    }
  ],
  dycJson: [
    {
      required: true,
      message: '请输入动态选择器',
      trigger: 'blur',
      validator: (rule, value, callback) => {
        if (
          !formatRemark.value.dycJson &&
          formatRemark.value.type == 'dycSelect'
        ) {
          callback(new Error('请选择表单类型'))
        } else {
          callback()
        }
      }
    }
  ]
})

const search = reactive({
  fdFormTemplateName: ''
})

//分页数据
const page = reactive({
  currPage: 1,
  pageSize: 10,
  totalCount: 1
})

const typeList = ref([
  { label: '输入框', value: 'input' },
  { label: '日期控件', value: 'date' },
  { label: '日期范围控件', value: 'daterange' },
  { label: '选择器', value: 'select' },
  { label: '文件选择器', value: 'file' },
  { label: '动态选择器', value: 'dycSelect' },
  { label: '前端自定义', value: 'custom' }
])

const formatRemark = ref({ type: 'input' })
// const selectList = ref([{ keyValue: '' }])
const dynamicValidateForm = ref({ selectList: [] })

const handleShowLogList = id => {
  showLogList.value = true
  listId.value = id
}

const addSelectItem = () => {
  dynamicValidateForm.value.selectList.push({ keyValue: '' })
}

const removeSelectItem = index => {
  dynamicValidateForm.value.selectList.splice(index, 1)
}

const handleEnable = row => {
  bpmtemplatecolumnEnableOrDisenable({
    id: row.id,
    enable: row.enable,
    updateBy: userInfo?.realName
  }).then(res => {
    res?.code === 0 && Message.success('修改成功' || res.message)
  })
}

const handelImport = () => {
  fileRef.value.click()
}
const handleChange = e => {
  const file = e.target.files[0]
  const formData = new FormData()
  formData.append('file', file)
  formData.append('systemCode', systemCode)
  formData.append('createBy', userInfo?.realName)
  formData.append('fdFormTemplateName', route.query.fdFormTemplateName || '')
  formData.append('fdFormTemplateCode', route.query.fdFormTemplateCode || '')
  commonBpmTemplateColumnImport(formData).then(res => {
    Message.success('导入成功')
  })
}

getList()

//表格选中的用户
const handleSelectionChange = val => {}

//查看、修改
const handleEdit = async (item, isAdd) => {
  state.isAdd = isAdd
  state.detail = item
  state.drawer = true
  if (Object.keys(item).length === 4 && item.type === 'array') {
    isChild.value = true
  }
  try {
    formatRemark.value = JSON.parse(state.detail.remark)

    if (formatRemark.value?.type === 'select') {
      dynamicValidateForm.value.selectList = formatRemark.value?.selectList.map(
        item => ({ keyValue: `${item.label}|${item.value}` })
      )
    }
  } catch (err) {
    console.log(err)
    formatRemark.value = { type: '' }
  }
}

const handleSizeChange = pageSize => {
  if (page.totalCount <= pageSize) {
    page.currPage = 1
  }
  page.pageSize = pageSize
  getList(page.currPage, pageSize)
}
const handleCurrentChange = currPage => {
  page.currPage = currPage
  getList(currPage, page.pageSize)
}
async function getList(currPage = 1, pageSize = 10) {
  state.loading = true

  const params = {
    ...search,
    systemCode, //固定值
    currPage,
    pageSize,
    ...form.value
  }
  route.query.fdFormTemplateCode &&
    (params.fdFormTemplateCode = route.query.fdFormTemplateCode)

  try {
    const { data, totalCount } = await bpmtemplatecolumnListpage(params)
    state.userList = data
    page.totalCount = totalCount
  } finally {
    state.loading = false
  }
}

const handleRoleSubmit = async () => {
  ruleForm.value.validate(async valid => {
    if (valid) {
      const {
        id,
        fdFormTemplateCode,
        fdFormTemplateName,
        name,
        type,
        description,
        isAuthority,
        enable,
        sort,
        remark
      } = state.detail

      if (formatRemark.value.type === 'select') {
        formatRemark.value.selectList =
          dynamicValidateForm.value.selectList.map(item => ({
            label: item?.keyValue.split('|')[0],
            value: item?.keyValue.split('|')[1]
          }))
      }

      const submitApi = state.isAdd
        ? bpmtemplatecolumnAdd
        : bpmtemplatecolumnUpdate
      const params = {
        id,
        systemCode,
        fdFormTemplateCode,
        fdFormTemplateName,
        name,
        type,
        description,
        isAuthority,
        enable,
        sort,
        remark: JSON.stringify(formatRemark.value)
      }
      if (state.isAdd) {
        params.createBy = userInfo?.realName
        delete params.id
      } else {
        params.updateBy = userInfo?.realName
      }

      if (isChild.value) {
        params.parentId = state.detail.id
      }

      const { msg } = await submitApi(params)
      Message.success(msg)
      state.detail = {}
      state.drawer = false
      getList(page.currPage)
    } else {
      return false
    }
  })
}

const onRefresh = () => {
  page.currPage = 1
  getList(page.currPage, page.pageSize)
}
</script>

<style lang="scss" scoped>
.role {
  &-action {
    background-color: #fff;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .page {
    margin: 30px 0;
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
  }
}
</style>
