import { Workbook } from 'exceljs'

import { getStorage } from '@/utils/storage'

function getWidth(data) {
  /*设置worksheet每列的最大宽度*/
  const colWidth = data.map((row) =>
    row.map((val) => {
      /*先判断是否为null/undefined*/
      if (val == null) {
        return 10
      } else if (/^data:image\/png;base64/.test(val)) {
        return getBase64Size(val).width / 8 + 4
      } else if (val.toString().charCodeAt(0) > 255) {
        /*再判断是否为中文*/
        return val.toString().length * 2 + 4
      } else {
        return val.toString().length + 4
      }
    })
  )
  /*以第一行为初始值*/
  let result = colWidth[0]
  for (let i = 1; i < colWidth.length; i++) {
    for (let j = 0; j < colWidth[i].length; j++) {
      if (result[j] < colWidth[i][j]) {
        result[j] = colWidth[i][j]
      }
    }
  }
  return result
}

async function valueFactory(data) {
  const _data = []
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const items = data[rowIndex]
    const values = []
    for (let colIndex = 0; colIndex < items.length; colIndex++) {
      let item = items[colIndex]
      if (item instanceof Blob) {
        // 转换blob为base64
        const reader = new FileReader()
        reader.readAsDataURL(item)
        const base64 = await new Promise((resolve) => {
          reader.onload = function (e) {
            resolve(e.target.result)
          }
        })
        values.push({
          type: 'image',
          value: base64
        })
      } else if (/^data:image\/png;base64/.test(item)) {
        values.push({
          type: 'image',
          value: item
        })
      } else {
        values.push({
          type: 'string',
          value: item
        })
      }
    }
    _data.push(values)
  }
  return _data
}

export async function exportExcel({ titleList, data, filename }) {
  const { username } = getStorage('userInfo')
  const workbook = new Workbook()
  workbook.creator = username
  workbook.lastModifiedBy = username
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()
  workbook.properties.date1904 = true
  workbook.calcProperties.fullCalcOnLoad = true

  const sheet = workbook.addWorksheet('My Sheet')

  const width = getWidth([titleList, ...data])
  sheet.columns = titleList.map((title, index) => {
    return {
      header: title,
      width: width[index],
      style: {
        alignment: {
          vertical: 'top',
          horizontal: 'center'
        }
      }
    }
  })
  const sheetData = await valueFactory(data)
  const includeImageRowIndex = []
  sheet.addRows(
    sheetData.map((items, rowIndex) => {
      return items.map((item, colIndex) => {
        if (item.type === 'image') {
          const { width, height } = getBase64Size(item.value)
          includeImageRowIndex.push({ rowIndex, width, height })
          const imageId = workbook.addImage({
            base64: item.value,
            extension: 'png'
          })
          sheet.addImage(imageId, {
            tl: { col: colIndex, row: rowIndex + 1 },
            ext: { width, height }
          })
          return ''
        }
        return item.value
      })
    })
  )

  for (let i = 0; i < includeImageRowIndex.length; i++) {
    const item = includeImageRowIndex[i]
    sheet.getRow(item.rowIndex + 2).height = item.height * 0.75
  }
  await workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    window.URL.revokeObjectURL(link.href)
  })
}

function getBase64Size(base64) {
  //确认处理的是png格式的数据
  if (base64.substring(0, 22) === 'data:image/png;base64,') {
    // base64 是用四个字符来表示3个字节
    // 我们只需要截取base64前32个字符(不计开头那22个字符)便可（24 / 3 * 4）
    // 这里的data包含12个字符，9个字节，除去第1个字节，后面8个字节就是我们想要的宽度和高度
    const data = base64.substring(22 + 20, 22 + 32)
    const base64Characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
    const nums = []
    for (const c of data) {
      nums.push(base64Characters.indexOf(c))
    }
    const bytes = []
    for (let i = 0; i < nums.length; i += 4) {
      bytes.push((nums[i] << 2) + (nums[i + 1] >> 4))
      bytes.push(((nums[i + 1] & 15) << 4) + (nums[i + 2] >> 2))
      bytes.push(((nums[i + 2] & 3) << 6) + nums[i + 3])
    }
    const width = (bytes[1] << 24) + (bytes[2] << 16) + (bytes[3] << 8) + bytes[4]
    const height = (bytes[5] << 24) + (bytes[6] << 16) + (bytes[7] << 8) + bytes[8]
    return {
      width: width / 4,
      height: height / 4
    }
  }
  throw Error('unsupported image type')
}
