<template>
  <div v-if="!disabled" class="Upload" @click="handleClick">
    <slot />
    <input
      ref="input"
      class="el-upload__input"
      type="file"
      :name="name"
      :multiple="multiple"
      :accept="accept"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'file'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null
    },
    fileList: {
      type: Array,
      default: () => []
    },
    upload: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$refs.input.value = null
        this.$refs.input.click()
      }
    },
    handleChange(ev) {
      const files = ev.target.files
      if (!files) return
      this.uploadFiles(files)
    },
    uploadFiles(files) {
      if (this.limit && this.fileList.length + files.length > this.limit) {
        this.onExceed && this.onExceed(files, this.fileList)
        this.$message({ message: `文件超限，最多可以上传${this.limit}个文件`, type: 'warning' })
        return
      }
      let postFiles = Array.prototype.slice.call(files)
      if (!this.multiple) {
        postFiles = postFiles.slice(0, 1)
      }
      if (postFiles.length === 0) {
        return
      }
      postFiles.forEach((rawFile) => {
        this.upload(rawFile)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Upload {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
}
</style>
