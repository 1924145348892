<template>
  <div class="subsidiesSetting">
    <TablePro
      ref="tableProRef"
      :actions="[]"
      :columns="columns"
      :request="requestList">
      <template #actionArea>
        <PopupForm
          ref="popupFormRef"
          popup-type="dialog"
          :columns="formColumns"
          :on-submit="onSubmit">
          <template #trigger="{ onShow }">
            <el-button
              type="primary"
              @click="
                onShow({
                  ascriptionModular: 65
                })
              ">
              新增
            </el-button>
          </template>
        </PopupForm>
      </template>
    </TablePro>
  </div>
</template>

<script>
import TablePro, { render } from '@/components/TablePro'
import PopupForm from '@/components/FormPro/PopupForm'
import {
  bpmtemplatepageListpage,
  bpmtemplatepageAdd,
  bpmtemplatepageUpdate
} from '@/api/bpmManage'

export default {
  components: {
    TablePro,
    PopupForm
  },
  data() {
    const columns = [
      {
        title: '系统平台',
        name: 'systemCode',
        search: true
      },
      { title: '表单流程编码', name: 'fdFormTemplateCode', search: true },
      { title: '表单流程名称', name: 'fdFormTemplateName', search: true },
      { title: '页面名称', name: 'pageName' },
      {
        title: '页面版本',
        name: 'pageVersion',
        search: true
      },
      { title: '页面地址', name: 'pageUrl' },
      {
        title: '操作',
        name: '',
        render: render((h, context) => {
          return (
            <div>
              <el-button
                type="text"
                onClick={() => {
                  console.log(context.data)
                  this.$refs.popupFormRef.onShow(context.data)
                }}>
                编辑
              </el-button>
            </div>
          )
        })
      }
    ]
    const formColumns = [
      {
        title: '系统平台',
        name: 'systemCode',
        fieldProps: {
          required: true
        }
      },
      {
        title: '表单流程编码',
        name: 'fdFormTemplateCode',
        fieldProps: {
          required: true
        }
      },
      {
        title: '表单流程名称',
        name: 'fdFormTemplateName',
        fieldProps: {
          required: true
        }
      },
      {
        title: '页面名称',
        name: 'pageName',
        fieldProps: {
          required: true
        }
      },
      {
        title: '页面版本',
        name: 'pageVersion',
        fieldProps: {
          required: true
        }
      },
      {
        title: '页面地址',
        name: 'pageUrl',
        fieldProps: {
          required: true
        }
      }
    ]
    return {
      columns,
      formColumns
    }
  },
  methods: {
    requestList(params) {
      return bpmtemplatepageListpage(params)
    },
    onSubmit(params) {
      const submitApi = params.id ? bpmtemplatepageUpdate : bpmtemplatepageAdd
      submitApi(params).then(() => {
        this.$message.success('操作成功')
        this.$refs.tableProRef.onRefreshTableList()
      })

      return true
    }
  }
}
</script>
