<template>
  <div class="subsidiesLimit">
    <TablePro
      ref="tableProRef"
      :columns="columns"
      :request="requestList"
      :on-delete-batches="onDeleteBatches"
      :actions="['export']"
      auto-width-cell
    >
      <template slot="actionArea">
        <el-button type="warning" @click="() => $refs.file.click()">
          导入初始补贴数据
          <input
            ref="file"
            hidden
            type="file"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="onChangeFile"
          >
        </el-button>
        <el-button @click="onExportTemplate">导出模板</el-button>
      </template>
    </TablePro>
    <el-dialog
      title="调整额度"
      :visible.sync="amountEdit.visible"
      max-width="1200px"
    >
      <el-form ref="amountEditFormRef" :model="amountEdit.form">
        <requiredFormItemSelect
          v-model="amountEdit.type"
          label="调整类型"
          :data-list="['增加', '减少']"
        />
        <requiredFormItem prop="adjustAmount" label="调整额度">
          <el-input-number
            v-model="amountEdit.form.adjustAmount"
            :step="1"
            :precision="2"
            controls-position="right"
            :min="0"
          />
        </requiredFormItem>
        <requiredFormItemInput
          v-model="amountEdit.form.remark"
          prop="remark"
          type="textarea"
          :rows="2"
          label="备注调整原因"
        />
      </el-form>
      <template #footer>
        <el-button @click="() => (amountEdit.visible = false)">取消</el-button>
        <el-button
          v-loading-click="onAmountEdit"
          type="primary"
        >提交</el-button>
      </template>
    </el-dialog>
    <el-dialog title="查看流程" :visible.sync="flowTable.visible" width="80%">
      <TablePro
        ref="flowTableProRef"
        max-height="800"
        hide-pagination
        :actions="[]"
        :auto-height="false"
        :columns="flowTable.columns"
        :request="flowTable.requestList.bind(flowTable)"
        auto-width-cell
      />
    </el-dialog>
    <el-dialog :visible.sync="flowTableDetail.visible" width="80%">
      <TablePro
        max-height="800"
        hide-pagination
        :actions="[]"
        :auto-height="false"
        :default-data-list="flowTableDetail.data"
        :columns="flowTableDetail.columns"
        auto-width-cell
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePro, { render } from '@/components/TablePro'
import requiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import requiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import requiredFormItem from '@/components/CustomFormItem/requiredFormItem'
import * as advSubsidyApi from './apis/advSubsidy'
import deepCopy from '@/utils/deepCopy'

const amountEditDefault = {
  visible: false,
  type: '增加',
  form: {
    remark: '',
    adjustAmount: '',
    licenseNumber: '',
    authorizedCityCode: '',
    createUserName: ''
  }
}

export default {
  components: {
    TablePro,
    requiredFormItem,
    requiredFormItemSelect,
    requiredFormItemInput
  },
  data() {
    const columns = [
      { title: '省份', name: 'authorizedProvinceName', search: true },
      { title: '售达方编号', name: 'licenseNumber', search: true },
      { title: '售达方描述', name: 'businessLicenseName' },
      { title: '实控人', name: 'bossName', search: true },
      { title: '授权城市', name: 'authorizedCityName', search: true },
      { title: '授权城市编号', name: 'authorizedCityCode', search: true },
      { title: '城市级别', name: 'marketLevelName' },
      { title: '品牌', name: 'brandName', search: true, hideInTable: true },
      { title: '额度', name: 'amount' },
      {
        title: '额度调整',
        width: 110,
        hideInConfirmTable: true,
        render: render((h, context) => (
          <el-button
            onClick={() => {
              this.amountEdit.visible = true
              this.amountEdit.form.licenseNumber = context.data.licenseNumber
              this.amountEdit.form.authorizedCityCode =
                context.data.authorizedCityCode
              this.amountEdit.form.createUserName = this.userInfo.realName
            }}
          >
            调整
          </el-button>
        ))
      },
      {
        title: '查询抵扣流程',
        width: 110,
        hideInConfirmTable: true,
        render: render((h, context) => (
          <el-button
            onClick={async() => {
              this.flowTable.licenseNumber = context.data.licenseNumber
              this.flowTable.authorizedCityCode =
                context.data.authorizedCityCode
              this.flowTable.visible = true
            }}
          >
            查看流程
          </el-button>
        ))
      }
    ]

    const flowTableDefault = {
      visible: false,
      licenseNumber: '',
      authorizedCityCode: '',
      async requestList(params) {
        params.licenseNumber = this.licenseNumber
        params.authorizedCityCode = this.authorizedCityCode
        const { data } = await advSubsidyApi.getDeclarationList(params)
        return { data, totalCount: data.length }
      },
      columns: [
        { title: '流程名称', name: 'title' },
        { title: '流程单号', name: 'orderNumber' },
        { title: '实际扣减金额', name: 'adjustAmount' },
        // {
        //   title: '广告投放起止时间',
        //   name: 'advertisingStartTime',
        //   renderText: (text, { advertisingStartTime, advertisingEndTime }) =>
        //     `${advertisingStartTime || ''}-${advertisingEndTime || ''}`
        // },
        { title: '提单时间', name: 'optTime' },
        { title: '归档时间', name: 'archiveTime' },
        {
          title: '操作',
          width: 120,
          render: render((h, context) => {
            return (
              context.data.declarationFormData && (
                <el-button
                  onClick={() => {
                    this.flowTableDetail.visible = true
                    this.flowTableDetail.data =
                      JSON.parse(context.data.declarationFormData)
                        .adverSubsidyDt1DtoList || []
                  }}
                >
                  查看详情
                </el-button>
              )
            )
          })
        }
      ]
    }

    const flowTableDetailDefault = {
      visible: false,
      columns: [
        { title: '广告类型', name: 'gglxnew1' },
        { title: '广告类型说明', name: 'ggsmnew1' },
        { title: '申请理由', name: 'sqly' },
        { title: '广告位置/商场名称', name: 'weizhi' },
        { title: '广告尺寸', name: 'chicun' },
        { title: '数量', name: 'ggsl' },
        { title: '计划投入金额', name: 'touru' },
        {
          title: '此广告是否开发票',
          name: 'sfhs',
          valueEnum: [
            { value: '0', label: '开发票' },
            { value: '1', label: '开收据' }
          ]
        },
        {
          title: '广告投放起止时间',
          name: 'ggfbsj',
          renderText: (text, data) => `${data.kssj || ''}-${data.jssj || ''}`
        },
        {
          title: '是否续约',
          name: 'sfxy',
          valueEnum: [
            { value: '0', label: '续约' },
            { value: '1', label: '非续约' }
          ]
        },
        { title: '广告租赁方信息（公司名称、联系人、联系电话）', name: 'gsxx' },
        { title: '审批比例', name: 'yjbtbl' },
        { title: '预计补贴金额', name: 'yjbtje' },
        {
          title: '商场外墙等级',
          name: 'scggdj',
          valueEnum: [
            { value: '0', label: 'A+' },
            { value: '1', label: 'A' },
            { value: '2', label: 'B' },
            { value: '3', label: 'C' },
            { value: '4', label: '无' }
          ]
        }
      ],
      data: []
    }

    return {
      columns,
      amountEdit: deepCopy(amountEditDefault),
      flowTable: flowTableDefault,
      flowTableDetail: deepCopy(flowTableDetailDefault)
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    'amountEdit.visible'(flag) {
      if (!flag) {
        this.amountEdit.form = JSON.parse(JSON.stringify(amountEditDefault))
      }
    },
    'flowTable.visible'(flag) {
      this.$nextTick(() => {
        if (!flag) {
          this.$refs.flowTableProRef.onResetTableList()
        } else {
          this.$refs.flowTableProRef.onRefreshTableList(1)
        }
      })
    }
  },
  methods: {
    async onAmountEdit() {
      await new Promise((resolve, reject) =>
        this.$refs.amountEditFormRef.validate(valid => {
          valid ? resolve() : reject()
        })
      )
      const form = { ...this.amountEdit.form }
      const isAdd = this.amountEdit.type === '增加'
      if (!isAdd) {
        form.adjustAmount = `-${form.adjustAmount}`
      }
      await advSubsidyApi.adjustAmount(form)
      this.$message.success('调整成功')
      this.$refs.tableProRef.onRefreshTableList()
      this.amountEdit.visible = false
    },
    async onChangeFile({ target: { files }}) {
      const formData = new FormData()
      formData.append('file', files[0])
      await advSubsidyApi.importData(formData)
      this.$message.success('导入成功')
      this.$refs.tableProRef.onRefreshTableList(1)
    },
    onExportTemplate() {
      advSubsidyApi.exportTemplate()
    },
    async onDeleteBatches(list) {
      // 批量删除
      const ids = list.map(item => item.id)
      await advSubsidyApi.deleteData(ids)
      this.$refs.tableProRef.onRefreshTableList()
    },
    requestList(params) {
      return advSubsidyApi.getDataList(params)
    }
  }
}
</script>
