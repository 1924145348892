<template>
  <div class="CommonUpload-File-Text-List-Container">
    <transition-group name="list" tag="div" class="CommonUpload-File-List">
      <div
        v-for="(fileItem, fileIndex) of fileList"
        :key="fileItem.uid"
        class="CommonUpload-File-Item"
        :class="[fileItem.status]"
      >
        <i v-if="fileItem.status === 'uploading'" class="el-icon-loading" />
        <i v-else class="el-icon-paperclip" />
        <!-- <img :src="fileItem.thumbUrl || fileItem.url"> -->
        <el-tooltip
          effect="light"
          :content="fileItem.status === 'error' ? fileItem.response :''"
          :disabled="fileItem.status !== 'error' || !fileItem.response"
        >
          <a
            target="_blank"
            :href="fileItem.url"
            class="CommonUpload-File-Name"
          >{{ fileItem.name }}</a>
        </el-tooltip>
        <div v-if="!disabled" class="CommonUpload-File-Actions">
          <!-- <i class="el-icon-delete" /> -->
          <el-button
            icon="el-icon-delete"
            size="mini"
            type="danger"
            plain
            @click="onDelete(fileItem, fileIndex)"
          />
        </div>
        <div
          v-if="fileItem.status === 'uploading'"
          class="CommonUpload-File-Upload-Progress"
        >
          <el-progress
            :percentage="fileItem.percent"
            :show-text="false"
            :stroke-width="2"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    async onDelete(file, index) {
      this.$emit('delete', file, index)
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/colors';

.CommonUpload-File-Text-List-Container {
  .CommonUpload-File-List {
  }
  .CommonUpload-File-Item {
    margin-top: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    position: relative;
    color: $primary-color;
    transition: background-color 0.3s, opacity 0.3s, transform 0.3s;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      .CommonUpload-File-Actions {
        .el-button--mini {
          opacity: 1;
        }
      }
    }
    .CommonUpload-File-Name {
      flex: auto;
      padding: 0 8px;
      line-height: 25px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .CommonUpload-File-Actions {
      .el-button--mini {
        opacity: 0;
        padding: 0;
        line-height: 25px;
        box-sizing: border-box;
        height: 25px;
        width: 25px;
        display: block;
        border: 0;
      }
    }
    &.error {
      color: $danger-dark-color;
      .CommonUpload-File-Actions {
        .el-button--mini {
          opacity: 1;
        }
      }
    }
    .CommonUpload-File-Upload-Progress {
      position: absolute;
      left: 23px;
      right: 33px;
      bottom: 0;
    }
  }
  .list-enter{
    opacity: 0;
    transform: translateY(-10px);
  }
  .list-leave-to{
    opacity: 0;
    transform: translateY(-10px);
  }
  .list-leave-active {
    position: absolute;
  }
}
</style>
