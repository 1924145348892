/**
 * 重装申请
 */
import http from "@/utils/request";

// 门店列表
export const shopBrand = params => {
  return http({
    url: '/dealermanage/shop/bossAuthShopList',
    params
  })
}

// 查询售达方和老板信息
export const submit = data => {
  return http({
    url: "/dealermanage/shopDecorate/submitShopDecorate",
    method: "POST",
    data
  });
};
// 查询流程表单
export const detail = params => {
  return http({
    url: "/dealermanage/shopDecorate/getDecorateInfo",
    params
  });
};
