import {
  validUserId as user_id,
  validName as name,
  validPhone as phone,
  validEmail as email,
  validCreditCode as credit_code,
  validbankAccount as bank_account,
  validMoney as money,
  validQQ as qq,
  validQQOrEmail as qq_email
} from '@/utils/useFormValidator'

export default {
  user_id,
  name,
  phone,
  email,
  credit_code,
  bank_account,
  money,
  qq,
  qq_email,
  age: (rule, value, callback) => {
    if (!/^((1[0-5])|[1-9])?\d$/.test(value)) {
      return callback(new Error('年龄为0-159的整数'))
    }
    callback()
  },
  number: (rule, value, callback) => {
    if (!/^[0-9]+\.?[0-9]*$/.test(value)) {
      return callback(new Error('请填写正确数字'))
    }
    callback()
  },
  int: (rule, value, callback) => {
    if (!/^\d+$/.test(value)) {
      return callback(new Error('请填写正确整数'))
    }
    callback()
  },

  // 手机号唯一校验1,校验实控人电话是否被占用(docs: http://10.12.0.64/docs/operate/operate-1dctbfo0q90ht)
  check_boss_number: async(rule, value, callback) => {
    const { checkBossPhone } = require('@/api/common')
    try {
      await checkBossPhone({
        mobilePhone: value
      }).then(res => {
        if (res.data && this.disabled) {
        // 异步完成,如果该表单项被禁用,则不返回错误
          callback(new Error('该手机号码被占用'))
        } else {
          callback()
        }
      })
    } catch {
      callback()
    }
  },
  // 身份证唯一校验1,校验实控人身份证是否存在
  check_boss_id_card: async(rule, value, callback) => {
    const { getBossDetail } = require('@/api/businessOos')
    try {
      const { data } = await getBossDetail({
        id_card: value
      })
      const err = data.length === 0 ? null : new Error('该身份证被占用')
      if (this.disabled) {
        // 异步完成,如果该表单项被禁用,则不返回错误
        callback()
      } else {
        callback(err)
      }
    } catch {
      callback()
    }
  }
}
