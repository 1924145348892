<template>
  <div>
    <el-input placeholder="请输入计划责任人名称" v-model="filterText">
    </el-input>
    <el-tree
      class="treeContainer"
      :data="data"
      show-checkbox
      node-key="id"
      :default-expanded-keys="value"
      :props="defaultProps"
      @check="handleCurrentChage"
      :filter-node-method="filterNode"
      ref="tree"
    >
    </el-tree>
  </div>
</template>

<script>

export default {
  data() {
    return {
      // data: [],
      defaultProps: {
        children: 'userList',
        label: 'label'
      },
      filterText: ''
    }
  },
  // mounted() {
  //   this.getTreeData()
  // },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        // this.$emit('handleChange', this.filterItem(value))
        this.$nextTick(() => {
          // console.log(value);
          this.$refs.tree.setCheckedKeys(value)
        })
      }
    }
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // filterItem(ids) {
    //   const personNameList = []
    //   this.data.forEach(item => {
    //     if (item.userList.filter(list => ids.includes(list.id)).length > 0) {
    //       const filterList = item.userList.filter(list => ids.includes(list.id))
    //       personNameList.push(...filterList.map(v => v.userName))
    //     }
    //   })
    //   return personNameList
    // },
    handleCurrentChage() {
      const list = this.$refs.tree.getCheckedKeys().filter(item => !!item)
      this.$emit('changeValue', list)
      // this.$emit('handleChange', this.filterItem(list))
    }
  }
}
</script>

<style lang="scss" scoped>
.treeContainer {
  height: 400px;
  overflow-y: auto;
}
</style>
