<template>
  <FlowForm
    ref="flowFormRef"
    :model="form"
    :get-detail="getDetail"
    :on-submit="onSubmit"
    :show-save="false"
    title="海外售达方数据录入"
  >
    <HeaderTitle style="margin: 20px 0 10px" title="实控人信息" />
    <RequiredFormItemInput
      v-model="form.bossNumber"
      prop="bossNumber"
      class="formItem"
      label="实控人编码"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      v-model="form.realName"
      prop="realName"
      class="formItem"
      label="实控人描述"
      placeholder="文本（实控人名称）"
    />
    <RequiredFormItemInput
      v-model="form.idCard"
      prop="idCard"
      class="formItem"
      label="实控人身份证"
    />
    <!-- placeholder="数字文本（若未填写则系统默认给出）" -->
    <RequiredModuleFormItemSelect
      v-model="form.sex"
      prop="sex"
      class="formItem"
      label="实控人性别"
      type="sex"
    />
    <RequiredFormItemInput
      v-model="form.contactInfo"
      prop="contactInfo"
      class="formItem"
      label="实控人联系电话"
    />

    <HeaderTitle style="margin: 20px 0 10px" title="经销商信息" />
    <RequiredFormItemInput
      v-model="form.dealerCode"
      prop="dealerCode"
      class="formItem"
      label="经销商编号"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      v-model="form.dealerName"
      prop="dealerName"
      class="formItem"
      label="经销商名称"
      placeholder="归档后生成"
      disabled
    />
    <HeaderTitle style="margin: 20px 0 10px" title="执照信息" />
    <RequiredFormItemInput
      v-model="form.licenseNumber"
      prop="licenseNumber"
      class="formItem"
      label="售达方编号"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      v-model="form.businessLicenseName"
      prop="businessLicenseName"
      class="formItem"
      label="售达方描述"
      placeholder="文本（营业执照名称）"
    />
    <RequiredFormItemInput
      v-model="form.businessLicenseShortName"
      prop="businessLicenseShortName"
      class="formItem"
      label="售达方简称"
    />
    <RequiredFormItemInput
      v-model="form.creditCode"
      prop="creditCode"
      class="formItem"
      label="执照代码"
    />
    <RequiredFormItemInput
      v-model="form.legalPersonName"
      prop="legalPersonName"
      class="formItem"
      label="法人姓名"
    />
    <!-- placeholder="文本（若未填写则系统默认给出）" -->
    <RequiredFormItemInput
      v-model="form.businessLicenseAddress"
      prop="businessLicenseAddress"
      class="formItem"
      label="执照地址"
    />
    <RequiredFormItemInput
      v-model="form.postCode"
      prop="postCode"
      class="formItem"
      label="邮政编码"
    />
    <RequiredFormItemInput
      v-model="form.contactPersonName"
      prop="contactPersonName"
      class="formItem"
      label="联系人"
    />
    <RequiredFormItemInput
      v-model="form.contactPersonPhone"
      prop="contactPersonPhone"
      class="formItem"
      label="联系电话"
    />
    <RequiredFormItemInput
      v-model="form.eMail"
      prop="eMail"
      class="formItem"
      label="负责人邮箱"
    />
    <RequiredModuleFormItemSelect
      v-model="form.tradingItem"
      prop="tradingItem"
      class="formItem"
      label="交易条款"
      type="exchange_provision"
      @changeSelectedItem="e => (form.tradingItemS = e.content)"
    />
    <RequiredModuleFormItemSelect
      v-model="form.paymentCondition"
      prop="paymentCondition"
      type="settlement_type"
      class="formItem"
      label="付款方式"
      @changeSelectedItem="e => (form.paymentConditionS = e.content)"
    />
    <RequiredFormItemInput
      v-model="form.destinationPort"
      prop="destinationPort"
      class="formItem"
      label="出货目的港"
      :required="false"
    />
    <RequiredFormItemInput
      v-model="form.shippingCounterpart"
      prop="shippingCounterpart"
      class="formItem"
      label="船务对接人"
      :required="false"
    />
    <RequiredFormItemInput
      v-model="form.shippingCounterpartEmail"
      prop="shippingCounterpartEmail"
      class="formItem"
      label="船务对接人邮箱"
      :required="false"
    />
    <RequiredModuleFormItemSelect
      v-model="form.distributionChannelCode"
      prop="distributionChannelCode"
      type="cooperation_code"
      class="formItem"
      label="销售渠道"
      @changeSelectedItem="e => (form.distributionChannelName = e.content)"
    />
    <RequiredModuleFormItemSelect
      v-model="form.salesDepartmentCode"
      prop="salesDepartmentCode"
      type="overseas_department"
      class="formItem"
      label="销售部门"
      @changeSelectedItem="e => (form.salesDepartmentName = e.content)"
    />
    <RequiredModuleFormItemSelect
      v-model="form.customerLevelCode"
      prop="customerLevelCode"
      type="client_grade"
      class="formItem"
      label="客户等级"
      :required="false"
      @changeSelectedItem="e => (form.customerLevelName = e.content)"
    />
    <!-- <RequiredFormItemInput class="formItem" label="销售额度" /> -->
    <RequiredModuleFormItemSelect
      v-model="form.productGradeCode"
      prop="productGradeCode"
      type="product_level"
      class="formItem"
      label="产品档次"
      :required="false"
      @changeSelectedItem="e => (form.productGradeName = e.content)"
    />

    <RequiredModuleFormItemSelect
      v-for="(label, key) in formLayout.commonSelect[0]"
      :key="key"
      v-model="form[key]"
      :prop="key"
      type="flow_common_select_ifEnable"
      class="formItem"
      :label="label"
      :required="false"
    />
    <RequiredFormItemInput
      v-for="(label, key) in formLayout.commonInput[0]"
      :key="key"
      v-model="form[key]"
      :prop="key"
      class="formItem"
      :label="label"
      :required="false"
    />
    <RequiredFormItemDatePicker
      v-model="form.authorizeTime"
      prop="authorizeTime"
      class="formItem"
      label="授权时间"
    />
    <RequiredModuleFormItemSelect
      v-model="form.customerOriginCode"
      prop="customerOriginCode"
      type="dealer_source"
      class="formItem"
      label="客户来源"
      @changeSelectedItem="e => (form.customerOriginName = e.content)"
    />
    <!-- <RequiredModuleFormItemSelect
      v-model="form.customerStatusCode"
      prop="customerStatusCode"
      type="distributor_state_code"
      class="formItem"
      label="客户状态"
      @changeSelectedItem="e => (form.customerStatusName = e.content)"
    />
    <RequiredModuleFormItemSelect
      v-model="form.stopCooperationReasonCode"
      prop="stopCooperationReasonCode"
      type="cooperation_end_reason"
      class="formItem"
      label="终止合作原因"
      @changeSelectedItem="e => (form.stopCooperationReasonName = e.content)"
    />
    <RequiredFormItemDatePicker
      v-model="form.stopCooperationTime"
      prop="stopCooperationTime"
      class="formItem"
      label="终止合作时间"
    /> -->
    <!-- <RequiredFormItemSelect
      ref="applyBrandListRef"
      :value="form.applyBrandList.map(item => item.code)"
      class="formItem"
      label-key="name"
      value-key="code"
      label="请选择系列"
      multiple
      :get-data-list="getBrandBrandList"
      @change="changeApplyBrandListSelectedItem"
    /> -->
    <RequiredModuleFormItemSelect
      v-model="form.countryCode"
      prop="countryCode"
      module="public"
      type="country"
      class="formItem"
      label="国家"
      @change="
        () => {
          form.firstAdministrativeRegionCode = ''
          form.firstAdministrativeRegionName = ''
          form.secondAdministrativeRegionCode = ''
          form.secondAdministrativeRegionName = ''
        }
      "
    />
    <RequiredModuleFormItemSelect
      v-model="form.firstAdministrativeRegionCode"
      module="public"
      type="province"
      :parent-code="form.countryCode"
      dependent-parent-code
      class="formItem"
      label="一级行政区"
      empty-disabled
      @change="
        () => {
          form.secondAdministrativeRegionCode = ''
          form.secondAdministrativeRegionName = ''
        }
      "
      @changeSelectedItem="
        e => (form.firstAdministrativeRegionName = e ? e.content : '')
      "
    />
    <RequiredModuleFormItemSelect
      v-model="form.secondAdministrativeRegionCode"
      module="public"
      type="city"
      :parent-code="form.firstAdministrativeRegionCode"
      dependent-parent-code
      class="formItem"
      label="二级行政区"
      empty-disabled
      :required="false"
      @changeSelectedItem="
        e => (form.secondAdministrativeRegionName = e ? e.content : '')
      "
    />
    <HeaderTitle style="margin: 20px 0 10px" title="授权信息" />
    <RequiredFormItemSelect
      ref="shopRef"
      v-model="form.authorizedCityCode"
      class="formItem"
      prop="authorizedCityCode"
      label="授权城市"
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      filterable
      remote
      :get-data-list="getCitylist"
      :get-next-list="getCitylist"
      @onOptionsListNotFound="onNotFoundCityList"
      @changeSelectedItem="
        e => {
          form.authorizedCityName = e ? e.authorizedCityName : ''
        }
      "
    />
    <RequiredFormItemSelect
      ref="applySeriesListRef"
      :value="form.applySeriesList.map(item => item.code)"
      class="formItem"
      label-key="name"
      value-key="code"
      label="授权系列"
      multiple
      :get-data-list="getBrandseriesList"
      @change="changeApplySeriesListSelectedItem"
    />
  </FlowForm>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FlowForm from './components/FlowForm'
import HeaderTitle from '@/views/flow/components/headerTitle'

import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'

import * as dealerJoinApi from '@/api/attractPlatform/dealerJoinApi'
import { authorizedcityListpage } from '@/api/businessOos'
import { brandseriesList } from '@/api/common'
import { saveSoldToParty } from './overseasDataApi'

export default {
  components: {
    FlowForm,
    HeaderTitle,
    RequiredFormItemInput,
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker
  },
  data() {
    return {
      form: {
        bossNumber: '', //	否	strng	实控人编号
        realName: '', //	是	strng	真实姓名
        idCard: '', //	是	strng	身份证
        dealerCode: '', //	否	strng	经销商编号
        dealerName: '', //	否	strng	经销商名称
        licenseNumber: '', //	否	strng	售达方编号
        businessLicenseName: '', //	是	strng	营业执照名称
        businessLicenseShortName: '', //	是	strng	售达方简称
        creditCode: '', //	是	strng	执照代码(社会统一信用代码)
        legalPersonName: '', // 是 string 法人姓名
        businessLicenseAddress: '', //	是	strng	执照地址
        postCode: '', //	是	strng	邮政编码
        contactPersonName: '', //	是	strng	联系人
        contactPersonPhone: '', //	是	strng	联系电话
        eMail: '', //	是	strng	负责人邮箱
        tradingItem: '', //	是	strng	交易条款编码
        tradingItemS: '', //	是	strng	交易条款 中文名称
        paymentCondition: '', //	是	strng	付款方式 编码
        paymentConditionS: '', //	是	strng	付款方式 中文名称
        destinationPort: '', //	是	strng	出货目的港
        shippingCounterpart: '', //	是	strng	船务对接人
        shippingCounterpartEmail: '', //	是	strng	船务对接人邮箱
        distributionChannelCode: '', //	是	strng	销售渠道编码
        distributionChannelName: '', //	是	strng	销售渠道 中文名称
        salesDepartmentCode: '', //	是	strng	销售部门编码
        salesDepartmentName: '', //	是	strng	销售部门 中文名称
        productGradeCode: '', //	是	strng	产品档次编码
        productGradeName: '', //	是	strng	产品档次 中文名称
        invoicePackingListOriginalFlag: '', //	是	strng	发票箱单是否正本 1是 0否
        billOfLadingOriginalFlag: '', //	是	strng	提单是否正本 1是 0否
        stiflingFlag: '', //	是	strng	是否需要熏蒸 1是 0否
        certificateOfOriginFlag: '', //	是	strng	是否需要产地证 1是 0否
        certificateOfOriginOriginalFlag: '', //	是	strng	产地证是否正本 1是 0否
        receiveCompanyName: '', //	是	strng	收货公司名称
        receiveCompanyAddress: '', //	是	strng	收货方地址
        consigneeName: '', //	是	strng	收货方姓名
        consigneeContact: '', //	是	strng	收货方联系方式
        consigneeEmail: '', //	是	strng	收货方邮箱
        notifyPartyCompanyName: '', //	是	strng	通知方公司名称
        notifyPartyAddress: '', //	是	strng	通知方地址
        notifyPartyName: '', //	是	strng	通知方姓名
        notifyPartyContact: '', //	是	strng	通知方联系方式
        notifyPartyEmail: '', //	是	strng	通知方邮箱
        customerOriginCode: '', //	是	strng	客户来源编码
        customerOriginName: '', //	是	strng	客户来源 中文名称
        customerStatusCode: '', //	是	strng	客户状态编码
        customerStatusName: '', //	是	strng	客户状态 中文名称
        stopCooperationReasonCode: '', //	是	strng	终止合作原因编码
        stopCooperationReasonName: '', //	是	strng	终止合作原因 中文名称
        stopCooperationTime: '', //	是	strng	终止合作时间
        firstAdministrativeRegionCode: '', //	是	strng	一级行政区域编码
        firstAdministrativeRegionName: '', //	是	strng	一级行政区域 中文名称
        secondAdministrativeRegionCode: '', //	是	strng	二级行政区域编码
        secondAdministrativeRegionName: '', //	是	strng	二级行政区域 中文名称
        countryCode: '', //	是	strng	国家编码
        createUsername: '', //	是	strng	创建人名字
        createUserId: '', //	是	strng	创建人id
        // applyBrandList: [], //	是	数组对象	大品牌列表
        applySeriesList: [], // 授权系列
        authorizeTime: '', // 授权时间
        customerLevelCode: '', // 客户等级编码
        customerLevelName: '', // 客户等级编码 中文名称
        sex: '', // 性别
        contactInfo: '' // 联系方式
      },
      formLayout: {
        commonInput: [
          {
            receiveCompanyName: '收货公司名称',
            receiveCompanyAddress: '收货方地址',
            consigneeName: '收货方姓名',
            consigneeContact: '收货方联系方式',
            consigneeEmail: '收货方邮箱',
            notifyPartyCompanyName: '通知方公司名称',
            notifyPartyAddress: '通知方地址',
            notifyPartyName: '通知方姓名',
            notifyPartyContact: '通知方联系方式',
            notifyPartyEmail: '通知方邮箱'
          }
        ],
        commonSelect: [
          {
            invoicePackingListOriginalFlag: '发票箱单是否正本',
            billOfLadingOriginalFlag: '提单是否正本',
            stiflingFlag: '是否需要熏蒸',
            certificateOfOriginFlag: '是否需要产地证',
            certificateOfOriginOriginalFlag: '产地证是否正本'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState({ isOA: state => state.dealershipTransfer.isOA })
  },
  methods: {
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await dealerJoinApi.detail({
          formId: this.$route.query.id
        })
        initFormData = data
      }
      if (initFormData) {
        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
      }
      if (this.userInfo && !this.isOA) {
        const { createUserId, createUserName, ...rest } = this.form || {}
        this.form = {
          ...rest,
          createUserId: createUserId || this.userInfo.accountId,
          createUserName: createUserName || this.userInfo.realName
        }
      }
    },
    getBrandseriesList(params) {
      return brandseriesList({
        brandLevel: 1,
        code: '24'
      }).then(res => res.data)
    },
    // getBrandBrandList(params) {
    //   return brandseriesList({
    //     brandLevel: 0,
    //     code: 'ZZ'
    //   }).then(res => res.data)
    // },
    // changeApplyBrandListSelectedItem() {
    //   this.$nextTick(() => {
    //     const selectItems =
    //       this.$refs.applyBrandListRef.$selectRef.selectedItem || []
    //     console.log(selectItems)
    //     this.form.applyBrandList = selectItems.map(item => ({
    //       id: item.id,
    //       code: item.code,
    //       name: item.name
    //     }))
    //   })
    // },
    changeApplySeriesListSelectedItem() {
      this.$nextTick(() => {
        const selectItems =
          this.$refs.applySeriesListRef.$selectRef.selectedItem || []
        this.form.applySeriesList = selectItems.map(item => ({
          id: item.id,
          code: item.code,
          name: item.name
        }))
      })
    },
    getCitylist({ page: currPage, searchText: authorizedCityName }) {
      return authorizedcityListpage({
        currPage,
        ifEnabled: 0,
        addr: authorizedCityName,
        overseasFlag: 1,
        pageSize: 10
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundCityList({ value: authorizedCityCode }, callback) {
      callback(
        await authorizedcityListpage({
          authorizedCityCode,
          ifEnabled: 0
        }).then(res => res.data)
      )
    },
    async onSubmit() {
      return saveSoldToParty(this.form)
    }
  }
}
</script>

<style></style>
