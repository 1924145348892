/*
 * @Author: your name
 * @Date: 2021-03-09 17:44:32
 * @LastEditTime: 2021-03-10 17:39:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mall-derucci-admin\src\api\user\index.js
 */
import request from '@/utils/request'

export function login(params) {
  return request({
    url: '/user/accountlogin/ordinary',
    method: 'post',
    isLogin: true,
    params
  })
}

export function getInfo(params) {
  return request({
    url: '/user/account/derucci/userInfo',
    method: 'get',
    params
  })
}

export function getBusiness(params) {
  return request({
    url: '/dealermanage/businesslicense/detailsList/dealerBusinessLicenseParent',
    params
  })
}

//授权访问
export function authLogin(params) {
  return request({
    url: '/user/accountlogin/settoken',
    method: 'post',
    isLogin: true,
    params,
    headers: {
      api_client_code: params.systemCode
    }
  })
}

//授权访问
export function ssologinEntry(params) {
  return request({
    url: '/user/ssologin/entry',
    method: 'post',
    isLogin: true,
    params,
    headers: {
      api_client_code: params.systemCode
    }
  })
}

//授权访问（支持跨平台，跨角色访问系统）
export function ssologinEntryPlatform(params) {
  return request({
    url: '/user/ssologin/entryPlatform',
    method: 'post',
    isLogin: true,
    params,
    headers: {
      api_client_code: params.systemCode
    }
  })
}

//获取动态路由菜单列表
export function menuList(params) {
  return request({
    url: '/user/menu/list',
    method: 'get',
    params
  })
}

// 获取用户角色详细信息
export function userRuleList(params) {
  return request({
    url: '/user/accountrole/listdetail',
    params
  })
}

// 修改密码
export const updatePassword = params => {
  return request({
    url: '/user/account/updatePasswordById',
    method: 'post',
    params,
    headers: {
      contentType: 'application/x-www-form-urlencoded'
    }
  })
}

// 企业微信登录
export const wxworkGetuserinfo = data => {
  return request({
    url: '/user/qywechatlogin/applets',
    method: 'post',
    data
  })
}

// 授信系统动态令牌
export const accountloginTempcode = data => {
  return request({
    url: '/user/accountlogin/tempcode',
    method: 'post',
    params: data
  })
}

// 授信系统间登录
export const authorizationLogin = data => {
  return request({
    url: '/user/accountlogin/authorizationLogin',
    method: 'post',
    params: data
  })
}
