import { render, staticRenderFns } from "./testFlow.vue?vue&type=template&id=02c72ae9&scoped=true"
import script from "./testFlow.vue?vue&type=script&setup=true&lang=js"
export * from "./testFlow.vue?vue&type=script&setup=true&lang=js"
import style0 from "./testFlow.vue?vue&type=style&index=0&id=02c72ae9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02c72ae9",
  null
  
)

export default component.exports