<template>
  <el-drawer :title="taskVisibleDialog==1?'新增':'修改'"
             :visible.sync="visibleDialog"
             :before-close="beforeClose"
             direction="rtl"
             size="50%;overflow-y:auto"
             v-if="visibleDialog">
    <el-form :model="taskform"
             ref="form"
             :rules="rules"
             label-width="100%"
             :inline="true"
             style="padding:0 30px"
             class="form">

      <el-form-item label="业务对象"
                    prop="businessObject"
                    v-if="taskVisibleDialog==1">
        <el-select v-model="taskform.businessObject"
                   placeholder="请选择业务对象类型">
          <el-option label="门店"
                     value="门店">
          </el-option>
          <el-option label="经销商"
                     value="经销商">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="formItem"
                    prop="checkColumns"
                    label="检查字段">
        <el-select v-model="taskform.checkColumns"
                   multiple
                   placeholder="请选择检查字段"
                   filterable>
          <el-option v-for="(item, index) in checkColumnsList"
                     :key="index"
                     :label="item.namecn"
                     :value="item.NAME"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="业务规则"
                    prop="businessRule"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.businessRule"
                  placeholder="请输入业务规则"
                  clearable></el-input>
      </el-form-item>

      <el-form-item label="是否可运行"
                    prop="enable"
                    v-if="taskVisibleDialog==1">
        <el-select v-model="taskform.enable"
                   placeholder="请选择业务对象类型">
          <el-option label="是"
                     :value="true">
          </el-option>
          <el-option label="否"
                     :value="false">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规则名称"
                    prop="ruleName">
        <el-input v-model="taskform.ruleName"
                  placeholder="请输入规则名称"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="创建人"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.createByName"
                  placeholder="请输入创建人"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="数据标准名称"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.dataStandardName"
                  placeholder="请输入数据标准名称"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="负责人"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.principalName"
                  placeholder="请输入负责人"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="规则描述"
                    prop="ruleDescription">
        <el-input v-model="taskform.ruleDescription"
                  placeholder="请输入规则描述"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="规则维度"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.ruleDimension"
                  placeholder="请输入规则维度"
                  clearable></el-input>
      </el-form-item>

      <el-form-item label="主题域组"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.subjectFieldGroup"
                  placeholder="请输入主题域组"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="所属系统"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.systemName"
                  placeholder="请输入所属系统"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="校验SQL"
                    style="width:100%"
                    prop="checkSql">
        <el-input v-model="taskform.checkSql"
                  type="textarea"
                  placeholder="请输入校验SQL"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="备注"
                    style="width:100%"
                    v-if="taskVisibleDialog==1">
        <el-input v-model="taskform.remark"
                  type="textarea"
                  placeholder="请输入备注"
                  clearable></el-input>
      </el-form-item>
      <div>
        <el-button type="primary"
                   @click="submitForm">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>

  </el-drawer>
</template>

<script>
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import {
  dealerSqlTaskInfoSave,
  dealerSqlTaskInfoUpdate,
  dealerTableColumnInfoGetMap
} from '@/api/watchData'

import { Base64 } from 'js-base64'

const taskform = () => {
  return {
    businessObject: '',
    enable: '',
    ruleName: '',
    checkSql: '',
    businessRule: '',
    checkColumns: ''
  }
}
export default {
  components: { RequiredFormItemSelect },
  props: {
    taskVisibleDialog: {
      type: Number,
      default: 0
    },
    cancel: {
      type: Function,
      default: () => {}
    },
    taskItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules: {
        businessObject: [
          { required: true, message: '请选择业务对象', trigger: 'change' }
        ],
        enable: [
          { required: true, message: '请选择是否运行', trigger: 'change' }
        ],
        checkColumns: [
          { required: true, message: '请选择检查字段', trigger: 'blur' }
        ],
        ruleName: [
          { required: true, message: '请输入规则名称', trigger: 'blur' }
        ],
        checkSql: [{ required: true, message: '请输入SQL', trigger: 'blur' }],
        businessRule: [
          { required: true, message: '请输入业务规则', trigger: 'blur' }
        ],
        ruleDescription: [
          { required: true, message: '请输入规则描述', trigger: 'blur' }
        ]
      },
      taskform: taskform(),
      checkColumnsList: [],
      descriptRules: {}
    }
  },
  computed: {
    visibleDialog() {
      if (this.taskVisibleDialog == 1) {
        this.rules.ruleDescription = null
      } else {
        this.rules.ruleDescription = [
          { required: true, message: '请输入规则描述', trigger: 'blur' }
        ]
      }
      return !!this.taskVisibleDialog
    }
  },
  watch: {
    visibleDialog() {},
    'taskform.businessObject'(val) {
      if (!val) return
      if (this.taskVisibleDialog == 1) {
        this.taskform.checkColumns = []
        this.checkColumnsList = []
      }
      if (val == '门店') {
        this.checkType = 0
        this.getMap('dealer_shop')
      } else {
        this.checkType = 1
        this.getMap('dealer_crm_distributor')
      }
    },
    taskItem(obj) {
      this.taskform = JSON.parse(JSON.stringify(obj))
      if (this.taskVisibleDialog == 2) {
        this.taskform.checkColumns = this.taskform.checkColumns.split(';')
      }
    }
  },
  methods: {
    beforeClose() {
      this.cancel()
    },
    async getMap(tableName) {
      return dealerTableColumnInfoGetMap({ tableName }).then(res => {
        this.checkColumnsList = res.map
      })
    },
    submitForm() {
      const params = JSON.parse(JSON.stringify(this.taskform))
      params.checkColumns = params.checkColumns.join(';')
      params.checkSql = Base64.encode(params.checkSql)
      const submit =
        this.taskVisibleDialog == 1
          ? dealerSqlTaskInfoSave(params)
          : dealerSqlTaskInfoUpdate(params)

      this.$refs.form.validate(async valid => {
        if (valid) {
          await submit
          this.$message.success('保存成功')
          this.$emit('onUpdate')
          this.cancel()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" >
.form {
  .el-form-item {
    width: 250px;
  }
  .el-form-item__content,
  .el-form-item__label {
    text-align: left;
    display: block;
    width: 100%;
    .el-date-editor.el-input {
      width: 100%;
    }
  }
  .el-select {
    width: 100%;
  }
}
</style>