export const selectMinxi = {
  created(){
    // console.log('212121')
    // window.addEventListener('keyup',this.enterSearch)
    document.body.addEventListener('keyup',this.enterSearch)
  },
  destroyed(){
    document.body.removeEventListener('keyup',this.enterSearch)
  },
  methods: {
    enterSearch(e){
      console.log('this.$refs', this.$refs)
      if (e.keyCode ===13) {
        this.requestList()
      }
    },
    visibleTypeChange(e){
      console.log('this.refs',this.$refs)
      if (!e) {
        for(const key in this.$refs){
          this.$refs[key].blur()
        }
      }
    },
  }
}