<script setup>
import { ref, computed } from 'vue'
import StoreRenovationBaseForm from './components/StoreRenovationBaseForm.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import { getStorage } from '@/utils/storage'
import HeaderTitle from '@/views/flow/components/headerTitle'
import FlowForm from './components/flowForm'
import {
  submitStoreStatusApproveData,
  saveStoreStatusDraft,
  submitStoreStatusFlow,
  getStoreStatusFlowDetail,
  getStoreStatusLinkFlowDetail
} from './api/classicalStoreChangeFlow'
import { useRoute, useRouter } from '@/utils/router'

const innerFlowData = {
  linkOrderNumber: '',
  linkProcessCode: '',
  beforeRenovationShop: {},
  approveData: {}
}

const innerBaseKeys = {
  title: '标题',
  orderNumber: '单号',
  zsOrderNumber: '招商单号',
  decorationStatus: '流程状态',
  linkProcessCode: '流程类型',
  linkOrderNumber: '关联流程单号',
  optJobNumber: '提单人工号',
  optUserName: '提单人',
  optDeptName: '提单人部门',
  businessLicenseName: '售达方',
  bossName: '经销商（实际控股人）',
  bossContactInfo: '经销商联系方式',
  bossNumber: '经销商账号',
  authorizedCityName: '授权城市',
  marketLevelName: '城市等级',
  province: '省份',
  city: '地级市',
  area: '区/县',
  marketCenterName: '营销中心',
  designBrandNames: '门店设计品牌',
  oldBrandNames: '已代理品牌'
}
const route = useRoute()
const fdFormTemplateCode = 'distributor-JXS034'
const flowFormRef = ref(null)
const flowData = ref(innerFlowData)
const approvalData = ref({})
const flowId = computed(() => {
  const { id, businessId } = route.query
  return id || businessId
})

async function getDetail(type, cachedFormData) {
  if (type != 'detail') {
    flowData.value = {
      ...innerFlowData,
      ...(cachedFormData || {})
    }
    return
  }
  const { data } = await getStoreStatusFlowDetail({ formId: flowId.value })
  flowData.value = { ...innerFlowData, ...data }
  approvalData.value = data.approveData ?? {}
}

function getOptUserInfo() {
  const userInfo = getStorage('userInfo')
  return {
    optUserId: userInfo.accountId, //提单人ID【必须】',
    optUserName: userInfo.realName, //提单人姓名【必须】,
    optJobNumber: userInfo.code || userInfo.account, //提单人工号【必须】
    optDeptName: userInfo.deptName,
    optDeptId: userInfo.deptId
  }
}

function onLinkTypeChange(item) {
  const { approveData = {} } = flowData.value
  const { decorationStatus, designerReason, designerReasonRemark } = approveData
  flowData.value = {
    ...innerFlowData,
    linkProcessCode: item.value,
    approveData: {
      ...approveData,
      decorationStatus,
      designerReason,
      designerReasonRemark
    }
  }
}

async function onLinkFlowChange(item) {
  const { data } = await getStoreStatusLinkFlowDetail({
    formId: item.id,
    linkProcessCode: flowData.value.linkProcessCode
  })
  const { approveData } = flowData.value
  const { decorationStatus, designerReason, designerReasonRemark } = approveData
  flowData.value = {
    ...innerFlowData,
    ...data,
    ...getOptUserInfo(),
    approveData: {
      ...data.approveData,
      decorationStatus,
      designerReason,
      designerReasonRemark
    }
  }
}

// bpmApproveModel bpm表单
function onApproval(bpmExecuteBean, bpmApproveModel) {
  const { optUserId, optUserName, optJobNumber } = getOptUserInfo()
  const approveFormData = {
    id: flowId.value,
    optUserId, //提单人ID【必须】',
    optUserName, //提单人姓名【必须】,
    optJobNumber, //提单人工号【必须】
    applyOrderModel: {
      approveAccount: optJobNumber, //审批人工号   [必填]',
      businessId: flowId.value,
      processInstanceId: bpmExecuteBean?.processId,
      systemCode: 'distributor', //来源系统  [必传],
      fdFormTemplateCode, //模板/流程编码,
      pageVersion: 'V1', //页面版本 当前流程使用的页面版本  [必传]',
      bpmExecuteBean
    },
    approveData: bpmApproveModel
  }

  if (Number(flowData.value?.status ?? 0) === 20) {
    flowData.value = {
      ...flowData.value,
      approveData: bpmApproveModel,
      applyOrderModel: approveFormData.applyOrderModel
    }
    return flowFormRef.value?.onCommit('submit')
  }

  return submitStoreStatusApproveData(approveFormData)
}

function onSubmit(type) {
  return type !== 'submit'
    ? saveStoreStatusDraft(flowData.value)
    : submitStoreStatusFlow(flowData.value)
}
</script>

<template>
  <FlowForm
    ref="flowFormRef"
    :fd-form-template-code="fdFormTemplateCode"
    :model="flowData"
    :is-edit="[0, 20].includes(Number(flowData?.status))"
    :approval-data="approvalData"
    :on-submit="onSubmit"
    :on-approval="onApproval"
    :get-detail="getDetail"
    title="慕思经典-门店状态调整申请流程">
    <StoreRenovationBaseForm
      :disabled="flowData.linkProcessCode == ''"
      :model-value.sync="flowData"
      :base-keys="innerBaseKeys"
      :link-flow-params="{ processCode: flowData.linkProcessCode, status: '' }"
      @linkFlowChange="onLinkFlowChange">
      <template #custom-base-item="{ formKey, formLabel }">
        <RequiredFormItemSelect
          v-if="formKey === 'decorationStatus'"
          v-model="flowData.approveData.decorationStatus"
          class="formItem"
          label-key="label"
          value-key="value"
          prop="approveData.decorationStatus"
          :label="formLabel"
          :select-props="{ clearable: true }"
          :data-list="[
            { label: '无须装修', value: '3' },
            { label: '门店失效', value: '2' },
            { label: '流程失效', value: '1' }
          ]" />
        <RequiredFormItemSelect
          v-else-if="formKey === 'linkProcessCode'"
          v-model="flowData[formKey]"
          label-key="label"
          value-key="value"
          class="formItem"
          :prop="formKey"
          :label="formLabel"
          :select-props="{ clearable: true }"
          :data-list="[
            { label: '经典门店管理流程', value: 'JXS020' },
            { label: '经典门店设计流程', value: 'JXS030' },
            { label: '经典门店装修流程', value: 'JXS031' }
          ]"
          @onChangeItem="onLinkTypeChange" />
      </template>
    </StoreRenovationBaseForm>
    <HeaderTitle style="margin: 20px 0" title="店装物料信息" />
    <RequiredFormItemSelect
      v-model="flowData.approveData.designerReason"
      prop="approveData.designerReason"
      class="formItem"
      label="调整原因"
      :data-list="[
        '不急装修',
        '取消不装',
        '换位置改图',
        '尺寸差异大改图',
        '门店管理流程数据错误',
        '商场或其他原因停工',
        '换商或经销商淘汰',
        '其他（人工填写）'
      ]"
      :select-props="{ clearable: true }" />
    <RequiredFormItemInput
      v-if="flowData.approveData.designerReason === '其他（人工填写）'"
      v-model="flowData.approveData.designerReasonRemark"
      prop="approveData.designerReasonRemark"
      class="formItem"
      label="填写原因"
      placeholder="请填写调整原因"
      type="textarea"
      maxlength="300"
      style="min-width: 40%"
      rows="1"
      :input-props="{ clearable: true }" />
  </FlowForm>
</template>
