import _deepCopy from './deepCopy'
import { looseEqual } from 'element-ui/src/utils/util'
export { renderFormItem } from '@/components/FormPro/fn.js'
import { processImage } from '@/utils/obsUpload'

export const deepCopy = _deepCopy

// 抽取对象中的属性，返回新对象、剩余属性对象
export function extractProps(obj, keys) {
  const newObj = {}
  const restObj = {}
  for (const key in obj) {
    if (typeof keys === 'string' ? keys === key : keys.includes(key)) {
      newObj[key] = obj[key]
    } else {
      restObj[key] = obj[key]
    }
  }
  return [newObj, restObj]
}

export const stringToArray = (str, separators = [',', ';']) => {
  if (Object.prototype.toString.call(str) === '[object Array]') {
    return str
  }
  for (let i = 0; i < separators.length; i++) {
    const separator = separators[i]
    if ((str || '').includes(separator)) {
      return str.split(separator)
    }
  }
  return str ? [str] : []
}

export const render = (render, config = {}) => {
  const { props, ...rest } = config
  return {
    props: {
      text: { default: '' },
      data: { type: Object, default: null },
      config: { type: Object, default: () => ({}) },
      index: { type: Number, default: null },
      ...props
    },
    render(h) {
      return render(h, this)
    },
    ...rest
  }
}

export const transformParams = (_params, columns) => {
  let params = { ..._params }

  const transformColumns = columns
    .filter(
      item =>
        item.transform ||
        (Object.prototype.toString.call(item.search) === '[object Object]' &&
          item.search.transform)
    )
    .filter(item => {
      // 隐藏的列不需要 transform
      if (typeof item.hidden === 'function') {
        return !item.hidden(item, params)
      }
      return item.hidden !== true
    })
  transformColumns.forEach(transformColumn => {
    const transform =
      transformColumn.transform || transformColumn.search?.transform
    if (transform) {
      const [currParams, originParams] = extractProps(
        params,
        transformColumn.name
      )
      const transformValueObj = transform(
        currParams[transformColumn.name],
        transformColumn,
        params
      )
      params = Object.assign(originParams, transformValueObj)
    }
  })

  for (const key in params) {
    if (params[key] === undefined) {
      delete params[key]
    }
  }

  return params
}

export const checkUseArrayType = options => {
  return options.valueType && options.selectProps?.multiple
}

export const validateToPromise = validatorFunction => {
  return new Promise((resolve, reject) => {
    validatorFunction((valid, message) => {
      if (valid) return resolve(valid)
      reject(message)
    })
  })
}

export const findValueEnum = (valueEnum, value) => {
  return valueEnum.find(_item => looseEqual(_item.value, value))
}

export const renderText = (
  { name, valueType, valueEnum, renderText: _renderText } = {},
  item = {},
  index,
  // 标识调用 renderText 的位置，用于区分是在 table 还是 export 中调用
  options = { type: 'table' }
) => {
  if (_renderText) {
    return _renderText(name && item && item[name], item, index, options)
  }
  if (!name || !item) return ''

  if (valueEnum) {
    return findValueEnum(valueEnum, item[name])?.label
  }

  if (valueType === 'image') {
    const image = stringToArray(item[name])[0]
    return image
  }
  if (valueType === 'images') {
    return stringToArray(item[name]) || []
  }
  return item[name]
}

export const isEmpty = value => {
  if (Array.isArray(value)) {
    return !value.filter(item => !isEmpty(item)).length
  }
  return ['', undefined, null].includes(value)
}
export const onInitParams = async (params = {}, columns = []) => {
  const tempParams = deepCopy(params)
  for (let i = 0; i < columns.length; i++) {
    const item = columns[i]
    if (isEmpty(tempParams[item.name]) && item.name) {
      if (item.defaultValue !== undefined && item.defaultValue !== null) {
        console.log('defaultValue', item)
        tempParams[item.name] = await getDefaultParam(item)
      } else {
        // 多选值初始化为数组
        if (checkUseArrayType(item)) {
          tempParams[item.name] = []
        } else {
          tempParams[item.name] = tempParams[item.name] || undefined
        }
      }
    }
  }
  return tempParams
}

// 从配置项提取默认值
export const getDefaultParam = async column => {
  if (column.defaultValue !== undefined && column.defaultValue !== null) {
    if (typeof column.defaultValue === 'function') {
      return await column.defaultValue(column)
    } else {
      return column.defaultValue
    }
  }
  return undefined
}

export async function formatJson(
  columns,
  jsonData,
  options = {
    type: 'export'
  }
) {
  const result = []
  for (let dataIndex = 0; dataIndex < jsonData.length; dataIndex++) {
    const dataItem = jsonData[dataIndex]
    const row = []
    for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
      const columnItem = columns[columnIndex]
      if (columnItem.exportTransform) {
        row.push(
          await columnItem.exportTransform(columnItem, dataItem, options)
        )
      } else {
        row.push(await renderText(columnItem, dataItem, dataIndex, options))
      }
    }
    result.push(row)
  }
  return result
}

export function getThumbUrl(url, l_width) {
  return processImage(url, { width: parseInt(l_width) })
}

export function getWidthCellWidth(column, data, baseFontSize) {
  if (column.autoWidthCell === false || column.render) {
    return undefined
  }
  if (['image', 'images'].includes(column.valueType)) {
    return 50
  }
  const text = renderText(column, data)
  return text ? getTextWidth(text, baseFontSize) : undefined
}

export function getTextWidth(
  str = '',
  baseFontSize = '12px',
  baseFontWeight = 400
) {
  const dom = document.createElement('span')
  dom.style.display = 'inline-block'
  dom.style.fontSize = baseFontSize
  dom.style.fontWeight = baseFontWeight
  dom.textContent = str
  document.body.appendChild(dom)
  const width = dom.clientWidth
  document.body.removeChild(dom)
  return width
}

// 重复valueKey拼接index
export function valueKeyWithIndex(list) {
  const renderKeyList = []
  return list.map((item, index) => {
    if (Object.prototype.toString.call(item) !== '[object Object]') {
      return item
    }
    // 重复的key拼接index
    let key = item.name || index
    const isAlreadyExist = renderKeyList.some(
      item => String(item) === String(key)
    )
    if (isAlreadyExist) {
      key = `${key}_${index}`
    }
    renderKeyList.push(key)
    return {
      _renderKey: key,
      ...item
    }
  })
}

//ping 函数, 检测与内网连接是否能在100ms 内响应. 实测实验室电脑连接内网 ping 为 9-11ms 左右
export function pingURL(ip, timeout = 100) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = ip
    let flag = false
    img.onload = function () {
      flag = true
      resolve()
    }
    img.onerror = function () {
      flag = true
      resolve()
    }
    setTimeout(function () {
      if (!flag) {
        //如果真的无法访问
        flag = false
        reject()
      }
    }, timeout)
  })
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}
