/**
 * 通过该接口/dealermanage/brandseries/list获取的数据
 * 由于流程中经常会用到这些枚举值，所以将其抽离出来
 * 避免在代码中出现魔法值，同时数据修改后也有份备案
 */
import { brandseriesList } from '@/api/common/'
import * as apiConfig from '@/api/commonConfig/areaConfig'
import { mergeStep } from '@/utils/index'

const state = {
  dataJson: [],
  regionData: [], // 大区
  smallRegionData: [], // 小区
  personData: [], // 人员
  deptData: [], // 部门
  allBrandData: [] // 所有品牌系列
}

const getters = {
  seriesMap(state) {
    const seriesMap = new Map()
    state.allBrandData.forEach(item => {
      if (item.parentId !== '0' && item.parentId) {
        seriesMap.set(item.name, item)
      }
    })
    return seriesMap
  },
  bigBrandMap(state) {
    const bigBrandMap = new Map()
    state.allBrandData.forEach(item => {
      if (item.parentId === '0' || !item.parentId) {
        bigBrandMap.set(item.name, item)
      }
    })
    return bigBrandMap
  }
}

const mutations = {
  setDataJson: (state, data) => {
    state.dataJson = data
  },
  setRegionData: (state, data) => {
    state.regionData = data
  },
  setSmallRegionData: (state, data) => {
    state.smallRegionData = data
  },
  setPersonData: (state, data) => {
    state.personData = data
  },
  setDeptData: (state, data) => {
    state.deptData = data
  },
  setAllBrandData: (state, data) => {
    state.allBrandData = data
  }
}

const actions = {
  asyncSetRegionData: async ({ commit, state }, payload) => {
    if (payload.level === 1) {
      if (state.regionData.length > 0) return
      const res = await apiConfig.getArea(payload)
      commit('setRegionData', res.data)
    } else {
      if (state.smallRegionData.length > 0) return
      const res = await apiConfig.getArea(payload)
      commit('setSmallRegionData', res.data)
    }
  },
  asyncSetPersonData: async ({ commit, state }) => {
    if (state.personData.length > 0) return
    const res = await apiConfig.getPerson()
    commit('setPersonData', res.data)
  },
  asyncSetDeptData: async ({ commit, state }) => {
    if (state.deptData.length > 0) return
    const res = await apiConfig.getDept()
    commit('setDeptData', res.data)
  },
  asyncSetAllBrandData: async ({ commit, state }) => {
    const res = await brandseriesList()
    if (state.allBrandData.length) state.allBrandData
    commit('setAllBrandData', res.data)
    return res.data
  },
  asyncInitCommonData: mergeStep(({ dispatch }) => {
    return dispatch('asyncSetAllBrandData')
  })
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
