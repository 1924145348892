<template>
  <div>
    <i class="el-icon-bell" @click="openHistory"></i>
    <el-dialog title="版本更新内容" :visible.sync="dialogVisible" width="375px">
      <div ref="historyContainer" class="historyContainer">
        <div
          ref="history"
          :class="[
            'history',
            index == dataList.length - 1 ? 'lastChild' : null
          ]"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="history_time">{{ item.editTime }}</div>
          <div class="history_title">{{ item.title }}</div>
          <div class="history_content" v-html="item.content"></div>
          <!-- <div class="history_image">
            <img
              v-for="(image, index) in item.imageList"
              :key="index"
              :src="image"
              alt=""
              @click="preview(image)"
            />
          </div> -->
          <div>
            <el-image
              v-for="(image, index) in item.imageList"
              class="history_image"
              :key="index"
              :src="image"
              :preview-src-list="item.imageList"
            >
            </el-image>
          </div>
        </div>

        <div class="loading" v-show="loading">数据加载中...</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getHistoryNotify } from '@/api/common'
import { Base64 } from 'js-base64'
export default {
  name: 'HistoryNotify',
  data() {
    return {
      dataList: [],
      dialogVisible: false,
      total: 0,
      currPage: 1,
      pageSize: 10,
      loading: false
    }
  },
  async created() {
    const _this = this
    const { data } = await this.getDataList({
      currPage: 1,
      pageSize: 10,
      status: 1
    })
    if (data.length === 0) return
    // 获取首条数据
    const object = data[0]
    // 获取本地记录
    const oldHistory = JSON.parse(localStorage.getItem('historyNotify'))
    if (!oldHistory || object.id !== oldHistory.id) {
      localStorage.setItem('historyNotify', JSON.stringify(object))
      const notify = this.$notify.info({
        title: '版本更新',
        message: object.title,
        duration: 0,
        onClick: () => {
          _this.dialogVisible = true
          _this.$nextTick(() => {
            document
              .querySelector('.historyContainer')
              .addEventListener('scroll', _this.domScroll)
            notify.close()
          })
        }
      })
    }
  },
  async mounted() {
    const _this = this
    const { data, totalCount } = await _this.getDataList({
      currPage: _this.currPage,
      pageSize: _this.pageSize
    })
    _this.total = totalCount
    _this.dataList = data.map(item => {
      const remark = JSON.parse(item.remark)
      return {
        ...item,
        dayTime: remark.dayTime,
        imageList: remark.imageList,
        content: _this.htmlDecode(item.content)
      }
    })
    // console.log(document.querySelector('.historyContainer'));
  },
  beforeDestroy() {
    document.querySelector('.historyContainer') &&
      document
        .querySelector('.historyContainer')
        .removeAttribute('scroll', this.domScroll)
  },
  methods: {
    // 获取数据列表
    getDataList(params) {
      return new Promise(async (resolve, reject) => {
        const res = await getHistoryNotify(params)
        resolve(res)
      })
    },
    async openHistory() {
      // const { data, totalCount } = await this.getDataList({
      //   currPage: this.currPage,
      //   pageSize: this.pageSize
      // })
      // this.total = totalCount
      // this.dataList = data.map(item => {
      //   const remark = JSON.parse(item.remark)
      //   return {
      //     ...item,
      //     dayTime: remark.dayTime,
      //     imageList: remark.imageList,
      //     content: this.htmlDecode(item.content)
      //   }
      // })
      this.dialogVisible = true
      this.$nextTick(() => {
        document
          .querySelector('.historyContainer')
          .addEventListener('scroll', this.domScroll)
      })
    },
    htmlDecode(text) {
      const str = text.replace(/\--/g, '+')
      return Base64.decode(str)
    },
    domScroll(e) {
      // console.log(this.$refs.historyContainer.scrollTop)
      let dom = this.$refs.historyContainer
      let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight)
      let scrollTop = e.target.scrollTop
      //窗口可视范围高度
      let clientHeight =
        dom.innerHeight || Math.min(dom.clientHeight, dom.clientHeight)
      if (clientHeight + scrollTop >= scrollHeight) {
        // console.log('加载下拉')
        if (this.total <= this.pageSize * this.currPage) return
        this.loading = true
        this.currPage += 1
        this.getLoadData()
      }
    },
    // 加载数据
    async getLoadData() {
      try {
        const res = await this.getDataList({
          currPage: this.currPage,
          pageSize: this.pageSize
        })
        // console.log(res)
        const { data, totalCount } = res
        this.total = totalCount
        this.dataList = [
          ...this.dataList,
          ...data.map(item => {
            const remark = JSON.parse(item.remark)
            return {
              ...item,
              dayTime: remark.dayTime,
              imageList: remark.imageList,
              content: this.htmlDecode(item.content)
            }
          })
        ]
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    // 图片预览
    preview(img) {
      console.log(img)
    }
  }
}
</script>

<style scoped lang="scss">
.historyContainer {
  height: 400px;
  overflow-y: auto;
  line-height: 24px;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .history {
    position: relative;
    padding: 0px 20px 15px;
    overflow: hidden;
    &.lastChild {
      &::before {
        display: none;
      }
    }
    &::after {
      display: inline-block;
      content: ' ';
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid #0099cc;
      position: absolute;
      left: 0;
      top: 4px;
    }
    &::before {
      display: inline-block;
      content: ' ';
      width: 2px;
      height: 100%;
      background: #eee;
      position: absolute;
      left: 7px;
      top: 18px;
    }
    // &::after {
    //   content: ' ';
    //   width: 90%;
    //   height: 1px;
    //   background: #eee;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    // }
    .history_time {
      font-size: 16px;
      // font-weight: bold;
    }
    .history_title {
      font-size: 16px;
    }
    .history_content {
      margin: 10px 0;
      color: #969696;
      ::v-deep {
        img,
        video {
          width: 100%;
        }
      }
    }
    .history_image {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
  .loading {
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ccc;
  }
}
</style>
