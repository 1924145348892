// @ts-nocheck
import request from '@/utils/request';

//获取用户列表
export const accountListPage = (params) => {
	return request({
		url: '/user/account/accountlistpage',
		method: 'get',
		params,
	});
};

//获取用户列表
export const logintypeList = (params) => {
	return request({
		url: '/user/logintype/list',
		method: 'get',
		params,
	});
};

//获取用户角色列表
export const accountRoleList = (params) => {
	return request({
		url: '/user/accountrole/list',
		method: 'get',
		params,
	});
};

//获取用户详情
export const accountUserById = (params) => {
	return request({
		url: '/user/account/getUserById',
		method: 'get',
		params,
	});
};

//修改用户信息
export const accountUpdate = (data) => {
	return request({
		url: '/user/account/update',
		method: 'post',
		data,
	});
};

//获取部门列表
export const deptList = (params) => {
	return request({
		url: '/user/dept/list',
		method: 'get',
		params,
	});
};

//用户批量新增用户角色
export const accountroleBatchadd = (data) => {
	return request({
		url: '/user/accountrole/batchadd',
		method: 'post',
		data,
	});
};

//重置用户密码
export const resetPassword = (params) => {
    return request({
        url: '/user/account/resetpassword',
        method: 'post',
        params,
    });
};