import http, { download } from '@/utils/request'
// 获取systemCode
export const datadictionaryList = params => {
  return http({
    url: '/dealermanage/datadictionary/list',
    params,
    method: 'GET'
  })
}

export const bpmtemplateListpage = params => {
  return http({
    url: '/commonservice/bpmtemplate/listpage',
    params,
    method: 'GET'
  })
}

export const bpmtemplateAdd = data => {
  return http({
    url: '/commonservice/bpmtemplate/add',
    data,
    method: 'POST'
  })
}

export const bpmtemplateUpdate = data => {
  return http({
    url: '/commonservice/bpmtemplate/update',
    data,
    method: 'POST'
  })
}

//bpm字段模板
export const bpmtemplatecolumnListpage = params => {
  return http({
    url: '/commonservice/bpmtemplatecolumn/listpage',
    params,
    method: 'GET'
  })
}

export const bpmtemplatecolumnAdd = data => {
  return http({
    url: '/commonservice/bpmtemplatecolumn/add',
    data,
    method: 'POST'
  })
}

export const bpmtemplatecolumnUpdate = data => {
  return http({
    url: '/commonservice/bpmtemplatecolumn/update',
    data,
    method: 'POST'
  })
}

export const commonBpmTemplateColumnImport = data => {
  return http({
    url: '/commonservice/bpmtemplatecolumn/commonBpmTemplateColumnImport',
    data,
    method: 'POST'
  })
}

export const bpmtemplatecolumnEnableOrDisenable = data => {
  return http({
    url: '/commonservice/bpmtemplatecolumn/enableOrDisenable',
    data,
    method: 'POST'
  })
}

export const commonBpmTemplateColumnDownload = params => {
  return download(
    'commonservice/bpmtemplatecolumn/commonBpmTemplateColumnDownload',
    params
  )
}

export const dataupdatehistoryListHistory = params => {
  return http({
    url: '/consumermanage/dataupdatehistory/listHistory',
    params,
    method: 'GET'
  })
}

export const bpmtemplatepageListpage = params => {
  return http({
    url: '/commonservice/bpmtemplatepage/listpage',
    params,
    method: 'GET'
  })
}

export const bpmtemplatepageAdd = data => {
  return http({
    url: '/commonservice/bpmtemplatepage/add',
    data,
    method: 'POST'
  })
}

export const bpmtemplatepageUpdate = data => {
  return http({
    url: '/commonservice/bpmtemplatepage/update',
    data,
    method: 'POST'
  })
}
