<template>
  <el-table-column v-bind="tableColumnProps">
    <template #header="scope">
      <component
        :is="column.title"
        v-if="Object.prototype.toString.call(column.title) === '[object Object]'"
        :text="column.title"
        :data="scope.row"
        :index="scope.$index"
        v-on="$listeners"
      />

      <span v-else>{{ column.title }}</span>
    </template>
    <template v-if="!column.tableColumnList" #default="scope">
      <span v-if="column.valueType === 'index'" v-text="scope.$index + 1" />
      <div v-else-if="column.valueType === 'button'">
        <el-button
          v-for="(buttonItem, buttonIndex) of column.buttonGroup"
          :key="buttonIndex"
          @click="onButtonAction(buttonItem, scope)"
        >
          {{ buttonItem.title }}
        </el-button>
      </div>
      <el-badge
        :style="
          renderText({ ...column, valueType: 'images' }, scope.row).length > 1
            ? {
                marginRight: '7px',
                marginTop: '10px'
              }
            : {}
        "
        :hidden="renderText({ ...column, valueType: 'images' }, scope.row).length <= 1"
        :value="renderText({ ...column, valueType: 'images' }, scope.row).length"
        v-else-if="column.valueType === 'image'"
      >
        <el-image
          :src="getThumbUrl(renderText(column, scope.row), column.width || '228')"
          fit="contain"
          :preview-src-list="
            renderText({ ...column, valueType: 'images' }, scope.row, scope.$index)
          "
          v-bind="{
            width: column.width,
            height: column.width,
            ...column.imageProps
          }"
        >
          <div slot="placeholder" class="image-slot">
            <div class="el-icon-loading" />
          </div>
          <div slot="error" class="el-image__error">
            <div class="el-icon-picture-outline" />
            <div class="el-image__erro-text">
              {{
                getThumbUrl(renderText(column, scope.row), column.width || '228')
                  ? '加载失败'
                  : '暂无图片'
              }}
            </div>
          </div>
        </el-image>
      </el-badge>
      <component
        :is="column.render"
        v-else-if="column.render"
        :text="renderText(column, scope.row)"
        :data.sync="scope.row"
        :index="scope.$index"
        v-on="$listeners"
      />
      <ValueEnumComponent
        v-else-if="column.valueEnum && column.name && !column.renderText"
        :data="scope.row"
        :column="column"
      />
      <span v-bind="column.renderTextProps" v-else>{{
        renderText(column, scope.row, scope.$index)
      }}</span>
    </template>

    <template v-if="column.tableColumnList">
      <CustomElTableColumn
        v-for="(tableColumn, tableIndex) of column.tableColumnList"
        :key="tableIndex"
        :column="tableColumn"
      />
    </template>
  </el-table-column>
</template>

<script>
import { renderText, findValueEnum, getThumbUrl } from './fn'
export default {
  name: 'CustomElTableColumn',
  components: {
    ValueEnumComponent: {
      props: {
        data: { type: Object, default: () => ({}) },
        column: { type: Object, default: () => ({}) }
      },
      computed: {
        valueEnumItem() {
          return findValueEnum(this.column.valueEnum, this.data[this.column.name])
        }
      },
      render(h) {
        const valueEnumItem = this.valueEnumItem || {}
        if (valueEnumItem.render) {
          return h(valueEnumItem.render, {
            props: {
              item: valueEnumItem,
              data: this.data,
              column: this.column
            }
          })
        }
        if (valueEnumItem.status) {
          return h(
            'el-tag',
            {
              props: {
                type: valueEnumItem.status,
                ...valueEnumItem.props
              }
            },
            valueEnumItem.label
          )
        }
        return h('span', valueEnumItem.label)
      }
    }
  },
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    showOverflowTooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableColumnProps({ column }) {
      const { name, width, tableColumnProps = {} } = column
      const { showOverflowTooltip = this.showOverflowTooltip } = tableColumnProps
      return {
        width,
        prop: name,
        label:
          Object.prototype.toString.call(column.title) === '[object Object]' ? '' : column.title,
        ...tableColumnProps,
        showOverflowTooltip
      }
    }
  },
  methods: {
    getThumbUrl,
    renderText,
    findValueEnum
  }
}
</script>
