import http from '@/utils/request'

export const getHoteList = params =>
  http({ url: '/marketing/app/hotel/listpage', params })

export const addHoteItem = data =>
  http({ url: '/marketing/app/hotel/add', data, method: 'POST' })

export const updateHoteItem = data =>
  http({ url: '/marketing/app/hotel/update', data, method: 'POST' })

export const updateHoteStatus = data =>
  http({ url: '/marketing/app/hotel/status', data, method: 'POST' })

export const delHoteItem = data =>
  http({ url: '/marketing/app/hotel/delete', data, method: 'POST' })

// 请求省/市/区分层解构
export const getCityMapList = params =>
  http({
    url: '/dealermanage/datadictionary/cityMap/list',
    params
  })
// 房间列表
export const getRoomList = params =>
  http({ url: '/marketing/hotelroom/listpage', params })
// 房间产品
export const getRoomProduct = params =>
  http({ url: '/marketing/device/getDeviceByRoomId', params })
// 产品列表
export const getProductList = params =>
  http({ url: '/marketing/device/listpage', params })
// 员工列表
export const getClerkList = params =>
  http({ url: '/marketing/hotelmanager/listpage', params })
