import Cookies from 'js-cookie'
const paltName=process.env.VUE_APP_PLATNAME||''

const platKey=key=>paltName+key


export const getStorage = key => {
  const value = localStorage[paltName+key]
  if (/^{.+}$/.test(value) || /^\[.+\]$/.test(value)) {
    try {
      return JSON.parse(value)
    } catch (err) {
      return value
    }
  }
  return value
}

export const removeStorage = key => {
  localStorage.removeItem(platKey(key))
}

export const setStorage = (key, value) => {
  try{
    if (!key) return
    if (typeof value === 'object') {
      localStorage[platKey(key)] = JSON.stringify(value)
      return
    }
    localStorage[platKey(key)] = value
  }catch(err){
    console.log(err)
  }
}

export const getSession = key => {
  const value = sessionStorage[platKey(key)]
  if (/^{.+}$/.test(value) || /^\[.+\]$/.test(value)) {
    try {
      return JSON.parse(value)
    } catch (err) {
      return value
    }
  }
  return value
}

export const removeSession = key => {
  sessionStorage.removeItem(platKey(key))
}

export const setSession = (key, value) => {
  if (!key) return
  if (typeof value === 'object') {
    sessionStorage[platKey(key)] = JSON.stringify(value)
    return
  }
  sessionStorage[platKey(key)] = value
}


export function getCookie(key) {
  return Cookies.get(platKey(key))
}

export function setCookie(key, data, options) {
  return Cookies.set(platKey(key), data, options)
}

export function removeCookie(key) {
  return Cookies.remove(platKey(key))
}