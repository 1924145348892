<script setup>
import { computed, ref, unref, watch } from 'vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import { getStorage } from '@/utils/storage'
import HeaderTitle from '@/views/flow/components/headerTitle'
import flowRoutes from '@/router/modules/flow'
import { getDesignLinkFlowList } from '../api/classicalStoreChangeFlow'
import _ from 'lodash'
import { useRouter } from '@/utils/router'
import qs from 'qs'

const router = useRouter()

const metaList = flowRoutes()
  .map(item => item.meta)
  .filter(item => item.code)
console.log('flowRoutes', metaList)

const fileKeys = {
  storeAroundOppositeBrandFileList: '店铺含外围店铺含外围照片不低于5张或视频',
  shopCADFileList: '店铺CAD图纸',
  vmdDesignFileList: 'VMD设计需求表',
  oneShotfilmFileList: '门店一镜到底视频'
}

const innerBaseKeys = {
  title: '标题',
  orderNumber: '单号',
  zsOrderNumber: '招商单号',
  linkOrderNumber: '门店设计流程单号',
  storeManageProcessName: '门店管理流程',
  storeManageProcessCode: '门店管理流程单号',
  optJobNumber: '提单人工号',
  optUserName: '提单人',
  optDeptName: '提单人部门',
  businessLicenseName: '售达方',
  bossName: '经销商（实际控股人）',
  bossContactInfo: '经销商联系方式',
  bossNumber: '经销商账号',
  authorizedCityName: '授权城市',
  marketLevelName: '城市等级',
  province: '省份',
  city: '地级市',
  area: '区/县',
  marketCenterName: '营销中心',
  designBrandNames: '门店设计品牌',
  oldBrandNames: '已代理品牌'
}

const innerShopKeys = {
  parentShopNumber: '物理门店编号',
  shopNumber: '门店编号',
  createShopType: '开店类型',
  storeOpeningMode: '开店模式',
  contactPerson: '门店负责人',
  contactPersonPhone: '门店负责人联系方式',
  renovationLiablePerson: '装修对接人',
  renovationPersonPhone: '装修对接人电话',
  decorationArea: '装修面积',
  adjustedStoreArea: '调整后门店面积',
  marketName: '卖场名称',
  marketType: '卖场类型',
  shopAddress: '门店地址',
  shopPositionLevel: '门店位置等级',
  storeNature: '店态性质',
  managementModel: '经营模式'
}

const props = defineProps({
  linkFlowParams: {
    type: Object,
    default: () => ({ processCode: 'JXS020' })
  },
  modelValue: {
    type: Object,
    default: null
  },
  baseKeys: {
    type: Object,
    default: () => ({})
  },
  shopKeys: {
    type: Object,
    default: () => ({})
  },
  disabled: { type: Boolean, default: false }
})
const emit = defineEmits(['update:modelValue'])

const listTotalCount = ref(0)
const uploadFileRefs = ref({})
const form = computed({
  get: () => props.modelValue,
  set: val => {
    emit('update:modelValue', val)
  }
})

const customBaseForm = computed(() => {
  return Object.keys(props.baseKeys).length == 0
    ? innerBaseKeys
    : props.baseKeys
})

const customShopKeys = computed(() => {
  return Object.keys(props.shopKeys).length == 0
    ? innerShopKeys
    : props.shopKeys
})

const flowFiles = key => {
  return form.value?.fileRecordList?.filter(
    fileItme => fileItme.functionAttribution == key
  )
}

const getLinkFlowList = async ({ page, searchText, getDataListParams }) => {
  const key = /[\u4e00-\u9fa5]/.test(searchText) ? 'title' : 'orderNumber'
  const { data, totalCount } = await getDesignLinkFlowList({
    currPage: page,
    status: 40,
    declarationType: 'BPM',
    [key]: searchText,
    ...getDataListParams
  })
  listTotalCount.value = totalCount
  return data
}

// 关联列表当前列表没有默认选项,触发
async function onNotFoundLinkFlowList({ value: orderNumber }, callback) {
  callback(await getDesignLinkFlowList({ orderNumber }).then(res => res.data))
}

async function onLinkFlowChange(item) {
  emit('linkFlowChange', item)
}

const shopFormItemValue = computed(() => formKey => {
  return form.value?.beforeRenovationShop &&
    Object.hasOwnProperty.call(form.value.beforeRenovationShop, formKey)
    ? form.value.beforeRenovationShop[formKey]
    : ''
})

const baseFormItemValue = computed(() => formKey => {
  if (
    [
      'province',
      'city',
      'area',
      'authorizedCityName',
      'marketLevelName'
    ].includes(formKey)
  ) {
    return shopFormItemValue.value(formKey)
  } else if ('marketCenterName' == formKey) {
    return form.value?.approveData &&
      Object.hasOwnProperty.call(form.value.approveData, formKey)
      ? form.value.approveData[formKey]
      : form.value?.marketCenterName ?? ''
  } else {
    return Object.hasOwnProperty.call(form.value, formKey)
      ? form.value[formKey]
      : ''
  }
})

/**
 * 跳转关联流程详情
 * @type 0 门店管理关联流程 1 门店设计流程
 * **/
const goLinkFlowDetail = type => {
  const { linkOrderNumber, storeManageProcessCode } = form.value
  const orderNumber = type === 1 ? linkOrderNumber : storeManageProcessCode
  if (!orderNumber) return
  onNotFoundLinkFlowList({ value: orderNumber }, ([item]) => {
    if (!item) return
    console.log(item)
    const query = {
      fdId: item.id,
      fdFormTemplateCode: 'distributor-' + item.processCode,
      processId: item.orderNumber,
      from: 'bpm',
      formType: 1
    }
    window.open(
      `${location.origin}${location.pathname}#/oa/flow/entrance?${qs.stringify(
        query
      )}`
    )
  })
}

function refreshFiles() {
  Object.keys(fileKeys).forEach(fileKey => {
    uploadFileRefs.value[fileKey]?.reloadFileList(flowFiles(fileKey))
  })
}

watch(
  () => form.value.fileRecordList,
  () => {
    refreshFiles()
  }
)
</script>

<template>
  <div>
    <template v-for="(formLabel, formKey) in customBaseForm">
      <slot name="custom-base-item" :form-key="formKey" :form-label="formLabel">
        <template v-if="formKey === 'linkOrderNumber'">
          <RequiredFormItemSelect
            v-model="form[formKey]"
            :prop="formKey"
            :getDataListParams="props.linkFlowParams"
            :total-count="listTotalCount"
            :get-data-list="getLinkFlowList"
            :get-next-list="getLinkFlowList"
            :select-props="{
              filterable: true,
              remote: true,
              clearable: true
            }"
            :disabled="props.disabled"
            :label="formLabel.replace('单号', '')"
            class="formItem"
            label-key="title"
            value-key="orderNumber"
            @onOptionsListNotFound="onNotFoundLinkFlowList"
            @onChangeItem="onLinkFlowChange">
            <template #label>
              <span @click="goLinkFlowDetail(1)">
                <label>{{ formLabel.replace('单号', '') }}</label>
                <i class="el-icon-link"></i>
              </span>
            </template>
          </RequiredFormItemSelect>
          <RequiredFormItemInput
            :value="form[formKey]"
            delay-update
            class="formItem"
            placeholder="/"
            disabled
            :label="formLabel"
            :rows="1"
            :required="false"
            :inputProps="{ readonly: true }" />
        </template>
        <RequiredFormItemInput
          v-else
          delay-update
          class="formItem"
          :value="baseFormItemValue(formKey)"
          :style="['oldBrandNames'].includes(formKey) ? 'min-width:40%' : null"
          type="text"
          :labelTip="
            ['oldBrandNames', 'designBrandNames', 'title'].includes(formKey)
              ? form[formKey]
              : null
          "
          :prop="formKey"
          :label="formLabel"
          :rows="1"
          :required="false"
          :inputProps="{ readonly: true }"
          placeholder="/"
          disabled>
          <template v-if="formKey === 'storeManageProcessName'" #label>
            <span @click="goLinkFlowDetail(0)">
              <label>{{ formLabel }}</label>
              <i class="el-icon-link"></i>
            </span>
          </template>
        </RequiredFormItemInput>
      </slot>
    </template>
    <HeaderTitle style="margin: 20px 0" title="终端门店基础信息" />
    <template v-for="(formLabel, formKey) in customShopKeys">
      <slot name="custom-shop-item" :form-key="formKey">
        <RequiredModuleFormItemSelect
          v-if="formKey === 'managementModel'"
          :value="shopFormItemValue(formKey)"
          :label="formLabel"
          :required="false"
          disabled
          class="formItem"
          type="management_model" />
        <RequiredModuleFormItemSelect
          v-else-if="formKey === 'storeNature'"
          :value="shopFormItemValue(formKey)"
          :label="formLabel"
          disabled
          class="formItem"
          type="shop_nature_code" />
        <RequiredFormItemInput
          v-else
          :value="shopFormItemValue(formKey)"
          delay-update
          class="formItem"
          placeholder="/"
          disabled
          :label="formLabel"
          :rows="1"
          :required="false"
          :inputProps="{ readonly: true }" />
      </slot>
    </template>
    <template v-for="(fileLabel, fileKey) in fileKeys">
      <RequiredFormItemUploadFile
        v-if="flowFiles(fileKey) && flowFiles(fileKey).length"
        :ref="el => (uploadFileRefs[fileKey] = el)"
        :value="flowFiles(fileKey)"
        class="formItem"
        :label="fileLabel"
        :required="false"
        :key="fileKey"
        disabled
        limit-size="400">
      </RequiredFormItemUploadFile>
    </template>
  </div>
</template>
