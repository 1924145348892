<template>
  <div>
    <TablePro
      ref="tableProRef"
      :columns="columns"
      :request="getTableList"
      :hidePagination="true"
      auto-width-cell>
      <template #tableBefore>
        <el-table-column width="50" align="center" label="序号" type="index" />
      </template>
    </TablePro>
    <PopupForm
      ref="popupFormRef"
      popup-type="el-drawer"
      :columns="popupFormColumns"
      :actions="['cancel']"
      label-position="left"
      destroy-slot-on-close
      width="30%" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import TablePro, { render } from '@/components/TablePro/index.vue'
import PopupForm, { renderFormItem } from '@/components/FormPro/PopupForm.vue'
const props = defineProps({
  dataList: {
    required: true,
    type: Array
  },
  shopNumber: {
    required: true,
    type: String
  }
})
const emit = defineEmits('closeDialog')

const tableProRef = ref(null)
const popupFormRef = ref(null)

const columns = [
  {
    title: '门店编号',
    renderText() {
      return props.shopNumber
    }
  },
  {
    title: '产品属性',
    name: 'type',

    tableColumnProps: { align: 'center' }
  },
  {
    title: '产品料号',
    name: 'materialNumber',

    tableColumnProps: { align: 'center' }
  },
  {
    title: '产品型号',
    name: 'model',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '品牌',
    name: 'brand',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '产品品类',
    name: 'category',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '建议零售价',
    name: 'originalPrice',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '价格标签',
    name: 'sellingPrice',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '高标/低标幅度',
    name: 'offsetRange',

    tableColumnProps: { align: 'center' }
  },
  {
    title: '退市时间',
    name: 'delistingTime',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '出厂日期',
    name: 'productionTime',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '违规类型',
    name: 'violationType',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '违规详情',
    name: 'violationDetail',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '备注',
    name: 'remark',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '照片',
    tableColumnProps: { align: 'center', minWidth: '100' },
    render: render((h, context) => {
      return (
        <el-button
          v-loading-click={async () => {
            const obj = {
              urls: context.data.urls
            }
            return popupFormRef.value.onShow(obj, () => {}, {
              title: '照片'
            })
          }}>
          查看
        </el-button>
      )
    })
  }
]
const getTableList = async (params) => {
  const products = props.dataList.map(product => {
    const { violations, urls,...rest } = product;
    let totalUrls = [];
    let totalViolationDetails = [];
    let totalViolationTypes = [];
    let totalRemarks = [];
    violations.forEach(violation => {
      if (violation.violationDetail) {
        totalViolationDetails.push(violation.violationDetail);
      }
      if (violation.violationType) {
        totalViolationTypes.push(violation.violationType);
      }
      if (violation.remark) {
        totalRemarks.push(violation.remark);
      }
      
    });
    {/* totalUrls.push(...violation.urls); */}
    return {
      ...rest,
      violationDetail: totalViolationDetails.join(','),
      violationType: totalViolationTypes.join(','),
      remark: totalRemarks.join(','),
      urls
    };
  });
  return {
    data: products,
    totalCount: 0
  };
};
const popupFormColumns = [
  {
    title: ' ',
    name: 'urls',
    renderFormItem: renderFormItem((h, context) => {
      return (
        <div>
          {context.$attrs.params.urls?.map((item, index) => (
            <el-image
              key={index}
              src={item}
              preview-src-list={context.$attrs.params.urls}
              fit="cover"
              style="width: 100px; height: 100px;margin: 10px;"
            />
          ))}
        </div>
      )
    })
  }
]
</script>
