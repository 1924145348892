/**
 * 门店系列管理申请表
 */
import http from '@/utils/request'

// 查询售达方和老板信息
export const license = params => {
  return http({
    url: '/dealermanage/shop/apply/business/license',
    method: 'POST',
    params
  })
}
// 根据售达方选择门店
export const shoplist = params => {
  return http({
    // url: "/dealermanage/shop/apply/shoplist",
    url: '/dealermanage/shop/apply/sellerCheckAuthorizedCity',
    params,
    method: 'POST'
  })
}
// 门店选择
export const shopBrand = params => {
  return http({
    url: '/dealermanage/shop/apply/shop/brand',
    params,
    method: 'POST'
  })
}
// 申请品牌（加盟品牌）
export const brandlist = params => {
  return http({
    url: '/dealermanage/shop/apply/brandlist',
    params,
    method: 'POST'
  })
}

// 保存门店相关信息
export const addSponsor = data => {
  return http({
    url: '/dealermanage/shop/apply/addSponsor',
    data,
    method: 'POST'
  })
}
// 提交门店相关信息
export const shopSubmit = data => {
  return http({
    url: '/dealermanage/shop/apply/submit',
    data,
    method: 'POST'
  })
}

// 从报单进来的详情
export const detail = params => {
  return http({
    url: '/dealermanage/shop/apply/declarationform',
    params,
    method: 'POST'
  })
}

// 获取售达方已代理授权品牌
export const shopApplyBrand = params => {
  return http({
    url: '/dealermanage/shop/apply/brand',
    params
  })
}

// 获取成本中心列表
export const getCostCenterList= params => {
  return http({
    url: '/dealermanage/datadictionary/listpage',
    params
  })
}
