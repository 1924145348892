import md5 from "js-md5";
import store from "@/store";
import { userRuleList } from "@/api/user";
import { getSession } from "@/utils/storage";

const isObject = data =>
  Object.prototype.toString.call(data) === "[object Object]";
// 添加请求参数
const configAddParams = (config, params = {}) => {
  if (isObject(config.data)) {
    config.data = Object.assign({}, params, config.data);
  } else if (isObject(config.params)) {
    config.params = Object.assign({}, params, config.params);
  } else if (config.params) {
    console.log("config.params 不是一个对象, 请自行在接口添加");
  } else {
    config.params = params;
  }
  return config;
};

// 缓存请求结果
let response = {};
let storeSubscribeAction = null;

// 需要传入数据管理员的接口
export const masterRuleUrls = [
  "/dealermanage/shop/ht/listpage",
  "/dealermanage/businesslicense/licenselist",
  "/dealermanage/authorizedbrand/receiverList",
  "/dealermanage/crmdistributor/distributorlistpage",
  "/dealermanage/shop/getDealerShopExport",
  "dealermanage/crmdistributor/export",
  "dealermanage/authorizedbrand/export",
  "/dealermanage/shop/getDealerShopBrandExport",
  "dealermanage/shop/getDealerShopSunExport",
  "/dealermanage/shop/getDealerShopSunList",
  "/dealermanage/crmdistributor/exportList",
  "/dealermanage/authorizedbrand/exportList",
  "/dealermanage/crmdistributor/distributorExportPage"
];

// 添加数据管理员标识
export const wrapMasterRuleConfig = async config => {
  const userInfo = store.state.user.userInfo;
  const accountId = userInfo && userInfo.accountId;
  const systemId = getSession("systemCode") || process.env.VUE_APP_SYSTEM_CODE;
  const params = { accountId, systemId };
  const responseKey = md5(JSON.stringify(params));

  // 退出登录清空缓存
  if (!storeSubscribeAction) {
    storeSubscribeAction = store.subscribeAction(({ type }) => {
      if (type === "user/logout") {
        response = {};
      }
    });
  }

  let ruleList = [];
  config = configAddParams(config, {
    accountId
  });
  if (response[responseKey]) {
    ruleList = response[responseKey];
  } else {
    ruleList = await userRuleList(params).then(res => {
      response[responseKey] = res.data;
      return res.data;
    });
  }
  if ((ruleList || []).length) {
    const isMasterDataManager = !!ruleList.find(
      item => item.code === "master_data_manager"
    );
    if (isMasterDataManager) {
      config = configAddParams(config, {
        roleCode: "master_data_manager"
      });
    }
  }
  return config;
};

// 接口参数包装
export const requestWrap = async config => {
  const isByBossUrl = masterRuleUrls.includes(config.url);
  if (isByBossUrl) {
    config = await wrapMasterRuleConfig(config);
  }
  return config;
};
