export default [
  {
    path: 'active',
    name: 'active',
    component: () => import('@/views/attractPlatform/activeManege'),
    meta: { title: '招商活动管理' }
  },
  {
    path: 'regionPersonal',
    name: 'RegionPersonal',
    component: () => import('@/views/attractPlatform/regionPersonal'),
    meta: {
      title: '地区责任人管理'
    }
  }, {
    path: 'messageCollect',
    name: 'MessageCollect',
    hidden: true, // 隐藏侧边栏
    component: () => import('@/views/attractPlatform/activeManege/messageCollect'),
    meta: {
      title: '信息收集'
    }
  }, {
    path: 'mineAttract',
    name: 'MineAttract',
    component: () => import('@/views/attractPlatform/activeManege/mineAttract'),
    meta: {
      title: '我的招商活动'
    }
  }, {
    path: 'createClient',
    name: 'CreateClient',
    hidden: true, // 隐藏侧边栏
    component: () => import('@/views/attractPlatform/activeManege/createClient'),
    meta: {
      title: '录入客户信息',
    }
  }, {
    path: 'dataManage',
    name: 'DataManage',
    component: () => import('@/views/attractPlatform/dataManage'),
    meta: {
      title: '招商资料管理'
    }
  }, {
    path: 'dataManageCountry',
    name: 'DataManageCountry',
    component: () => import('@/views/attractPlatform/dataManageCountry'),
    meta: {
      title: '招商资料管理(全国)'
    }
  }, {
    path: 'materialManage',
    name: 'materialManage',
    component: () => import('@/views/attractPlatform/materialManage'),
    meta: {
      title: '招商客户跟踪与资料管理'
    }
  }, {
    path: 'materialManageCountry',
    name: 'materialManageCountry',
    component: () => import('@/views/attractPlatform/materialManageCountry'),
    meta: {
      title: '招商客户跟踪与资料管理(全国)'
    }
  },
  {
    path: 'storeData',
    name: 'storeData',
    component: () => import('@/views/attractPlatform/storeData'),
    meta: {
      title: '门店数据管理'
    }
  }
]
