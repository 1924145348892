<script>
export default {
  created() {
    const { query } = this.$route
    this.$router.replace({ path: this.$store.getters.path, query })
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
