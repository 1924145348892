<template>
  <div>
    <OverViews />
    <AreaShop :data="tableData" />
    <ShopDetail />
    <el-button type="warning" class="btn" @click="goBack"
      >返回</el-button
    >
  </div>
</template>

<script>
import OverViews from './components/overViews'
import AreaShop from './components/areaShop'
import ShopDetail from './components/shopDetail'
import { storeDetail } from '@/api/businessPlan'
export default {
  components: {
    OverViews,
    AreaShop,
    ShopDetail
  },
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    async getDataList() {
      const id = this.$route.query.id
      const res = await storeDetail({
        activityId: id
      })
      if (res.code === 0) {
        this.tableData = res.data.map((item, index) => {
          item.storeTypeList.forEach((i, key) => {
            i.aId = String(index + 1) + String(key + 1)
            i.targetSum = i.planSum
          })
          return {
            ...item,
            storeType: '全部类型',
            aId: String(index + 1)
          }
        })
      }
    },
    goBack() {
      this.$route.params.page = this.$route.query.page
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  margin: 20px auto;
  display: block;
}
</style>
