<template>
  <div id="app" :class="`${theme} column${column}`">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['theme', 'column'])
  }
}
</script>
<style lang="scss">
@import '~@/styles/theme.scss';

.el-table--scrollable-x .el-table__body-wrapper {
  -webkit-overflow-scrolling: auto;
}

.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
}
.el-button .loadingClick-loading {
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: rgba(255, 255, 255, 0.9);
}
.loadingClick-loading .el-loading-spinner {
  top: 50%;
  transform: translateY(-50%);
  margin-top: inherit;
}
</style>
