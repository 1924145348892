import http from '@/utils/request'
import { download } from '@/utils/request'

// 获取区域部门列表
export const getAreaDept = params => {
  return http.get('/dealermanage/areadept/listpage', {
    params
  })
}

// 删除区域部门
export const delAreaDept = params => {
  return http({
    url: '/dealermanage/areadept/delete',
    method: 'post',
    data: params
  })
}

// 下载模板
export const downloadExcel = () => {
  download('dealermanage/areadept/excel')
}

// 导入模板
export const uploadExcel = data => {
  return http({
    url: '/dealermanage/areadept/import',
    method: 'post',
    data
  })
}

// 获取详情
export const areaDeptDetail = id => {
  return http({
    url: '/dealermanage/areadept/get',
    params: {
      id
    }
  })
}

// 获取部门数据
export const getDept = () => {
  return http({
    url:'/dealermanage/businessdivision/list',
  })
}

// 新增配置部门
export const addNewDept = (data) => {
  return http({
    url: '/dealermanage/areadept/add',
    data,
    method: 'post'
  })
}

// 编辑配置部门
export const editDept = (data) => {
  return http({
    url: '/dealermanage/areadept/updateEntity',
    data,
    method: 'post'
  })
}

// ---------------------------人员配置----------------------------
// 人员配置列表
export const areaPerson = params => {
  return http({
    url: '/dealermanage/areauser/listpage',
    params
  })
}

// 人员批量删除
export const delPerson = params => {
  return http({
    url: '/dealermanage/areauser/delete',
    method: 'post',
    data: params
  })
}
// 下载模板
export const downloadPersonExcel = () => {
  download('dealermanage/areauser/excel')
}

// 导入模板
export const uploadPersonExcel = data => {
  return http({
    url: '/dealermanage/areauser/import',
    method: 'post',
    data
  })
}

// 获取详情
export const areaPersonDetail = id => {
  return http({
    url: '/dealermanage/areauser/get',
    params: {
      id
    }
  })
}

// 获取区域
export const getArea = params => {
  return http({
    url: 'dealermanage/geographyregion/list',
    params: {
      ...params,
      status: 0
    }
  })
}

// 获取人员
export const getPerson = params => {
  return http({
    url: '/dealermanage/boss/list',
    params: params
  })
}

// 获取省市区
export const getAddress = params => {
  return http({
    url: '/dealermanage/datadictionary/list',
    params
  })
}

// 新增人员
export const addNewPerson = data => {
  return http({
    url: '/dealermanage/areauser/add',
    data,
    method: 'post'
  })
}

// 修改人员
export const editPerson = data => {
  return http({
    url: '/dealermanage/areauser/update',
    data,
    method: 'post'
  })
}

//品牌区域配置列表

export const delAreaPerson = params => {
  return http({
    url: '/dealermanage/areadiretor/listpage',
    method: 'get',
    params
  })
}
// 新增区域人员
export const delAddAreaPerson = data => {
  return http({
    url: '/dealermanage/areadiretor/add',
    method: 'post',
    data
  })
}
// 编辑区域人员
export const delEditAreaPerson = data => {
  return http({
    url: '/dealermanage/areadiretor/update',
    method: 'post',
    data
  })
}
//开关
export const delStaAreaPerson = data => {
  return http({
    url: '/dealermanage/areadiretor/status',
    method: 'post',
    data
  })
}
//批量更新
export const delbatchupdate = data => {
  return http({
    url: '/dealermanage/areadiretor/batchupdate',
    method: 'post',
    data
  })
}
//根据品牌获得大小区
export const getAreaByBrand = params => {
  return http({
    url: '/dealermanage/areadept/areaListByBrand',
    method: 'get',
    params
  })
}
//获得所有小区
export const getAllsmallRegion = params => {
  return http({
    url: '/dealermanage/areadiretor/smallRegion/listpage',
    method: 'get',
    params
  })
}
//获得所有大区
export const getAlladministrativeRegion = params => {
  return http({
    url: '/dealermanage/areadiretor/administrativeRegion/listpage',
    method: 'get',
    params
  })
}
// 根据品牌获取部门
export const getDeptList = (params) => {
  return http({
    url:'/dealermanage/businessdivision/brand/listpage',
    method: 'get',
    params
  })
}

