<template>
  <div>
    <div ref="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`">
          <template>
            <el-input
              v-model="formInline[item.key]"
              :placeholder="`请输入${item.label}`"
              @keyup.enter.native="query"></el-input>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button @click="hanldeDowloadTemplate">下载模板</el-button>
          <el-button @click="() => $refs.fileRef.click()">
            <input
              id="file"
              ref="fileRef"
              @change="handleImportChange"
              hidden
              type="file"
              name="file"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />导入
          </el-button>
          <el-button @click="exportFile">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div
      :style="{
        height: `calc(100vh - ${withoutTableHeight}px)`,
        marginTop: '20px'
      }">
      <el-table
        ref="table"
        border
        :data="dataList"
        style="width: 100%"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        v-loading="loading"
        height="100%">
        <el-table-column
          prop="shopBrandNumber"
          :label="`送达方编号`"
          width="110" />
        <el-table-column prop="deliveryPartyName" :label="`送达方名称`" />
        <el-table-column
          prop="orderAuthority"
          label="下单权限"
          v-if="hasMasterListOrderAuthority">
          <template slot-scope="scope">
            <div @click.stop="">
              <el-switch
                :value="scope.row.orderAuthority"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="handleChangeOrderAuthority(scope.row)">
              </el-switch>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="110">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleShowRecordDrawer(scope.row)"
              >变更记录</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange" />
    </div>
    <receiverDrawer
      ref="receiverDrawer"
      :selectItem="selectItem"
      :updateDetailData="updateDetailData" />
    <receiverRecordDrawer
      :selectItem="recordSelectItem"
      @recordDetail="handleRecordDetail"
      ref="recordDrawer" />
  </div>
</template>

<script>
import {
  getReceiverDataList,
  getReceiverOrderSpecialist,
  changeReceiverStatus,
  authorizedbrandDownload,
  importUpdateDealerAuthorizedBrand,
  listpageExporttask
} from '@/api/businessOos'
import { tableHeight } from '@/mixin/tableHeight'
import { getStorage } from '@/utils/storage'
import receiverRecordDrawer from './components/receiverRecordDrawer'
import receiverDrawer from './components/receiverDrawer'
import { uploadFile } from '@/utils/obsUpload'
import throttle from 'lodash/throttle'

const formInlineData = [
  {
    label: '送达方编号',
    key: 'shopBrandNumber'
  }
]
export default {
  components: {
    receiverRecordDrawer,
    receiverDrawer
  },
  mixins: [tableHeight],
  inject: ['addTask'],
  data() {
    return {
      formInline: {
        // activeName: '0'
      }, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      loading: false,
      rowData: null,
      rowId: [],
      dialogOrder: false,
      formData: {}, // 订单专员数据
      totalCount: 0,
      userInfo: {},
      city: '',
      id: '',
      fullscreenLoading: false,
      selectData: [], // 获取的送达方
      userInfo: getStorage('userInfo') || {},
      selectItem: {},
      recordSelectItem: {},
      updateDetailData: {}
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    // this.getBrandList();
    this.getDataList()
  },
  computed: {
    showCloum() {
      const userInfo = getStorage('userInfo')
      return userInfo.permList.includes('change_status')
    },
    hasMasterListOrderAuthority() {
      return this.userInfo.permList.includes('masterList_orderAuthority')
    }
  },
  methods: {
    exportFile() {
      this.addTask(
        '送达方操作日志导出',
        () => {
          listpageExporttask({
            userId: this.userInfo.accountId,
            userAccount: this.userInfo.account,
            moduleName: '送达方操作日志导出',
            tableName: 'dealer_authorized_brand'
          }).then(res => {
            if (res.code === 0) {
              this.$message.success(res.data || '导出数据中')
            }
          })
        },
        '1'
      )
    },
    async handleImportChange(e) {
      // console.log(e.target.files)
      const { res } = await uploadFile(e.target.files[0])
      //导入excel表格
      const form = new FormData()
      form.append('file', e.target.files[0])
      form.append('createBy', this.userInfo.accountId)
      form.append('createName', this.userInfo.realName)
      form.append('fileUrl', res.requestUrls[0])
      importUpdateDealerAuthorizedBrand(form).then(res => {
        res?.msg && this.$message.success(res.data || res.msg)
        this.getDataList()
      })
      e.target.value = ''
    },
    hanldeDowloadTemplate: throttle(() => authorizedbrandDownload({}), 500),
    handleRecordDetail(item) {
      this.$refs.receiverDrawer.storeVisibleDialog = true
      this.selectItem = JSON.parse(item.historicalData)
      this.selectItem.updateRemark = item.remark
      this.updateDetailData = JSON.parse(item.afterInfo)
    },
    handleShowRecordDrawer(item) {
      this.recordSelectItem = item
      this.$refs.recordDrawer.showRecordDrawer = true
    },
    async update(role) {
      if (role === 'order') {
        const res = await getReceiverOrderSpecialist({
          shopBrandNumber: this.rowData.shopBrandNumber,
          businessLicenseId: this.rowData.businessLicenseId
        })
        // console.log(res);
        this.tableList = res.data
      }
    },
    async handleChangeOrderAuthority({ id, orderAuthority, ifEnabled }) {
      const status = orderAuthority === 1 ? 0 : 1

      this.$prompt('请输入备注', '提示', {
        inputValidator(valid) {
          if (!valid) {
            return '请输入备注'
          }
        },
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async ({ value }) => {
        const res = await changeReceiverStatus({
          id,
          orderAuthority: status,
          ifEnabled,
          updateRemark: value,
          updateUserId: this.userInfo?.accountId
        })
        if (res.code === 0) {
          this.getDataList()
        }
      })
    },
    query() {
      // console.log(this.formInline);
      this.$refs.table.clearSelection()
      this.page = 1
      this.getDataList()
    },
    // 获取传统送达方
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })

      this.loading = true
      const res = await getReceiverDataList({
        ...this.formInline,
        // channelCooperationType: this.formInline.activeName == '0' ? '' : 0,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      // console.log(res);
      if (res.code === 0) {
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },

    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.city = data.city
      this.rowData = data
      this.id = data.id
      switch (type) {
        case 'code':
          this.getCodeDataList(data)
          break
        case 'order':
          this.getOrderDataList(data)
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';
:deep(.el-form) {
  .el-input__inner {
    width: 250px;
  }
}

.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
