<template>
  <el-drawer
    title="变更记录"
    :visible.sync="showRecordDrawer"
    direction="rtl"
    size="50%;overflow-y:auto">
    <div style="padding: 0 30px">
      <el-table
        ref="filterTable"
        border
        :data="dataList"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        style="width: 100%">
        <el-table-column prop="shopBrandNumber" label="送达方编号" />
        <el-table-column prop="createUserName" label="操作人" />
        <el-table-column prop="createTime" label="操作时间" />
        <el-table-column label="详情">
          <template slot-scope="scope">
            <el-button type="text" @click="$emit('recordDetail', scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; justify-content: center; padding-top: 20px">
        <el-pagination
          background
          layout="total,prev, pager, next,sizes"
          :total="total"
          :page-sizes="[10, 30, 50]"
          :page-size="limit"
          :current-page="page"
          @current-change="currentChange"
          @size-change="handleSizeChange" />
      </div>
    </div>
  </el-drawer>
</template>

<script>
// 10待装修20装修中30装修完成40正常营业50装修失效60撤店失效
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import { datahistorydetailListpage } from '../api/index'

export default {
  components: {
    RequestModuleSelect
  },
  props: {
    selectItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showRecordDrawer: false,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1
    }
  },
  watch: {
    selectItem(val) {
      val && this.getDataList({ dataId: val.id })
    }
  },
  methods: {
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList({
        currPage: page,
        pageSize: this.limit,
        dataId: this.selectItem.id
      })
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList({
        currPage: this.page,
        pageSize: this.limit,
        dataId: this.selectItem.id
      })
    },
    getDataList(params) {
      datahistorydetailListpage(params).then(res => {
        this.total = res.totalCount
        this.dataList = res.data.map(item => ({
          ...item,
          shopBrandNumber: this.selectItem.shopBrandNumber
        }))
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
