<template>
  <div class="CommonUpload" :class="listType">
    <template v-if="listType === 'text'">
      <template v-if="showUploadButton">
        <Upload :upload="onUpload" :file-list="fileList" v-bind="$attrs">
          <slot>
            <el-popover width="400" trigger="hover" :disabled="showFileList">
              <el-table :data="fileList">
                <el-table-column property="percent" label="进度">
                  <template slot-scope="scope">
                    <el-progress
                      :percentage="scope.row.status === 'done' ? 100 : scope.row.percent"
                    />
                  </template>
                </el-table-column>
                <el-table-column width="80" property="status" label="状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status === 'done'">成功</span>
                    <span v-else-if="scope.row.status === 'error'">失败</span>
                    <span v-else-if="scope.row.status === 'uploading'">上传中</span>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip property="url" label="url"></el-table-column>
              </el-table>
              <el-button slot="reference">上传文件</el-button>
            </el-popover>
          </slot>
        </Upload>
      </template>
      <FileList v-if="showFileList" :file-list="fileList" v-bind="$attrs" @delete="onDelete" />
    </template>
    <PictureFileList
      v-else-if="listType === 'picture'"
      v-model="fileList"
      v-bind="$attrs"
      :show-upload-button="showUploadButton"
      @delete="onDelete"
      @preview="onPreview"
      @play="onPlay"
    >
      <Upload :upload="onUpload" :file-list="fileList" v-bind="{ accept: 'image/*', ...$attrs }">
        <slot>
          <div class="CommonUpload-Upload-Button" :style="size">
            <i class="el-icon-folder-add" />
            <div class="CommonUpload-Upload-Button-text">上传文件</div>
          </div>
        </slot>
      </Upload>
      <template #preview-cover="{ file }">
        <slot name="preview-cover" :file="file"></slot>
      </template>
    </PictureFileList>
    <slot name="tip">
      <div v-if="tip" class="tip">
        {{ tip }}
      </div>
    </slot>
    <ImageViewer
      v-if="showViewer"
      :on-close="() => (showViewer = false)"
      :initial-index="currIndex"
      :url-list="fileList.map((item) => item.url || item.thumbUrl)"
    />
    <FilePlayer ref="playerRef" />
  </div>
</template>

<script>
import Upload from './Upload'
import FileList from './FileList'
import FilePlayer from './FilePlayer.vue'
import PictureFileList from './PictureFileList'
import { previewImage, getThumbUrl } from './utils'
import { isEqual } from 'element-ui/src/utils/util'
import { Image, MessageBox } from 'element-ui'
// import { uploadFile } from '@/utils/obsUpload'
import { uploadFile } from '@/utils/obsUpload'
const stringToArray = (str = '', separators = [',', ';']) => {
  for (let i = 0; i < separators.length; i++) {
    const separator = separators[i]
    if (str.includes(separator)) {
      return str.split(separator)
    }
    return str ? [str] : []
  }
}

const ImageViewer = Image.components.ImageViewer
export default {
  components: {
    ImageViewer,
    Upload,
    FileList,
    PictureFileList,
    FilePlayer
  },
  props: {
    // 隐藏文件列表，只显示上传按钮，进度在按钮上显示
    showFileList: {
      type: Boolean,
      default: true
    },
    tip: {
      type: String,
      default: ''
    },
    listType: {
      type: String,
      // text,picture
      default: 'text'
    },
    value: {
      type: [Array, String],
      default: ''
    },
    beforeUpload: {
      type: Function,
      default: async () => {
        // 文件上传前的函数,返回boolean或者返回uploadRequest 中customOptions 参数
        // 返回 false 不执行uploadRequest
        return true
      }
    },
    uploadRequest: {
      type: Function,
      default: async (file, progress, customOptions) => {
        // 上传到阿里云
        // return uploadFile(file).then(({ res }) => {
        //   return { url: res?.requestUrls[0] }
        // })
        // 上传到华为云
        return uploadFile(
          file,
          {
            onUploadProgress(progressEvent) {
              const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0
              if (typeof progress === 'function') {
                progress(complete)
              }
            }
          },
          customOptions
        ).then(({ url }) => {
          return { url }
        })
      }
    },
    stringSeparator: {
      type: String,
      default: ','
    },
    //记录上传的文件是否添加到fileList后面
    reverse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileList: [],
      showViewer: false,
      currIndex: 0
    }
  },
  computed: {
    showUploadButton({ $attrs, fileList }) {
      return $attrs.limit ? fileList.length < $attrs.limit : true
    },
    size({ $attrs }) {
      const itemWidth = $attrs['itemWidth'] || $attrs['item-width']
      const itemHeight = $attrs['itemHeight'] || $attrs['item-height']
      return {
        width: itemWidth || '104px',
        height: itemHeight || '104px'
      }
    },
    doneFileListUrl({ fileList }) {
      return fileList.filter((item) => item.status === 'done').map(({ url }) => url)
    }
  },
  watch: {
    value: {
      async handler(value) {
        const tempValue =
          typeof value === 'string' ? stringToArray(value, this.stringSeparator) : value
        if (tempValue && tempValue?.length === this.doneFileListUrl.length) {
          const isEqual = this.doneFileListUrl.every((item) => tempValue.includes(item))
          if (isEqual) {
            return
          }
        }
        this.initFileList(value || '')
      },
      immediate: true
    },
    doneFileListUrl: {
      handler(list) {
        let newValue = list
        if (typeof this.value === 'string') {
          newValue = list.length ? list.join(this.stringSeparator) : ''
        }
        if (isEqual(newValue, this.value)) return
        console.log('newValue', newValue)
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    // 初始化文件列表
    async initFileList(value = '') {
      if (typeof value === 'string') {
        value = stringToArray(value, this.stringSeparator)
      }
      const tempList = []
      for (let i = 0; i < value.length; i++) {
        const item = value[i]
        tempList.push(await this.createFileItem(item))
      }
      this.fileList = tempList
    },
    async onUpload(file) {
      const fileItem = await this.createFileItem(file)
      if (!this.reverse) {
        this.fileList.push(fileItem)
      } else {
        this.fileList.unshift(fileItem)
      }
      fileItem.status = 'uploading'
      const _this = this
      try {
        fileItem.percent = 1
        const customOptions = await this.beforeUpload(file)
        console.log('customOptions', customOptions)
        if (typeof customOptions === 'boolean' && !customOptions) return

        fileItem.response = await this.uploadRequest(
          file,
          {},
          typeof customOptions === 'boolean' ? {} : customOptions,
          (complete) => {
            fileItem.percent = complete
            console.log('上传中', this)
            this.$emit('progress', complete)
          }
        )
        fileItem.url = fileItem.response?.url
        fileItem.status = 'done'
        this.$emit('uploadSuccess', fileItem)
      } catch (error) {
        fileItem.response = error?.msg || error
        if (typeof fileItem.response !== 'string') {
          fileItem.response = '上传失败'
        }
        fileItem.status = 'error'
        this.$emit('uploadError', fileItem)
      }
    },
    async onDelete(fileItem, fileIndex) {
      try {
        await MessageBox.confirm('你确定要删除吗？')
        this.fileList.splice(fileIndex, 1)
        this.$emit('delect', fileItem, fileIndex)
      } catch (error) {}
    },
    onPreview(fileItem, fileIndex) {
      this.showViewer = true
      this.currIndex = fileIndex
    },
    onPlay(fileItem, fileIndex) {
      const { url } = fileItem
      this.$refs.playerRef.open({ url })
    },
    async createFileItem(origin) {
      const originType = Object.prototype.toString.call(origin)
      const fileItem = {
        uid: Date.now() + String(Math.random()).substr(3, 6)
      }
      if (originType === '[object File]') {
        return {
          ...fileItem,
          url: origin.url,
          thumbUrl: await previewImage(origin),
          file: origin,
          percent: 0,
          status: 'undone',
          name: origin.name
        }
      } else if (originType === '[object String]') {
        return {
          ...fileItem,
          url: origin,
          thumbUrl: getThumbUrl(origin, (parseInt(this.size.width) || 104) * 2),
          file: null,
          status: 'done',
          name: origin.split('/').slice(-1)[0]
        }
      } else {
        return {
          ...fileItem,
          ...origin
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.CommonUpload {
  .tip {
    color: $tipText-color;
    font-size: 12px;
  }
}

.CommonUpload.picture {
  /* display: flex; */
  :deep(.Upload) {
    order: 100;
  }
  .CommonUpload-Upload-Button {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-text {
      font-size: 12px;
      padding: 4px 0;
    }
    .el-icon-folder-add {
      font-size: 26px;
    }
    &:hover {
      border-color: $primary-color;
    }
  }
}
</style>
