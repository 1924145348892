import http from '@/utils/request'

// 获取审批数据
export const getApprovalData = params =>
  http({
    url: '/dealermanage/shopRenovation/details',
    params
  })

//提交审批数据
export const submitApprovalData = data =>
  http({
    url: '/dealermanage/shopRenovation/submitApproveData',
    data,
    method: 'POST'
  })

//提交流程
export const submitFlow = data =>
  http({
    url: '/dealermanage/shopRenovation/submit',
    data,
    method: 'POST'
  })
