import http from "@/utils/request";

// 获取反馈列表
export const getFeedBack = params => {
  return http({
    url:"/dealermanage/feedback/listpage",
    params
  })
}

// 提交反馈
export const saveFeedBack = data => {
  return http({
    url:'/dealermanage/feedback/add',
    data,
    method:'post'
  })
}
