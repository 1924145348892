import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem'

export const renderFormItem = (render, config = {}) => {
  const { rules, label, formItemProps, required, prop, components, computed, props, ...rest } =
    config
  return {
    render(h) {
      return (
        <RequiredFormItem
          label={this.label}
          prop={prop || this.config?.name}
          rules={rules}
          required={this.type === 'search' ? false : required === true}
          attrs={formItemProps}
        >
          {render(h, this)}
        </RequiredFormItem>
      )
    },
    components: { RequiredFormItem, ...components },
    props: {
      config: { type: Object, default: null },
      type: { type: String, default: 'edit' },
      ...props
    },
    computed: {
      label() {
        if (label !== undefined) {
          return label
        }
        return this.config?.label || this.config?.title
      },
      ...computed
    },
    ...rest
  }
}

export const renderHeader = (render, config = {}) => {
  return {
    render(h) {
      return render(h, this)
    },
    ...config
  }
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
