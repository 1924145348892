<script setup>
import { reactive, ref } from 'vue'
import { updateTrainingDealerPermission } from '../api'
import { Message } from 'element-ui'

const props = defineProps({
  open: {
    required: true,
    type: Boolean
  },
  dealer: {
    required: true,
    type: Object
  }
})
const emit = defineEmits({
  'update:open': _v => true,
  updated: _form => true
})

function onOpen() {
  form.isOpen = props.dealer.isOpen
  form.closeOrderTime = props.dealer.closeOrderTime
  form.remark = props.dealer.remark
}
function onClose() {
  resetForm()
  emit('update:open', false)
}

const form = reactive({
  isOpen: 1,
  closeOrderTime: '',
  remark: ''
})
function resetForm() {
  form.isOpen = 1
  form.closeOrderTime = ''
  form.remark = ''
}

const waiting = ref(false)
async function onConfirm() {
  if (waiting.value) return
  waiting.value = true
  await updateTrainingDealerPermission({
    id: props.dealer.id,
    ...form
  }).finally(() => (waiting.value = false))
  Message.success('修改成功')
  emit('update:open', false)
  emit('updated', {
    id: props.dealer.id,
    ...form
  })
}

const pickerOptions = {
  disabledDate(time) {
    const day = 24 * 60 * 60 * 1000
    return time.getTime() + day < Date.now()
  }
}
</script>

<template>
  <el-dialog
    title="编辑手工开启权限"
    width="500px"
    :visible="open"
    @open="onOpen"
    @close="onClose">
    <el-form label-width="150px" label-position="right">
      <el-form-item label="是否手工开启">
        <el-switch
          :value="form.isOpen === 1"
          @change="v => (form.isOpen = v ? 1 : 0)" />
      </el-form-item>
      <el-form-item label="关闭下单权限时间">
        <el-date-picker
          style="width: 100%"
          v-model="form.closeOrderTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          :picker-options="pickerOptions" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" maxlength="800" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" @click="onConfirm" :loading="waiting"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>
