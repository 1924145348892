import {
  Loading
} from 'element-ui';
let $load = {}
let loadLine = null
let options = {
  lock: true,
  spinner: 'el-icon-loading',
  background: 'rgba(255, 255, 255)'
}
$load.show = (type) => {
  options = type?{...options,...type}:options
  loadLine = Loading.service(options);
}

$load.close = () => {
  loadLine.close()
}


export default $load
