<template>
  <el-dialog
    :visible="open"
    @open="onOpen"
    @close="onClose"
    width="700px"
    :title="props.coefficientId ? '装修补贴详情' : '新增装修补贴'">
    <div v-loading="props.coefficientId && !coefficient?.id">
      <el-form
        label-width="100px"
        label-position="right"
        ref="formRef"
        :model="coefficient"
        v-if="coefficient">
        <el-form-item
          label="补贴类型"
          prop="name"
          :rules="{
            required: true,
            trigger: 'blur',
            message: '请输入补贴类型'
          }">
          <el-input
            style="width: 250px"
            v-model="coefficient.name"
            placeholder="请输入补贴类型" />
        </el-form-item>
        <el-form-item label="">
          <el-card
            style="margin-bottom: 12px"
            v-for="(rule, idx) in coefficient.subsidyOperationRules"
            :key="rule.id"
            shadow="none">
            <template #header>
              <div class="flex flex-justify-between">
                <div class="flex">
                  <el-input
                    v-model="rule.name"
                    placeholder="请输入条件名称"
                    style="width: 230px" />
                </div>
                <div class="flex">
                  <div>补贴上限</div>
                  <el-input
                    v-model="rule.value"
                    style="width: 150px; margin-left: 8px" />
                  <el-button
                    style="margin-left: 12px"
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    @click="onCoefficientRuleRemove(idx)" />
                </div>
              </div>
            </template>
            <div
              class="flex flex-justify-between"
              v-for="condition in rule.content"
              style="margin-bottom: 12px">
              <CoefficientConditionSelect
                :key="`${condition.value}:${condition.valueView}`"
                :value="condition"
                @update:value="
                  v =>
                    Object.keys(condition).forEach(k => (condition[k] = v[k]))
                " />
              <div>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  plain
                  @click="
                    onCoefficientConditionRemove(
                      `${condition.value}:${condition.valueView}`,
                      rule
                    )
                  " />
              </div>
            </div>
            <el-button
              style="width: 100%; display: block"
              icon="el-icon-plus"
              @click="onCoefficientConditionAdd(rule)" />
          </el-card>
          <el-button
            style="width: 100%; display: block"
            icon="el-icon-plus"
            @click="onCoefficientRuleAdd"
            >添加条件</el-button
          >
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch
            :value="coefficient.enable === 0"
            @change="v => (coefficient.enable = v ? 0 : 1)" />
        </el-form-item>
        <el-form-item label="">
          <el-button @click="onClose">取消</el-button>
          <el-button
            type="primary"
            :loading="saveWaiting"
            @click="onCoefficientSave"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { Message } from 'element-ui'
import CoefficientConditionSelect from '../../components/CoefficientConditionSelect.vue'
import {
  getDecorationSubsidy,
  saveDecorationSubsidy
} from '@/api/commonConfig/decorationSubsidy'
const props = defineProps({
  open: {
    required: true,
    type: Boolean
  },
  coefficientId: {
    required: true,
    type: String
  }
})
const emit = defineEmits(['update:open', 'saved'])

async function onOpen() {
  coefficient.value = getInitialCoefficient()
  if (props.coefficientId) {
    const res = await getDecorationSubsidy(props.coefficientId)
    coefficient.value = res
  }
}
function onClose() {
  emit('update:open', false)
}

const coefficient = ref()
function getInitialCoefficient() {
  return {
    id: '',
    name: '',
    enable: 0,
    associatedBusinessId: 1,
    subsidyOperationRules: []
  }
}
function onCoefficientRuleAdd() {
  coefficient.value.subsidyOperationRules.push({
    name: '',
    value: '',
    content: []
  })
}
function onCoefficientRuleRemove(idx) {
  coefficient.value.subsidyOperationRules.splice(idx, 1)
}
function onCoefficientConditionAdd(rule) {
  rule.content.push({
    key: '',
    keyView: '',
    operation: '',
    value: '',
    valueView: ''
  })
}
function onCoefficientConditionRemove(key, rule) {
  rule.content = rule.content.filter(
    item => key !== `${item.value}:${item.valueView}`
  )
}
const formRef = ref()
const saveWaiting = ref(false)
async function onCoefficientSave() {
  formRef.value.validate(async valid => {
    if (!valid) return
    if (saveWaiting.value) return
    saveWaiting.value = true
    await saveDecorationSubsidy({
      id: coefficient.value.id ? coefficient.value.id : undefined,
      associatedBusinessId: coefficient.value.associatedBusinessId,
      enable: coefficient.value.enable,
      name: coefficient.value.name,
      subsidyOperationRules: coefficient.value.subsidyOperationRules
    }).finally(() => {
      saveWaiting.value = false
    })
    emit('update:open', false)
    emit('saved')
    Message.success(coefficient.value.id ? '修改成功' : '新增成功')
  })
}

function toNumber(value) {
  const trimValue = value.trim()
  if (trimValue === '') return ''
  const onlyNumber = trimValue.replace(/[^\d.]/g, '')
  if (Number.isNaN(Number(onlyNumber))) return ''
  return Number(onlyNumber).toPrecision()
}
</script>
