<template>
  <section class="app-main"
           :class="{'title-tag-fix':needTagsView&&fixedHeader}">
    <transition name="fade-transform"
                mode="out-in">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view :key="key" />
      </keep-alive>
      <router-view :key="key"
                   v-else />
    </transition>
    <el-backtop></el-backtop>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppMain',
  computed: {
    ...mapState({
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    key() {
      return this.$route.path
    }
  }
}
</script>

<style >
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 50px - 40px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px;
}
.tags-view-container + .app-main {
  padding-top: 120px;
}
.fixed-header + .app-main {
  padding-top: 80px;
}
.fixed-header + .title-tag-fix {
  padding-top: 120px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
