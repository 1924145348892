<template>
  <div class="role">
    <div class="role-action">
      <el-form label-width="80px" :inline="true">
        <el-form-item label="姓名">
          <el-input v-model="search.realName"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="search.phone"></el-input>
        </el-form-item>

        <el-form-item label="工号">
          <el-input v-model="search.code"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-refresh-left" @click="handleReset"
            >重置</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="state.drawerRole = true"
            >批量配置角色</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-table
      border
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      :data="state.userList"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      v-loading="state.loading">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="realName" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="sex" label="性别" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.sex == 1" type="info">男</el-tag>
          <el-tag v-if="scope.row.sex == 2" type="success">女</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="昵称" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="手机号" align="center">
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 0" type="success">正常</el-tag>
          <el-tag v-else type="danger">异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template #default="scope">
          <!-- <el-button type="text" @click="resetUserPassword(scope.row)">重置密码</el-button> -->
          <el-button type="text" @click="handleEdit(scope.row, true)"
            >查看详情</el-button
          >
          <el-button type="text" @click="handleEdit(scope.row, false)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        background
        :current-page="page.currPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>

    <el-drawer
      :title="state.isDetail ? '详情' : '修改'"
      :visible.sync="state.drawer"
      direction="rtl"
      size="30%;min-width:500px;">
      <div style="padding: 10px 20px">
        <el-form label-width="80px" :inline="false" :model="state.detail">
          <el-form-item label="姓名">
            <el-input
              v-model="state.detail.realName"
              :disabled="state.isDetail"></el-input>
          </el-form-item>
          <el-form-item label="姓别">
            <el-select
              v-model="state.detail.sex"
              placeholder="请选择"
              :disabled="state.isDetail">
              <el-option label="男" :value="1"> </el-option>
              <el-option label="女" :value="2"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="state.detail.phone"
              :disabled="state.isDetail"></el-input>
          </el-form-item>
          <el-form-item label="角色">
            <el-cascader
              placeholder="请选择角色"
              v-model="state.detail.roleList"
              filterable
              :show-all-levels="false"
              :options="state.roleOptions"
              :props="{ checkStrictly: true, multiple: true }"
              :disabled="state.isDetail"></el-cascader>
          </el-form-item>
          <el-form-item v-if="!state.isDetail">
            <el-button type="primary" @click="handleRoleSubmit">保存</el-button>
            <el-button @click="state.drawer = false">取消</el-button>
          </el-form-item>
        </el-form>

        <el-table
          :data="state.accountTypeList"
          v-if="state.isDetail"
          style="width: 100%">
          <el-table-column prop="accountId" label="账号id" align="center">
          </el-table-column>
          <el-table-column prop="code" label="账号" align="center">
          </el-table-column>
          <el-table-column prop="loginType" label="账号类型" align="center">
            <template #default="scope">
              <span v-if="scope.row.loginType === 10">工号</span>
              <span v-if="scope.row.loginType === 20">手机号</span>
              <span v-if="scope.row.loginType === 30">微信openid</span>
            </template>
          </el-table-column>
          <el-table-column prop="sourceNum" label="来源编号" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <el-drawer
      title="批量配置角色"
      :visible.sync="state.drawerRole"
      direction="rtl"
      size="30%;min-width:500px;">
      <div style="padding: 10px 20px">
        <el-form label-width="80px" :inline="false" :model="state.detail">
          <el-form-item label="角色">
            <el-cascader
              placeholder="请选择角色"
              v-model="state.selectRole"
              :show-all-levels="false"
              :options="state.roleOptions"
              :props="{ checkStrictly: true }"
              :disabled="state.isDetail"></el-cascader>
          </el-form-item>
          <el-form-item v-if="!state.isDetail">
            <el-button type="primary" @click="handleUserSubmit">保存</el-button>
            <el-button @click="state.drawerRole = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { Message } from 'element-ui'
import { reactive } from 'vue'
import {
  accountListPage,
  logintypeList,
  accountUserById,
  accountUpdate,
  accountroleBatchadd,
  resetPassword
} from '@/api/system/user'

import { allRoleList } from '@/api/system/role'
import { toTree } from '@/utils/index'
import { getSession } from '@/utils/storage'
const systemCode = process.env.VUE_APP_SYSTEM_CODE

const state = reactive({
  userList: [],
  selectId: [],
  drawer: false, //修改用户
  isDetail: false, //是否查看详情
  detail: {},
  deptOptions: [],
  roleOptions: [],
  drawerRole: false, //角色弹窗
  selectRole: [],
  accountTypeList: [],
  loading: false
})

const search = reactive({
  realName: '',
  phone: '',
  code: ''
})

//分页数据
const page = reactive({
  currPage: 1,
  pageSize: 10,
  totalCount: 1
})

const init = async () => {
  getList()

  //获取角色列表
  const roleRes = await allRoleList({ system_id: systemCode })
  state.roleOptions = toTree(roleRes.data, {
    parentId: 'parentId',
    id: 'id',
    label: 'roleName'
  })
}
init()

const handleSearch = () => getList(page.currPage, page.pageSize)

const handleReset = () => {
  search.realName = ''
  search.phone = ''
  search.code = ''
  getList(page.currPage, page.pageSize)
}

//表格选中的用户
const handleSelectionChange = val => {
  state.selectId = val.map(item => ({ accountId: item.id }))
  console.log(state.selectId)
}

//重置密码
const resetUserPassword = async item => {
  console.log(item)
  const { code } = await resetPassword({ resetAccountId: item.id })
  if (code != 0) return
  Message.success('重置成功')
}

//查看、修改
const handleEdit = async (item, isDetail) => {
  isDetail ? (state.isDetail = true) : (state.isDetail = false)
  //获取用户详情
  const res = await accountUserById({ id: item.id, systemId: systemCode })
  state.detail = res.data
  state.detail.roleList = res.data.roleList.map(item => String(item.roleId))

  console.log(state.detail.roleList)
  state.drawer = true
  const { data } = await logintypeList({ accountId: item.id })
  state.accountTypeList = data
}

const handleSizeChange = pageSize => getList(page.currPage, pageSize)
const handleCurrentChange = currPage => {
  page.currPage = currPage
  getList(currPage, page.pageSize)
}
async function getList(currPage = 1, pageSize = 10) {
  state.loading = true
  try {
    const { data, totalCount } = await accountListPage({
      ...search,
      systemId: systemCode,
      currPage,
      pageSize,
      userRole: 1
    })
    state.userList = data
    page.totalCount = totalCount
  } finally {
    state.loading = false
  }
}

const handleUserSubmit = () => {
  const { selectId, selectRole } = state
  const params = {
    systemId: systemCode,
    accountRoleList: selectId.map(item => ({ ...item, roleId: selectRole[0] }))
  }
  accountroleBatchadd(params).then(res => {
    Message.success(res.msg)
    state.drawerRole = false
  })
}

const handleRoleSubmit = async () => {
  const { id, realName, sex, headPortrait, roleList } = state.detail
  const role = roleList
    ? roleList.flat(Infinity).map(item => ({ roleId: item }))
    : []
  const { msg } = await accountUpdate({
    systemId: systemCode,
    id,
    realName,
    sex,
    headPortrait,
    roleList: role
  })
  Message.success(msg)
  state.detail = {}
  state.drawer = false
}
</script>

<style lang="scss" scoped>
.role {
  &-action {
    background-color: #fff;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .page {
    margin: 30px 0;
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
  }
}
</style>
