<!-- 新渠道经销商合作网点报备流程 -->
<template>
  <div>
    <FlowForm
      ref="flowFormRef"
      title="新渠道经销商合作网点报备流程"
      fd-form-template-code="distributor-JXS026"
      :model="form"
      :is-edit="[0,20].includes(Number(form.dealerDeclarationForm.status))"
      :get-detail="getDetail"
      :on-submit="onSubmit"
      :approval-data="approvalData"
      :on-approval="onApproval"
    >
      <div>
        <RequiredFormItemInput
          delayUpdate
          label="标题"
          class="formItem"
          :value="form.dealerDeclarationForm.title"
          disabled
          :required="false"
        />
        <RequiredFormItemInput
          delayUpdate
          label="单号"
          class="formItem"
          :value="form.dealerDeclarationForm.orderNumber"
          placeholder="自动生成"
          disabled
          :required="false"
        />
        <RequiredFormItemInput
          delayUpdate
          label="招商单号"
          class="formItem"
          :value="form.dealerDeclarationForm.zsOrderNumber"
          placeholder="自动生成"
          disabled
          :required="false"
        />
      </div>
      <HeaderTitle style="margin-top: 20px" title="经销商个人基础信息" />
      <div>
        <RequiredFormItemInput
          class="formItem"
          label="提单人工号"
          :value="form.dealerDeclarationForm.optJobNumber"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="提单人"
          :value="form.dealerDeclarationForm.optUserName"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="提单部门"
          :value="form.dealerDeclarationForm.optDeptName"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="提单日期"
          :value="form.dealerDeclarationForm.optTime"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemSelect
          class="formItem"
          ref="businessRef"
          prop="businessLicenseId"
          v-model="form.businessLicenseId"
          label="售达方"
          labelKey="licenseNumber"
          valueKey="id"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :total-count="businessLicensesCount"
          :getDataList="getBusinessLicenses"
          :getNextList="getBusinessLicenses"
          @onOptionsListNotFound="onBusinessLicensesNotFound"
          @change="onBusinessLicenseChange"
          @changeSelectedItem="onBusinessLicenseSelect"
        >
          <template v-slot="{ scope }">
            <span>{{ scope.row.licenseNumber }}</span>
            <small>{{ scope.row.businessLicenseName }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemInput
          class="formItem"
          label="售达方名称"
          :value="form.businessLicenseName"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemSelect
          v-model="form.senderId"
          class="formItem"
          prop="senderId"
          label="送达方"
          placeholder="请选择售达方下对应送达方"
          labelKey="shopBrandNumber"
          valueKey="id"
          emptyDisabled
          :disabled="!form.businessLicenseId"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :getDataList="getSenders"
          :nextDataList="getSenders"
          :getDataListParams="form.businessLicenseId"
          @onOptionsListNotFound="onSendersNotFound"
          @change="onSenderChange"
          @changeSelectedItem="
            e => {
              selectedSenderItem = e
              form.senderId = e ? e.id : ''
              form.senderName = e ? e.authorizedName : ''
              form.senderNumber = e ? e.shopBrandNumber : ''
              if(e){
                getAuthorizedCityDetail(e.authorizedCityCode)
              }
            }
          "
        >
          <template v-slot="{ scope }">
            <span>{{ scope.row.shopBrandNumber }}</span>
            <small>{{ scope.row.authorizedName }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemInput
          class="formItem"
          label="送达方名称"
          :value="form.senderName"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="省"
          :value="form.province"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="市"
          :value="form.city"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="区/县"
          :value="form.area"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="营销部"
          :value="form.marketingDeptName"
          placeholder="自动生成"
          disabled
        />
        <RequiredFormItemInput
          class="formItem"
          label="营销中心"
          :value="form.marketCenterName"
          placeholder="自动生成"
          disabled
        />
      </div>
      <CommonTable
        :default-data-list="form.networkList"
        :columns="networkListColumns"
        :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
        style="border: 0; border-radius: 0"
      />
    </FlowForm>
  </div>
</template>

<script setup >
import {ref, reactive, computed, onMounted } from "vue";
import { Message } from "element-ui";
import dateFormat from "dateformat";
import FlowForm from "@/views/bpmFlow/components/flowForm.vue";
import CommonTable from '@/components/TablePro/Table.vue'
import { render } from '@/components/TablePro/fn'
import RequiredFormItemInput from "@/components/CustomFormItem/requiredFormItemInput.vue";
import RequiredFormItemSelect from "@/components/CustomFormItem/requiredFormItemSelect.vue";
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredFormItemDatePicker from "@/components/CustomFormItem/requiredFormItemDatePicker.vue";
import RequiredFormItemUploadFile from "@/components/CustomFormItem/requiredFormItemUploadFile.vue";
import RemarkItem from "@/views/flow/components/remarkItem.vue";
import HeaderTitle from "@/views/bpmFlow/components/headerTitle.vue";
import store from "@/store";
import { getByAuthorizedCityCode,requestGetSizeBarListByDept} from "@/api/common";
import * as BusinessApi from "@/api/attractPlatform/business";
import { getMasterDataList } from '@/api/businessOos/index'
import * as newChannelNetworkJoin from "@/views/bpmFlow/api/newChannelDealerNetworkReport"
import { useRoute, useRouter } from "@/utils/router";
const $route = useRoute();
const routeQuery = $route.query;
const flowFormRef = ref()
const user = store.state.user.userInfo;
const isOA = computed(() => store.state.dealershipTransfer.isOA);
const isMobile = computed(() => store.state.app.device === 'mobile'||store.state.app.isWxworkMoblie )
const form = reactive({
  dealerDeclarationForm: {
    id: "", //表单id
    title: "新渠道经销商合作网点报备流程", //标题
    orderNumber: "",//单号
    zsOrderNumber:"",//招商单号
    optJobNumber: "", //提单人工号
    optUserId: "", //提单人id
    optUserName: "", //提单人
    optDeptId: "", //提单部门id
    optDeptName: "", //提单部门
    optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss') //提单日期
  },
  businessLicenseId:"",//售达方id
  businessLicenseName: "", //售达方名称
  businessLicenseNumber: "", //售达方编号
  senderName: "", //送达方名称
  senderNumber: "", //送达方编号
  senderId: "", //送达方id
  province: "", //省份
  city: "", //地级市
  area: "", //区
  marketingDeptName: "", //营销部（大区）名称
  marketingDeptCode: "", //营销部编码（大区）
  marketCenterName: "", //营销中心名称（小区）
  marketCenterCode: "", //营销中心编码（小区）
  networkList:[
    {
      networkNumber:"",//网点编号
      networkName:"",//网点名称
      partnerBusinessLicenseName:"",//合作方营业执照全称
      partnerChannelShortName:"",//合作渠道简称
      channelCode:"",//所属渠道
      cooperationModeCode:"",//合作模式编码
      cooperationModeName:"",//合作模式名称
      cooperationModeOther:"",//合作模式其他名称
      sampleFlag:"",//是否上样
      networkDetailAddress:"",//合作网点所在详细地址
      cooperationStartTime:"",//合作开始时间
      networkImgUrlList:[],//合作网点照片
      sampleImgUrlList:[],//上样照片
      contractFileList:[],//合同附件
      designNetwork:"",//是否设计网点
      networkArea:""//网点面积（㎡）
    }
  ]//网点列表
});
const businessLicensesCount = ref(0)
const selectedBusinessLicenseItem = ref()
const selectedSenderItem = ref()
const approvalData = ref({})
const hideInTable = computed(() => {
 if(!isNaN(Number(form.dealerDeclarationForm.status))){
  return (![0,20].includes(Number(form.dealerDeclarationForm.status)));
 }else{
  return false
 }
  
})
const networkListColumns = ref([
  {
    name: 'bran',
    width: 60,
    hideInTable,
    tableColumnProps: {
      align: 'center'
    },
    title: render((h, context) => (
      <span
        class='el-icon-circle-plus iconfont'
        onClick={()=>{addNetworkList()}}
      />
    )),
    render: render((h, context) => (
      <span
        class='el-icon-remove iconfont'
        onClick={()=>{removeTableItem(form.networkList,context.index)}}
      />
    ))
  },
  { title: '序号', width: 55, valueType: 'index' },
  {
    title:'网点编号',
    name:'networkNumber',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemInput
          value={context.data.networkNumber}
          delay-update
          class='formItem'
          style='width: 100%'
          placeholder='系统生成'
          label={context.config.title}
          show-label={false}
          disabled
        />
      )
    })
  },
  {
    title:'网点名称',
    name:'networkName',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemInput
          value={context.data.networkName}
          delay-update
          class='formItem'
          style='width: 100%'
          placeholder='系统生成'
          label={context.config.title}
          show-label={false}
          disabled
        />
      )
    })
  },
  {
    title:'合作方营业执照全称',
    name:'partnerBusinessLicenseName',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemInput
            prop={`networkList.${context.index}.partnerBusinessLicenseName`}
            value={context.data.partnerBusinessLicenseName}
            onInput={
              e=>{
                context.data.partnerBusinessLicenseName=e
              }
            }
            delay-update
            class='formItem'
            style='width: 100%'
            label={context.config.title}
            show-label={false}
            maxLength={30}
          />
      )
    })
  },
  {
    title:'合作渠道简称',
    name:'partnerChannelShortName',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemInput
          prop={`networkList.${context.index}.partnerChannelShortName`}
          value={context.data.partnerChannelShortName}
          onInput={
              e=>{
                context.data.partnerChannelShortName=e
              }
          }
          delay-update
          class='formItem'
          style='width: 100%'
          label={context.config.title}
          show-label={false}
          maxLength={4}
        />
      )
    })
  },
  {
    title:'所属渠道',
    name:'channelCode',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredModuleFormItemSelect
          value={context.data.channelCode}
          onInput={
              e=>{
                context.data.channelCode=e
              }
          }
          type='new_channel_network_channel'
          required
          prop={`networkList.${context.index}.channelCode`}
          label={context.config.title}
          show-label={false}
        />
      )
    })
  },
  {
    title:'合作模式',
    name:'cooperationMode',
    width:200,
    render: render((h, context) => {
      return (
        <div>
          <RequiredModuleFormItemSelect
            value={context.data.cooperationModeCode}
            onInput={
                e=>{
                  context.data.cooperationModeCode=e
                }
            }
            type='new_channel_network_cooperation'
            required={true}
            prop={`networkList.${context.index}.cooperationModeCode`}
            label={context.config.title}
            show-label={false}
            onChangeSelectedItem={e=>{
              context.data.cooperationModeName = e?.content
              if(context.data.cooperationModeName!="其他"){
                context.data.cooperationModeOther=''
              }
            }}
          />
          {context.data.cooperationModeName==="其他"&&(
            <RequiredFormItemInput
              prop={`networkList.${context.index}.cooperationModeOther`}
              value={context.data.cooperationModeOther}
              onInput={
                  e=>{
                    context.data.cooperationModeOther=e
                  }
              }
              delay-update
              label={context.config.title}
              show-label={false}
            />
          )}
        </div>
      )
    })
  },
  {
    title:'是否上样',
    name:'sampleFlag',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemSelect
            prop={`networkList.${context.index}.sampleFlag`}
            value={context.data.sampleFlag}
            onInput={
                e=>{
                  context.data.sampleFlag=e
                }
            }
            label={context.config.title}
            class='formItem'
            show-label={false}
            label-key='label'
            value-key='value'
            data-list={[
              { label: '是', value: 1 },
              { label: '否', value: 0 }
            ]}
          />
      )
    })
  },
  {
    title:'合作网点所在详细地址',
    name:'networkDetailAddress',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemInput
          prop={`networkList.${context.index}.networkDetailAddress`}
          value={context.data.networkDetailAddress}
          onInput={
              e=>{
                context.data.networkDetailAddress=e
              }
          }
          delay-update
          class='formItem'
          style='width: 100%'
          label={context.config.title}
          show-label={false}
          type="textarea"
          rows={2}
        />
      )
    })
  },
  {
    title:'合作开始时间',
    name:'cooperationStartTime',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemDatePicker
          prop={`networkList.${context.index}.cooperationStartTime`}
          value={context.data.cooperationStartTime}
          onInput={
              e=>{
                context.data.cooperationStartTime=e
              }
          }
          placeholder={`请选择${context.config.title}`}
          label={context.config.title}
          show-label={false}
        />
      )
    })
  },
  {
    title:'合作网点照片',
    name:'networkImgUrlList',
    width:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemUploadFile
          value={context.data.networkImgUrlList}
          onChangeValue={
              e=>{
                context.data.networkImgUrlList=e
              }
          }
          class='formItem'
          limit-size='400'
          prop={`networkList.${context.index}.networkImgUrlList`}
          label={context.config.title}
          show-label={false}
          auto-upload
          scopedSlots={{
            tip(){
              return <div>单个文件不能超过400M</div>
            }
          }}
        >
        </RequiredFormItemUploadFile>
      )
    })
  },
  {
    title:'上样照片',
    name:'sampleImgUrlList',
    width:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemUploadFile
          value={context.data.sampleImgUrlList}
          onChangeValue={
              e=>{
                context.data.sampleImgUrlList=e
              }
          }
          class='formItem'
          limit-size='400'
          prop={`networkList.${context.index}.sampleImgUrlList`}
          label={context.config.title}
          show-label={false}
          auto-upload
          scopedSlots={{
            tip(){
              return <div>单个文件不能超过400M</div>
            }
          }}
          required={context.data.sampleFlag==0?false:true}
        >
        </RequiredFormItemUploadFile>
      )
    })
  },
  {
    title:'合同附件',
    name:'contractFileList',
    width:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemUploadFile
          value={context.data.contractFileList}
          onChangeValue={
              e=>{
                context.data.contractFileList=e
              }
          }
          class='formItem'
          limit-size='400'
          prop={`networkList.${context.index}.contractFileList`}
          label={context.config.title}
          show-label={false}
          auto-upload
          scopedSlots={{
              tip(){
                return ( <div>单个文件不能超过400M</div>)
              }
            }
          }
        >
        </RequiredFormItemUploadFile>
      )
    })
  },
  {
    title:'是否设计网点',
    name:'designNetwork',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemSelect
          prop={`networkList.${context.index}.designNetwork`}
          value={context.data.designNetwork}
          onChange={
              e=>{
                context.data.designNetwork=e
              }
          }
          label={context.config.title}
          class='formItem'
          show-label={false}
          label-key='label'
          value-key='value'
          data-list={[
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ]}
        />
      )
    })
  },
  {
    title:'网点面积（㎡）',
    name:'networkArea',
    minWidth:200,
    render: render((h, context) => {
      return (
        <RequiredFormItemInput
          type='number'
          min={0}
          prop={`networkList.${context.index}.networkArea`}
          value={context.data.networkArea}
          onInput={
              e=>{
                context.data.networkArea=e
              }
          }
          rules={validNumber()}
          delay-update
          class='formItem'
          style='width: 100%'
          label={context.config.title}
          show-label={false}
          maxLength={4}
        />
      )
    })
  }
])
const validNumber = () => {
  return [
    {
      validator(rule, value, callback) {
        if (value && !/^([1-9][0-9]*|0)(\.[0-9]{1})?$/.test(value)) {
          callback(new Error('请填写数字值'));
        } else {
          callback();
        }
      },
      trigger: 'blur'
    }
  ]
}
const removeTableItem = (data,index) => {
  if(data.length==1){
    Message.error("至少保留一个合作网点")
    return
  }
  data.splice(index,1)
}
const addNetworkList = () => {
  form.networkList.push({
    networkNumber:"",//网点编号
    networkName:"",//网点名称
    partnerBusinessLicenseName:"",//合作方营业执照全称
    partnerChannelShortName:"",//合作渠道简称
    channelCode:"",//所属渠道
    cooperationModeCode:"",//合作模式编码
    cooperationModeName:"",//合作模式名称
    cooperationModeOther:"",//合作模式其他名称
    sampleFlag:"",//是否上样
    networkDetailAddress:"",//合作网点所在详细地址
    cooperationStartTime:"",//合作开始时间
    networkImgUrlList:[],//合作网点照片
    sampleImgUrlList:[],//上样照片
    contractFileList:[],//合同附件
    designNetwork:"",//是否设计网点
    networkArea:""//网点面积（㎡）
  })
}
const getBusinessLicenses= async ({
      page,
      searchText,
      businessLicenseId
    }) => {
      const res = await BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      })
      businessLicensesCount.value = res.totalCount
      const item = res.data.find(
        item => item.id === form.businessLicenseId
      )
      if (item) {
        form.businessLicenseId = item.id
        form.businessLicenseName = item.businessLicenseName
      }
      return res.data
    }
const onBusinessLicensesNotFound = async (
  { value: businessLicenseId },
  callback
) => {
  const res = await BusinessApi.license({
    businessLicenseId
  })
  callback(res.data)
}
const onBusinessLicenseChange = businessLicenseId => {
  form.senderId = ''
  form.senderNumber = ''
  form.senderName = ''
  form.province = ''
  form.city = ''
  form.area = ''
  form.marketingDeptName = ''
  form.marketingDeptCode = ''
  form.marketCenterName = ''
  form.marketCenterCode = ''
}
const onBusinessLicenseSelect = item => {
    selectedBusinessLicenseItem.value = item
    form.businessLicenseId = item.id
    form.businessLicenseName = item.businessLicenseName
    form.businessLicenseNumber = item.licenseNumber
}
const onSenderChange = senderId => {
  form.province = ''
  form.city = ''
  form.area = ''
  form.marketingDeptName = ''
  form.marketingDeptCode = ''
  form.marketCenterName = ''
  form.marketCenterCode = ''
}
const getSenders = async ({
  getDataListParams: businessLicenseId,
  searchText
}) =>{
  if (!businessLicenseId) {
    return []
  }
  const res = await getMasterDataList({
    businessLicenseId,
    shopBrandNumber: searchText
  })
  return res
}
const onSendersNotFound = async (e, callback) => {
  const res = await getMasterDataList({
    shopBrandNumber: form.senderNumber
  })
  callback(res.data)
}
const getAuthorizedCityDetail = async authorizedCityCode => {
 const res = await getByAuthorizedCityCode({authorizedCityCode})
 const {province="",provinceCode="",city="",cityCode="",area="",areaCode="",townCode=""} = res.data
 form.province = province 
 form.city = city
 form.area = area
 getMarketingInfo({
  provinceCode,
  cityCode,
  areaCode,
  townCode
 })
}
const getMarketingInfo = async params => {
  const res = await requestGetSizeBarListByDept(params)
  const {administrativeName="",administrativeCode="",smallName="",smallCode=""} = res.data
  form.marketingDeptName = administrativeName
  form.marketingDeptCode = administrativeCode
  form.marketCenterName = smallName
  form.marketCenterCode = smallCode
}
const onApproval = async (bpmExecuteBean, bpmDealerJoinApproveModel) => {
  if (Number(form.dealerDeclarationForm.status) === 20) {
    form.approveAccount = user.code || user.account
    form.bpmExecuteBean = bpmExecuteBean
    form.bpmDealerJoinApproveModel = bpmDealerJoinApproveModel
    return flowFormRef.value.onCommit('submit')
  }

  return newChannelNetworkJoin.submitApproveData({
    formId: routeQuery.id || routeQuery.businessId,
    approveAccount: user.code || user.account,
    bpmExecuteBean,
    bpmDealerJoinApproveModel
  })
}
const getDetail = async (type, cachedFormData) => {
  let initFormData = cachedFormData
  // 编辑状态
  if (type === 'detail') {
    const { data } = await newChannelNetworkJoin.getDetail({
      formId: routeQuery.id || routeQuery.businessId
    })
    initFormData = data
  }

  if (initFormData) {
    for (const key in form) {
      if (initFormData[key] !== undefined) {
        form[key] = initFormData[key]
        if(key==='networkList'){
          form.networkList = form.networkList.map(item=>{
            const {
              networkNumber='',
              networkName='',
              partnerBusinessLicenseName='',
              partnerChannelShortName='', 
              channelCode="",
              cooperationModeCode="",
              cooperationModeName="",
              cooperationModeOther="",
              sampleFlag="",
              networkDetailAddress="",
              cooperationStartTime="",
              networkImgUrlList=[],
              sampleImgUrlList=[],
              contractFileList=[],
              designNetwork="",
              networkArea=""
            } = item
            return {
              networkNumber,
              networkName,
              partnerBusinessLicenseName,
              partnerChannelShortName, 
              channelCode,
              cooperationModeCode,
              cooperationModeName,
              cooperationModeOther,
              sampleFlag,
              networkDetailAddress,
              cooperationStartTime,
              networkImgUrlList,
              sampleImgUrlList,
              contractFileList,
              designNetwork,
              networkArea
            }
          })
        }
      }
    }
  
    approvalData.value = initFormData.bpmDealerJoinApproveModel
  }

  if (user && !isOA.value) {
    const {
      optUserId,
      optJobNumber,
      optUserName,
      optDeptId,
      optDeptName,
      ...rest
    } = form.dealerDeclarationForm || {}
    form.dealerDeclarationForm = {
      ...rest,
      optUserId: optUserId || user.accountId,
      optJobNumber: optJobNumber || user.code,
      optUserName: optUserName || user.realName,
      optDeptId: optDeptId || user.deptId,
      optDeptName: optDeptName || user.deptName
    }
  }
};
const onSubmit = async type => {
  const time = dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss')
  // form.dealerBoss.createTime = time
  // form.dealerBusinessLicense.createTime = time
  form.dealerDeclarationForm.optTime = time
  form.fdFormTemplateCode = 'distributor-JXS026'
  form.pageVersion = 'V1'
  form.systemCode = 'distributor'
  form.dealerDeclarationForm.title = `新渠道经销商合作网点报备流程(${selectedBusinessLicenseItem.value?.realName ||''},${selectedSenderItem.value?.authorizedCityName || ''})`
  const { data } = await newChannelNetworkJoin.saveDraft(form)
  const formId = data.id
  if (type !== 'submit') {
    return formId
  }
  const params = {
    formId: data.id
  }
  return newChannelNetworkJoin.submit(params)
};
</script>

<style></style>
