<template>
  <el-dropdown trigger="click" @command="handleChangeTheme">
    <span class="el-dropdown-link" :style="{ color: color }">
      主题选择<i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="item of themeOptions"
        :key="item.value"
        :disabled="theme === item.value"
        :command="item.value"
        style="padding: 5px 15px; display: flex; align-items: center">
        <span
          :style="{
            background: item.color,
            display: 'block',
            width: '20px',
            height: '20px',
            margin: '10px'
          }"></span>
        {{ item.label }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  data() {
    return {
      themeOptions: [
        { label: '默认主题', value: 'default-theme', color: '#cfbd95' },
        { label: '深蓝主题', value: 'primary-theme', color: '#409eff' },
        { label: '黑色主题', value: 'black-theme', color: '#000000' }
      ],
      color: '#cfbd95'
    }
  },
  computed: {
    theme() {
      this.color = this.themeOptions.find(
        item => item.value == this.$store.getters.theme
      )['color']
      return this.$store.getters.theme
    }
  },
  methods: {
    handleChangeTheme(val) {
      this.color = this.themeOptions.find(item => item.value == val)['color']
      this.$store.commit('settings/CHANGE_THEME', val)
    }
  }
}
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
