<template>
  <div v-if="!item.hidden">
    <template v-if="item.children&&item.children.length == 0||(!item.children)">
      <app-link :to="resolvePath(item.path)">
        <el-menu-item :index="item.path"
                      :class="{'submenu-title-noDropdown':!isNest}">
          <item :icon="item.parentId==0?item.icon:''"
                :title="item.name" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else
                ref="subMenu"
                :index="item.id"
                popper-append-to-body>
      <template slot="title">
        <item v-if="item.name"
              :icon="item.icon"
              :title="item.name" />

      </template>
      <sidebar-item v-for="child in item.children"
                    :key="child.id"
                    :is-nest="true"
                    :item="child"
                    :base-path="resolvePath(child.path)"
                    class="nest-menu" />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'
import { authLogin } from '@/api/user'
import { getStorage } from '@/utils/storage'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath = '') {
      const reg = /^http.+[welcome/index]$/
      if (reg.test(routePath)) {
        return (
          routePath + '?systemCode=30&account=' + getStorage('userInfo')['code']
        )
      }

      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
