<template>
  <required-form-item-select
    v-bind="{
      labelKey:'name',
      valueKey: 'id',
      disabledKey: 'ifDelete',
      multiple: true,
      clearable: true,
      filterDataList: onFilterDataList,
      getDataList: getBrandList,
      ...$attrs,
      ...selectProps
    }"
    @changeSelectedItem="onChangeSelectedItem"
    v-on="$listeners"
  />
</template>

<script>
import { brandseriesList } from '@/api/common/'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
const singleTextList = ['慕思助眠']
const multipleTextList = ['慕思时尚', '慕思通配']
export default {
  components: {
    RequiredFormItemSelect
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  props: {
    filterDataList: {
      tyep: Function,
      default: null
    },
    selectProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      brandList: null,
      parentList: []
    }
  },
  computed: {
    parentIds({ parentList }) {
      const single = {}
      const multiple = {}
      parentList.forEach(item => {
        if (singleTextList.includes(item.name)) {
          single[item.id] = item.name
        } else if (multipleTextList.includes(item.name)) {
          multiple[item.id] = item.name
        }
      })
      return {
        single,
        multiple
      }
    }
  },
  watch: {},
  methods: {
    onFilterDataList(list) {
      const _list = list
        .map(item => {
          if (this.parentIds.single[item.parentId]) {
            item.selectType = 'single'
          } else if (this.parentIds.multiple[item.parentId]) {
            item.selectType = 'multiple' // single or multiple or never
          } else {
            item.selectType = 'never'
          }

          return item
        })
        .filter(item => {
          if (Array.isArray(this.brandList) && this.brandList.length) {
            const { selectType } = this.brandList[0]
            return item.selectType === selectType
          } else {
            return true
          }
        })
      if (this.filterDataList) {
        return this.filterDataList(_list)
      }
      return _list
    },
    onChangeSelectedItem(selected) {
      // if (Array.isArray(selected) && selected.length) {
      //   const { selectType } = selected[0];

      //   for (const item of select) {
      //     if (item.selectType === selectType) {
      //       continue;
      //     }
      //     switch (selectType) {
      //       case "single":
      //         this.$message.error(
      //           `${singleTextList.join("和")}是独立店，不能和其他系列一起加盟`
      //         );
      //         break;
      //       case "multiple":
      //         this.$message.error(
      //           `该系列无法与其他系列一起加盟（只允许选择${multipleTextList.join(
      //             "或"
      //           )}下的系列）`
      //         );
      //         break;
      //       case "never":
      //         this.$message.error(`待定`);
      //         break;
      //     }
      //   }

      //   this.brandList = selected.filter(item => {
      //     if (Array.isArray(selected) && selected.length) {
      //       return item.selectType === selectType;
      //     }
      //     return true;
      //   });
      // }
      this.brandList = selected
    },
    async getBrandList() {
      return brandseriesList({
        ifDelete: -1
      }).then(res => {
        this.parentList = []
        return res.data.filter(item => {
          if (!item.parentId || item.parentId === '0') {
            this.parentList.push(item)
            return false
          }
          return true
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
