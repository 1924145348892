import http ,{download} from "@/utils/request";
// 门店数据管理列表
export const updateshopList = params => {
  return http({
    url: '/dealermanage/updateshop/listpage',
    params,
    method: 'GET'
  })
}

//状态修改
export const updateshopUpdate = data => {
    return http({
      url: '/dealermanage/updateshop/update',
      data,
      method: 'POST'
    })
  }

//门店详情
export const classificationInfo = id => {
  return http({
    url: '/dealermanage/updateshop/infos/'+id,
    method: 'get'
  })
}

//门店性质
export const datadictionaryListpage = (params) => {
  return http({
    method: "get",
    url: "/dealermanage/datadictionary/listpage",
    params,
  });
};

//导出门店数据
export const getDealerShopSunExport = (params) => {
  return download('dealermanage/updateshop/getDealerShopSunExport')
};
