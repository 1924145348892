<template>
  <el-drawer v-bind="$attrs" @close="handleClose">
    <div style="padding: 0 20px">
      <el-table :data="tableData" border>
        <el-table-column prop="tableName" label="模板名称"></el-table-column>
        <el-table-column prop="createName" label="修改人"></el-table-column>
        <el-table-column prop="createTime" label="修改日期"></el-table-column>
        <el-table-column prop="result" label="修改内容"></el-table-column>
        <el-table-column prop="oldData" label="修改前"></el-table-column>
        <el-table-column prop="newData" label="修改后"></el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          background
          :current-page="page.currPage"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.totalCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { dataupdatehistoryListHistory } from '@/api/bpmManage'
export default {
  props: {
    listId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      showLogList: true,
      page: {
        currPage: 1,
        pageSize: 10
      }
    }
  },
  watch: {
    listId(val) {
      val && this.getList()
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleSizeChange(size) {
      this.page.pageSize = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.page.currPage = current
      this.getList()
    },
    getList() {
      dataupdatehistoryListHistory({
        dataId: this.listId,
        ascriptionModular: 60,
        currPage: this.page.currPage,
        pageSize: this.page.pageSize
      }).then(res => {
        this.tableData = res.data
        this.page.totalCount = res.totalCount
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}
</style>
