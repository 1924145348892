<script setup>
import { ref, computed } from 'vue'
import 'element-ui/lib/theme-chalk/display.css'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import StoreRenovationBaseForm from './components/StoreRenovationBaseForm.vue'
import { getStorage } from '@/utils/storage'
import HeaderTitle from '@/views/flow/components/headerTitle'
import FlowForm from './components/flowForm'
import {
  saveStoreDecoration,
  submitStoreDecoration,
  submitStoreDecorationApproveData,
  getStoreDecorationDetail,
  getStoreDecorationParam
} from './api/classicalStoreChangeFlow'
import { requestJinDieHr, requestGetDesignerList } from '@/api/common'
import { useRoute } from '@/utils/router'

const route = useRoute()
const decimalsRule = {
  trigger: 'blur',
  message: '只能填写数字并且最多两位小数',
  validator: (rule, value, callback) => {
    const numberRegex = /^\d+(\.\d{1,2})?$/
    if (value != '' && !numberRegex.test(value)) {
      callback(rule.message)
    } else {
      callback()
    }
  }
}
const decimalsOrZeroRule = {
  trigger: 'blur',
  message: '填写数字并且最多两位小数,也可为0',
  validator: (rule, value, callback) => {
    const numberRegex = /^(0|(\d+(\.\d{1,2})?))$/
    if (value != '' && !numberRegex.test(value)) {
      callback(rule.message)
    } else {
      callback()
    }
  }
}
const integerOrZeroRule = {
  message: '填写整数或0',
  validator: (rule, value, callback) => {
    if (value != '' && !/^(0|([1-9]\d*))$/.test(value)) {
      callback(rule.message)
    } else {
      callback()
    }
  }
}
const approvalDataRules = ref({
  expandedRenovationArea: [decimalsRule],//扩店装修面积（不包含原门店面积及公摊面积即新增的装修部分实用面积）
  facadeRenovationArea: [decimalsRule],//外立面门头装修面积
  mainFloorRenovationArea: [decimalsRule],//主楼层装修面积（不包含公摊面积）
  adjacentFloorRenovationArea: [decimalsRule],//紧邻楼层装修面积（不包含公摊面积）
  otherFloorsRenovationArea: [decimalsRule],//其他楼层装修面积（不包含公摊面积）
  renovationCommonArea: [decimalsRule],//装修公摊总面积
  expandedAuditArea: [decimalsRule],//扩店审核面积（不包含原门店面积及公摊面积即新增的装修部分实用面积）
  facadeAuditArea: [decimalsRule],//外立面门头审核面积
  mainFloorAuditArea: [decimalsRule],//主楼层审核面积（不包含公摊面积）
  adjacentFloorAuditArea: [decimalsRule],//紧邻楼层审核面积（不包含公摊面积）
  otherFloorsAuditArea: [decimalsRule],//其他楼层审核面积（不包含公摊面积）
  auditCommonArea: [decimalsRule],//审核公摊总面积
  acceptanceArea: [decimalsRule],//监理验收面积（门店装修总面积）
  drawingReviewArea: [decimalsRule],//审图复核面积
  childrenArea: [decimalsOrZeroRule],//儿童专区面积
  homeTextileArea: [decimalsOrZeroRule],//家纺面积
  sleepAidArea: [decimalsOrZeroRule],//助眠面积
  advertisingMachine: [integerOrZeroRule],//广告机
  materialSampleCabinet: [integerOrZeroRule],//材料小样柜
  smartPillowLightBox: [integerOrZeroRule],//智慧枕灯箱
  coreMaterialLightBox: [integerOrZeroRule],//核心材质灯箱
  culturalSystem: [integerOrZeroRule],//文化体系
  sleepAidZone: [integerOrZeroRule],//助眠专区
  goldManager: [integerOrZeroRule],//金管家
  homeTextileZone: [integerOrZeroRule],//家纺区
  negotiationArea: [integerOrZeroRule],//洽谈区
  leatherDisplayCabinet: [integerOrZeroRule]//皮革展示柜
})
const baseKeys = {
  title: '标题',
  orderNumber: '单号',
  zsOrderNumber: '招商单号',
  linkOrderNumber: '门店设计流程单号',
  storeManageProcessName: '门店管理流程',
  storeManageProcessCode: '门店管理流程单号',
  optJobNumber: '提单人工号',
  optUserName: '提单人',
  optDeptName: '提单人部门',
  businessLicenseName: '售达方',
  bossName: '经销商（实际控股人）',
  bossContactInfo: '经销商联系方式',
  bossNumber: '经销商账号',
  authorizedCityName: '授权城市',
  marketLevelName: '城市等级',
  province: '省份',
  city: '地级市',
  area: '区/县',
  marketCenterName: '营销中心',
  designBrandNames: '门店设计品牌',
  oldBrandNames: '已代理品牌'
}

const shopKeys = {
  parentShopNumber: '物理门店编号',
  shopNumber: '门店编号',
  createShopType: '开店类型',
  storeOpeningMode: '开店模式',
  contactPerson: '门店负责人',
  contactPersonPhone: '门店负责人联系方式',
  renovationLiablePerson: '装修对接人',
  renovationPersonPhone: '装修对接人电话',
  acreageCount: '装修面积',
  adjustedStoreArea: '调整后门店面积',
  marketName: '卖场名称',
  marketType: '卖场类型',
  shopAddress: '门店地址',
  shopPositionLevelName: '门店位置等级',
  storeNature: '店态性质',
  managementModel: '经营模式'
}
const fdFormTemplateCode = 'distributor-JXS031'

const innerFlowData = {
  linkOrderNumber: '',
  beforeRenovationShop: {},
  approveData: {
    processState: '',
    engineeringSupervision: '',
    engineeringSupervisionName: ''
  }
}

const flowFormRef = ref(null)
const flowData = ref(innerFlowData)
const approveData = ref({})
const flowId = computed(() => {
  const { id, businessId } = route.query
  return id || businessId
})
const isApprove = computed(() => !!route.query.processId)

function getOptUserInfo() {
  const userInfo = getStorage('userInfo')
  return {
    optUserId: userInfo.accountId, //提单人ID【必须】',
    optUserName: userInfo.realName, //提单人姓名【必须】,
    optJobNumber: userInfo.code || userInfo.account, //提单人工号【必须】
    optDeptName: userInfo.deptName,
    optDeptId: userInfo.deptId
  }
}

async function getDetail(type, cachedFormData) {
  if (type != 'detail') {
    flowData.value = {
      ...flowData.value,
      ...(cachedFormData || {}),
      ...getOptUserInfo()
    }
    return
  }
  const { data } = await getStoreDecorationDetail({ formId: flowId.value })
  flowData.value = {
    ...innerFlowData,
    ...data,
    beforeRenovationShop: {
      ...data.decorateStoreModel,
      acreageCount:data.approveData?.acreageCount||'',
      adjustedStoreArea:data.approveData?.adjustedStoreArea||'',
    },
    approveData: {
      processState: '',
      engineeringSupervision: '',
      engineeringSupervisionName: '',
      ...data.approveData
    }
  }
  approveData.value = data.approveData ?? {
    processState: '',
    engineeringSupervision: '',
    engineeringSupervisionName: ''
  }
}

function onApproval(bpmExecuteBean, bpmDealerJoinApproveModel) {
  const { optUserId, optUserName, optJobNumber } = getOptUserInfo()

  const formData = {
    id: flowId.value,
    optUserId, //提单人ID【必须】',
    optUserName, //提单人姓名【必须】,
    optJobNumber, //提单人工号【必须】
    applyOrderModel: {
      approveAccount: optJobNumber, //审批人工号   [必填]',
      businessId: flowId.value,
      processInstanceId: route.query.processId,
      systemCode: 'distributor', //来源系统  [必传],
      fdFormTemplateCode, //模板/流程编码,
      pageVersion: 'V1', //页面版本 当前流程使用的页面版本  [必传]',
      bpmExecuteBean
    },
    approveData: bpmDealerJoinApproveModel
  }

  if (Number(flowData.value?.status ?? 0) === 20) {
    flowData.value = {
      ...flowData.value,
      approveData: bpmDealerJoinApproveModel,
      applyOrderModel: formData.applyOrderModel
    }
    return flowFormRef.value?.onCommit('submit')
  }

  return submitStoreDecorationApproveData(formData)
}

function onSubmit(type) {
  const { beforeRenovationShop, ...form } = flowData.value
  return type !== 'submit'
    ? saveStoreDecoration(form)
    : submitStoreDecoration(form)
}
async function linkFlowChange(item) {
  const { data } = await getStoreDecorationParam({ formId: item?.id })
  flowData.value = {
    beforeRenovationShop: {
      ...data.decorateStoreModel,
      acreageCount:data.approveData?.acreageCount || '',
      adjustedStoreArea:data.approveData?.adjustedStoreArea || '',
    },
    ...data,
    ...getOptUserInfo(),
    approveData: {
      processState: '',
      engineeringSupervision: '',
      engineeringSupervisionName: '',
      ...data.approveData
    }
  }
  approveData.value = data.approveData ?? {
    processState: '',
    engineeringSupervision: '',
    engineeringSupervisionName: ''
  }
}
async function requestJindieDataList({page,searchText}) {
  const res = await requestJinDieHr({
    page,
    personName:searchText
  })
  return res?.data ?? []
}
async function onJindieDataNotFound (
  { value: personNum },
  callback
) {
  const res = await requestJinDieHr({
    page:1,
    personNum
  })
  callback(res.data)
}
</script>

<template>
  <FlowForm
    ref="flowFormRef"
    :fd-form-template-code="fdFormTemplateCode"
    :approval-data-rules="approvalDataRules"
    :model="flowData"
    :is-edit="[0, 20].includes(Number(flowData?.declarationForm?.status))"
    :approval-data="approveData"
    :on-submit="onSubmit"
    :on-approval="onApproval"
    :get-detail="getDetail"
    submitButtonText="发起流程"
    title="慕思经典-门店装修流程">
    <StoreRenovationBaseForm
      :model-value.sync="flowData"
      :base-keys="baseKeys"
      :shop-keys="shopKeys"
      :link-flow-params="{ processCode: 'JXS030' }"
      @linkFlowChange="linkFlowChange" />
    <div v-if="!isApprove">
      <HeaderTitle style="margin: 20px 0" title="终端门店装修信息" />
      <RequiredFormItemInput
        class="formItem"
        label="品牌区域编码"
        :value="approveData?.brandRegionCode"
        placeholder="/"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="营销中心"
        :value="approveData?.marketCenterName"
        placeholder="/"
        disabled />
      <RequiredFormItemSelect
        class="formItem"
        :value="approveData?.pannelDesigner"
        label="平面图设计师"
        labelKey="realName"
        valueKey="account"
        :getDataList="
          async () => {
            const res = await requestGetDesignerList({
              ifEnable: 1,
              pageSize: 200
            })
            return res?.data ?? []
          }
        "
        disabled />
      <RequiredFormItemSelect
        class="formItem"
        :value="approveData?.sofaConstruction"
        label="施工图设计师"
        labelKey="personName"
        valueKey="personNum"
        :selectProps="{
          filterable: true,
          remote:true
        }"
        :getDataList="requestJindieDataList"
        @onOptionsListNotFound="onJindieDataNotFound"
        disabled />
      <RequiredFormItemSelect
        class="formItem"
        v-model="flowData.approveData.engineeringSupervision"
        :prop="`approveData.engineeringSupervision`"
        label="工程监理"
        labelKey="personName"
        valueKey="personNum"
        :selectProps="{
          filterable: true,
          remote:true
        }"
        :getDataList="requestJindieDataList"
        @onOptionsListNotFound="onJindieDataNotFound"
        @changeSelectedItem="
          e => {
            flowData.approveData.engineeringSupervision = e ? e.personNum : ''
            flowData.approveData.engineeringSupervisionName = e
              ? e.personName
              : ''
          }
        ">
        <template v-slot="{ scope }">
          <span>{{ scope.row.personName }} {{ scope.row.personNum }}</span>
        </template>
      </RequiredFormItemSelect>
    </div>
    <HeaderTitle
      v-if="isApprove"
      style="margin: 20px 0"
      title="终端门店装修信息" />
    <template v-if="isApprove" #approval-divider="{ fieldItem }">
      <HeaderTitle
        v-if="fieldItem.fdFieldId == 'storeStyle'"
        style="margin: 20px 0"
        title="终端门店数据" />
      <HeaderTitle
        v-if="fieldItem.fdFieldId == 'matcherProfessionalism'"
        style="margin: 20px 0"
        title="经销商评价" />
    </template>
  </FlowForm>
</template>
