<template>
  <required-form-item
    ref="formItemRef"
    class="required-form-item-select"
    v-bind="attrs.other">
    <template #label>
      <slot name="label"></slot>
    </template>
    <common-select
      ref="selectRef"
      :label="$attrs['label']"
      v-bind="attrs.select"
      v-on="listeners">
      <template v-slot="{ scope }">
        <slot :scope="scope" />
      </template>
    </common-select>
  </required-form-item>
</template>

<script>
import requiredFormItem from './requiredFormItem'
import Select from './Select'
import { FormItem } from 'element-ui'

const toCamelString = text => {
  const keyArr = text.split('-')
  let camelKey = keyArr[0]

  for (let i = 1, len = keyArr.length; i < len; i++) {
    camelKey += keyArr[i].replace(/^./, keyArr[i][0].toLocaleUpperCase())
  }
  return camelKey
}

export const attrsAssign = attrs => {
  const formItemPropsKeys = Object.keys(FormItem.props)
    .concat(Object.keys(requiredFormItem.props))
    .map(toCamelString)
  const selectPropsKeys = Object.keys(Select.props).map(toCamelString)

  const select = {
    ...(attrs['selectProps'] || {}),
    ...(attrs['select-props'] || {})
  }
  const other = {}
  const blackList = ['selectListeners', 'selectProps']
  for (const key in attrs) {
    const camelKey = toCamelString(key)
    let isUse = false
    // 在form-item和required-form-item组件定义的props
    if (formItemPropsKeys.includes(camelKey)) {
      other[key] = attrs[key]
      isUse = true
    }
    // 在select组件定义的props
    if (selectPropsKeys.includes(camelKey)) {
      select[key] = attrs[key]
      isUse = true
    }
    // 未定义的props传入select
    if (!isUse && !blackList.includes(camelKey)) {
      select[key] = attrs[key]
    }
  }

  return {
    other,
    select
  }
}

export default {
  components: { requiredFormItem, [Select.name]: Select },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  computed: {
    $formItemRef() {
      return this.$refs.formItemRef
    },
    $selectRef() {
      return this.$refs.selectRef
    },
    attrs({ $attrs }) {
      return attrsAssign($attrs)
    },
    listeners({ $listeners, $attrs }) {
      return Object.assign(
        $listeners,
        $attrs['selectListeners'],
        $attrs['select-listeners']
      )
    }
  }
}
</script>
