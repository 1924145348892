<script setup>
import { onMounted, ref } from 'vue'
import {
  getTrainingScores,
  saveTrainingScores,
  deleteTrainingScores
} from '../api'
import dateFormat from 'dateformat'
import { getStorage } from '@/utils/storage'
import { Message } from 'element-ui'

const props = defineProps({
  open: {
    required: true,
    type: Boolean
  },
  dealer: {
    required: true,
    type: Object
  }
})
const emit = defineEmits({
  'update:open': _v => true,
  updated: () => true
})

const user = getStorage('userInfo')

function onOpen() {
  fetchTrains()
}
function onClose() {
  trains.value = []
  willDeleteTrainIds.value = []
  emit('update:open', false)
}

const trains = ref([])
const willDeleteTrainIds = ref([])
async function fetchTrains() {
  const res = await getTrainingScores(props.dealer.dealerCode)
  trains.value = res
}

function onAppend() {
  trains.value.push({
    id: null,
    dealerCode: props.dealer.dealerCode,
    point: 0,
    trainTime: '',
    remark: '',
    createBy: user.accountId,
    createName: user.realName || user.nickName,
    createTime: dateFormat(Date.now(), 'yyyy-mm-dd hh:MM:ss')
  })
}
function onDelete(idx) {
  const item = trains.value[idx]
  if (item.id) {
    willDeleteTrainIds.value.push(item.id)
  }
  trains.value.splice(idx, 1)
}
const waiting = ref(false)
async function onConfirm() {
  const hasAllScores = trains.value.every(v => v.point != null)
  if (!hasAllScores) {
    Message.warning('请输入分数')
    return
  }
  const hasAllTrainTimes = trains.value.every(v => v.trainTime)
  if (!hasAllTrainTimes) {
    Message.warning('请选择培训时间')
    return
  }
  try {
    if (waiting.value) return
    waiting.value = true
    if (willDeleteTrainIds.value.length > 0) {
      await deleteTrainingScores(willDeleteTrainIds.value)
    }
    await saveTrainingScores(props.dealer.dealerCode, trains.value)
    Message.success('修改成功')
    emit('update:open', false)
    emit('updated')
  } finally {
    waiting.value = false
  }
}
</script>

<template>
  <el-dialog
    title="编辑培训详情"
    width="800px"
    :visible="open"
    @open="onOpen"
    @close="onClose">
    <el-table :data="trains" border>
      <el-table-column width="50px" label="序号">
        <template #default="{ $index }">{{ $index + 1 }}</template>
      </el-table-column>
      <el-table-column label="分数" prop="point" width="150px">
        <template #default="{ row }">
          <el-input-number
            v-model="row.point"
            :precision="2"
            :step="1"
            :max="100"
            style="width: 100%" />
        </template>
      </el-table-column>
      <el-table-column label="培训时间" prop="trainTime" width="220px">
        <template #default="{ row }">
          <el-date-picker
            v-model="row.trainTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%" />
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark">
        <template #default="{ row }">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="row.remark"
            maxlength="800" />
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template #header>
          <el-button @click="onAppend">添加</el-button>
        </template>
        <template #default="{ row, $index }">
          <el-button type="danger" @click="onDelete($index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" :loading="waiting" @click="onConfirm"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>
