import { render, staticRenderFns } from "./areaPersonShip.vue?vue&type=template&id=0d84e2c7"
import script from "./areaPersonShip.vue?vue&type=script&setup=true&lang=js"
export * from "./areaPersonShip.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports