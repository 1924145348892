<script setup>
// import { brandseriesList } from '@/api/common/'
import { accountListPage } from '@/api/system/user'
import {
  delAreaPerson,
  delAddAreaPerson,
  delEditAreaPerson,
  delStaAreaPerson,
  getAllsmallRegion,
  getAlladministrativeRegion
} from '@/api/commonConfig/areaConfig'
import TablePro, { render } from '@/components/TablePro/index.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import PopupForm, { renderFormItem } from '@/components/FormPro/PopupForm.vue'
import { ref, computed, onMounted, nextTick } from 'vue'
import CommonSelect from '@/components/CustomFormItem/Select.vue'
import { Message, MessageBox } from 'element-ui'
import { getStorage } from '@/utils/storage'

// import store from '@/store'

const tableProRef = ref(null)
const popupFormRef = ref(null)
const popupFormRefEdit = ref(null)
// const bigBrandMap = ref([])
const SelectedRow = ref([])
const props = defineProps({
  bigBrandMap: {
    type: Array,
    default: []
  }
})
const emit = defineEmits('getSomeData')

onMounted(async () => {})

const columns = [
  {
    title: '品牌ID',
    name: 'brandId',
    hideInTable: true
  },
  {
    title: '品牌编号',
    name: 'brandCode',
    hideInTable: true
  },
  {
    title: '品牌名称',
    name: 'brandName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '品牌名称',
    name: 'brandId',
    search: true,
    hideInTable: true,
    hideInExport: true,
    renderFormItem: renderFormItem((h, context) => {
      return (
        <RequiredFormItemSelect
          dataList={props.bigBrandMap}
          labelKey="name"
          valueKey="id"
          showLabel={false}
          filterable={true}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '小区ID',
    name: 'smallRegionId',
    hideInTable: true
  },
  {
    title: '小区编号',
    name: 'smallRegionCode',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '小区名称',
    name: 'smallRegionName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '小区名称',
    name: 'smallRegionId',
    search: true,
    hideInTable: true,
    hideInExport: true,
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page }) => {
        const res = await getAllsmallRegion({
          currPage: page,
          pageSize:10,
        })
        return res
      }
      return (
        <RequiredFormItemSelect
          getDataList={getSourceList}
          labelKey="smallRegionName"
          valueKey="smallRegionId"
          showLabel={false}
          filterable={true}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '大区ID',
    name: 'administrativeRegionId',
    hideInTable: true
  },
  {
    title: '大区编号',
    name: 'administrativeRegionCode',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '大区名称',
    name: 'administrativeRegionName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '大区名称',
    name: 'administrativeRegionId',
    search: true,
    hideInTable: true,
    hideInExport: true,
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page }) => {
        const res = await getAlladministrativeRegion({
          currPage: page,
          pageSize:10,
        })
        return res
      }
      return (
        <RequiredFormItemSelect
          getDataList={getSourceList}
          labelKey="administrativeRegionName"
          valueKey="administrativeRegionId"
          showLabel={false}
          filterable={true}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '状态',
    name: 'status',
    valueType: 'select',
    valueEnum: [
      { label: '启用中', value: 0 },
      { label: '已停用', value: 1 }
    ],
    tableColumnProps: { align: 'center' },
    render: render((h, context) => {
      return (
        <el-switch
          value={context.data.status}
          active-value={0}
          inactive-value={1}
          on-change={async val => {
            await delStaAreaPerson({
              id: context.data.id
              // status: val
            })
            Message.success('修改成功')
            tableProRef.value.onRefreshTableList()
          }}
        />
      )
    })
  }
]
const popupFormColumns = [
  {
    title: '品牌',
    name: 'brandNameArr',
    renderFormItem: renderFormItem(
      (h, context) => {
        return (
          <CommonSelect
            value={context.$attrs.value?.id}
            placeholder="请选择品牌"
            dataList={props.bigBrandMap}
            // getDataList={getListCategory}
            label-key="name"
            value-key="id"
            clearable
            filterable
            onChangeSelectedItem={context.$listeners.input}
          />
        )
      },
      {
        required: true,
        formItemProps: {
          requiredTrigger: ['blur']
        }
      }
    ),
    transform(e) {
      return e
        ? {
            brandId: e?.id,
            brandCode: e?.code,
            brandName: e?.name
          }
        : {}
    }
  },
  {
    title: '大区名称',
    name: 'administrativeRegionName',
    fieldProps: { required: true }
  },
  {
    title: '小区名称',
    name: 'smallRegionName',
    fieldProps: { required: true }
  }
  // {
  //   title: '小区负责人',
  //   name: 'smallRegionDirectorNameArr',
  //   renderFormItem: renderFormItem((h, context) => {
  //     const getListCategory = ({ searchText, page }) => {
  //       return accountListPage({
  //         currPage: page,
  //         pageSize: 10,
  //         realName: searchText
  //       })
  //     }
  //     return (
  //       <CommonSelect
  //         // label={context.config.title}
  //         value={context.$attrs.value?.realName}
  //         placeholder="请选择小区负责人"
  //         getDataList={getListCategory}
  //         label-key="realName"
  //         value-key="id"
  //         clearable
  //         filterable
  //         remote
  //         onChangeSelectedItem={context.$listeners.input}
  //       />
  //     )
  //   }),
  //   transform(e) {
  //     return e
  //       ? {
  //           smallRegionDirectorId: e?.id,
  //           smallRegionDirectorName: e?.realName
  //         }
  //       : {}
  //   }
  // },
  // {
  //   title: '大区负责人',
  //   name: 'administrativeRegionDirectorNameArr',
  //   renderFormItem: renderFormItem((h, context) => {
  //     const getListCategory = ({ searchText, page }) => {
  //       return accountListPage({
  //         currPage: page,
  //         pageSize: 10,
  //         realName: searchText
  //       })
  //     }
  //     return (
  //       <CommonSelect
  //         // label={context.config.title}
  //         value={context.$attrs.value?.realName}
  //         placeholder="请选择大区负责人"
  //         getDataList={getListCategory}
  //         label-key="realName"
  //         value-key="id"
  //         clearable
  //         filterable
  //         remote
  //         onChangeSelectedItem={context.$listeners.input}
  //       />
  //     )
  //   }),
  //   transform(e) {
  //     return e
  //       ? {
  //           administrativeRegionDirectorId: e?.id,
  //           administrativeRegionDirectorName: e?.realName
  //         }
  //       : {}
  //   }
  // }
]

const getTableList = async params => {
  const res = await delAreaPerson({
    ...params
  })
  return {
    data: res.data,
    totalCount: res.totalCount
  }
}
const onSubmit = async params => {
  // console.log(params, 'params11111')
  const userInfo = getStorage('userInfo')
  await (params.id ? delEditAreaPerson : delAddAreaPerson)({
    ...params,
    createBy: userInfo.accountId
  })
  Message.success(params.id ? '修改成功' : '新增成功')
  tableProRef.value.onRefreshTableList()
  // emit('getSomeData')
  return true
}
</script>

<template>
  <div>
    <TablePro
      ref="tableProRef"
      :columns="columns"
      :request="getTableList"
      :actions="['export']"
      exportFileName="品牌区域配置"
      :headerCellStyle="{ background: '#e2f2fb', color: '#393F4D' }"
      auto-width-cell>
      <template #tableBefore>
        <el-table-column type="selection" reserve-selection />
      </template>
      <template #actionArea>
        <PopupForm
          ref="popupFormRef"
          popup-type="dialog"
          :columns="popupFormColumns"
          :on-submit="onSubmit"
          label-position="left"
          destroy-slot-on-close
          width="26%">
          <template #trigger="{ onShow }">
            <el-button
              v-loading-click="() => onShow({}, () => {}, { title: '新增' })"
              type="primary">
              新增
            </el-button>
          </template>
        </PopupForm>
      </template>
    </TablePro>
  </div>
</template>
