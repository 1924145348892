<template>
  <el-form
    ref="commonFormRef"
    class="common-form"
    :model="innerParams"
    v-bind="getProps"
    @submit.prevent="onSubmit"
  >
    <slot name="form-before" :params="innerParams" />

    <component
      :is="item.wrapRender || 'div'"
      v-bind="item.wrapProps"
      class="edit-item"
      v-for="item of getColumns"
      :key="item._renderKey"
    >
      <component v-if="item.renderHeader" :is="item.renderHeader" />
      <FormColumnRender
        v-model="innerParams[item.name]"
        :params="innerParams"
        :column-item="item"
        v-bind="{
          ...(isPreview ? { disabled: true } : {})
        }"
      />
      <component :is="item.renderHint" v-if="item.renderHint" v-bind="item"></component>
      <div style="color: red; margin-bottom: 20px" v-if="item.hintText">
        {{ item.hintText }}
      </div>
    </component>

    <slot name="form-after" :params="innerParams" />
    <slot
      name="footer"
      :on-submit="onSubmit"
      :on-reset="onReset"
      :on-clear="onClear"
      :params="innerParams"
    >
      <el-form-item class="footer">
        <el-button v-loading-click="onSubmit" type="primary">提交</el-button>
        <el-button @click="onReset">重置</el-button>
      </el-form-item>
    </slot>
  </el-form>
</template>

<script>
export default {
  name: 'CommonForm'
}
</script>
<script setup>
import { ref, unref, watch, computed, useAttrs, nextTick } from 'vue'
import {
  transformParams,
  validateToPromise,
  onInitParams,
  deepCopy,
  valueKeyWithIndex
} from '@/components/TablePro/fn'
import FormColumnRender from './FormColumnRender.vue'
import { timingSequenceStep } from '@/utils'
import deepEqual from 'fast-deep-equal'

const props = defineProps({
  defaultParams: { type: Object, default: () => ({}) },
  columns: { type: Array, default: () => [] },
  isPreview: { type: Boolean, default: false },
  // 严格初始化params,除了id和columns中的name,其他的都不会初始化
  strictParams: { type: Boolean, default: false },
  /** 标签的长度，例如 '50px'。 作为 Form 直接子元素的 form-item 会继承该值。 可以使用 auto。 */
  labelWidth: {
    type: String,
    default: undefined
  },
  /** 行内表单模式 */
  inline: {
    type: Boolean,
    default: false
  },
  onSubmit: {
    type: Function,
    default: () => {
      console.log('请定义onSubmit方法')
    }
  }
})

const attrs = useAttrs()
const emits = defineEmits(['register', 'reset', 'clear', 'input', 'submit'])
const innerParams = ref({})
let innerDefaultParams = {}

const commonFormRef = ref(null)
const innerPropsRef = ref({})
const getProps = computed(() => {
  return {
    ...props,
    ...unref(innerPropsRef),
    ...attrs,
    ...(props.isPreview ? { disabled: true } : {}),
    labelWidth: props.labelWidth ?? props.inline ? undefined : 'auto'
  }
})

const getColumns = computed(() => {
  const columns = unref(getProps).columns
  return valueKeyWithIndex(columns).filter((item) => !isHidden(item, unref(innerParams)))
})

watch(
  () => innerParams.value,
  (params) => {
    emits('input', transformParams(params, unref(getProps).columns))
  }
)

const getDefaultParams = timingSequenceStep(async (params) => {
  return onInitParams(params, unref(getProps).columns)
})
watch(
  () => getProps.value,
  ({ columns, defaultParams }) => {
    if (columns.length) {
      onInit(defaultParams || {})
    }
  },
  {
    immediate: true
  }
)

function isHidden(item, params) {
  if (typeof item.hidden === 'function') {
    return item.hidden(item, params, {
      isPreview: props.isPreview
    })
  }
  return !item.hidden === false
}
function setProps(props) {
  innerPropsRef.value = { ...unref(innerPropsRef), ...props }
}

async function onInit(params = {}) {
  if (unref(getProps).strictParams) {
    const nameKeys = unref(getProps)
      .columns.map((item) => item.name)
      .concat(['id'])
    params = nameKeys.reduce((pre, cur) => {
      pre[cur] = params[cur]
      return pre
    }, {})
  }
  try {
    const processParams = await getDefaultParams(params)
    innerParams.value = deepCopy(processParams)
    innerDefaultParams = deepCopy(processParams)
    // 初始化清空校验
    if (commonFormRef.value) {
      commonFormRef.value.resetFields()
    }
  } catch (error) {
    if (error?.msg === 'Promise被取消，因为有新的Promise被执行') {
      return
    }
    throw error
  }
}

async function onSubmit(options = {}, ...rest) {
  const { isTransformParams = true } = options
  await validateToPromise(commonFormRef.value.validate)
  emits('submit', transformParams(innerParams.value, unref(getProps).columns))
  return unref(getProps).onSubmit(
    isTransformParams
      ? transformParams(innerParams.value, unref(getProps).columns)
      : innerParams.value,
    options,
    ...rest
  )
}
function onReset(params) {
  if (!(params?.target && params.clientX && params.clientY)) {
    if (typeof params === 'object') {
      innerDefaultParams = params
    }
  }
  innerParams.value = deepCopy(innerDefaultParams)
  emits('reset', transformParams(innerParams.value, unref(getProps).columns))
}
async function onClear() {
  const processParams = await onInitParams()
  innerParams.value = deepCopy(processParams)
  emits('clear', transformParams(innerParams.value, unref(getProps).columns))
}

function onUpdateParams(params) {
  innerParams.value = deepCopy(params)
}

function onUpdateValue(key, value) {
  innerParams.value[key] = value
}

const popupFormAction = {
  params: innerParams,
  setProps,
  onInit,
  onSubmit,
  onReset,
  onClear,
  onUpdateParams,
  onUpdateValue
}
defineExpose(popupFormAction)
</script>

<style lang="scss" scoped>
.common-form {
  padding: 10px;

  :deep(.el-select) {
    width: 100%;
  }

  .footer {
    :deep(.el-form-item__content) {
      justify-content: flex-end;
    }
  }
  &.el-form--inline {
    /* display: flex; */
    .edit-item {
      vertical-align: middle;
      display: inline-flex;
    }
  }
}
</style>
