<template>
  <el-form-item
    ref="formItemRef"
    class="required-form-item"
    :label="label"
    :rules="rules"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #label>
      <slot name="label">
        <span v-if="label">
          {{ label }}
          <el-tooltip v-if="labelTip">
            <template #content>
              <div class="label-tip-content">
                {{ labelTip }}
              </div>
            </template>
            <i style="color: #000; cursor: pointer" class="el-icon-question" />
          </el-tooltip>
        </span>
      </slot>
    </template>
    <slot />
  </el-form-item>
</template>

<script>
import validationData from './validationData'

export default {
  inject: {
    bpmFlow: {
      default: undefined
    },
    oaFlow: {
      default: undefined
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: undefined
    },
    validations: {
      type: Array,
      default: () => []
    },
    requiredTrigger: {
      type: Array,
      default: () => ['change', 'blur']
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    labelTip: {
      type: String,
      default: ''
    }
  },
  computed: {
    $formItemRef() {
      return this.$refs.formItemRef
    },
    label({ $attrs, showLabel }) {
      return showLabel ? $attrs['label'] : ''
    },
    rules({ disabled, $attrs, requiredTrigger, validations }) {
      // 禁用项不做校验
      if (disabled) {
        if (this.$refs.formItemRef && $attrs.prop) {
          this.$refs.formItemRef.clearValidate($attrs.prop)
        }
        return []
      }
      const rules = validations.map(item => {
        if (validationData[item]) {
          return {
            validator: validationData[item],
            trigger: 'blur'
          }
        }
        throw new Error('没有找到该规则')
      })
      return [
        {
          required: this.required !== false,
          message: $attrs['label'] ? `${$attrs['label']}不能为空` : '不能为空',
          trigger: requiredTrigger
        },
        ...rules,
        ...($attrs['rules'] || [])
      ]
    }
  }
}
</script>

<style scoped>
.label-tip-content {
  max-width: 500px;
  white-space: break-spaces;
}
</style>
