<template>
  <div class="login-container">
    <div class="login-box" v-if="pageLoading">
      <div class="weixin-login" v-show="isWeixinLogin">
        <div id="qyweixin"></div>
        <el-divider content-position="center">其他登录方式</el-divider>
        <el-button :loading="loading" size="mini" @click="isWeixinLogin = false"
          >账号登录</el-button
        >
        <el-button :loading="loading" size="mini" @click="handleAuthLogin"
          >慕思统一身份登录</el-button
        >
      </div>
      <el-form
        ref="loginForm"
        v-show="!isWeixinLogin"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="on"
        label-position="left">
        <div class="form-item">
          <!-- <div class="title-container">
        <h3 class="title"></h3>
      </div> -->

          <el-form-item prop="username">
            <span class="svg-container">
              <svg-icon icon-class="user" />
            </span>
            <el-input
              ref="username"
              v-model="loginForm.username"
              placeholder="请输入账号"
              name="username"
              type="text"
              tabindex="1"
              auto-complete="on" />
          </el-form-item>

          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              name="password"
              tabindex="2"
              auto-complete="on"
              @keyup.enter.native="handleLogin" />
            <span class="show-pwd" @click="showPwd">
              <svg-icon
                :icon-class="
                  passwordType === 'password' ? 'eye' : 'eye-open'
                " />
            </span>
          </el-form-item>

          <div class="login-button">
            <el-button
              :loading="loading"
              class="btn"
              type="primary"
              @click.native.prevent="handleLogin"
              >登 录</el-button
            >
            <div style="text-align: left; padding-top: 10px">
              <el-checkbox v-model="rememberPwd" @change="handleCheckBox"
                >记住密码</el-checkbox
              >
              <el-tooltip class="item" effect="light" placement="right">
                <div slot="content">
                  勾选记住密码会把您的账号密码<br />记录在本地，公共设备谨慎勾选
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>

            <el-divider content-position="center">其他登录方式</el-divider>
            <el-button
              :loading="loading"
              size="mini"
              @click="isWeixinLogin = true"
              >企业微信登录</el-button
            >
            <el-button :loading="loading" size="mini" @click="handleAuthLogin"
              >慕思统一身份登录</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <div class="page-loading-warp" v-else>
      <div class="ant-spin ant-spin-lg ant-spin-spinning">
        <span class="ant-spin-dot ant-spin-dot-spin"
          ><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i
          ><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i
        ></span>
      </div>
    </div>

    <div class="footer">
      <el-link type="primary" href="https://beian.miit.gov.cn/" target="_blank"
        >粤ICP备13056199号</el-link
      >
    </div>
  </div>
</template>

<script>
import {
  getStorage,
  getCookie,
  setCookie,
  setStorage,
  setSession
} from '@/utils/storage'
const { account, password } = getStorage('account') || {}

import { wxworkGetuserinfo, authLogin } from '@/api/user'
import { mapMutations } from 'vuex'

export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入账号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: getCookie('account') || '',
        password: getCookie('password') || ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      isWeixinLogin: false,
      rememberPwd: !!Number(getStorage('rememberPwd')),
      pageLoading: false
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  beforeRouteEnter(to, from, next) {
    const { code = '', exit = '' } = to.query
    localStorage.clear()
    sessionStorage.clear()

    if (!code && exit != 1 && navigator.userAgent.includes('wxwork')) {
      const redirectUrl = process.env.VUE_APP_REDIRECT_URL + '/redirect.html'
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd054cb5d9acc03cd&redirect_uri=${encodeURIComponent(
        redirectUrl
      )}&response_type=code&scope=snsapi_base&state=qyweixin#wechat_redirect`
      return
    }

    next()
  },
  created() {
    //企业微信登录
    const { code, qyweixin } = this.$route.query
    if (code) {
      const formData = new FormData()
      formData.append('code', code)
      formData.append('appId', '1000057')
      formData.append('systemCode', '30')

      setSession('systemCode', '30')

      wxworkGetuserinfo(formData)
        .then(res => {
          setStorage('token', res.data.token)
          this.SET_TOKEN(res.data.token)
          this.$store.dispatch('tagsView/delAllViews')
          this.$router.push({ path: this.redirect || '/' })
        })
        .finally(() => (this.pageLoading = true))
      return
    }
    if (qyweixin) return

    this.pageLoading = true
  },
  mounted() {
    var wwLogin = new WwLogin({
      id: 'qyweixin',
      appid: 'wxd054cb5d9acc03cd',
      agentid: '1000057',
      redirect_uri: process.env.VUE_APP_REDIRECT_URL + '/redirect.html',
      state: 'qyweixin',
      href: '',
      lang: 'zh'
    })
  },
  methods: {
    ...mapMutations('user', ['SET_TOKEN']),
    handleCheckBox(e) {
      console.log(Number(e))
      setStorage('rememberPwd', Number(e))
    },
    handleAuthLogin() {
      let jumpUrl = `${process.env.VUE_APP_REDIRECT_URL}/redirect.html`
      if (this.$route.query.redirect) {
        jumpUrl += `?redirect=${this.$route.query.redirect}`
      }
      const url = `${process.env.VUE_APP_SSO_RUL}?client_id=${
        process.env.VUE_APP_CLIENT_ID
      }&response_type=code&redirect_uri=${encodeURI(
        jumpUrl
      )}&oauth_timestamp=${Date.now()}&state=paila`
      location.href = url
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('user/login', this.loginForm)
            .then(() => {
              this.rememberPwd
                ? setCookie('password', this.loginForm.password)
                : setCookie('password', '')
              setCookie('account', this.loginForm.username)
              // setCookie('password', this.loginForm.password)
              this.$store.dispatch('tagsView/delAllViews')
              this.$router.push({ path: this.redirect || '/' })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
$bg: #283443;
$light_gray: #000;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}
.weixin-login {
  width: 450px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  text-align: center;
  padding: 35px;
  box-sizing: border-box;
}
/* reset element-ui css */
.login-container {
  background: url(~@/assets/img/login_bg.png);
  .login-button {
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    .btn {
      height: 54px;
      width: 100%;
    }
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #fff inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }
  .el-form {
    position: relative;
    z-index: 2;
  }

  .el-form-item {
    // border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    // border-radius: 5px;
    // color: #454545;
    border: 1px solid #000;
    color: #000;
  }
  // .el-button {
  //   height: 54px;
  // }
}
</style>

<style lang="scss" scoped>
$bg: #000;
$dark_gray: #000;
$light_gray: #000;

.footer {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
.login-container {
  min-height: 100%;
  height: 100vh;
  width: 100%;
  background-color: $bg;
  background-size: cover;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 450px;
    max-width: 100%;

    margin: 0 auto;
    margin-top: 200px;
    overflow: hidden;
    // background-color: #fff;
    background: url(~@/assets/img/pop.png) no-repeat;
    background-size: 100% auto;
    .form-item {
      background-color: #fff;
      margin-top: 70px;
      padding: 0px 35px 0;
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 20px auto;
      text-align: center;
      font-weight: bold;
      height: 29px;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
@media screen and (max-width: 760px) {
  .login-container {
    background: #000;
  }
  .login-form {
    transform: scale(0.8);
  }
}

.page-loading-warp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 98px;
}

.ant-spin {
  position: absolute;
  display: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  color: #1890ff;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  text-align: center;
  list-style: none;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
}

.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}

.ant-spin-dot {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1890ff;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antspinmove 1s infinite linear alternate;
  animation: antSpinMove 1s infinite linear alternate;
}

.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}

.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: antrotate 1.2s infinite linear;
  animation: antRotate 1.2s infinite linear;
}

.ant-spin-lg .ant-spin-dot {
  width: 32px;
  height: 32px;
  font-size: 32px;
}

.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}
</style>
