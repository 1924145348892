<template>
  <div>
    <CommonTarget />
    <AreaShop title="我的计划概览" :data="data" />
    <CommonShopDetail />
    <div class="btn">
      <el-button v-if="type == 'edit'" type="primary" @click="save"
        >提交</el-button
      >
      <el-button @click="goBack">取消</el-button>
    </div>
  </div>
</template>

<script>
import CommonTarget from './components/commonTarget'
import AreaShop from './components/areaShop'
import CommonShopDetail from './components/commonShopDetail'
import { getMinePlanMassage, updateMinePlan } from '@/api/businessPlan'
export default {
  components: {
    CommonTarget,
    AreaShop,
    CommonShopDetail
  },
  data() {
    return {
      type: this.$route.query.type,
      data: []
    }
  },
  created() {
    this.getPlan()
  },
  methods: {
    async getPlan() {
      const res = await getMinePlanMassage({
        activityId: this.$route.query.id
      })
      if (res.code === 0) {
        this.data = res.data.map((item, index) => {
          item.targetsList.forEach((i, key) => {
            i.aId = String(index + 1) + String(key + 1)
            i.targetSum = i.targetCount
          })
          return {
            ...item,
            storeTypeList: item.targetsList,
            aId: String(index + 1),
            targetSum: item.targetCount
          }
        })
      }
    },
    async save() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      try {
        const res = await updateMinePlan({
          activityId: this.$route.query.id,
          planStatus: 1
        })
        loading.close()
        if (res.code === 0) {
          this.$message.success('提交成功')
          this.goBack()
        }
      } catch (error) {
        loading.close()
      }
    },
    goBack() {
      this.$route.params.page = this.$route.query.page
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
</style>
