<template>
  <div>
    <TablePro
      ref="tableProRef"
      :columns="columns"
      :request="getTableList"
      :hidePagination="true"
      auto-width-cell>
      <template #tableBefore>
        <el-table-column width="50" align="center" label="序号" type="index" />
      </template>
    </TablePro>
    <PopupForm
      ref="popupFormRef"
      popup-type="el-drawer"
      :columns="popupFormColumns"
      :actions="['cancel']"
      label-position="left"
      destroy-slot-on-close
      width="30%" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import TablePro, { render } from '@/components/TablePro/index.vue'
import PopupForm, { renderFormItem } from '@/components/FormPro/PopupForm.vue'
const props = defineProps({
  dataList: {
    required: true,
    type: Array
  },
  shopNumber: {
    required: true,
    type: String
  },
  dealerCode: {
    required: true,
    type: String
  },
  dealerName: {
    required: true,
    type: String
  }
})
const emit = defineEmits('closeDialog')

const tableProRef = ref(null)
const popupFormRef = ref(null)

const columns = [
  {
    title: '门店编号',
    renderText() {
      return props.shopNumber
    }
  },
  {
    title: '经销商编号',
    renderText() {
      return props.dealerCode
    }
  },
  {
    title: '经销商名称',
    renderText() {
      return props.dealerName
    }
  },
  {
    title: '违规类型',
    name: 'violationType',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '违规情形',
    name: 'violationDetail',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '品牌',
    name: 'productBrand',
    tableColumnProps: { align: 'center' },
    renderText(value) {
      return value || '/'
    }
  },
  {
    title: '产品件数',
    name: 'productCount',
    tableColumnProps: { align: 'center' },
    renderText(value) {
      return value || '/'
    }
  },
  {
    title: '产品型号',
    name: 'productModel',
    tableColumnProps: { align: 'center' },
    renderText(value) {
      return value || '/'
    }
  },
  {
    title: '违规次数',
    name: 'violationTimes',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '违约金',
    name: 'violationPrice',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '备注',
    name: 'remark',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '照片',
    tableColumnProps: { align: 'center', minWidth: '100' },
    render: render((h, context) => {
      return (
        <div>
          {context.data.urls?.length > 0 ? (
            <el-button
              v-loading-click={async () => {
                const obj = {
                  urls: context.data.urls
                }
                return popupFormRef.value.onShow(obj, () => {}, {
                  title: '照片'
                })
              }}>
              查看
            </el-button>
          ) : (
            '/'
          )}
        </div>
      )
    })
  }
]
const getTableList = async params => {
  return {
    data: props.dataList,
    totalCount: 0
  }
}
const popupFormColumns = [
  {
    title: ' ',
    name: 'urls',
    renderFormItem: renderFormItem((h, context) => {
      return (
        <div>
          {context.$attrs.params.urls?.map((item, index) => (
            <el-image
              key={index}
              src={item}
              preview-src-list={context.$attrs.params.urls}
              fit="cover"
              style="width: 100px; height: 100px;margin: 10px;"
            />
          ))}
        </div>
      )
    })
  }
]
</script>
