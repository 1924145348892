var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleChangeTheme}},[_c('span',{staticClass:"el-dropdown-link",style:({ color: _vm.color })},[_vm._v(" 主题选择"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.themeOptions),function(item){return _c('el-dropdown-item',{key:item.value,staticStyle:{"padding":"5px 15px","display":"flex","align-items":"center"},attrs:{"disabled":_vm.theme === item.value,"command":item.value}},[_c('span',{style:({
          background: item.color,
          display: 'block',
          width: '20px',
          height: '20px',
          margin: '10px'
        })}),_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }