<template>
  <el-drawer title="问题数据查询"
             :visible.sync="visibleDialog"
             direction="rtl"
            :before-close="()=>$emit('closeDrawer')"
             size="80%;overflow-y:auto">
            <div style="padding:0 20px">
                  <el-table ref="filterTable"
              v-loading="loading"
              element-loading-text="Loading"
              border
              :data="dataList"
              style="width: 100%"
              :header-cell-style="{background:'#F4F5F8',color:'#393F4D'}">
              <el-table-column :show-overflow-tooltip="true"
                              v-for="(item,index) in  itemKey"
                              :key="index"
                              :prop="item"
                              :label="listItem[item]"
                              align="center"
                              >
                <template slot-scope="scope" >
                  <span >{{scope.row[item]||'-'}}</span>
                </template>
              </el-table-column>
            </el-table>
            </div>
  </el-drawer>
</template>

<script>
import { getColumnUpdateHistory } from '@/api/watchData/'
var listItem = {     
  "historicalData": "更新数据" ,  //更新数据
  "updateFields": "更新字段",    //更新字段
  "createUserName": "更新人",                             //更新人
  "createTime": "更新时间",                    //更新时间
  "orderNumber": "流程单号",                      //流程单号
  "title": "流程标题"//流程标题
}
export default {
  props: {
    visibleDialog:{
      type:Boolean,
      default:true,
    },
    column: {
      type: String,
      default: '',
    },
    shopNumber: {
      type: String,
      default: ''
    },
    table:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      dataList:[],
      listItem,
      itemKey: Object.keys(listItem),
      loading:false
    }
     
  },
  watch:{
    visibleDialog(val){
      this.loading=true
      const {column,shopNumber,table}=this
      getColumnUpdateHistory({column,data:shopNumber,table}).then(res=>{
        this.loading=false
        this.dataList=res.data
      }).catch(err=>this.loading=false)
    }
  },
  methods: {
   
  }
}
</script>

<style lang="scss" >

</style>