// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/welcome.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".welcome-container[data-v-34049d51]{height:calc(100vh - 124px);background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% auto}.welcome-text[data-v-34049d51]{font-size:30px;line-height:46px}", "",{"version":3,"sources":["/apps/jenkins/www/workspace/招商平台-huawei-prod/src/views/welcome/index.vue"],"names":[],"mappings":"AAEE,oCACE,0BAAA,CACA,4DAAA,CACA,yBAAA,CAEF,+BACE,cAAA,CACA,gBAAA","file":"index.vue","sourcesContent":["\n.welcome {\n  &-container {\n    height: calc(100vh - 50px - 34px - 40px);\n    background: url(~@/assets/img/welcome.png) no-repeat;\n    background-size: 100% auto;\n  }\n  &-text {\n    font-size: 30px;\n    line-height: 46px;\n  }\n}\n"]}]);
// Exports
module.exports = exports;
