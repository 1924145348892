import http from "@/utils/request";

// 查询授权品牌
export const getShopTaskResults = (params) => http({
  url: '/dealerSqlTaskResult/getShopTaskResults',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'get',
  params
})

export const exportShopTaskResults = process.env.VUE_APP_CONSUMER_URL+'/dealerSqlTaskResult/exportShopTaskResults'


// 经销商问题
export const getDistributorTaskResults = (params) => http({
  url: '/dealerSqlTaskResult/getDistributorTaskResults',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'get',
  params
})

//经销商问题导出接口
export const exportDistributorTaskResults = process.env.VUE_APP_CONSUMER_URL+'/dealerSqlTaskResult/exportDistributorTaskResults'



// 问题统计
export const getTaskResults = (params) => http({
  url: '/dealerSqlTaskResult/getTaskResults',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'get',
  params
})

//问题统计导出接口
export const exportTaskResults = process.env.VUE_APP_CONSUMER_URL+'/dealerSqlTaskResult/exportTaskResults'


// 统计任务
export const getDealerSqlTaskInfoList = (params) => http({
  url: '/dealerSqlTaskInfo/list',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'get',
  params
})

//问题统计导出接口
export const exportDealerSqlTaskResult = process.env.VUE_APP_CONSUMER_URL+'/dealerSqlTaskInfo/export'

// 统计任务列表新建
export const dealerSqlTaskInfoSave = (params) => http({
  url: '/dealerSqlTaskInfo/save',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'post',
  data:params
})

// 统计任务列表更新
export const dealerSqlTaskInfoUpdate = (params) => http({
  url: '/dealerSqlTaskInfo/update',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'post',
  data:params
})

// 统计任务列表删除
export const dealerSqlTaskInfoDelete = (params) => http({
  url: '/dealerSqlTaskInfo/delete',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'post',
  data:params
})

// 表格获取列信息与备注接口
export const dealerTableColumnInfoGetMap = (params) => http({
  url: '/dealerTableColumnInfo/getMap',
  baseURL:process.env.VUE_APP_CONSUMER_URL,
  method:'get',
  params
})

// 表格获取列信息与备注接口
export const getSapReport = (params) => http({
  url: '/dealermanage/datasapsync/listpage',
  method:'get',
  params
})

// 获取门店（经销商）问题数据查询
export const getColumnUpdateHistory = (params) => http({
  url: '/dealermanage/datahistorydetail/getColumnUpdateHistory',
  method:'get',
  params
})