<template>
  <required-form-item-select
    ref="itemSelect"
    class="formItem"
    label="售达方"
    :dataList="defalutList"
    :getDataList="asyncBusinessData"
    :getNextList="asyncBusinessData"
    :value="value"
    @changeSelectedItem="getBusinessData"
    :totalCount="totalCount"
    labelKey="licenseNumber"
    valueKey="id"
    :getDataTrigger="[]"
    :selectProps="{
      filterable: true,
      remote: true,
      clearable: true,
      'popper-class': 'custom-business-select'
    }"
    @onOptionsListNotFound="onOptionsListNotFound"
    @onChangeItem="onChangeItem"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot="{ scope }">
      <span>{{ scope.row.licenseNumber }}</span>
      <small>{{ scope.row.businessLicenseName }}</small>
    </template>
  </required-form-item-select>
</template>

<script>
import requiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import { getBusiness } from '@/api/user'
import { getBusinessPageData } from '@/api/common'
export default {
  props: {
    value: {
      type: [String],
      default: ''
    },
    /**
     * @initSuccess
     * 编辑初始化传入id后执行,事件只执行一次
     * 返回值为售达方详情信息
     * */
    initSuccess: {
      type: Function,
      default: () => {}
    }
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  components: {
    requiredFormItemSelect
  },
  watch: {
    value: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal) {
          if (
            this.$refs.itemSelect &&
            this.$refs.itemSelect.$selectRef.onSelectedItem()
          )
            return
          this.businessId = newVal
          const res = await getBusiness({
            id: newVal,
            shopStatus:-1
          })
          const { data } = res
          this.defalutList = data
          if (this.initSuccess) {
            this.initSuccess(data[0])
          }
        }
      }
    }
  },
  data() {
    return {
      businessList: [],
      businessId: '',
      defalutList: [],
      totalCount: 0
    }
  },
  async created() {
    const res = await getBusinessPageData({
      creditCode: '',
      pageSize: 10,
      currPage: 1,
      ifEnabled: 0
    })
    this.defalutList = res.data
    this.totalCount = res.totalCount
  },
  methods: {
    // 获取售达方分页
    async asyncBusinessData({ searchText, page }) {
      const res = await getBusinessPageData({
        key: searchText,
        pageSize: 10,
        currPage: page || 1,
        ifEnabled: 0
      })
      if (res.code === 0) {
        // this.businessList = [...this.businessList, ...res.data]
        // if (res.currPage == 1) {
        //   this.businessList = [...res.data]
        // } else if (res.currPage > 1) {
        //   this.businessList = [...this.businessList, ...res.data]
        // }
        return res.data
      }
    },
    // 获取售达方
    async getBusinessData(e) {
      if (!e) {
        this.$emit('changeValue', '')
        const res = await getBusinessPageData({
          creditCode: '',
          pageSize: 10,
          currPage: 1,
          ifEnabled: 0
        })
        this.defalutList = res.data
        this.$emit('onChange', {})
        return
      }
      this.$emit('changeValue', e.id)
      const res = await getBusiness({
        creditCode: e.creditCode,
        shopStatus:-1
      })
      const { data } = res
      this.$emit('onChange', data[0])
    },
    async onOptionsListNotFound({ value, getDataListParams }, callback) {
      const res = await getBusinessPageData({
        key: value,
        pageSize: 10,
        currPage: 1,
        ifEnabled: 0
      })
      callback(res.data)
    },
    onChangeItem(data) {
      this.$emit('onChangeItem', data)
    }
  }
}
</script>
