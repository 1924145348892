import http from '@/utils/request'
import { getStorage } from '@/utils/storage'

/**
 * 分页培训经销商列表
 * @see http://10.12.0.64/docs/operate/operate-1f4emcpi0d89g
 * @param {object} params
 * @param {string} params.dealerCode
 * @param {number} params.isCanOrder
 * @param {number} params.trainStatus
 * @param {number} params.isOpen
 */
export async function listTrainingDealers(params) {
  const res = await http.get(
    '/dealermanage/crmdistributor/distributorExportPage',
    {
      params
    }
  )
  return res
}

/**
 * 更新培训经销商权限
 * @see http://10.12.0.64/docs/operate/operate-1f4emk2b64l76
 * @param {object} data
 * @param {string} data.id
 * @param {0|1} data.isOpen
 * @param {string} data.closeOrderTime
 * @param {string} data.remark
 */
export async function updateTrainingDealerPermission(data) {
  const user = getStorage('userInfo')
  if (!user) return Promise.reject(new Error('获取用户信息失败'))
  const res = await http.post('/dealermanage/crmdistributor/updateOpen', {
    createBy: user.accountId,
    createName: user.realName || user.nickName,
    ...data
  })
  return res
}

/**
 * 获取培训分数列表
 * @see http://10.12.0.64/docs/operate/operate-1f4emiud43jv7
 * @param {string} dealerCode
 */
export async function getTrainingScores(dealerCode) {
  const res = await http.get('/dealermanage/trainmanagedetail/listpage', {
    params: {
      dealerCode
    }
  })
  return res.data
}

/**
 * 保存培训分数
 * @see http://10.12.0.64/docs/operate/operate-1f4lvd4cesf42
 * @param {string} dealerCode
 * @param {any[]} dealerTrainManageDetails
 */
export async function saveTrainingScores(dealerCode, dealerTrainManageDetails) {
  const res = await http.post('/dealermanage/trainmanagedetail/addOrUpdate', {
    dealerCode,
    dealerTrainManageDetails
  })
  return res
}

/**
 * 删除培训分数
 * @see http://10.12.0.64/docs/operate/operate-1f4emj2fj375d
 * @param {string[]} ids
 */
export async function deleteTrainingScores(ids) {
  const res = await http.post('/dealermanage/trainmanagedetail/delete', ids)
  return res
}

/**
 * 导入经销商培训分数文件
 * @see http://10.12.0.64/docs/operate/operate-1f4emhh87kst9
 * @param {File} file
 */
export async function importDealerTrainingScoresFile(file) {
  const user = getStorage('userInfo')
  if (!user) return Promise.reject(new Error('获取用户信息失败'))
  const formData = new FormData()
  formData.append('file', file)
  formData.append('createBy', user.accountId)
  formData.append('createName', user.realName || user.nickName)
  const res = await http.post(
    '/dealermanage/trainmanagedetail/dealerTrainManageDetailImport',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return res
}
