<template>
  <required-form-item
    ref="formItemRef"
    class="required-form-item-select"
    v-bind="attrs.other"
    :disabled="selectDisabled"
  >
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <slot name="contentBefore" />
    <request-module-select
      ref="selectRef"
      v-bind="attrs.select"
      v-on="listeners"
      @changeDisabled="onChangeDisabled"
    />
  </required-form-item>
</template>

<script>
import requiredFormItem from './requiredFormItem'
import Select from './Select'
import { attrsAssign } from './requiredFormItemSelect.vue'
import requestModuleSelect from './requestModuleSelect'

export default {
  components: {
    requiredFormItem,
    [Select.name]: Select,
    requestModuleSelect
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  data() {
    return {
      selectDisabled: false
    }
  },
  computed: {
    $formItemRef() {
      return this.$refs.formItemRef
    },
    $selectRef() {
      if (this.$refs.selectRef) {
        return this.$refs.selectRef.$selectRef
      }
      return null
    },
    listeners({ $listeners, $attrs }) {
      return Object.assign($listeners, $attrs['selectListeners'], $attrs['select-listeners'])
    },
    attrs({ $attrs }) {
      return attrsAssign($attrs)
    }
  },
  methods: {
    onChangeDisabled(bool) {
      this.selectDisabled = bool
    }
  }
}
</script>
