<!--
 * @Author: your name
 * @Date: 2024-06-24 15:40:11
 * @LastEditTime: 2024-07-03 09:07:30
 * @LastEditors: Michaels-MacBook.local
 * @Description: In User Settings Edit
 * @FilePath: /zhaoshang/src/views/bpmFlow/components/ApprovalColumn.vue
-->
<template>
  <div>
    <RequiredFormItemInput
      v-if="fieldItem.fieldType === 'input'"
      v-model="modelValue"
      :class="className"
      :required="fieldItem.require"
      :prop="prop || fieldItem.fdFieldId"
      :disabled-prior="!fieldItem.edit"
      :label="fieldItem.fdName"
      v-bind="fieldItem.fieldProps" />
    <RequiredFormItemDatePicker
      v-if="fieldItem.fieldType === 'date'"
      v-model="modelValue"
      :class="className"
      :required="fieldItem.require"
      :prop="prop || fieldItem.fdFieldId"
      :disabled-prior="!fieldItem.edit"
      :label="fieldItem.fdName"
      v-bind="fieldItem.fieldProps" />
    <RequiredFormItemSelect
      v-if="fieldItem.fieldType === 'select'"
      v-model="modelValue"
      :class="className"
      :required="fieldItem.require"
      :prop="prop || fieldItem.fdFieldId"
      :disabled-prior="!fieldItem.edit"
      :label="fieldItem.fdName"
      v-bind="fieldItem.fieldProps" />
    <RequiredFormItemSelect
      v-if="fieldItem.fieldType === 'dycSelect'"
      v-model="modelValue"
      :class="className"
      :required="fieldItem.require"
      :prop="prop || fieldItem.fdFieldId"
      :disabled-prior="!fieldItem.edit"
      :label="fieldItem.fdName"
      v-bind="fieldItem.fieldProps" />
    <RequiredFormItemUploadFile
      v-if="fieldItem.fieldType === 'file'"
      v-model="modelValue"
      :class="className"
      :required="fieldItem.require"
      :prop="prop || fieldItem.fdFieldId"
      :disabled-prior="!fieldItem.edit"
      :label="fieldItem.fdName"
      v-bind="fieldItem.fieldProps" />
  </div>
</template>

<script>
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'

export default{
  components: {
    RequiredFormItemInput,
    RequiredFormItemDatePicker,
    RequiredFormItemSelect,
    RequiredFormItemUploadFile
  },
  data() {
    return {
      modelValue: ''
    }
  },
  props: {
    fieldItem: Object,
    value: {
      type: String | Number | Object | Array | Boolean,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    }
  },
  watch:{
    value:{
      handler(val){
        this.modelValue = val
      },
      immediate:true
    },
    modelValue:{
      handler(val){
        this.$emit('input', val)
      }
    }
  }
}

</script>

<style scoped lang="scss"></style>
