import http from "@/utils/request";

//保存
export const submitShopRevoke = data => http({
  url:'/dealermanage/shopRevoke/submitShopRevoke',
  method:'post',
  data
})
//获取详情
export const shopRevokeDetail = params => http({
  url:'/dealermanage/shopRevoke/getRevokeInfo',
  method:'get',
  params
})
//获取门店
export const bossAuthShopList = params => http({
  url:'/dealermanage/shop/apply/shoplist',
  method:'get',
  params
})