<template>
  <div class="app-container">
    <TablePro
      ref="tableProRef"
      :request="getData"
      :columns="columns"
      auto-width-cell
    />
    <el-dialog title="修改实控人挂靠执照" :visible.sync="dialogVisible" width="600px" @close="cancel">
      <el-form :model="form" style="padding:0 20px 20px">
        <el-form-item>
          <el-radio-group v-model="form.businessLicenseId" @change="changeBusinessLicense">
              <div style="margin-bottom:10px" v-for="(item,index) in attachLicenseList">
                <el-radio  :key="index" :label="item.id" >{{item.businessLicenseName}}（{{item.province}}）（{{item.ifEnabled?'失效':'有效'}}）</el-radio>
              </div>
              
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TablePro, { render } from '@/components/TablePro/index.vue'
import { getBossOrganizationPage,getAttachLicenseList,bossBindLicense } from '@/api/weChatWorkDepartmentManage'
export default {
  components: { TablePro },
  data() {
    return {
      loading:false,
      attachLicenseList:[],
      dialogVisible: false,
      form: {
        bossId:'',
        businessLicenseId:'',
        province:''
      },
      ifEnabled:0,
      columns: [
        { title: '实控人编号', name: 'bossNumber', search: true },
        { title: '实控人名称', name: 'bossName', search: true },
        {
          title: '实控人状态',
          name: 'bossIfEnabled',
          valueEnum: [
            { label: '有效', value: 0 },
            { label: '失效', value: 1 }
          ]
        },
        { title: '当前挂靠售达方编号', name: 'licenseNumber', search: true },
        {
          title: '当前挂靠售达方名称',
          name: 'businessLicenseName',
          search: true
        },
        {
          title: '操作',
          render: render((h, { data, index }) => {
            return (
              <div>
                <el-button
                  onClick={() => {
                    this.getAttachLicenseList(data)
                  }}
                >
                  修改挂靠执照
                </el-button>
              </div>
            )
          })
        }
      ]
    }
  },
  methods: {
    changeBusinessLicense(e){
      const arr = this.attachLicenseList.filter(v=>v.id === e)
      this.form.province = arr?.[0]?.province || ''
      this.ifEnabled = arr?.[0]?.ifEnabled || 0
    },
    cancel(){
      for(const key in this.form){
        this.form[key] = ''
      }
      this.dialogVisible = false
    },
    submit(){
      if(this.ifEnabled){
        this.$message.error('无法挂靠至已失效执照')
        return
      }
      this.loading = true
      bossBindLicense(this.form).then(res=>{
        const {code,msg} = res
        if(code!=0){
          this.$message.error(msg)
          return
        }
        this.$message.success(msg)
        this.cancel()
        this.$refs.tableProRef.onRefreshTableList()
      }).finally(() => {
        this.loading = false
      })
    },
    getAttachLicenseList({bossId,businessLicenseId}){
      getAttachLicenseList(bossId).then(res=>{
        const {code,msg,data} = res
        if(code!=0){
          this.$message.error(msg)
          return
        }
        this.attachLicenseList = data
        this.form.bossId = bossId
        this.form.businessLicenseId = businessLicenseId
        const arr = data.filter(v => v.id === businessLicenseId)
        this.form.province = arr?.[0]?.province
        this.ifEnabled = arr?.[0]?.ifEnabled
        this.dialogVisible = true
      })
    },
    getData(params) {
      return getBossOrganizationPage(params).then(res => {
        const {code,msg,data,totalCount} = res
        if(code!=0){
          this.$message.error(msg)
          return {
            data:[],
            totalCount:0
          }
        }
        return {
          data,
          totalCount
        }
      })
    }
  }
}
</script>

<style>
</style>