<template>
  <transition-group
    tag="ul"
    :class="[
      'el-upload-list',
      'el-upload-list--' + listType,
      { 'is-disabled': disabled }
    ]"
    name="el-list"
  >
    <li
      v-for="file in files"
      :key="file.uid"
      :class="[
        'el-upload-list__item',
        'is-' + file.status,
        focusing ? 'focusing' : ''
      ]"
      tabindex="0"
      @keydown.delete="!disabled && $emit('remove', file)"
      @focus="focusing = true"
      @blur="focusing = false"
      @click="focusing = false"
    >
      <slot :file="file">
        <a class="el-upload-list__item-name" @click="handleClick(file)">
          <img
            v-if="/png|jpe?g|gif|svg/.test(file.name)"
            class="img"
            :src="file.url || (file.response && file.response.url)"
            @click.stop="$emit('preview-button', file)"
          >
          <i v-else class="el-icon-document" />
          <span>
            {{ file.name.substr(0, 30) }}
          </span>
        </a>
        <label class="el-upload-list__item-status-label">
          {{
            {
              ready: '未上传',
              pausing: '暂停中',
              paused: '已暂停',
              uploading: '上传中',
              success: '上传成功',
              fail: '上传失败'
            }[file.status]
          }}
        </label>
        <el-button
          v-if="file.status === 'success' && showPreviewButton && disabled"
          class="preview"
          type="text"
          size="small"
          @click="$emit('preview-button', file)"
        >
          预览
        </el-button>

        <div class="progress">
          <div class="progress-bar">
            <el-progress :percentage="parsePercentage(file.percentage)" />
          </div>
          <template v-if="withPause">
            <el-button
              v-if="['uploading', 'pausing'].includes(file.status)"
              type="text"
              size="small"
              :loading="file.status === 'pausing'"
              @click="$emit('pause', file)"
            >
              暂停
            </el-button>
            <el-button
              v-else-if="file.status === 'paused'"
              type="text"
              size="small"
              @click="$emit('resume', file)"
            >
              续传
            </el-button>
          </template>
          <el-button
            v-if="file.status === 'fail'"
            type="text"
            size="small"
            @click="$emit('retry', file)"
          >
            重试
          </el-button>
          <el-button
            v-if="file.status === 'success' && showPreviewButton"
            type="text"
            size="small"
            @click="$emit('preview-button', file)"
          >
            预览
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="$emit('remove', file)"
          >
            取消
          </el-button>
        </div>
      </slot>
    </li>
  </transition-group>
</template>
<script>
import Locale from 'element-ui/src/mixins/locale'
import ElProgress from 'element-ui/packages/progress'

export default {
  name: 'ElUploadList',
  components: { ElProgress },

  mixins: [Locale],

  props: {
    files: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listType: String,
    showPreviewButton: {
      type: Boolean,
      default: false
    },
    withPause: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      focusing: false
    }
  },
  methods: {
    parsePercentage(val = 100) {
      return parseInt(val, 10)
    },
    handleClick(file) {
      this.$emit('preview', file)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-upload-list__item {
  .el-upload-list__item-name {
    display: flex;
    white-space: inherit;
    margin-right: 50px;
    align-items: center;
    span {
      overflow: auto;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
      display: block;
      flex: 1;
    }
  }
  &:focus {
    outline: none;
  }
  &:hover {
    ::v-deep .el-progress__text {
      display: block;
    }
  }
}
.el-upload-list__item-status-label {
  position: absolute;
  right: 0;
  top: 2px;
  display: block !important;
  font-size: 12px;
  color: #979797;
}
.preview {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  top: 50%;
  display: block !important;
  font-size: 12px;
  color: #979797;
  padding: 4px 0;
}
.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .progress-bar {
    flex: 1;
    position: relative;
    margin-right: 30px;
    height: 6px;
    .el-progress {
      top: -4px;
      padding-right: 40px;
    }
    ::v-deep .el-progress__text {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.el-upload-list__item-name {
  display: flex;
  align-items: flex-end;
  .img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    object-fit: contain;
  }
}
</style>
