import http, {download} from '../../utils/request';

export const getDataList = (params) => {
  return http({
    method: 'get',
    url: 'consumermanage/clientrecord/clientListPage',
    params: params
  })
}

/* 新增页所有下拉 */
export const getSelectList = (params) => {
  return http({
    method: 'get',
    url: '/consumermanage/datadictionary/list',
    params
  })
}

/* 获取系列 */
export const getSeriesList = (params) => {
  return http({
    method: 'get',
    url: '/dealermanage/brandseries/listpage',
    params
  })
}

/* 获取招商主管列表 */
export const getRegionUserList = (params) => {
  return http({
    method: 'get',
    url: '/consumermanage/regionuser/listpage',
    params
  })
}

export const edit = (data) => {
  return http({
    method: 'post',
    url: '/consumermanage/clientrecord/save',
    data
  })
}

/* 历史修改记录 */
export const getUpdateRecord = (params) => {
  return http({
    method: 'get',
    url: '/consumermanage/clientrecord/clientList',
    params
  })
}

export const detail = (id) => {
  return http({
    method: 'get',
    url: '/consumermanage/clientrecord/info/' + id
  })
}

export const record = (params) => {
  return http({
    method: 'get',
    url: 'consumermanage/clientrecord/clientList',
    params
  })
}

export const exportExcel = (params) => {
  return download('consumermanage/clientrecord/export', params)
}

export const getFollowUserList = (params) => {
  return http({
    method: 'get',
    url: 'user/account/effective',
    params
  })
}
