var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.FlowForm,{ref:"flowFormRef",attrs:{"fd-form-template-code":_setup.fdFormTemplateCode,"model":_setup.flowData,"is-edit":[0, 20].includes(Number(_setup.flowData?.status)),"approval-data":_setup.approvalData,"on-submit":_setup.onSubmit,"on-approval":_setup.onApproval,"get-detail":_setup.getDetail,"title":"慕思经典-门店状态调整申请流程"}},[_c(_setup.StoreRenovationBaseForm,{attrs:{"disabled":_setup.flowData.linkProcessCode == '',"model-value":_setup.flowData,"base-keys":_setup.innerBaseKeys,"link-flow-params":{ processCode: _setup.flowData.linkProcessCode, status: '' }},on:{"update:modelValue":function($event){_setup.flowData=$event},"update:model-value":function($event){_setup.flowData=$event},"linkFlowChange":_setup.onLinkFlowChange},scopedSlots:_vm._u([{key:"custom-base-item",fn:function({ formKey, formLabel }){return [(formKey === 'decorationStatus')?_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"label-key":"label","value-key":"value","prop":"approveData.decorationStatus","label":formLabel,"select-props":{ clearable: true },"data-list":[
          { label: '无须装修', value: '3' },
          { label: '门店失效', value: '2' },
          { label: '流程失效', value: '1' }
        ]},model:{value:(_setup.flowData.approveData.decorationStatus),callback:function ($$v) {_vm.$set(_setup.flowData.approveData, "decorationStatus", $$v)},expression:"flowData.approveData.decorationStatus"}}):(formKey === 'linkProcessCode')?_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"label-key":"label","value-key":"value","prop":formKey,"label":formLabel,"select-props":{ clearable: true },"data-list":[
          { label: '经典门店管理流程', value: 'JXS020' },
          { label: '经典门店设计流程', value: 'JXS030' },
          { label: '经典门店装修流程', value: 'JXS031' }
        ]},on:{"onChangeItem":_setup.onLinkTypeChange},model:{value:(_setup.flowData[formKey]),callback:function ($$v) {_vm.$set(_setup.flowData, formKey, $$v)},expression:"flowData[formKey]"}}):_vm._e()]}}])}),_c(_setup.HeaderTitle,{staticStyle:{"margin":"20px 0"},attrs:{"title":"店装物料信息"}}),_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"prop":"approveData.designerReason","label":"调整原因","data-list":[
      '不急装修',
      '取消不装',
      '换位置改图',
      '尺寸差异大改图',
      '门店管理流程数据错误',
      '商场或其他原因停工',
      '换商或经销商淘汰',
      '其他（人工填写）'
    ],"select-props":{ clearable: true }},model:{value:(_setup.flowData.approveData.designerReason),callback:function ($$v) {_vm.$set(_setup.flowData.approveData, "designerReason", $$v)},expression:"flowData.approveData.designerReason"}}),(_setup.flowData.approveData.designerReason === '其他（人工填写）')?_c(_setup.RequiredFormItemInput,{staticClass:"formItem",staticStyle:{"min-width":"40%"},attrs:{"prop":"approveData.designerReasonRemark","label":"填写原因","placeholder":"请填写调整原因","type":"textarea","maxlength":"300","rows":"1","input-props":{ clearable: true }},model:{value:(_setup.flowData.approveData.designerReasonRemark),callback:function ($$v) {_vm.$set(_setup.flowData.approveData, "designerReasonRemark", $$v)},expression:"flowData.approveData.designerReasonRemark"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }