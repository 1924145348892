import { getStorage } from '@/utils/storage'

export default {
  inserted(el, binding) {
    const { value } = binding
    const userInfo = getStorage('userInfo')
    const roles = (userInfo && userInfo.permList) || []

    if (value && value instanceof Array && value.length > 0) {
      
      const hasPermission = roles.some(role => value.includes(role))
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`need roles! Like v-permission="['perm.supper.admin']"`)
    }
  }
}
