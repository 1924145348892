<!--
 * @Author: your name
 * @Date: 2024-05-28 10:28:54
 * @LastEditTime: 2024-10-16 16:40:44
 * @LastEditors: 林雏燕 785648832@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /zhaoshang/src/views/flow/terminalStoresFlow.vue
-->
<template>
  <FlowForm
    ref="flowFormRef"
    fd-form-template-code="distributor-JXS029"
    :is-edit="[0, 20].includes(Number(formData.status))"
    :model="formData"
    :on-submit="handleSave"
    :approval-data="approvalData"
    :getDetail="getDetail"
    title="终端门店巡检整改流程"
    :onApproval="onApproval">
    <BaseMessage
      :title="formData.title"
      :number="formData.orderNumber"
      :zsOrderNumber="formData.zsOrderNumber" />
    <headerTitle title="经销商个人基础信息" />
    <RequiredFormItemInput
      v-for="item in flowBasicColumns"
      :key="item.name"
      :required="item.required"
      class="formItem"
      :value="formData[item.name] ?? ''"
      :label="item.label"
      :readonly="item.readonly"
      :placeholder="item.placeholder" />
    <div
      v-for="item in approvalColumns.filter(item =>
        ['storeFindingsOfAudit'].includes(item.fdFieldId)
      )"
      :key="item.fdFieldId">
      <ApprovalColumn
        v-model="formData[item.fdFieldId]"
        className="formItem"
        :prop="`${item.fdFieldId}`"
        :fieldItem="item" />
    </div>
    <headerTitle title="门店巡检整改信息" />
    <div v-if="isMobile">
      <div
        style="margin: 20px 0"
        v-for="(list, index) in formData.storeInspectionRectificationList">
        <div v-for="item in mobileColumns" :key="item.name">
          <div v-if="item.valueType === 'select'">
            <RequiredModuleFormItemSelect
              v-model="list[item.name]"
              :type="item.valueModule"
              class="formItem"
              :label="item.title"
              :disabled="true"
              :required="false"
              placeholder="自动生成" />
          </div>
          <div v-else-if="item.valueType === 'image'">
            <div v-if="list[item.name]">
              <el-form-item class="formItem" :label="item.title">
                <el-image
                  v-for="image in list[item.name]"
                  :key="image"
                  :src="image"
                  style="width: 50px; height: 50px; margin-right: 10px"
                  :previewSrcList="list[item.name]" />
              </el-form-item>
            </div>
          </div>
          <div v-else>
            <RequiredFormItemInput
              class="formItem"
              :value="list[item.name] ?? ''"
              :label="item.title"
              :readonly="true"
              :required="false"
              placeholder="自动生成" />
          </div>
        </div>
        <RequiredFormItemInput
          class="formItem"
          v-model="list.rectificationRequirements"
          label="整改要求"
          type="textarea"
          :prop="`storeInspectionRectificationList.${index}.rectificationRequirements`"
          :rows="2"
          placeholder="请输入内容" />
        <div v-if="![0, 20].includes(Number(formData.status))">
          <div v-for="item in approvalColumnsTable">
            <ApprovalColumn
              className="formItem"
              :prop="`storeInspectionRectificationList.${index}.${item.fdFieldId}`"
              v-model="list[item.fdFieldId]"
              :fieldItem="item" />
          </div>
        </div>
      </div>
    </div>
    <TablePro
      v-else
      style="margin-bottom: 16px"
      :columns="columns"
      :defaultDataList="formData.storeInspectionRectificationList"
      :autoHeight="false"
      auto-width-cell />
    <template #approval-form>
      <div />
    </template>
  </FlowForm>
</template>

<script>
import { mapState } from 'vuex'
import { getStorage } from '@/utils/storage'
import FlowForm from './components/flowForm.vue'
import BaseMessage from './components/baseMessage'
import headerTitle from './components/headerTitle'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import TablePro, { render } from '@/components/TablePro/index.vue'
import { deepCopy } from 'ali-oss/lib/common/utils/deepCopy'
import moment from 'moment'
import {
  flowSubmit,
  submitApproveData,
  saveDraft,
  flowDetail
} from './api/terminalStoresFlow'
import ApprovalColumn from './components/ApprovalColumn.vue'
import { queryColumnAuthority } from './api'
const userInfo = getStorage('userInfo')
const flowBasicColumns = [
  {
    label: '提单人工号',
    name: 'optJobNumber',
    readonly: true,
    required: false,
    placeholder: '自动生成'
  },
  {
    label: '提单人',
    name: 'optUserName',
    readonly: true,
    required: false,
    placeholder: '自动生成'
  },
  {
    label: '提单部门',
    name: 'optDeptName',
    readonly: true,
    required: false,
    placeholder: '自动生成'
  },
  {
    label: '提单日期',
    name: 'optTime',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '售达方',
    name: 'licenseNumber',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '售达方名称',
    name: 'businessLicenseName',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '法人',
    name: 'legalPersonName',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '法人联系方式',
    name: 'legalPersonContact',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '送达方',
    name: 'shopBrandNumber',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '送达方名称',
    name: 'deliveryPartyName',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '省份',
    name: 'province',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '地级市',
    name: 'city',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '区县',
    name: 'area',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '营销部',
    name: 'bigRegionName',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '营销中心',
    name: 'smallRegionName',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  },
  {
    label: '品牌',
    name: 'brandName',
    required: false,
    readonly: true,
    placeholder: '自动生成'
  }
]

const resultOptions = [
  {
    label: '整改期限内合格',
    value: '整改期限内合格'
  },
  {
    label: '整改期限内不合格',
    value: '整改期限内不合格'
  },
  {
    label: '逾期未整改',
    value: '逾期未整改'
  }
]
export default {
  components: {
    FlowForm,
    BaseMessage,
    headerTitle,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    TablePro,
    ApprovalColumn
  },
  data() {
    return {
      approvalColumns: [],
      formData: {
        storeInspectionRectificationList: []
      },
      approvalData: {},
      flowBasicColumns,
      resultOptions,
      userInfo,
      mobileColumns: [
        {
          title: '送达方编码',
          name: 'shopBrandNumber'
        },
        {
          title: '送达方名称',
          name: 'deliveryPartyName'
        },
        {
          title: '门店编码',
          name: 'shopNumber'
        },
        {
          title: '门店地址',
          name: 'shopAddress'
        },
        {
          title: '门店状态',
          name: 'shopStatus',
          valueType: 'select',
          valueModule: 'shop_status'
        },
        {
          title: '违规类型',
          name: 'violationType'
        },
        {
          title: '违规情形',
          name: 'violationDetail'
        },
        {
          title: '违规资料',
          name: 'violationImages',
          valueType: 'image'
        },
        {
          title: '违约金收取金额（元）',
          name: 'liquidatedDemages'
        },
        // {
        //   title: '备注',
        //   name: 'remarks'
        // },
        {
          title: '整改开始时间',
          name: 'rectificationStartTime'
        },
        {
          title: '整改结束时间',
          name: 'rectificationEndTime'
        },
        {
          title: '整改期限',
          name: 'rectificationDeadline'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isMobile: state =>
        state.app.device === 'mobile' || state.app.isWxworkMoblie
    }),
    approvalColumnsTable({ approvalColumns }) {
      return approvalColumns.filter(
        item => item.fdFieldId !== 'storeFindingsOfAudit'
      )
    },
    columns({ approvalColumnsTable }) {
      const column = [
        {
          title: '序号',
          valueType: 'index',
          tableColumnProps: { align: 'center' }
        },
        {
          title: '送达方编码',
          name: 'shopBrandNumber'
        },
        {
          title: '送达方名称',
          name: 'deliveryPartyName'
        },
        {
          title: '门店编码',
          name: 'shopNumber'
        },
        {
          title: '门店地址',
          name: 'shopAddress',
          width: '200px'
        },
        {
          title: '门店状态',
          name: 'shopStatus',
          width: '150',
          render: render((_, context) => {
            return (
              <RequiredModuleFormItemSelect
                class="required-table-item"
                value={context.data.shopStatus}
                type="shop_status"
                label="门店状态"
                showLabel={false}
                disabled={true}
              />
            )
          })
        },
        {
          title: '违规类型',
          name: 'violationType'
        },
        {
          title: '违规情形',
          name: 'violationDetail'
        },
        {
          title: '违规资料',
          name: 'violationImages',
          width: '250',
          render: render((_, context) => {
            return (
              <div>
                {context.data.violationImages.map((image, index) => (
                  <el-image
                    style={{
                      width: '50px',
                      height: '50px',
                      marginRight: '10px'
                    }}
                    key={index}
                    src={image}
                    alt={`Violation ${index}`}
                    fit="cover"
                    previewSrcList={context.data.violationImages}
                  />
                ))}
              </div>
            )
          })
        },
        {
          title: '违约金收取金额（元）',
          name: 'liquidatedDemages'
        },
        // {
        //   title: '备注',
        //   name: 'remarks'
        // },
        {
          title: '整改开始时间',
          name: 'rectificationStartTime'
        },
        {
          title: '整改结束时间',
          name: 'rectificationEndTime'
        },
        {
          title: '整改期限',
          name: 'rectificationDeadline'
        },
        {
          title: '整改要求',
          name: 'rectificationRequirements',
          width: '200',
          render: render((_, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.rectificationRequirements}
                label="整改要求"
                type="textarea"
                showLabel={false}
                prop={`storeInspectionRectificationList.${context.index}.rectificationRequirements`}
                rows={2}
                placeholder="请输入内容"
              />
            )
          })
        }
      ]
      if (approvalColumnsTable.length > 0) {
        approvalColumnsTable.forEach((item, index) => {
          item.fieldProps = {
            ...item?.fieldProps,
            showLabel: false
          }
          column.push({
            title: item.fdName,
            name: item.fdFieldId,
            width: '200',
            hideInTable: [0, 20].includes(Number(this.formData.status)),
            render: render((_, context) => {
              return (
                <ApprovalColumn
                  prop={`storeInspectionRectificationList.${context.index}.${item.fdFieldId}`}
                  v-model={context.data[item.fdFieldId]}
                  fieldItem={item}
                />
              )
            })
          })
        })
      }
      return column
    }
  },
  methods: {
    // 审批配置
    getApprovalColumns(column) {
      console.log('approvalColumns', column)
      this.approvalColumns = column
    },
    async getQueryColumnAuthority() {
      this.approvalColumns = await queryColumnAuthority({
        formType: route.query?.formType || 0, //formType: 0 或 null, 原审批页面，1，查询页面
        formInstanceId: this.$route.query.id || this.$route.query.businessId,
        loginName: userInfo.code,
        processId: this.$route.query.processId,
        fdFormTemplateCode: 'distributor-JXS029',
        fdNodeType: 2
      }).then(({ data = [] }) =>
        data
          .filter(item => {
            return item.show
          })
          .map(item => {
            item.fieldType = 'input'
            if (item.remark) {
              // type: input/date/select/dycSelect
              // {\"type\":\"select\",\"selectList\":[{\"label\":\"男\",\"value\":\"1\"},{\"label\":\"女\",\"value\":\"0\"}]}
              // "{\"type\":\"dycSelect\",\"dycJson\":\"{url:\\\"接口链接\\\",params:\\\"接口参数\\\",pageSize:\\\"\\\",currentPage:\\\"\\\",lable:\\\"\\\",value:\\\"\\\"}\"}"
              // console.log(item.remark)
              const tempObj = JSON.parse(item.remark)
              item.fieldType = tempObj.type ? tempObj.type : 'input'
              if (item.fieldType === 'select') {
                item.fieldProps = {
                  labelKey: 'label',
                  valueKey: 'value',
                  dataList: tempObj.selectList
                }
              }
              if (item.fieldType === 'dycSelect') {
                const {
                  label,
                  value,
                  multiple,
                  searchName,
                  fieldProps,
                  ...dycJson
                } = JSON.parse(tempObj.dycJson)
                item.fieldProps = {
                  labelKey: label,
                  valueKey: value,
                  multiple: multiple ?? false,
                  getDataList: ({ page, getDataListParams, searchText }) => {
                    dycJson.params = {
                      ...getDataListParams,
                      ...dycJson.params,
                      [searchName || value]: searchText,
                      currPage: page
                    }
                    return request({
                      ...dycJson
                    })
                  },
                  // 请求下拉列表额外传参
                  getDataListParams: computed(() => {
                    const params = {}
                    console.log('getDataListParams', params)
                    return params
                  }),
                  ...fieldProps
                }
              }
              if (item.fieldType === 'file') {
                item.fieldProps = tempObj?.dycJson
                  ? JSON.parse(tempObj.dycJson)
                  : {}
              }
            }
            return item
          })
      )
    },
    // 审批字段
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await flowDetail({
          formId: this.$route.query.id || this.$route.query.businessId
        })
        initFormData = data
        initFormData.storeInspectionRectificationList.forEach((item, index) => {
          const violationDetailJson = JSON.parse(item.violationDetailJson)
          item.violationDetail = violationDetailJson.violationDetail
          item.violationImages =
            violationDetailJson.violationImages &&
            violationDetailJson.violationImages.length > 0
              ? violationDetailJson.violationImages
              : []
        })
      }
      this.formData = initFormData
      this.approvalData = initFormData.bpmDealerJoinApproveModel
      if ([0, 20].includes(Number(this.formData.status))) return
      this.getQueryColumnAuthority()
      // if (initFormData) {
      //   for (const key in this.form) {
      //     if (initFormData[key] !== undefined) {
      //       this.form[key] = initFormData[key]
      //     }
      //   }
      //   this.approvalData = initFormData.bpmDealerJoinApproveModel
      // }
    },

    async handleSave(code, bpm = undefined) {
      this.formData.optTime = moment().format('YYYY-MM-DD HH:mm:ss')
      const formDataOptions = deepCopy({
        ...this.formData,
        optUserId: this.userInfo?.accountId || '',
        optUserName: this.userInfo?.realName || '',
        optJobNumber: this.userInfo?.code || '',
        optDeptId: this.userInfo?.deptId || '',
        optDeptName: this.userInfo?.deptName || ''
      })
      formDataOptions.status = code === 'submit' ? 5 : 0
      try {
        if (code === 'submit') {
          formDataOptions.applyOrderModel = {
            //BPM 参数   [必填]
            approveAccount: bpm ? userInfo.code : userInfo.account,
            systemCode: 'distributor',
            fdFormTemplateCode: 'distributor-JXS029',
            pageVersion: 'V1',
            bpmExecuteBean: bpm //bpm传什么是什么
          }
          return flowSubmit(formDataOptions)
        } else {
          const formDraft = await saveDraft([formDataOptions])
          return formDraft
        }
      } catch (err) {
        console.log(err)
      }
    },
    async onApproval(bpmExecuteBean, bpmDealerJoinApproveModel) {
      if (this.formData.status === 20) {
        return handleSave('submit', bpmExecuteBean)
      }
      const result = await this.$refs.flowFormRef.validateForm()
      if (result) {
        // 抛出异常
        throw new Error('请填写完整信息')
      }
      const data = {
        ...this.formData,
        id: this.$route.query.id || this.$route.query.businessId,
        optUserId: this.formData.optUserId || userInfo.accountId,
        optUserName: this.formData.optUserName || userInfo.realName,
        optJobNumber: this.formData.optJobNumber || userInfo.code,
        approveData: bpmDealerJoinApproveModel,
        applyOrderModel: {
          //BPM 参数   [必填]
          approveAccount: userInfo.code || userInfo.account,
          systemCode: 'distributor',
          fdFormTemplateCode: 'distributor-JXS029',
          pageVersion: 'V1',
          bpmExecuteBean //bpm传什么是什么
        }
      }
      return submitApproveData(data)
    }
  }
}
</script>

<style scoped lang="scss">
.required-form-item-input {
  ::v-deep {
    .el-input.is-disabled {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input {
      .el-input__inner[type='number'] {
        padding-right: 0;
      }
    }
  }
}
</style>
