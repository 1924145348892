<script setup>
import { computed, ref } from 'vue'
import StoreRenovationBaseForm from './components/StoreRenovationBaseForm.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import { getStorage } from '@/utils/storage'
import HeaderTitle from '@/views/flow/components/headerTitle'
import FlowForm from './components/flowForm'
import {
  submitDesignApproveData,
  saveDesignDraft,
  submitDesignFlow,
  getDesignFlowDetail,
  getDesignLinkFlowItem
} from './api/classicalStoreChangeFlow'
import { useRoute } from '@/utils/router'
import { requestGetDesignerList, requestJinDieHr } from '@/api/common'

const route = useRoute()

const fdFormTemplateCode = 'distributor-JXS030'
const baseKeys = {
  title: '标题',
  orderNumber: '单号',
  zsOrderNumber: '招商单号',
  linkOrderNumber: '关联流程单号',
  optJobNumber: '提单人工号',
  optUserName: '提单人',
  optDeptName: '提单人部门',
  businessLicenseName: '售达方',
  authorizedBrandName: '送达方',
  bossName: '经销商（实际控股人）',
  bossContactInfo: '经销商联系方式',
  bossNumber: '经销商账号',
  authorizedCityName: '授权城市',
  marketLevelName: '城市等级',
  province: '省份',
  city: '地级市',
  area: '区/县',
  marketCenterName: '营销中心',
  designBrandNames: '门店设计品牌',
  oldBrandNames: '已代理品牌'
}

const shopKeys = {
  parentShopNumber: '物理门店编号',
  shopNumber: '门店编号',
  createShopType: '开店类型',
  storeOpeningMode: '开店模式',
  contactPerson: '门店负责人',
  contactPersonPhone: '门店负责人联系方式',
  renovationLiablePerson: '装修对接人',
  renovationPersonPhone: '装修对接人电话',
  marketName: '卖场名称',
  marketType: '卖场类型',
  shopAddress: '门店地址',
  shopPositionLevel: '门店位置等级',
  storeNature: '店态性质',
  managementModel: '经营模式'
}

const numRule = {
  trigger: 'blur',
  message: '只能填写数字并且最多两位小数',
  validator: (rule, value, callback) => {
    console.log('validator', rule)
    const numberRegex = /^\d+(\.\d{1,2})?$/
    if (value != '' && !numberRegex.test(value)) {
      callback(rule.message)
    } else {
      callback()
    }
  }
}
const flowFormRef = ref(null)
const flowData = ref({ linkOrderNumber: '', beforeRenovationShop: {} })
const approvalData = ref({})
const approvalDataRules = ref({
  zmdlcs: [
    {
      message: '必须是整数，不能为0',
      validator: (rule, value, callback) => {
        if (value != '' && !/^[1-9]\d*$/.test(value)) {
          callback(rule.message)
        } else {
          callback()
        }
      }
    }
  ],
  expandStoreDesignArea: [numRule],
  doorHeaderArea: [numRule],
  zlcsjmj: [numRule],
  jllcsjmj: [numRule],
  qtlcsjmj: [numRule],
  kdmj: [numRule]
})
const flowId = computed(() => {
  const { id, businessId } = route.query
  return id || businessId
})

// 政策类型数据
const getPolicyTypeList = computed(() => (fieldItem, form) => {
  return (
    fieldItem?.fieldProps?.dataList?.map(item => {
      return {
        ...item,
        disabled:
          item.value == '3'
            ? form.syxsZhengCe == '是'
            : form.syxsZhengCe == '否'
      }
    }) ?? []
  )
})

async function getDetail(type, cachedFormData) {
  if (type != 'detail') {
    flowData.value = {
      ...flowData.value,
      ...(cachedFormData || {})
    }
    return
  }
  const { data } = await getDesignFlowDetail({ formId: flowId.value })
  flowData.value = data
  const { approveData = {} } = data
  approvalData.value = {
    ...approveData,
    brands: approveData.brands.split(',')
  }
}

function getOptUserInfo() {
  const userInfo = getStorage('userInfo')
  return {
    optUserId: userInfo.accountId, //提单人ID【必须】',
    optUserName: userInfo.realName, //提单人姓名【必须】,
    optJobNumber: userInfo.code || userInfo.account, //提单人工号【必须】
    optDeptName: userInfo.deptName,
    optDeptId: userInfo.deptId
  }
}

async function onLinkFlowChange(item) {
  try {
    const { data } = await getDesignLinkFlowItem({ formId: item.id })
    flowData.value = {
      ...flowData.value,
      ...data,
      ...getOptUserInfo()
    }
  } catch (error) {
    console.log(error)
  }
}

function onApproval(bpmExecuteBean, bpmApproveModel) {
  bpmApproveModel = {
    ...bpmApproveModel,
    brands: bpmApproveModel?.brands.join(',')
  }

  const { optUserId, optUserName, optJobNumber } = getOptUserInfo()
  const approveFormData = {
    id: flowId.value,
    optUserId, //提单人ID【必须】',
    optUserName, //提单人姓名【必须】,
    optJobNumber, //提单人工号【必须】
    applyOrderModel: {
      approveAccount: optJobNumber, //审批人工号   [必填]',
      businessId: flowId.value,
      processInstanceId: bpmExecuteBean?.processId,
      systemCode: 'distributor', //来源系统  [必传],
      fdFormTemplateCode, //模板/流程编码,
      pageVersion: 'V1', //页面版本 当前流程使用的页面版本  [必传]',
      bpmExecuteBean
    },
    approveData: bpmApproveModel
  }

  if (Number(flowData.value?.status ?? 0) === 20) {
    flowData.value = {
      ...flowData.value,
      approveData: bpmApproveModel,
      applyOrderModel: approveFormData.applyOrderModel
    }
    return flowFormRef.value?.onCommit('submit')
  }

  return submitDesignApproveData(approveFormData)
}

async function onSubmit(type) {
  return type !== 'submit'
    ? saveDesignDraft(flowData.value)
    : submitDesignFlow(flowData.value)
}

const designCountTotal = ref(0)

async function getDesignerList({ page, searchText, getDataListParams }) {
  return requestGetDesignerList({
    currPage: page,
    realName: searchText,
    stylistPost: getDataListParams === '是' ? '点将设计师' : '区域设计师'
  }).then(res => {
    designCountTotal.value = res.totalCount
    return res.data
  })
}

async function onNotFoundDesignerList({ value: account }, callback) {
  callback(await requestGetDesignerList({ account }).then(res => res.data))
}
const sofaConstructionCountTotal = ref(0)

async function getSofaConstructionList({
  page,
  searchText,
  getDataListParams
}) {
  return requestJinDieHr({
    currPage: page,
    personName: searchText,
    isLeft: 0
  }).then(res => {
    sofaConstructionCountTotal.value = res.totalCount
    return res.data
  })
}

async function onNotFoundSofaConstructionList({ value: personNum }, callback) {
  callback(await requestJinDieHr({ personNum }).then(res => res.data))
}
</script>

<template>
  <FlowForm
    ref="flowFormRef"
    :fd-form-template-code="fdFormTemplateCode"
    :model="flowData"
    :is-edit="[0, 20].includes(Number(flowData?.status))"
    :approval-data="approvalData"
    :approval-data-rules="approvalDataRules"
    :on-submit="onSubmit"
    :on-approval="onApproval"
    :get-detail="getDetail"
    title="慕思经典-门店设计流程">
    <StoreRenovationBaseForm
      :model-value.sync="flowData"
      :base-keys="baseKeys"
      :shop-keys="shopKeys"
      @linkFlowChange="onLinkFlowChange" />
    <HeaderTitle
      v-if="flowId"
      style="margin: 20px 0"
      title="终端门店设计信息" />

    <template #approval-divider="{ fieldItem }">
      <div v-if="fieldItem.sort == 20">
        <HeaderTitle
          v-if="flowId"
          style="margin: 20px 0"
          title="终端门店数据" />
      </div>
    </template>
    <template #custom="{ field: fieldItem, form }">
      <RequiredFormItemSelect
        v-if="fieldItem.fdFieldId === 'dianjiangDesigner'"
        v-model="form[fieldItem.fdFieldId]"
        :required="fieldItem.require"
        :prop="fieldItem.fdFieldId"
        :disabled-prior="!fieldItem.edit"
        :label="fieldItem.fdName"
        :select-props="{ clearable: true }"
        :label-key="({ realName, account }) => `${realName}  ${account}`"
        :total-count="designCountTotal"
        :getDataListParams="form.ifDianjiangDesigner"
        :get-data-list="getDesignerList"
        :get-next-list="getDesignerList"
        value-key="account"
        class="formItem"
        @onOptionsListNotFound="onNotFoundDesignerList"
        @clear="
          () => (approvalData = { ...approvalData, ...form, designerPhone: '' })
        "
        @onChangeItem="
          ({ phone }) => {
            approvalData = {
              ...approvalData,
              ...form,
              designerPhone: phone
            }
          }
        " />
      <RequiredFormItemSelect
        v-else-if="fieldItem.fdFieldId === 'ifDianjiangDesigner'"
        v-model="approvalData[fieldItem.fdFieldId]"
        v-bind="fieldItem.fieldProps"
        class="formItem"
        :required="fieldItem.require"
        :prop="fieldItem.fdFieldId"
        :disabled-prior="!fieldItem.edit"
        :label="fieldItem.fdName"
        :select-props="{ clearable: true }"
        @onChangeItem="
          () => {
            approvalData = {
              ...approvalData,
              ...form,
              dianjiangDesigner: '',
              designerPhone: ''
            }
          }
        " />
      <RequiredFormItemSelect
        v-else-if="fieldItem.fdFieldId === 'sofaConstruction'"
        v-model="form[fieldItem.fdFieldId]"
        :required="fieldItem.require"
        :prop="fieldItem.fdFieldId"
        :disabled-prior="!fieldItem.edit"
        :label="fieldItem.fdName"
        :select-props="{ clearable: true, filterable: true, remote: true }"
        :label-key="
          ({ personName, personNum }) => `${personName}  ${personNum}`
        "
        :total-count="sofaConstructionCountTotal"
        :get-data-list="getSofaConstructionList"
        :get-next-list="getSofaConstructionList"
        value-key="personNum"
        class="formItem"
        @onOptionsListNotFound="onNotFoundSofaConstructionList" />
      <template v-else-if="fieldItem.fdFieldId === 'acreageCount'">
        <RequiredFormItemInput
          v-if="flowData.beforeRenovationShop.marketType === '独立店'"
          v-model="form[fieldItem.fdFieldId]"
          :required="fieldItem.require"
          :prop="fieldItem.fdFieldId"
          :disabled-prior="!fieldItem.edit"
          class="formItem"
          :label="fieldItem.fdName"
          :rules="approvalDataRules[fieldItem.fdFieldId]" />
        <span v-else></span>
      </template>
      <RequiredFormItemSelect
        v-else-if="fieldItem.fdFieldId === 'syxsZhengCe'"
        v-model="approvalData[fieldItem.fdFieldId]"
        v-bind="fieldItem.fieldProps"
        class="formItem"
        :required="fieldItem.require"
        :prop="fieldItem.fdFieldId"
        :disabled-prior="!fieldItem.edit"
        :label="fieldItem.fdName"
        :select-props="{ clearable: true }"
        @onChangeItem="() => (approvalData.xszhengceType = '')" />
      <RequiredFormItemSelect
        v-else-if="fieldItem.fdFieldId === 'xszhengceType'"
        v-model="approvalData[fieldItem.fdFieldId]"
        v-bind="fieldItem.fieldProps"
        class="formItem"
        disabled-key="disabled"
        :required="fieldItem.require"
        :prop="fieldItem.fdFieldId"
        :disabled-prior="!fieldItem.edit"
        :label="fieldItem.fdName"
        :select-props="{ clearable: true }"
        :data-list="getPolicyTypeList(fieldItem, form)" />
    </template>
  </FlowForm>
</template>
