export default [
  {
    path: 'templateList',
    name: 'templateList',
    component: () => import('@/views/bpmManage/templateList'),
    meta: { title: '流程模板管理' }
  },
  {
    path: 'fieldList',
    name: 'fieldList',
    component: () => import('@/views/bpmManage/fieldList'),
    meta: { title: '流程字段管理' }
  },
  {
    path: 'pageConfig',
    name: 'pageConfig',
    component: () => import('@/views/bpmManage/pageConfig'),
    meta: { title: '页面管理' }
  }
]
