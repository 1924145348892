// 装修补贴/系数
import http from '@/utils/request'
import { getStorage } from '@/utils/storage'

/**
 * 获取装修补贴列表
 * @see http://10.12.0.64/docs/operate/operate-1emutq1h6c9eg
 * @param {object} params
 * @param {number} [params.currPage=1]
 * @param {number} [params.pageSize=10]
 * @param {0|1} params.associatedBusinessId 业务类型(0:补贴系数; 1:补贴上限)
 * @param {string} params.name 查询名称
 */
export async function listDecorationSubsidies(params) {
  const res = await http.get('/dealermanage/configuration/listpage', {
    params: {
      currPage: 1,
      pageSize: 10,
      systemCode: 120,
      ...params
    }
  })
  return res
}

/**
 * 获取装修补贴详情
 * @see http://10.12.0.64/docs/operate/operate-1emutrk1snni3
 * @param {string} id
 */
export async function getDecorationSubsidy(id) {
  const [{ data: detail }, { data: subsidyOperationRules }] = await Promise.all(
    [
      http.get('/dealermanage/configuration/get', { params: { id } }),
      http.get('/dealermanage/operationrule/listpage', {
        params: {
          currPage: 1,
          pageSize: 100,
          configurationId: id
        }
      })
    ]
  )
  return {
    ...detail,
    subsidyOperationRules: subsidyOperationRules.map(v => ({
      ...v,
      content: JSON.parse(v.content || '[]')
    }))
  }
}

/**
 * 保存装修补贴(新增/更新)
 * @see http://10.12.0.64/docs/operate/operate-1emutpts6vu7l
 * @param {object} params
 * @param {string} [params.id]
 * @param {number} params.associatedBusinessId
 * @param {string} params.name
 * @param {0|1} params.enable (0:开; 1:关)
 * @param {object[]} params.subsidyOperationRules
 */
export async function saveDecorationSubsidy(params) {
  const addUrl = '/dealermanage/subsidy/add'
  const updateUrl = '/dealermanage/subsidy/update'
  const subsidyOperationRules = params.subsidyOperationRules.map(rule => ({
    ...rule,
    content: rule.content.length === 0 ? '' : JSON.stringify(rule.content)
  }))
  const user = getStorage('userInfo')
  if (!user) return Promise.reject('获取用户信息失败')
  const res = await http.post(params.id ? updateUrl : addUrl, {
    systemCode: 120,
    ...params,
    subsidyOperationRules,
    createBy: params.id ? undefined : user.realName,
    updateBy: user.realName,
    createTime: undefined,
    updateTime: undefined
  })
  return res.data
}

/**
 * 更新装修补贴状态
 * @see http://10.12.0.64/docs/operate/operate-1emutv8hq9u8e
 * @param {string} id
 * @param {boolean} status
 */
export async function updateDecorationSubsidyStatus(id, status) {
  const user = getStorage('userInfo')
  if (!user) return Promise.reject('获取用户信息失败')
  const res = await http.post('/dealermanage/subsidy/updateEnable', {
    id,
    enable: status ? 0 : 1,
    updateBy: user.realName
  })
  return res.data
}

/**
 * 获取装修补贴条件列表
 * @see http://10.12.0.64/docs/operate/operate-1elnlna8u9ege
 * @param {object} params
 * @param {string} [params.type]
 * @param {string} [params.remark]
 */
export async function getDecorationSubsidyConditions(params) {
  const res = await http.get('/dealermanage/datadictionary/list', {
    params: {
      ...params
    }
  })
  return res.data
}
