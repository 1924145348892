export default [
  {
    path: 'businessPlan',
    name: 'BusinessPlan',
    component: () => import('@/views/businessPlan/businessPlan'),
    meta: { title: '招商计划/目标' },
  },
  {
    path: 'mineBusinessPlan',
    name: 'MineBusinessPlan',
    component: () => import('@/views/businessPlan/mineBusinessPlan'),
    meta: { title: '我的招商计划/目标' }
  },
  {
    path: 'businessPlanDetail',
    name: 'BusinessPlanDetail',
    hidden: true,
    component: () => import('@/views/businessPlan/businessPlanDetail'),
    meta: { title: '查看招商计划/目标' }
  },
  {
    path: 'editMineBusinessPlan',
    name: 'EditMineBusinessPlan',
    hidden: true,
    component: () => import('@/views/businessPlan/editMineBusinessPlan'),
    meta: { title: '编辑我的招商计划' }
  }
]
