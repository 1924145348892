import http from '@/utils/request'
export const getMaketPlaceList = params =>
  http({
    url: '/dealermanage/datadictionary/threeList',
    method: 'get',
    params
  })
export const getStoreType = params =>
  http({
    url: 'dealermanage/datadictionary/list',
    method: 'get',
    params
  })
export const add = data =>
  http({
    url: '/dealermanage/datadictionary/add',
    method: 'post',
    data
  })
export const update = data =>
  http({
    url: '/dealermanage/datadictionary/update',
    method: 'post',
    data
  })
export const updateStatus = data =>
  http({
    url: '/dealermanage/datadictionary/updateStates',
    method: 'post',
    data
  })

/**
 * @description: 获取商场列表
 * @param {*} params
 */
export const requestGetShopList = params =>
  http({
    url: '/dealermanage/datadictionary/marketList',
    data: params,
    method: 'post'
  })
/**
 * @description: 获取传统渠道列表
 * @param {*} params
 */
export const requestGetClassicList = params =>
  http({
    url: '/dealermanage/datadictionary/parentListpage',
    params,
  })
