import http from "@/utils/request";
import {download} from "@/utils/request";

// 获取数据列表
export const getPlanList = params => {
  return http({
    url:'/consumermanage/distributorplan/activitypage',
    params
  })
}

// 获取地区责任人
export const getRegionuserTreeData = params => {
  return http({
    url:'/consumermanage/distributorplan/regionuser',
    params
  })
}

// 获取活动详情
export const getActiveInfo = params => {
  return http({
    url:'/consumermanage/distributorplan/planactivityinfo',
    params
  })
}

// 新增/修改活动
export const addOrUpdateActive = data => {
  return http({
    url:'/consumermanage/distributorplan/saveorupdate',
    data,
    method:'post',
  })
}
// 删除活动
export const delActive = data => {
  return http({
    url:'/consumermanage/distributorplan/delete',
    data,
    method:'post',
  })
}

// 活动下发/作废/完成
export const activeStatusChange = data => {
  return http({
    url:'/consumermanage/distributorplan/updatestatus',
    method:'post',
    data
  })
}

// 获取目标列表
export const getTargetListData = params => {
  return http({
    url:'/consumermanage/activitytarget/targetlist',
    params
  })
}
// 导入目标列表
export const downLoadFile = data => {
  return http({
    url:'/consumermanage/activitytarget/importTarget',
    data,
    method:'post'
  })
}
// 下载模板
export const downloadExcel = () => {
  return download('consumermanage/activitytarget/exportTargetExcel')
}

// 删除目标
export const delTarget = data => {
  return http({
    url:'/consumermanage/activitytarget/deleteProvinceBrandTarget',
    data,
    method:'post'
  })
}
// 重置目标
export const resetTarget = data => {
  return http({
    url:'/consumermanage/activitytarget/deletetarget',
    data,
    method:'post'
  })
}
// 下发目标
export const issuedTarget = data => {
  return http({
    url:'/consumermanage/activitytarget/targetissued',
    data,
    method:'post'
  })
}
// 开店汇总
export const storeDetail = params => {
  return http({
    url:'/consumermanage/activitytarget/storeDetialList',
    params
  })
}

// 导出各区域开店计划
export const excelPlan = params =>{
  return download('consumermanage/activitytarget/exportPlan',params)
}
// ------------------------------------------------------------
// 我的计划列表
export const getMinePlanDataList = params => {
  return http({
    url:'/consumermanage/target/plan/listpage',
    params
  })
}
// 切换目标状态
export const updateMinePlan = params => {
  return http({
    url:'/consumermanage/target/plan/update',
    params
  })
}

// 获取目标概览
export const getMinePlanDetail = params => {
  return http({
    url:'/consumermanage/target/plan/activityByIdPlanList',
    params
  })
}
// 获取我的计划概览
export const getMinePlanMassage = params => {
  return http({
    url:'/consumermanage/target/plan/overview',
    params
  })
}

// 下载模板
export const downloadExc = () => {
  return download('consumermanage/target/plan/download')
}

// 导入excel
export const uploadMinePlan = data =>{
  return http({
    url:'/consumermanage/target/plan/imports',
    data,
    method:'post'
  })
}

// 删除 / 重置
export const delOrReset = params => {
  return http({
    url:'/consumermanage/target/plan/delete',
    params
  })
}
