<template>
  <div>
    <el-tabs
      v-model="form.channelType"
      type="border-card"
      style="margin-top: 16px"
    >
      <el-tab-pane label="传统渠道" name="1">
        <div v-if="form.channelType === '1'">
          <!-- <el-card shadow="none">
            <el-form :inline="true" :model="form">
              <RequiredFormItemSelect
                :required="false"
                label="所属渠道性质一级分类"
                labelKey="content"
                valueKey="id"
                :getDataList="() => getStoreType('store_type')"
                v-model="form.stairId"
                :selectProps="{
                  clearable: true,
                  filterable: true,
                  'popper-class': 'selectWrap'
                }"
                @change="onChange"
              ></RequiredFormItemSelect>
              <RequiredFormItemSelect
                :disabled="disabled"
                :required="false"
                label="所属渠道性质二级分类"
                labelKey="content"
                valueKey="id"
                :dataList="dataList"
                v-model="form.foxBaseId"
                :selectProps="{
                  clearable: true,
                  filterable: true
                }"
              ></RequiredFormItemSelect>
              <el-form-item label="三级分类编号">
                <el-input
                  placeholder="请填写三级分类编号"
                  v-model="form.code"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="query">查询</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <TablePro
            ref="tableProRef"
            :request="getData"
            :columns="columns"
            auto-width-cell
            :auto-height="false"
            :maxAutoWidthTitle="300"
            :actions="['export']"
          >
            <el-table-column
              slot="tableBefore"
              type="selection"
              reserve-selection
              :fixed="true"
            />
            <template #actionArea="{ selectedList }">
              <PopupForm
                ref="popupFormRef"
                popup-type="dialog"
                width="450px"
                title="新增"
                :columns="classicFormColumns"
                :on-submit="onSubmit"
              >
                <template #trigger="{ onShow }">
                  <el-button @click="() => onShow({})"> 新增 </el-button>
                </template>
              </PopupForm>
              <el-badge
                :value="selectedList.length || ''"
                class="item"
                type="info"
              >
                <el-button
                  :disabled="!selectedList.length"
                  @click="updateStatus(0, selectedList)"
                >
                  批量生效
                </el-button>
              </el-badge>
              <el-badge
                :value="selectedList.length || ''"
                class="item"
                type="info"
              >
                <el-button
                  :disabled="!selectedList.length"
                  @click="updateStatus(1, selectedList)"
                >
                  批量失效
                </el-button>
              </el-badge>
            </template>
          </TablePro> -->
          <ClassicChannel />
        </div>
      </el-tab-pane>
      <el-tab-pane label="新渠道" name="2">
        <div v-if="form.channelType === '2'">
          <el-card shadow="none">
            <el-form :inline="true" :model="form">
              <RequiredFormItemSelect
                :required="false"
                label="所属渠道性质一级分类"
                labelKey="content"
                valueKey="id"
                :getDataList="() => getStoreType('store_type')"
                v-model="form.stairId"
                :selectProps="{
                  clearable: true,
                  filterable: true,
                  'popper-class': 'selectWrap'
                }"
                @change="onChange"
              ></RequiredFormItemSelect>
              <RequiredFormItemSelect
                :disabled="disabled"
                :required="false"
                label="所属渠道性质二级分类"
                labelKey="content"
                valueKey="id"
                :dataList="dataList"
                v-model="form.foxBaseId"
                :selectProps="{
                  clearable: true,
                  filterable: true
                }"
              ></RequiredFormItemSelect>
              <el-form-item label="三级分类编号">
                <el-input
                  placeholder="请填写三级分类编号"
                  v-model="form.code"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="query">查询</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <TablePro
            ref="newTableProRef"
            :request="getData"
            :columns="newChannelColumns"
            auto-width-cell
            :auto-height="false"
            :maxAutoWidthTitle="300"
          >
            <el-table-column
              slot="tableBefore"
              type="selection"
              reserve-selection
              :fixed="true"
            />
          </TablePro>
        </div>
      </el-tab-pane>
      <el-tab-pane label="经典卖场管理" name="3">
        <div v-if="form.channelType === '3'">
          <TraditionalChannel />
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :title="type ? '修改' : '新增'"
      :visible.sync="visible"
      @close="cancel"
    >
      <el-form :model="f" label-width="200px" ref="form">
        <RequiredFormItemSelect
          :disabled="true"
          label="所属渠道性质一级分类"
          labelKey="content"
          valueKey="id"
          :getDataList="() => getStoreType('store_type')"
          v-model="f.stairId"
          :selectProps="{
            clearable: true,
            filterable: true
          }"
        ></RequiredFormItemSelect>
        <RequiredFormItemSelect
          prop="parentId"
          :disabled="type ? true : false"
          label="所属渠道性质二级分类"
          labelKey="content"
          valueKey="id"
          :getDataList="() => getStoreType('secondary_store_type')"
          v-model="f.parentId"
          :selectProps="{
            clearable: true,
            filterable: true
          }"
          @changeSelectedItem="onSecondaryChange"
        ></RequiredFormItemSelect>
        <el-form-item label="渠道性质三级分类编号" v-if="type">
          <el-input v-model="f.code" disabled></el-input>
        </el-form-item>
        <el-form-item
          label="渠道性质三级分类名称"
          prop="content"
          :rules="[
            {
              required: true,
              message: '请输入渠道性质三级分类名称',
              trigger: 'blur'
            }
          ]"
        >
          <el-input v-model="f.content" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注(对应联合营销平台编码)">
          <el-input v-model="f.remark" clearable></el-input>
        </el-form-item>
        <el-form-item label="更新人">
          <el-input v-model="f.createName" disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TablePro, { render } from '@/components/TablePro/index.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import {
  getMaketPlaceList,
  getStoreType,
  add,
  update,
  updateStatus
} from '@/api/processBasicConfiguration'
import { getStorage } from '@/utils/storage'
import TraditionalChannel from './components/TraditionalChannel.vue'
import ClassicChannel from '@/views/processBasicConfiguration/components/ClassicChannel'
export default {
  components: {
    TablePro,
    RequiredFormItemSelect,
    TraditionalChannel,
    ClassicChannel
  },
  data() {
    return {
      f: {
        stairId: '',
        parentId: '',
        parentCode: '',
        code: '',
        id: '',
        content: '',
        remark: '',
        createName: ''
      },
      type: 0,
      visible: false,
      disabled: true,
      dataList: [],
      form: {
        stairId: '',
        foxBaseId: '',
        code: '',
        channelType: '1'
      },
      newChannelColumns: [
        {
          title: '渠道性质三级分类编号',
          name: 'code'
        },
        {
          title: '渠道性质三级分类（大客户名称）',
          name: 'content'
        },
        {
          title: '所属渠道性质一级分类',
          name: 'stairContent'
        },
        {
          title: '所属渠道性质二级分类',
          name: 'foxBaseContent'
        },
        {
          title: '合作方执照代码',
          name: 'partnerCreditCode'
        },
        {
          title: '合作方执照名称',
          name: 'partnerBusinessLicenseName'
        },
        {
          title: '合作方联系人（名称）',
          name: 'partnerContactPersonName'
        },
        {
          title: '合作方联系人联系方式',
          name: 'partnerContactPersonPhone'
        },
        {
          title: '对应送达方',
          name: 'senderNumber'
        },
        {
          title: '对应售达方',
          name: 'licenseNumber'
        },
        {
          title: '生/失效',
          name: 'ifDelete',
          valueEnum: [
            { label: '生效', value: 0 },
            { label: '失效', value: 1 }
          ]
        },
        {
          title: '有效日期（起）',
          name: 'startTime'
        },
        {
          title: '有效日期（止）',
          name: 'endTime'
        },
        {
          title: '更新人',
          name: 'createName'
        },
        {
          title: '操作',
          width: 100,
          render: render((h, { data, index }) => {
            if (data.ifDelete) {
              return (
                <div>
                  <el-link
                    size="mini"
                    type="success"
                    underline={false}
                    onClick={() => {
                      this.updateStatus(0, [data])
                    }}
                  >
                    生效
                  </el-link>
                </div>
              )
            } else {
              return (
                <div>
                  <el-link
                    size="mini"
                    type="warning"
                    underline={false}
                    onClick={() => {
                      this.updateStatus(1, [data])
                    }}
                  >
                    失效
                  </el-link>
                </div>
              )
            }
          })
        }
      ],
      columns: [
        {
          title: '渠道性质三级分类编号',
          name: 'code'
        },
        {
          title: '渠道性质三级分类（卖场）',
          name: 'content'
        },
        {
          title: '所属渠道性质一级分类',
          name: 'stairContent'
        },
        {
          title: '所属渠道性质二级分类',
          name: 'foxBaseContent'
        },
        { title: '备注', name: 'remark' },
        {
          title: '生/失效',
          name: 'ifDelete',
          valueEnum: [
            { label: '生效', value: 0 },
            { label: '失效', value: 1 }
          ]
        },
        { title: '更新人', name: 'createName' },
        {
          title: '操作',
          width: 100,
          render: render((h, { data, index }) => {
            if (data.ifDelete) {
              return (
                <div>
                  <el-button
                    size="mini"
                    type="text"
                    onClick={() => {
                      this.updateStatus(0, [data])
                    }}
                  >
                    生效
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    onClick={() => {
                      this.openDialog(data)
                    }}
                  >
                    修改
                  </el-button>
                </div>
              )
            } else {
              return (
                <div>
                  <el-button
                    size="mini"
                    type="text"
                    onClick={() => {
                      this.updateStatus(1, [data])
                    }}
                  >
                    失效
                  </el-button>
                  <el-button
                    size="mini"
                    type="text"
                    onClick={() => {
                      this.openDialog(data)
                    }}
                  >
                    修改
                  </el-button>
                </div>
              )
            }
          })
        }
      ]
    }
  },
  methods: {
    updateStatus(ifDelete, idsList) {
      const _this = this
      const title = ifDelete ? '确定失效吗' : '确定生效吗'
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          idsList: idsList.map(item => item.id),
          ifDelete
        }
        console.log(params)
        updateStatus(params).then(res => {
          const { code, msg } = res
          if (code != 0) {
            _this.$message.error(msg)
            return
          }
          _this.$message.success(msg)
          if (_this.form.channelType === '1') {
            _this.$refs.tableProRef.onRefreshTableList()
          }
          if (_this.form.channelType === '2') {
            _this.$refs.newTableProRef.onRefreshTableList()
          }
        })
      })
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.type) {
            const { id, content, remark, createName } = this.f
            update({
              id,
              content,
              remark,
              createName
            }).then(res => {
              const { code, msg } = res
              if (code != 0) {
                this.$message.error(msg)
                return
              }
              this.$message.success(msg)
              this.cancel()
              if (this.form.channelType === '1') {
                this.$refs.tableProRef.onRefreshTableList()
              }
              if (this.form.channelType === '2') {
                this.$refs.newTableProRef.onRefreshTableList()
              }
            })
          } else {
            const { parentId, parentCode, content, remark, createName } = this.f
            add({
              parentId,
              parentCode,
              module: 'distributor',
              type: 'three_store_type',
              content,
              remark,
              createName
            }).then(res => {
              const { code, msg } = res
              if (code != 0) {
                this.$message.error(msg)
                return
              }
              this.$message.success(msg)
              this.cancel()
              if (this.form.channelType === '1') {
                this.$refs.tableProRef.onRefreshTableList()
              }
              if (this.form.channelType === '2') {
                this.$refs.newTableProRef.onRefreshTableList()
              }
            })
          }
        }
      })
    },
    cancel() {
      for (const key in this.f) {
        this.f[key] = ''
      }
      this.f.createName = getStorage('userInfo').realName
      this.$refs.form.clearValidate()
      this.visible = false
    },
    openDialog(data) {
      for (const key in this.f) {
        this.f[key] = ''
      }
      this.f.createName = getStorage('userInfo').realName
      if (!data) {
        this.type = 0
      } else {
        this.type = 1
        this.f.parentId = data.foxBaseId
        this.f.parentCode = data.foxBaseCode
        this.f.remark = data.remark
        this.f.code = data.code
        this.f.content = data.content
        this.f.id = data.id
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    onSecondaryChange(e) {
      if (!e) {
        this.f.stairId = ''
        return
      }
      this.f.stairId = e.parentId
      this.f.parentCode = e.code
    },
    query() {
      if (this.form.channelType === '1') {
        this.$refs.tableProRef.onSearchTableList(this.form)
      }
      if (this.form.channelType === '2') {
        this.$refs.newTableProRef.onSearchTableList(this.form)
      }
    },
    async onChange(val) {
      console.log(val)
      this.form.foxBaseId = ''
      this.dataList = []
      if (!val) {
        this.disabled = true
        return
      }
      this.dataList = await this.getStoreType('secondary_store_type', val)
      this.disabled = false
    },
    getStoreType(type, parent_id) {
      let params = {
        module: 'distributor',
        type
      }
      if (type === 'secondary_store_type') {
        params = {
          ...params,
          parent_id
        }
      }
      return getStoreType(params).then(res => {
        const { code, msg, data } = res
        if (code != 0) {
          this.$message.error(msg)
          return []
        }
        return data
      })
    },
    getData(params) {
      return getMaketPlaceList({ ...params, ...this.form }).then(res => {
        const { code, msg, data, totalCount } = res
        if (code != 0) {
          this.$message.error(msg)
          return {
            data: [],
            totalCount: 0
          }
        }
        return {
          data,
          totalCount
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
