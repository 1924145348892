<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      width="450px"
      @close="handleClose"
      :close-on-click-modal="false">
      <el-form ref="ruleForm" :model="formOption" label-width="120px">
        <el-form-item
          label="品牌"
          prop="brandCode"
          :rules="[
            { required: true, message: '请选择品牌', trigger: 'change' }
          ]">
          <!-- <el-input v-model="formOption.userId"></el-input> -->
          <el-select
            v-model="formOption.brandCode"
            filterable
            remote
            placeholder="请选择品牌"
            popper-class="custom-business-select"
            @change="handleSelectDept">
            <el-option
              v-for="item in bigBrandMap"
              :key="item.id"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          prop="regionId"
          :rules="[
            { required: true, message: '请选择大区', trigger: 'change' }
          ]"
        > -->
        <!-- <el-input v-model="formOption.regionId"></el-input> -->
        <required-form-item-select
          ref="itemSelect"
          prop="administrativeRegionName"
          label="大区"
          :dataList="Region"
          v-model="formOption.administrativeRegionName"
          labelKey="administrativeRegionName"
          valueKey="administrativeRegionName"
          @changeSelectedItem="
            data => {
              if (data) {
                formOption.administrativeRegionCode =
                  data.administrativeRegionCode
                formOption.administrativeRegionId = data.administrativeRegionId
              }
            }
          "
          :getDataTrigger="[]"
          :selectProps="{
            filterable: true,
            remote: true,
            clearable: true,
            'popper-class': 'custom-business-select'
          }">
          <template v-slot="{ scope }">
            <span>{{ scope.row.administrativeRegionName }}</span>
          </template>
        </required-form-item-select>
        <!-- </el-form-item> -->
        <!-- <el-form-item label="小区" prop="smallRegionId"> -->
        <!-- <el-input v-model="formOption.smallRegionId"></el-input> -->
        <required-form-item-select
          ref="itemSelect"
          label="小区"
          prop="smallRegionName"
          :dataList="SmallRegion"
          v-model="formOption.smallRegionName"
          labelKey="smallRegionName"
          valueKey="smallRegionName"
          @changeSelectedItem="
            data => {
              if (data) {
                formOption.smallRegionCode = data.smallRegionCode
                formOption.smallRegionId = data.smallRegionId
              }
            }
          "
          :getDataTrigger="[]"
          :selectProps="{
            filterable: true,
            remote: true,
            clearable: true,
            'popper-class': 'custom-business-select'
          }"
          :required="true">
          <template v-slot="{ scope }">
            <span>{{ scope.row.smallRegionName }}</span>
          </template>
        </required-form-item-select>
        <!-- </el-form-item> -->
        <el-form-item
          label="地区(省市区)"
          prop="provinceCode"
          :rules="[
            { required: true, message: '请选择地区', trigger: 'change' }
          ]">
          <el-cascader
            v-if="refresh"
            :props="addressProp"
            v-model="selectAddress"></el-cascader>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch
            v-model="formOption.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="0"
            :inactive-value="1">
          </el-switch>
        </el-form-item>
        <el-form-item
          label="部门"
          prop="deptCode"
          :rules="[
            { required: true, message: '请选择部门', trigger: 'change' }
          ]">
          <CommonSelect
            v-model="formOption.deptCode"
            filterable
            remote
            placeholder="请选择部门"
            :get-data-list="getListDetp"
            :get-next-list="getListDetp"
            key="id"
            value-key="code"
            label-key="name"
            @changeSelectedItem="handleSelectDepts" />
          <!-- <el-input v-model="formOption.userId"></el-input> -->
          <!-- <el-select
            v-model="formOption.deptCode"
            filterable
            remote
            placeholder="请选择部门"
            popper-class="custom-business-select"
            @change="handleSelectDepts">
            <el-option
              v-for="item in deptData"
              :key="item.id"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSave">保存</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import requiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
// import { getAddress, getAreaByBrand } from '@/api/commonConfig/areaConfig'
import CommonSelect from '@/components/CustomFormItem/Select.vue'
import * as apiConfig from '@/api/commonConfig/areaConfig'
// import { brandseriesList } from '@/api/common/'
export default {
  name: 'configDeptDialog',
  components: {
    requiredFormItemSelect,
    CommonSelect
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
      required: true
    },
    value: {
      type: Object,
      default: {},
      required: true
    },
    bigBrandMap: {
      type: Array,
      default: [],
      required: true
    }
  },
  watch: {
    hidden: {
      handler(value) {
        this.isShow = value
      },
      immediate: true
    },
    value: {
      async handler(value) {
        // this.formOption = value
        // console.log(value)
        if (value.hasOwnProperty('id')) {
          this.refresh = false
          this.$nextTick(() => {
            this.refresh = true
          })
          for (let key in value) {
            this.formOption[key] = value[key]
          }
          if (value.areaCode && value.areaCode !== '0') {
            this.selectAddress = [
              value.provinceCode,
              value.cityCode,
              value.areaCode
            ]
          } else if (!value.cityCode) {
            this.selectAddress = [value.provinceCode]
          } else {
            this.selectAddress = [value.provinceCode, value.cityCode]
          }
        } else {
          this.selectAddress = []
          this.formOption = {
            brandRegionCode: '',
            deptId: '', // 部门id
            deptCode: '', // 部门编号
            brandId: '', // 品牌id
            brandCode: '', // 品牌编号
            administrativeRegionId: '', //大区id
            administrativeRegionCode: '', //大区编号
            administrativeRegionName: '', //大区名称
            smallRegionId: '', //小区id
            smallRegionCode: '', //小区编号
            smallRegionName: '', //小区名称
            provinceCode: '',
            cityCode: '',
            areaCode: '',
            status: 0,
            id: ''
          }
          this.$refs.ruleForm?.clearValidate()
        }
      },
      immediate: true,
      deep: true
    },
    selectAddress(value) {
      if (value.length > 0) {
        this.formOption.provinceCode = value[0]
        this.formOption.cityCode = value[1] || ''
        this.formOption.areaCode = value[2] || ''
        this.$refs.ruleForm.clearValidate('provinceCode')
      }
    },
    'formOption.brandId'(value) {
      if (value) {
        apiConfig
          .getAreaByBrand({
            brandId: value,
            status: 0
          })
          .then(res => (this.Region = res.data))
      } else {
        this.Region = []
      }
    },
    'formOption.administrativeRegionId'(value) {
      if (value) {
        apiConfig
          .getAreaByBrand({
            brandId: this.formOption.brandId,
            administrativeRegionId: value,
            status: 0
          })
          .then(res => (this.SmallRegion = res.data))
      } else {
        this.SmallRegion = []
      }
    },
    // 'formOption.brandCode'(value) {
    //   if (value) {
    //     this.brandCodeParams = value
    //     this.getListDetp(1, '')
    //   } else {
    //     this.brandCodeParams = ''
    //     // this.deptData = []
    //   }
    // }
  },
  computed: {
    // ...mapState('commonConfig', ['regionData', 'smallRegionData', 'deptData'])
    // ...mapGetters('commonConfig', ['bigBrandMap'])
  },
  data() {
    return {
      Region: [], //大区
      SmallRegion: [], //小区
      formOption: {
        brandRegionCode: '',
        deptId: '', // 部门id
        deptCode: '', // 部门编号
        brandId: '', // 品牌id
        brandCode: '', // 品牌编号
        administrativeRegionId: '', //大区id
        administrativeRegionCode: '', //大区编号
        administrativeRegionName: '', //大区名称
        smallRegionId: '', //小区id
        smallRegionCode: '', //小区编号
        smallRegionName: '', //小区名称
        provinceCode: '',
        cityCode: '',
        areaCode: '',
        status: 0,
        id: ''
      },
      isShow: false,
      loading: false,
      addressProp: {},
      selectAddress: [],
      refresh: true,
      // deptData: [],
      // brandCodeParams: ''
      // bigBrandMap:[]
    }
  },
  async mounted() {
    // const res = await brandseriesList({ brandLevel: 0, ifDelete: 0 })
    // this.bigBrandMap = res.data
    this.addressProp = {
      lazy: true,
      checkStrictly: true,
      // emitPath: false,
      lazyLoad: async (node, resolve) => {
        const { level, value } = node
        let type = ''
        switch (level) {
          case 0:
            type = 'province'
            break
          case 1:
            type = 'city'
            break
          case 2:
            type = 'area'
            break
        }
        const res = await this.getAddressData({
          type: type,
          parentCode: value || null
        })
        // console.log(res, 'res')
        const nodes = res.map(item => {
          if (item.code == '020') {
            return {
              value: item.code,
              label: item.content,
              leaf: true
            }
          } else {
            return {
              value: item.code,
              label: item.content,
              leaf: level >= 2
            }
          }
        })
        resolve(nodes)
      }
    }
    // const res = await this.getAddressData({
    //   type: 'province'
    // })
    // this.addressList = res
  },
  methods: {
    //获取部门
    getListDetp({ page, searchText }) {
      // if (!getDataListParams) return []
      return apiConfig.getDept({
        currPage: page,
        pageSize: 10,
        name: searchText,
        // brandCode: getDataListParams
      })
    },
    // 选择部门
    handleSelectDepts(data) {
      // console.log(data, 'data');
      // const option = this.deptData.find(item => item.code === data.code)
      this.formOption.deptId = data?.id
    },
    handleSelectDept(data) {
      const option = this.bigBrandMap.find(item => item.code === data)
      this.formOption.brandId = option?.id
      this.formOption.administrativeRegionId = ''
      this.formOption.administrativeRegionCode = ''
      this.formOption.administrativeRegionName = ''
      this.formOption.smallRegionId = ''
      this.formOption.smallRegionCode = ''
      this.formOption.smallRegionName = ''
      this.formOption.brandRegionCode = ''
      // this.formOption.deptId = ''
      // this.formOption.deptCode = ''
    },
    // 获取省市区
    async getAddressData(params) {
      const res = await apiConfig.getAddress(params)
      // console.log(res);
      return res.data
    },
    handleClose() {
      this.$emit('onClose', false)
    },
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('changeValue', this.formOption)
          this.$emit('onSave')
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-form-item--small.el-form-item {
  // margin-bottom: 8px;
}
.select_item {
  margin-bottom: 20px;
}
</style>
