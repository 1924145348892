<template>
  <div class="common-select">
    <el-select
      v-show="!isTextDisplay"
      ref="elSelectRef"
      v-model="inputValue"
      :placeholder="placeholderText"
      :loading="loading"
      :remote-method="onRemoteMethod"
      :disabled="isDisabled"
      v-bind="$attrs"
      v-on="$listeners"
      @change="onChange"
      @visible-change="onVisibleChange">
      <el-option
        v-for="(item, index) in valueKeyWithIndex(factoryOptionList)"
        :key="item._renderKey || initKey(item, valueKey)"
        :label="initKey(item, labelKey)"
        :value="initKey(item, valueKey)"
        :disabled="disabledKey ? !!initKey(item, disabledKey) : false">
        <slot
          :scope="{
            row: item,
            $index: index,
            selected: itemSelected(initKey(item, valueKey))
          }" />
      </el-option>
      <el-option
        v-if="ifMore"
        v-loading="loadMoreLoading"
        disabled
        :class="{ 'on-more': ifMore }"
        value="加载更多"
        element-loading-spinner="el-icon-loading"
        @click.native="onLoadMore" />
    </el-select>
    <div v-if="isTextDisplay" class="text-display">
      {{
        getSelectedLabelString(selectedItem) || (value?.length == 0 ? '' : value)
      }}
    </div>
  </div>
</template>

<script>
import { timingSequenceStep } from '@/utils/index'
import { debounce } from 'throttle-debounce'

import { valueEquals, isEqual } from 'element-ui/src/utils/util'
import { mapGetters } from 'vuex'

export default {
  name: 'CommonSelect',
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  inject: {
    bpmFlow: {
      default: undefined
    },
    oaFlow: {
      default: undefined
    }
  },
  props: {
    value: {
      type: [String, Number, Array, Boolean],
      default: ''
    },
    // 以文本形式展示
    textDisplay: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: null
    },
    disabledPrior: {
      type: Boolean,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    // 下拉列表, dataList 或 getDataList为必填项
    dataList: {
      type: Array,
      default: () => []
    },
    // 请求下拉列表,通过该函数获取下拉列表,可异步
    getDataList: {
      type: Function,
      default: null
    },
    // 请求下一页, 已经废弃，只做兼容处理，不建议使用，合并到getDataList
    // getNextList: {
    //   type: Function,
    //   default: null
    // },
    // 请求下拉列表参数, 更新时触发getDataList
    getDataListParams: undefined,
    /**
     * 触发请求事件
     * create: 组件创建时
     * change: value 有变化时
     * show: 展示下拉框时
     * */
    getDataTrigger: {
      type: Array,
      default: () => ['create', 'change'] // show
    },
    // 筛选选择器列表项
    filterDataList: {
      type: Function,
      default: null
    },
    // 总条数, 用来判断是否存在下一页, 不传则在请求结果为空数组时停止
    totalCount: {
      type: [Number, null],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    labelKey: {
      type: [Function, String],
      default: ''
    },
    valueKey: {
      type: [Function, String],
      default: ''
    },
    disabledKey: {
      type: [Function, String],
      default: ''
    },
    // 将传入的值自定义转换, 如将字符串转换为数值类型 :value-translate="Number"
    valueTranslate: {
      type: Function,
      default: null
    },
    // 输出值转换
    valueOutputTranslate: {
      type: Function,
      default: null
    },
    // 列表为空是禁用选择器
    emptyDisabled: {
      type: Boolean,
      defualt: false
    },
    // 列表值最大查询次数
    maxFindLoopCount: {
      type: Number,
      default: 1
    },
    // 没有匹配值时尝试在其它值内搜索,并重新赋值,使用场景:以往要求存储id,后面要求更换为其它值时使用.
    oldValueKeys: {
      type: Array,
      default: null
    },
    requestConfig: {
      type: Object,
      default: () => ({
        list: 'data',
        total: 'totalCount'
      })
    },
    // 隐藏相同value的选项
    hiddenEqualValueItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // 初始化无需在html绑定的字段
    const dataList = JSON.parse(JSON.stringify(this.dataList))
    this.optionListCache = dataList // 缓存数据
    this.searchText = '' // 搜索内容
    this.page = 1
    this.firstLoader = true // 第一次加载

    // 循环查找列表不存在值的次数(解决调用查找值接口后,模糊匹配的数据与value值不全等.导致循环调用多次,若使用了缓存将导致应用卡死)
    this.LOOP_FIND_COUNT = 0

    return {
      inputValue: this.value,
      optionList: dataList, // 显示的选项
      selectedItem: undefined,
      loading: false,
      loadMoreLoading: false,
      interTotalCount: null
    }
  },
  computed: {
    ...mapGetters('dealershipTransfer', ['isOA']),
    isTextDisplay({ textDisplay, isDisabled, isOA }) {
      return (isDisabled && isOA) || textDisplay
    },
    isDisabled({
      disabled,
      disabledPrior,
      emptyDisabled,
      bpmFlow,
      oaFlow,
      isOA,
      optionList
    }) {
      if (disabledPrior !== null) {
        return disabledPrior
      }
      if (!bpmFlow && isOA) {
        return true
      }
      return (
        oaFlow?.disabled ||
        bpmFlow?.disabled ||
        disabled ||
        (emptyDisabled && !optionList.length)
      )
    },
    // 筛选后的选择器列表,筛除重复value项
    factoryOptionList({ optionList, filterDataList, hiddenEqualValueItem }) {
      const removeEqualValueOptionsList = []
      const renderKeyList = []
      if (hiddenEqualValueItem) {
        optionList.forEach(item => {
          const isObject =
            Object.prototype.toString.call(item) === '[object Object]'
          const key = isObject ? this.initKey(item, this.valueKey) : item
          const isAlreadyExist = renderKeyList.some(
            _key => String(_key) === String(key)
          )
          // 保存未重复的key
          if (!isAlreadyExist) {
            renderKeyList.push(key)
            removeEqualValueOptionsList.push(item)
          }
        })
      }
      const realWithOptionsList = hiddenEqualValueItem
        ? removeEqualValueOptionsList
        : optionList
      if (filterDataList) {
        return filterDataList(realWithOptionsList)
      }

      return realWithOptionsList
    },
    placeholderText({ placeholder }) {
      return placeholder === null ? `请选择${this.label}` : placeholder
    },
    ifMore({ totalCount, interTotalCount, optionList }) {
      if (totalCount !== null) {
        return totalCount > optionList.length
      } else if (interTotalCount !== null) {
        return interTotalCount > optionList.length
      } else {
        return false
      }
    },
    getNextList({ $attrs, getDataList }) {
      const getNextList = $attrs.getNextList || $attrs['get-next-list']
      if (!getNextList) {
        return getDataList
      }
      return getNextList
    }
  },
  watch: {
    dataList(list) {
      if (!isEqual(list, this.optionList)) {
        this.optionList = JSON.parse(JSON.stringify(list))
      }
    },
    value: {
      immediate: true,
      handler(value) {
        // 转换值
        if (this.valueTranslate) {
          value = this.valueTranslate(value)
        }
        if (
          this.valueOutputTranslate
            ? value !== this.valueOutputTranslate(this.inputValue)
            : value !== this.inputValue
        ) {
          this.inputValue = value
        }
        // 值发生变化重置查询
        this.LOOP_FIND_COUNT = 0
      }
    },
    inputValue: {
      immediate: true,
      async handler(value, oldValue) {
        // 检查字符串or数组是否相等
        if (!valueEquals(value, oldValue)) {
          this.changeValue(value)
        }
        // 首次加载执行,不放在created里的原因是,immediate执行比created早
        if (this.firstLoader) {
          this.firstLoader = false
          this.getList('create')
        }
        this.onSelectedItem()
      }
    },
    getDataListParams() {
      this.getList('change')
    },
    selectedItem: {
      immediate: true,
      handler(selectedItem, oldSelectedItem) {
        // 松散相等/数组/字符串/对象
        if (!isEqual(selectedItem, oldSelectedItem)) {
          this.$emit('changeSelectedItem', selectedItem)
        }
      }
    },
    optionList(list) {
      this.optionListCache = this.uniqueMerge(this.optionListCache, list)
      this.onSelectedItem()
    },
    isDisabled: {
      immediate: true,
      handler(bool) {
        this.$emit('changeDisabled', bool)
      }
    }
  },
  mounted() {
    // 滚动到底请求下一页
    this.$nextTick(() => {
      if (!this.$refs.elSelectRef) return
      this.SELECTWRAP = this.$refs.elSelectRef.$el.querySelector(
        '.el-select-dropdown .el-select-dropdown__wrap'
      )
      this.SELECTWRAP.addEventListener('scroll', this.onScroll)
    })
  },
  destroyed() {
    if (this.SELECTWRAP) {
      this.SELECTWRAP.removeEventListener('scroll', this.onScroll)
    }
  },
  methods: {
    itemSelected(value) {
      if (Array.isArray(this.inputValue)) {
        return this.inputValue.includes(value)
      } else {
        return this.inputValue === value
      }
    },
    // 重复valueKey拼接index
    valueKeyWithIndex(list) {
      const renderKeyList = []
      return list.map((item, index) => {
        if (Object.prototype.toString.call(item) !== '[object Object]') {
          return item
        }
        // 重复的key拼接index
        let key = this.initKey(item, this.valueKey)
        const isAlreadyExist = renderKeyList.some(
          item => String(item) === String(key)
        )
        if (isAlreadyExist) {
          key = `${key}_${index}`
        }
        renderKeyList.push(key)
        return {
          _renderKey: key,
          ...item
        }
      })
    },
    getSelectedLabelString(selectedItem) {
      switch (Object.prototype.toString.call(selectedItem)) {
        case '[object Object]':
          return this.initKey(selectedItem, this.labelKey)
        case '[object Array]':
          return selectedItem
            .map(item => this.initKey(item, this.labelKey))
            .join(',')
        default:
          return ''
      }
    },
    changeValue(value) {
      this.$emit(
        'changeValue',
        this.valueOutputTranslate ? this.valueOutputTranslate(value) : value
      )
    },
    uniqueMerge(originList, pushList) {
      const templist = originList.concat(pushList)
      const result = []
      const obj = {}

      for (const item of templist) {
        const key = this.initKey(item, this.valueKey)
        if (!obj[key]) {
          result.push(item)
          obj[key] = 1
        }
      }
      return result
    },
    onChange(...rest) {
      this.$nextTick(() => {
        this.$emit('onChangeItem', this.selectedItem)
      })
    },
    // 显示列表时触发
    onVisibleChange(flag) {
      if (flag) {
        this.getList('show')
      } else {
        // 触发关闭选项的时候重新获取列表
        if (this.searchText) {
          this.searchText = ''
          this.getList('remote')
        }
      }
    },
    // 远程获取
    onRemoteMethod: debounce(100, async function(searchText) {
      this.searchText = searchText
      this.getList('remote')
    }),
    // 获取选中的对象/字符串
    onSelectedItem() {
      // 非对象列表
      if (!this.valueKey) return (this.selectedItem = this.inputValue)
      // this.getList 正在执行中
      if (this.loading) {
        return null
      }
      let selectedItem
      // 选中值为数组表示多选,否则单选
      const isMultiple =
        Object.prototype.toString.call(this.inputValue) === '[object Array]'
      // 值本身为空
      const isEmptyValue = isMultiple
        ? !this.inputValue.length
        : ['', undefined, null].includes(this.inputValue)
      if (isEmptyValue) {
        return (this.selectedItem = undefined)
      }
      if (isMultiple) {
        // 筛选多选对象
        selectedItem = this.optionListCache.filter(item => {
          return this.inputValue.includes(this.initKey(item, this.valueKey))
        })
      } else {
        // 获取单选对象
        selectedItem = this.optionListCache.find((item, index) => {
          return this.initKey(item, this.valueKey) === this.inputValue
        })
      }

      // 找不到该选项(单选或多选)
      let isNotFount = false
      if (Array.isArray(this.inputValue)) {
        // 多选找不到
        isNotFount = this.inputValue.length && !selectedItem.length
        // 在对象其它选项查找
        if (isNotFount) {
          const findItems = this.replaceMultiOldValue()
          if (findItems) return findItems
        }
      } else {
        // 单选找不到
        isNotFount = [undefined, null].includes(selectedItem)
        if (isNotFount) {
          const findItem = this.replaceSingleOldValue()
          if (findItem) return findItem
        }
      }

      // 找不到值
      if (isNotFount) {
        if (this.LOOP_FIND_COUNT >= this.maxFindLoopCount) {
          // 中断循环
          this.LOOP_FIND_COUNT = 0
          return
        }
        this.LOOP_FIND_COUNT += 1
        this.$emit(
          'onOptionsListNotFound',
          {
            value: this.inputValue,
            getDataListParams: this.getDataListParams
          },
          response => {
            const list = this.resultFormat(response)
            if (Array.isArray(list)) {
              if (!list.length) {
                return console.log('返回数据为空,找不到数值')
              }
              const filterList = list.filter(item => {
                const itemValue = this.initKey(item, this.valueKey)
                if (isMultiple) {
                  return this.inputValue.includes(itemValue)
                } else {
                  return itemValue === this.inputValue
                }
              })
              if (!filterList.length) {
                return console.log('返回数据找不到该项')
              }
              // 额外输入数据,不应该影响分页
              this.optionList = this.uniqueMerge(this.optionList, filterList)
            } else {
              console.info(
                `请回调数组或者含'${this.requestConfig.list}'字段对象`
              )
            }
          }
        )
      } else {
        this.selectedItem = selectedItem
      }

      return this.selectedItem
    },
    async getList(type) {
      this.loading = true
      if (
        Object.prototype.toString.call(this.getDataList) === '[object Function]'
      ) {
        if (type === 'remote' || this.getDataTrigger.includes(type)) {
          try {
            this.page = 1
            const list = await this.getListReuqest()
            this.optionList = list
          } catch (error) {
            if (error === 'cancel promise') return
            console.error(error)
          }
        }
      }
      this.loading = false
      return this.optionList
    },
    // 处理返回值
    resultFormat(res) {
      if (Array.isArray(res)) {
        return res
      }

      if (Array.isArray(res[this.requestConfig.list])) {
        if (res[this.requestConfig.total] !== undefined) {
          this.interTotalCount = res[this.requestConfig.total]
        }
        return res[this.requestConfig.list]
      }
    },
    // 处理时序
    getListReuqest: timingSequenceStep(async function() {
      const res = await this.getDataList({
        page: 1,
        searchText: this.searchText,
        getDataListParams: this.getDataListParams
      })
      return this.resultFormat(res)
    }),
    initKey(item, value) {
      if (Object.prototype.toString.call(value) === '[object Function]') {
        return value(item)
      }
      return value ? item[value] : item
    },
    replaceMultiOldValue() {
      if (this.oldValueKeys && this.oldValueKeys.length) {
        const findValue = []
        for (const inputItem of this.inputValue) {
          for (const key of this.oldValueKeys) {
            const findItem = this.optionListCache.find(
              value => value[key] === inputItem
            )
            if (findItem) {
              const value = this.initKey(findItem, this.valueKey)
              findValue.push(value)
              break
            }
          }
        }
        if (findValue.length) {
          // this.changeValue(findValue)
          this.inputValue = findValue
          if (findValue.length === this.inputValue.length) {
            return findValue
          }
        }
      }
      return false
    },
    replaceSingleOldValue() {
      // 在对象其它选项查找
      if (this.oldValueKeys && this.oldValueKeys.length) {
        for (const key of this.oldValueKeys) {
          const findItem = this.optionListCache.find(
            value => value[key] === this.inputValue
          )
          if (findItem) {
            const value = this.initKey(findItem, this.valueKey)
            this.inputValue = value
            return findItem
          }
        }
      }
    },
    async onLoadMore() {
      try {
        this.loadMoreLoading = true
        if (this.getNextList) {
          const list = this.resultFormat(
            await this.getNextList({
              page: ++this.page,
              searchText: this.searchText,
              getDataListParams: this.getDataListParams
            })
          )
          this.optionList = this.uniqueMerge(this.optionList, list)
          if (!list.length) {
            this.interTotalCount = this.optionList.length
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadMoreLoading = false
      }
    },
    onScroll({ target }) {
      const CONDITION =
        target.scrollHeight - target.scrollTop <= target.clientHeight
      if (CONDITION && this.ifMore) {
        this.onLoadMore()
      }
    }
  }
}
</script>

<style lang="scss">
.el-form--label-top {
  .common-select > .el-select {
    display: block;
  }
}
.el-select-dropdown__item.on-more.is-disabled {
  cursor: pointer;
}
.el-table--small {
  .text-display {
    font-size: 12px;
  }
}
.common-select {
  .text-display {
    line-height: 32px;
    padding-left: 15px;
    min-height: 32px;
  }
}
.oa-select {
  .el-input.is-disabled {
    .el-input__inner {
      background-color: transparent;
      border-color: transparent;
      border-bottom-color: rgba(0, 0, 0, 0.06);
      color: #333;
      cursor: default;
      padding-left: 0;
    }
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
