import http from '@/utils/request'

// 慕思经典门店设计审批
export const submitDesignApproveData = data => {
  return http({
    url: '/dealermanage/bpm/storeDesign/submitApproveData',
    data,
    method: 'POST'
  })
}
// 保存慕思经典门店设计草稿
export const saveDesignDraft = data => {
  return http({
    url: '/dealermanage/bpm/storeDesign/saveDraft',
    data,
    method: 'POST'
  })
}
// 提交慕思经典门店设计流程
export const submitDesignFlow = data => {
  return http({
    url: '/dealermanage/bpm/storeDesign/conmitDraft',
    data,
    method: 'POST'
  })
}
// 获取门店设计详情
export const getDesignFlowDetail = params => {
  return http({
    url: '/dealermanage/bpm/storeDesign/details',
    params
  })
}
// 获取关联流程列表
export const getDesignLinkFlowList = params => {
  return http({
    url: '/dealermanage/declarationform/listpage',
    params
  })
}

// 获取门店设计管理流程详情
export const getDesignLinkFlowItem = params => {
  return http({
    url: '/dealermanage/shopManageFlow/getStoreDesign',
    params
  })
}
// 获取门店整改流程详情
export const getRectifyFlowItem = params => {
  return http({
    url: '/dealermanage/bpm/classicStoreRectification/details',
    params
  })
}
// 门店整审批信息提交
export const submitRectifyApproveData = data => {
  return http({
    url: '/dealermanage/bpm/classicStoreRectification/submitApproveData',
    data,
    method: 'POST'
  })
}
// 门店整流程提交
export const submitRectifyFlow = data => {
  return http({
    url: '/dealermanage/bpm/classicStoreRectification/conmitDraft',
    data,
    method: 'POST'
  })
}
// 店装物料下单-保存草稿
export const saveStoreLoadingMaterialOrder = data => {
  return http({
    url: '/dealermanage/bpm/storeLoadingMaterialOrder/saveDraft',
    data,
    method: 'POST'
  })
}
// 店装物料下单-提交报单
export const submitStoreLoadingMaterialOrder = data => {
  return http({
    url: '/dealermanage/bpm/storeLoadingMaterialOrder/conmitDraft',
    data,
    method: 'POST'
  })
}
// 店装物料下单-中间节点审批
export const submitStoreLoadingMaterialOrderApproveData = data => {
  return http({
    url: '/dealermanage/bpm/storeLoadingMaterialOrder/submitApproveData',
    data,
    method: 'POST'
  })
}
// 店装物料下单-报单详情
export const getStoreLoadingMaterialOrderDetail = params => {
  return http({
    url: '/dealermanage/bpm/storeLoadingMaterialOrder/details',
    params
  })
}
// 门店装修-保存草稿
export const saveStoreDecoration = data => {
  return http({
    url: '/dealermanage/bpm/storeDecoration/saveDraft',
    data,
    method: 'POST'
  })
}
// 门店装修-提交报单
export const submitStoreDecoration = data => {
  return http({
    url: '/dealermanage/bpm/storeDecoration/conmitDraft',
    data,
    method: 'POST'
  })
}
// 门店装修-中间节点审批
export const submitStoreDecorationApproveData = data => {
  return http({
    url: '/dealermanage/bpm/storeDecoration/submitApproveData',
    data,
    method: 'POST'
  })
}
// 门店装修-报单详情
export const getStoreDecorationDetail = params => {
  return http({
    url: '/dealermanage/bpm/storeDecoration/details',
    params
  })
}
// 门店装修-经典门店设计流程触发店装流程对象
export const getStoreDecorationParam = params => {
  return http({
    url: '/dealermanage/bpm/storeDecoration/getStoreDecorationParam',
    params
  })
}

// 慕思经典门店状态调整审批
export const submitStoreStatusApproveData = data => {
  return http({
    url: '/dealermanage/bpm/storeStatus/submitApproveData',
    data,
    method: 'POST'
  })
}
// 保存慕思经典门店状态草稿
export const saveStoreStatusDraft = data => {
  return http({
    url: '/dealermanage/bpm/storeStatus/saveDraft',
    data,
    method: 'POST'
  })
}
// 提交慕思经典门店状态流程
export const submitStoreStatusFlow = data => {
  return http({
    url: '/dealermanage/bpm/storeStatus/conmitDraft',
    data,
    method: 'POST'
  })
}
// 获取门店状态详情
export const getStoreStatusFlowDetail = params => {
  return http({
    url: '/dealermanage/bpm/storeStatus/details',
    params
  })
}
// 获取门店状态关联流程详情
export const getStoreStatusLinkFlowDetail = params => {
  return http({
    url: '/dealermanage//bpm/storeStatus/getStoreStatus',
    params
  })
}
