<template>
    <div>
        <div>
            <el-button style="margin-bottom:10px" @click="onPreviewTemplate">预览模版</el-button>
            <el-button style="margin-bottom:10px" @click="onDownloadTemplate">下载模版</el-button>
        </div>
        <div ref="docxTemplate" class="docxTemplate" >
           <slot></slot>
        </div>
        <ImageViewer
        v-if="showViewer"
        :on-close="() => (showViewer = false)"
        :url-list="[imageUrl]"
        /> 
    </div>
</template>
<script setup>
import html2canvas from "html2canvas"
import ImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
import {ref} from 'vue'
const props = defineProps({
    isPermission:{
        type:Boolean,
        default: false
    }
})
const emit = defineEmits(['showTip'])
const docxTemplate = ref()
const imageUrl = ref('')
const showViewer = ref(false)
const onPreviewTemplate = ()=>{
    if(!props.isPermission){
        emit('showTip')
        return
    }
    const dom = docxTemplate.value
    html2canvas(dom,{radio:3}).then(canvas => {
       const url = canvas.toDataURL('image/png')
       imageUrl.value = url
       showViewer.value = true
    })
   
}
const onDownloadTemplate = ()=>{
    if(!props.isPermission){
        emit('showTip')
        return
    }
    const dom = docxTemplate.value
    html2canvas(dom,{radio:3,scale:4}).then(canvas => {
        const url = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        const href = url
        const title = '经销商主动放弃经销权申请书.png'
        a.setAttribute('href', href)
        a.setAttribute('download', title)
        a.click()
        a.remove()
    })
}
</script>
<style scoped lang="scss">
.docxTemplate{
    width:592px;
    height:842px;
    margin:0 auto;
    padding:0 20px;      
    font-size: 16px;
    line-height: 2;
    word-break: break-all;
    position:fixed;
    z-index:-10;
}
</style>