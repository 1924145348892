var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-container"},[_c(_setup.TablePro,{ref:"tableProRef",attrs:{"columns":_setup.columnsTable,"request":_setup.getTableList},scopedSlots:_vm._u([{key:"actionArea",fn:function(){return [_c(_setup.PopupForm,{ref:"popupFormRef",attrs:{"popup-type":"dialog","columns":_setup.columnsForm,"on-submit":_setup.onSubmit,"label-position":"left","destroy-slot-on-close":"","width":"50%"},scopedSlots:_vm._u([{key:"trigger",fn:function({ onShow }){return [_c('el-button',{directives:[{name:"loading-click",rawName:"v-loading-click",value:(
              () =>
                onShow(
                  {
                    // renderFormItem el-switch组件预设传值
                    brandStatus: 0,
                    productNumberStatus: 0,
                    productModelStatus: 0,
                    phontoStatus: 0,
                    enable: 0
                  },
                  () => {},
                  { title: '新增' }
                )
            ),expression:"\n              () =>\n                onShow(\n                  {\n                    // renderFormItem el-switch组件预设传值\n                    brandStatus: 0,\n                    productNumberStatus: 0,\n                    productModelStatus: 0,\n                    phontoStatus: 0,\n                    enable: 0\n                  },\n                  () => {},\n                  { title: '新增' }\n                )\n            "}],attrs:{"icon":"el-icon-plus","type":"primary"}},[_vm._v(" 新增 ")])]}}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }