import data from '@/store/modules/data'
import request from '@/utils/request'
import { Message } from 'element-ui'

// 保存老经销商新增门店
export function saveDraftData(data) {
  return request({
    url: '/dealermanage/shop/saveOrUpdate/draft',
    method: 'post',
    data
  })
}

// 获取老经销商门店新增详情
export function getDraftDetial(params) {
  return request({
    url: '/dealermanage/declarationform/details',
    method: 'get',
    params
  })
}
// 获取老经销商新系列新增
export function saveShopbrand(data) {
  return request({
    url: '/dealermanage/deliveryparty/saveOrUpdate/draft',
    method: 'post',
    data
  })
}

// 获取结构数据列表
export function shopFLowData(params) {
  return request({
    url: '/dealermanage/shop/detailsList',
    method: 'get',
    params
  })
}

// 识别营业执照
export function ocrBizlicense(params) {
  return request({
    url: '/consumer-admin/api/wx/ocr/bizlicense',
    method: 'post',
    params
  }).then(res => {
    const { data = {} } = res
    Message.success('您上传的营业执照识别成功，请仔细检查营业执照图片与填充的内容是否一致。')
    return {
      ...res,
      data: {
        ...data,
        // 英文括号替换成中文括号
        enterprise_name: data?.enterprise_name?.replace(/[()]/g, match =>
          match === '(' ? '（' : '）'
        )
      }
    }
  })
}
// 获取我的申请列表
export function getAppleList(params) {
  return request({
    url: '/dealermanage/declarationform/sonFormModelpage',
    params
  })
}
