/*
 * @Author: wangmq
 * @Date: 2023-04-19 08:56:05
 * @LastEditors: wangmq
 * @LastEditTime: 2023-06-20 12:12:59
 */
import http from "@/utils/request";

// 提交保存流程
export const requestSaveFlow = async(data) => {
  const res = await http({
    url: '/dealermanage/shop/pushDraft',
    data,
    method: 'post'
  })
  return res
}

/**
 * @description: 获取点将师详情
 * @param {string} account
 * @see http://10.12.0.64/docs/operate/operate-1d6c0g9rdd9bq
 */

export const requestGetDianjiangDetail = async(account) => {  
  const res = await http({
    url: `/consumermanage/stylist/works`,
    method: 'get',
    params: {
      account
    }
  })
  return res
}
/**
 * @description: 获取案例列表
 * @param {string} params.stylistId
 * @see http://10.12.0.64/docs/operate/operate-1d6ckj96u4pqb
 */

export const requestVMDList = async(params) => {  
  const res = await http({
    url: `/consumermanage/production/listpage`,
    method: 'get',
    params
  })
  return res
}
/**
 * @description: 获取案例详情
 * @param {string} id
 * @see http://10.12.0.64/docs/operate/operate-1d6ckj96u4pqb
 */

export const requestVMDdetail = async(id) => {  
  const res = await http({
    url: `/consumermanage/production/info/${id}`,
    method: 'get'
  })
  return res
}

/**
 * @description: 点将老师列表
 * @param {*} params
 */
export const requestGetDianjiangList = (params) => {
  return http({
    url: '/consumermanage/stylist/listpage',
    method: 'get',
    params: {
      ...params,
      ifEnable: 1
    }
  })
}