<template>
  <div>
    <h2 class="header-title">经销商数据录入</h2>
    <ul class="entry-list">
      <li
        v-for="entryItem of entryList"
        :key="entryItem.type"
        v-permission="[entryItem.perm]"
        class="entry-item"
      >
        <el-button class="btn" type="primary" @click="goToFlow(entryItem.type)">
          {{ entryItem.title }}
        </el-button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entryList: [
        {
          title: '海外售达方数据录入',
          type: 'businessFlow',
          perm: 'overseas_data_apply_001'
        },
        {
          title: '海外送达方录入',
          type: 'deliverypartyFlow',
          perm: 'overseas_data_apply_002'
        },
        {
          title: '海外门店录入',
          type: 'shopFlow',
          perm: 'overseas_data_apply_003'
        }
      ]
    }
  },
  methods: {
    goToFlow(type) {
      if (!type) return
      this.$router.push({ path: `/overseasData/${type}` })
    }
  }
}
</script>

<style scoped lang="scss">
.entry-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  & > li {
    margin: 10px;
  }
}
.header-title {
  margin-top: 20px;
  padding-left: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 760px) {
  .entry-list li {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}
</style>
