<template>
  <div class="role">
    <div class="role-action">
      <el-form label-width="80px" :inline="true">
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="handleEdit({}, true)"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-table
      border
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      :data="state.userList"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      v-loading="state.loading">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="systemCode" label="系统平台" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.systemCode == 'distributor'" type="info"
            >渠道运营管理系统</el-tag
          >
          <el-tag v-else type="danger">未知</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="associatedBusinessId"
        label="业务ID"
        align="center"></el-table-column>
      <el-table-column
        prop="fdFormTemplateName"
        label="表单名称"
        align="center">
      </el-table-column>
      <el-table-column
        prop="fdFormTemplateCode"
        label="表单编码"
        align="center">
      </el-table-column>
      <el-table-column prop="flowTemplateCode" label="模板编码" align="center">
      </el-table-column>
      <el-table-column prop="flowTemplateId" label="模板ID" align="center">
      </el-table-column>
      <el-table-column prop="createBy" label="创建人" align="center">
      </el-table-column>
      <el-table-column prop="updateBy" label="更新人" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center">
      </el-table-column>

      <el-table-column label="操作" width="200px" align="center">
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row, false)"
            >编辑</el-button
          >
          <el-button type="text" @click="handleRouteTo(scope.row)"
            >编辑字段</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        background
        :current-page="page.currPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>

    <el-dialog
      :title="state.isAdd ? '新增' : '修改'"
      :visible.sync="state.drawer"
      direction="rtl"
      size="30%;min-width:500px;">
      <div style="padding: 10px 20px">
        <el-form label-width="100px" :inline="false" :model="state.detail">
          <el-form-item label="流程名称">
            <el-select
              v-model="state.detail.associatedBusinessId"
              filterable
              placeholder="请选择">
              <el-option
                v-for="item in state.associatedBusinessIdOptions"
                :key="item.id"
                :label="item.content"
                :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="表单名称">
            <el-input
              v-model="state.detail.fdFormTemplateName"
              :disabled="state.isDetail"></el-input>
          </el-form-item>
          <el-form-item label="流程模板id" v-if="!state.isAdd">
            <el-input v-model="state.detail.flowTemplateId"></el-input>
          </el-form-item>
          <el-form-item label="模版编码" v-if="!state.isAdd">
            <el-input v-model="state.detail.flowTemplateCode"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="state.detail.remark"
              :disabled="state.isDetail"></el-input>
          </el-form-item>
          <el-form-item v-if="!state.isDetail">
            <el-button type="primary" @click="handleRoleSubmit">保存</el-button>
            <el-button @click="state.drawer = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { Message } from 'element-ui'
import { reactive } from 'vue'
import { resetPassword } from '@/api/system/user'
import {
  datadictionaryList,
  bpmtemplateListpage,
  bpmtemplateUpdate,
  bpmtemplateAdd
} from '@/api/bpmManage'
import { getStorage } from '@/utils/storage'
import { useRouter } from '@/utils/router'

const router = useRouter()

const systemCode = 'distributor'
const userInfo = getStorage('userInfo') || {}

const state = reactive({
  userList: [],
  selectId: [],
  drawer: false, //修改用户
  isDetail: false, //是否查看详情
  detail: {},
  deptOptions: [],
  associatedBusinessIdOptions: [],
  drawerRole: false, //角色弹窗
  associatedBusinessId: '',
  accountTypeList: [],
  loading: false,
  isAdd: true
})

const search = reactive({})

//分页数据
const page = reactive({
  currPage: 1,
  pageSize: 10,
  totalCount: 1
})

const init = async () => {
  getList()
  //获取角色列表
  const paramsRes = await datadictionaryList({ type: 'flow' })
  state.associatedBusinessIdOptions = paramsRes.data
}
init()

//表格选中的用户
const handleSelectionChange = val => {}

const handleRouteTo = row => {
  router.push({
    path: '/bpmManage/fieldList',
    query: {
      fdFormTemplateCode: row.fdFormTemplateCode,
      fdFormTemplateName: row.fdFormTemplateName
    }
  })
}

//查看、修改
const handleEdit = async (item, isAdd) => {
  state.isAdd = isAdd
  state.detail = item
  state.drawer = true
}

const handleSizeChange = pageSize => {
  if (page.totalCount <= pageSize) {
    page.currPage = 1
  }
  page.pageSize = pageSize
  getList(page.currPage, pageSize)
}
const handleCurrentChange = currPage => {
  page.currPage = currPage
  getList(currPage, page.pageSize)
}
async function getList(currPage = 1, pageSize = 10) {
  state.loading = true
  try {
    const { data, totalCount } = await bpmtemplateListpage({
      ...search,
      systemCode, //固定值
      currPage,
      pageSize
    })
    state.userList = data
    page.totalCount = totalCount
  } finally {
    state.loading = false
  }
}

const handleRoleSubmit = async () => {
  const {
    id,
    associatedBusinessId,
    fdFormTemplateName,
    remark,
    flowTemplateId,
    flowTemplateCode
  } = state.detail

  const submitApi = state.isAdd ? bpmtemplateAdd : bpmtemplateUpdate
  const params = {
    id,
    systemCode,
    associatedBusinessId,
    fdFormTemplateName,
    remark
  }
  if (state.isAdd) {
    params.createBy = userInfo?.realName
  } else {
    params.flowTemplateId = flowTemplateId
    params.flowTemplateCode = flowTemplateCode
    params.updateBy = userInfo?.realName
  }

  const { msg } = await submitApi(params)
  Message.success(msg)
  state.detail = {}
  state.drawer = false
  getList()
}
</script>

<style lang="scss" scoped>
.role {
  &-action {
    background-color: #fff;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .page {
    margin: 30px 0;
    padding: 20px 0;
    text-align: center;
    background-color: #fff;
  }
}
</style>
