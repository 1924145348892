import http from "@/utils/request";

//保存
export const saveFranchiseChange = data => http({
  url:'/dealermanage/franchisechange/save',
  method:'post',
  data
})
//获取详情
export const details = params => http({
  url:'/dealermanage/declarationform/details',
  method:'get',
  params
})