// import http from "@/utils/http";
import http, { download } from '@/utils/request'

// const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
// const { username = "", id = "" } = userInfo;

// 违规类型列表
export const apiGetViolationList = params => {
  return http.request({
    url: '/dealermanage/shopinspectviolateconfigurationinfo/listpage',
    method: 'get',
    params
  })
}

// 新增违规详情
export const apiAddViolation = data => {
  return http.request({
    url: '/dealermanage/shopinspectviolateconfigurationinfo/add',
    method: 'post',
    data
  })
}

// 编辑违规详情
export const apiUpdateViolation = data => {
  return http.request({
    url: '/dealermanage/shopinspectviolateconfigurationinfo/update',
    method: 'post',
    data
  })
}

// 删除违规详情
export const apiDeleteViolation = data => {
  return http.request({
    url: '/dealermanage/shopinspectviolateconfigurationinfo/delete',
    method: 'post',
    data
  })
}

// 巡检列表
export const apiGetInspectionList = params => {
  return http.request({
    url: '/dealermanage/shoppatrolinspectioninfo/listpage',
    method: 'get',
    params
  })
}
// 批量逻辑删除
export const apiBatchDeleteInspection = data => {
  return http.request({
    url: '/dealermanage/shoppatrolinspectioninfo/delete',
    method: 'post',
    data
  })
}
// 已提交改为草稿状态
export const apibatchRejected = data => {
  return http.request({
    url: '/dealermanage/shoppatrolinspectioninfo/batchRejected',
    method: 'post',
    data
  })
}
//违约次数
export const downloadCountJob = (params) => {
  return download('dealermanage/shoppatrolinspectioninfo/exportCountJob', params)
}
//违约金
export const downloadFineJob = (params) => {
  return download('dealermanage/shoppatrolinspectioninfo/exportMoneyJob', params)
}
//产品总清单报表导出
export const downloadProductTotalList = (params) => {
  return download('dealermanage/shoppatrolinspectioninfo/exportProductMasterList', params)
}
//违约产品导出
export const downloadViolationProduct = (params) => {
  return download('dealermanage/shoppatrolinspectioninfo/exportProduct', params)
}
//跨品牌违规销售报表导出
export const downloadCrossBrandSales = (params) => {
  return download('dealermanage/shoppatrolinspectioninfo/exportCrossBrand', params)
}
//违约照片导出
export const downloadViolationPhoto = (params) => {
  return download('dealermanage/shoppatrolinspectioninfo/exportPhoto', params)
}
//事业部列表
export const apiGetBusinessList = params => {
  return http.request({
    url: '/dealermanage/brandseries/list',
    method: 'get',
    params
  })
}

//整改通知单列表
export const apiGetNoticeList = data => {
  return http.request({
    url: '/file/rectificationfile/listpage',
    method: 'POST',
    data
  })
}
//导出整改通知单
export const downloadNotice = (params) => {
  return download('file/rectificationfile/exporttask', params)
}