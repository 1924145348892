<script setup>
import TablePro from '@/components/TablePro'
import { getClerkList } from './api/'

const tableProProps = {
  request: getClerkList,
  autoWidthCell: true,
  columns: [
    { title: '酒店ID', name: 'hotelId' },
    { title: '酒店名称', name: 'hotelName', search: true },
    { title: '员工姓名', name: 'userName' },
    { title: '员工手机号', name: 'userAccount' },
    {
      title: '员工角色',
      name: 'userAccountType',
      valueType: 'select',
      valueEnum: [
        { label: '前台', value: '2' },
        { label: '管理员', value: '3' }
      ]
    },
    {
      title: '员工状态',
      name: 'enable',
      search: true,
      valueType: 'select',
      valueEnum: [
        { label: '有效', value: 1 },
        { label: '无效', value: 0 }
      ]
    },
    { title: '更新时间', name: 'updateTime' },
    { title: '更新人', name: 'updateBy' }
  ]
}
</script>

<template>
  <TablePro v-bind="tableProProps" />
</template>
