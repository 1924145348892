<template>
  <div id="editor" ref="editorRef" v-loading="loading" />
</template>

<script>
import E from 'wangeditor'
import { uploadFile } from '@/utils/obsUpload'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      editor: null
    }
  },
  watch: {
    value(val) {
      if (val !== this.editor.txt.html()) {
        this.editor.txt.html(val)
      }
    }
  },
  mounted() {
    this.editor = new E(this.$refs.editorRef)
    this.editor.config.compatibleMode = () => false
    this.editor.config.onchangeTimeout = 500 // 修改为 500 ms
    this.editor.config.customUploadImg = this.insertFile
    this.editor.config.customUploadVideo = this.insertFile
    this.editor.config.zIndex = 1
    this.editor.config.uploadVideoAccept = ['mp4', 'MP4']
    this.editor.config.uploadVideoMaxSize = 2 * 1024 * 1024 * 1024 // 2G

    this.editor.config.customAlert = (s, t) => {
      switch (t) {
        case 'success':
          this.$message.success(s)
          break
        case 'info':
          this.$message.info(s)
          break
        case 'warning':
          this.$message.warning(s)
          break
        case 'error':
          this.$message.error(s)
          break
        default:
          this.$message.info(s)
          break
      }
    }
    this.editor.config.height = this.options.height || 500

    this.editor.config.excludeMenus = this.options.excludeMenus || ['emoticon']

    this.editor.create()
    this.editor.txt.html(this.value)
    this.editor.config.onblur = (newHtml) => {
      this.$emit('onGetEditorContent', newHtml) // 获取最新的 html 内容
    }
    this.editor.config.onchange = (newHtml) => {
      this.$emit('onGetEditorContent', newHtml) // 获取最新的 html 内容
    }
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  },
  methods: {
    getEditorData() {
      return this.editor.txt.html()
    },
    insertFile(resultFiles, insertImgFn) {
      const loading = this.$loading({
        lock: true,
        text: '上传中'
      })
      resultFiles.forEach(item => {
        uploadFile(item).then(data => {
          insertImgFn(data.url)
        }).finally(() => loading.close())
      })
    }
  }
}
</script>

<style>

</style>
