/*
 * @Author: your name
 * @Date: 2024-05-31 10:25:07
 * @LastEditTime: 2024-06-02 10:19:36
 * @LastEditors: Michaels-MacBook.local
 * @Description: In User Settings Edit
 * @FilePath: /zhaoshang/src/views/bpmFlow/api/terminalStoresFlow.js
 */

import http from '@/utils/request'

// 提交
export const flowSubmit = data => {
  return http({
    url: '/dealermanage/bpm/storeInspectionRectification/submit',
    data,
    method: 'POST'
  })
}
// 审批
export const submitApproveData = data => {
  return http({
    url: '/dealermanage/bpm/storeInspectionRectification/submitApproveData',
    data,
    method: 'POST'
  })
}
// 保存到草稿
export const saveDraft = data => {
  return http({
    url: '/dealermanage/bpm/storeInspectionRectification/saveDraft',
    data,
    method: 'POST'
  })
}

// 详情
export const flowDetail = params => {
  return http({
    url: '/dealermanage/bpm/storeInspectionRectification/details',
    params
  })
}
