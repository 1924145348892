export default [
  {
    name: 'menu',
    path: 'menu',
    component: () => import('@/views/system/menus.vue'),
    meta: { title: '菜单管理' }
  },
  {
    name: 'role',
    path: 'role',
    component: () => import('@/views/system/role.vue'),
    meta: { title: '角色管理' }
  },
  {
    name: 'user',
    path: 'user',
    component: () => import('@/views/system/user.vue'),
    meta: { title: '用户管理' }
  }
]
