<template>
  <div class="sap-report">
    <el-form :inline="true">
      <el-form-item>
        <el-input
          prefix-icon="el-icon-search"
          v-model="keyword"
          placeholder="请输入搜索关键词"
          clearable
        >
          <el-button slot="append" type="primary" @click="onSearch">
            查询
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>

    <el-table
      stripe
      v-loading="loading"
      element-loading-text="Loading"
      border
      :data="list"
      style="width: 100%"
      max-height="680"
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
    >
      <el-table-column prop="processCode" label="单号"> </el-table-column>
      <el-table-column prop="createTime" label="创建日期"> </el-table-column>
      <el-table-column prop="dataType" label="数据类型">
        <template v-slot="{ row }">
          <div>{{ dataTypeList[row.dataType] }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template v-slot="{ row }">
          <div>{{ statusList[row.status] }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="historicalData" label="详情" min-width="380">
      </el-table-column>
    </el-table>
    <div class="pagination-bar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes,jumper"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { getSapReport } from '@/api/watchData'

export default {
  name: 'sapReport',
  components: {

  },
  props: {

  },
  data () {
    return {
      loading: false,
      total: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      list: [],
      dataTypeList: {
        '10': '门店数据',
        '20': '送达方',
        '30': '售达方',
        '40': '送达方与售达方关系',
      },
      statusList: ['未同步', '已同步'],
      search: {},
      keyword: ''
    };
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList (params) {
      try {
        this.loading = true
        let { code, data, totalCount } = await getSapReport({ currPage: 1, pageSize: 10, keyword: this.keyword, ...params })
        this.loading = false
        if (code != 0) return
        console.log(data)
        this.list = data
        this.total = totalCount
      } catch (error) {
        this.loading = false
      }
    },
    // 页面切换
    currentChange (page) {
      this.currentPage = page
      this.getList({ currPage: page })
    },
    // 页数切换
    handleSizeChange (page) {
      this.currentPage = 1
      this.pageSize = page
      this.getList({ currPage: page })
    },
    onSearch (value) {
      this.currentPage = 1
      this.getList({ page: 1 })
    }
  },
};
</script>

<style scoped lang="scss">
.pagination-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  overflow-x: auto;
}
</style>
