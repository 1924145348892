export default [
  {
    path: 'businessList',
    name: 'BusinessList',
    component: () => import('@/views/businessOos/businessList'),
    meta: { title: '经销商列表', keepAlive: true }
  },
  {
    path: 'authorizedList',
    name: 'AuthorizedList',
    component: () => import('@/views/businessOos/authorizedList'),
    meta: { title: '售达方列表', keepAlive: true }
  },
  {
    path: 'masterList',
    name: 'MasterList',
    component: () => import('@/views/businessOos/masterList'),
    meta: { title: '送达方列表', keepAlive: true }
  },
  {
    path: 'shopList',
    name: 'ShopList',
    component: () => import('@/views/businessOos/shopList'),
    meta: { title: '门店列表', keepAlive: true }
  },
  {
    path: 'dealerList',
    name: 'DealerList',
    component: () => import('@/views/businessOos/dealerList'),
    meta: { title: '实控人列表', keepAlive: true }
  },
  {
    path: 'member-manager',
    name: 'MemberManager',
    component: () => import('@/views/businessOos/memberManager'),
    meta: { title: '人员数据授权' }
  },
  {
    path: 'storeTransfer',
    name: 'StoreTransfer',
    component: () => import('@/views/businessOos/storeTransfer'),
    meta: { title: '门店转让详情' }
  },
  {
    path: 'netList',
    name: 'NetList',
    component: () => import('@/views/businessOos/netList'),
    meta: { title: '网点列表' }
  }
]
