<template>
  <div class="page">
    <h2>测试流程</h2>
    <el-divider></el-divider>
    <div style="display: flex">
      <div style="flex: 1; margin-right: 20px">
        <el-card>
          <el-form class="form" ref="form" :model="kmBpmformMainForm">
            <el-form-item label="姓名">
              <el-input
                :disabled="authObj['teacherInfos.applyName']"
                v-model="kmBpmformMainForm.applyName"
                placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item label="老师">
              <el-input
                :disabled="authObj['teacherInfos.name']"
                v-model="kmBpmformMainForm.name"
                placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item label="金额">
              <el-input
                :disabled="authObj['teacherInfos.amount']"
                v-model="kmBpmformMainForm.amount"
                placeholder="请输入金额" />
            </el-form-item>

            <el-form-item
              v-if="!query.businessId || detailData.applyStatus === '0'">
              <el-button type="primary" @click="onSubmit">{{
                detailData.applyStatus === '0' ? '重新发起' : '发起流程'
              }}</el-button>
            </el-form-item>
          </el-form>
        </el-card>
        <!-- 底部 -->
        <div v-if="query.businessId" style="margin-top: 20px">
          <el-card type="card">
            <el-tabs>
              <el-tab-pane label="审批记录">
                <iframe
                  id="audit-notes"
                  style="border: none"
                  width="100%"
                  height="1000"
                  :src="`https://testbpm.derucci.com/web/sys-lbpm/desktop/#/lbpmIntegrate/integrate/auditNotes?fdProcessId=${query.processId}`">
                </iframe>
              </el-tab-pane>
              <el-tab-pane :lazy="true" label="流程状态">
                <iframe
                  id="audit-notes"
                  style="border: none"
                  width="100%"
                  height="1000"
                  :src="`https://testbpm.derucci.com/web/sys-lbpm/desktop/#/lbpmIntegrate/integrate/processStatus?fdProcessId=${query.processId}`"></iframe>
              </el-tab-pane>
              <el-tab-pane :lazy="true" label="流程图">
                <iframe
                  id="flow-chart"
                  style="border: none"
                  width="100%"
                  height="1000"
                  :src="`https://testbpm.derucci.com/web/sys-lbpm/desktop/#/lbpmIntegrate/integrate/auditChart?fdProcessId=${query.processId}`"></iframe>
              </el-tab-pane>
              <el-tab-pane :lazy="true" label="操作日志">
                <iframe
                  id="audit-logs"
                  style="border: none"
                  width="100%"
                  height="1000"
                  :src="`https://testbpm.derucci.com/web/sys-lbpm/desktop/#/lbpmIntegrate/integrate/auditLogs?fdProcessId=${query.processId}`">
                </iframe>
              </el-tab-pane>
              <el-tab-pane :lazy="true" label="基本信息">
                <iframe
                  id="audit-basicInfo"
                  style="border: none"
                  width="100%"
                  height="1000"
                  :src="`https://testbpm.derucci.com/web/sys-lbpm/desktop/#/lbpmIntegrate/integrate/basicInfo?fdProcessId=${query.processId}`">
                </iframe>
              </el-tab-pane>
              <el-tab-pane :lazy="true" label="传阅记录">
                <iframe
                  id="audit-circulated"
                  style="border: none"
                  width="100%"
                  height="1000"
                  :src="`https://testbpm.derucci.com/web/sys-lbpm/desktop/#/lbpmIntegrate/integrate/auditcirculated?fdProcessId=${query.processId}`">
                </iframe>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </div>
    </div>

    <br />
    <br />
    <br />
    <!-- 右侧 -->
    <div class="menu" v-if="query.businessId">
      <el-card>
        <iframe
          id="flow-chart"
          style="border: none"
          width="100%"
          height="1200"
          :src="`https://testbpm.derucci.com/web/sys-lbpm/desktop/#/lbpmIntegrate/integrate/auditForm?fdProcessId=${query.processId}&fdLoginName=${userInfo.code}&approveLayout=bottomEmb&submitBtnPosition=card`">
        </iframe>
      </el-card>
    </div>

    <!-- 移动端 -->

    <div style="margin-top: 50px; color: #000" v-if="query.businessId">
      移动端审批测试
      <div style="width: 390px; height: 844px; background-color: #e6e6e6">
        <!-- 基础信息组件（可选） -->
        <div id="baseInfoWrapper">
          <iframe
            frameBorder="0"
            width="100%"
            height="100%"
            :src="`https://testbpm.derucci.com/web/sys-lbpm/mobile/#/lbpmIntegrate/integrate/baseInfo?fdProcessId=${query.processId}&maxHeight=65vh`">
          </iframe>
        </div>
        <div>
          <el-card>
            <el-form class="form" ref="form" :model="kmBpmformMainForm">
              <el-form-item label="姓名">
                <el-input
                  :disabled="authObj['teacherInfos.applyName']"
                  v-model="kmBpmformMainForm.applyName"
                  placeholder="请输入姓名" />
              </el-form-item>
              <el-form-item label="老师">
                <el-input
                  :disabled="authObj['teacherInfos.name']"
                  v-model="kmBpmformMainForm.name"
                  placeholder="请输入姓名" />
              </el-form-item>
              <el-form-item label="金额">
                <el-input
                  :disabled="authObj['teacherInfos.amount']"
                  v-model="kmBpmformMainForm.amount"
                  placeholder="请输入金额" />
              </el-form-item>
            </el-form>
          </el-card>
        </div>

        <!-- 审批纪录时间轴 -->
        <div id="auditNotesWrapper">
          <iframe
            id="auditNotes"
            frameBorder="0"
            width="100%"
            height="100%"
            :src="`https://testbpm.derucci.com/web/sys-lbpm/mobile/#/lbpmIntegrate/integrate/auditNotes?fdProcessId=${query.processId}`">
          </iframe>
        </div>
        <div>
          <iframe
            frameBorder="0"
            width="100%"
            height="100%"
            :src="`https://testbpm.derucci.com/web/sys-lbpm/mobile/#/lbpmIntegrate/integrate/auditForm?fdProcessId=${query.processId}`">
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue'
import {
  dealerFlowTestApiSubmit,
  dealerFlowTestApiGetFlowData,
  queryColumnAuthority,
  dealerFlowTestApiExecute
} from './api'
import { getStorage } from '@/utils/storage'
import { useRoute } from '@/utils/router'
import { Message } from 'element-ui'

const kmBpmformMainForm = ref({
  applyName: '',
  teacherInfos: []
})

const detailData = ref({}) //表单详情字段

const userInfo = ref({})
userInfo.value = getStorage('userInfo') || {}

if (!userInfo.value.code && userInfo.value.account)
  userInfo.value.code = userInfo.value.account

const route = useRoute()
const { query } = route

//表单编辑权限
const authObj = ref({})
const authResData = ref({})

const getAuthObjStatus = falg => {
  return {
    'teacherInfos.name': falg,
    'teacherInfos.applyName': falg,
    'teacherInfos.amount': falg
  }
}

//发起流程
const onSubmit = () => {
  Message('提交的表单为:' + JSON.stringify(kmBpmformMainForm.value))
  const params = {
    // businessId: '',
    // processInstanceId: '',
    applyAccount: userInfo.value.code,
    systemCode: 'distributor', //固定
    fdFormTemplateCode: 'derucci-distributor-202310130001',
    moduleCode: '10001',
    pageVersion: 'V1',
    applyName: kmBpmformMainForm.value.applyName,
    teacherInfos: [
      {
        name: kmBpmformMainForm.value.name,
        amount: kmBpmformMainForm.value.amount
      }
    ]
  }
  dealerFlowTestApiSubmit(params).then(res => {
    Message('提交结果:' + res?.msg)
  })
}
console.log('route.query.loginid', route.query.loginid)

//模拟提交审批流程
const onApproval = (data = {}) => {
  //校验必填

  const params = {
    applyBusinessModel: {
      ...detailData.value, //回显data
      ...kmBpmformMainForm.value, //审批过程修改data
      approveAccount: userInfo.value.code
    },
    bpmExecuteBean: data
  }
  console.log('审批参数--------', params)

  dealerFlowTestApiExecute(params).then(res => {
    Message('审批结果:' + res?.msg)
    if (res?.msg == 'success') {
      location.reload()
    }
  })
}

if (query.businessId) {
  //获取数据
  dealerFlowTestApiGetFlowData({ businessId: query.businessId }).then(res => {
    // Message('获取的到流程数据为' + JSON.stringify(res))
    detailData.value = res.data
    if (res.data.applyName || res.data.teacherInfos) {
      kmBpmformMainForm.value = {
        applyName: res.data.applyName,
        name: res.data.teacherInfos[0]['name'],
        amount: res.data.teacherInfos[0]['amount']
      }
    }
    //applyStatus;// 申请状态 0 驳回草稿 1 流程归档 2 流程废弃  3 审核中
    if (res.data.applyStatus == 0) {
      authObj.value = getAuthObjStatus(false)
    }
  })
  //获取审批表单权限
  queryColumnAuthority({
    formInstanceId: query.businessId,
    loginName: userInfo.value.code,
    processId: query.processId,
    fdNodeType: 2
  }).then(res => {
    if (res.data && res.data.length > 0) {
      authObj.value = getAuthObjStatus(true)

      res.data.forEach(item => {
        authObj.value[item.fdFieldId] = !item.edit
      })

      authResData.value = res.data
    }
  })
} else {
  authObj.value = getAuthObjStatus(false)
}

onMounted(() => {
  console.log('dom加载完毕')
  window.addEventListener('message', function (event) {
    console.log('message', event)
    if (event && event.data && event.data.type === 'submit') {
      // 点击提交事件，可先校验业务表单的内容，然后再处理流程的数据
      const formValues = event.data.formValues
      console.log('evev', event.data)
      if ('errorFields' in formValues) {
        Message('请填写所有必填表单项')
      } else {
        // 提交数据逻辑可参考场景1
        Message('提交的表单数据为：' + JSON.stringify(event.data))

        onApproval(event.data.formValues)
      }
    }
  })

  mobileDomAction()
})

// 移动端
function mobileDomAction() {
  window.addEventListener('message', function (event) {
    const eventData = event && event.data
    if (!eventData) {
      return
    }

    if (eventData.type === 'getFormValues') {
      const formValues = eventData.formValues
      if ('errorFields' in formValues) {
        alert('请填写所有必填表单项')
      } else {
        // 参照方案一的提交逻辑
        alert('通过必填校验，提交审批')
        onApproval(formValues)
      }
    }
    if (eventData.type === 'auditFormHeight') {
      const height = eventData.value
      const placeholderHeight = eventData.placeholderHeight
      // 审批表单的高度
      document.querySelector('#auditFormWrapper').style.height = height
      // 占位元素的高度
      document.querySelector('#auditFormPlaceholder').style.height =
        placeholderHeight || height
    }
    if (eventData.type === 'auditNotesHeight') {
      const height = eventData.value
      document.querySelector('#auditNotesWrapper').style.height = height
    }
    if (eventData.type === 'baseInfoHeight') {
      // 基础信息卡片
      const height = eventData.value
      document.querySelector('#baseInfoWrapper').style.height = height
    }
  })
}
</script>

<style lang="scss" scoped>
.page {
  padding: 20px;
}

.box1 {
  display: flex;
  width: 100%;
}

.form {
  flex: 1;
}

.menu {
  // padding-left: 20px;
  min-width: 300px;
}
</style>
