var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.FlowForm,{ref:"flowFormRef",attrs:{"title":"终端门店整改申诉流程","fd-form-template-code":"distributor-JXS028","model":_setup.form,"is-edit":[0, 20].includes(Number(_setup.form.status)),"get-detail":_setup.getDetail,"on-submit":_setup.onSubmit,"approval-data":_setup.approveData,"on-approval":_setup.onApproval}},[_c('div',[_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"delayUpdate":"","label":"标题","value":_setup.form.title,"disabled":"","required":false}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"delayUpdate":"","label":"单号","value":_setup.form.orderNumber,"placeholder":"自动生成","disabled":"","required":false}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"delayUpdate":"","label":"招商单号","value":_setup.form.zsOrderNumber,"placeholder":"自动生成","disabled":"","required":false}})],1),_c(_setup.HeaderTitle,{staticStyle:{"margin-top":"20px"},attrs:{"title":"经销商个人基础信息"}}),_c('div',[_c('div',[_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"提单人工号","value":_setup.form.optJobNumber,"placeholder":"自动生成","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"提单人","value":_setup.form.optUserName,"placeholder":"自动生成","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"提单部门","value":_setup.form.optDeptName,"placeholder":"自动生成","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"提单日期","value":_setup.form.optTime,"placeholder":"自动生成","disabled":""}})],1),_c(_setup.RequiredFormItemSelect,{ref:"businessRef",staticClass:"formItem",attrs:{"prop":"crmDistributorCode","label":"经销商（实际控股人）","labelKey":"dealerName","valueKey":"dealerCode","selectProps":{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        },"total-count":_setup.dealerListCount,"getDataList":_setup.getDealerList,"getNextList":_setup.getDealerList},on:{"onOptionsListNotFound":_setup.onDealerNotFound,"change":_setup.onDealerChange,"changeSelectedItem":e => {
            _setup.selectedDealerItem = e
            _setup.form.crmDistributorId = e ? e.id : ''
            _setup.form.crmDistributorName = e ? e.dealerName : ''
            _setup.form.crmDistributorCode = e ? e.dealerCode : ''
            _setup.form.authorizedCityName = e ? e.authorizedCityName : ''
            _setup.form.authorizedCityCode = e ? e.authorizedCityCode : ''
            if (e) {
              _setup.getAuthorizedCityDetail(e.authorizedCityCode)
              _setup.getDealerBossDetail(e.bossId)
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.dealerCode))]),_c('small',[_vm._v(_vm._s(scope.row.dealerName))])]}}]),model:{value:(_setup.form.crmDistributorCode),callback:function ($$v) {_vm.$set(_setup.form, "crmDistributorCode", $$v)},expression:"form.crmDistributorCode"}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"经销商（实际控股人）联系方式","value":_setup.form.bossContactInfo,"placeholder":"自动生成","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"授权城市","value":_setup.form.authorizedCityName,"placeholder":"自动生成","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"省","value":_setup.form.province,"placeholder":"自动生成","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"市","value":_setup.form.city,"placeholder":"自动生成","disabled":""}}),_c(_setup.RequiredFormItemInput,{staticClass:"formItem",attrs:{"label":"区/县","value":_setup.form.area,"placeholder":"自动生成","disabled":""}}),(_setup.form.optDeptName.indexOf('经销商') == -1)?_c(_setup.RequiredFormItemSelect,{staticClass:"formItem",attrs:{"prop":"approveData.brandIds","label":"品牌","labelKey":"name","valueKey":"id","selectProps":{
          filterable: true
        },"getDataList":_setup.getBrandList},model:{value:(_setup.form.approveData.brandIds),callback:function ($$v) {_vm.$set(_setup.form.approveData, "brandIds", $$v)},expression:"form.approveData.brandIds"}}):_vm._e(),_c(_setup.CommonTable,{staticStyle:{"border":"0","border-radius":"0"},attrs:{"default-data-list":_setup.form.storeRectificationList,"columns":_setup.storeRectificationColumns,"table-layout-direction":_setup.isMobile ? 'horizontal' : 'vertical',"card-props":{ shadow: 'never', bodyStyle: 'padding: 0' }}}),_c('div',{staticStyle:{"margin-top":"20px"}},[_c(_setup.RequiredFormItemInput,{staticClass:"formItem",staticStyle:{"width":"100%"},attrs:{"prop":"specialSituationDescription","inputProps":{
            type: 'textarea',
            resize: 'none',
            rows: 6,
            maxlength: '500',
            showWordLimit: true
          },"label":"特殊情况","rules":[
            {
              required: true,
              message: '特殊情况不能为空',
              trigger: ['change', 'blur']
            }
          ]},model:{value:(_setup.form.specialSituationDescription),callback:function ($$v) {_vm.$set(_setup.form, "specialSituationDescription", $$v)},expression:"form.specialSituationDescription"}})],1),_c('div',[_c(_setup.RequiredFormItemUploadFile,{staticClass:"formItem",staticStyle:{"width":"100%"},attrs:{"limit-size":"400","prop":"fileRecordList","label":"上传附件","auto-upload":"","required":false},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('div',[_vm._v("单个文件不能超过400M")])]},proxy:true}]),model:{value:(_setup.form.fileRecordList),callback:function ($$v) {_vm.$set(_setup.form, "fileRecordList", $$v)},expression:"form.fileRecordList"}})],1)],1)],1),_c('el-drawer',{attrs:{"title":"选择关联巡检流程","visible":_setup.visible,"size":"80%","append-to-body":""},on:{"update:visible":function($event){_setup.visible=$event},"open":_setup.handleOpen}},[_c('div',{staticStyle:{"padding":"0 20px"}},[_c('el-form',{attrs:{"model":_setup.commonForm,"inline":""}},[_c('el-form-item',{staticClass:"formItem",attrs:{"prop":"linkOrderNumber","label":"终端门店巡检整改流程"}},[(_setup.visible)?_c(_setup.CommonSelect,{attrs:{"valueKey":"orderNumber","labelKey":"title","filterable":true,"remote":true,"totalCount":_setup.linkOrderNumberTotalCount,"getDataListParams":_setup.getDataListParams,"getDataList":_setup.getLinkOrderNumber,"getNextList":_setup.getLinkOrderNumber,"label":"关联巡检整改流程"},on:{"changeSelectedItem":e => {
                _setup.commonForm.linkOrderNumber = e ? e.orderNumber : ''
                _setup.commonForm.linkformId = e ? e.id : ''
                _setup.commonForm.title = e ? e.title : ''
              },"OnOptionsListNotFound":function($event){async ({ value: orderNumber }, callback) => {
                const res = await _setup.getShopListPage({
                  orderNumber,
                  processCode: 'JXS029',
                  appeal: 'Y',
                  searchDealer: _setup.form.crmDistributorCode
                })
                callback(res.data)
              }}},model:{value:(_setup.commonForm.linkOrderNumber),callback:function ($$v) {_vm.$set(_setup.commonForm, "linkOrderNumber", $$v)},expression:"commonForm.linkOrderNumber"}}):_vm._e()],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_setup.getStoreDetail}},[_vm._v("查询")])],1)],1),_c('el-form',{attrs:{"model":_setup.form}},[_c(_setup.CommonTable,{ref:"commonTableRef",staticStyle:{"border":"0","border-radius":"0"},attrs:{"default-data-list":_setup.tableData,"columns":_setup.columns,"table-layout-direction":_setup.isMobile ? 'horizontal' : 'vertical',"card-props":{ shadow: 'never', bodyStyle: 'padding: 0' }},on:{"selection-change":_setup.onSelectionChange},scopedSlots:_vm._u([{key:"tableBefore",fn:function(){return [_c('el-table-column',{attrs:{"type":"selection","width":"55"}})]},proxy:true}])})],1),_c('div',{staticStyle:{"text-align":"center","padding-top":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_setup.onConfirm}},[_vm._v("确定")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }