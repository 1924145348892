<template>
  <el-drawer
    :visible.sync="themeVisibleDialog"
    :modal="false"
    title="系统设置"
    size="400px"
    :before-close="cancel"
  >
    <div class="drawer-container">
      <div class="drawer-item">
        <span>主题颜色</span>
        <ThemeSelect style="float: right" />
      </div>

      <div class="drawer-item">
        <span>开启标签页</span>
        <el-switch v-model="tagsView" class="drawer-switch" />
      </div>

      <div class="drawer-item">
        <span>固定头部</span>
        <el-switch v-model="fixedHeader" class="drawer-switch" />
      </div>
      <div class="drawer-item">
        <span>流程申请列数</span>
        <el-select
          v-model="column"
          style="width: 80px"
          placeholder="请选择列数"
        >
          <el-option
            v-for="(val, index) of columns"
            :key="val"
            :label="`${val}列`"
            :value="`${index + 2}`"
          />
        </el-select>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import ThemeSelect from '@/components/ThemeSelect'
export default {
  components: {
    ThemeSelect
  },
  props: {
    themeVisibleDialog: { type: Boolean, default: false },
    cancel: { type: Function, default: () => {} }
  },
  data() {
    return {
      drawer: true,
      columns: ['二', '三', '四', '五', '六', '七', '八', '九', '十']
    }
  },
  computed: {
    column: {
      get() {
        return this.$store.getters.column
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'column',
          value: val
        })
      }
    },
    fixedHeader: {
      get() {
        return this.$store.state.settings.fixedHeader
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        })
      }
    },
    tagsView: {
      get() {
        return this.$store.state.settings.tagsView
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 22px;
  }

  .drawer-item {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  .drawer-switch {
    float: right;
  }
}
</style>
