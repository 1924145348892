<template>
  <div>
    <FlowForm
      ref="flowFormRef"
      title="终端门店整改申诉流程"
      fd-form-template-code="distributor-JXS028"
      :model="form"
      :is-edit="[0, 20].includes(Number(form.status))"
      :get-detail="getDetail"
      :on-submit="onSubmit"
      :approval-data="approveData"
      :on-approval="onApproval">
      <div>
        <RequiredFormItemInput
          delayUpdate
          label="标题"
          class="formItem"
          :value="form.title"
          disabled
          :required="false" />
        <RequiredFormItemInput
          delayUpdate
          label="单号"
          class="formItem"
          :value="form.orderNumber"
          placeholder="自动生成"
          disabled
          :required="false" />
        <RequiredFormItemInput
          delayUpdate
          label="招商单号"
          class="formItem"
          :value="form.zsOrderNumber"
          placeholder="自动生成"
          disabled
          :required="false" />
      </div>
      <HeaderTitle style="margin-top: 20px" title="经销商个人基础信息" />
      <div>
        <div>
          <RequiredFormItemInput
            class="formItem"
            label="提单人工号"
            :value="form.optJobNumber"
            placeholder="自动生成"
            disabled />
          <RequiredFormItemInput
            class="formItem"
            label="提单人"
            :value="form.optUserName"
            placeholder="自动生成"
            disabled />
          <RequiredFormItemInput
            class="formItem"
            label="提单部门"
            :value="form.optDeptName"
            placeholder="自动生成"
            disabled />
          <RequiredFormItemInput
            class="formItem"
            label="提单日期"
            :value="form.optTime"
            placeholder="自动生成"
            disabled />
        </div>

        <RequiredFormItemSelect
          class="formItem"
          ref="businessRef"
          prop="crmDistributorCode"
          v-model="form.crmDistributorCode"
          label="经销商（实际控股人）"
          labelKey="dealerName"
          valueKey="dealerCode"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :total-count="dealerListCount"
          :getDataList="getDealerList"
          :getNextList="getDealerList"
          @onOptionsListNotFound="onDealerNotFound"
          @change="onDealerChange"
          @changeSelectedItem="
            e => {
              selectedDealerItem = e
              form.crmDistributorId = e ? e.id : ''
              form.crmDistributorName = e ? e.dealerName : ''
              form.crmDistributorCode = e ? e.dealerCode : ''
              form.authorizedCityName = e ? e.authorizedCityName : ''
              form.authorizedCityCode = e ? e.authorizedCityCode : ''
              if (e) {
                getAuthorizedCityDetail(e.authorizedCityCode)
                getDealerBossDetail(e.bossId)
              }
            }
          ">
          <template v-slot="{ scope }">
            <span>{{ scope.row.dealerCode }}</span>
            <small>{{ scope.row.dealerName }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemInput
          class="formItem"
          label="经销商（实际控股人）联系方式"
          :value="form.bossContactInfo"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="授权城市"
          :value="form.authorizedCityName"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="省"
          :value="form.province"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="市"
          :value="form.city"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="区/县"
          :value="form.area"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemSelect
          v-if="form.optDeptName.indexOf('经销商') == -1"
          class="formItem"
          prop="approveData.brandIds"
          v-model="form.approveData.brandIds"
          label="品牌"
          labelKey="name"
          valueKey="id"
          :selectProps="{
            filterable: true
          }"
          :getDataList="getBrandList">
        </RequiredFormItemSelect>
        <CommonTable
          :default-data-list="form.storeRectificationList"
          :columns="storeRectificationColumns"
          :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
          :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
          style="border: 0; border-radius: 0" />
        <div style="margin-top: 20px">
          <RequiredFormItemInput
            prop="specialSituationDescription"
            v-model="form.specialSituationDescription"
            style="width: 100%"
            class="formItem"
            :inputProps="{
              type: 'textarea',
              resize: 'none',
              rows: 6,
              maxlength: '500',
              showWordLimit: true
            }"
            label="特殊情况"
            :rules="[
              {
                required: true,
                message: '特殊情况不能为空',
                trigger: ['change', 'blur']
              }
            ]" />
        </div>
        <div>
          <RequiredFormItemUploadFile
            style="width: 100%"
            v-model="form.fileRecordList"
            class="formItem"
            limit-size="400"
            prop="fileRecordList"
            label="上传附件"
            auto-upload
            :required="false">
            <template v-slot:tip>
              <div>单个文件不能超过400M</div>
            </template>
          </RequiredFormItemUploadFile>
        </div>
      </div>
    </FlowForm>
    <el-drawer
      title="选择关联巡检流程"
      :visible.sync="visible"
      size="80%"
      append-to-body
      @open="handleOpen">
      <div style="padding: 0 20px">
        <el-form :model="commonForm" inline>
          <el-form-item
            class="formItem"
            prop="linkOrderNumber"
            label="终端门店巡检整改流程">
            <CommonSelect
              v-if="visible"
              v-model="commonForm.linkOrderNumber"
              @changeSelectedItem="
                e => {
                  commonForm.linkOrderNumber = e ? e.orderNumber : ''
                  commonForm.linkformId = e ? e.id : ''
                  commonForm.title = e ? e.title : ''
                }
              "
              valueKey="orderNumber"
              labelKey="title"
              :filterable="true"
              :remote="true"
              :totalCount="linkOrderNumberTotalCount"
              :getDataListParams="getDataListParams"
              :getDataList="getLinkOrderNumber"
              :getNextList="getLinkOrderNumber"
              @OnOptionsListNotFound="
                async ({ value: orderNumber }, callback) => {
                  const res = await getShopListPage({
                    orderNumber,
                    processCode: 'JXS029',
                    appeal: 'Y',
                    searchDealer: form.crmDistributorCode
                  })
                  callback(res.data)
                }
              "
              label="关联巡检整改流程">
            </CommonSelect>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getStoreDetail">查询</el-button>
          </el-form-item>
        </el-form>
        <el-form :model="form">
          <CommonTable
            ref="commonTableRef"
            :default-data-list="tableData"
            :columns="columns"
            :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
            :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
            style="border: 0; border-radius: 0"
            @selection-change="onSelectionChange">
            <template #tableBefore>
              <el-table-column type="selection" width="55"> </el-table-column>
            </template>
          </CommonTable>
        </el-form>

        <div style="text-align: center; padding-top: 20px">
          <el-button type="primary" @click="onConfirm">确定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, toRefs, nextTick } from 'vue'
import { Message } from 'element-ui'
import dateFormat from 'dateformat'
import FlowForm from '@/views/bpmFlow/components/flowForm.vue'
import CommonTable from '@/components/TablePro/Table.vue'
import { render } from '@/components/TablePro/fn'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import CommonSelect from '@/components/CustomFormItem/Select.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import HeaderTitle from '@/views/bpmFlow/components/headerTitle.vue'
import store from '@/store'
import { useRoute, useRouter } from '@/utils/router'
import { getBusinessCityList, getBossDetail } from '@/api/businessOos'
import { getByAuthorizedCityCode, brandseriesList } from '@/api/common'
import { getShopListPage } from '@/api/business/draft'
import * as storeRectificationAppealApi from '@/views/bpmFlow/api/storeRectificationAppeal'
import { aliUrlToFileObject } from '@/utils/obsUpload'
import { getStorage, getSession } from '@/utils/storage'
const commonTableRef = ref()
const commonForm = ref({
  linkOrderNumber: '',
  title: '',
  linkformId: ''
})
const visible = ref(false)
const $route = useRoute()
const routeQuery = $route.query
const flowFormRef = ref()
const user = store.state.user.userInfo
const isOA = computed(() => store.state.dealershipTransfer.isOA)
const isMobile = computed(
  () => store.state.app.device === 'mobile' || store.state.app.isWxworkMoblie
)
const approveData = ref({
  rectificationType: '2', //申诉类型:1:申诉(经销商提交)、2:免罚(总部提交) ,
  brandIds: []
})
const form = reactive({
  id: '', //表单id
  title: '终端门店整改申诉流程', //标题
  orderNumber: '', //单号
  zsOrderNumber: '', //招商单号
  optJobNumber: '', //提单人工号
  optUserId: '', //提单人id
  optUserName: '', //提单人
  optDeptId: '', //提单部门id
  optDeptName: '', //提单部门
  optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'), //提单日期
  crmDistributorId: '', //经销商id
  crmDistributorCode: '', //经销商编号
  crmDistributorName: '', //经销商名称
  bossContactInfo: '', //经销商（实际控股人）联系方式
  authorizedCityCode: '', //授权城市编号
  authorizedCityName: '', //授权城市名称
  province: '', //省
  city: '', //市
  area: '', //区
  storeRectificationList: [],
  fileRecordList: [], //附件
  specialSituationDescription: '', //特殊情况说明
  status: 0,
  applyOrderModel: {
    approveAccount: '',
    businessId: '',
    processInstanceId: '',
    bpmCreateBean: {},
    bpmExecuteBean: {},
    fdFormTemplateCode: '',
    pageVersion: '',
    systemCode: ''
  },
  approveData: {
    rectificationType: '2', //申诉类型:1:申诉(经销商提交)、2:免罚(总部提交) ,
    brandIds: []
  }
})
const getDataListParams = computed(() => {
    return {
      searchBrandIds:form.optDeptName.indexOf('经销商') == -1?form.approveData.brandIds:''
    }
  
})

const hideInTable = computed(() => {
  if (!isNaN(Number(form.status))) {
    return ![0, 20].includes(Number(form.status))
  } else {
    return false
  }
})
const storeRectificationColumns = ref([
  {
    name: 'bran',
    width: 60,
    hideInTable,
    tableColumnProps: {
      align: 'center'
    },
    title: render((h, context) => (
      <span
        class="el-icon-circle-plus iconfont"
        onClick={() => {
          if (!form.crmDistributorCode) {
            Message.error('请先选择经销商')
            return
          }
          if (form.optDeptName.indexOf('经销商') == -1&&!form.approveData.brandIds) {
            Message.error('请选择品牌')
            return
          }
          visible.value = true
        }}
      />
    )),
    render: render((h, context) => (
      <span
        class="el-icon-remove iconfont"
        onClick={() => {
          removeTableItem(form.storeRectificationList, context.index)
        }}
      />
    ))
  },
  { title: '序号', width: 55, valueType: 'index' },
  {
    title: '关联巡检整改流程',
    name: 'linkOrderNumber',
    width: 100
  },
  {
    title: '关联巡检整改流程名称',
    name: 'linkTitle',
    width: 100
  },
  {
    title: '门店编码',
    name: 'shopNumber',
    width: 150
  },
  {
    title: '违规类型',
    name: 'violationType',
    minWidth: 200
  },
  {
    title: '违规情形',
    name: 'violationSituation',
    minWidth: 200
  },
  {
    title: '违规资料（2至3张带水印时间照片）',
    name: 'violationInformationFileUrls',
    width: 400,
    render: render((h, context) => {
      if (context.data.violationInformationFileUrls) {
        return (
          <RequiredFormItemUploadFile
            value={context.data.violationInformationFileUrls}
            onChangeValue={e => {
              context.data.violationInformationFileUrls = e
            }}
            class="formItem"
            limit-size="400"
            prop={`storeRectificationList.${context.index}.violationInformationFileUrls`}
            label={context.config.title}
            show-label={false}
            auto-upload
            scopedSlots={{
              tip() {
                return <div>单个文件不能超过400M</div>
              }
            }}
            disabled></RequiredFormItemUploadFile>
        )
      }
    })
  },
  {
    title: '整改开始时间',
    name: 'rectificationStartTime',
    minWidth: 200
  },
  {
    title: '整改结束时间',
    name: 'rectificationEndTime',
    minWidth: 200
  },
  {
    title: '整改期限',
    name: 'rectificationDeadline',
    minWidth: 200
  },
  {
    title: '整改要求',
    name: 'rectificationRequirements',
    minWidth: 200
  },
  {
    title: '审核结果',
    name: 'findingsOfAudit',
    minWidth: 200
  },
  {
    title: '整改附件',
    name: 'fileRecordList',
    width: 400,
    render: render((h, context) => {
      if (context.data.fileRecordList) {
        return (
          <RequiredFormItemUploadFile
            value={context.data.fileRecordList}
            onChangeValue={e => {
              context.data.fileRecordList = e
            }}
            class="formItem"
            limit-size="400"
            prop={`storeRectificationList.${context.index}.fileRecordList`}
            label={context.config.title}
            show-label={false}
            auto-upload
            scopedSlots={{
              tip() {
                return <div>单个文件不能超过400M</div>
              }
            }}
            disabled></RequiredFormItemUploadFile>
        )
      }
    })
  }
])
const tableData = ref([])
const getStoreDetail = async params => {
  if (!commonForm.value.linkformId) {
    Message.error('请先选择巡检流程')
    return
  }
  const res =
    await storeRectificationAppealApi.getStoreInspectionRectificationDetail({
      formId: commonForm.value.linkformId
    })
  tableData.value = res.data.storeInspectionRectificationList.map(item => {
    const violationDetail = JSON.parse(item.violationDetailJson)
    const violationImages =
      violationDetail.violationImages.map(url => {
        return aliUrlToFileObject(url)
      }) || []
    return {
      ...item,
      violationInformationFileUrls: violationImages,
      brandNumber:res.data.brandNumber
    }
  })
}
const columns = ref([
  { title: '序号', width: 55, valueType: 'index' },
  {
    title: '门店编码',
    name: 'shopNumber'
  },
  {
    title: '违规类型',
    name: 'violationType'
  },
  {
    title: '违规情形',
    name: 'violationSituation'
  },
  {
    title: '违规资料（2至3张带水印时间照片）',
    name: 'violationInformationFileUrls',
    width: 400,
    render: render((h, context) => {
      if (context.data.violationInformationFileUrls) {
        return (
          <RequiredFormItemUploadFile
            value={context.data.violationInformationFileUrls}
            onChangeValue={e => {
              context.data.violationInformationFileUrls = e
            }}
            class="formItem"
            limit-size="400"
            label={context.config.title}
            show-label={false}
            auto-upload
            scopedSlots={{
              tip() {
                return <div>单个文件不能超过400M</div>
              }
            }}
            disabled
            rules={[{ required: false }]}></RequiredFormItemUploadFile>
        )
      }
    })
  },
  {
    title: '整改开始时间',
    name: 'rectificationStartTime'
  },
  {
    title: '整改结束时间',
    name: 'rectificationEndTime'
  },
  {
    title: '整改期限',
    name: 'rectificationDeadline'
  },
  {
    title: '整改要求',
    name: 'rectificationRequirements'
  },
  {
    title: '审核结果',
    name: 'findingsOfAudit'
  },
  {
    title: '整改附件',
    name: 'fileRecordList',
    width: 400,
    render: render((h, context) => {
      if (context.data.fileRecordList) {
        return (
          <RequiredFormItemUploadFile
            value={context.data.fileRecordList}
            onChangeValue={e => {
              context.data.fileRecordList = e
            }}
            class="formItem"
            limit-size="400"
            label={context.config.title}
            show-label={false}
            auto-upload
            scopedSlots={{
              tip() {
                return <div>单个文件不能超过400M</div>
              }
            }}
            disabled></RequiredFormItemUploadFile>
        )
      }
    })
  }
])
const multipleSelections = ref([])
const onSelectionChange = selections => {
  multipleSelections.value = selections
}
const handleOpen = () => {
  nextTick(() => {
    commonForm.value.linkOrderNumber = ''
    commonForm.value.linkformId = ''
    commonForm.value.title = ''
    tableData.value = []
  })
}
const onConfirm = () => {
  if (!multipleSelections.value.length) {
    Message.error('请选择门店巡检记录')
    return
  }
  const ids = form.storeRectificationList.map(item => item.id)
  const storeDetails = []
  multipleSelections.value.forEach(item => {
    if (ids.indexOf(item.id) == -1) {
      storeDetails.push({
        id: item.id,
        linkformId: commonForm.value.linkformId, //关联巡检整改流程单id
        linkOrderNumber: commonForm.value.linkOrderNumber, //关联巡检整改流程单号
        linkTitle: commonForm.value.title,
        shopId: item.shopId, //门店id
        shopNumber: item.shopNumber, //门店编码
        violationType: item.violationType, //违规类型
        violationSituation: item.violationSituation, //违规情形
        violationInformationFileUrls: item.violationInformationFileUrls, //违规资料（2至3张带水印时间照片） 多张文件链接用英文逗号隔开
        rectificationStartTime: item.rectificationStartTime, //整改开始时间
        rectificationEndTime: item.rectificationEndTime, //整改结束时间
        rectificationDeadline: item.rectificationDeadline, //整改期限
        rectificationRequirements: item.rectificationRequirements, //整改要求
        findingsOfAudit: item.findingsOfAudit, //审核结果
        fileRecordList: item.fileRecordList, //整改附件
        brandNumber: item.brandNumber,//品牌Id
      })
    }
  })
  form.storeRectificationList = [
    ...form.storeRectificationList,
    ...storeDetails
  ]
  visible.value = false
}
const dealerListCount = ref(0)
const selectedDealerItem = ref()
const linkOrderNumberTotalCount = ref(0)
const getLinkOrderNumber = async ({ page, searchText, getDataListParams }) => {
  const res = await getShopListPage({
    currPage: page,
    title: searchText,
    processCode: 'JXS029',
    appeal: 'Y',
    searchDealer: form.crmDistributorCode,
    ...getDataListParams
  })
  linkOrderNumberTotalCount.value = res.totalCount
  return res.data
}
const getDealerList = async ({ page, searchText }) => {
  const systemCode = getSession('systemCode') || process.env.VUE_APP_SYSTEM_CODE
  const res = await getBusinessCityList({
    currPage: page,
    dealerName: searchText,
    ifEnabled: 0,
    bossId: systemCode == '120' ? getStorage('bossInfo')?.id : ''
  })
  dealerListCount.value = res.totalCount
  const item = res.data.find(
    item => item.dealerCode === form.crmDistributorCode
  )
  if (item) {
    form.crmDistributorId = item.id
    form.crmDistributorName = item.dealerName
    form.crmDistributorCode = item.dealerCode
    form.authorizedCityName = item.authorizedCityName
    form.authorizedCityCode = item.authorizedCityCode
  }
  return res.data
}
const onDealerNotFound = async ({ value: dealerCode }, callback) => {
  const res = await getBusinessCityList({
    dealerCode
  })
  callback(res.data)
}
const onDealerChange = () => {
  form.bossContactInfo = ''
  form.province = ''
  form.city = ''
  form.area = ''
  form.town = ''
  form.authorizedCityName = ''
  form.authorizedCityCode = ''
  form.storeRectificationList = []
}
const getBrandList = async ({ page, searchText }) => {
  const res = await brandseriesList({
    brandLevel: 0,
    ifDelete: [0, 20].includes(form.status) ? 0 : -1
  })
  return res.data
}
const getAuthorizedCityDetail = async authorizedCityCode => {
  const res = await getByAuthorizedCityCode({ authorizedCityCode })
  const { province = '', city = '', area = '' } = res.data
  form.province = province
  form.city = city
  form.area = area
}
const getDealerBossDetail = async bossId => {
  const res = await getBossDetail({ id: bossId })
  if (res.data && res.data.length) {
    const { contactInfo = '' } = res.data[0]
    form.bossContactInfo = contactInfo
  }
}
const addStoreRectificationList = () => {
  if (!form.crmDistributorName) {
    Message.error('请先选择经销商')
    return
  }
  form.storeRectificationList.push({
    linkformId: '', //关联巡检整改流程单id
    linkOrderNumber: '', //关联巡检整改流程单号
    shopId: '', //门店id
    shopNumber: '', //门店编码
    violationType: '', //违规类型
    violationSituation: '', //违规情形
    violationInformationFileUrls: [], //违规资料（2至3张带水印时间照片） 多张文件链接用英文逗号隔开
    rectificationStartTime: '', //整改开始时间
    rectificationEndTime: '', //整改结束时间
    rectificationDeadline: '', //整改期限
    rectificationRequirements: '', //整改要求
    findingsOfAudit: '' //审核结果
  })
}
const removeTableItem = (data, index) => {
  data.splice(index, 1)
}
const onApproval = async (bpmExecuteBean, applyOrderModel) => {
  // console.log('审批',bpmExecuteBean, applyOrderModel)
  if (Number(form.status) === 20) {
    form.applyOrderModel = {
      ...form.applyOrderModel,
      ...applyOrderModel
    }
    form.applyOrderModel.bpmExecuteBean = bpmExecuteBean
    return flowFormRef.value.onCommit('submit')
  }

  return storeRectificationAppealApi.submitApproveData({
    id: routeQuery.id || routeQuery.businessId,
    optUserId: user.accountId,
    optUserName: user.realName,
    optJobNumber: user.code || user.account,
    approveData: form.approveData,
    applyOrderModel: {
      ...form.applyOrderModel,
      ...applyOrderModel,
      approveAccount: user.code || user.account,
      bpmExecuteBean
    }
  })
}
const getDetail = async (type, cachedFormData) => {
  let initFormData = cachedFormData
  // 编辑状态
  if (type === 'detail') {
    const { data } = await storeRectificationAppealApi.getDetail({
      formId: routeQuery.id || routeQuery.businessId
    })
    initFormData = data
  }

  if (initFormData) {
    for (const key in form) {
      if (initFormData[key] !== undefined) {
        if (key == 'storeRectificationList') {
          const storeRectificationList = initFormData[key].map(item => {
            const violationInformationFileUrls =
              item.violationInformationFileUrls
                ? item.violationInformationFileUrls.split(',')
                : []
            return {
              ...item,
              violationInformationFileUrls: violationInformationFileUrls.map(
                url => aliUrlToFileObject(url)
              )
            }
          })
          form[key] = storeRectificationList
        } else {
          form[key] = initFormData[key]
          if(key=='approveData'){
            approveData.value = {
              brandIds:form.approveData.brandIds?form.approveData.brandIds.split(","):[],
              rectificationType:`${form.approveData.rectificationType}`
            }
          }
        }
      }
    }
  }

  if (user && !isOA.value) {
    form.optUserId = form.optUserId || user.accountId
    form.optJobNumber = form.optJobNumber || user.code
    form.optUserName = form.optUserName || user.realName
    form.optDeptId = form.optDeptId || user.deptId
    form.optDeptName = form.optDeptName || user.deptName
  }
}
const onSubmit = async type => {
  const time = dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss')
  form.optTime = time
  form.applyOrderModel.approveAccount = user.code || user.account
  form.applyOrderModel.fdFormTemplateCode = 'distributor-JXS028'
  form.applyOrderModel.pageVersion = 'V1'
  form.applyOrderModel.systemCode = 'distributor'
  const brandNumbers = []
  const storeRectificationList = form.storeRectificationList.map(item => {
    if(item.brandNumber&&brandNumbers.indexOf(item.brandNumber)==-1){
        brandNumbers.push(item.brandNumber)
    }
    const violationInformationFileUrls = item.violationInformationFileUrls.map(
      item => {
        return item.requestDomainName + item.requestPath
      }
    )
    return {
      ...item,
      violationInformationFileUrls: violationInformationFileUrls.join(',')
    }
  })
  const rectificationType = form.optDeptName.indexOf('经销商') != -1 ? '1' : '2'
  const brandIds = rectificationType=='1'? brandNumbers.join(','): form.approveData.brandIds
  form.title = `终端门店整改申诉流程(${form.crmDistributorName || ''})`
  const data = Object.assign({}, form, {
    storeRectificationList,
    approveData: { ...form.approveData, brandIds, rectificationType }
  })
  if (type !== 'submit') {
    return storeRectificationAppealApi.saveDraft(data)
  }
  if (!storeRectificationList.length) {
    Message.error('请添加门店巡检申诉')
    return Promise.reject()
  }
  return storeRectificationAppealApi.submit(data)
}
</script>

<style lang="scss"></style>
