<script setup lang="jsx">
import TablePro, { render, renderFormItem } from '@/components/TablePro'
import CommonPopup from '@/components/FormPro/CommonPopup.vue'
import { getRoomList, getRoomProduct } from './api/'

const productTableProps = {
  request: async params => {
    const { data } = await getRoomProduct(params)
    return { data, totalCount: data.length }
  },
  columns: [
    { title: '设备名称', name: 'deviceName' },
    { title: '设备码', name: 'deviceCode' },
    {
      title: '可用状态',
      name: 'status',
      valueType: 'select',
      valueEnum: [
        { label: '在线', value: 1 },
        { label: '离线', value: 0 }
      ]
    }
  ]
}

const tableProProps = {
  request: async params => getRoomList({ ...params }),
  autoWidthCell: true,
  columns: [
    { title: '酒店ID', name: 'hotelId' },
    { title: '酒店名称', name: 'hotelName', search: true },
    { title: '楼栋', name: 'building' },
    { title: '房号', name: 'roomName' },
    { title: '房型', name: 'roomType' },
    {
      title: '房间状态',
      name: 'status',
      search: true,
      valueType: 'select',
      valueEnum: [
        { label: '可用', value: 1 },
        { label: '不可用', value: 2 },
        { label: '使用中', value: 3 }
      ]
    },
    {
      title: '是否绑定设备',
      name: 'deviceBindNum',
      search: {
        transform: e => {
          return { ifBindDevice: e }
        }
      },
      valueType: 'select',
      valueEnum: [
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ],
      renderText: (text, data) => text
    },
    {
      title: '可用状态',
      name: 'enable',
      search: true,
      valueType: 'select',
      valueEnum: [
        { label: '有效', value: 1 },
        { label: '无效', value: 0 }
      ]
    },
    { title: '更新时间', name: 'updateTime' },
    { title: '更新人', name: 'updateBy' },
    {
      title: '操作',
      width: 120,
      render: render((h, ctx) => {
        return (
          <CommonPopup
            popupType="drawer"
            width="70%"
            scopedSlots={{
              trigger: ({ onShow }) => {
                return (
                  <el-button
                    type="primary"
                    onClick={() =>
                      onShow({}, undefined, {
                        title: `${ctx.data.roomName}产品列表`
                      })
                    }>
                    查看产品
                  </el-button>
                )
              },
              default: () => {
                return (
                  <TablePro
                    auto-width-cell
                    hide-pagination
                    request={productTableProps.request}
                    columns={productTableProps.columns}
                    requestParams={{
                      roomId: ctx.data.roomId
                    }}
                  />
                )
              }
            }}
          />
        )
      })
    }
  ]
}
</script>

<template>
  <TablePro v-bind="tableProProps" />
</template>
