<template>
  <div class="app-container">
    <TablePro
      ref="tableProRef"
      :columns="columns"
      :request="getTableList"
      auto-width-cell>
      <template #actionArea>
        <el-button @click="onExportCount()"> 导出 </el-button>
      </template>
    </TablePro>
  </div>
</template>

<script setup>
import TablePro, { render } from '@/components/TablePro/index.vue'
import { ref } from 'vue'
import { apiGetNoticeList, downloadNotice } from '../api'

const tableProRef = ref(null)
const onExportCount = () => {
  downloadNotice()
}

const columns = [
  {
    title: '序号',
    tableColumnProps: { align: 'center' },
    valueType: 'index'
  },
  {
    title: '下发人',
    name: 'inspectionName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '下发时间',
    name: 'createTime',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '省份',
    name: 'province',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '授权城市',
    name: 'authorizedCity',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '经销商编号',
    name: 'dealerCode',
    search: true,
    hideInTable: true,
    hideInExport: true,
    tableColumnProps: { align: 'center' }
  },
  {
    title: '经销商',
    name: 'dealerName',
    search: true,
    tableColumnProps: { align: 'center' }
  },
  // {
  //   title: '违约金（应收）合计',
  //   name: 'receivableAmount',
  //   tableColumnProps: { align: 'center' }
  // },
  {
    title: '违约金（实收）合计',
    name: 'actualAmount',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '整改通知单',
    name: 'obsUrl',
    tableColumnProps: { align: 'center' },
    render: render((h, context) => {
      return (
        <el-button onClick={() => handlePreview(context.data.obsUrl)}>
          预览
        </el-button>
      )
    })
  }
]
const getTableList = async params => {
  const res = await apiGetNoticeList({
    ...params,
    enable: 1
  })
  return {
    data: res.data,
    totalCount: res.totalCount
  }
}
const handlePreview = url => {
  window.open(url)
}
</script>
