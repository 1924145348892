<template>
  <div style="margin-top:20px">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
      </div>
      <el-table
        ref="filterTable"
        :data="data"
        border
        row-key="aId"
        style="width: 100%;margin-top:20px"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        :tree-props="{children: 'storeTypeList', hasChildren: 'hasChildren'}"
      >
        <el-table-column prop="province" label="省份" align="center">
        </el-table-column>
        <el-table-column prop="brand" label="品牌" align="center">
        </el-table-column>
        <el-table-column prop="storeType" label="店铺类型" align="center">
        </el-table-column>
        <el-table-column prop="targetSum" label="计数" align="center">
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: '各区域开店计划汇总明细'
    },
    data:{
      type:Array,
      default:()=>[]
    }
  }
}
</script>

<style lang="scss" scoped></style>
