<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="区域配置" name="first">
        <AreaPersonShip v-if="isFirst" :bigBrandMap="bigBrandMap" />
      </el-tab-pane>
      <el-tab-pane label="区域部门配置" name="second"
        ><AreaDepartmentShip v-if="isSecond" :bigBrandMap="bigBrandMap" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AreaDepartmentShip from './components/areaDepartmentShip.vue'
import AreaPersonShip from './components/areaPersonShip.vue'
import { brandseriesList } from '@/api/common/'
import * as apiConfig from '@/api/commonConfig/areaConfig'
// import {
//   getAllsmallRegion,
//   getAlladministrativeRegion
// } from '@/api/commonConfig/areaConfig'
// import { mapActions } from 'vuex'
export default {
  components: {
    AreaDepartmentShip,
    AreaPersonShip
  },
  watch: {},
  computed: {},
  data() {
    return {
      activeName: 'first',
      isFirst: true,
      isSecond: false,
      bigBrandMap: []
      // bigRegionMap: [],
      // smallRegionMap: []
    }
  },
  async mounted() {
    this.getSomeData()
    // this.asyncSetRegionData({
    //   level: 1
    // })
    // this.asyncSetRegionData({
    //   level: 2
    // })
    // this.asyncSetDeptData()
  },
  methods: {
    // ...mapActions('commonConfig', ['asyncSetRegionData', 'asyncSetDeptData']),
    async getSomeData() {
      // const res = await apiConfig.getAreaByBrand({ status: 0 })
      const res = await brandseriesList({ brandLevel: 0, ifDelete: 0 })
      this.bigBrandMap = res.data
      // const res2 = await getAlladministrativeRegion()
      // this.bigRegionMap = res2.data
      // const res3 = await getAllsmallRegion()
      // this.smallRegionMap = res3.data
    },
    handleClick(tab) {
      this.isFirst = tab.name === 'first'
      this.isSecond = tab.name === 'second'
    }
  }
}
</script>

<style scoped lang="scss"></style>
