var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"visible":_vm.isShow,"width":"450px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.isShow=$event},"close":_vm.handleClose}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formOption,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"品牌","prop":"brandCode","rules":[
          { required: true, message: '请选择品牌', trigger: 'change' }
        ]}},[_c('el-select',{attrs:{"filterable":"","remote":"","placeholder":"请选择品牌","popper-class":"custom-business-select"},on:{"change":_vm.handleSelectDept},model:{value:(_vm.formOption.brandCode),callback:function ($$v) {_vm.$set(_vm.formOption, "brandCode", $$v)},expression:"formOption.brandCode"}},_vm._l((_vm.bigBrandMap),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.code}})}),1)],1),_c('required-form-item-select',{ref:"itemSelect",attrs:{"prop":"administrativeRegionName","label":"大区","dataList":_vm.Region,"labelKey":"administrativeRegionName","valueKey":"administrativeRegionName","getDataTrigger":[],"selectProps":{
          filterable: true,
          remote: true,
          clearable: true,
          'popper-class': 'custom-business-select'
        }},on:{"changeSelectedItem":data => {
            if (data) {
              _vm.formOption.administrativeRegionCode =
                data.administrativeRegionCode
              _vm.formOption.administrativeRegionId = data.administrativeRegionId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.administrativeRegionName))])]}}]),model:{value:(_vm.formOption.administrativeRegionName),callback:function ($$v) {_vm.$set(_vm.formOption, "administrativeRegionName", $$v)},expression:"formOption.administrativeRegionName"}}),_c('required-form-item-select',{ref:"itemSelect",attrs:{"label":"小区","prop":"smallRegionName","dataList":_vm.SmallRegion,"labelKey":"smallRegionName","valueKey":"smallRegionName","getDataTrigger":[],"selectProps":{
          filterable: true,
          remote: true,
          clearable: true,
          'popper-class': 'custom-business-select'
        },"required":true},on:{"changeSelectedItem":data => {
            if (data) {
              _vm.formOption.smallRegionCode = data.smallRegionCode
              _vm.formOption.smallRegionId = data.smallRegionId
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.smallRegionName))])]}}]),model:{value:(_vm.formOption.smallRegionName),callback:function ($$v) {_vm.$set(_vm.formOption, "smallRegionName", $$v)},expression:"formOption.smallRegionName"}}),_c('el-form-item',{attrs:{"label":"地区(省市区)","prop":"provinceCode","rules":[
          { required: true, message: '请选择地区', trigger: 'change' }
        ]}},[(_vm.refresh)?_c('el-cascader',{attrs:{"props":_vm.addressProp},model:{value:(_vm.selectAddress),callback:function ($$v) {_vm.selectAddress=$$v},expression:"selectAddress"}}):_vm._e()],1),_c('el-form-item',{attrs:{"label":"状态","prop":"status"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":0,"inactive-value":1},model:{value:(_vm.formOption.status),callback:function ($$v) {_vm.$set(_vm.formOption, "status", $$v)},expression:"formOption.status"}})],1),_c('el-form-item',{attrs:{"label":"部门","prop":"deptCode","rules":[
          { required: true, message: '请选择部门', trigger: 'change' }
        ]}},[_c('CommonSelect',{key:"id",attrs:{"filterable":"","remote":"","placeholder":"请选择部门","get-data-list":_vm.getListDetp,"get-next-list":_vm.getListDetp,"value-key":"code","label-key":"name"},on:{"changeSelectedItem":_vm.handleSelectDepts},model:{value:(_vm.formOption.deptCode),callback:function ($$v) {_vm.$set(_vm.formOption, "deptCode", $$v)},expression:"formOption.deptCode"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }