import http from '../../utils/request'

export const getCityInfoData = params => {
  return http({
    url: '/dealermanage/authorizedcity/listpage',
    params,
    method: 'GET'
  })
}

export const getCityLevel = params => {
  return http({
    url: '/dealermanage/datadictionary/list?module=distributor&type=authorized_city_level',
    params,
    method: 'GET'
  })
}

export const addCityInfo = data => {
  return http({
    url: '/dealermanage/authorizedcity/add',
    data,
    method: 'POST'
  })
}

export const editCityInfo = data => {
  return http({
    url: '/dealermanage/authorizedcity/update',
    data,
    method: 'POST'
  })
}

export const delCityInfo = data => {
  return http({
    url: '/dealermanage/authorizedcity/delete',
    data,
    method: 'POST'
  })
}

export const activeOrInActive = params => {
  return http({
    url: '/dealermanage/authorizedcity/sign', //   /dealermanage/authorizedcity/sign?id=4&ifEnabled=1
    params,
    method: 'GET'
  })
}

/** ----------------------------- */
/**
 * 获取城市信息列表
 */
export const authorizedCityAndLevel = params => {
  return http({
    url: '/dealermanage/authorizedcity/authorizedCityAndLevel', //   /dealermanage/authorizedcity/sign?id=4&ifEnabled=1
    params,
    method: 'GET'
  })
}

/**
 * 新增授权城市等级
 * @param {*} params
 * @returns
 */
export const authorizedcitylevelAdd = data => {
  return http({
    url: '/dealermanage/authorizedcitylevel/add', //   /dealermanage/authorizedcity/sign?id=4&ifEnabled=1
    data,
    method: 'POST'
  })
}

/**
 * 修改授权城市等级
 * @param {*} params
 * @returns
 */
export const authorizedcitylevelUpate = data => {
  return http({
    url: '/dealermanage/authorizedcitylevel/update', //   /dealermanage/authorizedcity/sign?id=4&ifEnabled=1
    data,
    method: 'POST'
  })
}

/**
 * 获取用户授权大品牌
 */
export const getUserAuthorizedBrand = params => {
  return http({
    url: '/dealermanage/brandseries/getUserAuthorizedBrand', //   /dealermanage/authorizedcity/sign?id=4&ifEnabled=1
    params,
    method: 'GET'
  })
}
