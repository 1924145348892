<script>
import { defineComponent, ref, computed, watch, nextTick } from 'vue'
import XgPlayer from 'xgplayer'

export default defineComponent({
  emits: ['update:show'],
  setup(props, { emit }) {
    const playerRef = ref(null)
    const player = ref(null)
    const message = ref(null)

    const visible = ref(false)

    const defaultOptions = {
      autoplay: false,
      fluid: true, //流式布局
      videoInit: true, //初始化显示视频首帧
      playbackRate: [0.5, 0.75, 1, 1.5, 2],
      defaultPlaybackRate: 1,
      definitionActive: 'click', //清晰度切换配置
      playsinline: true, //内联模式
      // lastPlayTime: 20, //视频起播时间（单位：秒）
      // lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
      // rotateFullscreen: true, //样式横屏全屏
      // cssFullscreen: true, //网页样式全屏
      // ignores: ['fullscreen'],
      'x5-video-player-type': 'h5', //微信同层播放
      'x5-video-player-fullscreen': true, //微信全屏播放
      'x5-video-orientation': 'portraint', //微信横竖屏控制
      closeVideoClick: true, //video触发click事件后视频切换播放/暂停状态，可通过closeVideoClick关闭
      closeVideoTouch: false,
      enableVideoDbltouch: true, //使移动端支持双击暂停/播放
      videoInit: true
    }

    //销毁播放器
    const destroy = (isDelDom = true) => {
      if (!player.value) return
      player.value.destroy(isDelDom)
      player.value = null
      playerRef.value.innerHTML = ''
    }

    const initPlayer = (scopeData={}) => {
      const { url, title } = scopeData
      if (!url || url == '') return
      message.value = title
      visible.value = true
      nextTick(() => {
        player.value = new XgPlayer({ ...defaultOptions, url, el: playerRef.value })
      })
    }

    const open = (scopeData) => {
      initPlayer(scopeData)
    }

    const onClose = () => {
      visible.value = false
      destroy()
    }

    return { visible, playerRef, player, message, initPlayer, onClose, open }
  }
})
</script>

<template>
  <el-dialog
    :visible.sync="visible"
    :title="message"
    width="50%"
    v-bind="$attrs"
    @opened="initPlayer"
    @close="onClose"
  >
    <div ref="playerRef"></div>
  </el-dialog>
</template>
