<template>
  <div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>概览</span>
      </div>
      <div>
        <div v-if="$route.query.type === 'edit'">
          <el-button class="download">
            导入目标
            <input
              ref="files"
              type="file"
              class="file"
              name=""
              id=""
              @change="downloadFile"
            />
          </el-button>
          <el-button @click="getTemplete">下载目标模板</el-button>
          <el-button type="danger" @click="del">删除选中</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
        <div>
          <el-table
            ref="filterTable"
            :data="tableData"
            border
            style="width: 100%;margin-top:20px"
            @row-click="rowClick"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column prop="province" label="省份" align="center">
            </el-table-column>
            <el-table-column prop="brand" label="品牌" align="center">
            </el-table-column>
            <el-table-column prop="ifIssued" label="目标状态" align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.ifIssued ? '是' : '否' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="jan" label="1月" align="center">
            </el-table-column>
            <el-table-column prop="feb" label="2月" align="center">
            </el-table-column>
            <el-table-column prop="mar" label="3月" align="center">
            </el-table-column>
            <el-table-column prop="apr" label="4月" align="center">
            </el-table-column>
            <el-table-column prop="may" label="5月" align="center">
            </el-table-column>
            <el-table-column prop="june" label="6月" align="center">
            </el-table-column>
            <el-table-column prop="july" label="7月" align="center">
            </el-table-column>
            <el-table-column prop="aug" label="8月" align="center">
            </el-table-column>
            <el-table-column prop="sep" label="9月" align="center">
            </el-table-column>
            <el-table-column prop="octo" label="10月" align="center">
            </el-table-column>
            <el-table-column prop="nov" label="11月" align="center">
            </el-table-column>
            <el-table-column prop="dece" label="12月" align="center">
            </el-table-column>
            <el-table-column prop="targetSum" label="合计" align="center">
            </el-table-column>
          </el-table>
          <div class="total">
            <span>合计：{{ sumTotal }}</span>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            :current-page="currentPage"
            :page-sizes="[10, 30, 50]"
            :page-size="pageSize || 10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="currentChange"
          />
        </div>
        <div v-if="$route.query.type === 'edit'">
          <el-button type="primary" @click="targetIssued(1)">目标下发</el-button>
          <el-button type="primary" @click="targetIssued(0)">清空已下发目标</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getTargetListData,
  downLoadFile,
  downloadExcel,
  delTarget,
  resetTarget,
  issuedTarget
} from '@/api/businessPlan'
export default {
  data() {
    return {
      tableData: [],
      rowId: [],
      currentPage: 1,
      total: 0,
      pageSize: 10,
      sumTotal: 0,
      activityId: null
    }
  },
  created() {
    this.activityId = this.$route.query.id
    this.getDataList()
  },
  methods: {
    async getDataList() {
      const res = await getTargetListData({
        currPage: this.currentPage,
        pageSize: this.pageSize,
        activityId: this.activityId,
        type: 0
      })
      console.log(res)
      if (res.code === 0) {
        this.tableData = res.data.map((item, index) => {
          return {
            ...item,
            id: index
          }
        })
        this.total = res.totalCount
        if (res.data.length == 0) {
          this.sumTotal = 0
          return
        }
        res.data.forEach(item => {
          this.sumTotal += item.targetSum
        })
      }
    },
    // 导入模板
    async downloadFile(e) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      formData.append('activityId', this.activityId)
      // this.$refs.files.values = ''
      this.$refs.files.value = ''
      const res = await downLoadFile(formData)
      console.log(res)
      if (res.code === 0) {
        this.currentPage = 1
        this.getDataList()
        this.$message.success('导入成功')
      }
    },
    // 下载模板
    async getTemplete() {
      await downloadExcel()
    },
    // 删除选中目标
    del() {
      if (this.rowId.length == 0) {
        this.$message.warning('请选择需要删除的目标')
        return
      }
      this.$confirm(`是否删除该目标?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const list = this.tableData
          .filter(item => this.rowId.includes(item.id))
          .map(i => {
            return {
              activityId: i.activityId,
              province: i.province,
              brand: i.brand
            }
          })
        const res = await delTarget(list)
        if (res.code === 0) {
          this.getDataList()
          this.$message.success('删除成功')
        }
      })
    },
    // 目标下发
    async targetIssued(type) {
      const msg = type?'下发':'清空'
      if (this.rowId.length == 0) {
        this.$message.warning(`请选择需要${msg}的目标`)
        return
      }
      const list = this.tableData
        .filter(item => this.rowId.includes(item.id))
        .map(i => {
          return {
            activityId: i.activityId,
            province: i.province,
            brand: i.brand,
            ifIssued: type
          }
        })
      const res = await issuedTarget(list)
      if (res.code === 0) {
        this.getDataList()
        this.$message.success(`目标${msg}成功`)
      }
    },
    // 重置
    reset() {
      this.$confirm(`是否重置目标概览?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await resetTarget({ activityId: this.activityId })
        if (res.code === 0) {
          this.currentPage = 1
          this.getDataList()
          this.$message.success('重置成功')
        }
      })
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.filterTable.toggleRowSelection(row)
    },
    // 页面切换
    currentChange(page) {
      this.currentPage = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.currentPage = 1
      this.pageSize = page
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  align-items: center;
  padding-right: 60px;
  width: 100%;
  border: 1px solid #e6ebf5;
  border-top-width: 0;
}
.pagination {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.download {
  position: relative;
  .file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
