var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlowForm',{ref:"flowFormRef",attrs:{"model":_vm.form,"get-detail":_vm.getDetail,"on-submit":_vm.onSubmit,"show-save":false,"title":"海外门店数据录入"}},[_c('HeaderTitle',{staticStyle:{"margin":"20px 0 10px"},attrs:{"title":"实控人信息"}}),_c('RequiredFormItemSelect',{ref:"businessRef",staticClass:"formItem",attrs:{"prop":"businessLicenseId","label":"售达方","label-key":"licenseNumber","value-key":"id","filterable":"","remote":"","popper-class":"custom-business-select","get-data-list":_vm.getBusinessLicense,"get-next-list":_vm.getBusinessLicense},on:{"onOptionsListNotFound":_vm.onNotFoundBusinessLicense,"change":() => {
        _vm.form.senderNumber = ''
        _vm.form.senderId = ''
        _vm.form.senderName = ''
      },"changeSelectedItem":e => {
        _vm.businessSelectedItem = e
        _vm.form.businessLicenseName = e.businessLicenseName
        _vm.form.licenseNumber = e.licenseNumber
      }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.licenseNumber))]),_c('small',[_vm._v(_vm._s(scope.row.businessLicenseName))])]}}]),model:{value:(_vm.form.businessLicenseId),callback:function ($$v) {_vm.$set(_vm.form, "businessLicenseId", $$v)},expression:"form.businessLicenseId"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"售达方名称","placeholder":"自动带出","disabled":""}}),_c('RequiredFormItemSelect',{staticClass:"formItem",attrs:{"prop":"senderNumber","label":"送达方编号","placeholder":"请选择售达方下对应送达方","label-key":"shopBrandNumber","value-key":"shopBrandNumber","filterable":"","remote":"","disabled":!_vm.form.businessLicenseId,"get-data-list":_vm.getMasterDataList,"next-data-list":_vm.getMasterDataList,"get-data-list-params":_vm.form.businessLicenseId},on:{"onOptionsListNotFound":_vm.onNotFoundMasterDataList,"changeSelectedItem":e => {
        _vm.form.senderId = e ? e.id : ''
        _vm.form.senderName = e ? e.authorizedName : ''
      }},model:{value:(_vm.form.senderNumber),callback:function ($$v) {_vm.$set(_vm.form, "senderNumber", $$v)},expression:"form.senderNumber"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"送达方名称","placeholder":"系统自动带出","disabled":""},model:{value:(_vm.form.senderName),callback:function ($$v) {_vm.$set(_vm.form, "senderName", $$v)},expression:"form.senderName"}}),_c('RequiredFormItemSelect',{ref:"shopRef",staticClass:"formItem",attrs:{"prop":"authorizedCityCode","label":"授权城市","label-key":"authorizedCityName","value-key":"authorizedCityCode","empty-disabled":"","get-data-list":_vm.getCitylist,"get-next-list":_vm.getCitylist,"get-data-list-params":_vm.businessSelectedItem
        ? `${_vm.businessSelectedItem.id},${_vm.businessSelectedItem.bossId}`
        : ''},on:{"changeSelectedItem":e => (_vm.form.authorizedCityName = e ? e.authorizedCityName : '')},model:{value:(_vm.form.authorizedCityCode),callback:function ($$v) {_vm.$set(_vm.form, "authorizedCityCode", $$v)},expression:"form.authorizedCityCode"}}),_c('br'),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"门店编号","placeholder":"归档后自动生成","disabled":""},model:{value:(_vm.form.shopNumber),callback:function ($$v) {_vm.$set(_vm.form, "shopNumber", $$v)},expression:"form.shopNumber"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"prop":"shopName","label":"门店名称"},model:{value:(_vm.form.shopName),callback:function ($$v) {_vm.$set(_vm.form, "shopName", $$v)},expression:"form.shopName"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"prop":"marketName","label":"商场名称"},model:{value:(_vm.form.marketName),callback:function ($$v) {_vm.$set(_vm.form, "marketName", $$v)},expression:"form.marketName"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"门店地址","prop":"street"},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"门店面积（平方米）","prop":"shopArea"},model:{value:(_vm.form.shopArea),callback:function ($$v) {_vm.$set(_vm.form, "shopArea", $$v)},expression:"form.shopArea"}}),_c('RequiredFormItemDatePicker',{staticClass:"formItem",attrs:{"prop":"openingTime","label":"开业时间"},model:{value:(_vm.form.openingTime),callback:function ($$v) {_vm.$set(_vm.form, "openingTime", $$v)},expression:"form.openingTime"}}),_c('br'),_c('RequiredModuleFormItemSelect',{staticClass:"formItem",attrs:{"prop":"countryCode","module":"public","type":"country","label":"国家"},on:{"change":() => {
        _vm.form.firstAdministrativeRegionCode = ''
        _vm.form.firstAdministrativeRegionName = ''
        _vm.form.secondAdministrativeRegionCode = ''
        _vm.form.secondAdministrativeRegionName = ''
      },"changeSelectedItem":e => (_vm.form.country = e ? e.content : '')},model:{value:(_vm.form.countryCode),callback:function ($$v) {_vm.$set(_vm.form, "countryCode", $$v)},expression:"form.countryCode"}}),_c('RequiredModuleFormItemSelect',{staticClass:"formItem",attrs:{"module":"public","type":"province","parent-code":_vm.form.countryCode,"dependent-parent-code":"","label":"一级行政区","empty-disabled":""},on:{"change":() => {
        _vm.form.secondAdministrativeRegionCode = ''
        _vm.form.secondAdministrativeRegionName = ''
      },"changeSelectedItem":e => (_vm.form.firstAdministrativeRegionName = e ? e.content : '')},model:{value:(_vm.form.firstAdministrativeRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "firstAdministrativeRegionCode", $$v)},expression:"form.firstAdministrativeRegionCode"}}),_c('RequiredModuleFormItemSelect',{staticClass:"formItem",attrs:{"module":"public","type":"city","parent-code":_vm.form.firstAdministrativeRegionCode,"dependent-parent-code":"","label":"二级行政区","empty-disabled":""},on:{"changeSelectedItem":e => (_vm.form.secondAdministrativeRegionName = e ? e.content : '')},model:{value:(_vm.form.secondAdministrativeRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "secondAdministrativeRegionCode", $$v)},expression:"form.secondAdministrativeRegionCode"}}),_c('RequiredFormItemSelect',{ref:"applyBrandSeriesListRef",staticClass:"formItem",attrs:{"value":_vm.form.applySeriesList.map(item => item.code),"label-key":"name","value-key":"brandCode","label":"门店代理系列","multiple":"","empty-disabled":"","get-data-list-params":`${_vm.form.businessLicenseId || ''},${
      _vm.form.authorizedCityCode || ''
    }`,"get-data-list":_vm.getBrandseriesList},on:{"change":_vm.changeApplyBrandSeriesListSelectedItem}}),_c('RequiredModuleFormItemSelect',{staticClass:"formItem",attrs:{"prop":"shopType","type":"store_type","label":"渠道性质"},on:{"changeSelectedItem":e => (_vm.form.shopTypeValue = e ? e.content : '')},model:{value:(_vm.form.shopType),callback:function ($$v) {_vm.$set(_vm.form, "shopType", $$v)},expression:"form.shopType"}}),_c('RequiredModuleFormItemSelect',{staticClass:"formItem",attrs:{"prop":"statusExplain","type":"shop_state_code","label":"门店状态"},model:{value:(_vm.form.statusExplain),callback:function ($$v) {_vm.$set(_vm.form, "statusExplain", $$v)},expression:"form.statusExplain"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }