<!--
 * @Author: wangmq
 * @Date: 2023-04-22 11:51:14
 * @LastEditors: wangmq
 * @LastEditTime: 2023-06-25 11:43:07
-->
<template>
  <div>
    <TablePro
      ref="tableRef"
      :columns="tableColumns"
      :request="getDataList"
      :actions="['export']"
      @selection-change="onSelect"
    >
      <template #actionArea>
        <div>
          <PopupForm
            ref="popupFormRef"
            popup-type="dialog"
            width="450px"
            title="新增"
            :columns="formStoreColumns"
            :on-submit="onStoreSubmit"
          >
            <!-- 触发表单按钮，也可以通过调用组件实例onShow方法触发
              onShow(Object),Object初始化表单数据，返回一个promise，当弹框关闭时resolve
           -->
            <template #trigger="{ onShow }">
              <el-button
                style="margin-right: 8px"
                type="primary"
                @click="
                  onShow({
                    updateName: userInfo.realName,
                    moduleBrand: []
                  })
                "
              >
                添加渠道性质
              </el-button>
            </template>
          </PopupForm>
          <PopupForm
            ref="popupFormRef"
            popup-type="dialog"
            width="450px"
            title="新增"
            :columns="formStoreSecColumns"
            :on-submit="onSecSubmit"
          >
            <!-- 触发表单按钮，也可以通过调用组件实例onShow方法触发
              onShow(Object),Object初始化表单数据，返回一个promise，当弹框关闭时resolve
           -->
            <template #trigger="{ onShow }">
              <el-button
                type="primary"
                @click="
                  onShow({
                    updateName: userInfo.realName
                  })
                "
              >
                添加渠道性质二级分类
              </el-button>
            </template>
          </PopupForm>
          <el-button
            type="primary"
            :disabled="selectList.length === 0"
            @click="handleChangeStatus(0)"
          >
            批量生效
          </el-button>
          <el-button
            type="primary"
            :disabled="selectList.length === 0"
            @click="handleChangeStatus(1)"
          >
            批量失效
          </el-button>
        </div>
      </template>
    </TablePro>
  </div>
</template>

<script>
import TablePro, { render, renderFormItem } from '@/components/TablePro'
import CommonSelect from '@/components/CustomFormItem/requestModuleSelect.vue'
import PopupForm from '@/components/FormPro/PopupForm.vue'
import SelectPro from '@/components/CustomFormItem/Select.vue'
import RequiredSelect from '@/components/CustomFormItem/Select.vue'
import {
  requestGetClassicList,
  updateStatus
} from '@/api/processBasicConfiguration'
import deepCopy from '@/utils/deepCopy'
import { getStorage } from '@/utils/storage'
import { brandseriesList, requestAddDatadictionary } from '@/api/common'
export default {
  components: {
    TablePro,
    CommonSelect,
    PopupForm,
    SelectPro,
    RequiredSelect
  },
  data() {
    return {
      userInfo: {},
      selectList: [],
      tableColumns: [
        {
          title: '渠道性质',
          name: 'parentContent',
          search: {
            transform(e) {
              return e
                ? {
                    parentCode: e
                  }
                : {}
            }
          },
          renderFormItem: renderFormItem((_, context) => {
            return (
              <CommonSelect
                attrs={context.$attrs}
                on={context.$listeners}
                label="渠道性质"
                showLabel={false}
                type="store_type"
              />
            )
          })
        },
        {
          title: '渠道性质所属品牌',
          name: 'parentModelBrand',
          renderText: e => {
            if (!e) return ''
            const brands = e.split(',')
            const text = brands
              .map(item => {
                const i = this.brandsList.find(v => v.code === item)
                return i ? i.name : ''
              })
              .join('，')
            return text
          }
        },
        {
          title: '生/失效',
          name: 'parentIfDelete',
          render: render((_, context) => {
            return (
              <el-switch
                v-model={context.data.parentIfDelete}
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value={0}
                inactive-value={1}
                onChange={e => {
                  this.handleUpdate({
                    module: 'distributor',
                    type: 'store_type',
                    remark: '渠道性质一级分类',
                    id: context.data.parentId,
                    content: context.data.parentContent,
                    ifDelete: context.data.parentIfDelete
                  })
                }}
              ></el-switch>
            )
          })
        },
        {
          title: '渠道性质二级分类',
          name: 'content',
          search: true,
          renderFormItem: renderFormItem((_, context) => {
            return (
              <CommonSelect
                attrs={context.$attrs}
                on={context.$listeners}
                label="渠道性质二级分类"
                showLabel={false}
                type="secondary_store_type"
                value-key="content"
                getDataListParams={{parentType:'store_type'}}
                parent-code={context.$attrs.params.shopType}
              />
            )
          })
        },

        {
          title: '渠道性质二级分类所属品牌',
          name: 'moduleBrand',
          renderText: e => {
            if (!e) return ''
            const brands = e.split(',')
            const text = brands
              .map(item => {
                const i = this.brandsList.find(v => v.code === item)
                return i ? i.name : ''
              })
              .join('，')
            return text
          }
        },
        {
          title: '生/失效',
          name: 'ifDelete',
          valueEnum: [
            { label: '生效', value: 0 },
            { label: '失效', value: 1 }
          ],
          render: render((_, context) => {
            return context.data.content ? (
              <el-switch
                v-model={context.data.ifDelete}
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value={0}
                inactive-value={1}
                onChange={e => {
                  this.handleUpdate({
                    id: context.data.parentId,
                    module: 'distributor',
                    type: 'store_type',
                    content: context.data.parentContent,
                    remark: '渠道性质',
                    childDataList: [
                      {
                        id: context.data.id,
                        module: 'distributor',
                        type: 'secondary_store_type',
                        content: context.data.content,
                        remark: '渠道性质二级分类',
                        ifDelete: e
                      }
                    ]
                  })
                }}
              ></el-switch>
            ) : (
              ''
            )
          })
        },
        {
          title: '更新人',
          name: 'updateName'
        },
        {
          title: '操作',
          width: 140,
          render: render((h, { data, index }) => {
            return (
              <div>
                {/* <div>
                  <el-button
                    style={{ marginRight: '10px' }}
                    type="text"
                    onClick={() => {
                      data.ifDelete = data.ifDelete ? 0 : 1
                      this.handleUpdate(data)
                    }}
                  >
                    {data.ifDelete ? '生效' : '失效'}
                  </el-button>
                </div> */}
                <PopupForm
                  ref="popupFormRef"
                  popup-type="dialog"
                  width="450px"
                  title="修改"
                  columns={this.formStoreColumns}
                  attrs={{
                    onSubmit: this.onStoreSubmit
                  }}
                  scopedSlots={{
                    trigger: ({ onShow }) => {
                      return (
                        <div>
                          <el-button
                            type="text"
                            onClick={() => {
                              onShow({
                                id: data.parentId,
                                content: data.parentContent,
                                moduleBrand: data.parentModelBrand.split(','),
                                updateName: this.userInfo.realName
                              })
                            }}
                          >
                            修改渠道性质
                          </el-button>
                        </div>
                      )
                    }
                  }}
                ></PopupForm>
                <PopupForm
                  ref="popupFormRef"
                  popup-type="dialog"
                  width="450px"
                  title="修改"
                  columns={this.formStoreSecColumns}
                  attrs={{
                    onSubmit: this.onSecSubmit
                  }}
                  scopedSlots={{
                    trigger: ({ onShow }) => {
                      return (
                        <div>
                          <el-button
                            type="text"
                            onClick={() =>
                              onShow({
                                id: data.id,
                                updateName: this.userInfo.realName,
                                shopTypeCode: data.parentCode,
                                content: data.content,
                                moduleBrand: data.moduleBrand?.split(',') || []
                              })
                            }
                          >
                            修改渠道性质二级分类
                          </el-button>
                        </div>
                      )
                    }
                  }}
                ></PopupForm>
              </div>
            )
          })
        }
      ],
      formStoreColumns: [
        {
          title: '渠道性质',
          name: 'content',
          fieldProps: {
            required: true
          }
        },
        {
          title: '所属大品牌',
          name: 'moduleBrand',
          transform(e) {
            return e
              ? {
                  moduleBrand: e.join(',')
                }
              : {}
          },
          renderFormItem: renderFormItem(
            (_, context) => (
              <RequiredSelect
                attrs={context.$attrs}
                on={context.$listeners}
                label="所属大品牌"
                showLabel={false}
                label-key="name"
                valueKey="code"
                getDataList={this.getBrands}
                multiple={true}
              />
            ),
            {
              required: true
            }
          )
        },
        {
          title: '更新人',
          name: 'updateName',
          fieldProps: {
            readonly: true
          }
        }
      ],
      formStoreSecColumns: [
        {
          title: '渠道性质',
          name: 'shopTypeCode',
          renderFormItem: renderFormItem(
            (_, context) => {
              return (
                <CommonSelect
                  attrs={context.$attrs}
                  on={context.$listeners}
                  label="渠道性质"
                  showLabel={false}
                  type="store_type"
                  onChangeSelectedItem={e => {
                    context.$attrs.params.shopType = e ? e.content : ''
                    context.$attrs.params.parentId = e ? e.id : ''
                    context.$attrs.params.parentCode = e ? e.code : ''
                    console.log(e.moduleBrand, this.brandsList)
                    const moduleBrandArray = e ? e.moduleBrand.split(',') : []
                    this.filtersBrands = moduleBrandArray.map(item => {
                      return this.brandsList.find(v => v.code === item)
                    })
                  }}
                />
              )
            },
            {
              required: true
            }
          )
        },
        {
          title: '渠道性质二级分类',
          name: 'content',
          fieldProps: {
            required: true
          }
        },
        {
          title: '所属大品牌',
          name: 'moduleBrand',
          renderFormItem: renderFormItem(
            (_, context) => (
              <RequiredSelect
                attrs={context.$attrs}
                on={context.$listeners}
                label="所属大品牌"
                showLabel={false}
                label-key="name"
                valueKey="code"
                dataList={this.filtersBrands}
                multiple={true}
              />
            ),
            {
              required: true
            }
          )
        },
        {
          title: '更新人',
          name: 'updateName',
          fieldProps: {
            readonly: true
          }
        }
      ],
      brandsList: [],
      filtersBrands: []
    }
  },
  mounted() {
    this.userInfo = getStorage('userInfo')
    this.getBrands()
  },
  methods: {
    async getBrands(params) {
      if (this.brandsList.length > 0) {
        return { data: this.brandsList }
      }
      const res = await brandseriesList({
        ...params,
        ifDelete: 0,
        brandLevel: 0
      })
      this.brandsList = res.data
      return res
    },
    async getDataList(params) {
      const res = await requestGetClassicList({
        ...params,
        parentType: 'store_type'
      })
      return res
    },
    async handleUpdate(data) {
      const valueData = deepCopy(data)
      valueData.updateName = this.userInfo.realName
      await requestAddDatadictionary(valueData)
      this.$message.success('操作成功')
      this.$refs.tableRef.onRefreshTableList()
    },
    onSelect(rows) {
      this.selectList = rows
    },
    async handleChangeStatus(status) {
      await updateStatus({
        idsList: this.selectList.map(item => item.id),
        ifDelete: status
      })
      this.$message.success('操作成功')
      this.$refs.tableRef.onRefreshTableList()
    },
    async onStoreSubmit(data) {
      const dataValue = {
        ...data,
        module: 'distributor',
        type: 'store_type',
        remark: '渠道性质一级分类',
        ifDelete: 0
      }
      if (data.id) {
        await requestAddDatadictionary(dataValue)
        this.$refs.tableRef.onRefreshTableList()
      } else {
        await requestAddDatadictionary(dataValue)
        this.$refs.tableRef.onRefreshTableList(1)
      }
      this.$message.success('操作成功')
      return true
    },
    async onSecSubmit(data) {
      const dataValue = {
        ...data,
        module: 'distributor',
        moduleBrand: 'C',
        type: 'store_type',
        content: data.shopType,
        updateName: data.updateName,
        remark: '渠道性质',
        childDataList: [
          {
            id: data.id,
            module: 'distributor',
            moduleBrand: data.moduleBrand.join(','),
            type: 'secondary_store_type',
            content: data.content,
            remark: '渠道性质二级分类'
          }
        ]
      }
      if (data.id) {
        await requestAddDatadictionary(dataValue)
        this.$refs.tableRef.onRefreshTableList()
      } else {
        await requestAddDatadictionary(dataValue)
        this.$refs.tableRef.onRefreshTableList(1)
      }
      this.$message.success('操作成功')
      return true
    }
  }
}
</script>

<style scoped lang="scss"></style>
