import http from "@/utils/request";

//保存
export const save = data => http({ url: "/dealermanage/shop/decorated/temporarysave", method: "POST", data })

//提交
export const submit = data => http({ url: "/dealermanage/operationflow/masterDataApi/submit", method: "POST", data })

//获取门店信息
export const getShopDetail = params => http({ url: "/dealermanage/shop/decorated/declarationform", params })

//获取详情
export const getFlowDetail = params => http({ url: "/dealermanage/shop/decorated/historybyid", params })

//获取关联流程
export const getRelevantFlow = params => http({ url: "/dealermanage/declarationform/list", params })

//获取关联流程
export const getObtain = data => http({ url: "/consumer-admin/sap/forward/obtain", data, method: 'POST' })

//获取关联流程
export const updateHistoricalData = data => http({ url: "/dealermanage/datahistory/updateHistoricalData", data, method: 'POST' })